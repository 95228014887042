import React, { createContext, useContext, useState } from "react";

const EmpresaContext = createContext();

export const EmpresaProvider = ({ children }) => {
  const [empresa, setEmpresa] = useState(null);

  return (
    <EmpresaContext.Provider value={{ empresa, setEmpresa }}>
      {children}
    </EmpresaContext.Provider>
  );
};

export const useEmpresa = () => {
  const context = useContext(EmpresaContext);
  if (context === undefined) {
    throw new Error("useEmpresa debe ser usado dentro de un EmpresaProvider");
  }
  return context;
};