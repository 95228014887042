import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import { changeTitle, exportToExcel, handleColum, isMobile } from "../../config/functions/funGenerales";
import { CheckboxCol } from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { Series } from "../Series";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "../miniComponentes";

export const ConsMovimientos = ({ movimientos, permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;

  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaMovimientos, setListaMovimientos] = useState(movimientos);

  const [contProyecto, setContProyecto] = useState(0);
  const [contBodega, setContBodega] = useState(0);

  const [acumulados, setAcumulados] = useState({ superTotal: 0, cantidadArticulo: 0 });

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);
  const [col14, setCol14] = useState(true);
  const [col15, setCol15] = useState(true);
  const [col16, setCol16] = useState(true);
  const [col17, setCol17] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const tablaRef = useRef(null);

  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Cons_movimientos");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    handleColum(
      [setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17],
      user,
      "consMovimientos"
    );
    isMobile(setMobile)
    setListaMovimientos(movimientos);

  };

  useEffect(() => {
    setListaMovimientos(movimientos);
  }, [movimientos]);

  useEffect(() => {
    manipularCabecerasTabla(listaMovimientos);
    setDocumentosFiltrados(listaMovimientos);
  }, [listaMovimientos]);

  const calcularAcumulado = (listaMostrada) => {
    const resultado = listaMostrada.reduce((acumulados, li) => {
      const valorCelda = li.ult_Costo * li.cantidad;
      return {
        superTotal: acumulados.superTotal + valorCelda,
        cantidadArticulo: parseFloat(acumulados.cantidadArticulo + li.cantidad),
      };
    },
      { superTotal: 0, cantidadArticulo: 0 }
    );

    return resultado;
  };

  //------ Paginación -------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas, listaMovimientos, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaMovimientos(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  useEffect(() => {
    const { superTotal, cantidadArticulo } = calcularAcumulado(registrosPaginaActual);
    setAcumulados({ superTotal, cantidadArticulo });
  }, [registrosPaginaActual, paginaActual]);


  const manipularCabecerasTabla = (lista) => {
    let cP = 0
    let cB = 0

    lista.forEach(etb => {
      if (etb.proyectoDestino !== "" && etb.proyectoDestino !== undefined) {
        cP = cP + 1
      }
      if (etb.bodegaDestino !== "" && etb.bodegaDestino !== undefined) {
        cB = cB + 1
      }
    })
    setContBodega(cB)
    setContProyecto(cP)
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    if (col9 && excluido !== 'col9') contador++;
    if (col10 && excluido !== 'col10') contador++;
    if (col11 && excluido !== 'col11') contador++;
    if (col12 && excluido !== 'col12') contador++;
    if (col13 && excluido !== 'col13') contador++;
    if (col14 && excluido !== 'col14') contador++;
    if (col15 && excluido !== 'col15') contador++;
    if (col16 && excluido !== 'col16') contador++;
    if (col17 && excluido !== 'col17') contador++;
    return contador >= 2;
  };

  const formatExcel = (lista) => {
    const data = lista?.map((item) => {
      return {
        "Consecutivo Art": item?.consecutivoFix,
        "Consecutivo Mov": item?.consecutivoFixMov,
        "Estado": item?.estadoMov,
        "Artículo": item?.des_Larga,
        "Clasificacion 1": item?.clasificacion1,
        "Clasificacion 2": item?.clasificacion2,
        "Clasificacion 3": item?.clasificacion3,
        "Cantidad": item?.cantidad,
        "A Bodega": item?.bodegaDestino,
        "A Ubicacion": item?.ubicacionDestino,
        "A Proyecto": item?.proyectoDestino,
        "Costo en Gestion": item?.valor,
        "Costo Actual": item?.ult_Costo,
        "Valor Total": item?.ult_Costo * item?.cantidad,
        "Solicitante": item?.usuario_creacion,
        "Fecha": item?.fecha_creacion
      }
    });
    if (data.length > 0) {
      exportToExcel(data, 'datos.xlsx')
    }
  }

  //************ Inicio componente ******************/
  return (
    <>
      {isLoading ?
        <LoaderES />
        :
        (<>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            <div className="row ms-2 me-2">

              {/* botones de opcion */}
              <div className="col-4 align-self-start text-start">
                <div className="btn-group">
                  {/* Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                  <button className="btn fa-solid fa-file-excel"
                    onClick={() => {
                      formatExcel(registrosPaginaActual)
                    }}
                    style={{ cursor: 'pointer', color: 'green' }}
                    title='Exportar a Excel'
                  />

                </div>
              </div>

              {/* filtrar */}
              <div className="col-8">
                <Filtrar2
                  tabla="myTable"
                  documentos={listaMovimientos}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={(mobile ? "mt-2 mb-3" : "mt-5 ")} />

          {listaMovimientos.length > 0 &&
            (
              mobile ?
                (<>
                  {registrosPaginaActual.map((li) => (
                    <div key={li.consecutivo}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + li.consecutivo}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none" }}>{li.consecutivoFixMov}</th>
                            <td>{li.estado}</td>
                            <td>{li.bodegaDestino ? li.bodegaDestino : li.proyectoDestino}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + li.consecutivo}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody>
                              <DatoTablaMobile titulo={'Consec. Art'} dato={li.consecutivoFix} columna={col2} />
                              <DatoTablaMobile titulo={'Consec. Mov'} dato={li.consecutivoFixMov} columna={col3} />
                              <DatoTablaMobile titulo={'Estado'} dato={li.estado} columna={col4} />
                              <DatoTablaMobile titulo={'Artículo'} dato={li.des_Larga} columna={col5} clase={(col5 && li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : !col5 ? " text-danger d-none" : col5 ? "" : "d-none"} />
                              <DatoTablaMobile titulo={'Clasificacion 1'} dato={li.clasificacion1} columna={col6} />
                              <DatoTablaMobile titulo={'Clasificacion 2'} dato={li.clasificacion2} columna={col7} />
                              <DatoTablaMobile titulo={'Clasificacion 3'} dato={li.clasificacion3} columna={col8} />
                              <DatoTablaMobile titulo={'Cantidad'} dato={li.cantidad} columna={col9} />
                              {li.bodegaDestino && (<>
                                <DatoTablaMobile titulo={'A Bodega'} dato={li.bodegaDestino} columna={col10} />
                                <DatoTablaMobile titulo={'A Ubicacion'} dato={li.ubicacionDestino} columna={col11} />
                              </>)}
                              {li.proyectoDestino && (
                                <DatoTablaMobile titulo={'A Proyecto'} dato={li.proyectoDestino} columna={col12} />
                              )}
                              <DatoTablaMobile titulo={'Costo Actual'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo)}`} columna={col13} />
                              <DatoTablaMobile titulo={'Costo en Gestion'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} columna={col14} />
                              <DatoTablaMobile titulo={'Valor Total'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo * li.cantidad)}`} columna={col15} />
                              <DatoTablaMobile titulo={'Solicitante'} dato={li.usuario_creacion} columna={col16} />
                              <DatoTablaMobile titulo={'Fecha Solicitud'} dato={li.fecha_creacion} columna={col17} />
                              {li.serial && (
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Ver Series</th>
                                  <td>
                                    <Series articulo={li} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </>)
                :
                (<>
                  <div className="table-responsive">
                    <table
                      id="myTable"
                      ref={tablaRef}
                      className="table table-hover table-striped table-sm table-bordered mt-3">
                      <thead className="text-center">
                        <tr>
                          <ThTablaH ordenar={ordenar} dOrdenar={'consecutivoFix'} columna={col2} titulo={'Consec. Art'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'consecutivoFixMov'} columna={col3} titulo={'Consec. Mov'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'estado'} columna={col4} titulo={'Estado'} />

                          <ThTablaH ordenar={ordenar} dOrdenar={'articulo'} columna={col5} titulo={'Artículo'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion1'} columna={col6} titulo={'Clasificacion 1'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion2'} columna={col7} titulo={'Clasificacion 2'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion3'} columna={col8} titulo={'Clasificacion 3'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'cantidad'} columna={col9} titulo={'Cantidad'} />
                          {contBodega > 0 && (<>
                            <ThTablaH ordenar={ordenar} dOrdenar={'bodegaDestino'} columna={col10} titulo={'A Bodega'} />
                            <ThTablaH ordenar={ordenar} dOrdenar={'ubicacionDestino'} columna={col11} titulo={'A Ubicacion'} />
                          </>)}
                          {contProyecto > 0 && (
                            <ThTablaH ordenar={ordenar} dOrdenar={'proyectoDestino'} columna={col12} titulo={'A Proyecto'} />
                          )}
                          <ThTablaH ordenar={ordenar} dOrdenar={'valor'} columna={col14} titulo={'Costo en Gestion'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'ult_Costo'} columna={col13} titulo={'Costo Actual'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={''} columna={col15} titulo={'Total'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'usuario_creacion'} columna={col16} titulo={'Solicitante'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'fecha_creacion'} columna={col17} titulo={'Fecha'} />
                          <th>Opciones</th>
                        </tr>
                      </thead>

                      <tbody className="text-center" id="tabla_articulos">
                        {registrosPaginaActual.map((li) => (
                          <tr key={li.consecutivo + li.articulo}>
                            <TdTablaD columna={col2} dato={li.consecutivoFix} />
                            <TdTablaD columna={col3} dato={li.consecutivoFixMov} />
                            <TdTablaD columna={col4} dato={li.estadoMov} />
                            <TdTablaD columna={col5} dato={li.des_Larga} clase={(col5 && li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : !col5 ? " text-danger d-none" : col5 ? "" : "d-none"} />
                            <TdTablaD columna={col6} dato={li.clasificacion1} />
                            <TdTablaD columna={col7} dato={li.clasificacion2} />
                            <TdTablaD columna={col8} dato={li.clasificacion3} />
                            <TdTablaD columna={col9} dato={li.cantidad} />
                            {contBodega > 0 && (<>
                              <TdTablaD columna={col10} dato={li.bodegaDestino} />
                              <TdTablaD columna={col11} dato={li.ubicacionDestino} />
                            </>)}
                            {contProyecto > 0 && (
                              <TdTablaD columna={col12} dato={li.proyectoDestino} />
                            )}
                            <TdTablaD columna={col14} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} />
                            <TdTablaD columna={col13} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo)}`} />
                            <TdTablaD columna={col15} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo * li.cantidad)}`} />
                            <TdTablaD columna={col16} dato={li.usuario_creacion} />
                            <TdTablaD columna={col17} dato={li.fecha_creacion} />
                            <td>
                              {li.serial && <Series articulo={li} />}
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>

                      <tfoot className=" mt-2 text-center text-danger">
                        <tr>
                          <td className={col2 ? "" : "d-none"}></td>
                          <td className={col3 ? "" : "d-none"}></td>
                          <td className={col4 ? "" : "d-none"}></td>
                          <td className={col5 ? "" : "d-none"}></td>
                          <td className={col6 ? "" : "d-none"} ></td>
                          <td className={col7 ? "" : "d-none"}></td>
                          <td className={col8 ? "" : "d-none"}></td>
                          <td className={col9 ? "text-center" : "d-none"}>{acumulados.cantidadArticulo}</td>
                          {contBodega > 0 && (<>
                            <td className={col10 ? "" : "d-none"}></td>
                            <td className={col11 ? "" : "d-none"} ></td>
                          </>)}
                          {contProyecto > 0 && (
                            <td className={col12 ? "" : "d-none"}></td>
                          )}
                          <td className={col13 ? "" : "d-none"}></td>
                          <td className={col14 ? "" : "d-none"}></td>
                          <td className={col15 ? "text-center" : "d-none"}>${Intl.NumberFormat("es-CO").format(acumulados.superTotal)}</td>
                          <td className={col16 ? "" : "d-none"}></td>
                          <td className={col17 ? "" : "d-none"}></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </>)
            )}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados}
          />

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo Art."} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Consecutivo Mov."} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Artículo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Clasificacion 1	"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Clasificacion 2"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Clasificacion 3"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9')} agregarColumna={agregarColumna} texto={"Cantidad"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10')} agregarColumna={agregarColumna} texto={"Bodega"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11')} agregarColumna={agregarColumna} texto={"Ubicacion"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12')} agregarColumna={agregarColumna} texto={"Proyecto"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13')} agregarColumna={agregarColumna} texto={"Costo en Gestion"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14')} agregarColumna={agregarColumna} texto={"Costo Actual"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={15} checked={col15} setChecked={setCol15} validar={() => validarCheckboxes('col15')} agregarColumna={agregarColumna} texto={"Valor Total"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={16} checked={col16} setChecked={setCol16} validar={() => validarCheckboxes('col16')} agregarColumna={agregarColumna} texto={"Solicitante"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consMovimientos" columna={17} checked={col17} setChecked={setCol17} validar={() => validarCheckboxes('col17')} agregarColumna={agregarColumna} texto={"Fecha"} />
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal" >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>)
      }
    </>
  );
};
