import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { CSSTransition } from "react-transition-group";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import bcrypt from "bcryptjs";
import CookieConsent from "react-cookie-consent";
import logo from "../images/ES_sin_fondo.png";
import logoes from "../images/ewhite.png";
import logo_livenUp from "../images/lvup.png";
import logo_white from "../images/ES_sin_fondo_white.png";
import ReCAPTCHA from "react-google-recaptcha";

import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  changePwd,
  consultarDocumentoWhereIgual,
  consultarSesiones,
  logOutUsuario,
  signInUser
} from "../config/firebase";

import "react-toastify/dist/ReactToastify.css";
import "../css/login.css";
import "../css/login2.css";
import { changeTitle } from "../config/functions/funGenerales";

export const Login = () => {

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const fechaModificacion = fechaFormateada;
  const history = useHistory();

  const [idDocumento, setIdDocumento] = useState("");
  const [cambioClave, setCambioClave] = useState(0);
  const [authToken, setAuthToken] = useState("");
  const [emailSubs, setEmailSubs] = useState("");
  const [clave1, setClave1] = useState("");
  const [clave2, setClave2] = useState("");
  const [pwd, setPwd] = useState("");
  const [usr, setUsr] = useState("");

  const [emailForm, setEmailForm] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [nombre, setNombre] = useState("");
  const [asunto, setAsunto] = useState("");
  const [tel, setTel] = useState("");


  //----------------------------------------------------------
  useEffect(() => {
    changeTitle("EasyStock");
  }, []);

  //----------------------------------------------------------
  const setToken = (token) => {
    if (token) {
      setAuthToken(token);
      localStorage.setItem("token", JSON.stringify(token));
    } else {
      setAuthToken(null);
      localStorage.removeItem("token");
    }
  };

  // ----------- cambio de clave ----------------------------
  const cambiarClave = async () => {
    if (clave1 !== "" && clave2 !== "") {
      if (clave1 === clave2) {
        const nuevaClave = bcrypt.hashSync(clave1, 10);

        const enviarUsuario = {
          clave: nuevaClave,
          cambioClave: false,
          fechaModificacion: fechaModificacion,
        };
        try {
          await actualizarDocumentoDatabase("usuarios", idDocumento, enviarUsuario);
          await changePwd(clave1)
        } catch (error) {
          console.error(error)
        }
        logOutUsuario()
        setCambioClave(0)
        setClave1("")
        setClave2("")
        setPwd("")

        toast.success("Contraseña modificada de manera correcta, ya puede ingresar al aplicativo");
      } else {
        toast.warning("Las contraseñas no son iguales")
      }
    } else {
      toast.warning("Diligencie ambos campos")
    }
  };

  //--------------------***************----------------------
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaValue2, setRecaptchaValue2] = useState(null);
  const [recaptchaValue3, setRecaptchaValue3] = useState(null);

  const handleRecaptchaChange = (value) => { setRecaptchaValue(value) };
  const handleRecaptchaChange2 = (value) => { setRecaptchaValue2(value) };
  const handleRecaptchaChange3 = (value) => { setRecaptchaValue3(value) };

  //---------------------------------------------------------
  const handleClickLogin = async () => {
    try {
      const prueba = await signInUser(usr, pwd);
      const ok = prueba.user.uid;

      let dataEmpresa = [];
      let empNombre = "";

      if (ok !== null) {
        if (usr !== "" && pwd !== "") {
          const listaTemporal = await consultarDocumentoWhereIgual("usuarios", "correo", usr)
          if (listaTemporal.length > 0) {
            const emp = listaTemporal[0].empresa
            dataEmpresa = await consultarDocumentoWhereIgual("empresas", "idEmpresa", emp)
            empNombre = dataEmpresa[0].razonSocial
          }
          if (listaTemporal.length === 1) {
            setIdDocumento(listaTemporal[0].idDocumento);
            if (listaTemporal[0].cambioClave) {
              setCambioClave(1);
              setUsr("")
              toast.info("Se debe realizar asignación de contraseña")
            } else {

              if (dataEmpresa[0].autorizado) {
                const hpwd = bcrypt.compareSync(pwd, listaTemporal[0].clave);

                if (hpwd === true && listaTemporal[0].estado === "ACTIVO") {
                  /* const resp = await addActiveSesion(listaTemporal[0].empresa)
                  if (resp === "Ok") { */
                  localStorage.removeItem("componente");
                  toast.success("Bienvenido");
                  setToken({
                    userName: listaTemporal[0].usuario,
                    tipo: listaTemporal[0].rol,
                    name: listaTemporal[0].empleado,
                    empresa: listaTemporal[0].empresa,
                    idDocumento: listaTemporal[0].idDocumento
                  });
                  history.push("/home");
                  /*  } */
                } else {
                  toast.warning("El usuario no se encuentra autorizado");
                }
              } else {
                toast.warning("La empresa " + empNombre + ", no esta autorizada para ingresar al aplicativo, comuniquese con el administrador")
              }
            }
          } else {
            toast.warning("Los datos ingresados son incorrectos");
          }
        }

      }
    } catch (error) {
      toast.warning("Error durante la autenticación:", error.message);
    }

  };

  const addActiveSesion = async (emp) => {
    const today = new Date()
    const sesiones = await consultarSesiones(emp, usr)
    if (sesiones[0] === true) {
      toast.warning("Este usuario ya se encuentra logueado en otra sesión")
    } else {
      if (sesiones[1] > sesiones[2]) {
        toast.warning(`No hay sesiones disponibles, ${sesiones[1]}/${sesiones[2]} `)
      } else {
        try {
          await actualizarDocumentoDatabaseIdCampo("usuarios", idDocumento, "activeSesion", today.toISOString());
          await actualizarDocumentoDatabaseIdCampo("usuarios", idDocumento, "lastActiveSesion", today.toISOString());
          return ("Ok")
        } catch (error) {
          console.error(error)
        }
      }
    }
  };

  //---------------------------------------------------------
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        document.querySelector(".sticky-top")?.classList.add("bg-easystock", "shadow-sm");
        document.querySelector(".sticky-top").style.top = "0px";
      } else {
        document.querySelector(".sticky-top")?.classList.remove("bg-easystock", "shadow-sm");
        document.querySelector(".sticky-top").style.top = "-150px";
      }

      if (window.scrollY > 100) {
        document.querySelector(".back-to-top").style.display = "block";
      } else {
        document.querySelector(".back-to-top").style.display = "none";
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }


  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const scrollToElement = (destino) => {
    const elementoDestino = document.getElementById(destino);

    if (elementoDestino) {
      elementoDestino.scrollIntoView({ behavior: "smooth" });
    }
  };

  //---------------------------------------------------------
  const validarCorreo = (correo) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(correo);
  };

  const handleEnviarCorreo = async (correo) => {
    if (validarCorreo(correo)) {

      const templateParams = {
        from_name: correo,
        message: "Nueva suscripción EasyStock"
      }

      const ids = {
        EMAIL_SERVICE_ID: "service_34559f8",
        EMAIL_TEMPLATE_ID: "template_8ebnz0d",
        EMAIL_PKEY: "-7FcMHncoreveh1Ka",
      }

      emailjs.send(ids.EMAIL_SERVICE_ID, ids.EMAIL_TEMPLATE_ID, templateParams, ids.EMAIL_PKEY)
        .then((result) => {
          toast.success("Suscripción exitosa");
          setEmailSubs("")
          setRecaptchaValue2(null)
        }, (error) => {
          toast.warning(error.text);
        });

    } else {
      toast.warning("Por favor, ingresa una dirección de correo electrónico válida");
    }
  };

  const enviarFormulario = () => {
    if (validarCorreo(emailForm)) {
      const templateParams = {
        from_name: emailForm,
        message: "Nuevo Formulario, "
          + "mi nombre es: " + nombre
          + ", con tel: " + tel
          + ", de la empresa: " + empresa
          + ", escribo por: " + asunto
          + ", mi mensaje es: " + mensaje,
      }

      const ids = {
        EMAIL_SERVICE_ID: "service_34559f8",
        EMAIL_TEMPLATE_ID: "template_370i8vi",
        EMAIL_PKEY: "-7FcMHncoreveh1Ka",
      }

      emailjs.send(ids.EMAIL_SERVICE_ID, ids.EMAIL_TEMPLATE_ID, templateParams, ids.EMAIL_PKEY)
        .then((result) => {
          toast.success("Leeremos tu mensaje lo antes posible y nos pondremos en contacto para atender tu inquietud o requerimiento");
          setNombre("")
          setEmailForm("")
          setTel("")
          setAsunto("")
          setEmpresa("")
          setMensaje("")
          setRecaptchaValue3(null)
        }, (error) => {
          toast.warning(error.text);
        });

    } else {
      toast.warning("Por favor, ingresa una dirección de correo electrónico válida");
    }
  };

  //---------------------------------------------------------

  const [cookieAccepted, setCookieAccepted] = useState(false);

  const handleAccept = () => {
    setCookieAccepted(true);
  };

  const handleReject = () => {
    setCookieAccepted(false);
  };

  //---------------------------------------------------------

  return (
    <>
      <ToastContainer position="bottom-right" />

      <FloatingWhatsApp
        phoneNumber="573105834876"
        accountName="Easy Stock"
        allowEsc
        allowClickAway
        notification
        notificationSound
        statusMessage="Control-Manage-Expand"
        chatMessage="Tienes alguna pregunta? déjanos saber tus inquietudes"
        buttonClassName="me-2"
        buttonStyle={{ marginBottom: "80px" }}
        chatboxStyle={{ marginBottom: "70px" }}
        avatar={logo_white}
      />

      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        enableDeclineButton
        declineButtonText="Rechazar"
        cookieName="miCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={handleAccept}
        onDecline={handleReject}
      >
        Easy Stock utiliza cookies para mejorar la experiencia del usuario.
      </CookieConsent>

      {/* offcanvas */}
      <div className="container ">

        <div className="offcanvas offcanvas-end "
          tabIndex="-1"
          id="offcanvasLogin"
          aria-labelledby="offcanvasLoginLabel">

          <div className="offcanvas-header">

            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">

            {/* <div className="login-wrap"
              style={{ backgroundColor: "white" }}> */}

            <div className="login-html">
              <div className="row text-center">
                <div className="col"><img src={logo} className="logo" alt="logo" /></div>
              </div>

              <hr style={{ color: "white", backgroundColor: "white", height: "3px" }} className="m-2" />

              {cambioClave === 0
                ?
                <>
                  <input
                    type="text"
                    placeholder="User Name"
                    className="form-control text-center mb-2"
                    value={usr}
                    onChange={(e) => setUsr(e.target.value)}
                  />

                  <input
                    type="password"
                    className="form-control text-center mb-3"
                    placeholder="Password"
                    data-type="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                  />

                  <div
                    className="row text-center mb-3">
                    <div className="col d-flex justify-content-center align-items-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                  </div>

                </>
                :
                <>
                  <input
                    type="text"
                    className="input text-center mb-2"
                    data-type="password"
                    placeholder={"Ingrese Contraseña"}
                    value={clave1}
                    onChange={(e) => setClave1(e.target.value)}
                  />
                  <input
                    type="password"
                    className="input text-center mb-2"
                    data-type="password"
                    placeholder={"Repetir Contraseña"}
                    value={clave2}
                    onChange={(e) => setClave2(e.target.value)}
                  />
                </>
              }

              {(usr !== "" || (clave1 !== "" && clave2 !== "")) && recaptchaValue
                ? <div className="container">
                  <div className="row">
                    <button
                      className="btn btn-outline-secondary"
                      data-bs-dismiss="offcanvas"
                      aria-label="SignIn"
                      onClick={cambioClave === 0 ? handleClickLogin : cambiarClave}>
                      {cambioClave === 0 ? "Sign In" : "Asignar clave"}
                    </button>
                  </div>
                </div>
                : null
              }

            </div>
            {/* </div> */}
          </div>
        </div>

      </div>
      {/* offcanvas */}

      {/* Landing Page */}
      <>

        {/* <!-- Navbar --> */}
        <div className="container-fluid sticky-top">
          <div className="container">

            <nav className="navbar navbar-expand-lg navbar-dark p-0 navbar-easystock">

              <a className="navbar-brand">
                <img src={logoes}
                  alt="LogoEasyStock"
                  width="30"
                  // height="24"
                  className="d-inline-block align-text-top" />
              </a>

              <h1 className="text-white" style={{fontSize: '1rem'}}>EASY STOCK</h1>

              <button
                type="button"
                aria-label="collapseNavbar"
                className="navbar-toggler ms-auto me-0"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarCollapse">

                <div className="navbar-nav ms-auto">

                  <button
                    className="nav-item nav-link active b-none"
                    style={{ fontSize: "16px" }}
                    aria-label="Inicio">
                    Inicio
                  </button>
                  <button
                    aria-label="NuestroSistema"
                    style={{ fontSize: "16px" }}
                    className="nav-item nav-link b-none"
                    onClick={() => scrollToElement("nuestroSistema")}>
                    Nuestro Sistema
                  </button>
                  <button className="nav-item nav-link b-none"
                    aria-label="Características"
                    style={{ fontSize: "16px" }}
                    onClick={() => scrollToElement("caracteristicas")}>
                    Características
                  </button>
                  <button className="nav-item nav-link b-none"
                    aria-label="FAQs"
                    style={{ fontSize: "16px" }}
                    onClick={() => scrollToElement("faqs")}>
                    FAQs
                  </button>
                  <button className="nav-item nav-link b-none"
                    aria-label="Contacto"
                    style={{ fontSize: "16px" }}
                    data-bs-toggle="modal"
                    data-bs-target="#modalContacto">
                    Contáctanos
                  </button>

                  <button
                    aria-label="Canvas"
                    style={{ fontSize: "16px" }}
                    className="nav-item nav-link b-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasLogin"
                    aria-controls="offcanvasLogin" >
                    Inicia Sesión
                  </button>

                </div>

              </div>

            </nav>

          </div>
        </div>
        {/* <!-- Navbar  --> */}

        {/*  <!-- Header TAB  --> */}
        <div className="container-fluid pt-5 easystock-header mb-5 bg-easystock">
          <div className="container pt-5">
            <div className="row g-5 pt-5">

              <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">

                <h1 className="display-4 text-white mb-4 animated slideInRight h1-easystock" id="nuestroSistema">
                  Manejar el inventario nunca fue tan fácil.
                </h1>
                <p className="text-white mb-4 animated slideInRight">
                  Gestiona tu inventario de manera eficiente con
                  EasyStock. Registra entradas, salidas y movimientos
                  de forma sencilla. Asigna productos a bodegas y crea
                  proyectos para una administración precisa. ¡Todo en
                  un solo lugar!
                </p>
                <button
                  aria-label="more"
                  onClick={() => scrollToElement("nuestroSistema")}
                  className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight">Saber Más
                </button>
                <button
                  aria-label="contacto"
                  data-bs-toggle="modal"
                  data-bs-target="#modalContacto"
                  className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contáctanos
                </button>
              </div>

              <div className="col-lg-6 align-self-end text-center text-lg-end player2">
                <Player
                  src="https://lottie.host/751afd5e-01ce-451b-87a3-1fd473c78772/WptfHlYSZN.json"
                  loop
                  autoplay
                />
              </div>

            </div>
          </div>
        </div>
        {/* <!-- Header  -->*/}

        {/* <!-- Nuestro Sistema --> */}
        <div className="container-fluid py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.8s">
                <div className="about-img">
                  <img className="img-fluid" src={logo} alt="logo" />
                </div>
              </div>

              <CSSTransition in={true} appear={true} timeout={2000} classNames="fadeIn" >
                <div className="col-lg-6  ">
                  <div className="btn btn-sm border rounded-pill text-easystock px-3 mb-3">
                    Nuestro sistema
                  </div>
                  <h1 className="mb-4 h1-easystock">
                    Ágil, Potente y <br />Versátil en la Nube
                  </h1>
                  <p className="mb-4">
                    Descubre nuestra aplicación líder en gestión de inventarios, diseñada para brindarte un
                    rendimiento ágil y eficiente en la nube. Con una plataforma multiplataforma, podrás acceder
                    y administrar tus inventarios desde cualquier dispositivo.
                  </p>
                  <p className="mb-4">
                    Experimenta la potencia y
                    versatilidad que EasyStock ofrece para una gestión de inventarios sin complicaciones y de alto rendimiento.
                  </p>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <h6 className="mb-3"><i className="fa fa-check text-easystock me-2"></i>100% Nube</h6>
                      <h6 className="mb-0"><i className="fa fa-check text-easystock me-2"></i>Control Total</h6>
                    </div>
                    <div className="col-sm-6">
                      <h6 className="mb-3"><i className="fa fa-check text-easystock me-2"></i>Multiplataforma</h6>
                      <h6 className="mb-0"><i className="fa fa-check text-easystock me-2"></i>Optimiza Procesos</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <button
                      aria-label="mas"
                      className="btn btn-easystock rounded-pill px-4 me-3 text-white"
                      onClick={() => scrollToElement("caracteristicas")}>
                      Saber Más
                    </button>
                  </div>
                </div>
              </CSSTransition>


            </div>
          </div>
        </div>
        {/* <!-- Nuestro Sistema --> */}

        {/* <!-- form subscripcion --> */}
        <div className="container-fluid bg-easystock newsletter py-5">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 col-md-5 col-sm-6 pt-5  fadeIn player ms-5"
                data-wow-delay="0.3s"  >
                <Player
                  src="https://lottie.host/2157c318-640f-4082-8dbc-eb569387031b/O1CpEVYT7E.json"
                  loop
                  autoplay
                />
              </div>

              <div className="col-9 col-md-7 col-sm-6 py-5 newsletter-text wow fadeIn ms-5"
                data-wow-delay="0.5s"
              >
                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                  Noticias!
                </div>

                <h1 className="text-white mb-4 h1-easystock">Suscríbete a nuestras noticias.</h1>

                <div className="position-relative mt-3 mb-2">
                  <input
                    className="form-control border-0 rounded-pill ps-4 pe-5"
                    type="text"
                    placeholder="Enter Your Email"
                    style={{ height: "48px" }}
                    onChange={(e) => setEmailSubs(e.target.value)}
                  />
                  {recaptchaValue2 && emailSubs && (
                    <button
                      aria-label="emailSubscribe"
                      type="button"
                      className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                      onClick={() => handleEnviarCorreo(emailSubs)}
                    >
                      <i className="fa fa-paper-plane text-easystock fs-4"></i>
                    </button>
                  )}
                </div>
                <small className="text-white-50">Al dar clic en enviar autorizas el tratamiento de datos para el uso exclusivo de correos promocionales</small>

                {emailSubs && (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange2}
                  />
                )}


              </div>

            </div>
          </div>


        </div>
        {/* <!-- form subscripcion --> */}

        {/* <!-- Características --> */}
        {/* <Element name="caracteristicas"> */}
        <CSSTransition in={true} appear={true} timeout={2000} classNames="fadeIn">
          <div className="container-fluid bg-light mt-5 py-5" id="caracteristicas">
            <div className="container py-5">
              <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                  <div className="btn btn-sm border rounded-pill text-easystock px-3 mb-3" >
                    Características
                  </div>
                  <h1 className="mb-4 h1-easystock">Solución Integral para una Gestión de Inventarios Eficiente</h1>
                  <p className="mb-4">
                    EasyStock revoluciona la gestión de inventarios. Registra movimientos, planifica proyectos
                    y reserva inventario fácilmente. Mantén un control detallado con hojas de vida y realiza
                    mantenimientos preventivos y correctivos. Permite solicitudes de inventario desde dispositivos
                    móviles y accede a información valiosa en tiempo real. Eficiencia y precisión en un solo sistema.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <button
                      aria-label="Contacto"
                      className="btn btn-easystock rounded-pill px-4 me-3 text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#modalContacto">
                      Contáctanos
                    </button>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row g-4">
                    <div className="col-md-6">
                      <div className="row g-4">
                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                          <div
                            className="service-item d-flex flex-column justify-content-center text-center rounded"
                          >
                            <div className="service-icon btn-square">
                              <i className="fa-solid fa-screwdriver-wrench fa-2x"></i>

                            </div>
                            <h5 className="mb-3 h5-easystock">Mantenimiento de Artículos</h5>
                            <p>
                              No solo registramos tus artículos, también te
                              permitimos llevar un control detallado con hojas de
                              vida. Realiza mantenimientos preventivos y correctivos
                              con facilidad, gestionando los seriales de cada
                              referencia.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                          <div
                            className="service-item d-flex flex-column justify-content-center text-center rounded"
                          >
                            <div className="service-icon btn-square">
                              <i className="fa-solid fa-warehouse fa-2x"></i>
                            </div>
                            <h5 className="mb-3 h5-easystock">Reservas de Inventario</h5>
                            <p>
                              Crea proyectos y reserva inventario según tus necesidades.
                              Planifica con anticipación y garantiza que siempre tengas
                              los productos necesarios en el momento adecuado.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 pt-md-4">
                      <div className="row g-4">
                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                          <div
                            className="service-item d-flex flex-column justify-content-center text-center rounded"
                          >
                            <div className="service-icon btn-square">
                              <i className="fa-solid fa-print fa-2x"></i>
                            </div>
                            <h5 className="mb-3 h5-easystock">Consultas y Reportes</h5>
                            <p>
                              Los datos son imprescindibles!, Accede a ellos y encuentra información valiosa con nuestro módulo de consultas y reportes.
                              Visualiza, exporta y/o analiza información en tiempo real para tomar decisiones acertadas.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                          <div
                            className="service-item d-flex flex-column justify-content-center text-center rounded"
                          >
                            <div className="service-icon btn-square">
                              <i className="fa-solid fa-mobile-screen-button fa-2x"></i>
                            </div>
                            <h5 className="mb-3 h5-easystock">Solicitudes en tu móvil</h5>
                            <p>
                              Con EasyStock, tus usuarios pueden solicitar
                              fácilmente EPPs,
                              herramientas o consumibles desde sus
                              dispositivos móviles. <br />Simplifica el proceso y garantiza
                              que siempre tengan lo que necesitan.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
        {/* </Element> */}
        {/* <!-- Características --> */}

        {/* <!-- Por qué EasyStock --> */}
        <div className="container-fluid bg-easystock feature pt-5">
          <div className="container pt-5">
            <div className="row g-5">
              <div
                className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn"
                data-wow-delay="0.3s"
              >
                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">
                  Por qué Easy Stock?
                </div>
                <h1 className="text-white mb-4 h1-easystock">
                  Optimiza tu Gestión de Inventarios con EasyStock
                </h1>
                <p className="text-light mb-4">
                  Simplifica la administración de inventarios y maximiza la eficiencia
                  operativa con EasyStock, la solución integral para empresas de todos los tamaños.
                </p>

                <div className="d-flex align-items-center text-white mb-3">
                  <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <span> Evita pérdidas y optimiza el uso de recursos con un sistema preciso y fácil de usar.</span>
                </div>
                <div className="d-flex align-items-center text-white mb-3">
                  <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <span>Adaptable a las necesidades de tu empresa, EasyStock se expande contigo a medida que creces.</span>
                </div>
                <div className="d-flex align-items-center text-white mb-3">
                  <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <span>Mantén el control total en cualquier lugar y dispositivo, la toma de decisiones será informada y ágil.</span>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <button
                    aria-label="Contacto"
                    className="btn border rounded-pill px-4 me-3 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#modalContacto">
                    Contáctanos
                  </button>
                </div>

              </div>
              <div
                className="col-lg-6 align-self-end text-center text-md-end wow fadeIn player2"
                data-wow-delay="0.5s"
              >
                <Player
                  src="https://lottie.host/a7d482d5-6e0c-4805-b12d-6636aba2ee7c/5cR3IFX5Zi.json"
                  loop
                  autoplay
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Por qué EasyStock --> */}

        {/* <!-- FAQs  --> */}
        <div className="container-fluid bg-light py-5" id="faqs">
          <div className="container py-5">
            <div
              className="mx-auto text-center wow fadeIn"
              data-wow-delay="0.1s"
              style={{ maxWidth: "500px" }}
            >
              <div className="btn btn-sm border rounded-pill text-easystock px-3 mb-3">
                FAQs
              </div>
              <h1 className="mb-4 h1-easystock">Preguntas frecuentes</h1>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="accordion" id="accordionFAQ1">
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        ¿Necesito instalar EasyStock en mi equipo?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionFAQ1"
                    >
                      <div className="accordion-body accordion-easystock">
                        No, EasyStock es una aplicación web multiplataforma, lo que significa que puedes acceder
                        a ella desde cualquier dispositivo con conexión a internet sin necesidad de descargar o
                        instalar software adicional.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.2s">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        ¿Cómo puedo estar seguro de que la información de mi inventario está protegida?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionFAQ1"
                    >
                      <div className="accordion-body accordion-easystock">
                        Garantizamos la seguridad de tu información mediante autenticación segura OAuth 2.0 y utilizando
                        la infraestructura de seguridad de Firebase. Esto asegura que tus datos estén protegidos contra
                        accesos no autorizados.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.3s">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        ¿Puedo obtener una demostración o muestra del software?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionFAQ1"
                    >
                      <div className="accordion-body accordion-easystock">
                        Claro, te proporcionamos un usuario de demostración por 15 días. Esto te permite comenzar a explorar
                        y administrar tu inventario para que puedas experimentar de primera mano la funcionalidad de EasyStock.
                        <div className="d-flex align-items-center mt-4">
                          <button
                            aria-label="preguntas"
                            className="btn btn-easystock rounded-pill px-4 me-3 text-white"
                            data-bs-toggle="modal"
                            data-bs-target="#modalContacto">
                            Contáctanos
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.4s">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        ¿Puedo asignar permisos por usuario?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionFAQ1"
                    >
                      <div className="accordion-body accordion-easystock">
                        ¡Absolutamente! Todos los módulos de EasyStock tienen permisos de acceso que son gestionados por el usuario
                        administrador. Esto te permite controlar quién tiene acceso a qué partes del sistema.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionFAQ2">
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        ¿Cuál es el costo de EasyStock?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionFAQ2"
                    >
                      <div className="accordion-body accordion-easystock">
                        EasyStock se ofrece mediante suscripciones anuales o mensuales. Te invitamos a contactarnos
                        para recibir asesoramiento personalizado y conocer los detalles específicos sobre precios y
                        planes disponibles.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.6s">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        ¿Puedo personalizar los reportes de entrada y salida?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionFAQ2"
                    >
                      <div className="accordion-body accordion-easystock">
                        Sí, nuestros asesores estarán encantados de personalizar los reportes de entrada y salida para que se
                        adapten a tus necesidades específicas. Queremos que obtengas la información que realmente importa
                        para tu negocio.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.7s">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        ¿Qué sucede si no realizo el pago a tiempo?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionFAQ2"
                    >
                      <div className="accordion-body accordion-easystock">
                        Si no realizas el pago a tiempo, tu cuenta se suspenderá automáticamente.
                        Sin embargo, tus datos seguirán estando disponibles durante un período de
                        hasta 6 meses para que puedas acceder y consultar la información cuando
                        decidas reanudar tu membresía.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeIn" data-wow-delay="0.8s">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        aria-label="preguntas"
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        ¿Existe la posibilidad de integrar EasyStock con otros sistemas en el futuro?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionFAQ2"
                    >
                      <div className="accordion-body accordion-easystock">
                        ¡Absolutamente! Estamos enfocados en mejorar constantemente EasyStock y una de
                        nuestras metas es implementar una API de integración para que puedas conectar
                        fácilmente la aplicación con otros sistemas que utilices en tu empresa. Estamos
                        trabajando arduamente para brindarte esta funcionalidad en el futuro cercano y
                        así optimizar la eficiencia de tus operaciones.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FAQs --> */}

        {/* <!-- Footer --> */}
        <div className="container-fluid bg-easystock text-white-50 footer pt-5" id="contactenos">
          <div className="container py-5">
            <div className="row g-5 text-center">
              <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                <img src={logo_white}
                  width="130"
                  alt="logo_white" />
              </div>
              <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                <div className="row align-items-end justify-content-center">
                  <div className="col-4 col-sm-6  text-end">
                    <h1 className="text-white h1-easystock mb-4">by</h1>
                  </div>
                  <div className="col-8 col-sm-6  text-start">
                    <a href="http://livenup.com.co" className="d-inline-block mb-3">
                      <img src={logo_livenUp} width="150" alt="logoLivenUP" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                <p className="mt-4">
                  Easy Stock es un software creado y diseñado por LivenUp Consulting Solutions, si requiere información no dude en contactarnos.
                </p>
              </div>

              <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                {/* <h4 className="text-white mb-1 mt-3">A un toque!</h4> */}
                <p><i className="fa-solid fa-link me-3 mt-3"></i>www.livenup.com.co</p>
                <p><i className="fa fa-phone-alt me-3"></i>+310 583 4876</p>
                <p><i className="fa fa-envelope me-3" />info@livenup.com.co</p>
                <p className="mt-2">
                  <button
                    aria-label="contacto"
                    className="btn btn-sm border rounded-pill px-4 me-4 text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#modalContacto">
                    Contáctanos
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="container wow fadeIn" data-wow-delay="0.1s">
            <div className="copyright">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  &copy; <a className="border-bottom" href="http://easystock.com.co">easystock.com.co</a>, All
                  Right Reserved.
                  {/* Designed By  */}&nbsp;
                  <a className="border-bottom" href="https://livenup.com.co">LivenUP</a>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* <!-- Footer --> */}

        {/* <!-- Back to Top --> */}
        <button
          aria-label="toTop"
          className="btn btn-lg btn-easystock btn-lg-square back-to-top  text-white border-light"
          onClick={scrollToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      </>
      {/* Landing Page */}

      {/* Modal de contacto */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="modalContacto"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content ">

            <div className="container text-center">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="wrapper">
                    <div className="row no-gutters">

                      {/* Formulario */}
                      <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">

                        <div className="contact-wrap w-100 p-md-5 p-4">
                          <h2 className="mb-4 h2-easystock fs-3 easystock-color">Contactemos!</h2>

                          <div className="row justify-content-center">

                            <div className="col-md-12 mb-2">
                              <div className="form-group">
                                <input type="text" className="form-control"
                                  placeholder="Nombre y apellido"
                                  aria-label="Nombre y apellido"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-md-12 mb-2">
                              <div className="form-group">
                                <input type="text" className="form-control"
                                  placeholder="Empresa"
                                  aria-label="Empresa"
                                  value={empresa}
                                  onChange={(e) => setEmpresa(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-md-6 mb-2">
                              <div className="form-group">
                                <input type="email"
                                  className="form-control"
                                  placeholder="E-mail"
                                  aria-label="e-mail"
                                  value={emailForm}
                                  onChange={(e) => setEmailForm(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-md-6 mb-2">
                              <div className="form-group">
                                <input type="email"
                                  className="form-control"
                                  placeholder="Tel"
                                  aria-label="tel"
                                  value={tel}
                                  onChange={(e) => setTel(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-md-12 mb-2">
                              <div className="form-group">
                                <input type="text"
                                  className="form-control"
                                  placeholder="Asunto"
                                  aria-label="Asunto"
                                  value={asunto}
                                  onChange={(e) => setAsunto(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-md-12 mb-2">
                              <div className="form-group">
                                <textarea name="message"
                                  className="form-control"
                                  aria-label="Mensaje"
                                  placeholder="Mensaje"
                                  value={mensaje}
                                  onChange={(e) => setMensaje(e.target.value)} />
                              </div>
                            </div>
                            {emailForm && (
                              <div className="col">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                  onChange={handleRecaptchaChange3}
                                />
                              </div>
                            )}
                            {recaptchaValue3 && emailForm && (
                              <div className="col mt-2">
                                <button
                                  type="button"
                                  className="btn btn-easystock text-white"
                                  data-bs-dismiss="modal"
                                  onClick={() => enviarFormulario()}>
                                  Enviar Formulario
                                </button>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <button type="button" className="btn-close mt-2 me-2 text-end" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                      </div>
                      {/* Formulario */}

                      {/* Datos form */}
                      <div className="col-lg-4 col-md-5 d-flex align-items-stretch ps-0 pe-0">
                        <div className="info-wrap bg-easystock text-white w-100 p-md-5 p-4">
                          <div className="about-img mb-2">
                            <img className="img-fluid" src={logo_white} alt="logoWhite" />
                          </div>
                          <p className="mb-3 text-center"><i className="fa-solid fa-link me-3 mt-3"></i>www.livenup.com.co</p>
                          <p className="mb-3 text-center"><i className="fa fa-envelope me-3" />info@livenup.com.co</p>
                          <p className="mb-3 text-center"><i className="fa fa-phone-alt me-3"></i>310 583 4876</p>
                        </div>
                      </div>
                      {/* Datos form */}

                    </div>
                  </div>
                </div>
              </div>
            </div>






          </div>
        </div>
      </div>
      {/* Modal de contacto */}

    </>
  );
};
