import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";

import {
    actualizarDocumentoDatabase,
    actualizarImagen,
    consultarDatabase,
    eliminarImagen,
    guardarDatabase,
    obtenerURLImagen,
    subirImagen,
} from "../../config/firebase";
import { changeTitle } from "../../config/functions/funGenerales";

export const AdminEmpresa = (listaEmp) => {

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');
    const user = JSON.parse(localStorage.getItem("token"))?.userName;

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    const fecha_modificacion = fechaFormateada;
    const fecha_creacion = fechaFormateada;
    const admin_modificacion = user;
    const admin_creacion = user;

    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [consecutivos, setConsecutivos] = useState([]);
    const [clasif, setClasif] = useState([]);
    const [unidad, setUnidad] = useState([]);

    const [digitoVerificacion, setDigitoVerificacion] = useState(0);
    const [autorizado, setAutorizado] = useState(null);
    const [idDocumento, setIdDocumento] = useState("");
    const [razonSocial, setRazonSocial] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telefono1, setTelefono1] = useState("");
    const [telefono2, setTelefono2] = useState("");
    const [idEmpresa, setIdEmpresa] = useState("");
    const [licencias, setLicencias] = useState(0);
    const [adicionar, setAdicionar] = useState(0);
    const [correo, setCorreo] = useState("");
    const [url2, setUrl2] = useState("");
    const [logo, setLogo] = useState("");
    const [url1, setUrl1] = useState("");
    const [nit, setNit] = useState("");


    const [col2, setCol2] = useState(true);
    const [col3, setCol3] = useState(true);
    const [col4, setCol4] = useState(true);
    const [col5, setCol5] = useState(true);
    const [col6, setCol6] = useState(true);
    const [col7, setCol7] = useState(true);
    const [col8, setCol8] = useState(true);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);
    const inputFile = useRef(null);
    const tablaRef = useRef(null);

    const datos = [
        { clase: "DOCUMENTO", descripcion: "AJUSTE INVENTARIO", prefijo: "AJIN", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "INVENTARIO", prefijo: "INV", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "MOVIMIENTO", prefijo: "MOV", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "ASIGNACION", prefijo: "ASG", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "SOLICITUD", prefijo: "SOL", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "COMPRA", prefijo: "COM", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "VENTA", prefijo: "VEN", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
        { clase: "DOCUMENTO", descripcion: "BAJA", prefijo: "BJA", valorActual: 0, valorFinal: 9999, valorInicial: 0, estado: "ACTIVO" },
    ];

    const datos2 = [
        { descripcionClasificacion: "PROVEEDOR", tipoClasificacion: "3", estado: "ACTIVO" },
        { descripcionClasificacion: "FAMILIA", tipoClasificacion: "2", estado: "ACTIVO" },
        { descripcionClasificacion: "MARCA", tipoClasificacion: "1", estado: "ACTIVO" },
    ];
    const datos3 = [
        { codigo: "UND", descripcion: "UNIDAD", estado: "ACTIVO" },
        { codigo: "MT", descripcion: "METRO", estado: "ACTIVO" },
        { codigo: "CM", descripcion: "CENTIMETRO", estado: "ACTIVO" },
    ];

    const emp = idEmpresa
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [rutaEnFirestore, setRutaEnFirestore] = useState("");
    const [countOrdenar, setCountOrdenar] = useState(0);
    const rutaEmpresa = "gs://mn-app-60991.appspot.com";
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [deleteImg, setDeleteImg] = useState(false);
    const [imagenURL, setImagenURL] = useState("");
    const [existe, setExiste] = useState(null);


    //************* Funciones *****************/
    useEffect(() => {
        changeTitle("Admin Empresa");
        buscarEmpresas()
        buscarColumnas()
        crearConsecDefault()
        crearClasifDefault()
        crearUnidadDefault()
        validarMobil()
    }, []);

    const validarMobil = () => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    };

    useEffect(() => {
        if (adicionar === 1) {
            resetStates();
        }
    }, [adicionar]);

    //---------Varios-----------------
    const buscarColumnas = () => {
        setCol2(localStorage.getItem(user + "/empresa/2") === "false" ? false : true);
        setCol3(localStorage.getItem(user + "/empresa/3") === "false" ? false : true);
        setCol4(localStorage.getItem(user + "/empresa/4") === "false" ? false : true);
        setCol5(localStorage.getItem(user + "/empresa/5") === "false" ? false : true);
        setCol6(localStorage.getItem(user + "/empresa/6") === "false" ? false : true);
        setCol7(localStorage.getItem(user + "/empresa/7") === "false" ? false : true);
        setCol8(localStorage.getItem(user + "/empresa/8") === "false" ? false : true);
    };

    const buscarEmpresas = async () => {
        setIsLoading(true)
        try {
            const listaTemporal = await consultarDatabase("empresas");
            setListaEmpresas(listaTemporal.sort(sortBy("nit")))
            setDocumentosFiltrados(listaTemporal.sort(sortBy("nit")))
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    };

    const crearConsecDefault = async () => {
        const objetos = [];
        for (const dato of datos) {
            const nuevoObjeto = {
                id: uniqid("consecutivo-"),
                clase: dato.clase,
                descripcion: dato.descripcion,
                fecha_creacion: fechaFormateada,
                prefijo: dato.prefijo,
                usuario_creacion: admin_creacion,
                valorActual: parseInt(dato.valorActual),
                valorFinal: parseInt(dato.valorFinal),
                valorInicial: parseInt(dato.valorInicial),
                estado: dato.estado,
            };
            objetos.push(nuevoObjeto);
        }
        setConsecutivos(objetos)
    };

    const guardarConsecutivos = async () => {
        for (const elemento of consecutivos) {
            try {
                await guardarDatabase(idEmpresa + "_consecutivos", elemento);
            } catch (error) {
                console.error(error)
            }
        }
    };

    const crearClasifDefault = async () => {
        const objetos = [];
        for (const dato of datos2) {
            const nuevoObjeto = {
                id: uniqid("clasificacion-"),
                descripcionClasificacion: dato.descripcionClasificacion,
                estado: dato.estado,
                lista: [],
                tipoClasificacion: dato.tipoClasificacion,
                fecha_creacion: fechaFormateada,
                usuario_creacion: admin_creacion,
            };
            objetos.push(nuevoObjeto);
        }
        setClasif(objetos)
    };

    const guardarClasificaciones = async () => {
        for (const elemento of clasif) {
            try {
                await guardarDatabase(idEmpresa + "_clasificaciones", elemento);
            } catch (error) {
                console.error(error)
            }
        }
    };

    const crearUnidadDefault = async () => {
        const objetos = [];
        for (const dato of datos3) {
            const nuevoObjeto = {
                id: uniqid("unidad-"),
                codigo: dato.codigo,
                descripcion: dato.descripcion,
                estado: dato.estado,
                fecha_creacion: fechaFormateada,
                usuario_creacion: admin_creacion,
            };
            objetos.push(nuevoObjeto);
        }
        setUnidad(objetos)
    };

    const guardarUnidades = async () => {
        for (const elemento of unidad) {
            try {
                await guardarDatabase(idEmpresa + "_unidades", elemento);
            } catch (error) {
                console.error(error)
            }
        }
    };

    //---------CRUD-----------------
    const handleClickAdicionar = async () => {
        const respuesta = listaEmpresas.filter((e) => e.correo === correo || e.nit === nit || e.razonSocial === razonSocial.toUpperCase() || e.idEmpresa === idEmpresa)

        if (respuesta.length >= 1) {
            resetStates();
            toast.warning("Ya existe una empresa registrada con Razon Social : " + razonSocial.toUpperCase() + ", o con NIT: " + nit + " o con E-mail:  " + correo.toUpperCase() + " o con Id Empresa: " + idEmpresa.toUpperCase() + ", por favor verifique e intente de nuevo");
        } else {
            const enviarEmpresa = {
                id: uniqid("empresa-"), digitoVerificacion: parseInt(digitoVerificacion),
                idEmpresa, nit, razonSocial, direccion, correo, telefono1, telefono2,
                url1, url2, logo, autorizado, fecha_creacion, admin_creacion, licencias
            };

            if (nit === "" || razonSocial === "" || direccion === "" || digitoVerificacion === "" || idEmpresa === "") {
                toast.warning("Debe diligenciar todos lo campos para completar el registro");
            } else {
                try {
                    await guardarDatabase("empresas", enviarEmpresa);
                    toast.success("La empresa  " + razonSocial.toUpperCase() + " se ha registrado de manera correcta");
                    await handleSubirImagen();
                    await guardarConsecutivos();
                    await guardarClasificaciones();
                    await guardarUnidades();
                    resetStates();
                    clearInputFile();
                    await buscarEmpresas();
                } catch (error) {
                    console.error(error)
                }
            }
        }
    };

    const handleClickTraerData = async (e) => {
        setNit(e.nit);
        setDigitoVerificacion(e.digitoVerificacion);
        setRazonSocial(e.razonSocial);
        setDireccion(e.direccion);
        setCorreo(e.correo);
        setTelefono1(e.telefono1);
        setTelefono2(e.telefono2);
        setUrl1(e.url1);
        setUrl2(e.url2);
        setLogo(e.logo);
        setIdEmpresa(e.idEmpresa);
        setAutorizado(e.autorizado);
        setIdDocumento(e.idDocumento);
        setLicencias(e.licencias)
    };

    const handleClickModificarBd = async () => {
        const enviarArticulo = {
            idDocumento, razonSocial, direccion, correo, telefono1, telefono2,
            url1: url1, url2: url2, admin_modificacion, fecha_modificacion, licencias
        };
        if (idDocumento !== "" && idDocumento !== undefined) {
            try {
                await actualizarDocumentoDatabase("empresas", idDocumento, enviarArticulo);
                toast.success("Empresa Modificada correctamente");
                resetStates();
                await buscarEmpresas();
            } catch (error) {
                console.error(error)
            }
        } else {
            toast.warning("La empresa no se encuentra registrada");
        }
    };

    //-----------------------------------
    const CambioEstado = async () => {
        const valor = {
            autorizado: !autorizado,
            adminModificacion: admin_modificacion,
            fecha_modificacion: fecha_modificacion
        };
        try {
            await actualizarDocumentoDatabase("empresas", idDocumento, valor);
            toast.success("Cambio de estado en autorizacion exitoso, comuníqueselo ala empresa.");
            buscarEmpresas();
        } catch (error) {
            console.error(error)
        }
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        const value = parseInt(e.target.value, 10);
        if (input.length > 1) {
            toast.warning("Este campo permite solo un dígito entre 0 y 9.")
            setDigitoVerificacion("");
        } else if (!isNaN(value) && value >= 0 && value <= 9) {
            setDigitoVerificacion(value);
        }
    };

    const resetStates = () => {
        setNit("")
        setDigitoVerificacion("")
        setRazonSocial("")
        setDireccion("")
        setCorreo("")
        setTelefono1("")
        setTelefono2("")
        setUrl1("")
        setUrl2("")
        setLogo("")
        setAutorizado(null)
        setIdEmpresa("")
        setLicencias(0)
    };

    //-----------------------------------
    const agregarColumna = (tabla, columna, valor) => {
        localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
    };

    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas]);

    const ordenar = (columna) => {
        const direccion = (countOrdenar % 2 === 0 ? "" : "-")
        const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
        setListaEmpresas(temporal);
        setCountOrdenar(countOrdenar + 1)
    };

    //--------- CRUD imágenes-----------
    const handleImageSelect = (event, tipo) => {
        if (tipo === 1) {
            const image = event.target.files[0];
            if (image) {
                const maxSize = 204800;
                if (image.size > maxSize) {
                    toast.warning('Imagen Supera el tamaño permitido')
                    event.target.value = ''
                } else {
                    setImagenSeleccionada(image);
                    setRutaEnFirestore(`${rutaEmpresa}/${emp}/logo_${emp}/${image.name}`)
                    setLogo(`${rutaEmpresa}/${emp}/logo_${emp}/${image.name}`)
                }
            }
        } else if (tipo === 2) {
            const image = event.target.files[0];
            if (image) {
                const maxSize = 204800;
                if (image.size > maxSize) {
                    toast.warning('Imagen Supera el tamaño permitido')
                    event.target.value = ''
                } else {
                    setImagenSeleccionada(image);
                }
            }
        }
    };

    const handleSubirImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await subirImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue cargada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        }
    };

    const handleEliminarImagen = async () => {
        if (rutaEnFirestore) {
            try {
                await eliminarImagen(rutaEnFirestore);
                setImagenURL("");
                toast.success("La imagen fue eliminada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        }
    };

    const handleActualizarImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await actualizarImagen(rutaEnFirestore, imagenSeleccionada);
                toast.success("La imágen fue actualizada correctamente")
                setImagenURL("");
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        } else {
            setImagenURL("");
            setImagenSeleccionada("")
            toast.warning("Hubo un error al tratar de actualizar la imágen, intente nuevamente.")
        }
    };

    const handleObtenerURLImagen = async (ruta) => {
        setImagenURL("");
        if (ruta !== undefined) {
            try {
                const url = await obtenerURLImagen(ruta);
                setImagenURL(url);
                setRutaEnFirestore(url)
                setExiste(true)
            } catch (error) {
                toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
                setExiste(false)
                setRutaEnFirestore(ruta)
            }
        }
    };

    const clearInputFile = () => {
        inputFile.current.value = "";
    };

    const limpiar = () => {
        setOcultarBtn(false)
        setUpdateImg(false)
        setDeleteImg(false)
    };


    /********** Componente ***************/
    return (
        <>
            <ToastContainer position="bottom-right" />
            {isLoading ?
                <LoaderES />
                : <>
                    <div className="fixed-top mt-2"
                        style={{
                            backgroundColor: "white",
                            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
                            transition: "margin-left 0.3s ease",
                            top: "46px",
                            zIndex: 1
                        }}>

                        {/* botones de opcion */}
                        <div className="row ms-2 me-2">
                            <div className="col-sm-4 align-self-start text-start">
                                <div className="btn-group">

                                    {/* Adicionar */}
                                    <button
                                        className="btn fa-solid fa-plus"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                        id="modaladicionar"
                                        title="Crear Nueva Empresa"
                                        onClick={() => {
                                            resetStates()
                                            setAdicionar(1);
                                            setAutorizado(true);
                                        }} />

                                    {/* Columnas */}
                                    <button
                                        className="btn fa-solid fa-table-columns"
                                        title="Seleccionar Columnas"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarColumnas"
                                        id="modalcolumnas" />

                                    {/* Actualizar */}
                                    <button
                                        className="btn fa-solid fa-rotate"
                                        title="Actualizar Datos"
                                        onClick={buscarEmpresas} />
                                </div>
                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar2
                                    tabla="myTable"
                                    documentos={listaEmpresas}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={"text-danger mt-5 " + (mobile ? " mb-5" : "")} />

                    {mobile ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual?.map((empresa) => (
                                empresa.consecutivo === "Sin Consecutivo" ?
                                    <button
                                        className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                        title="Crear Nueva Empresa"
                                        id="modaladicionar"
                                        onClick={() => setAdicionar(1)} />
                                    :
                                    <div key={empresa.nit + empresa.id}>
                                        <table className="table table-sm table-hover table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px", resize: "none" }}>
                                                        <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#collapseItem" + empresa.nit}
                                                            aria-expanded="false"
                                                            aria-controls="collapseItem" />
                                                    </th>

                                                    <th style={{ resize: "none", width: "15px" }}>
                                                        {empresa.idEmpresa}<sup>{empresa.licencias}</sup>
                                                    </th>

                                                    <th style={{ resize: "none", width: "5px" }}>
                                                        <i className="fa-solid fa-arrow-right"></i>
                                                    </th>
                                                    <td
                                                        style={{
                                                            minWidth: '60px',
                                                            maxWidth: '150px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            resize: 'none',
                                                        }} className={(!empresa.autorizado) ? "text-danger" : ""}>
                                                        <span className="truncate-text">
                                                            {empresa.razonSocial > 50 ? empresa.razonSocial.slice(0, 50) + "..." : empresa.razonSocial}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>

                                        <div className="collapse" id={"collapseItem" + empresa.nit}>
                                            <div className="card card-body">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ resize: "none" }}>NIT </th>
                                                            <td>{empresa.nit + "-" + empresa.digitoVerificacion}</td>
                                                        </tr>

                                                        {!col3 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Razón Social </th>
                                                                <td>{empresa.razonSocial}</td>
                                                            </tr>
                                                        }

                                                        {!col4 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Teléfono </th>
                                                                <td>{empresa.telefono1}</td>
                                                            </tr>
                                                        }

                                                        {!col5 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Correo </th>
                                                                <td>{empresa.correo}</td>
                                                            </tr>
                                                        }

                                                        {!col6 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Autorizado </th>
                                                                <td className={(!empresa.autorizado) ? "text-danger" : ""}>{empresa.autorizado ? "SI" : "NO"}</td>
                                                            </tr>
                                                        }

                                                        {!col7 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Alias </th>
                                                                <td>{empresa.idEmpresa}</td>
                                                            </tr>
                                                        }

                                                        {!col8 ? null :
                                                            <tr>
                                                                <th style={{ resize: "none" }}>Licencias </th>
                                                                <td>{empresa.licencias}</td>
                                                            </tr>
                                                        }

                                                        <tr>
                                                            <th style={{ resize: "none" }}>Actions </th>
                                                            <td>
                                                                <div className="btn-group">

                                                                    {/* Btn Imagen logo */}
                                                                    <button
                                                                        className="btn btn-outline-success fa-solid fa-eye border-0 btn-sm"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#imagen"
                                                                        onClick={() => {
                                                                            handleObtenerURLImagen(empresa.logo)
                                                                        }} />

                                                                    {/* Btn Modificar */}
                                                                    <button
                                                                        className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#ModificarAdicionar"
                                                                        id="modalModificar"
                                                                        title="Modificar Item Seleccionado"
                                                                        onClick={() => {
                                                                            handleClickTraerData(empresa);
                                                                            setAdicionar(0);
                                                                        }} />

                                                                    {/* Btn  password */}
                                                                    <button
                                                                        className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole border-0"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#pass"
                                                                        id="modalPass"
                                                                        title="Cambiar Permiso de Acceso"
                                                                        onClick={() => {
                                                                            handleClickTraerData(empresa);
                                                                        }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        <div className="table-responsive abs-center-table ">
                            <table id="myTable" ref={tablaRef}
                                className="table table-hover table-striped table-sm table-bordered mt-3" >
                                <thead className="text-center">
                                    <tr>
                                        <th onClick={() => ordenar("nit")}>NIT</th>
                                        <th onClick={() => ordenar("digitoVerificacion")} className={col2 ? "" : "d-none"}>Dig. Verif</th>
                                        <th onClick={() => ordenar("razonSocial")} className={col3 ? "" : "d-none"}>Razon Social</th>
                                        <th onClick={() => ordenar("telefono1")} className={col4 ? "" : "d-none"}>Teléfono</th>
                                        <th onClick={() => ordenar("correo")} className={col5 ? "" : "d-none"}>Correo</th>
                                        <th onClick={() => ordenar("autorizado")} className={col6 ? "" : "d-none"}>Autorizado</th>
                                        <th onClick={() => ordenar("idEmpresa")} className={col7 ? "" : "d-none"}>Alias</th>
                                        <th onClick={() => ordenar("licencias")} className={col8 ? "" : "d-none"}>Licencias</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-center" id="tabla_empresas">
                                    {registrosPaginaActual.map((empresa) => (
                                        <tr key={empresa.id}>
                                            <td>{empresa.nit}</td>
                                            <td className={col2 ? "" : "d-none"}>{empresa.digitoVerificacion}</td>
                                            <td className={(!empresa.autorizado && col3) ? "text-danger" : (!empresa.autorizado && !col3) ? "d-none" : (col3) ? "" : "d-none"}>{empresa.razonSocial.toUpperCase()}</td>
                                            <td className={col4 ? "" : "d-none"}>{empresa.telefono1}</td>
                                            <td className={col5 ? "" : "d-none"}>{empresa.correo.toUpperCase()}</td>
                                            <td className={(!empresa.autorizado && col6) ? "text-danger" : (!empresa.autorizado && !col6) ? "d-none" : (col6) ? "" : "d-none"}>{empresa.autorizado ? "SI" : "NO"}</td>
                                            <td className={col7 ? "" : "d-none"}>{empresa.idEmpresa}</td>
                                            <td className={col8 ? "" : "d-none"}>{empresa.licencias}</td>
                                            <td className="text-center">
                                                <div className="btn-group">

                                                    {/* +Btn IMG */}
                                                    <button className="fa-solid btn btn-sm btn-outline-success fa-eye"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#imagen"
                                                        onClick={() => {
                                                            handleObtenerURLImagen(empresa.logo)
                                                        }} />

                                                    {/* +Btn modificar */}
                                                    <button
                                                        className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModificarAdicionar"
                                                        id="modalModificar"
                                                        title="Modificar Item Seleccionado"
                                                        onClick={() => {
                                                            handleClickTraerData(empresa);
                                                            setAdicionar(0);
                                                        }} />

                                                    {/* +Btn passw */}
                                                    <button
                                                        className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#pass"
                                                        id="modalPass"
                                                        title="Cambiar Permiso de Acceso"
                                                        onClick={() => {
                                                            handleClickTraerData(empresa);
                                                        }} />

                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        /* Desktop */
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />


                    {/* +Modal de Modificar // Adicionar*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarAdicionar"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">

                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title">
                                        {adicionar === 1 ? "Crear " : "Modificar "} Empresa
                                    </h5>
                                </div>

                                <div className="modal-body">
                                    {/* Inputs NIT y digito verificacion */}
                                    <div className="row">
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="nit">NIT</span>
                                            <input type="number" className="form-control" id="nit"
                                                disabled={adicionar !== 1}
                                                value={nit}
                                                onChange={(e) => setNit(e.target.value)} />
                                        </div>

                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="verificacionNit">Dígito de Verificación</span>
                                            <input type="number" className="form-control" id="verificacionNit"
                                                disabled={adicionar !== 1}
                                                min={0}
                                                max={9}
                                                value={digitoVerificacion}
                                                onChange={handleInputChange} />
                                        </div>
                                    </div>

                                    <div className="input-group input-group-sm mb-2 col">
                                        <span className="input-group-text" htmlFor="razonSocial">Razón Social</span>
                                        <input type="text" className="form-control" id="razonSocial"
                                            value={razonSocial}
                                            onChange={(e) => setRazonSocial(e.target.value)} />
                                    </div>

                                    {/* Input razón social y alias */}
                                    <div className="row">
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="idEmpresa">ID Empresa</span>
                                            <input type="text" className="form-control" id="idEmpresa"
                                                disabled={!adicionar}
                                                placeholder="Alias"
                                                value={idEmpresa}
                                                onChange={(e) => setIdEmpresa(e.target.value)} />
                                        </div>

                                        {/* Input Seleccione logo */}
                                        {adicionar ?
                                            <div className="input-group input-group-sm mb-2 col">
                                                <span className="input-group-text" htmlFor="logo">Logo</span>
                                                <input type="file" accept="image/*"
                                                    onChange={(e) => handleImageSelect(e, 1)} className="form-control" ref={inputFile} />
                                            </div>
                                            :
                                            null}
                                    </div>

                                    {/* Inputs direccion y correo */}
                                    <div className="input-group input-group-sm mb-2 col">
                                        <span className="input-group-text" htmlFor="direccion">Dirección</span>
                                        <input type="text" className="form-control" id="direccion"
                                            value={direccion}
                                            onChange={(e) => setDireccion(e.target.value)} />
                                    </div>

                                    <div className="row">
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="email">E-mail</span>
                                            <input type="email" className="form-control" id="email"
                                                value={correo}
                                                onChange={(e) => setCorreo(e.target.value)} />
                                        </div>

                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="email">Licencias</span>
                                            <input type="number" className="form-control" id="licencias"
                                                min={0}
                                                max={1000}
                                                value={licencias}
                                                onChange={(e) => setLicencias(e.target.value)} />
                                        </div>
                                    </div>

                                    {/* Inputs telefono 1 y 2*/}
                                    <div className="row">

                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="telefono1">Teléfono 1</span>
                                            <input type="number" className="form-control" id="telefono1"
                                                value={telefono1}
                                                min={0}
                                                onChange={(e) => {
                                                    setTelefono1(e.target.value)
                                                    setLogo(rutaEnFirestore)
                                                }} />
                                        </div>

                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="telefono2">Teléfono 2</span>
                                            <input type="number" className="form-control" id="telefono2"
                                                value={telefono2}
                                                min={0}
                                                onChange={(e) => setTelefono2(e.target.value)} />
                                        </div>
                                    </div>

                                    {/* Inputs URL 1 y 2*/}
                                    <div className="row">

                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="url1">URL 1</span>
                                            <input type="url" className="form-control" id="url1"
                                                value={url1}
                                                onChange={(e) => setUrl1(e.target.value)} />
                                        </div>
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text" htmlFor="url2">URL 2</span>
                                            <input type="url" className="form-control" id="url2"
                                                value={url2}
                                                onChange={(e) => setUrl2(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={() =>
                                            adicionar === 1
                                                ? handleClickAdicionar()
                                                : handleClickModificarBd()
                                        } >
                                        {adicionar === 1 ? "Crear" : "Modificar"}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal" >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal permiso cambio clave */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="pass"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={`modal-header ${autorizado ? "bg-danger" : "bg-primary"} text-light`}>
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Cambio de Permiso de Acceso
                                    </h5>
                                </div>

                                <div className="modal-body">
                                    <div className="modal-body">
                                        <p>
                                            {autorizado
                                                ? `Desea denegar el acceso al aplicativo a la empresa: ${razonSocial} ?`
                                                : `Desea aprobar el acceso al aplicativo a la empresa: ${razonSocial} ?`
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-footer">

                                    <button
                                        type="button"
                                        className={autorizado ? "btn btn-danger" : "btn btn-primary"}
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={() => CambioEstado()}>
                                        {autorizado ? "Sí, Denegar" : "Sí, Aprobar"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal" >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal permiso cambio clave */}

                    {/* +Modal Columnas */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarColumnas"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Selección de columnas
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col2}
                                                onChange={(e) => {
                                                    setCol2(e.target.checked)
                                                    agregarColumna("empresa", 2, e.target.checked)
                                                }}
                                                id="clasif1" />
                                            <label className="form-check-label"
                                                htmlFor="clasif1">
                                                Dig. Verificación
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col3}
                                                onChange={(e) => {
                                                    setCol3(e.target.checked)
                                                    agregarColumna("empresa", 3, e.target.checked)
                                                }}
                                                id="clasif2" />
                                            <label className="form-check-label"
                                                htmlFor="clasif2">
                                                Razon Social
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col4}
                                                onChange={(e) => {
                                                    setCol4(e.target.checked)
                                                    agregarColumna("empresa", 4, e.target.checked)
                                                }}
                                                id="clasif3" />
                                            <label className="form-check-label"
                                                htmlFor="clasif3">
                                                Teléfono
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col5}
                                                onChange={(e) => {
                                                    setCol5(e.target.checked)
                                                    agregarColumna("empresa", 5, e.target.checked)
                                                }}
                                                id="clasif4" />
                                            <label className="form-check-label"
                                                htmlFor="clasif4">
                                                Correo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col6}
                                                onChange={(e) => {
                                                    setCol6(e.target.checked)
                                                    agregarColumna("empresa", 6, e.target.checked)
                                                }}
                                                id="clasif5" />
                                            <label className="form-check-label"
                                                htmlFor="clasif5">
                                                Autorizado
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col7}
                                                onChange={(e) => {
                                                    setCol7(e.target.checked)
                                                    agregarColumna("empresa", 7, e.target.checked)
                                                }}
                                                id="clasif6" />
                                            <label className="form-check-label"
                                                htmlFor="clasif6">
                                                Alias
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col8}
                                                onChange={(e) => {
                                                    setCol8(e.target.checked)
                                                    agregarColumna("empresa", 8, e.target.checked)
                                                }}
                                                id="clasif7" />
                                            <label className="form-check-label"
                                                htmlFor="clasif7">
                                                Licencias
                                            </label>
                                        </div>


                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Columnas */}

                    {/* +Modal Imagen */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="imagen"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className={`modal-header ${deleteImg ? "bg-danger" : "bg-primary"} text-light`}>
                                    <h3 className="modal-title" id="staticBackdropLabel3">
                                        Logo empresa
                                    </h3>
                                </div>

                                <div className="modal-body">
                                    {!deleteImg && !updateImg && existe ?
                                        <>
                                            <img src={imagenURL} alt="Mi imagen" style={{
                                                "display": "block", "marginLeft": "auto",
                                                "marginRight": "auto", "width": "40%",
                                                "height": "auto"
                                            }} />
                                        </>
                                        :
                                        updateImg || !existe ?
                                            <>
                                                <div className="container mt-3 ">
                                                    <div className="row text-center">
                                                        <h2 className={existe ? "mb-3 fs-5 text-primary" : "mb-3 fs-5 text-success"}>{existe ? "Modificar Imágen" : "Cargara imágen"}</h2>

                                                        <div className="mb-3 row">
                                                            <label className="col-sm-3 col-form-label">
                                                                Seleccione su nueva imágen:
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <div className="input-group input-group-sm mb-2 ">
                                                                    <input type="file" accept="image/*"
                                                                        onChange={(e) => handleImageSelect(e, 2)} className="form-control" ref={inputFile} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {imagenSeleccionada ?
                                                            <div className="col text-center">
                                                                <button className="btn btn-primary fa-solid fa-floppy-disk"
                                                                    type="button"
                                                                    data-bs-dismiss="modal"
                                                                    onClick={() => {
                                                                        if (imagenSeleccionada) {
                                                                            handleActualizarImagen()
                                                                            limpiar()
                                                                        } else {
                                                                            toast.warning("Debe seleccionar un archivo para guardar, intente nuevamente")
                                                                        }
                                                                    }} />
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            </> : <>
                                                <div className="container mt-3">
                                                    <div className="row text-center">
                                                        <p className="mb-3"> Esta seguro de eliminar esta imágen? <br /><span className="text-danger">Recuerde que esta accion no se puede revertir.</span></p>
                                                        <img src={imagenURL} alt="Mi imagen" style={{
                                                            "display": "block", "marginLeft": "auto",
                                                            "marginRight": "auto", "width": "20%",
                                                            "height": "auto"
                                                        }} />
                                                    </div>
                                                    <div className="row text-center mt-3">
                                                        <div className="col">
                                                            <div className="col">
                                                                <button className="btn btn-sm btn-danger"
                                                                    title="Confirmar Eliminacion Imágen"
                                                                    data-bs-dismiss="modal"
                                                                    onClick={() => {
                                                                        handleEliminarImagen()
                                                                        limpiar()
                                                                    }} >Sí, Eliminar</button>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col">
                                                                <button className="btn  btn-secondary fa-solid fa-ban"
                                                                    title="Cancelar"
                                                                    onClick={() => {
                                                                        limpiar()
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                    {/* Eliminar o modificar imagen */}
                                    <div className="container mt-4">
                                        <div className="row text-center">
                                            {existe ?
                                                <>
                                                    {ocultarBtn ? null : <>
                                                        <div className="col">
                                                            <button className="btn btn-sm  btn-danger fa-solid fa-trash-can" title="Eliminar Imagen" onClick={() => {
                                                                setDeleteImg(true)
                                                                setOcultarBtn(true)
                                                            }} />
                                                        </div>
                                                    </>}

                                                    {ocultarBtn ? null : <>
                                                        <div className="col">
                                                            <button className="btn btn-sm btn-primary fa-solid fa-pen-to-square" title="Cambiar Imagen" onClick={() => {
                                                                setUpdateImg(true)
                                                                setOcultarBtn(true)
                                                            }} />
                                                        </div>
                                                    </>}
                                                </>
                                                : null}

                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setImagenSeleccionada("")
                                            limpiar()
                                        }} >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>}
        </>)
}
