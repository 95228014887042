import { Document, Image, PDFViewer, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

export const DocumentoPDF = () => {

  const infoPDF = JSON.parse(localStorage.getItem('myData'));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [data, setData] = useState([])
  const accion = infoPDF && infoPDF.a;
  const cons = infoPDF && infoPDF.c;
  const datos = infoPDF && infoPDF.d;
  const emp = infoPDF && infoPDF.e;

  const [contProyectoD, setContProyectoD] = useState(0);
  const [contProyectoS, setContProyectoS] = useState(0);
  const [contProyecto, setContProyecto] = useState(0);
  const [contBodegaS, setContBodegaS] = useState(0);
  const [contBodegaD, setContBodegaD] = useState(0);
  const [contBodega, setContBodega] = useState(0);
  const [superTotal, setSuperTotal] = useState(0);
  const [solicitante, setSolicitante] = useState("");
  const [imagenURL, setImagenURL] = useState("");
  const [fechaS, setFechaS] = useState("");
  const [estado, setEstado] = useState("");
  const [fecha, setFecha] = useState("");
  const [tipo, setTipo] = useState("");

  const styles = StyleSheet.create({
    // image: {
    // display: "block",
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: 40,
    // height: "auto"
    // },

    page: {
      fontFamily: "Helvetica",
      padding: 30,
      fontSize: 15,
    },

    title: {
      fontSize: 18,
      marginBottom: 30,
      textAlign: "center",
    },

    //--------- secciones ------------
    sectionEmpresa: {
      flexDirection: "row",
      marginBottom: 15,
    },

    sectionTabla: {
      flexDirection: "row",
      marginBottom: 5,
    },
    elementoHijo: {
      flex: 1,  // Ocupa todo el espacio disponible
      margin: 5, // Espacio entre elementos
    },
    section1: {
      flexDirection: "column",
      marginTop: 5,
      marginBottom: 5
    },

    //-------- labels ---------------
    label: {
      fontWeight: "extrabold",
      marginRight: 5,
    },

    labelFechaCarga: {
      fontSize: 13,
      fontWeight: "hairline",
      // marginLeft: 15,
    },

    labelConsecutivo: {
      fontSize: 13,
      fontWeight: "hairline",
      // marginLeft: 240,
    },
    //------------ solicitudes -----------
    labelSol: {
      fontSize: 10,
      fontWeight: "hairline",
      // marginLeft: 14,
    },

    //--------- datos empresa ----------
    nit: {
      fontSize: 8,
      fontWeight: "ultralight",
      marginLeft: 6,
      marginTop: 4,
    },

    fecha: {
      marginTop: 15,
      marginLeft: 250,
      fontSize: 10,
      fontWeight: "hairline",
    },
    empresa: {
      fontSize: 20,
      display: "flex",
      marginLeft: 5,
    },
    //--------- Contenedor ppal -----------
    viewer: {
      width: "100%",
      height: "90vh",
    },
    //--------- Tabla -----------
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000",
    },

    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
    },

    tableHeader: {
      flex: 5,
      backgroundColor: "#f2f2f2",
      fontWeight: "extrabold",
      padding: 5, // Aumentar el padding para que el encabezado sea más grande
      textAlign: "center",
      fontSize: 10, // Ajustar el tamaño de fuente del encabezado
    },

    tableCell2: {
      flex: 5,
      padding: 5, // Aumentar el padding para que el contenido sea más grande
      textAlign: "center",
      fontSize: 7, // Ajustar el tamaño de fuente del contenido
    },
    total: {
      marginTop: 5,
      fontWeight: "bold",
      fontSize: 12
    },
    //--------- articulos -----------
    articulos: {
      minWidth: "130px",
    },

    factura: {
      minWidth: "18px",
    },
  });

  const manipularCabecerasTabla = (lista, tipo) => {
    if (tipo === "Movimiento" || tipo === "Asignacion") {
      let cPS = 0, cBS = 0, prefijo;

      Object.keys(lista).forEach((art) => {
        prefijo = lista[art].detalle
        if (prefijo.proyectoSalida !== "" && prefijo.proyectoSalida !== undefined) {
          cPS = cPS + 1
        }
        if (prefijo.bodegaSalida !== "" && prefijo.bodegaSalida !== undefined) {
          cBS = cBS + 1
        }
      })
      setContBodegaS(cBS)
      setContProyectoS(cPS)

      let cPD = 0, cBD = 0;
      Object.keys(lista).forEach((art) => {
        prefijo = lista[art].detalle
        if (prefijo.proyectoDestino !== "" && prefijo.proyectoDestino !== undefined) {
          cPD = cPD + 1
        }
        if (prefijo.bodegaDestino !== "" && prefijo.bodegaDestino !== undefined) {
          cBD = cBD + 1
        }
      })
      setContBodegaD(cBD)
      setContProyectoD(cPD)
    } else {
      let cP = 0, cB = 0, prefijo;
      Object.keys(lista).forEach((art) => {
        prefijo = lista[art].detalle
        if (prefijo.proyecto !== "" && prefijo.proyecto !== undefined) {
          cP = cP + 1
        }
        if (prefijo.bodega !== "" && prefijo.bodega !== undefined) {
          cB = cB + 1
        }
      })
      setContBodega(cB)
      setContProyecto(cP)
    }
  };

  const calcularSupertotal = () => {
    let sTotal = 0;
    Object.keys(data).forEach((art) => {
      const valorTotalArticulo = data[art].detalle.valor * data[art].detalle.cantidad;
      sTotal += valorTotalArticulo;
    })
    setSuperTotal(sTotal);
  };

  const obtenerFechaConsec = () => {
    let fec, est, tipo, sol
    Object.keys(datos).forEach((art) => {
      fec = datos[art]?.fecha_creacion
      sol = datos[art].detalle.solicitante
      est = datos[art].detalle.estado
      tipo = datos[art].detalle.tipo
    })
    setFechaS(fec)
    setSolicitante(sol)
    setTipo(tipo)
    setEstado(est)
  };

  const obtenerFecha = () => {
    let fec
    Object.keys(data).forEach((art) => {
      fec = data[art].detalle.fecha_creacion
    })
    setFecha(fec)
  };

  //---------- useEffect data ---------------
  useEffect(() => {
    obtenerFecha()
    if (accion !== "Solicitudes") {
      manipularCabecerasTabla(data, accion);
      calcularSupertotal();
    } else {
      obtenerFechaConsec()
    }
  }, [data]);

  //---------- useEffect procesar datos ---------------
  useEffect(() => {
    const d = datos.filter((dt) => dt.consecutivo === cons);
    const nuevoArreglo = d.reduce((resultado, elemento) => {
      const encontrado = resultado.find(item => item.consecutivo === elemento.consecutivo);
      if (encontrado) {
        encontrado.articulo += "," + elemento.articulo;
        encontrado.descArt = elemento.descArt;
      } else {
        resultado.push({ detalle: elemento.detalle, descArt: elemento.descArt });
      }
      return resultado;
    }, []);
    setData(nuevoArreglo);
  }, []);


  // useEffect(() => {
  //   const obtenerImagen = async () => {
  //     try {
  //       const response = await axios.get('https://firebasestorage.googleapis.com/v0/b/mn-app-60991.appspot.com/o/prb%2Flogo_prb%2FJD%20Programacion.png?alt=media&token=168f153d-56b4-407a-975d-bf718cbb0dda', { responseType: 'blob' });
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setImagenURL(reader.result);
  //       };
  //       reader.readAsDataURL(response.data);
  //     } catch (error) {
  //       console.error("Error al obtener la imagen:", error);
  //     }
  //   };

  //   obtenerImagen();
  // }, []);



  /* ************************************************** */
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Text style={styles.title}>{accion} de Artículos</Text>

            {/* Informacion empresa */}
            <View style={styles.sectionEmpresa}>
              <View style={styles.section1}>

                {/* <Image style={styles.image} src={img} /> */}
                {imagenURL && <Image style={styles.image} src={imagenURL} />}

                <Text style={styles.empresa}>{emp[0].razonSocial}</Text>
                <Text style={styles.nit}>NIT: {emp[0].nit} </Text>

              </View>
              <View style={styles.fecha}>
                <Text>Fecha: {fechaFormateada}</Text>
              </View>
            </View>
            {/* Fecha y consecutivo */}

            {accion === "Solicitudes" ?
              (<>
                <View style={styles.sectionTabla}>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelSol}>Fecha Solicitud: {fechaS}</Text>
                  </View>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelSol}>Solicitante: {solicitante}</Text>
                  </View>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelSol}>Estado: {estado.toUpperCase()}</Text>
                  </View>
                </View>
                <View style={styles.sectionTabla}>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelSol}>Consecutivo: {cons}</Text>
                  </View>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelSol}>Tipo Solicitud: {tipo.toUpperCase()}</Text>
                  </View>
                </View>
              </>)
              :
              (<>
                <View style={styles.sectionTabla}>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelFechaCarga}>Fecha {accion}: {fecha}</Text>
                  </View>
                  <View style={styles.elementoHijo}>
                    <Text style={styles.labelConsecutivo}>Consecutivo: {cons}</Text>
                  </View>
                </View>
              </>)}


            {/* Tabla */}
            {accion === "Movimiento" || accion === "Asignacion" ?
              (<>
                <View style={styles.table}>
                  {/* Theader */}
                  <View style={styles.tableRow}>
                    <Text style={[styles.tableCell2, styles.tableHeader, styles.articulos]}>Artículo</Text>

                    {accion === "Movimiento" ?
                      (<>
                        {contBodegaD !== 0 && (<>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Bdga. Destino</Text>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Ubic. Destino</Text>
                        </>)}
                        {contBodegaS !== 0 && (<>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Bdga. Origen</Text>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Ubic. Origen</Text>
                        </>)}
                        {contProyectoS !== 0 && (
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Proy. Origen</Text>
                        )}

                      </>)
                      :
                      accion === "Asignacion" ? (<>
                        {contProyectoD !== 0 && (
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Proy. Destino</Text>
                        )}
                        {contBodegaS !== 0 && (<>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Bdga. Origen</Text>
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Ubic. Origen</Text>
                        </>)}
                        {contProyectoS !== 0 && (
                          <Text style={[styles.tableCell2, styles.tableHeader]}>Proy. Origen</Text>
                        )}
                      </>) : null}

                    <Text style={[styles.tableCell2, styles.tableHeader]}>Cantidad</Text>
                    <Text style={[styles.tableCell2, styles.tableHeader]}>Series</Text>
                    <Text style={[styles.tableCell2, styles.tableHeader]}>Observacion</Text>
                    <Text style={[styles.tableCell2, styles.tableHeader]}>Estado</Text>
                    <Text style={[styles.tableCell2, styles.tableHeader]}>Valor Und</Text>
                    <Text style={[styles.tableCell2, styles.tableHeader]}>Valor Total</Text>
                  </View>
                  {/* Tbody */}


                  {data.map(art => (
                    <View style={styles.tableRow} key={art} >
                      <Text style={[styles.tableCell2, styles.articulos]}>{art.descArt}</Text>
                      {accion === "Movimiento" ?
                        <>
                          {contBodegaD !== 0 && (<>
                            <Text style={[styles.tableCell2]}>{art.detalle.bodegaDestino}</Text>
                            <Text style={[styles.tableCell2]}>{art.detalle.ubicacionDestino}</Text>
                          </>)}
                          {contBodegaS !== 0 && (<>
                            <Text style={[styles.tableCell2]}>{art.detalle.bodegaSalida}</Text>
                            <Text style={[styles.tableCell2]}>{art.detalle.ubicacionSalida}</Text>
                          </>)}
                          {contProyectoS !== 0 && (
                            <Text style={[styles.tableCell2]}>{art.detalle.proyectoSalida}</Text>
                          )}

                        </>
                        : accion === "Asignacion" ?
                          (<>
                            {contProyectoD !== 0 && (
                              <Text style={[styles.tableCell2]}>{art.detalle.proyectoDestino}</Text>
                            )}
                            {contBodegaS !== 0 && (<>
                              <Text style={[styles.tableCell2]}>{art.detalle.bodegaSalida}</Text>
                              <Text style={[styles.tableCell2]}>{art.detalle.ubicacionSalida}</Text>
                            </>)}
                            {contProyectoS !== 0 && (
                              <Text style={[styles.tableCell2]}>{art.detalle.proyectoSalida}</Text>
                            )}
                          </>) : null}

                      <Text style={styles.tableCell2}>{art.detalle.cantidad}</Text>
                      <Text style={styles.tableCell2}>{art.detalle.listaSeriales?.join(", ") || "Sin series"}</Text>
                      <Text style={styles.tableCell2}>{art.detalle.observacion}</Text>
                      <Text style={styles.tableCell2}>{art.detalle.estado}</Text>
                      <Text style={styles.tableCell2}>${Intl.NumberFormat("es-CO").format(art.detalle.valor)}</Text>
                      <Text style={styles.tableCell2}>${Intl.NumberFormat("es-CO").format(art.detalle.valor * art.detalle.cantidad)}</Text>
                    </View>
                  ))}

                </View>
              </>)
              : accion === "Solicitudes" ?
                (<>
                  {/* Tabla tipo == solicitudes */}
                  <View style={styles.table}>
                    {/* Theader */}
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Existe</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader, styles.articulos]}>Artículo</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Cantidad Disponible</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Cantidad Solicitada</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Destino</Text>
                    </View>
                    {/* Tbody */}
                    {data.map((art) => (
                      <View style={styles.tableRow} key={art.detalle.consecutivo} >
                        <Text style={styles.tableCell2}>{art.detalle.ArtExiste ? "SI" : "NO"}</Text>
                        <Text style={[styles.tableCell2, styles.articulos]}>{art.descArt}</Text>
                        <Text style={styles.tableCell2}>{art.detalle.cantidadDisponible}</Text>
                        <Text style={styles.tableCell2}>{art.detalle.cantidadSolicitada}</Text>
                        <Text style={styles.tableCell2}>{art.detalle.destino}</Text>
                      </View>

                    ))}
                  </View>

                </>)
                :
                (<>
                  {/* Tabla tipo !== Movimiento || Asignacion || Solicitudes */}
                  <View style={styles.table}>
                    {/* Theader */}
                    <View style={styles.tableRow}>
                      {accion === "Venta" || accion === "Compra" && (
                        <Text style={[styles.tableCell2, styles.tableHeader, styles.factura]}>Factura N°</Text>)}
                      <Text style={[styles.tableCell2, styles.tableHeader, styles.articulos]}>Artículo</Text>
                      {contBodega !== 0 && (<>
                        <Text style={[styles.tableCell2, styles.tableHeader]}>Bdga.</Text>
                        <Text style={[styles.tableCell2, styles.tableHeader]}>Ubic.</Text>
                      </>)}
                      {contProyecto !== 0 && (
                        <Text style={[styles.tableCell2, styles.tableHeader]}>Proy.</Text>
                      )}
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Cantidad</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Series</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Observacion</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Valor Und</Text>
                      <Text style={[styles.tableCell2, styles.tableHeader]}>Valor Total</Text>
                    </View>
                    {/* Tbody */}

                    {data.map(art => (
                      <View style={styles.tableRow} key={art} >
                        {accion === "Compra" || accion === "Venta" &&
                          (<Text style={styles.tableCell2}>{art.detalle.factura}</Text>)}
                        <Text style={[styles.tableCell2, styles.articulos]}>{art.descArt}</Text>
                        {contBodega !== 0 && (<>
                          <Text style={styles.tableCell2}>{art.detalle.bodega}</Text>
                          <Text style={styles.tableCell2}>{art.detalle.ubicacion}</Text>
                        </>)}
                        {contProyecto !== 0 && (
                          <Text style={styles.tableCell2}>{art.detalle.proyecto}</Text>)}
                        <Text style={styles.tableCell2}>{art.detalle.cantidad}</Text>
                        <Text style={styles.tableCell2}>{art.detalle.listaSeriales?.join(", ") || "Sin series"}</Text>
                        <Text style={styles.tableCell2}>{art.detalle.observacion}</Text>
                        <Text style={styles.tableCell2}>${Intl.NumberFormat("es-CO").format(art.detalle.valor)}</Text>
                        <Text style={styles.tableCell2}>${Intl.NumberFormat("es-CO").format(art.detalle.valor * art.detalle.cantidad)}</Text>
                      </View>
                    ))}
                  </View>
                </>)}

            {accion !== "Solicitudes" && (<>
              {/* Total Inventario */}
              <Text style={[styles.total]}>Total: ${Intl.NumberFormat("es-CO").format(superTotal)}</Text>
            </>)}

          </View>
        </Page >
      </Document >
    </PDFViewer >
  )
}
