import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { consultarDatabase, obtenerURLImagen } from "../../config/firebase";
import { changeTitle, exportToExcel, handleColum, isMobile, styles } from "../../config/functions/funGenerales";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { Series } from "../Series";
import { VerImagen } from '../VerImagen';
import { TdTablaD } from "../miniComponentes/TdTablaD";
import { /* BotonImprimir */ DatoTablaMobile, ThTablaH } from "../miniComponentes/index";
import sortBy from "sort-by";
import {CheckboxCol} from "../CheckboxCol";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "react-toastify/dist/ReactToastify.css";


export const ConsInventario = ({ inventario }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaInventario, setListaInventario] = useState(inventario);

  const [listaBodegas, setListaBodegas] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);

  const [acumulados, setAcumulados] = useState({ superTotal: 0, cantidadArticulo: 0 });
  const [contProyecto, setContProyecto] = useState(0);
  const [contBodega, setContBodega] = useState(0);

  const [ubicacion, setUbicacion] = useState("");
  const [proyecto, setProyecto] = useState("");
  const [bodega, setBodega] = useState("");

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [k, setK] = useState(0);

  const [ubicaciones, setUbicaciones] = useState();
  const [bodegas, setBodegas] = useState(listaBodegas);
  const [proyectos, setProyectos] = useState(listaProyectos);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [soloBodInv, setSoloBodInv] = useState(false);
  const [imagenURL, setImagenURL] = useState(null);
  const [show, setShow] = useState(false);
  const [nombre, setNombre] = useState(false);

  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Consulta_inventario");
    cargaInicial()
  }, []);

  const cargaInicial = async () => {
    isMobile(setMobile)
    setListaInventario(inventario)
    handleColum(
      [setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13],
      user,
      "ConsInvenatrio"
    );
    setIsLoading(true)
    try {
      await cargarBodegas();
      await cargarProyectos();
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    setListaInventario(inventario)
  }, [inventario]);

  const soloConInventario = (propiedadCantidad, propiedadAgrupar) => {
    let arrayFiltrado = listaInventario.filter(item => item[propiedadCantidad] > 0)
      .reduce((acc, item) => {
        if (!acc.find(obj => obj[propiedadAgrupar] === item[propiedadAgrupar])) {
          acc.push({ [propiedadAgrupar]: item[propiedadAgrupar] });
        }
        return acc;
      }, []);

    return arrayFiltrado;
  };

  //------ Bodegas ---------
  const cargarBodegas = async () => {
    const listaTemporal = await consultarDatabase(emp + "_bodegas");
    setListaBodegas(listaTemporal);
  };

  useEffect(() => {
    let options = [], bodegasTem = listaBodegas;
    if (soloBodInv) {
      const data = soloConInventario("cant_disponible", "bodega")
      let arrayConDescripcion = data.map(item => {
        let bodega = bodegasTem.find(bod => bod.consecutivo === item.bodega);
        return bodega ? { ...item, descripcion: bodega.descripcion } : item;
      });
      options = arrayConDescripcion.map(b => ({ value: b.bodega, label: b.bodega + ": (" + b.descripcion + ")" }));
      setBodegas(options?.sort(sortBy("value")))
    }
    if (!soloBodInv) {
      options = listaBodegas.map(b => ({ value: b.consecutivo, label: b.consecutivo + ": (" + b.descripcion + ")" }));
      setBodegas(options?.sort(sortBy("value")))
    }

  }, [listaBodegas, soloBodInv]);

  useEffect(() => {
    if (bodega.value && bodega.value !== "") {
      if (soloBodInv) {
        const data = listaInventario.filter((i) => i.bodega === bodega.value && i.cant_disponible > 0)
        let ubicaciones = data.map(item => item.consecutivo.split('\\')[1]);
        let ubicacionesUnicas = [...new Set(ubicaciones)];
        const options = ubicacionesUnicas.map((t) => ({ value: t, label: t }));
        setUbicaciones(options?.sort(sortBy("value")))
      }
      if (!soloBodInv) {
        const temporal = listaBodegas.find(b => b.consecutivo === bodega.value)?.listaUbicaciones
        if (temporal) {
          const options = temporal.map((t) => ({ value: t.ubicacion, label: t.ubicacion }));
          setUbicaciones(options?.sort(sortBy("value")))
        }
      }
    } else {
      setUbicaciones("")
      setUbicacion("")
    }
  }, [bodega]);

  //------ Proyectos ---------
  const cargarProyectos = async () => {
    const listaTemporal = await consultarDatabase(emp + "_proyectos");
    setListaProyectos(listaTemporal.sort(sortBy("consecutivo")));
  };

  useEffect(() => {
    let options = [], proyectosTem = listaProyectos;
    if (soloBodInv) {
      const data = soloConInventario("cant_reservada", "proyecto")
      let arrayConDescripcion = data.map(item => {
        let proyecto = proyectosTem.find(pr => pr.consecutivo === item.proyecto);
        return proyecto ? { ...item, descripcion: proyecto.descripcion } : item;
      });
      options = arrayConDescripcion.map(b => ({ value: b.proyecto, label: b.proyecto + ": (" + b.descripcion + ")" }));
      setProyectos(options?.sort(sortBy("value")))
    }
    if (!soloBodInv) {
      options = listaProyectos.map(b => ({ value: b.consecutivo, label: b.consecutivo + ": (" + b.descripcion + ")" }));
      setProyectos(options?.sort(sortBy("value")))
    }
  }, [listaProyectos, soloBodInv]);

  //------- Tablas ------
  const manipularCabecerasTabla = async (lista) => {
    let cP = 0
    let cB = 0
    // let cT = 0
    lista.forEach(etb => {
      if (etb.proyecto !== "" && etb.proyecto !== undefined) {
        cP = cP + 1
      }
      if (etb.bodega !== "" && etb.bodega !== undefined) {
        cB = cB + 1
      }
    })
    setContBodega(cB)
    setContProyecto(cP)
  };

  useEffect(() => {
    manipularCabecerasTabla(listaInventario)
    filtrarLista();
  }, [listaInventario, soloBodInv]);

  const filtrarLista = () => {
    setIsLoading(true)
    if (listaInventario.length > 0) {
      try {
        let listaFiltradaTemporal = [];
        if (bodega !== '' && ubicacion === '') {
          soloBodInv ?
            listaFiltradaTemporal = listaInventario.filter(item => item.bodega === bodega.value && item.cant_disponible > 0) :
            listaFiltradaTemporal = listaInventario.filter(item => item.bodega === bodega.value);
        }
        if (bodega !== '' && ubicacion !== '') {
          soloBodInv ?
            listaFiltradaTemporal = listaInventario.filter(item => (item.bodega === bodega.value) && (item.ubicacion === ubicacion.value) && (item.cant_disponible > 0)) :
            listaFiltradaTemporal = listaInventario.filter(item => (item.bodega === bodega.value) && (item.ubicacion === ubicacion.value))
        }
        if (proyecto !== '') {
          soloBodInv ?
            listaFiltradaTemporal = listaInventario.filter(item => item.proyecto === proyecto.value && item.cant_reservada > 0) :
            listaFiltradaTemporal = listaInventario.filter(item => item.proyecto === proyecto.value)
        }
        if (proyecto === '' && bodega === '' && ubicacion === '') {
          soloBodInv ?
            listaFiltradaTemporal = listaInventario.filter(item => item.cant_reservada > 0 || item.cant_disponible > 0) :
            listaFiltradaTemporal = listaInventario.filter(item => item.cant_reservada >= 0 || item.cant_disponible >= 0)
        }
        setDocumentosFiltrados(listaFiltradaTemporal);
        if (listaFiltradaTemporal.length === 0) {
          toast.warning(`No se encontro registro de inventario en la ubicacion seleccionada, por favor intente de nuevo`)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  };

  const calcularAcumulado = (listaMostrada) => {
    const resultado = listaMostrada.reduce(
      (acumulados, li) => {
        const valorCelda = li.ult_Costo * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada);
        return {
          superTotal: acumulados.superTotal + valorCelda,
          cantidadArticulo: acumulados.cantidadArticulo + parseFloat(li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada),
        };
      },
      { superTotal: 0, cantidadArticulo: 0 }
    );

    return resultado;
  };

  useEffect(() => {
    const { superTotal, cantidadArticulo } = calcularAcumulado(registrosPaginaActual);
    setAcumulados({ superTotal, cantidadArticulo });
  }, [registrosPaginaActual, paginaActual]);

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //------- Paginación --------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    setK(k + 1)
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas, k, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaInventario(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  useEffect(() => {
    if (bodega !== '' || proyecto !== '') filtrarLista()
  }, [bodega, ubicacion, proyecto, listaInventario]);

  useEffect(() => {
    setUbicacion("")
    if (bodega === "") return
    setProyecto("")
  }, [bodega]);

  useEffect(() => {
    if (proyecto === "") return
    setBodega("")
  }, [proyecto]);

  const borrarFiltros = () => {
    setBodega("")
    setUbicacion("")
    setProyecto("")
    setDocumentosFiltrados(listaInventario);
  };

  //--------- Columnas -------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    if (col9 && excluido !== 'col9') contador++;
    if (col10 && excluido !== 'col10') contador++;
    if (col11 && excluido !== 'col11') contador++;
    if (col12 && excluido !== 'col12') contador++;
    if (col13 && excluido !== 'col13') contador++;
    return contador >= 2;
  };

  const handleObtenerURLImagen = async (ruta) => {
    if (ruta !== undefined) {
      try {
        const url = await obtenerURLImagen(ruta);
        if (url) {
          setShow(true);
          setImagenURL(url);
        } else {
          toast.warning("El artículo no tiene imágen de factura disponible")
          setShow(false)
        }
      } catch (error) {
        toast.warning("El artículo no tiene imágen de factura disponible")
        setShow(false)
        console.error(error)
      }
    }
  };

  const cerrarModal = () => {
    setShow(false);
  };

  const formatExcel=(lista)=>{
    const data = lista?.map((item) => {
      return {
        "Consecutivo": item?.consecutivoFix,
        "Artículo": item?.des_Larga,
        "Clasificacion 1": item?.clasificacion1,
        "Clasificacion 2": item?.clasificacion2,
        "Clasificacion 3": item?.clasificacion3,
        "Cantidad": item?.cant_disponible !== undefined ? item?.cant_disponible : item?.cant_reservada,
        "Bodega": item?.bodega,
        "Ubicacion": item?.ubicacion,
        "Proyecto": item?.proyecto,
        "Costo en Gestion": item?.valor,
        "Costo Actual": item?.ult_Costo,
        "Valor Total": item?.ult_Costo * (item?.cant_disponible !== undefined ? item?.cant_disponible : item?.cant_reservada)
      }
    });
    if(data.length>0){
      exportToExcel(data, 'datos.xlsx')
    }
  }

  //************ Inicio componente ******************/
  return (
    <>
      {isLoading ?
        <LoaderES />
        :
        (<>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "marginLeft 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            {/* botones de opcion */}
            <div className="row ms-2">
              <div className={mobile ? "col-12 align-self-center text-center mt-0 mb-2" : "col-4 align-self-start text-start"}>
                <div className="btn-group">

                  {/* Boton Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                  {/* Boton Imprimir */}
                  <button className="btn fa-solid fa-file-excel"
                    onClick={() => {
                     formatExcel(registrosPaginaActual)
                      // exportToExcel(registrosPaginaActual, 'datos.xlsx')
                    }}
                    style={{ cursor: 'pointer', color: 'green' }}
                    title='Exportar a Excel'
                  />

                  {/* Boton borrar filtros */}
                  <button
                    className="btn fa-solid fa-eraser me-2"
                    title="Borrar Filtros"
                    onClick={() => borrarFiltros()}
                  />
                  {/* Boton Sólo con inventario */}
                  <div className="form-check form-switch mt-1">
                    <input className="form-check-input " type="checkbox" role="switch" id="flexCheckDefault"
                      onChange={(e) => {
                        borrarFiltros();
                        setSoloBodInv(e.target.checked)
                      }}
                    />
                    <label className="form-check-label" style={{ fontSize: "0.8em" }} for="flexCheckDefault">Sólo con inventario</label>
                  </div>

                </div>
              </div>

              {/* filtrar */}
              <div className={mobile ? "row mb-2" : "col-7"}>
                <Filtrar2
                  tabla="myTable"
                  documentos={listaInventario}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={(mobile ? " mt-5 mb-2" : "mt-5 ")} />

          {/* Filtros */}
          <div className={mobile ? "row mt-5 mb-3" : "row mb-3"}>

            {/* Bodega */}
            <div className={"col-12 col-md-4" + (mobile ? " mt-4 mb-1" : " mt-3 mb-2")}>
              <Select
                placeholder="Bodega"
                value={bodega}
                onChange={(option) => setBodega(option)}
                options={bodegas}
                styles={styles}
              />
            </div>

            {/* Ubicacion */}
            <div className={"col-12 col-md-4" + (mobile ? " mt-1 mb-1" : " mt-3 mb-2")}>
              <Select
                placeholder="Ubicación"
                isDisabled={proyecto || !bodega}
                value={ubicacion}
                onChange={(option) => setUbicacion(option)}
                options={ubicaciones}
                styles={styles}
              />
            </div>

            {/* Proyecto */}
            <div className={"col-12 col-md-4" + (mobile ? " mt-1 mb-1" : " mt-3 mb-2")}>
              <Select
                placeholder="Proyecto"
                value={proyecto}
                onChange={(option) => setProyecto(option)}
                options={proyectos}
                styles={styles}
              />
            </div>

          </div>

          <hr className="bg-dark mt-3 mb-4" />

          {/* Tabla de Inventario */}
          {listaInventario.length > 0 &&
            (<>
              {mobile ?
                (<>
                  {registrosPaginaActual.map((li, index) => (
                    <div key={li.consecutivo}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + (li.consecutivoFix) + (index)}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none" }} >
                              {li.consecutivoFix}:
                            </th>
                            <td style={{ minWidth: "200px" }} className={li.estado === "INACTIVO" ? "text-danger" : ""}>{li.des_Larga}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + (li.consecutivoFix) + (index)}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody>
                              <DatoTablaMobile titulo={'Consecutivo'} dato={li.consecutivoFix} columna={col2} />
                              <DatoTablaMobile titulo={'Artículo'} dato={li.des_Larga} columna={col3} clase={(col3 && li.estado === "INACTIVO") ? "text-danger user-select-none" : !col3 ? " text-danger d-none" : col3 ? "" : "d-none"} />
                              <DatoTablaMobile titulo={'Clasificacion 1'} dato={li.clasificacion1} columna={col4} />
                              <DatoTablaMobile titulo={'Clasificacion 2'} dato={li.clasificacion2} columna={col5} />
                              <DatoTablaMobile titulo={'Clasificacion 3'} dato={li.clasificacion3} columna={col6} />
                              <DatoTablaMobile titulo={'Cantidad'} dato={li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada} columna={col7} />
                              {li.bodega &&
                                <DatoTablaMobile titulo={'Bodega '} dato={li.bodega} columna={col8} />
                              }
                              {li.bodega &&
                                <DatoTablaMobile titulo={'Ubicacion'} dato={li.ubicacion} columna={col9} />
                              }
                              {li.proyecto &&
                                <DatoTablaMobile titulo={'Proyecto'} dato={li.proyecto} columna={col10} />
                              }
                              <DatoTablaMobile titulo={'Costo en Gestion'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} columna={col11} />
                              <DatoTablaMobile titulo={'Costo Actual'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo)}`} columna={col12} />
                              <DatoTablaMobile titulo={'Valor Total'} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada))}`} columna={col13} />
                              {li.serial &&
                                <tr>
                                  <td></td>
                                  <th>Ver Series </th>
                                  <td><Series articulo={li} /></td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </>)
                :
                (<>
                  <div className="table-responsive" id="printable-area">
                    <table id="myTable" className="table table-hover table-striped table-sm table-bordered mt-2">
                      <thead className="text-center">
                        <tr>
                          <ThTablaH ordenar={ordenar} dOrdenar={'consecutivoFix'} columna={col2} titulo={'Consecutivo'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'des_Larga'} columna={col3} titulo={'Artículo'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion1'} columna={col4} titulo={'Clasificacion '} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion2'} columna={col5} titulo={'Clasificacion '} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion3'} columna={col6} titulo={'Clasificacion '} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'cant_disponible' || 'cant_reservada'} columna={col7} titulo={'Cantidad'} />
                          {contBodega > 0 && (<ThTablaH ordenar={ordenar} dOrdenar={'bodega'} columna={col8} titulo={'Bodega'} />)}
                          {contBodega > 0 && (<ThTablaH ordenar={ordenar} dOrdenar={'ubicacion'} columna={col9} titulo={'Ubicacion'} />)}
                          {contProyecto > 0 && (<ThTablaH ordenar={ordenar} dOrdenar={'proyecto'} columna={col10} titulo={'Proyecto'} />)}
                          <ThTablaH ordenar={ordenar} dOrdenar={'valor'} columna={col11} titulo={'Costo n Gestion'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={'ult_Costo'} columna={col12} titulo={'Costo ctual'} />
                          <ThTablaH ordenar={ordenar} dOrdenar={''} columna={col13} titulo={'Valor otal'} />
                          <th>Opciones</th>
                        </tr>
                      </thead>

                      <tbody className="text-center" id="tabla_articulos">
                        {registrosPaginaActual.map((li) => (
                          <tr key={li.bodega ? li.articulo + li.id : li.articulo + li.id}>
                            <TdTablaD columna={col2} dato={li.consecutivoFix} />
                            <TdTablaD columna={col3} dato={li.des_Larga} clase={(col3 && li.estado === "INACTIVO") ? "text-danger user-select-none" : !col3 ? "d-none" : col3 ? "" : "d-none"} title={li.estado === "INACTIVO" && "Artículo inactivo"} />
                            <TdTablaD columna={col4} dato={li.clasificacion1} />
                            <TdTablaD columna={col5} dato={li.clasificacion2} />
                            <TdTablaD columna={col6} dato={li.clasificacion3} />
                            <TdTablaD columna={col7} dato={li.bodega !== undefined ? li.cant_disponible : li.cant_reservada} />
                            {contBodega > 0 && (<TdTablaD columna={col8} dato={li.bodega} />)}
                            {contBodega > 0 && (<TdTablaD columna={col9} dato={li.ubicacion} />)}
                            {contProyecto > 0 && (<TdTablaD columna={col10} dato={li.proyecto} />)}
                            <TdTablaD columna={col11} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} />
                            <TdTablaD columna={col12} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo)}`} />
                            <TdTablaD columna={col13} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada))}`} />
                            <td>
                              <div className="btn-group">
                                {li.serial && (<Series articulo={li} />)}
                                <button className="btn btn-sm btn-outline-success fa-solid fa-eye"
                                  title="Ver Imagen"
                                  onClick={() => {
                                    handleObtenerURLImagen(li.imagen)
                                    setNombre(li.des_Larga)
                                  }} />
                              </div>
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>

                      <tfoot className=" mt-2 text-center text-danger">
                        <tr>
                          <td className={col2 ? "" : "d-none"}></td>
                          <td className={col3 ? "" : "d-none"}></td>
                          <td className={col4 ? "" : "d-none"}></td>
                          <td className={col5 ? "" : "d-none"}></td>
                          <td className={col6 ? "" : "d-none"}></td>
                          <td className={col7 ? "text-center" : "d-none"}>{parseFloat(acumulados.cantidadArticulo)}</td>
                          {contBodega > 0 && (<td className={col8 ? "" : "d-none"}></td>)}
                          {contBodega > 0 && (<td className={col9 ? "" : "d-none"}></td>)}
                          {contProyecto > 0 && (<td className={col10 ? "" : "d-none"}></td>)}
                          <td className={col11 ? "" : "d-none"}></td>
                          <td className={col12 ? "" : "d-none"}></td>
                          <td className={col13 ? "text-center" : "d-none"}>${Intl.NumberFormat("es-CO").format(acumulados.superTotal)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </>)
              }

              <Pagination
                paginaActual={paginaActual}
                totalPaginas={totalPaginas}
                setPaginaActual={setPaginaActual}
                registrosPorPagina={registrosPorPagina}
                setRegistrosPorPagina={setRegistrosPorPagina}
                documentosFiltrados={documentosFiltrados}
              />
            </>)}

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">
                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Artículo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Clasificacion 1"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Clasificacion 2"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Clasificacion 3"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Cantidad"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Bodega"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9')} agregarColumna={agregarColumna} texto={"Ubicacion"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10')} agregarColumna={agregarColumna} texto={"Proyecto"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11')} agregarColumna={agregarColumna} texto={"Costo en Gestion"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12')} agregarColumna={agregarColumna} texto={"Costo Actual"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="ConsInvenatrio" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13')} agregarColumna={agregarColumna} texto={"Valor Total"} />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal" >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Imagen */}
          <VerImagen url={imagenURL} show={show} handleClose={cerrarModal} nombre={nombre} />

        </>)
      }
    </>
  );
};