import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { cargarPermisos, changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import { crearGruposConsec, procesarDatos, traerEmpresa } from "../../config/functions/funReportes";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { ModalArtReport } from "./ModalArtReport";
import { Pagination } from "../Pagination";

export const ReportSalidas = ({ articulos, kardex,permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaFiltroVenta, setListaFiltroVenta] = useState([]);
  const [listaFiltroBajas, setListaFiltroBajas] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaSalidas, setListaSalidas] = useState([]);
  const [dataEmpresa, setDataEmpresa] = useState([]);
  const [listaPDF, setListaPDF] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");
  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [mobile, setMobile] = useState(true);
  const [consec, setConsec] = useState("");
  const tablaRef = useRef(null);

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);

  /***************** Funciones ****************/
  useEffect(() => {
    changeTitle("Admin. Reportes_Salidas");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum(
      [setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8],
      user,
      "repSalidas"
    );
    setOpcionSeleccionada("rep-bajas");
    isMobile(setMobile);
    traerEmpresa(emp, setDataEmpresa);
    setListaSalidas(kardex);
    setListaArticulos(articulos);
  };

  useEffect(() => {
    setListaArticulos(articulos)
  }, [articulos]);

  useEffect(() => {
    setListaSalidas(kardex);
  }, [kardex]);

  useEffect(() => {
    if (opcionSeleccionada === "rep-bajas") {
      filtrarSalidas(listaSalidas, "bajas")
    } else if (opcionSeleccionada === "rep-ventas") {
      filtrarSalidas(listaSalidas, "ventas")

    }
  }, [opcionSeleccionada, listaSalidas]);

  const filtrarSalidas = (lista, tipo) => {
    if (tipo === "bajas") {
      const articulosFiltradosI = lista?.filter(
        (item) => item.consecutivo && item.consecutivo.startsWith("BJA")
      );
      const listaOrdenada = articulosFiltradosI.sort((a, b) => {
        const consecutivoA = parseInt(a.consecutivo.split("-")[1]);
        const consecutivoB = parseInt(b.consecutivo.split("-")[1]);
        return consecutivoB - consecutivoA;
      });
      procesarDatos(listaOrdenada, listaArticulos, setListaFiltroBajas, setListaPDF)
    }
    if (tipo === "ventas") {
      const articulosFiltradosC = lista?.filter(
        (item) => item.consecutivo && item.consecutivo.startsWith("VEN")
      );
      const listaOrdenadaC = articulosFiltradosC.sort((a, b) => {
        const consecutivoA = parseInt(a.consecutivo.split("-")[1]);
        const consecutivoB = parseInt(b.consecutivo.split("-")[1]);
        return consecutivoB - consecutivoA;
      });
      procesarDatos(listaOrdenadaC, listaArticulos, setListaFiltroVenta, setListaPDF)
    }
  };

  //---------- Columnas ----------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (opcionSeleccionada === "rep-bajas") {
      if (col2 && excluido !== 'col2') contador++;
      if (col3 && excluido !== 'col3') contador++;
      if (col4 && excluido !== 'col4') contador++;
    }
    if (opcionSeleccionada === "rep-ventas") {
      if (col5 && excluido !== 'col5') contador++;
      if (col6 && excluido !== 'col6') contador++;
      if (col7 && excluido !== 'col7') contador++;
      if (col8 && excluido !== 'col8') contador++;
    }
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //---------- Paginación ----------
  useEffect(() => {
    if (opcionSeleccionada === "rep-bajas") {
      setTotalPaginas(Math.ceil(listaFiltroBajas.length / registrosPorPagina))
    } else if (opcionSeleccionada === "rep-ventas") {
      setTotalPaginas(Math.ceil(listaFiltroVenta.length / registrosPorPagina))
    }
  }, [listaFiltroBajas, listaFiltroVenta, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    if (opcionSeleccionada === "rep-bajas") {
      setRegistrosPaginaActual(listaFiltroBajas.slice(inicio, fin))
    } else if (opcionSeleccionada === "rep-ventas") {
      setRegistrosPaginaActual(listaFiltroVenta.slice(inicio, fin))
    }
  }, [paginaActual, totalPaginas]);


  /* ************************ */
  return (
    <>
      <div className="fixed-top mt-2"
        style={{
          backgroundColor: "white",
          marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
          transition: "margin-left 0.3s ease",
          top: "46px",
          zIndex: 1
        }}>

        <div className="row ms-2 me-2">

          {/* botones de opcion */}
          <div className="col-4 align-self-start text-start">
            <div className="btn-group">

              {/* Columnas */}
              <button
                className="btn fa-solid fa-table-columns"
                title="Seleccionar Columnas"
                data-bs-toggle="modal"
                data-bs-target="#ModificarColumnas"
                id="modalcolumnas" />
            </div>
          </div>

          {/* filtrar */}
          <div className="col-8">
            <Filtrar2
              tabla="myTable"
              documentos={opcionSeleccionada === "rep-bajas" ? listaFiltroBajas : listaFiltroVenta}
              setDocumentosFiltrados={setDocumentosFiltrados}
              setRegistrosFiltrados={setRegistrosPaginaActual}
              registrosPorPagina={registrosPorPagina} />
          </div>
        </div>
      </div>

      <hr className={"text-light mt-5 mb-4" + (mobile ? "mb-5" : "")} />
      <hr className={"text-light mt-1 mb-1"} />

      {/* Menu header */}
      <ul className="nav nav-tabs mt-3">
        <li className="nav-item user-select-none"
          onClick={() => {
            if (permisos.includes("repBajas")) {
              setOpcionSeleccionada("rep-bajas")
            }
          }}>
          <p className={"nav-link " + (opcionSeleccionada === "rep-bajas" ? "active" : "")}
            style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }} >Bajas</p>
        </li>

        <li className="nav-item user-select-none"
          onClick={() => {
            if (permisos.includes("repVenta")) {
              setOpcionSeleccionada("rep-ventas")
            }
          }}>
          <p className={"nav-link " + (opcionSeleccionada !== "rep-bajas" ? "active" : "")}
            style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Ventas</p>
        </li>
      </ul>

      <div>
        {/*  Bajas  */}
        {permisos.includes("repBajas") ?
          opcionSeleccionada === "rep-bajas" && (
            <>
              <div className=" table-responsive">
                <table id="myTable"
                  ref={tablaRef}
                  className="table table-hover table-striped table-sm table-bordered mt-0">
                  <thead className="text-center">
                    <tr>
                      <th className="text-center" style={{ resize: "none" }} colSpan={"100%"}> REPORTE DE BAJAS DE INVENTARIO</th>
                    </tr>
                    <tr>
                      <th className={col2 ? "" : "d-none"}>Consecutivo</th>
                      <th className={col3 ? "" : "d-none"}>Fecha Baja</th>
                      <th className={col4 ? "" : "d-none"}>Artículos</th>
                      <th className="text-center">Ver Rep.</th>
                    </tr>
                  </thead>
                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual && registrosPaginaActual.map(art => (
                      <tr key={art.consecutivo}>
                        <td className={col2 ? "" : "d-none"}>{art.consecutivo}</td>
                        <td className={col3 ? "" : "d-none"}>{art.fecha_creacion}</td>
                        <td className={col4 ? "" : "d-none"}>
                          <button className="btn btn-sm btn-outline-primary fa-solid fa-list"
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                            onClick={() => {
                              setConsec(art.consecutivo)
                              crearGruposConsec(art.articulo, setGrupo)
                            }} />
                        </td>
                        <td className="text-center">
                          {permisos.includes("impReportBaja") ?
                            <NavLink
                              to={{ pathname: `/docpdf` }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="btn btn-outline-primary btn-sm me-2 fa-solid fa-print"
                                onClick={() => {
                                  localStorage.setItem('myData', JSON.stringify({
                                    c: art.consecutivo, d: listaPDF,
                                    a: "Baja", e: dataEmpresa
                                  }));
                                }}
                              />
                            </NavLink>
                            : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>

          )
          : null}

        {/* Ventas  */}
        {permisos.includes("repVenta") ?
          opcionSeleccionada === "rep-ventas" &&
          (<>
            <div className=" table-responsive">
              <table id="myTable"
                ref={tablaRef}
                className="table table-hover table-striped table-sm table-bordered">
                <thead className="text-center">
                  <tr>
                    <th className="text-center" style={{ resize: "none" }} colSpan={"100%"}> REPORTE DE VENTAS</th>
                  </tr>
                  <tr>
                    <th className={col5 ? "" : "d-none"}>Factura</th>
                    <th className={col6 ? "" : "d-none"}>Consecutivo</th>
                    <th className={col7 ? "" : "d-none"}>Fecha Venta</th>
                    <th className={col8 ? "" : "d-none"}>Artículos</th>
                    <th className="text-center">Ver Rep.</th>
                  </tr>
                </thead>
                <tbody className="text-center" id="tabla_articulos">
                  {registrosPaginaActual && registrosPaginaActual.map(art => (
                    <tr key={art.consecutivo}>
                      <td className={col5 ? "" : "d-none"}>{art.detalle.factura}</td>
                      <td className={col6 ? "" : "d-none"}>{art.consecutivo}</td>
                      <td className={col7 ? "" : "d-none"}>{art.fecha_creacion}</td>
                      <td className={col8 ? "" : "d-none"}>
                        <button className="btn btn-sm btn-outline-primary fa-solid fa-list"
                          data-bs-toggle="modal" data-bs-target="#exampleModal"
                          onClick={() => {
                            setConsec(art.consecutivo)
                            crearGruposConsec(art.articulo, setGrupo)
                          }} />
                      </td>
                      <td className="text-center">
                        {permisos.includes("impReportVen") ?
                          <NavLink
                            to={{ pathname: `/docpdf` }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="btn btn-outline-primary btn-sm me-2 fa-solid fa-print"
                              onClick={() => {
                                localStorage.setItem('myData', JSON.stringify({
                                  c: art.consecutivo, d: listaPDF,
                                  a: "Venta", e: dataEmpresa
                                }));
                              }}
                            />
                          </NavLink>
                          : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>

          )
          : null}
      </div>

      <Pagination
        paginaActual={paginaActual}
        totalPaginas={totalPaginas}
        setPaginaActual={setPaginaActual}
        registrosPorPagina={registrosPorPagina}
        setRegistrosPorPagina={setRegistrosPorPagina}
        documentosFiltrados={documentosFiltrados}
      />

      {/* Modal Columnas */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModificarColumnas"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title" id="staticBackdropLabel3">
                Selección de columnas {opcionSeleccionada === "rep-bajas" ? "Bajas" : "Ventas"}
              </h5>
            </div>

            <div className="modal-body">
              {opcionSeleccionada === "rep-bajas" ?
                <>
                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Fecha Baja"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Artículos"} />
                  </div>
                </> : null
              }

              {opcionSeleccionada === "rep-ventas" ?
                <>
                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Factura"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Fecha Venta"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="repSalidas" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Artículos"} />
                  </div>
                </> : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal articulos */}
      <ModalArtReport consecutivo={consec} lista={grupo} />

    </>
  );
};
