import React, { useEffect, useState } from "react";
import { Modal } from "react-rainbow-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    actualizarDocumentoDatabase,
    actualizarImagen,
    consultarDocumentoWhere,
    obtenerURLImagen
} from "./../config/firebase";
import { LoaderES } from "./LoaderES";
import { changeTitle } from "../config/functions/funGenerales";

export const MiEmpresa = ({permisos}) => {

    //*********  variables de estado *********/
    const user = JSON.parse(localStorage.getItem("token"))?.userName;
    const emp = JSON.parse(localStorage.getItem("token"))?.empresa;

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    const fecha_modificacion = fechaFormateada;
    const usuario_modificacion = user;

    const [digitoVerificacion, setDigitoVerificacion] = useState("");
    const [idDocumento, setIdDocumento] = useState("");
    const [razonSocial, setRazonSocial] = useState("");
    const [telefono2, setTelefono2] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telefono1, setTelefono1] = useState("");
    const [existe, setExiste] = useState(false);
    const [correo, setCorreo] = useState("");
    const [url1, setUrl1] = useState("");
    const [url2, setUrl2] = useState("");
    const [logo, setLogo] = useState("");
    const [nit, setNit] = useState("");

    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [rutaEnFirestore, setRutaEnFirestore] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [imagenURL, setImagenURL] = useState("");
    const [existeA, setExisteA] = useState(null);
    const inputFile = React.useRef(null);

    //************* Funciones *****************/
    useEffect(() => {
        localStorage.setItem("componente", "MiEmpresa");
        changeTitle("Registro Empresas");
        buscarEmpresas();
    }, []);

    const buscarEmpresas = async () => {
        try {
            const listaTemporal = await consultarDocumentoWhere("empresas", "idEmpresa", emp);

            if (listaTemporal.length > 0) {
                setExiste(true);
                setNit(listaTemporal[0].nit);
                setDigitoVerificacion(listaTemporal[0].digitoVerificacion);
                setRazonSocial(listaTemporal[0].razonSocial);
                setDireccion(listaTemporal[0].direccion);
                setCorreo(listaTemporal[0].correo);
                setTelefono1(listaTemporal[0].telefono1);
                setTelefono2(listaTemporal[0].telefono2);
                setUrl1(listaTemporal[0].url1);
                setUrl2(listaTemporal[0].url2);
                setLogo(listaTemporal[0].logo);
                setIdDocumento(listaTemporal[0].idDocumento);
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleClickModificarBd = async () => {
        const enviarArticulo = {
            idDocumento: idDocumento,
            razonSocial: razonSocial.toUpperCase(),
            direccion: direccion.toUpperCase(),
            correo: correo,
            telefono1: telefono1,
            telefono2: telefono2,
            url1: url1,
            url2: url2,
            usuario_modificacion: usuario_modificacion.toUpperCase(),
            fecha_modificacion: fecha_modificacion,
        };

        if (idDocumento !== "" && idDocumento !== undefined) {
            try {
                await actualizarDocumentoDatabase("empresas", idDocumento, enviarArticulo);
                toast.success("Empresa Modificada correctamente");
                resetStates();
                await buscarEmpresas();
            } catch (error) {
                console.error(error)
            }
        } else {
            toast.warning("La empresa no se encuentra registrada");
        }
    };

    const resetStates = () => {
        setNit("")
        setDigitoVerificacion("")
        setRazonSocial("")
        setDireccion("")
        setCorreo("")
        setTelefono1("")
        setTelefono2("")
        setUrl1("")
        setUrl2("")
        setLogo("")
    };

    //---------- CRUD imágenes----------
    const handleImageSelect = (event) => {
        const image = event.target.files[0];
        setImagenSeleccionada(image);
    };

    const handleActualizarImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await actualizarImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue actualiada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        }
    };

    const handleObtenerURLImagen = async (ruta) => {
        if (ruta !== undefined) {
            try {
                const url = await obtenerURLImagen(ruta);
                setImagenURL(url);
                setRutaEnFirestore(url)
                setModalIsOpen(true)
                setExisteA(true)
            } catch (error) {
                toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
                setExisteA(false)
                setModalIsOpen(true)
                setRutaEnFirestore(ruta)
            }
        }
    };

    const limpiar = () => {
        setOcultarBtn(false)
        setUpdateImg(false)
    }


    //-----------Inicio componente-------------
    return (
        <>
            <ToastContainer position="bottom-right" />

            {nit === "" ?
                <LoaderES />
                :
                <>
                    <div className="container mt-5">
                        <div className="card p-4 border-2 border-grey rounded-3 mt-2">
                            <div className="row text-center">
                                <h6 className="mb-3">{existe ? "Modificar" : "Registrar"} Empresa</h6>
                            </div>

                            {/* Inputs NIT y digito verificacion */}
                            <div className="row mt-3">
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="nit">NIT</span>
                                        <input type="number" className="form-control" id="nit"
                                            disabled={existe}
                                            value={nit} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="verificacionNit">Dígito de Verificación</span>
                                        <input type="number" className="form-control" id="verificacionNit"
                                            disabled={existe}
                                            min={0}
                                            max={9}
                                            value={digitoVerificacion} />
                                    </div>
                                </div>
                            </div>

                            {/* Input razón social */}
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="razonSocial">Razón Social</span>
                                        <input type="text" className="form-control" id="razonSocial"
                                            value={razonSocial}
                                            onChange={(e) => setRazonSocial(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* Inputs direccion y correo */}
                            <div className="row mt-3">
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="direccion">Dirección</span>
                                        <input type="text" className="form-control" id="direccion"
                                            value={direccion}
                                            onChange={(e) => setDireccion(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="email">E-mail</span>
                                        <input type="email" className="form-control" id="email"
                                            value={correo}
                                            onChange={(e) => setCorreo(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* Inputs telefono 1 y 2*/}
                            <div className="row mt-3">
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="telefono1">Teléfono 1</span>
                                        <input type="number" className="form-control" id="telefono1"
                                            min={0}
                                            value={telefono1}
                                            onChange={(e) => setTelefono1(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="telefono2">Teléfono 2</span>
                                        <input type="number" className="form-control" id="telefono2"
                                            min={0}
                                            value={telefono2}
                                            onChange={(e) => setTelefono2(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* Inputs URL 1 y 2*/}
                            <div className="row mt-3">
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="url1">URL 1</span>
                                        <input type="url" className="form-control" id="url1"
                                            value={url1}
                                            onChange={(e) => setUrl1(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" htmlFor="url2">URL 2</span>
                                        <input type="url" className="form-control" id="url2"
                                            value={url2}
                                            onChange={(e) => setUrl2(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            {/* Input Seleccione logo */}
                            <div className="row mt-3 mb-3">
                                <div className="col text-center">
                                    <button className="btn btn-outline-secondary"
                                        onClick={() => handleObtenerURLImagen(logo)} >
                                        <i className="fa-solid fa-eye me-2"></i>
                                        Ver Logo
                                    </button>
                                </div>
                            </div>

                            {/* Boton */}
                            <div className="d-grid gap-2 col-6 mx-auto mt-3 mb-3">
                                <button type="button" className="btn btn-primary"
                                    onClick={() => handleClickModificarBd()}>
                                    Modificar
                                </button>
                            </div>
                        </div>
                    </div>
                </>}

            {/* Modal CRUD de imagenes */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    setModalIsOpen(false)
                    setImagenSeleccionada("")
                    limpiar()
                }}
                contentLabel="My Image Modal">

                {!updateImg && existeA ? <>
                    <h3 className="text-center mb-4 fs-5">Logotipo</h3>
                    <img src={imagenURL} alt="Mi imagen" style={{
                        "display": "block", "margin-left": "auto",
                        "margin-right": "auto", "width": "40%",
                        "height": "auto"
                    }} />
                </>
                    :
                    updateImg || !existeA ? <>
                        <div className="container mt-3">
                            <div className="row text-center">
                                <h2 className={existeA ? "mb-3 fs-5 text-primary" : "mb-3 fs-5 text-success"}>{existeA ? "Modificar Logo" : "Cargara Logo"}</h2>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">
                                        Seleccione su nuevo logo:
                                    </label>
                                    <div className="col-sm-9">
                                        <div className="input-group mb-3">
                                            <input type="file" accept="image/*"
                                                onChange={handleImageSelect} className="form-control" ref={inputFile} />
                                        </div>
                                    </div>
                                </div>
                                {imagenSeleccionada ?
                                    <div className="col text-center">
                                        <button className="btn btn-primary fa-solid fa-floppy-disk"
                                            type="button"
                                            onClick={() => {
                                                if (imagenSeleccionada) {
                                                    handleActualizarImagen()
                                                    setModalIsOpen(false)
                                                    limpiar()
                                                } else {
                                                    toast.warning("Debe seleccionar un archivo para guardar, intente nuevamente")
                                                }
                                            }} />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </>
                        : null}

                {/* Eliminar o modificar imagen */}
                <div className="container mt-4">
                    <div className="row text-center">
                        {existeA ? <>
                            {ocultarBtn ? null : <>
                                <div className="col">
                                    <button className="btn bnt-sm btn-primary fa-solid fa-pen-to-square" title="Cambiar Imagen"
                                        onClick={() => {
                                            setUpdateImg(true)
                                            setOcultarBtn(true)
                                        }} />
                                </div>
                            </>}
                        </>
                            : null}
                    </div>
                </div>
            </Modal>
        </>)
}
