import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { read, utils } from "xlsx";
import { actualizarDocumentoDatabase } from "../../config/firebase";

export const CarMasClasificaciones = ({
  permiso,
  head,
  listaClasif,
  onRespuesta
}) => {

  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const [sonIguales, setSonIguales] = useState(false);
  const [minuscula, setMinuscula] = useState([]);
  const [errores, setErrores] = useState([]);
  const [datosX, setDatosX] = useState([]);
  const estados = ["ACTIVO", "INACTIVO"];
  const [headX, setHeadX] = useState([]);
  const [infoX, setInfoX] = useState([]);
  const [ok, setOk] = useState(false);
  const [per, setPer] = useState(false);

  /******** FUNCIONES ***********/
  const enviarRespuestaCarga = (resp) => {
    if (resp !== "") {
      const nuevaRespuesta = resp;
      onRespuesta(nuevaRespuesta);
    }
  };

  useEffect(() => {
    const temporal = permiso;
    if (temporal.length > 0) {
      setPer(temporal.includes("cargaMasClasif"));
    }
  }, [permiso]);

  useEffect(() => {
    const seleccionarArchivo = () => {
      const fileInput = document.getElementById("file-input");
      fileInput.click();
    };

    const fileInputButton = document.getElementById("file-input-button");
    if (fileInputButton) {
      fileInputButton.addEventListener("click", seleccionarArchivo);
    }
    return () => {
      if (fileInputButton) {
        fileInputButton.removeEventListener("click", seleccionarArchivo);
      }
    };
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      setDatosX(jsonData);
      toast.success("Datos obtenidos correctamente");
      const cx = jsonData.length >= 1 ? Array.from(jsonData[0]) : [];
      const dxArray = jsonData.length >= 1 ? Array.from(jsonData) : [];
      dxArray.splice(0, 1);
      setHeadX(cx);
      setInfoX(dxArray);
    };
    reader.readAsArrayBuffer(file);
  };

  const validarCabecerasIguales = () => {
    const ct = head.map((valT) => valT?.toLowerCase());
    const cx = headX.map((valX) => valX?.toLowerCase());

    const res = ct.length === cx.length && ct.every((valor, index) => valor === cx[index].toLowerCase());
    if (res) {
      toast.success("Las cabeceras son iguales");
      convertirA_Objeto(datosX);
    } else {
      toast.warning(
        "Las cabeceras no coinciden, verifique el archivo e intente nuevamente."
      );
    }
    setSonIguales(res);
  };

  const convertirA_Objeto = (val) => {
    const resultado = {};

    for (let i = 1; i < val.length; i++) {
      const item = val[i];
      const objeto = {};

      for (let j = 0; j < item.length; j++) {
        const clave = val[0][j];
        let valor = item[j];
        if (typeof valor === "string") {
          valor = valor.toUpperCase()
        }
        if (typeof valor === "string") {
          valor = valor.trim().toUpperCase();
        }
        if (clave === "tipoClasificacion") {
          if (typeof valor === "number") {
            valor = valor.toString()
          }
        }
        if (valor !== undefined && valor !== null && valor !== "") {
          objeto[clave] = valor;
        }

      }
      resultado[i] = objeto;
    }
    setMinuscula(resultado);
  };

  const validarData = () => {
    let contador = 0;
    const listaNueva = [];

    if (minuscula && Object.keys(minuscula).length > 0) {
      for (const [key, value] of Object.entries(minuscula)) {
        contador++;
        const tipo = value.tipoClasificacion;
        const code = value.codigo;
        const desc = value.descripcion;
        const clasifExiste = listaClasif.find((item) => item.tipoClasificacion === tipo);
        if (!clasifExiste) listaNueva.push(`La clasificación tipo: ${tipo} no se existe.`);

        let lst = clasifExiste?.lista;
        const codigoExiste = lst?.find(item => item.codigo === code);
        if (codigoExiste !== undefined) listaNueva.push(`El código: ${code} ya existe.`)

        const descExiste = lst?.find((item) => item.descripcion === desc);
        if (descExiste !== undefined) listaNueva.push(`La descripción: ${desc} ya existe.`)

        if (contador === Object.keys(minuscula).length) {
          setErrores(listaNueva)
          if (listaNueva.length === 0) {
            toast.success("Puede subir la información sin problemas")
            setOk(true)
          }
        }
      }
    }
  };

  const guardarMasivos = async () => {
    if (minuscula && Object.keys(minuscula).length > 0) {
      let datosPorTipo = {};
      let clasifMap = {};

      listaClasif.forEach(obj => {
        if (!datosPorTipo[obj.tipoClasificacion]) {
          datosPorTipo[obj.tipoClasificacion] = [];
        }
        clasifMap[obj.tipoClasificacion] = obj;
      });

      for await (const [key, value] of Object.entries(minuscula)) {
        datosPorTipo[value.tipoClasificacion].push({
          codigo: value.codigo,
          descripcion: value.descripcion,
          estadoI: "ACTIVO",
        });
      }

      for (const tipo in datosPorTipo) {
        if (datosPorTipo.hasOwnProperty(tipo)) {
          const data = datosPorTipo[tipo];
          const clasifExiste = clasifMap[tipo];

          if (clasifExiste) {
            const doc = clasifExiste.idDocumento;
            const listaBD = clasifExiste.lista;
            const enviarData = [...listaBD, ...data]

            if (enviarData.length !== listaBD.length) {
              try {
                await actualizarDocumentoDatabase(`${emp}_clasificaciones`, doc, { lista: enviarData });
              } catch (error) {
                console.error(error)
              }
            }
          }
        }
      }
      enviarRespuestaCarga("Ok");
    }
  };

  const tableHeaders = head.map((header, index) => {
    return <th key={index}>{header}</th>;
  });


  //************************************/
  return (
    <>
      <ToastContainer position="bottom-right" />

      {/* Boton carga masiva */}
      {datosX.length === 0 ?
        (<>
          <button
            id="file-input-button"
            // disabled={!per}
            disabled
            className="btn fa-solid fa-file-csv"
            title="Cargar Datos desde Excel" />
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange} />
        </>)
        : null}

      {datosX.length >= 1 ? (
        <button
          className="btn btn-sm fa-regular fa-eye"
          data-bs-toggle="modal"
          data-bs-target="#ModalVistaDatos"
          id="modalEliminar"
          title="Visualizar Datos Cargados"
          onClick={() => validarCabecerasIguales()} />
      )
        : null}

      {/* +Modal de Visualizacion*/}
      <div
        className="modal fade "
        aria-hidden="true"
        id="ModalVistaDatos"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false" >

        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header">
              <h3 className={sonIguales ? "modal-title " : "modal-title text-danger"}>
                {sonIguales
                  ? "Las cabeceras coinciden"
                  : "Las cabeceras no coinciden"}
              </h3>
            </div>

            <div className="modal-body">
              <table className="table table-hover table-striped table-sm">
                <thead className="text-center">
                  <tr>{tableHeaders}</tr>
                </thead>

                <tbody className="text-center" id="tabla_articulos">
                  {infoX.map((x, index) => (
                    <tr key={index}>
                      {x.map((y, colIndex) => (
                        <td key={colIndex}>
                          {y}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="modal-footer">
              {sonIguales && !ok ?
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalError"
                  id="modalEliminar"
                  title="Visualizar Datos Cargados"
                  onClick={() => validarData()} >
                  Validar data
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                }} >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* -Modal de fallas */}
      <div
        className="modal fade "
        aria-hidden="true"
        id="ModalError"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false">

        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header">
              <h3 className="modal-title ">
                Resultado validacion
              </h3>
            </div>

            <div className="modal-body">
              {ok ?
                <h5 className="modal-title ">
                  Puede constinuar con la carga, la validacion ha sido existosa
                </h5>
                : <>
                  <h6 className={ok ? "modal-title " : "modal-title text-danger"}>
                    Se han detectado problemas de coincidencia en los siguientes campos. Por favor, corríjalos e inténtelo de nuevo.
                  </h6>
                  <table className="table table-hover table-striped table-sm">
                    <thead className="text-center">
                      <tr><th>Problemas</th></tr>
                    </thead>

                    <tbody className="text-center" id="tabla_articulos">
                      {errores.map((x) => (
                        <tr>
                          <td >
                            {x}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>}
            </div>

            <div className="modal-footer">
              {ok ?
                <button
                  type="button"
                  className="btn btn-primary text-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDatosX([]);
                    setInfoX([]);
                    setHeadX([]);
                    guardarMasivos();
                  }} >
                  Cargar Datos
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                }}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
