import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

export const PlantillaExcel = ({ origen, onRespuesta, tipo, permiso }) => {

    const bodegas = { consecutivo: "hm-1", bodega: "bdg-3", ubicacion: "dj-12", cant_disponible: "3", listaSeriales: "t11,t12,t13/*", observacion: "Si las hay/*", usaserial: "1(si),0(no)", valor: "12500/0" }

    const proyectos = { consecutivo: "hm-1", proyecto: "proy-2", cant_reservada: "3", listaSeriales: "t11,t12,t13/*", observacion: "Si las hay/*", usaserial: "1(si),0(no)", valor: "12500/0" }

    const articulos = { consecutivo: "hm-2", clasificacion1: "marca del articulo", clasificacion2: "familia del articulo", clasificacion3: "proveedor del articulo", des_Corta: "martillo", des_Larga: "martillo mango de caucho", referencia: "mtllo12", um: "UND", uc: "1", tipo: "herramienta", ult_Compra: "2023-07-05", ult_Costo: "32600", serial: "1(si), 0(no)", estado: "activo//inactivo" }

    const clasificaciones = { tipoClasificacion: "1", codigo: "smg", descripcion: "samsung" }

    const [perArt, setPerArt] = useState(false);
    const [perClas, setPerClas] = useState(false);
    /*********** Funciones **************/
    useEffect(() => {
        if (permiso.length > 0) {
            if (tipo === "Clasificaciones") {
                setPerClas(permiso.includes("descPlantClasif"));
            } else {
                setPerArt(permiso.includes("descargaPlantArt"));
            }
        }
    }, [tipo])

    /* Descargar plantilla */

    const descargarPlantilla = () => {
        let ws;
        // Crear un nuevo libro de Excel
        const wb = XLSX.utils.book_new();
        switch (tipo) {
            case "Bodega":
                ws = XLSX.utils.json_to_sheet([bodegas], {});
                break;
            case "Proyecto":
                ws = XLSX.utils.json_to_sheet([proyectos], {});
                break;
            case "Articulos":
                ws = XLSX.utils.json_to_sheet([articulos], {});
                break;
            case "Clasificaciones":
                ws = XLSX.utils.json_to_sheet([clasificaciones], {});
                break;
            default:
                break;
        }

        // Agregar la hoja de trabajo al libro
        XLSX.utils.book_append_sheet(wb, ws, `Plantilla ${tipo}`);

        // Generar el archivo Excel
        XLSX.writeFile(wb, `Plantilla_${origen}_${tipo}.xlsx`);

    };

    /* Enviar respuesta descarga */
    const enviarRespuestaDescarga = (resp) => {
        if (resp !== "") {
            onRespuesta(resp);
        }
    };


    /*************************************************************/

    return (
        <>
            {tipo === "Articulos" ?

                <button
                    // disabled={!perArt}
                    disabled
                    className="btn fa-solid fa-circle-down"
                    title="Descargar Plantilla Excel art"
                    onClick={() => {
                        enviarRespuestaDescarga("Ok")
                        descargarPlantilla()
                    }} />
                : null}

            {tipo === "Clasificaciones" ?
                <button
                    disabled
                    // disabled={!perClas}
                    className="btn fa-solid fa-circle-down"
                    title="Descargar Plantilla Excel clas"
                    onClick={() => {
                        enviarRespuestaDescarga("Ok")
                        descargarPlantilla()
                    }} />
                : null}

        </>
    );
};

