import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  guardarDatabase
} from "../../config/firebase";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "../miniComponentes";

export const Consecutivos = ({ permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const tipo = JSON.parse(localStorage.getItem("token")).tipo;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaConsecutivos, setListaConsecutivos] = useState([]);

  const [valorInicial, setValorInicial] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [valorActual, setValorActual] = useState(0);
  const [valorFinal, setValorFinal] = useState(1000);
  const [estado, setEstado] = useState("ACTIVO");
  const [prefijo, setPrefijo] = useState("");
  const [id, setId] = useState("");

  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;
  const usuario_modificacion = user;
  const usuario_creacion = user;

  const [adicionar, setAdicionar] = useState(0);
  const tablaRef = useRef(null);

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [idDocumento, setIdDocumento] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [clase, setClase] = useState("");

  // ***************Funciones**************
  useEffect(() => {
    changeTitle("Admin. Consecutivos");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8], user, "consecutivos");
    cargarConsecutivos();
    isMobile(setMobile);
  };

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);

  //-----------CRUD----------
  const cargarConsecutivos = async () => {
    setIsLoading(true);
    let consecut;
    try {
      const listaTempConsecutivos = await consultarDatabase(`${emp}_consecutivos`);
      if (listaTempConsecutivos.length === 0) {
        consecut = [{ prefijo: "Sin Datos" }]
      } else {
        consecut = listaTempConsecutivos.sort(sortBy("clase"));
      }
      setListaConsecutivos(consecut);
      setDocumentosFiltrados(consecut);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdicionar = async () => {
    const respuesta = listaConsecutivos.find((c) => c.prfijo === prefijo);
    if (respuesta !== undefined) {
      resetStates();
      toast.info("El prefijo: " + prefijo + " ya se encuentra registrado, intente de nuevo");
    } else {
      const enviarConsecutivo = {
        id: uniqid("consecutivo-"),
        prefijo,
        descripcion,
        valorInicial: parseInt(valorInicial),
        valorActual: parseInt(valorActual),
        valorFinal: parseInt(1000),
        usuario_creacion,
        fecha_creacion,
        clase,
        estado,
      };

      if (prefijo === "" || descripcion === "" ||
        valorActual === "" || valorInicial === "" || clase === "" || estado === "") {
        toast.warning("Diligencie Todos los campos de manera correcta");
      } else {
        setIsLoading(true)
        try {
          await guardarDatabase(`${emp}_consecutivos`, enviarConsecutivo);
          resetStates();
          toast.success("Consecutivo agregado de manera correcta");
          await cargarConsecutivos();
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = async (c) => {
    setIdDocumento(c.idDocumento);
    setPrefijo(c.prefijo);
    setDescripcion(c.descripcion);
    setValorInicial(c.valorInicial);
    setValorActual(c.valorActual);
    setValorFinal(c.valorFinal);
    setClase(c.clase);
    setEstado(c.estado);
    setId(c.id);
  };

  const handleClickModificarBd = async () => {
    if (idDocumento && idDocumento !== "") {
      const enviarConsecutivo = {
        descripcion,
        valorInicial: parseInt(valorInicial),
        valorActual: parseInt(valorActual),
        valorFinal: parseInt(valorFinal),
        usuario_modificacion,
        fecha_modificacion,
        estado,
        id,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDocumento, enviarConsecutivo);
        toast.success("Consecutivo modificado correctamente");
        resetStates();
        await cargarConsecutivos();
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("Seleccione un consecutivo valido para modificarlo");
    }
  };

  const resetStates = () => {
    setClase("");
    setPrefijo("");
    setDescripcion("");
    setValorInicial("");
    setValorActual("");
    setValorFinal("");
    setEstado("");
  };

  //---------Columnas-------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //----------Paginación-------------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaConsecutivos(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //-------------- Inicio componente --------------
  return (
    <>
      {isLoading ?
        <LoaderES />
        :
        (<>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            <div className="row ms-2 me-2">

              {/* botones de opcion */}
              <div className="col-sm-4 align-self-start text-start">
                <div className="btn-group">

                  {/* Adicionar */}
                  <button
                    className="btn fa-solid fa-plus"
                    disabled={!permisos.includes("crearConsec")}
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarAdicionar"
                    id="modaladicionar"
                    title="Crear Nuevo Consecutivo"
                    onClick={() => setAdicionar(1)} />

                  {/* Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                  {/* Actualizar */}
                  <button
                    className="btn fa-solid fa-rotate"
                    title="Actualizar Datos"
                    onClick={cargarConsecutivos} />
                </div>
              </div>

              {/* filtrar */}
              <div className="col-sm-8">
                <Filtrar2
                  tabla="myTable"
                  documentos={listaConsecutivos}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={" mt-5 " + (mobile ? " mb-5" : "")} />

          {permisos.includes("verConsec") && (
            mobile ?
              (<>
                {registrosPaginaActual.map((consecutivo) => (
                  consecutivo.prefijo === "Sin Datos" ?
                    <button
                      className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                      disabled={!permisos.includes("crearConsec")}
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarAdicionar"
                      id="modaladicionar"
                      title="Crear Nuevo Consecutivo"
                      onClick={() => setAdicionar(1)}
                    />
                    :
                    <div key={consecutivo.prefijo}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + consecutivo.prefijo}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none" }}>{consecutivo.prefijo}:</th>
                            <td style={{ minWidth: "260px", resize: "none" }} className={(consecutivo.estado !== "ACTIVO") ? "text-danger" : ""}>{consecutivo.descripcion}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + consecutivo.prefijo}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody>
                              <DatoTablaMobile titulo={"Clase"} dato={consecutivo.clase} columna={col2} />
                              <DatoTablaMobile titulo={"Prefijo"} dato={consecutivo.prefijo} columna={col3} clase={(consecutivo.estado !== "ACTIVO") ? "text-danger" : ""} />
                              <DatoTablaMobile titulo={"Descripcion"} dato={consecutivo.descripcion} columna={col4} />
                              <DatoTablaMobile titulo={"Valor Inicial"} dato={consecutivo.valorInicial} columna={col5} />
                              <DatoTablaMobile titulo={"Valor Actual"} dato={consecutivo.valorActual} columna={col6} />
                              <DatoTablaMobile titulo={"Valor Final"} dato={consecutivo.valorFinal} columna={col7} />
                              <DatoTablaMobile titulo={"Estado"} dato={consecutivo.estado} columna={col8} clase={(consecutivo.estado !== "ACTIVO") ? "text-danger" : ""} />
                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Edit </th>
                                <td>
                                  <button
                                    className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                    disabled={!permisos.includes("editarConsec")}
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModificarAdicionar"
                                    id="modalModificar"
                                    title="Modificar Item Seleccionado"
                                    onClick={() => {
                                      handleClickTraerData(consecutivo);
                                      setAdicionar(0);
                                    }} />
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                ))}
              </>)
              :
              (<div className="table-responsive abs-center-table">
                {/* Tabla de artículos  */}
                <table
                  id="myTable"
                  ref={tablaRef}
                  className="table table-hover table-striped table-sm table-bordered mt-3"
                >
                  <thead className="text-center">
                    <tr>
                      <ThTablaH ordenar={ordenar} dOrdenar={"clase"} columna={col2} titulo={"Clase"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"prefijo"} columna={col3} titulo={"Prefijo"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"descripcion"} columna={col4} titulo={"Descripcion"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"valorInicial"} columna={col5} titulo={"Val. Inicial"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"valorActual"} columna={col6} titulo={"Val. Actual"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"valorFinal"} columna={col7} titulo={"Val. Final"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={"estado"} columna={col8} titulo={"Estado"} />
                      <th className="text-center">Edit</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual.map((consecutivo) => (
                      consecutivo.prefijo === "Sin Datos" ?
                        <tr>
                          <td colSpan={"100%"} className="text-center">
                            <button
                              className="btn fa-solid fa-plus"
                              disabled={!permisos.includes("crearConsec")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modaladicionar"
                              title="Crear Nuevo Consecutivo"
                              onClick={() => setAdicionar(1)} />
                          </td>
                        </tr>
                        :
                        <tr key={consecutivo.id}>
                          <TdTablaD columna={col2} dato={consecutivo.clase} />
                          <TdTablaD columna={col3} dato={consecutivo.prefijo} clase={consecutivo.estado !== "ACTIVO" && "text-danger"} />
                          <TdTablaD columna={col4} dato={consecutivo.descripcion} />
                          <TdTablaD columna={col5} dato={consecutivo.valorInicial} />
                          <TdTablaD columna={col6} dato={consecutivo.valorActual} />
                          <TdTablaD columna={col7} dato={consecutivo.valorFinal} />
                          <TdTablaD columna={col8} dato={consecutivo.estado} clase={consecutivo.estado !== "ACTIVO" && "text-danger"} />
                          <td className="text-center">
                            {/* +Btn modificar */}
                            <button
                              className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                              disabled={!permisos.includes("editarConsec")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modalModificar"
                              title="Modificar Item Seleccionado"
                              onClick={() => {
                                handleClickTraerData(consecutivo);
                                setAdicionar(0);
                              }} />
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>)
          )}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados} />

          {/* +Modal de Modificar // Adicionar*/}
          <div className="modal fade"
            aria-hidden="true"
            id="ModificarAdicionar"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title">
                    {adicionar === 1 ? "Crear " : "Modificar "} Consecutivo
                  </h5>
                </div>

                <div className="modal-body">
                  {/* Prefijo */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Prefijo:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Prefijo"
                      disabled={!adicionar}
                      value={prefijo}
                      onChange={(e) => setPrefijo(e.target.value.toUpperCase())} />
                  </div>

                  {/* Descripcion */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Descripcion:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Descripcion"
                      disabled={!adicionar}
                      value={descripcion}
                      onChange={(e) => setDescripcion(e.target.value.toUpperCase())} />
                  </div>

                  {/* Val. Inicial */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Val. Inicial:</span>
                    <input
                      type="number"
                      min={0}
                      max={9999}
                      className="form-control"
                      placeholder="Val. Inicial"
                      value={valorInicial}
                      onChange={(e) => setValorInicial(e.target.value)}
                    />
                  </div>

                  {/* Val. Actual */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text"> Val. Actual:</span>
                    <input
                      type="number"
                      min={0}
                      max={9999}
                      className="form-control"
                      placeholder="val. Actual"
                      value={valorActual}
                      onChange={(e) => setValorActual(e.target.value)} />
                  </div>

                  {/* Val. Final */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Val. Final: </span>
                    <input
                      type="number"
                      min={0}
                      max={1000}
                      className="form-control"
                      placeholder="Val. Final"
                      disabled
                      value={valorFinal}
                      onChange={(e) => setValorFinal(e.target.value)} />
                  </div>

                  {/* Clase */}
                  {adicionar !== 0 ? <>
                    <div className="input-group input-group-sm mb-2">
                      <span className="input-group-text">Clase:</span>
                      <select
                        className="form-select"
                        value={clase}
                        onChange={(e) => setClase(e.target.value.toUpperCase())}>
                        <option value="" disabled >Seleccione</option>
                        {tipo === "SUPERADMIN" ?
                          <option value="DOCUMENTO">DOCUMENTO</option>
                          : null}
                        <option value="ARTICULO">ARTÍCULO</option>
                        <option value="BODEGA">BODEGA</option>
                        <option value="PROYECTO">PROYECTO</option>
                      </select>
                    </div>
                  </>
                    : null}

                  {/* estado */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Estado:</span>
                    <select
                      className="form-select"
                      placeholder="Estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)} >
                      <option value="" disabled>Seleccione</option>
                      <option value="ACTIVO">Activo</option>
                      <option value="INACTIVO">Inactivo</option>
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      adicionar === 1
                        ? handleClickAdicionar()
                        : handleClickModificarBd()
                    }>
                    {adicionar === 1 ? "Crear" : "Modificar"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => resetStates()} >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Clase"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Prefijo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={" Descripción"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Val. Inicial"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Val. Actual"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={" Val. Final"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consecutivos" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>)}
    </>
  );
};
