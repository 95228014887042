import React from 'react'

export const ModalArtReport = ({ consecutivo, lista }) => {
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-light justify-content-center">
                            <h5 className="modal-title " id="exampleModalLabel">Artículos del consecutivo {consecutivo}</h5>
                        </div>

                        <div className="modal-body">
                            <div className="accordion" id="myAccordion">
                                {Object.entries(lista)?.map(([group, items], idx) => (
                                    <div className="accordion-item" key={idx}>
                                        <h2 className="accordion-header" id={`heading${idx}`}>
                                            <button className="accordion-button text-secondary bg-primary-subtle rounded-3 p-2" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${idx}`} aria-expanded="true" aria-controls={`collapse${idx}`}>
                                                {group}
                                            </button>
                                        </h2>
                                        <div id={`collapse${idx}`} className="accordion-collapse collapse" aria-labelledby={`heading${idx}`} data-bs-parent="#myAccordion">
                                            <div className="accordion-body">
                                                <ul>
                                                    {items.map((item, idx) => (
                                                        <li key={idx}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
