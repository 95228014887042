import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { DocumentoPDF } from "../components/DocumentoPDF";
import { Imagen } from "../components/Imagen";
import { Login } from "../components/Login";
import { Menu } from "../components/Menu";
import { RutaPrivada } from "./../components/RutaPrivada";
export const AppRouter = () => {
  return (
    <>
      <Router basename="/EasyStock">
        <Switch>
          {/* RUTA PUBLICA */}
          <Route path="/" exact component={Login} />

          {/* RUTA PRIVADA */}
          <RutaPrivada path="/home" component={Menu} />

          <RutaPrivada path="/mi_empresa" component={Menu} />

          <RutaPrivada path="/admin_empresa" component={Menu} />
          <RutaPrivada path="/admin_usuario" component={Menu} />
          <RutaPrivada path="/migracion" component={Menu} />
          <RutaPrivada path="/cambioURL" component={Menu} />

          <RutaPrivada path="/moventrada" component={Menu} />
          <RutaPrivada path="/movsalida" component={Menu} />
          <RutaPrivada path="/movimiento" component={Menu} />
          <RutaPrivada path="/mov_pendientes" component={Menu} />
          <RutaPrivada path="/dev_pendientes" component={Menu} />

          <RutaPrivada path="/cons_inventario" component={Menu} />
          <RutaPrivada path="/cons_movimientos" component={Menu} />
          <RutaPrivada path="/cons_articulo" component={Menu} />
          <RutaPrivada path="/cons_solicitud" component={Menu} />
          <RutaPrivada path="/gest_solicitud" component={Menu} />
          <RutaPrivada path="/cons_mov_transito" component={Menu} />
          <RutaPrivada path="/cons_ajuste_inv" component={Menu} />


          <RutaPrivada path="/report_entradas" component={Menu} />
          <RutaPrivada path="/report_salidas" component={Menu} />
          <RutaPrivada path="/report_movimientos" component={Menu} />
          <RutaPrivada path="/report_solicitudes" component={Menu} />

          <RutaPrivada path="/solicitudes_epp" component={Menu} />
          <RutaPrivada path="/solicitudes_consumible" component={Menu} />
          <RutaPrivada path="/solicitudes_herramienta" component={Menu} />

          <RutaPrivada path="/ajuste_inventario" component={Menu} />

          <RutaPrivada path="/articulos" component={Menu} />
          <RutaPrivada path="/bodegas" component={Menu} />
          <RutaPrivada path="/proyectos" component={Menu} />
          <RutaPrivada path="/clasificaciones" component={Menu} />
          <RutaPrivada path="/terceros" component={Menu} />
          <RutaPrivada path="/consecutivos" component={Menu} />
          <RutaPrivada path="/unidades" component={Menu} />
          <RutaPrivada path="/usuarios" component={Menu} />
          <RutaPrivada path="/usuariopermiso" component={Menu} />
          {/* <RutaPrivada path="/hoja_vida_art" component={Menu} /> */}
          <RutaPrivada path="/hoja_vida_art" component={Menu} />
          {/* <RutaPrivada path="/yo" component={Menu} /> */}

          <RutaPrivada path="/imagen" component={Imagen} />

          <RutaPrivada path="/docpdf" component={DocumentoPDF} />

        </Switch>
      </Router>
    </>
  );
};
