import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";
import { changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";

import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  actualizarDocumentoDatabaseIdCampo,
  consultarDocumentoWhere,
  guardarDatabaseId
} from "../../config/firebase";
import {CheckboxCol} from "../CheckboxCol";
import { LoaderES } from "../LoaderES";

export const MovPendientes = ({ inventario, articulos, kardex, movimientos, listaFusion, permisos }) => {
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const nombre = JSON.parse(localStorage.getItem("token")).name;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const rol = JSON.parse(localStorage.getItem("token")).tipo;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));
  const location = useLocation();
  const history = useHistory();
  const [origen, setOrigen] = useState("");

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;

  const [articulosUnicosEnInventario, setArticulosUnicosEnInventario] = useState([]);
  const [listaFusionMovimientos, setListaFusionMovimientos] = useState([]);
  const [listaInventarioActual, setListaInventarioActual] = useState([]);
  const [listaPendientesB, setListaPendientesB] = useState([]);
  const [listaPendientesP, setListaPendientesP] = useState([]);
  const [listaMovimientos, setListaMovimientos] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaKardex, setListaKardex] = useState([]);

  const [observacionPendientes, setObservacionPendientes] = useState("");
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("pendientesB");

  const [pendientesP, setPendientesP] = useState(0);
  const [pendientesB, setPendientesB] = useState(0);
  const [recibir, setRecibir] = useState(false);
  const [tipo, setTipo] = useState("");

  const [contProyectoEA, setContProyectoEntregaAsignar] = useState(0);
  const [contProyectoEM, setContProyectoEntregaMover] = useState(0);
  const [contBodegaEA, setContBodegaEntregaAsignar] = useState(0);
  const [contBodegaEM, setContBodegaEntregaMover] = useState(0);

  const [lineaRecibir, setLineaRecibir] = useState({});
  const [mobile, setMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [esRespPry, setEsRespPry] = useState(null);
  const [esRespBdg, setEsRespBdg] = useState(null);

  const [col1, setCol1] = useState(true);
  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);
  const [col14, setCol14] = useState(true);
  const [col15, setCol15] = useState(true);
  const [col16, setCol16] = useState(true);
  const [col17, setCol17] = useState(true);
  const [col18, setCol18] = useState(true);
  const [col19, setCol19] = useState(true);
  const [col20, setCol20] = useState(true);
  const [col21, setCol21] = useState(true);
  const [col22, setCol22] = useState(true);
  const [col23, setCol23] = useState(true);
  const [col24, setCol24] = useState(true);
  const [col25, setCol25] = useState(true);
  const [col26, setCol26] = useState(true);
  const [col27, setCol27] = useState(true);

  // const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  // const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  // const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  // const [paginaActual, setPaginaActual] = useState(1);
  // const [totalPaginas, setTotalPaginas] = useState(0);

  //************Funciones*************/
  useEffect(() => {
    changeTitle("Admin. Movimiento");
    isMobile(setMobile);
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum([setCol1, setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, setCol18, setCol19, setCol20, setCol21, setCol22, setCol23, setCol24, setCol25, setCol26, setCol27], user, "movPendietes");
    cargarInventarioActual();
    setListaMovimientos(movimientos);
    setListaKardex(kardex);
    pendientesUsuario("mover");
    pendientesUsuario("asignar");
    validarUser();
  };

  useEffect(() => {
    const existeComponente = (location.state)?.componente
    if (existeComponente) {
      setOrigen((location.state).origen);
    }
  }, [location]);

  useEffect(() => {
    cargarInventarioActual()
  }, [inventario]);

  useEffect(() => {
    setListaFusionMovimientos(listaFusion);
  }, [listaFusion]);

  useEffect(() => {
    setListaMovimientos(movimientos);
  }, [movimientos]);

  useEffect(() => {
    setListaKardex(kardex);
  }, [kardex]);

  //-------------Inv. Actual // Articulo ------------------
  const cargarInventarioActual = async () => {
    const inventarioTemporal = inventario
    setListaInventarioActual(inventarioTemporal);
    const articulosSet = new Set();
    inventarioTemporal.forEach((item) => {
      if (item.articulo) {
        articulosSet.add(item.articulo);
      }
    });
    setArticulosUnicosEnInventario(Array.from(articulosSet));
  };

  const cargarArticulos = async () => {
    const listaTemporal = articulos
    if (listaTemporal.length === 0) {
      toast.warning("No se encontraron artículos creados en la base de datos");
      setListaArticulos([{ id: 0 }]);
      ;
    } else {
      const listaFiltrada = listaTemporal.filter((item) => {
        return articulosUnicosEnInventario.includes(item.consecutivo);
      });
      setListaArticulos(listaFiltrada);

    }
  };

  useEffect(() => {
    if (articulosUnicosEnInventario.length === 0) {
      setListaArticulos([{ id: 0 }]);

    } else {
      cargarArticulos();
    }
  }, [articulosUnicosEnInventario]);

  useEffect(() => {
    pendientesUsuario("mover");
    pendientesUsuario("asignar");
  }, [listaFusionMovimientos]);


  //-----------Tablas---------------
  const manipularCabecerasTabla = (lista, tipo, subTipo) => {
    if (tipo === "pendientes") {
      if (subTipo === "mover") {
        let cPE = 0;
        let cBE = 0;
        lista.forEach((etb) => {
          if (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined) {
            cPE = cPE + 1;
          }
          if (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) {
            cBE = cBE + 1;
          }
        });
        setContBodegaEntregaMover(cBE);
        setContProyectoEntregaMover(cPE);
      } else if (subTipo === "asignar") {
        let cPE = 0;
        let cBE = 0;
        lista.forEach((etb) => {
          if (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined) {
            cPE = cPE + 1;
          }
          if (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) {
            cBE = cBE + 1;
          }
        });
        setContBodegaEntregaAsignar(cBE);
        setContProyectoEntregaAsignar(cPE);
      }
    }
  };

  useEffect(() => {
    manipularCabecerasTabla(listaPendientesB, "pendientes", "mover");
  }, [listaPendientesB]);

  useEffect(() => {
    manipularCabecerasTabla(listaPendientesP, "pendientes", "asignar");
  }, [listaPendientesP]);


  //------------Kardex--------------
  const modificarEstadoKrdx = async (id, estadoNue, art, estadoAct, idMov) => {
    let hj;
    const valor = id.split("-")[0];
    const nuevosDatos = listaKardex.find((lk) => lk.consecutivo === id);

    for (const key in nuevosDatos) {
      const obj = nuevosDatos[key];
      const enTransito = Object(obj);

      if (enTransito.articulo === art && enTransito.estado === estadoAct && enTransito.idMov === idMov) {
        hj = enTransito;
        hj.estado = estadoNue;
        hj.observacion = observacionPendientes;
        hj.usuario_modificacion = user;
        hj.fecha_modificacion = fecha_modificacion;
      }

      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, id, nuevosDatos);
      } catch (error) {
        console.error(error);
      }
    }
  };

  //--------------Pedidos-------------------
  const recibirPedido = async (tipo) => {
    let resp, listaEnviar;
    const datoAModificar = lineaRecibir;
    const idDocumento = datoAModificar.consecutivo;
    const valor = datoAModificar.consecutivo.split("-")[0];
    const art = listaArticulos.find(
      (art) => art.consecutivo === datoAModificar.articulo
    );
    const conSerial = art.serial;
    const idArtcl = art.id;
    let datosMov = listaMovimientos.find(
      (lk) => lk.consecutivo === idDocumento
    );

    let enviarInventario = {
      id: uniqid("inventario-"),
      idArticulo: idArtcl,
      articulo: datoAModificar?.articulo.toUpperCase(),
      fecha_carga: fecha_creacion,
      observacion: observacionPendientes,
      valor: parseFloat(datoAModificar?.valor),
      usuario_creacion: user.toUpperCase(),
      fecha_creacion,
      estado: "entregado",
    };

    const existeD = listaInventarioActual.find(
      (t) => t.consecutivo === datoAModificar.idDocDestino
    );
    const existeS = listaInventarioActual.find(
      (t) => t.consecutivo === datoAModificar.idDocSalida
    );

    if (existeD !== undefined && idDocumento !== "") {
      const listaFusion = conSerial
        ? [...datoAModificar?.listaSeriales, ...existeD?.listaSeriales]
        : [];

      if (tipo === "mover") {
        resp = await modificarArticuloEnTransito(existeD, existeD?.consecutivo, existeD?.cant_disponible, listaFusion, datoAModificar?.cantidad, "disponible", datoAModificar?.valor, "aceptar", conSerial);
      }
      if (tipo === "asignar") {
        resp = await modificarArticuloEnTransito(existeD, existeD?.consecutivo, existeD?.cant_reservada, listaFusion, datoAModificar?.cantidad, "reservada", datoAModificar?.valor, "aceptar", conSerial);
      }

      if (resp) {
        let datos = modifObjeto(datosMov, datoAModificar.articulo, "entregado", "transito", datoAModificar.idDocSalida);
        if (datosMov)
          setIsLoading(true)
        try {
          await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
          await modificarEstadoKrdx(idDocumento, "entregado", datoAModificar.articulo, "transito", datoAModificar.idMov);
          setObservacionPendientes("");
          setListaPendientesB([])
          setListaPendientesP([])
          setPendientesB(0);
          setPendientesP(0);
          pendientesUsuario("mover");
          pendientesUsuario("asignar");
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false)
        }
        history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })
      } else {
        toast.warning("Error al modificar el artículo entregado");
        return
      }
    } else {
      if (conSerial) {
        enviarInventario.listaSeriales = datoAModificar?.listaSeriales;
      }
      if (tipo === "mover") {
        enviarInventario.cant_disponible = parseFloat(datoAModificar?.cantidad);
        enviarInventario.bodega = datoAModificar?.bodegaDestino.toUpperCase();
        enviarInventario.ubicacion = datoAModificar?.ubicacionDestino.toUpperCase();
      }
      if (tipo === "asignar") {
        enviarInventario.cant_reservada = parseFloat(datoAModificar?.cantidad);
        enviarInventario.proyecto = datoAModificar?.proyectoDestino.toUpperCase();
      }

      const enviarB = { [enviarInventario.bodega + "\\" + enviarInventario.ubicacion + "\\" + enviarInventario.articulo]: enviarInventario, };
      const enviarP = { [enviarInventario.proyecto + "\\" + enviarInventario.articulo]: enviarInventario, };
      const enviar = tipo === "mover" ? enviarB : enviarP;
      const documento = tipo === "mover" ? enviarInventario.bodega.toLowerCase() : "proyectos";
      try {
        resp = await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
      } catch (error) {
        console.error(error);
      }
      let datos = modifObjeto(datosMov, datoAModificar.articulo, "entregado", "transito", datoAModificar.idDocSalida);
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
      } catch (error) {
        console.error(error);
      }
    }

    if (resp !== null && resp !== "") {
      if (existeS !== undefined) {
        setIsLoading(true)
        const bdg = existeS.bodega !== undefined ? existeS.bodega : existeS.proyecto;
        const documento = bdg.split("-")[0] === "BDG" ? bdg.toLowerCase() : "proyectos";

        try {
          listaEnviar = crearLista(existeS, observacionPendientes, "entregado");
          if (listaEnviar.lengh !== 0) {
            await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, existeS?.consecutivo, listaEnviar);
            await modificarEstadoKrdx(idDocumento, "entregado", datoAModificar.articulo, "transito", datoAModificar.idMov);
            setObservacionPendientes("");
            setListaPendientesB([])
            setListaPendientesP([])
            setPendientesB(0);
            setPendientesP(0);
            pendientesUsuario("mover");
            pendientesUsuario("asignar");
            toast.success("Se envio correctamente el recibido del artículo");
          }
          history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })

        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false)

        }
      }
    }

  };

  const rechazarPedido = async (tipo) => {
    let enviar,
      nuevaLisSeriales = [];
    if (tipo === "mover") {
      const datoAModificar = lineaRecibir;
      const idDocumento = datoAModificar.consecutivo;
      const valor = datoAModificar.consecutivo.split("-")[0];
      let bod = datoAModificar.bodegaSalida;
      let pro = datoAModificar.proyectoSalida;
      let series = datoAModificar.listaSeriales;

      const nuevosDatos = listaMovimientos.find((lk) => lk.consecutivo === idDocumento);
      let art = listaArticulos.find((art) => art.consecutivo === datoAModificar.articulo);
      let conSerial = art.serial;
      const existeS = listaInventarioActual.find((t) => t.consecutivo === datoAModificar.idDocSalida);
      const existeD = listaInventarioActual.find((t) => t.consecutivo === datoAModificar.idDocDestino);

      if (existeS !== undefined) {
        nuevaLisSeriales = conSerial ? [...series, ...existeS.listaSeriales] : [];
        let cant = bod !== undefined ? parseFloat(existeS.cant_disponible + datoAModificar.cantidad)
          : parseFloat(existeS.cant_reservada + datoAModificar.cantidad);
        const idConsS = existeS?.consecutivo;
        const docS = existeS.bodega !== undefined ? existeS.bodega : existeS.proyecto;
        const documentoS = docS.split("-")[0] === "BDG" ? docS.toLowerCase() : "proyectos";
        enviar = crearLista(existeS, observacionPendientes, "rechazado");
        if (conSerial) {
          enviar.listaSeriales = nuevaLisSeriales;
        }
        if (bod !== undefined) {
          enviar.cant_disponible = parseFloat(cant);
        }
        if (pro !== undefined) {
          enviar.cant_reservada = parseFloat(cant);
        }
        try {
          await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documentoS}`, idConsS, enviar);
        } catch (error) {
          console.error(error);
        }
        if (existeD !== undefined) {
          let idC = existeD.consecutivo;
          const docD = existeD.bodega !== undefined ? existeD.bodega : existeD.proyecto;
          const documentoD = docD.split("-")[0] === "BDG" ? docD.toLowerCase() : "proyectos";
          let data = crearLista(existeD, observacionPendientes, "rechazado");
          try {
            await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documentoD}`, idC, data);
          } catch (error) {
            console.error(error);
          }
        }
        let datos = modifObjeto(nuevosDatos, datoAModificar.articulo, "rechazado", "transito", datoAModificar.idDocSalida);
        setIsLoading(true)
        try {
          await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
          await modificarEstadoKrdx(datoAModificar.consecutivo, "rechazado", datoAModificar.articulo, "transito", datoAModificar.idMov);
          setPendientesB(0);
          setPendientesP(0);
          setListaPendientesB([])
          setListaPendientesP([])
          setObservacionPendientes("");
          toast.success("Se envio correctamente el rechazado del artículo");
          pendientesUsuario("mover");
          pendientesUsuario("asignar");
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false)
        }
      }
    }

    if (tipo === "asignar") {
      const datoAModificar = lineaRecibir;
      const idDocumento = datoAModificar.consecutivo;
      const valor = datoAModificar.consecutivo.split("-")[0];
      let bod = datoAModificar.bodegaSalida;
      let pro = datoAModificar.proyectoSalida;
      let series = datoAModificar.listaSeriales;

      const nuevosDatos = listaMovimientos.find((lk) => lk.consecutivo === idDocumento);
      let art = listaArticulos.find((art) => art.consecutivo === datoAModificar.articulo);
      let conSerial = art.serial;
      const existeS = listaInventarioActual.find((t) => t.consecutivo === datoAModificar.idDocSalida);
      const existeD = listaInventarioActual.find((t) => t.consecutivo === datoAModificar.idDocDestino);

      if (existeS !== undefined) {
        nuevaLisSeriales = conSerial ? [...series, ...existeS.listaSeriales] : [];
        let cant = bod !== undefined ? parseFloat(existeS.cant_disponible + datoAModificar.cantidad)
          : parseFloat(existeS.cant_reservada + datoAModificar.cantidad);
        const idConsS = existeS?.consecutivo;
        const docS = existeS.bodega !== undefined ? existeS.bodega : existeS.proyecto;
        const documentoS = docS.split("-")[0] === "BDG" ? docS.toLowerCase() : "proyectos";
        enviar = crearLista(existeS, observacionPendientes, "rechazado");
        if (conSerial) {
          enviar.listaSeriales = nuevaLisSeriales;
        }
        if (bod !== undefined) {
          enviar.cant_disponible = parseFloat(cant);
        }
        if (pro !== undefined) {
          enviar.cant_reservada = parseFloat(cant);
        }
        try {
          await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documentoS}`, idConsS, enviar);
        } catch (error) {
          console.error(error);
        }
        if (existeD !== undefined) {
          let idC = existeD.consecutivo;
          const docD = existeD.bodega !== undefined ? existeD.bodega : existeD.proyecto;
          const documentoD = docD.split("-")[0] === "BDG" ? docD.toLowerCase() : "proyectos";
          let data = crearLista(existeD, observacionPendientes, "rechazado");
          try {
            await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documentoD}`, idC, data);
          } catch (error) {
            console.error(error);
          }
        }
        let datos = modifObjeto(nuevosDatos, datoAModificar.articulo, "rechazado", "transito", datoAModificar.idDocSalida);
        setIsLoading(true)
        try {
          await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
          await modificarEstadoKrdx(datoAModificar.consecutivo, "rechazado", datoAModificar.articulo, "transito", datoAModificar.idMov);
          setPendientesP(0);
          setListaPendientesB([])
          setListaPendientesP([])
          setPendientesB(0);
          setObservacionPendientes("");
          toast.success("Se envio correctamente el rechazado del artículo");
          pendientesUsuario("mover");
          pendientesUsuario("asignar");
          history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false)
        }

      }
    }

  };

  const crearLista = (existe, observacionPendientes, estado) => {
    return {
      ...existe,
      estado: estado,
      observacion: observacionPendientes ? observacionPendientes : "",
      usuario_modificacion: user,
      fecha_modificacion,
    };
  };

  const modifObjeto = (datos, articulo, estadoNue, estadoAct, docSal) => {
    let hj;
    for (const key in datos) {
      const obj = datos[key];
      const enTransito = Object(obj);

      if (
        enTransito.articulo === articulo &&
        enTransito.estado === estadoAct &&
        enTransito.idDocSalida === docSal
      ) {
        hj = enTransito;
        hj.estado = estadoNue;
        hj.observacion = observacionPendientes;
        hj.usuario_modificacion = user;
        hj.fecha_modificacion = fecha_modificacion;
      }
    }
    return datos;
  };

  const modificarArticuloEnTransito = async (lista, idDocumento, cantidadBD, listaFusion, cantidad, tipoCant, valor, accion, conSerie) => {
    let cant = parseFloat(cantidadBD) + parseFloat(cantidad);
    cant = Number(Number.parseFloat(cant).toFixed(2));
    const doc = lista.bodega !== undefined ? lista.bodega : lista.proyecto;
    const documento = doc.split("-")[0] === "BDG" ? doc.toLowerCase() : "proyectos";
    let listaRecibir = {
      ...lista,
      valor: parseFloat(valor),
      usuario_modificacion: user,
      fecha_modificacion,
      observacion: observacionPendientes,
    };

    if (accion === "aceptar") {
      if (tipoCant === "disponible") {
        listaRecibir.cant_disponible = cant;
        listaRecibir.estado = "recibido";
      } else if (tipoCant === "reservada") {
        listaRecibir.cant_reservada = cant;
        listaRecibir.estado = "recibido";
      }
      if (conSerie) {
        listaRecibir.listaSeriales = listaFusion;
      }
    }

    if (accion === "devolver") {
      if (tipoCant === "disponible") {
        listaRecibir.cant_disponible = cant;
        listaRecibir.estado = "recibido";
      } else if (tipoCant === "reservada") {
        listaRecibir.cant_reservada = cant;
        listaRecibir.estado = "recibido";
      }
      if (conSerie) {
        listaRecibir.listaSeriales = listaFusion;
      }
    }
    if (idDocumento !== "" && idDocumento !== undefined) {
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, idDocumento, listaRecibir);
        accion === "aceptar" ? toast.success("Se envio correctamente el recibido del artículo") : toast.success("Se envio correctamente el rechazo del artículo");
        return true;
      } catch (error) {
        console.error(error);
        return false;
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("Error al enviar el rechazo del artículo al sistema");
      return false;
    }
  };

  const validarUser = async () => {
    const datosBodega = await consultarDocumentoWhere(`${emp}_bodegas`, "responsable", nombre);
    let bdg = datosBodega?.map((b) => b.consecutivo);
    const datosProyecto = await consultarDocumentoWhere(`${emp}_proyectos`, "responsable", nombre);
    let pry = datosProyecto?.map((b) => b.consecutivo);
    if (bdg.length > 0) {
      setEsRespBdg(true);
    } else {
      setEsRespBdg(false);
    }
    if (pry.length > 0) {
      setEsRespPry(true);
    } else {
      setEsRespPry(false);
    }
  };

  const pendientesUsuario = (tipo) => {
    let enTransitoB = [], enTransitoP = [];
    if (tipo === "mover") {
      enTransitoB = listaFusionMovimientos.filter((m) => m.consecutivo.split("-")[0] === "MOV" && m.estado === "transito");
      setPendientesB(enTransitoB.length);
      setListaPendientesB(enTransitoB);
    }
    if (tipo === "asignar") {
      enTransitoP = listaFusionMovimientos.filter((m) => m.consecutivo.split("-")[0] === "ASG" && m.estado === "transito");
      setListaPendientesP(enTransitoP);
      setPendientesP(enTransitoP.length);
    }
  };

  //-----Tablas--------
  const validarCheckboxes = (excluido, tipo) => {
    let contador = 0;
    if (tipo === "b") {
      if (col2 && excluido !== 'col2') contador++;
      if (col3 && excluido !== 'col3') contador++;
      if (col4 && excluido !== 'col4') contador++;
      if (col5 && excluido !== 'col5') contador++;
      if (col6 && excluido !== 'col6') contador++;
      if (col7 && excluido !== 'col7') contador++;
      if (col8 && excluido !== 'col8') contador++;
      if (col9 && excluido !== 'col9') contador++;
      if (col10 && excluido !== 'col10') contador++;
      if (col11 && excluido !== 'col11') contador++;
      if (col12 && excluido !== 'col12') contador++;
      if (col13 && excluido !== 'col13') contador++;
      if (col14 && excluido !== 'col14') contador++;
    }
    if (tipo === "p") {
      if (col15 && excluido !== 'col15') contador++;
      if (col16 && excluido !== 'col16') contador++;
      if (col17 && excluido !== 'col17') contador++;
      if (col18 && excluido !== 'col18') contador++;
      if (col19 && excluido !== 'col19') contador++;
      if (col20 && excluido !== 'col20') contador++;
      if (col21 && excluido !== 'col21') contador++;
      if (col22 && excluido !== 'col22') contador++;
      if (col23 && excluido !== 'col23') contador++;
      if (col24 && excluido !== 'col24') contador++;
      if (col25 && excluido !== 'col25') contador++;
      if (col26 && excluido !== 'col26') contador++;
    }
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //-------Paginación-----------
  // useEffect(() => {
  //   setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina));
  // }, [documentosFiltrados, registrosPorPagina]);

  // useEffect(() => {
  //   const inicio = (paginaActual - 1) * parseInt(registrosPorPagina);
  //   const fin = inicio + parseInt(registrosPorPagina);
  //   setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin));
  // }, [paginaActual, totalPaginas]);

  //************ Inicio componente ******************/
  return (
    <>
      <ToastContainer position="bottom-right" />

      {isLoading ?
        <LoaderES />
        :
        <>
          <div
            className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : !collapsed ? "250px" : "80px",
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1,
            }}
          />

          <hr className="my-3" />

          <div className={mobile ? "row justify-content-center ms-3 mt-4" : "row justify-content-start ms-3 mt-4"}>
            <div className="col-auto text-start">
              <button className="btn btn-primary fa-solid fa-angle-left btn-sm  me-4 "
                title="Volver"
                onClick={() => {
                  history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })
                }} />

              {/* Columnas */}
              <button
                className="btn fa-solid fa-table-columns"
                title="Seleccionar Columnas"
                data-bs-toggle="modal"
                data-bs-target="#ModificarColumnas"
                id="modalcolumnas"
              />
            </div>

          </div>

          {/* Menu header */}
          <ul className="nav nav-tabs mt-2">

            {/* Mov pendientes */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movBodega")}
              onClick={() => {
                setOpcionSeleccionada("pendientesB");
              }}>
              <p className={"nav-link " + (opcionSeleccionada === "pendientesB" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Movimientos Pendientes  <span className="text-danger" > ({pendientesB})</span></p>
            </li>

            {/* Asig Pendientes */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movProyecto")}
              onClick={() => {
                setOpcionSeleccionada("pendientesP");
              }}>
              <p className={"nav-link " + (opcionSeleccionada === "pendientesP" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}> Asignaciones Pendientes  <span className="text-danger" > ({pendientesP})</span></p>
            </li>

          </ul>

          <hr className="text-light mt-2 mb-0" />

          {mobile ?
            /* Mobile */
            <>
              {opcionSeleccionada === "pendientesB" ?
                (<>
                  {listaPendientesB?.map((articulo) => (
                    <div key={articulo.consecutivo + articulo.id}>
                      <table className="table table-sm table-hover table-striped mb-0 mt-2">
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + articulo.consecutivo}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ width: "75px", resize: "none" }}>{articulo.consecutivoFix}</th>
                            <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }} className={(articulo.estado !== "ACTIVO") ? "text-danger" : ""}>
                              <span className="truncate-text">
                                {articulo.des_Larga.length > 50 ? articulo.des_Larga.slice(0, 50) + "..." : articulo.des_Larga}
                              </span>
                            </td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + articulo.consecutivo}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody >
                              {!col1 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Fecha Mov.</th>
                                  <td>{articulo.fechaMover}</td>
                                </tr>
                              }
                              {!col2 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Consec. Art</th>
                                  <td>{articulo.consecutivoFix}</td>
                                </tr>
                              }
                              {!col3 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Descripcion</th>
                                  <td>{articulo.des_Larga}</td>
                                </tr>
                              }
                              {!col4 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Tipo</th>
                                  <td>{articulo.tipo}</td>
                                </tr>
                              }
                              {!col5 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td>{articulo.estado}</td>
                                </tr>
                              }
                              {!col6 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Valor</th>
                                  <td>${Intl.NumberFormat("es-CO").format(articulo.valor)}</td>
                                </tr>
                              }
                              {!col7 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Cantidad</th>
                                  <td>{articulo.cantidad}</td>
                                </tr>
                              }
                              {!col8 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Consec. Mov</th>
                                  <td>{articulo.consecutivoFixMov}</td>
                                </tr>
                              }
                              {contProyectoEM === 0 ? null : (
                                !col9 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}>Origen Proyecto</th>
                                    <td>{articulo.proyectoSalida}</td>
                                  </tr>
                              )}
                              {contBodegaEM === 0 ? null : (
                                !col10 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}> Origen Bodega</th>
                                    <td>{articulo.bodegaSalida}</td>
                                  </tr>
                              )}
                              {contBodegaEM === 0 ? null : (
                                !col11 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}>Ubicacion Origen</th>
                                    <td>{articulo.ubicacionSalida}
                                    </td>
                                  </tr>
                              )}
                              {!col12 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Destino </th>
                                  <td>{articulo.bodegaDestino}</td>
                                </tr>
                              }
                              {!col13 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Ubicacion Destino </th>
                                  <td>{articulo.ubicacionDestino}</td>
                                </tr>
                              }
                              {!col14 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Enviado por </th>
                                  <td>{articulo.usuario_creacion}</td>
                                </tr>
                              }

                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Actions </th>
                                <td>
                                  <div className="btn-group">
                                    {esRespBdg || rol === "ADMIN" || rol === "SUPERADMIN" ?
                                      <>
                                        <button
                                          className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                          data-bs-toggle="modal"
                                          title="Aceptar"
                                          data-bs-target="#modalPendientes"
                                          onClick={() => {
                                            setRecibir(true);
                                            setTipo("mover");
                                            setLineaRecibir(articulo);
                                          }}
                                        />

                                        <button
                                          className="btn btn-outline-danger btn-sm fa-solid fa-thumbs-down"
                                          data-bs-toggle="modal"
                                          title="Rechazar"
                                          data-bs-target="#modalPendientes"
                                          onClick={() => {
                                            setRecibir(false);
                                            setTipo("mover");
                                            setLineaRecibir(articulo);
                                          }}
                                        />
                                      </> : null}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </>)
                : null}

              {opcionSeleccionada === "pendientesP" ?
                (<>
                  {listaPendientesP?.map((articulo) => (
                    <div key={articulo.consecutivo + articulo.id}>
                      <table className="table table-sm table-hover table-striped mb-0 mt-2">
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + articulo.consecutivo}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ width: "75px", resize: "none" }}>{articulo.consecutivoFix}</th>
                            <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }} className={(articulo.estado !== "ACTIVO") ? "text-danger" : ""}>
                              <span className="truncate-text">
                                {articulo.des_Larga.length > 50 ? articulo.des_Larga.slice(0, 50) + "..." : articulo.des_Larga}
                              </span>
                            </td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + articulo.consecutivo}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody >
                              {!col15 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Fecha Asig.</th>
                                  <td>{articulo.fechaMover}</td>
                                </tr>
                              }
                              {!col16 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Consec. Art</th>
                                  <td>{articulo.consecutivoFix}</td>
                                </tr>
                              }
                              {!col17 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Descripcion</th>
                                  <td>{articulo.des_Larga}</td>
                                </tr>
                              }
                              {!col18 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Tipo</th>
                                  <td>{articulo.tipo}</td>
                                </tr>
                              }
                              {!col19 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td>{articulo.estado}</td>
                                </tr>
                              }
                              {!col20 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Valor</th>
                                  <td>${Intl.NumberFormat("es-CO").format(articulo.valor)}</td>
                                </tr>
                              }
                              {!col21 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Cantidad</th>
                                  <td>{articulo.cantidad}</td>
                                </tr>
                              }
                              {!col22 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Consec. Mov</th>
                                  <td>{articulo.consecutivoFixMov}</td>
                                </tr>
                              }
                              {contProyectoEA === 0 ? null : (
                                !col23 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}>Origen Proyecto</th>
                                    <td>{articulo.proyectoSalida}</td>
                                  </tr>
                              )}
                              {contBodegaEA === 0 ? null : (
                                !col24 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}> Origen Bodega</th>
                                    <td>{articulo.bodegaSalida}</td>
                                  </tr>
                              )}
                              {contBodegaEA === 0 ? null : (
                                !col25 ? null :
                                  <tr>
                                    <td></td>
                                    <th style={{ resize: "none" }}>Ubicacion Origen</th>
                                    <td>{articulo.ubicacionSalida}
                                    </td>
                                  </tr>
                              )}
                              {!col26 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Destino </th>
                                  <td>{articulo.proyectoDestino}</td>
                                </tr>
                              }
                              {!col27 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Enviado por </th>
                                  <td>{articulo.usuario_creacion}</td>
                                </tr>
                              }

                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Actions </th>
                                <td>
                                  <div className="btn-group">
                                    {esRespPry || rol === "ADMIN" || rol === "SUPERADMIN" ?
                                      <>
                                        <button
                                          className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                          data-bs-toggle="modal"
                                          title="Aceptar"
                                          data-bs-target="#modalPendientes"
                                          onClick={() => {
                                            setRecibir(true);
                                            setTipo("asignar");
                                            setLineaRecibir(articulo);
                                          }}
                                        />

                                        <button
                                          className="btn btn-outline-danger btn-sm fa-solid fa-thumbs-down"
                                          data-bs-toggle="modal"
                                          title="Rechazar"
                                          data-bs-target="#modalPendientes"
                                          onClick={() => {
                                            setRecibir(false);
                                            setTipo("asignar");
                                            setLineaRecibir(articulo);
                                          }}
                                        />
                                      </> : null}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </>)
                : null}
            </>
            /* Mobile */
            :
            /* Desktop */
            <>
              {opcionSeleccionada === "pendientesB" ?
                (<>
                  <div className="table-responsive mt-2">
                    <h5 className="text-center mb-4">Artículos movidos a bodega</h5>
                    <table className="table table-hover table-striped table-sm">
                      <thead className="text-center">
                        <tr>
                          <th className={col1 ? "text-center" : "d-none"}>Fecha Mov.</th>
                          <th className={col2 ? "text-center" : "d-none"}>Consec. <br /> Art</th>
                          <th className={col3 ? "text-center" : "d-none"}>Descripcion</th>
                          <th className={col4 ? "text-center" : "d-none"}>Tipo</th>
                          <th className={col5 ? "text-center" : "d-none"}>Estado</th>
                          <th className={col6 ? "text-center" : "d-none"}>Valor</th>
                          <th className={col7 ? "text-center" : "d-none"}>Cantidad</th>
                          <th className={col8 ? "text-center" : "d-none"}>Consec. <br /> Mov</th>
                          {contProyectoEM === 0 ? null : (
                            <th className={col9 ? "text-center" : "d-none"}>
                              Origen P
                            </th>
                          )}
                          {contBodegaEM === 0 ? null : (
                            <th className={col10 ? "text-center" : "d-none"}>
                              Origen B
                            </th>)}
                          {contBodegaEM === 0 ? null : (
                            <th className={col11 ? "text-center" : "d-none"}>
                              Ubic. <br /> Origen
                            </th>
                          )}
                          <th className={col12 ? "text-center" : "d-none"}>Destino</th>
                          <th className={col13 ? "text-center" : "d-none"}>Ubic. <br /> Destino</th>
                          <th className={col14 ? "text-center" : "d-none"}>Enviado por</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody className="text-center" id="tabla_articulos">
                        {listaPendientesB.map((art) => (
                          <tr key={art.articulo}>
                            <td className={col2 ? "text-center" : "d-none"}>{art.fechaMover}</td>
                            <td className={col3 ? "text-center" : "d-none"}>{art.consecutivoFix}</td>
                            <td className={col4 ? "text-center" : "d-none"}>{art.des_Larga}</td>
                            <td className={col5 ? "text-center" : "d-none"}>{art.tipo}</td>
                            <td className={col6 ? "text-center" : "d-none"}>{art.estado}</td>
                            <td className={col7 ? "text-center" : "d-none"}>${Intl.NumberFormat("es-CO").format(art.valor)}</td>
                            <td className={col8 ? "text-center" : "d-none"}>{art.cantidad}</td>
                            <td className={col9 ? "text-center" : "d-none"}>{art.consecutivoFixMov}</td>
                            {contProyectoEM === 0 ? null : (
                              <td className={col10 ? "text-center" : "d-none"}>{art.proyectoSalida}</td>
                            )}
                            {contBodegaEM === 0 ? null : (
                              <td className={col11 ? "text-center" : "d-none"}>{art.bodegaSalida}</td>
                            )}
                            {contBodegaEM === 0 ? null : (
                              <td className={col12 ? "text-center" : "d-none"}>{art.ubicacionSalida}</td>
                            )}
                            <td className={col13 ? "text-center" : "d-none"}>{art.bodegaDestino}</td>
                            <td className={col14 ? "text-center" : "d-none"}>{art.ubicacionDestino}</td>
                            <td className={col15 ? "text-center" : "d-none"}>{art.usuario_creacion}</td>

                            <td className="btn-group">
                              {esRespBdg || rol === "ADMIN" || rol === "SUPERADMIN" ?
                                <>
                                  <button
                                    className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                    data-bs-toggle="modal"
                                    title="Aceptar"
                                    data-bs-target="#modalPendientes"
                                    onClick={() => {
                                      setRecibir(true);
                                      setTipo("mover");
                                      setLineaRecibir(art);
                                    }}
                                  />

                                  <button
                                    className="btn btn-outline-danger btn-sm fa-solid fa-thumbs-down"
                                    data-bs-toggle="modal"
                                    title="Rechazar"
                                    data-bs-target="#modalPendientes"
                                    onClick={() => {
                                      setRecibir(false);
                                      setTipo("mover");
                                      setLineaRecibir(art);
                                    }}
                                  />
                                </> : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>)
                : null}

              {opcionSeleccionada === "pendientesP" ?
                (<>
                  <div className="table-responsive mt-2">
                    <h5 className="text-center mb-4">Artículos asignados a proyecto</h5>
                    <table className="table table-hover table-striped table-sm">
                      <thead className="text-center">
                        <tr>
                          <th className={col15 ? "text-center" : "d-none"}>Fecha</th>
                          <th className={col16 ? "text-center" : "d-none"}>Consec. <br /> Art</th>
                          <th className={col17 ? "text-center" : "d-none"}>Descripcion</th>
                          <th className={col18 ? "text-center" : "d-none"}>Tipo</th>
                          <th className={col19 ? "text-center" : "d-none"}>Estado</th>
                          <th className={col20 ? "text-center" : "d-none"}>Valor</th>
                          <th className={col21 ? "text-center" : "d-none"}>Cantidad</th>
                          <th className={col22 ? "text-center" : "d-none"}>Consec. <br /> Mov</th>
                          {contProyectoEA === 0 ? null : (
                            <th className={col23 ? "text-center" : "d-none"}>
                              Origen P
                            </th>
                          )}
                          {contBodegaEA === 0 ? null : (
                            <th className={col24 ? "text-center" : "d-none"}>
                              Origen B
                            </th>
                          )}
                          {contBodegaEA === 0 ? null : (
                            <th className={col25 ? "text-center" : "d-none"}>
                              Ubic. <br /> Origen
                            </th>
                          )}
                          <th className={col26 ? "text-center" : "d-none"}>Destino</th>
                          <th className={col27 ? "text-center" : "d-none"}>Enviado por</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody className="text-center" id="tabla_articulos">
                        {listaPendientesP.map((art) => (
                          <tr key={art.articulo}>
                            <td className={col15 ? "text-center" : "d-none"}>{art.fechaAsignar}</td>
                            <td className={col16 ? "text-center" : "d-none"}>{art.consecutivoFix}</td>
                            <td className={col17 ? "text-center" : "d-none"}>{art.des_Larga}</td>
                            <td className={col18 ? "text-center" : "d-none"}>{art.tipo}</td>
                            <td className={col19 ? "text-center" : "d-none"}>{art.estado}</td>
                            <td className={col20 ? "text-center" : "d-none"}>${Intl.NumberFormat("es-CO").format(art.valor)}</td>
                            <td className={col21 ? "text-center" : "d-none"}>{art.cantidad}</td>
                            <td className={col22 ? "text-center" : "d-none"}>{art.consecutivoFixMov}</td>
                            {contProyectoEA === 0 ? null : (
                              <td className={col23 ? "text-center" : "d-none"}>{art.proyectoSalida}</td>
                            )}
                            {contBodegaEA === 0 ? null : (
                              <td className={col23 ? "text-center" : "d-none"}>{art.bodegaSalida}</td>
                            )}
                            {contBodegaEA === 0 ? null : (
                              <td className={col24 ? "text-center" : "d-none"}>{art.ubicacionSalida}</td>
                            )}
                            <td className={col25 ? "text-center" : "d-none"}>{art.proyectoDestino}</td>
                            <td className={col26 ? "text-center" : "d-none"}>{art.usuario_creacion}</td>

                            <td className="btn-group">
                              {esRespPry || rol === "ADMIN" || rol === "SUPERADMIN" ?
                                <>
                                  <button
                                    className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                    data-bs-toggle="modal"
                                    title="Aceptar"
                                    data-bs-target="#modalPendientes"
                                    onClick={() => {
                                      setRecibir(true);
                                      setTipo("asignar");
                                      setLineaRecibir(art);
                                    }}
                                  />

                                  <button
                                    className="btn btn-outline-danger btn-sm fa-solid fa-thumbs-down"
                                    data-bs-toggle="modal"
                                    title="Rechazar"
                                    data-bs-target="#modalPendientes"
                                    onClick={() => {
                                      setRecibir(false);
                                      setTipo("asignar");
                                      setLineaRecibir(art);
                                    }}
                                  />
                                </> : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>)
                : null}
            </>}

          {/* <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados} /> */}

          {/* ------ Modal pendientes --------- */}
          <div
            className="modal fade"
            id="modalPendientes"
            tabIndex="-1"
            aria-labelledby="modalSerialesLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className={
                    (recibir)
                      ? "modal-header bg-primary text-light"
                      : "modal-header bg-danger text-light"
                  }
                >
                  <h1 className="modal-title fs-5" id="modalSerialesLabel">
                    {recibir ? "Aceptar Movimiento" : "Rechazar Movimiento"}
                  </h1>

                </div>

                {/* ------ Input Observaciones ------ */}
                <div className="modal-body">
                  <div className="row mt-3">
                    {/* input observaciones */}
                    <div className="col">
                      <div className="input-group input-group-sm mb-2 ">
                        <span className="input-group-text">
                          Observación
                        </span>
                        <textarea
                          type="text"
                          className="form-control"
                          rows={1}
                          placeholder={
                            recibir
                              ? "Ingrese observacion de recibido"
                              : "Ingrese observacion de rechazado"
                          }
                          value={observacionPendientes}
                          onChange={(e) =>
                            setObservacionPendientes(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  {observacionPendientes === "" ? null : (
                    <>
                      <button
                        type="button"
                        className={
                          recibir ? "btn btn-primary" : "btn btn-danger"
                        }
                        data-bs-dismiss="modal"
                        onClick={() => {
                          if (recibir) {
                            if (tipo === "mover") {
                              recibirPedido("mover");
                            } else if (tipo === "asignar") {
                              recibirPedido("asignar");
                            }
                          } else {
                            if (tipo === "mover") {
                              rechazarPedido("mover");
                            } else if (tipo === "asignar") {
                              rechazarPedido("asignar");
                            }
                          }
                        }}
                      >
                        {recibir ? "Recibir" : "Rechazar"}
                      </button>
                    </>
                  )}


                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setObservacionPendientes("")}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas {opcionSeleccionada === "pendientesB" ? "Movimientos" : "Asiganaciones"}
                  </h5>
                </div>

                <div className="modal-body">

                  {opcionSeleccionada === "pendientesB" ?
                    <>
                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={1} checked={col1} setChecked={setCol1} validar={() => validarCheckboxes('col1', "b")} agregarColumna={agregarColumna} texto={"Fecha Movimiento"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2', "b")} agregarColumna={agregarColumna} texto={"Consecutivo Art."} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3', "b")} agregarColumna={agregarColumna} texto={"Descripcion"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4', "b")} agregarColumna={agregarColumna} texto={"Tipo"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5', "b")} agregarColumna={agregarColumna} texto={"Estado"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6', "b")} agregarColumna={agregarColumna} texto={"Valor"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7', "b")} agregarColumna={agregarColumna} texto={"Cantidad"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8', "b")} agregarColumna={agregarColumna} texto={"Consecutivo Mov."} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9', "b")} agregarColumna={agregarColumna} texto={"Origen Proyecto"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10', "b")} agregarColumna={agregarColumna} texto={"Origen Bodega"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11', "b")} agregarColumna={agregarColumna} texto={"Ubicacion Origen"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12', "b")} agregarColumna={agregarColumna} texto={"Destino"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13', "b")} agregarColumna={agregarColumna} texto={"Ubicacion Destino"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14', "b")} agregarColumna={agregarColumna} texto={"Enviado por"} />
                      </div>

                    </>
                    :
                    <>
                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={15} checked={col15} setChecked={setCol15} validar={() => validarCheckboxes('col15', "p")} agregarColumna={agregarColumna} texto={"Fecha Asignacion"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={16} checked={col16} setChecked={setCol16} validar={() => validarCheckboxes('col16', "p")} agregarColumna={agregarColumna} texto={"Consecutivo Art"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={17} checked={col17} setChecked={setCol17} validar={() => validarCheckboxes('col17', "p")} agregarColumna={agregarColumna} texto={"Descripcion"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={18} checked={col18} setChecked={setCol18} validar={() => validarCheckboxes('col18', "p")} agregarColumna={agregarColumna} texto={"Tipo"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={19} checked={col19} setChecked={setCol19} validar={() => validarCheckboxes('col19', "p")} agregarColumna={agregarColumna} texto={"Estado"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={20} checked={col20} setChecked={setCol20} validar={() => validarCheckboxes('col20', "p")} agregarColumna={agregarColumna} texto={"Valor"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={21} checked={col21} setChecked={setCol21} validar={() => validarCheckboxes('col21', "p")} agregarColumna={agregarColumna} texto={"Cantidad"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={22} checked={col22} setChecked={setCol22} validar={() => validarCheckboxes('col22', "p")} agregarColumna={agregarColumna} texto={"Consecutivo Asig."} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={23} checked={col23} setChecked={setCol23} validar={() => validarCheckboxes('col23', "p")} agregarColumna={agregarColumna} texto={"Origen Proyecto"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={24} checked={col24} setChecked={setCol24} validar={() => validarCheckboxes('col24', "p")} agregarColumna={agregarColumna} texto={"Origen Bodega"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={25} checked={col25} setChecked={setCol25} validar={() => validarCheckboxes('col25', "p")} agregarColumna={agregarColumna} texto={"Ubicacion Origen"} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={26} checked={col26} setChecked={setCol26} validar={() => validarCheckboxes('col26', "p")} agregarColumna={agregarColumna} texto={"Destino "} />
                      </div>

                      <div className="form-check">
                        <CheckboxCol tabla="movPendientes" columna={27} checked={col27} setChecked={setCol27} validar={() => validarCheckboxes('col27', "p")} agregarColumna={agregarColumna} texto={"Enviado por"} />
                      </div>
                    </>}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};
