import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";

import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  guardarDatabase
} from "../../config/firebase";

import { cargarConsecutivos, changeTitle, handleColum, handleModificarConsecutivo, isMobile, styles } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "../miniComponentes/index";

export const Bodegas = ({ terceros, permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaConsecutivos, setListaConsecutivos] = useState([]);
  const [listaResponsables, setListaResponsables] = useState([]);
  const [listaUbicaciones, setListaUbicaciones] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [ubicacion, setUbicacion] = useState([]);

  const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
  const [valorConsecutivo, setValorConsecutivo] = useState(0);
  const [responsableM, setResponsableM] = useState("");
  const [responsable, setResponsable] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [consecutivo, setConsecutivo] = useState("");
  const [idDocumento, setIdDocumento] = useState("");
  const [direccion, setDireccion] = useState("");
  const [estado, setEstado] = useState("ACTIVO");
  const [ciudad, setCiudad] = useState("");
  const [idBdg, setIdBdg] = useState("");

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [responsables, setResponsables] = useState(listaResponsables);
  const [idResponsable, setIdResponsable] = useState("");

  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [adicionar, setAdicionar] = useState(0);
  const [mobile, setMobile] = useState(true);
  const tablaRef = useRef(null);


  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Bodegas");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7], user, "bodegas");
    cargarConsecutivos("BODEGA", emp, setListaConsecutivos);
    cargarBodegas();
    cargarResponsables();
    isMobile(setMobile);
  };

  useEffect(() => {
    cargarResponsables()
  }, [terceros]);

  useEffect(() => {
    if (adicionar) {
      resetStates();
    }
  }, [adicionar]);

  //-------Consecutivos------------
  useEffect(() => {
    const selectConsecutivo = document.getElementById("seleccioneConsecutivo");
    if (selectConsecutivo) {
      selectConsecutivo.innerHTML = "";
      listaConsecutivos.forEach((lc) => {
        let option = document.createElement("option");
        option.value = lc.prefijo;
        option.text = lc.descripcion;
        selectConsecutivo.appendChild(option);
      });
    }
    setRefresh(false);
  }, [listaConsecutivos, adicionar, refresh]);

  //-------Responsables------------
  const cargarResponsables = async () => {
    const tipoResp = "EMPLEADO";
    const estadoResp = "ACTIVO";
    try {
      const listaTemporal = terceros;
      const activos = listaTemporal.filter((act) => act.estado === estadoResp && act.tipo === tipoResp);
      setListaResponsables(activos);
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    const options = listaResponsables.map(cliente => ({ value: cliente.razonSocial, label: cliente.razonSocial }));
    setResponsables(options?.sort(sortBy("value")))
  }, [listaResponsables]);


  //--------CRUD-----------
  const cargarBodegas = async () => {
    setIsLoading(true);
    let temporal;
    try {
      const listaTempBodegas = await consultarDatabase(`${emp}_bodegas`);
      if (listaTempBodegas.length === 0) {
        temporal = [{ consecutivo: "Sin Datos" }];
      } else {
        temporal = listaTempBodegas.sort(sortBy("consecutivo"));
      }
      setListaBodegas(temporal);
      setDocumentosFiltrados(temporal)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleClickAdicionar = async () => {
    const respuesta = listaBodegas.find(b => b.consecutivo === consecutivo.toUpperCase())

    if (respuesta !== undefined) {
      resetStates();
      toast.info("La bodega: " + consecutivo.toUpperCase() + " ya se encuentra registrado, intente de nuevo");
    } else {
      let resp = responsable.label;
      const enviarBodega = {
        id: uniqid("bodega-"),
        idResponsable,
        consecutivo: consecutivo.toUpperCase() + "-" + valorConsecutivo,
        ciudad,
        direccion,
        responsable: resp,
        estado,
        descripcion,
        listaUbicaciones: listaUbicaciones.length > 0 ? listaUbicaciones : [],
        usuario_creacion: user,
        fecha_creacion: fechaFormateada,
      };
      if (estado === "" || direccion === "" || descripcion === "" || ciudad === "" || responsable === "") {
        toast.warning("Diligencie todos los campos de manera correcta");
      } else {
        setIsLoading(true)
        try {
          await guardarDatabase(`${emp}_bodegas`, enviarBodega);

          await handleModificarConsecutivo(enviarBodega.consecutivo, user, fechaFormateada, idDocumentoConsecutivo, emp);
          resetStates();
          toast.success("Bodega registrada de manera correcta");
          await cargarBodegas();
          await cargarConsecutivos("BODEGA", emp, setListaConsecutivos);
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = (b) => {
    setIdDocumento(b.idDocumento);
    setConsecutivo(b.consecutivo);
    setDireccion(b.direccion);
    setCiudad(b.ciudad);
    setResponsable(b.responsable);
    setEstado(b.estado);
    setDescripcion(b.descripcion);
    setIdResponsable(b.idResponsable)
    setIdBdg(b.id)
  };

  const handleClickModificarBd = async () => {
    let rep = responsableM.label !== undefined ? responsableM.label : responsable;
    if (idDocumento && idDocumento !== undefined) {
      const enviarBodega = {
        idResponsable,
        ciudad,
        direccion,
        responsable: rep,
        estado,
        id: idBdg,
        descripcion,
        usuario_modificacion: user,
        fecha_modificacion: fechaFormateada,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabase(`${emp}_bodegas`, idDocumento, enviarBodega);
        resetStates();
        toast.success("Bodega modificada correctamente");
        await cargarBodegas();

      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("La Bodega no existe, porfavor verifique e intente de nuevo.")
    }
  };

  const resetStates = () => {
    setConsecutivo("");
    setIdDocumentoConsecutivo("");
    setValorConsecutivo("");
    setDireccion("");
    setCiudad("");
    setResponsable("");
    setResponsableM("");
    setEstado("");
    setListaUbicaciones([]);
    setUbicacion([]);
    setDescripcion("");
  };

  //---------BLUR---------
  const handleBlurDatalist = (datalistId, value, setValue) => {
    const datalist = document.getElementById(datalistId);
    const option = Array.from(datalist.options).find((opt) => opt.value === value);
    !option && setValue("")

  };

  //-------ubicacion----------
  const adicionarNuevaUbicacion = async (index, listas, doc) => {
    let enviarNuevaUbicacion = [];
    const nuevaUbicacion = { ubicacion: ubicacion[index] };

    if (nuevaUbicacion.ubicacion === undefined || nuevaUbicacion.ubicacion === "") {
      toast.warning("Diligencie el campo de manera correcta");
    } else {
      const encontrada = listas.listaUbicaciones.find((lu) => lu.ubicacion === nuevaUbicacion.ubicacion);
      if (encontrada === undefined) {
        const nuevaListaUbicaciones = [...listas.listaUbicaciones];
        nuevaListaUbicaciones.push(nuevaUbicacion);
        enviarNuevaUbicacion = { listaUbicaciones: nuevaListaUbicaciones };
        setIsLoading(true)
        try {
          await actualizarDocumentoDatabase(`${emp}_bodegas`, doc, enviarNuevaUbicacion);
          await cargarBodegas();
          toast.success("Ubicacion agregada de manera correcta");
          setUbicacion([...ubicacion.slice(0, index), "", ...ubicacion.slice(index + 1),]);
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      } else {
        toast.warning("La ubicacion: " + nuevaUbicacion.ubicacion + " ya se encuentra registrada, intente de nuevo");
        setUbicacion([...ubicacion.slice(0, index), "", ...ubicacion.slice(index + 1)]);
      }
    }
  };

  //---------Columnas----------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor))
  };

  //--------Paginación----------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaBodegas(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //*********** Componente ***********
  return (
    <>
      {isLoading && <LoaderES />}
      <>
        {/* botones de opcion */}
        <div className="fixed-top mt-2"
          style={{
            backgroundColor: "white",
            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
            transition: "margin-left 0.3s ease",
            top: "46px",
            zIndex: 1
          }}>

          {/* botones de opcion */}
          <div className="row ms-2 me-2">
            <div className="col-sm-4 align-self-start text-start">
              <div className="btn-group">

                {/* Adicionar */}
                <button
                  className="btn fa-solid fa-plus"
                  disabled={!permisos.includes("crearBod")}
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarAdicionar"
                  id="modaladicionar"
                  title="Crear Nueva Bodega"
                  onClick={() => {
                    setAdicionar(1)
                    setRefresh(true)
                  }} />

                {/* Columnas */}
                <button
                  className="btn fa-solid fa-table-columns"
                  title="Seleccionar Columnas"
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarColumnas"
                  id="modalcolumnas" />

                {/* Actualizar */}
                <button
                  className="btn fa-solid fa-rotate"
                  title="Actualizar Datos"
                  onClick={() => {
                    cargarBodegas()
                  }} />
              </div>
            </div>

            {/* filtrar */}
            <div className="col-sm-8">
              <Filtrar2
                tabla="myTable"
                documentos={listaBodegas}
                setDocumentosFiltrados={setDocumentosFiltrados}
                setRegistrosFiltrados={setRegistrosPaginaActual}
                registrosPorPagina={registrosPorPagina} />
            </div>
          </div>
        </div>

        <hr className={" mt-5 " + (mobile ? " mb-5" : "")} />

        {mobile ?
          (<>
            {registrosPaginaActual.map((bodega, index) => (
              bodega.consecutivo === "Sin Datos" ? (
                <button
                  className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                  disabled={!permisos.includes("crearBod")}
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarAdicionar"
                  id="modaladicionar"
                  title="Crear Nuevo Proyecto"
                  onClick={() => setAdicionar(1)} />)
                :
                (<>
                  <table className="table table-sm table-hover table-striped mb-0 "
                    key={bodega.consecutivo}>
                    <thead>
                      <tr>
                        <th style={{ width: "50px", resize: "none" }}>
                          <button className="btn  fa-solid fa-chevron-down btn-sm"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseItem" + bodega.consecutivo}
                            aria-expanded="false"
                            aria-controls="collapseItem" />
                        </th>
                        <th style={{ width: "65px", resize: "none" }}>{bodega.consecutivo}:</th>
                        <td style={{ minWidth: "60px", resize: "none", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                          className={bodega.estado !== "ACTIVO" ? "text-danger" : ""}>
                          <span className="truncate-text">
                            {bodega.descripcion.length > 50 ? bodega.descripcion.slice(0, 50) + "..." : bodega.descripcion}
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>

                  <div className="collapse" id={"collapseItem" + bodega.consecutivo}>
                    <div className="card card-body">
                      <table className="table table-sm" >
                        <tbody >
                          <DatoTablaMobile titulo={'Consecutivo'} dato={bodega.consecutivo} columna={col2} />
                          <DatoTablaMobile titulo={"Dirección"} dato={bodega.direccion} columna={col3} />
                          <DatoTablaMobile titulo={"Ciudad"} dato={bodega.ciudad} columna={col4} />
                          <DatoTablaMobile titulo={"Descripcion"} dato={bodega.descripcion} columna={col5} />
                          <DatoTablaMobile titulo={"Responsable"} dato={bodega.responsable} columna={col6} />
                          <DatoTablaMobile titulo={"Estado"} dato={bodega.estado} columna={col7} />
                          <tr>
                            <td></td>
                            <th style={{ resize: "none", textAlign: "start" }}>Actions </th>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                  disabled={!permisos.includes("editarBod")}
                                  data-bs-toggle="modal"
                                  data-bs-target="#ModificarAdicionar"
                                  id="modalModificar"
                                  title="Modificar Item Seleccionado"
                                  onClick={() => {
                                    handleClickTraerData(bodega);
                                    setAdicionar(0);
                                  }} />
                              </div>
                            </td>
                          </tr>
                        </tbody>

                        <tfoot>
                          <tr className="w-100">
                            <td className="btn btn-sm fa-solid fa-chevron-down "
                              data-bs-toggle="collapse"
                              data-bs-target={"#" + bodega.consecutivo}
                              aria-expanded="false"
                              aria-controls="collapseItem"
                              onClick={() => setIdDocumento(bodega.idDocumento)} />
                            <th style={{ fontSize: "12px", textAlign: "start" }}>Ubicaciones:</th>
                          </tr>

                          <tr>
                            <td className="p-0">
                              <div id={bodega.consecutivo}
                                className="accordion-collapse collapse"
                                aria-labelledby={"flush-heading" + index}
                                data-bs-parent="#accordionClasificaciones" >
                                <div className="accordion-body">
                                  {bodega && bodega.listaUbicaciones && (
                                    <div className="table-responsive">
                                      <table className="table table-sm table-hover table-stripped align-middle table-bordered table-info">
                                        <thead>
                                          <tr>
                                            <th>Ubicacion</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {bodega.listaUbicaciones.map((lu) => (
                                            <tr key={lu.ubicacion}>
                                              <td>{lu.ubicacion}</td>
                                            </tr>
                                          ))}
                                          <tr>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                placeholder="Ubicacion"
                                                value={ubicacion[index] || ""}
                                                onFocus={() => {
                                                  setListaUbicaciones(bodega.listaUbicaciones);
                                                }}
                                                onChange={(e) => {
                                                  const tempUbicacion = [...ubicacion];
                                                  tempUbicacion[index] = e.target.value.toUpperCase();
                                                  setUbicacion(tempUbicacion);
                                                }} />
                                            </td>
                                            <td className="text-center">
                                              <button
                                                className="btn btn-primary fa-solid fa-plus btn-sm "
                                                disabled={!permisos.includes("crearUbicBod")}
                                                onClick={() => {
                                                  setIdDocumento(bodega.idDocumento);
                                                  setListaUbicaciones(bodega.listaUbicaciones);
                                                  adicionarNuevaUbicacion(index,
                                                    bodega, bodega.idDocumento);
                                                }} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </>)
            ))}
          </>)
          :
          (<div className="table-responsive abs-center-table">
            <table
              id="myTable"
              ref={tablaRef}
              className="table table-hover table-sm table-bordered mt-3">
              <thead className="text-center">
                <tr key={consecutivo + 1}>
                  <ThTablaH ordenar={ordenar} dOrdenar={'consecutivo'} columna={col2} titulo={'Consecutivo'} />
                  <ThTablaH ordenar={ordenar} dOrdenar={'direccion'} columna={col3} titulo={'Dirección'} />
                  <ThTablaH ordenar={ordenar} dOrdenar={'ciudad'} columna={col4} titulo={'Ciudad'} />
                  <ThTablaH ordenar={ordenar} dOrdenar={'descripcion'} columna={col5} titulo={'Descripcion'} />
                  <ThTablaH ordenar={ordenar} dOrdenar={'responsable'} columna={col6} titulo={'Responsable'} />
                  <ThTablaH ordenar={ordenar} dOrdenar={'estado'} columna={col7} titulo={'Estado'} />
                  <th className="text-center">Edit</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {registrosPaginaActual.map((bodega, index) => (
                  bodega.consecutivo === "Sin Datos" ?
                    <tr key={bodega.consecutivo}>
                      <td colSpan={"100%"} className="text-center">
                        <button
                          className="btn fa-solid fa-plus"
                          disabled={!permisos.includes("crearBod")}
                          data-bs-toggle="modal"
                          data-bs-target="#ModificarAdicionar"
                          id="modaladicionar"
                          title="Crear Nueva Bodega"
                          onClick={() => setAdicionar(1)} />
                      </td>
                    </tr>
                    :
                    <>
                      <tr
                        key={bodega.consecutivo + index}
                        className="accordion accordion-flush"
                        id="accordionClasificaciones"
                      >
                        <TdTablaD columna={col2} dato={bodega.consecutivo} clase={(bodega.estado !== "ACTIVO" && col2) ? "text-danger" : (bodega.estado !== "ACTIVO" && !col2) ? "d-none" : (col2) ? "" : "d-none"} />
                        <TdTablaD columna={col3} dato={bodega.direccion} />
                        <TdTablaD columna={col4} dato={bodega.ciudad} />
                        <TdTablaD columna={col5} dato={bodega.descripcion} />
                        <TdTablaD columna={col6} dato={bodega.responsable} />
                        <TdTablaD columna={col7} dato={bodega.estado} clase={(bodega.estado !== "ACTIVO" && col7) ? "text-danger" : (bodega.estado !== "ACTIVO" && !col7) ? "d-none" : (col7) ? "" : "d-none"} />
                        <td className="text-center" style={{ width: "10px" }}>
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                              disabled={!permisos.includes("editarBod")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modalModificar"
                              title="Modificar Item Seleccionado"
                              onClick={() => {
                                handleClickTraerData(bodega);
                                setAdicionar(0);
                              }} />
                          </div>
                        </td>
                        {/* **************  acordion ******************** */}
                        <td style={{ width: "5px" }}>
                          <div className="accordion-item m-0">
                            <h2
                              className="accordion-header"
                              id={"flush-heading" + index}>
                              <button
                                className="accordion-button collapsed p-0"
                                style={{ backgroundColor: "transparent" }}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#" + bodega.consecutivo}
                                aria-expanded="false"
                                aria-controls={bodega.consecutivo}
                                onClick={() => {
                                  setIdDocumento(bodega.idDocumento);
                                }}
                              />
                            </h2>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-0">
                          <div
                            id={bodega.consecutivo}
                            className="accordion-collapse collapse"
                            aria-labelledby={"flush-heading" + index}
                            data-bs-parent="#accordionClasificaciones" >
                            <div className="accordion-body">
                              {bodega && bodega.listaUbicaciones && (
                                <div className="table-responsive">
                                  <table className="table table-sm table-hover table-stripped align-middle table-bordered table-info">
                                    <thead>
                                      <tr key={bodega.consecutivo}>
                                        <th>Ubicacion</th>
                                        <th className="text-center">Opciones</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bodega.listaUbicaciones.map((lu) => (
                                        <tr key={lu.ubicacion}>
                                          <td>{lu.ubicacion}</td>
                                        </tr>
                                      ))}
                                      <tr>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Ubicacion"
                                            value={ubicacion[index] || ""}
                                            onFocus={() => {
                                              setListaUbicaciones(bodega.listaUbicaciones);
                                            }}
                                            onChange={(e) => {
                                              const tempUbicacion = [...ubicacion];
                                              tempUbicacion[index] = e.target.value.toUpperCase();
                                              setUbicacion(tempUbicacion);
                                            }} />
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-primary fa-solid fa-plus btn-sm "
                                            disabled={!permisos.includes("crearUbicBod")}
                                            onClick={() => {
                                              setIdDocumento(bodega.idDocumento);
                                              setListaUbicaciones(bodega.listaUbicaciones);
                                              adicionarNuevaUbicacion(index,
                                                bodega, bodega.idDocumento);
                                            }} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                ))}
              </tbody>
            </table>
          </div>)
        }

        <Pagination
          paginaActual={paginaActual}
          totalPaginas={totalPaginas}
          setPaginaActual={setPaginaActual}
          registrosPorPagina={registrosPorPagina}
          setRegistrosPorPagina={setRegistrosPorPagina}
          documentosFiltrados={documentosFiltrados}
        />

        {/* Modal de Modificar // Adicionar*/}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarAdicionar"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false" >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content">
              <div className="modal-header bg-primary text-light ">
                <h5 className="modal-title">
                  {adicionar === 1 ? "Crear " : "Modificar "} Bodega
                </h5>
              </div>

              <div className="modal-body">
                {/* Consecutivo */}
                <div className="input-group input-group-sm mb-2">
                  {listaConsecutivos.length === 0 ? (
                    <input className="form-control text-center text-danger"
                      defaultValue={"Sin consecutivos disponibles"}
                    />)
                    :
                    (<>
                      <span className="input-group-text">Consecutivo:</span>
                      <input
                        list="seleccioneConsecutivo"
                        className="form-control"
                        disabled={!adicionar}
                        type="text"
                        placeholder="Type to search..."
                        onBlur={() =>
                          handleBlurDatalist("seleccioneConsecutivo", consecutivo, setConsecutivo)}
                        value={consecutivo}
                        onChange={(e) => {
                          const c = listaConsecutivos.find((c) => c.prefijo === e.target.value);
                          setConsecutivo(e.target.value);
                          setIdDocumentoConsecutivo(c ? c.idDocumento : "");
                          setValorConsecutivo(c && parseInt(c.valorActual) + 1);
                        }} />
                      <datalist id="seleccioneConsecutivo" />
                    </>)}

                  {listaConsecutivos.length > 0 || adicionar && (
                    <input
                      type="number"
                      className="form-control text-center"
                      value={valorConsecutivo}
                      readOnly
                      disabled />
                  )}
                </div>

                {/* Direccion */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Dirección:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Dirección"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value.toUpperCase())} />
                </div>

                {/* Descripcion */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Descripcion:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Descripcion"
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value.toUpperCase())} />
                </div>

                {/* Ciudad */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Ciudad:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ciudad"
                    value={ciudad}
                    onChange={(e) => setCiudad(e.target.value.toUpperCase())} />
                </div>

                {/* Responsable */}
                {listaResponsables.length === 0 ? (
                  <input
                    className=" form-control text-center text-danger mb-2"
                    defaultValue={"No hay responsables disponibles"}
                    readOnly
                  />)
                  :
                  (<Select className="mb-2"
                    value={adicionar ? responsable : responsableM}
                    placeholder="Responsable"
                    onChange={(option) => {
                      const res = listaResponsables.find((r) => r.razonSocial === option.label);
                      setIdResponsable(res ? res.consecutivo : "");
                      adicionar ? setResponsable(option) :
                        setResponsableM(option);
                    }}
                    options={responsables}
                    styles={styles} />
                  )}

                {/* Responsable actual */}
                {!adicionar && (
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Responsable Actual:</span>
                    <input
                      type="text"
                      disabled
                      readOnly
                      className="form-control"
                      defaultValue={typeof responsable === "object" && responsable !== undefined ? responsable.value : responsable}
                    />
                  </div>)}

                {/* Estado */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Estado:</span>
                  <select
                    className="form-select"
                    placeholder="Estado"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)} >
                    <option value="" disabled> Seleccione </option>
                    <option value="ACTIVO">Activo</option>
                    <option value="INACTIVO">Inactivo</option>
                  </select>
                </div>
              </div>

              <div className="modal-footer">
                {listaResponsables.length > 0 && adicionar === 1 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      adicionar
                        ? handleClickAdicionar()
                        : handleClickModificarBd()}>
                    Crear
                  </button>)}

                {!adicionar && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      adicionar
                        ? handleClickAdicionar()
                        : handleClickModificarBd()}>
                    Modificar
                  </button>)}

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    resetStates()
                    setRefresh(true)
                  }}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Columnas */}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarColumnas"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">
              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title" id="staticBackdropLabel3">
                  Selección de columnas
                </h5>
              </div>

              <div className="modal-body">

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Direccion"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Ciudad"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Descripcion"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Responsable"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="bodegas" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Estado"} />
                </div>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal" >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
