import React, { useEffect } from "react";
import { changeTitle } from "../config/functions/funGenerales";
import logo from "../images/ES_sin_fondo.png";
// import { Cronometro } from "./miniComponentes/Cronometro";

const Home = () => {

    useEffect(() => {
        changeTitle("Admin. Home");
    }, []);


    //************************* */
    return (<>
        <img
            src={logo}
            className="logoHome mt-5"
            style={{ maxWidth: "350px" }}
        // style={{ maxWidth: "100px" }}
        />
        {/* <Cronometro /> */}

    </>
    )
}

export default Home