import bcrypt from "bcryptjs";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import {
    actualizarDocumentoDatabase,
    consultarDatabase,
    createUser,
    guardarDatabase
} from "../../config/firebase";
import { changeTitle, convertirDate, isMobile, styles } from "../../config/functions/funGenerales";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";


export const AdminUsuario = (listaEmp) => {

    const [componente, setComponente] = useState(localStorage.getItem("componente") || "AdminUsuario");
    const user = JSON.parse(localStorage.getItem("token")).userName;
    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");


    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [licenciasEmpresa, setLicenciasEmpresa] = useState([]);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [listaEmpresas, setListaEmpresas] = useState([]);

    const [empresas, setEmpresas] = useState(listaEmpresas);
    const [nomEmpresa, setNomEmpresa] = useState("");
    const [idDocumento, setIdDocumento] = useState("");
    const [estado, setEstado] = useState("ACTIVO");
    const [telefono, setTelefono] = useState("");
    const [usuario, setUsuario] = useState("");
    const [correo, setCorreo] = useState("");
    const [rol, setRol] = useState("");

    const fecha_creacion = fechaFormateada;
    const fecha_modificacion = fechaFormateada;
    const admin_modificacion = user;
    const admin_creacion = user;

    const [isLoading, setIsLoading] = useState(false);
    const [adicionar, setAdicionar] = useState(0);
    const [mobile, setMobile] = useState(true)
    const tablaRef = useRef(null);
    const history = useHistory();

    const [col2, setCol2] = useState(true);
    const [col3, setCol3] = useState(true);
    const [col4, setCol4] = useState(true);
    const [col5, setCol5] = useState(true);
    const [col6, setCol6] = useState(true);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [countOrdenar, setCountOrdenar] = useState(0);


    //************* Funciones *****************/
    useEffect(() => {
        changeTitle("Admin Usuario")
        setListaEmpresas(listaEmp.listaEmpresas)
        cargarUsuarios()
        buscarColumnas()
        isMobile(setMobile);
    }, []);

    useEffect(() => {
        if (adicionar === 1) {
            resetStates();
        }
    }, [adicionar]);

    useEffect(() => {
        const options = listaEmpresas.map(emp => ({ value: emp.idEmpresa, label: emp.idEmpresa }));
        setEmpresas(options)
    }, [listaEmpresas]);

    useEffect(() => {
        localStorage.setItem("componente", componente);
    }, [componente]);

    //-----------------------------------
    const cargarUsuarios = async () => {
        setIsLoading(true);
        let temporal;
        try {
            const listaTemp = await consultarDatabase("usuarios");
            if (listaTemp.length === 0) {
                temporal = [{ empleado: "Sin Datos" }]
            } else {
                temporal = listaTemp.sort(sortBy("empresa", "empleado"));

                const licenciasEmp = []
                listaEmp.listaEmpresas.forEach(le => {
                    const temp = listaTemp.filter(lt => lt.empresa === le.idEmpresa)
                    const tempActivos = temp.filter(t => t.estado === "ACTIVO")
                    const datoLic = {
                        empresa: le.idEmpresa,
                        licencias: isNaN(parseInt(le.licencias)) ? 0 : parseInt(le.licencias),
                        usuarios: temp.length,
                        activos: tempActivos.length
                    }
                    licenciasEmp.push(datoLic);
                });
                setDocumentosFiltrados(temporal);
                setListaUsuarios(temporal);
                setLicenciasEmpresa(licenciasEmp)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    };

    const handleClickAdicionar = async () => {
        const passAdmin = bcrypt.hashSync("P@55w0rd!", 10);
        const respuesta = listaUsuarios.filter((u) => u.correo === correo);

        if (respuesta.length >= 1 && respuesta[0].correo == correo) {
            resetStates();
            toast.info("El usuario con correo:  " + correo.toUpperCase() + " ya se encuentra registrado, intente de nuevo");
        } else {
            const llave = passAdmin;
            const enviarUsuario = {
                id: uniqid("usuario-"), usuario, empleado: "ADMIN", correo, estado, rol: "ADMIN",
                telefono, clave: llave, cambioClave: true, admin_creacion, fecha_creacion, empresa: nomEmpresa.label,
            };
            if (nomEmpresa === "" || correo === "" || estado === "" ||
                rol === "" || telefono === "" || usuario === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                try {
                    await guardarDatabase("usuarios", enviarUsuario);
                    await createUser(correo, "P@55w0rd!")
                    toast.success("Usuario registrado de manera correcta");
                    resetStates();
                    await cargarTablas("usuarios", setListaUsuarios);
                } catch (error) {
                    console.error(error)
                }
            }
        }
    };

    const handleClickTraerData = async (u) => {
        setIdDocumento(u.idDocumento);
        setCorreo(u.correo);
        setRol(u.rol);
        setTelefono(u.telefono);
        setEstado(u.estado);
        setUsuario(u.usuario);
        setNomEmpresa(u.empresa);
    };

    const handleClickModificarBd = async () => {
        const enviarUsuario = {
            telefono, estado, admin_modificacion, fecha_modificacion,
        };
        try {
            await actualizarDocumentoDatabase("usuarios", idDocumento, enviarUsuario);
            toast.success("Usuario modificado correctamente");
            await cargarTablas("usuarios", setListaUsuarios);
        } catch (error) {
            console.error(error)
        }
    };

    const resetStates = () => {
        setCorreo("");
        setTelefono("");
        setRol("");
        setEstado("");
        setUsuario("");
        setNomEmpresa("");
    };

    //------------------------------------------
    const cargarTablas = async (tabla, setLista) => {
        try {
            const listaTemp = await consultarDatabase(tabla);
            setLista(listaTemp);
        } catch (error) {
            console.error(error)
        }
    };

    const renderEmpresas = () => {
        const selectEmpresa = document.getElementById("seleccioneEmpresa");
        if (selectEmpresa) {
            selectEmpresa.innerHTML = "";
            listaEmpresas?.forEach((lc) => {
                let option = document.createElement("option");
                option.text = lc.razonSocial;
                option.value = lc.idEmpresa;
                selectEmpresa.appendChild(option);
            });
        }
    };

    //--------------------------------
    const CambioClave = async () => {
        const valor = {
            cambioClave: true,
        };
        try {
            await actualizarDocumentoDatabase("usuarios", idDocumento, valor);
            toast.success("Permiso otorgado exitosamente. Comuníqueselo al usuario.");
            await cargarTablas("usuarios", setListaUsuarios);
        } catch (error) {
            console.error(error)
        }
    };

    //--------------Paginación----------
    const agregarColumna = (tabla, columna, valor) => {
        localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
    };

    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas]);

    const ordenar = (columna) => {
        const direccion = (countOrdenar % 2 === 0 ? "" : "-")
        const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
        setListaUsuarios(temporal);
        setCountOrdenar(countOrdenar + 1)
    };

    const buscarColumnas = () => {
        setCol2(localStorage.getItem(user + "/adminUser/2") === "false" ? false : true);
        setCol3(localStorage.getItem(user + "/adminUser/3") === "false" ? false : true);
        setCol4(localStorage.getItem(user + "/adminUser/4") === "false" ? false : true);
        setCol5(localStorage.getItem(user + "/adminUser/5") === "false" ? false : true);
        setCol6(localStorage.getItem(user + "/adminUser/6") === "false" ? false : true);
    };



    /********** Componente ***************/
    return (
        <>
            <ToastContainer position="bottom-right" />
            {isLoading ?
                <LoaderES />
                : <>
                    <div className="fixed-top mt-2"
                        style={{
                            backgroundColor: "white",
                            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
                            transition: "margin-left 0.3s ease",
                            top: "46px",
                            zIndex: 1
                        }}>

                        <div className="row ms-2 me-2">

                            {/* botones de opcion */}
                            <div className="col-sm-4 align-self-start text-start">
                                <div className="btn-group">

                                    {/* Adicionar */}
                                    <button
                                        className="btn fa-solid fa-plus"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                        id="modaladicionar"
                                        title="Crear Nuevo Usuario"
                                        onClick={() => setAdicionar(1)} />

                                    {/* Columnas */}
                                    <button
                                        className="btn fa-solid fa-table-columns"
                                        title="Seleccionar Columnas"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarColumnas"
                                        id="modalcolumnas" />

                                    {/* Actualizar */}
                                    <button className="btn fa-solid fa-rotate"
                                        title="Actualizar Datos"
                                        onClick={() => cargarUsuarios()} />
                                </div>
                            </div>

                            {/* filtrar */}
                            <div className="col-sm-8">
                                <Filtrar2
                                    tabla="myTable"
                                    documentos={listaUsuarios}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>
                    </div>

                    <hr className={" mt-5 " + (mobile === true ? " mb-5" : "")} />

                    {mobile ?
                        /* Mobile */
                        <>
                            {registrosPaginaActual.map((usuario) => (
                                usuario.empleado === "Sin Datos" ?
                                    <button
                                        className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                        id="modaladicionar"
                                        title="Crear Nuevo Usuario"
                                        onClick={() => {
                                            setAdicionar(1);
                                        }} />
                                    :
                                    <div>
                                        <table className="table table-sm table-hover table-striped mb-0" key={usuario.correo}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px", resize: "none" }}>
                                                        <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#collapseItem" + usuario.correo}
                                                            aria-expanded="false"
                                                            aria-controls="collapseItem" />
                                                    </th>
                                                    <th style={{ resize: "none" }}>{usuario.empresa}:</th>
                                                    <td style={{ minWidth: "200px" }} className={(usuario.cambioClave) ? "text-warning" : ""}>{usuario.empleado}</td>
                                                </tr>
                                            </thead>
                                        </table>

                                        <div className="collapse" id={"collapseItem" + usuario.correo}>
                                            <div className="card card-body">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}> Ult. Ingreso</th>
                                                            <td>{convertirDate(usuario.lastActiveSesion)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}> Empleado</th>
                                                            <td>{usuario.empleado}</td>
                                                        </tr>

                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}> Empresa</th>
                                                            <td>{usuario.empresa}</td>
                                                        </tr>

                                                        {!col2 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}> Usuario</th>
                                                                <td>{usuario.usuario}</td>
                                                            </tr>
                                                        }

                                                        {!col3 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}> Correo</th>
                                                                <td>{usuario.correo}</td>
                                                            </tr>
                                                        }

                                                        {!col3 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}> Teléfono</th>
                                                                <td>{usuario.telefono}</td>
                                                            </tr>
                                                        }

                                                        {!col4 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}> Rol</th>
                                                                <td>{usuario.rol}</td>
                                                            </tr>
                                                        }

                                                        {!col5 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}> Estado</th>
                                                                <td className={(usuario.estado !== "ACTIVO") ? "text-danger" : ""}>{usuario.estado}</td>
                                                            </tr>
                                                        }

                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}>Actions</th>
                                                            <td>
                                                                <div className="btn-group">

                                                                    {/* Btn Permisos */}
                                                                    <button className="btn btn-outline-warning btn-sm fa-solid fa-shield-halved"
                                                                        title="Persmisos de Usuario"
                                                                        onClick={() => {
                                                                            setComponente("usuariopermiso")
                                                                            history.push({ pathname: "/usuariopermiso", state: { componente: "usuariopermiso", userP: usuario.id, origen: "AdminUsuario" } })
                                                                        }} />

                                                                    {/* Btn Modificar */}
                                                                    <button
                                                                        className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#ModificarAdicionar"
                                                                        id="modalModificar"
                                                                        title="Modificar Item Seleccionado"
                                                                        onClick={() => {
                                                                            handleClickTraerData(usuario);
                                                                            setAdicionar(0);
                                                                        }} />

                                                                    {/* Btn Pass */}
                                                                    <button
                                                                        className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#pass"
                                                                        id="modalPass"
                                                                        disabled={usuario.cambioClave}
                                                                        title="Cambiar Contarseña"
                                                                        onClick={() => {
                                                                            handleClickTraerData(usuario);
                                                                        }} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </>
                        /* Mobile */
                        :
                        /* Desktop */
                        licenciasEmpresa.map(le => (
                            <div className="table-responsive abs-center-table" key={le.empresa}>
                                <table
                                    id="myTable"
                                    ref={tablaRef}
                                    className="table table-hover table-striped table-sm table-bordered mt-3">
                                    {/* +Tabla de Usuarios */}
                                    <thead className="text-center">
                                        <tr>
                                            <th colSpan={2}>
                                                {`${le.empresa}`}
                                            </th>
                                            <th colSpan={2}>
                                                {`Licencias: ${le.licencias}`}
                                            </th>
                                            <th colSpan={2}>
                                                {`Usuarios Activos: ${le.activos}`}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th onClick={() => ordenar("empleado")}>Ult. Ingreso</th>
                                            <th onClick={() => ordenar("empleado")}>Empleado</th>
                                            <th onClick={() => ordenar("usuario")} className={col2 ? "" : "d-none"}>Usuario</th>
                                            <th onClick={() => ordenar("correo")} className={col3 ? "" : "d-none"}>Correo</th>
                                            <th onClick={() => ordenar("telefono")} className={col4 ? "" : "d-none"}>Teléfono</th>
                                            <th onClick={() => ordenar("rol")} className={col5 ? "" : "d-none"}>Rol</th>
                                            <th onClick={() => ordenar("estado")} className={col6 ? "" : "d-none"}>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-center" id="tabla_usuarios">
                                        {registrosPaginaActual.map(usuario => (
                                            usuario.empleado === "Sin Datos" ?
                                                <tr key={usuario.id}>
                                                    <td colSpan={9} className="text-center">
                                                        <button
                                                            className="btn fa-solid fa-plus"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModificarAdicionar"
                                                            id="modaladicionar"
                                                            title="Crear Nuevo Usuario"
                                                            onClick={() => setAdicionar(1)} />
                                                    </td>
                                                </tr>
                                                : le.empresa === usuario.empresa ?
                                                    <tr key={usuario.id}>
                                                        <td>{convertirDate(usuario.lastActiveSesion)}</td>
                                                        <td>{usuario.empleado}</td>
                                                        <td title={!usuario.cambioClave ? "" : "Tiene pendiente el cambio de clave"}
                                                            className={usuario.cambioClave && col2 ? "text-warning" : !usuario.cambioClave && !col2 ? "d-none" : col2 ? "" : "d-none"}>
                                                            {!usuario.cambioClave
                                                                ? usuario.usuario
                                                                : usuario.usuario + " * "}
                                                        </td>
                                                        <td className={col3 ? "" : "d-none"}>{usuario.correo.toUpperCase()}</td>
                                                        <td className={col4 ? "" : "d-none"}>{usuario.telefono}</td>
                                                        <td className={col5 ? "" : "d-none"}>{usuario.rol}</td>
                                                        <td className={(usuario.estado !== "ACTIVO" && col6) ? "text-danger" : (usuario.estado !== "ACTIVO" && !col6) ? "d-none" : (col6) ? "" : "d-none"}>{usuario.estado}</td>
                                                        <td className="btn-group text-center d-block" style={{ borderRadius: "0" }}>

                                                            {/*Btn Permisos security */}
                                                            <button className="btn btn-outline-warning btn-sm fa-solid fa-shield-halved"
                                                                title="Persmisos de Usuario"
                                                                onClick={() => {
                                                                    setComponente("usuariopermiso")
                                                                    history.push({ pathname: "/usuariopermiso", state: { componente: "usuariopermiso", userP: usuario.id, origen: "AdminUsuario" } })
                                                                }} />

                                                            {/* Btn Modificar */}
                                                            <button
                                                                className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModificarAdicionar"
                                                                id="modalModificar"
                                                                title="Modificar Item Seleccionado"
                                                                onClick={() => {
                                                                    handleClickTraerData(usuario);
                                                                    setAdicionar(0);
                                                                }} />

                                                            {/* Btn Pass*/}
                                                            <button
                                                                className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#pass"
                                                                id="modalPass"
                                                                title="Cambiar Contarseña"
                                                                disabled={usuario.cambioClave}
                                                                onClick={() => {
                                                                    handleClickTraerData(usuario);
                                                                }} />
                                                        </td>
                                                    </tr>
                                                    : null))}
                                    </tbody>
                                </table>
                            </div>
                        ))
                        /* Desktop */
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* +Modal de Modificar // Adicionar*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarAdicionar"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dia
                        log-centered modal-dialog-scrollable ">
                            <div className="modal-content ">

                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title">
                                        {adicionar === 1 ? "Crear " : "Modificar "} Usuario
                                    </h5>
                                </div>

                                <div className="modal-body">
                                    {adicionar ? <>
                                        <Select
                                            placeholder="Empresa"
                                            disabled={!adicionar}
                                            className="mb-2"
                                            value={nomEmpresa}
                                            onChange={(option) => setNomEmpresa(option)}
                                            options={empresas}
                                            styles={styles}
                                        />
                                    </>
                                        : <>
                                            <div className="input-group input-group-sm mb-2 col">
                                                <span className="input-group-text">Empresa:</span>
                                                <input type="text" className="form-control form-control-sm"
                                                    value={nomEmpresa}
                                                    disabled />
                                            </div>
                                        </>}

                                    <div className="row">
                                        {/* Rol */}
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text">Rol:</span>
                                            <select
                                                className="form-select"
                                                placeholder="Rol"
                                                disabled={!adicionar}
                                                value={rol}
                                                onChange={(e) => setRol(e.target.value)} >
                                                <option value="" disabled>Seleccione</option>
                                                <option value="ADMIN">Admin</option>
                                                {/* <option value="SUPERADMIN">Super Admin</option> */}
                                            </select>
                                        </div>

                                        {/* usuario */}
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text">Usuario:</span>
                                            <input
                                                disabled={!adicionar}
                                                type="email"
                                                className="form-control"
                                                placeholder={"Usuario"}
                                                value={usuario}
                                                onChange={(e) => setUsuario(e.target.value.toUpperCase())} />
                                        </div>
                                    </div>

                                    {/* correo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Correo:</span>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Correo"
                                            disabled={!adicionar}
                                            value={correo}
                                            onChange={(e) => setCorreo(e.target.value)} />
                                    </div>

                                    <div className="row">
                                        {/* telefono */}
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text">Teléfono:</span>
                                            <input
                                                type="number"
                                                min={0}
                                                className="form-control"
                                                placeholder="Teléfono"
                                                value={telefono}
                                                onChange={(e) => setTelefono(e.target.value)} />
                                        </div>

                                        {/* estado */}
                                        <div className="input-group input-group-sm mb-2 col">
                                            <span className="input-group-text">Estado:</span>
                                            <select
                                                className="form-select"
                                                placeholder="Estado"
                                                value={estado}
                                                onChange={(e) => {
                                                    setEstado(e.target.value)
                                                    renderEmpresas()
                                                }}>
                                                <option value="" disabled>Seleccione</option>
                                                <option value="ACTIVO">Activo</option>
                                                <option value="INACTIVO">Inactivo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={() =>
                                            adicionar === 1
                                                ? handleClickAdicionar()
                                                : handleClickModificarBd()} >
                                        {adicionar === 1 ? "Crear" : "Modificar"}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal permiso cambio clave */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="pass"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">

                                <div className="modal-header bg-info text-light">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Permiso Para Cambio De Contraseña
                                    </h5>
                                </div>

                                <div className="modal-body">
                                    <div className="modal-body">
                                        <p> Desea permitir que el usuario: <strong>{usuario}</strong> <br /> pueda cambiar su clave? </p>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-info text-light"
                                        data-bs-dismiss="modal"
                                        id="btnEliminarArticulo"
                                        onClick={CambioClave} >
                                        Sí, Permitir
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* -Modal permiso cambio clave */}

                    {/* +Modal Columnas */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarColumnas"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Selección de columnas
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col2}
                                                onChange={(e) => {
                                                    setCol2(e.target.checked)
                                                    agregarColumna("adminUser", 2, e.target.checked)
                                                }}
                                                id="clasif1" />
                                            <label className="form-check-label"
                                                htmlFor="clasif1">
                                                Usuario
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col3}
                                                onChange={(e) => {
                                                    setCol3(e.target.checked)
                                                    agregarColumna("adminUser", 3, e.target.checked)
                                                }}
                                                id="clasif2" />
                                            <label className="form-check-label"
                                                htmlFor="clasif2">
                                                E-mail
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col4}
                                                onChange={(e) => {
                                                    setCol4(e.target.checked)
                                                    agregarColumna("adminUser", 4, e.target.checked)
                                                }}
                                                id="clasif3" />
                                            <label className="form-check-label"
                                                htmlFor="clasif3">
                                                Teléfono
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col5}
                                                onChange={(e) => {
                                                    setCol5(e.target.checked)
                                                    agregarColumna("adminUser", 5, e.target.checked)
                                                }}
                                                id="clasif4" />
                                            <label className="form-check-label"
                                                htmlFor="clasif4">
                                                Rol
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col6}
                                                onChange={(e) => {
                                                    setCol6(e.target.checked)
                                                    agregarColumna("adminUser", 6, e.target.checked)
                                                }}
                                                id="clasif5" />
                                            <label className="form-check-label"
                                                htmlFor="clasif5">
                                                Estado
                                            </label>
                                        </div>


                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Columnas */}
                </>}
        </>
    );
}
