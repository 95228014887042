import React from 'react'

export const TdTablaD = ({ columna, dato, clase }) => {
    return (
        <>
            <td className={clase ? clase : (columna ? "" : "d-none")} >{dato}</td>

        </>
    )
}
