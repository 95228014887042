import React from "react"
import { Route, Redirect } from "react-router-dom"

export const RutaPrivada = ({ component: Component, ...rest}) => {
    
    const usuarioActivo = JSON.parse(localStorage.getItem("token")).userName ? JSON.parse(localStorage.getItem("token")).userName : ""
    /* const usuarioEstado = JSON.parse(localStorage.getItem("token")).estado */

    return (
        <Route { ...rest}>
            {
                usuarioActivo !== "" /* && usuarioEstado === "ACTIVO" */ ? 
                    <Component /> :
                    <Redirect to="/" />
            }
        </Route>
    )
}
