import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import sortBy from 'sort-by';
import { consultarDocumentoDatabaseObject, guardarDatabaseId } from '../../config/firebase';
import { changeTitle, traerTabla } from '../../config/functions/funGenerales';
import { LoaderES } from '../LoaderES';


export const Migracion = () => {

    const emp = JSON.parse(localStorage.getItem("token")).empresa;

    const [listaPrefijos, setListaPrefijos] = useState([]);
    const [listaBodegas, setListaBodegas] = useState([]);
    const [lisPref, setLisPref] = useState([]);
    const [lisBod, setLisBod] = useState([]);

    const [opcionSelect, setOpcionSelect] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [prefijo, setPrefijo] = useState("");
    const [nombre, setNombre] = useState("");
    const proyecto = { value: "PRY", label: "PROYECTOS" };

    //******************************** */
    useEffect(() => {
        changeTitle("Admin. Migracion");
        getTablas();
    }, []);

    const getTablas = async () => {
        const tempConsec = await traerTabla(emp, "consecutivos");
        setListaPrefijos(tempConsec);
        const tempBdg = await traerTabla(emp, "bodegas");
        setListaBodegas(tempBdg);
    };

    useEffect(() => {
        setPrefijo("")
        setNombre("")
    }, [opcionSelect])

    useEffect(() => {
        let filtro = [];
        const clase = opcionSelect === "articulos" ? "ARTICULO" : "DOCUMENTO";
        filtro = listaPrefijos.filter((a) => a.clase === clase);

        if (opcionSelect === "movimientos") {
            filtro = filtro.filter((a) => a.prefijo === "MOV" || a.prefijo === "ASG");
        }

        if (opcionSelect === "inventario") {
            const prefijos = listaBodegas.map((b) => b.consecutivo);
            const options = prefijos.map(b => ({ value: b, label: b }));
            options.push(proyecto);
            setLisBod(options?.sort(sortBy("value")));
        }

        const prefijos = filtro.map((p) => p.prefijo);
        const options = prefijos.map(p => ({ value: p, label: p }));
        setLisPref(options?.sort(sortBy("value")));
    }, [opcionSelect]);

    const getDocumentos = async (colecc, prefijo) => {
        setIsLoading(true)
        try {
            const data = await consultarDocumentoDatabaseObject(`${emp}_${colecc}`, `${emp}_${colecc}`)
            if (data.length > 0) {
                const filtrado = data.filter(d => d.consecutivo.startsWith(prefijo))
                return filtrado
            } else {
                toast.warning('no se encuentran datos para migrar')
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const migrar = async () => {
        if (!(opcionSelect && prefijo && nombre)) {
            toast.warning("faltan datos")
            return
        }

        if (nombre.split("_")[0] === "" || nombre.split("_")[1] === "" || nombre.split("_")[2] === "") {
            toast.warning("Todos los campos debe ser diligenciados de manera correcta")
            return
        }
        if (window.confirm("Esta seguro de continuar con la migracion de datos?")) {
            setIsLoading(true)
            try {
                const listaCrear = await getDocumentos(opcionSelect, prefijo)
                const objeto = listaCrear.reduce((obj, item) => {
                    obj[item.consecutivo] = item;
                    return obj;
                }, {});
                await guardarDatabaseId(`${emp}_${opcionSelect}`, nombre, objeto);
                toast.success("Migracion completada con exito")

            } catch (e) {
                console.error(`Error al guardar: ${e}`);
            } finally {
                resetStates();
                setIsLoading(false)
            }
        } else {
            resetStates()
            return;
        }
    };

    const resetStates = () => {
        setOpcionSelect("");
        setPrefijo("");
        setNombre("");
    };

    const radioBtn = (titulo, id, value) => {
        return (
            <div className="form-check form-check-inline">
                <input className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id={id}
                    value={value}
                    onChange={(e) => setOpcionSelect(e.target.value)} />
                <label className="form-check-label" for={id}>{titulo}</label>
            </div>
        )
    };

    const selectOptions = (list) => {
        return (
            list.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))
        )
    };

    //******************************** */

    return (
        <>
            {isLoading
                ? <LoaderES />
                :
                <>
                    <div className="container mt-5">
                        <h3 className="my-4">Migracion de Datos</h3>

                        <div className="col my-3">
                            {radioBtn("Artículos", "art", "articulos")}
                            {radioBtn("Kardex", "krd", "kardex")}
                            {radioBtn("Movimientos", "mov", "movimientos")}
                            {radioBtn("Inventario", "inv", "inventario")}
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" >{opcionSelect !== "inventario" ? "Prefijo" : "Bodega"}</span>
                            <select
                                className="form-control"
                                disabled={!opcionSelect}
                                value={prefijo}
                                onChange={(e) => {
                                    setPrefijo(e.target.value);
                                    {
                                        e.target.value === "PRY" ? setNombre(`${emp}_${opcionSelect}_proyectos`)
                                            : setNombre(`${emp}_${opcionSelect}_${e.target.value.toLowerCase()}`)
                                    }
                                }}>

                                <option value="" disabled>Seleccione</option>
                                {opcionSelect !== "inventario" ? selectOptions(lisPref) : selectOptions(lisBod)}
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text">Nombre Documento</span>
                            <input type="text"
                                readOnly
                                className="form-control"
                                value={prefijo !== "PRY" ? `${emp}_${opcionSelect}_${prefijo.toLowerCase()}`
                                    : `${emp}_${opcionSelect}_proyectos`}

                            />
                        </div>

                        <div className="row justify-content-center mt-5">
                            <button className=" w-25 btn btn-md btn-primary"
                                onClick={migrar}
                            >
                                Migrar
                            </button>
                        </div>

                    </div>
                </>
            }
        </>
    )
};
