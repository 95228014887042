import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import uniqid from "uniqid";
import { read, utils } from "xlsx";

import {
  actualizarDocumentoDatabase,
  consultarDocumentoWhere,
  guardarDatabaseId
} from "../../config/firebase";

export const CarMasInventario = ({ listaBod, listaProy, listaArt, listaInvAct, tipo, onRespuesta, consecutivo }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const fechaActual = new Date();
  const fechaActualFormat = fechaActual.toLocaleDateString("es-CO");

  const [listaCabProy, setListaCabProy] = useState([])
  const [tableHeaders, setTableHeaders] = useState([]);
  const [listaCabBod, setListaCabBod] = useState([])
  const [minusculaK, setMinusculaK] = useState([]);
  const [minuscula, setMinuscula] = useState([]);
  const [errores, setErrores] = useState([]);
  const [datosX, setDatosX] = useState([]);
  const [headX, setHeadX] = useState([]);
  const [infoX, setInfoX] = useState([]);

  const [sonIguales, setSonIguales] = useState(false);
  const [ok, setOk] = useState(false);

  /******** FUNCIONES ***********/
  const enviarRespuestaCarga = (resp) => {
    if (resp !== "") {
      const nuevaRespuesta = resp;
      onRespuesta(nuevaRespuesta);
    }
  };

  useEffect(() => {
    if (tipo === "Bodega") {
      let cbB = ["Consecutivo", "Bodega", "Ubicacion", "Cant_disponible",
        "listaSeriales", "Observacion", "usaSerial", "Valor"]
      setListaCabBod(cbB);

    } else if (tipo === "Proyecto") {
      let cbP = ["Consecutivo", "Proyecto", "Cant_reservada", "listaSeriales",
        "Observacion", "usaSerial", "Valor"]
      setListaCabProy(cbP);
    }
  }, []);

  useEffect(() => {
    const seleccionarArchivo = () => {
      const fileInput = document.getElementById("file-input");
      fileInput.click();
    };
    const fileInputButton = document.getElementById("file-input-button");
    if (fileInputButton) {
      fileInputButton.addEventListener("click", seleccionarArchivo);
    }
    return () => {
      if (fileInputButton) {
        fileInputButton.removeEventListener("click", seleccionarArchivo);
      }
    };
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      setDatosX(jsonData);
      toast.success("Datos obtenidos correctamente");
      const cx = jsonData.length >= 1 ? Array.from(jsonData[0]) : [];
      const dxArray = jsonData.length >= 1 ? Array.from(jsonData) : [];
      dxArray.splice(0, 1);
      setHeadX(cx);
      setInfoX(dxArray);
    };
    reader.readAsArrayBuffer(file);
  };

  const validarCabecerasIguales = (tipo) => {
    let ct = [];
    if (tipo === "Bodega") {
      ct = listaCabBod.map((valT) => valT.toLowerCase());
    } else if (tipo === "Proyecto") {
      ct = listaCabProy.map((valT) => valT.toLowerCase());
    }
    const cx = headX.map((valX) => valX.toLowerCase());
    const res = ct.length === cx.length && ct.every((valor, index) => valor === cx[index].toLowerCase());

    if (res) {
      toast.success("Las cabeceras son iguales");
      convertirA_Objeto(datosX);
      crearCabeceras()
    } else {
      crearCabeceras()
      toast.warning("Las cabeceras no coinciden, verifique el archivo e intente nuevamente.");
      enviarRespuestaCarga("Cancel")
    }
    setSonIguales(res);
  };

  const convertirA_Objeto = (val) => {
    let resultadoK = []
    const resultado = {}

    let id = null;
    let conc = null
    for (let i = 1; i < val.length; i++) {
      const item = val[i];
      const objeto = {};

      for (let j = 0; j < item.length; j++) {
        let clave = val[0][j];
        let valor = item[j];

        if (typeof valor === "string") {
          valor = valor.trim().toUpperCase();
        }

        if (clave === "consecutivo") {
          conc = valor
          const articuloExistente = listaArt.find(
            (item) => item.consecutivo === conc);
          id = articuloExistente?.id
          clave = "articulo"
        }
        if (clave === "usaserial") {
          if (valor === 1 || valor === "1") {
            valor = true;
          } else if (valor === 0 || valor === "0") {
            valor = false;
          }
        }
        if (clave === "listaSeriales") {
          if (valor !== "*") {
            const serialesArray = valor.toString()?.split(",");
            valor = serialesArray;
          } else {
            valor = [];
          }
        }
        if (clave === "observacion") {
          if (typeof valor === "string" && valor !== "*") {
            valor = valor.toUpperCase()
          } else if (typeof valor === "undefined" || valor === "*") {
            valor = "";
          }
        }
        if (clave === "cant_disponible" || clave === "cant_reservada") {
          if (valor !== "*") {
            valor = parseInt(valor)
          } else if (valor === "*") {
            valor = 0;
          }
        }
        if (clave === "valor") {
          if (valor !== "*") {
            valor = parseFloat(valor)
          } else if (valor === "*") {
            valor = 0;
          }
        }
        if (valor !== undefined && valor !== null && valor !== "") {
          objeto[clave] = valor;
        }
      }
      if (Object.keys(objeto).length > 0) {
        objeto.id = uniqid("inventario-");
        objeto.fecha_creacion = fechaActualFormat;
        objeto.fecha_carga = fechaActualFormat;
        objeto.usuario_creacion = user;
        objeto.idArticulo = id;
        resultadoK.push(objeto);
      }
      if (tipo === "Bodega") {
        resultado[objeto.bodega + "\\" + objeto.ubicacion + "\\" + objeto.articulo] = objeto;
      } else if (tipo === "Proyecto") {
        resultado[objeto.proyecto + "\\" + objeto.articulo] = objeto;
      }
    }
    setMinuscula(resultado);
    setMinusculaK(JSON.parse(JSON.stringify(resultadoK)));
  };

  const validarData = async () => {
    let contador = 0;
    const listaNueva = [];
    let lsBd;
    if (tipo === "Bodega") {
      if (minuscula && Object.keys(minuscula).length > 0) {
        for (const key1 in minuscula) {
          if (minuscula.hasOwnProperty(key1)) {
            contador++;
            const objeto1 = minuscula[key1];
            const conc = objeto1.articulo;
            const bdg = objeto1.bodega;
            const ubc = objeto1.ubicacion;
            const ls = objeto1.listaSeriales;
            const cant = objeto1.cant_disponible;
            const conSerial = objeto1.usaserial ? true : false;

            const articuloExistente = listaArt.find((item) => item.consecutivo === conc);
            if (articuloExistente === undefined) {
              listaNueva.push(`El  artículo de concecutivo ${conc} no existe`);
              setErrores(listaNueva);
            } else {

              let serial = articuloExistente?.serial ? true : false;

              if (conSerial !== serial) listaNueva.push(`El ${conc} no lleva serial`);
              const existeBodega = listaBod.find((item) => item.consecutivo === bdg);
              if (!existeBodega) listaNueva.push(`La bodega ${bdg} no existe`);

              const listaUbic = existeBodega.listaUbicaciones && existeBodega.listaUbicaciones;
              const existeUbicacion = listaUbic.find(item => item.ubicacion === ubc);
              if (!existeUbicacion) listaNueva.push(`La ubicacion ${ubc}, de la ${bdg} no existe`);

              if (serial) {
                let itemInvAct = listaInvAct.find((l) => l.articulo === conc && l.bodega === bdg && l.ubicacion === ubc)
                if (itemInvAct !== undefined) {
                  lsBd = itemInvAct.listaSeriales !== undefined ? itemInvAct.listaSeriales : [];
                }

                if (serial && ls?.length === 0) listaNueva.push(`El consecutivo ${conc}, lleva seriales y no los tiene`);

                if (parseInt(ls?.length) !== parseInt(cant)) listaNueva.push(`En el consecutivo ${conc}, hacen falta o le sobran seriales`);

                if (ls?.length > 0 && lsBd?.length > 0) {
                  let repetidos = ls?.filter(item => lsBd?.includes(item));
                  if (repetidos?.length > 0) listaNueva.push(`El consecutivo ${conc}, tiene seriales que ya existen ${repetidos.map((r) => r,)}`);
                }
              }

              if (contador === Object.keys(minuscula).length) {
                setErrores(listaNueva)
                if (listaNueva.length === 0) {
                  toast.success("Puede subir la informacion sin problemas")
                  setOk(true)
                }
              }
            }
          }
        }
      }
    } else if (tipo === "Proyecto") {
      if (minuscula && Object.keys(minuscula).length > 0) {
        for (const key1 in minuscula) {
          if (minuscula.hasOwnProperty(key1)) {
            contador++;
            const objeto1 = minuscula[key1];
            const conc = objeto1.articulo;
            const pry = objeto1.proyecto;
            const ls = objeto1.listaSeriales;
            const cant = objeto1.cant_reservada;
            const conSerial = objeto1.usaserial ? 1 : 0;

            const articuloExistente = listaArt.find((item) => item.consecutivo === conc);

            if (articuloExistente === undefined) {
              listaNueva.push(`El artículo de concecutivo ${conc} no existe`);
              setErrores(listaNueva);
            } else {
              let serial = articuloExistente.serial ? 1 : 0;
              if (conSerial !== serial) listaNueva.push(`En ${conc}, los campos usaSerial no coinciden`);

              const existeProyecto = listaProy.find((item) => item.consecutivo === pry);
              if (!existeProyecto) listaNueva.push(`El proyecto ${pry} no existe`);

              if (serial) {
                let itemInvAct = listaInvAct.find((l) => l.articulo === conc && l.proyecto === pry)
                if (itemInvAct !== undefined) {
                  lsBd = itemInvAct.listaSeriales !== undefined ? itemInvAct.listaSeriales : [];
                }
                if (serial && ls?.length === 0) listaNueva.push(`El consecutivo ${conc}, lleva seriales y no los tiene`);

                if (parseInt(ls?.length) !== parseInt(cant)) listaNueva.push(`En el consecutivo ${conc},  hacen falta o le sobran seriales`);

                if (ls.length > 0 && lsBd.length > 0) {
                  let repetidos = ls.filter(item => lsBd.includes(item));
                  if (repetidos.length > 0) listaNueva.push(`El consecutivo ${conc}, tiene seriales que ya existen ${repetidos.map((r) => r,)}`);
                }
              }
              if (contador === Object.keys(minuscula).length) {
                setErrores(listaNueva)
                if (listaNueva.length === 0) {
                  toast.success("Puede subir la informacion sin problemas")
                  setOk(true)
                }
              }
            }
          }
        }
      }
    }
  };

  const crearCabeceras = () => {
    let cabeceras = [];
    if (tipo === "Bodega") {
      cabeceras = listaCabBod.map((header, index) => {
        return <th key={index}>{header}</th>;
      });
    } else if (tipo === "Proyecto") {
      cabeceras = listaCabProy.map((header, index) => {
        return <th key={index}>{header}</th>;
      });
    }
    setTableHeaders(cabeceras)
  };

  const procesarData = (t) => {
    let lsBd;
    if (t === "Proyecto") {
      for (const key in minuscula) {
        if (minuscula.hasOwnProperty(key)) {
          const objetoActual = minuscula[key];
          const pry = objetoActual.proyecto;
          const conc = objetoActual.articulo;
          let ls = [];

          let inventarioItem = listaInvAct.find(i => (i.articulo === conc && i.proyecto === pry));
          if (inventarioItem) {
            if (inventarioItem.listaSeriales !== undefined) {
              lsBd = (inventarioItem.listaSeriales);
              ls = [...lsBd, ...objetoActual.listaSeriales]
            }
            objetoActual.cant_reservada = (inventarioItem.cant_reservada += objetoActual.cant_reservada);
            !objetoActual.usaserial ? delete objetoActual.listaSeriales : objetoActual.listaSeriales = ls;
          }
          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
        }
      };
      return minuscula;

    } else if (t === "Bodega") {
      for (const key in minuscula) {
        if (minuscula.hasOwnProperty(key)) {
          const objetoActual = minuscula[key];
          const bdg = objetoActual.bodega;
          const ubc = objetoActual.ubicacion;
          const conc = objetoActual.articulo;
          let ls = [];

          let inventarioItem = listaInvAct.find(i => (i.articulo === conc && i.bodega === bdg && i.ubicacion === ubc));
          if (inventarioItem) {
            if (inventarioItem.listaSeriales !== undefined) {
              lsBd = (inventarioItem.listaSeriales);
              ls = [...lsBd, ...objetoActual.listaSeriales]
            }
            objetoActual.cant_disponible = (inventarioItem.cant_disponible += objetoActual.cant_disponible);
            !objetoActual.usaserial ? delete objetoActual.listaSeriales : objetoActual.listaSeriales = ls;
          }

          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
        }
      };
      return minuscula;
    }
  };

  const guardarMasivos = async () => {
    if (tipo === "Bodega") {
      let minB = procesarData("Bodega");
      if (minB && Object.keys(minB).length > 0) {
        try {
          await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario`, minB);
        } catch (error) {
          console.error(error)
        }
        for (const key in minusculaK) {
          if (minusculaK.hasOwnProperty(key)) {
            const objetoActual = minusculaK[key];
            objetoActual.cantidad = objetoActual.cant_disponible;
            delete objetoActual.cant_disponible;
            delete objetoActual.usuario_creacion;
            delete objetoActual.fecha_creacion;
          }
        }
        try {
          const res = await cargarInventarioEnKardex(minusculaK);
          if (res) {
            toast.success("La carga masiva de inventario se completo con éxito");
          }
        } catch (error) {
          console.error(error)
        }
      }

    } else if (tipo === "Proyecto") {
      let minP = procesarData("Proyecto");
      if (minP && Object.keys(minP).length > 0) {
        try {
          await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario`, minP);
        } catch (error) {
          console.error(error)
        }
        for (const key in minusculaK) {
          if (minusculaK.hasOwnProperty(key)) {
            const objetoActual = minusculaK[key];
            objetoActual.cantidad = objetoActual.cant_reservada;
            delete objetoActual.cant_reservada;
            delete objetoActual.usuario_creacion;
            delete objetoActual.fecha_creacion;
          }
        }
        try {
          const res = await cargarInventarioEnKardex();
          if (res) {
            toast.success("La carga masiva de inventario se completo con éxito");
          } else {
            return;
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  };

  const cargarInventarioEnKardex = async () => {
    let id = uniqid("kardex-")
    if (minusculaK.length !== 0) {

      for (const key in minusculaK) {
        if (minusculaK.hasOwnProperty(key)) {
          const objetoActual = minusculaK[key];
          objetoActual.id = id;
          objetoActual.usuario_creacion = user;
          objetoActual.fecha_creacion = fechaActualFormat;
        }
      }
      const enviar = { [consecutivo]: minusculaK };
      try {
        let res = await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex`, enviar);
        if (res !== "") {
          await actualizarConsecutivo();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      toast.warning("Error, no hay informacion para guardar, por favor verifica e inetenta de nuevo");
    }
  };

  const actualizarConsecutivo = async () => {
    const prefijo = consecutivo.split("-")[0];
    const valor = consecutivo.split("-")[1];
    try {
      const respuestaConsec = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", prefijo);
      if (respuestaConsec.length > 0) {
        const idDoc = respuestaConsec[0].idDocumento;
        const valorActual = {
          valorActual: parseInt(valor),
        };
        try {
          await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, valorActual);
          let consec = prefijo + "-" + (parseInt(valor) + 1)
          enviarRespuestaCarga(consec)
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  };

  //********************************************************************/
  return (
    <>
      <ToastContainer position="bottom-right" />

      {/* Boton carga masiva inventario */}
      {datosX.length === 0 ?
        (<>
          <button
            id="file-input-button"
            className="btn fa-solid fa-file-csv"
            disabled
            title="Cargar Datos desde Excel"
          />
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </>)
        : null}

      {datosX.length >= 1 ?
        (
          <button
            className="btn btn-sm  fa-regular fa-eye"
            data-bs-toggle="modal"
            data-bs-target="#ModalVistaDatos"
            title="Ver Datos Cargados"
            onClick={() => validarCabecerasIguales(tipo)}
          />
        )
        : null}

      {/* +Modal de Visualizacion*/}
      <div
        className="modal fade "
        aria-hidden="true"
        id="ModalVistaDatos"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false" >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header">
              <h3 className={sonIguales ? "modal-title " : "modal-title text-danger"}>
                {sonIguales
                  ? "Las cabeceras coinciden"
                  : "Las cabeceras no coinciden "}
                <span style={{ fontSize: "17px", color: "black" }}> ( {tipo} )</span>
              </h3>
            </div>

            <div className="modal-body">
              <table className="table table-hover table-striped table-sm">
                <thead className="text-center">
                  <tr>{tableHeaders}</tr>
                </thead>

                <tbody className="text-center" id="tabla_articulos">
                  {infoX.map((x, index) => (
                    <tr key={index}>
                      {x.map((y, colIndex) => (
                        <td key={colIndex}>
                          {y}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="modal-footer">
              {sonIguales && !ok ?
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalError"
                  id="modalEliminar"
                  title="Visualizar Datos Cargados"
                  onClick={() => validarData()}>
                  Validar data
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                  enviarRespuestaCarga("Cancel")
                }}> Cancelar </button>
            </div>
          </div>
        </div>
      </div>

      {/* -Modal de fallas */}
      <div
        className="modal fade "
        aria-hidden="true"
        id="ModalError"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false" >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header">
              <h3 className="modal-title ">
                Resultado validacion
              </h3>
            </div>

            <div className="modal-body text-center">
              {ok ?
                <h5 className="modal-title ">
                  Puede constinuar con la carga, la validacion ha sido existosa
                </h5>
                :
                <>
                  <h6 className={ok ? "modal-title " : "modal-title text-danger"}>
                    Se han detectado problemas de coincidencia en los siguientes campos. <br /> Por favor, corríjalos e inténtelo de nuevo.
                  </h6>
                  <table className="table table-hover table-striped table-sm">
                    <thead className="text-center">
                      <tr><th>Problemas</th></tr>
                    </thead>
                    <tbody className="text-center" id="tabla_articulos">
                      {errores.map((x) => (
                        <tr key={x}>
                          <td >
                            {x}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>}
            </div>

            <div className="modal-footer">
              {ok ?
                <button
                  type="button"
                  className="btn btn-primary text-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDatosX([]);
                    setInfoX([]);
                    setHeadX([]);
                    guardarMasivos();
                  }}>
                  Cargar Data
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                  enviarRespuestaCarga("Cancel")
                }} >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
