import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { ToastContainer, toast } from "react-toastify";

import { AdminEmpresa, AdminUsuario, CambioURL, Migracion, UsuariosPermisos } from "./superAdmin/index";
import { Articulos, Bodegas, Clasificaciones, Consecutivos, HojaVidaArt, Proyectos, Terceros, Unidades, Usuarios } from "./administracion/index";
import { ConsAjusteInv, ConsArticulo, ConsInventario, ConsMovTransito, ConsMovimientos, ConsSolicitudes } from "./consultas/index";
import { MovPendientes, Moventrada, Movsalida, Movimiento, DevPendientes } from "./movimientos/index";
import { ReportEntradas, ReportMovimientos, ReportSalidas, ReportSolicitudes } from "./reportes/index";
import { AjusteInventario } from "./AjusteInventario";
import { GestionSolicitudes } from "./GestionSolicitudes";
import { MiEmpresa } from "./MiEmpresa";
import { Solicitudes } from "./Solicitudes";

import bcrypt from "bcryptjs";
import sortBy from "sort-by";
import logoesok from "../images/E.png";
import logoes from "../images/ewhite.png";
import Home from "./Home";
import LogoutButton from "./LogoutButton";

import {
  MenuItem,
  Menu as ProMenu,
  Sidebar,
  SubMenu
} from "react-pro-sidebar";

import {
  aplanarObjetos,
  fixConsecutivo,
  isMobile
} from "../config/functions/funGenerales";

import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  consultarDocumentoWhere,
  consultarDocumentoWhereIgual,
  obtenerURLImagen,
  useCollectionSnapshot,
  useDocumentSnapshot,
  useDocumentSnapshotUsuario
} from "../config/firebase";

import "react-toastify/dist/ReactToastify.css";
import "../css/menu.css";

export const Menu = () => {

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#607489",
      },
      font: {
        color: "black"
      }
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
        /*  color: "white", */
      },
      font: {
        color: "white"
      }
    },
  };

  const location = useLocation();
  const history = useHistory();

  const tipoUsuario = JSON.parse(localStorage.getItem("token")).tipo;
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const nombre = JSON.parse(localStorage.getItem("token")).name;
  const [empresa, setEmp] = useState(JSON.parse(localStorage.getItem("token")).empresa)
  const idDocumento = JSON.parse(localStorage.getItem("token")).idDocumento

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [theme, setTheme] = useState("dark")

  const [componente, setComponente] = useState(localStorage.getItem("componente") || "Home")
  const [activoHover, setActivoHover] = useState(null);

  const [uP, setUP] = useState("")
  const [sol, setSol] = useState("");
  const [ls, setLs] = useState([]);
  const [la, setLa] = useState([]);
  const [li, setLi] = useState([]);
  const [ts, setTs] = useState([]);

  const [permisos, setPermisos] = useState([])
  const [movimientosTemp, setMovimientosTemp] = useState([]);
  const [solicitudTemp, setSolicitudTemp] = useState([]);
  const [solicitudesFiltradas, setSolicitudesFiltradas] = useState([]);
  const [cantSolicutudes, setCantSolicutudes] = useState(0);

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const usuarioModificacion = user;
  const fechaModificacion = fechaFormateada;

  const [activo, setActivo] = useState("Home")
  const activar = activo;

  const [movShow, setMovShow] = useState(false);
  const [soliShow, setSoliShow] = useState(false);
  const [adminShow, setAdminShow] = useState(false);
  const [superShow, setSuperShow] = useState(false);
  const [reportShow, setReportShow] = useState(false);
  const [procesShow, setProcesShow] = useState(false);
  const [consultShow, setConsultShow] = useState(false);

  const [claveActual, setClaveActual] = useState("");
  const [clave1, setClave1] = useState("");
  const [clave2, setClave2] = useState("");

  const [empresas, setEmpresas] = useState([])
  const [listaEmpresas, setListaEmpresas] = useState([])
  const [listaRechazos, setListaRechazos] = useState([]);
  const [listaPendientesM, setListaPendientesM] = useState([]);

  const [logoVer, setLogoVer] = useState("")
  const [mobile, setMobile] = useState(false)

  const [tipoSolicitud, setTipoSolicitud] = useState('EPP')

  const [datosSnapshotMovimientos, setDatosSnapshotMovimientos] = useState([]);
  useCollectionSnapshot(`${empresa}_movimientos`, setDatosSnapshotMovimientos);
  const [datosSnapshotListaMovimientos, setDatosSnapshotListaMovimientos] = useState([]);

  const [datosSnapshotInventario, setDatosSnapshotInventario] = useState([]);
  useCollectionSnapshot(`${empresa}_inventario`, setDatosSnapshotInventario);

  const [datosSnapshotKardex, setDatosSnapshotKardex] = useState([]);
  useCollectionSnapshot(`${empresa}_kardex`, setDatosSnapshotKardex);

  const [datosSnapshotListaArticulos, setDatosSnapshotListaArticulos] = useState([]);
  const [datosSnapshotArticulos, setDatosSnapshotArticulos] = useState([]);
  useCollectionSnapshot(`${empresa}_articulos`, setDatosSnapshotArticulos);

  const [datosSnapshotAjustesInventario, setDatosSnapshotAjustesInventario] = useState([]);
  useDocumentSnapshot(`${empresa}_ajuste_inv`, `${empresa}_ajuste_inv`, setDatosSnapshotAjustesInventario);

  const [datosSnapshotSolicitudes, setDatosSnapshotSolicitudes] = useState([]);
  useDocumentSnapshot(`${empresa}_solicitudes`, `${empresa}_solicitudes`, setDatosSnapshotSolicitudes);

  const [datosSnapshotTerceros, setDatosSnapshotTerceros] = useState([]);
  useDocumentSnapshot(`${empresa}_terceros`, `${empresa}_terceros`, setDatosSnapshotTerceros);

  const [datosSnapshotUsuario, setDatosSnapshotUsuario] = useState([]);
  useDocumentSnapshotUsuario(idDocumento, setDatosSnapshotUsuario);

  const [datosSnapshotListaInventario, setDatosSnapshotListaInventario] = useState([]);
  const [datosSnapshotListaAjustesInventario, setDatosSnapshotListaAjustesInventario] = useState([]);

  useEffect(() => {
    const tempo = ajustarArticulosListaInventario(datosSnapshotInventario)
    if (tempo === undefined) return
    setDatosSnapshotListaInventario(tempo)
  }, [datosSnapshotInventario, datosSnapshotArticulos]);

  useEffect(() => {
    const tempo = ajustarArticulosListaInventario(datosSnapshotAjustesInventario)
    if (tempo === undefined) return
    setDatosSnapshotListaAjustesInventario(tempo)
  }, [datosSnapshotAjustesInventario]);

  useEffect(() => {
    const listaTempMovimientos = aplanarObjetos(datosSnapshotMovimientos);
    const tempo = ajustarArticulosListaMovimientos(listaTempMovimientos)
    if (tempo === undefined) return
    setDatosSnapshotListaMovimientos(tempo)
  }, [datosSnapshotMovimientos]);

  useEffect(() => {
    procesarDatosSolMov(datosSnapshotSolicitudes).then(resp => {
      setSolicitudTemp(resp)
    })
    procesarDatosSolMov(datosSnapshotMovimientos).then(resp => {
      setMovimientosTemp(resp)
    })
  }, [datosSnapshotSolicitudes, datosSnapshotMovimientos]);

  useEffect(() => {
    setDatosSnapshotListaArticulos(agregarFix(datosSnapshotArticulos))
  }, [datosSnapshotArticulos]);

  const ajustarArticulosListaInventario = (listaInv) => {
    const articulos = datosSnapshotArticulos === undefined || (datosSnapshotArticulos && datosSnapshotArticulos.length === 0)
    const lista = listaInv === undefined || (listaInv && listaInv.length === 0)
    if (articulos || lista) return
    const arrayTemporal = []
    listaInv.forEach(e => {
      const temporalArticulo = datosSnapshotArticulos.find(at => at.consecutivo === e.articulo)
      let consecutivoFix = '', consecutivoFixMov = '';
      consecutivoFix = temporalArticulo?.consecutivo === undefined ? consecutivoFix : fixConsecutivo(temporalArticulo?.consecutivo)
      consecutivoFixMov = e?.consec_ajin === undefined ? consecutivoFixMov : fixConsecutivo(e?.consec_ajin)
      const datocompleto = {
        ...temporalArticulo,
        ...e,
        consecutivoFix,
        consecutivoFixMov
      }
      arrayTemporal.push(datocompleto)
    });

    const ordenarArray = (array) => {
      const ordenado = array.sort((a, b) => {
        if (a.consecutivoFix < b.consecutivoFix) return -1;
        if (a.consecutivoFix > b.consecutivoFix) return 1;

        const aDisp = a.cant_disponible ? parseFloat(a.cant_disponible) : 0
        const bDisp = b.cant_disponible ? parseFloat(b.cant_disponible) : 0
        const aRese = a.cant_reservada ? parseFloat(a.cant_reservada) : 0
        const bRese = b.cant_reservada ? parseFloat(b.cant_reservada) : 0
        const aCant = aDisp + aRese
        const bCant = bDisp + bRese

        if (aCant < bCant) return -1;

        return 0;
      });
      return ordenado
    }

    return (ordenarArray(arrayTemporal))
  };

  const ajustarArticulosListaMovimientos = (listaAux) => {
    const articulos = datosSnapshotArticulos === undefined || (datosSnapshotArticulos && datosSnapshotArticulos.length === 0)
    const lista = listaAux === undefined || (listaAux && listaAux.length === 0)
    if (articulos || lista) return

    const arrayTemporal = []
    listaAux.forEach(e => {
      const temporalArticulo = datosSnapshotArticulos?.find(at => at.consecutivo === e.articulo)
      const datocompleto = {
        ...temporalArticulo,
        ...e,
        estadoArt: temporalArticulo?.estado,
        estadoMov: e.estado,
        consecutivoFix: fixConsecutivo(temporalArticulo.consecutivo),
        consecutivoFixMov: fixConsecutivo(e.consecutivo)
      }
      arrayTemporal.push(datocompleto)
    });
    const sortedArray = arrayTemporal.sort(sortBy('consecutivoFixMov'));
    if (JSON.stringify(listaAux) !== JSON.stringify(sortedArray)) {
      return sortedArray
    }
  };

  const agregarFix = (lista) => {
    if (!lista) return
    const arrayTemporal = []
    lista.forEach(e => {
      const consecutivoFix = fixConsecutivo(e.consecutivo)
      const datocompleto = {
        ...e,
        consecutivoFix,
      }
      arrayTemporal.push(datocompleto)
    });
    return arrayTemporal
  };

  const procesarDatosSolMov = async (lista) => {
    const arreglo = [];
    lista.forEach((element) => {
      Object.entries(element).forEach(([key, value]) => {
        if (key !== "consecutivo" && typeof value === "object") {
          let datoTemporal = { consecutivo: element.consecutivo, ...value };
          let datoTemporalSinDetalle = {
            consecutivo: datoTemporal.consecutivo,
            fecha_creacion: datoTemporal.fecha_creacion,
            articulo: datoTemporal.articulo,
            detalle: datoTemporal,
          };
          arreglo.push(datoTemporalSinDetalle);
        }
      });
    });
    return (arreglo);
  };

  //--------------------------------------------
  useEffect(() => {
    localStorage.setItem("collapsed", false);
    localStorage.setItem("broken", false);
    cargarEmpresas()
    cargarPermisos()
    isMobile(setMobile)
  }, []);


  useEffect(() => {
    const existeComponente = (location.state)?.componente
    const org = (location.state)?.origen
    if (existeComponente) {
      setComponente((location.state).componente)
      if (org === "Usuario") setUP((location.state).userP);
      if (org === "ConsSolicitudes") {
        setSol((location.state).sol);
        setLs((location.state).listaSol)
        setLa((location.state).listaArt)
        setLi((location.state).listaInv)
        setTs((location.state).todasSol)
      }
    }
  }, [location]);

  const cargarPermisos = async () => {
    const temporal = await consultarDocumentoWhereIgual("usuarios", "usuario", user)
    setPermisos(temporal[0]?.permisos ? temporal[0].permisos : [])
  };

  //----------------------------------------------
  const pendientesUsuario = async (tipo) => {
    let enTransitoB = [], rechazadosB = [];
    let enTransitoP = [], rechazadosP = [];

    const procesarMovimientos = (filtro1, filtro2) => {
      let enTransito = [], rechazados = [];

      for (const key in datosSnapshotMovimientos) {
        const obj = datosSnapshotMovimientos[key];
        const transacciones = Object.values(obj);

        const listaEnTransito = transacciones.filter(filtro1);
        enTransito = [...enTransito, ...listaEnTransito];

        const listaRechazados = transacciones.filter(filtro2);
        rechazados = [...rechazados, ...listaRechazados];
      }

      return [enTransito, rechazados];
    };

    if (tipo === "mover") {
      [enTransitoB, rechazadosB] = procesarMovimientos(
        (tr) => tr.estado === "transito",
        (item) => item.estado === "rechazado"
      );
    } else if (tipo === "asignar") {
      [enTransitoP, rechazadosP] = procesarMovimientos(
        (tr) => tr.estado === "transito",
        (tr) => tr.estado === "rechazado"
      );
    }

    const todosMov = [...enTransitoB, ...enTransitoP];
    const todosRech = [...rechazadosB, ...rechazadosP];

    setListaPendientesM(todosMov.sort(sortBy("consecutivo")));
    setListaRechazos(todosRech.sort(sortBy("consecutivo")));
  };

  useEffect(() => {
    pendientesUsuario("mover")
    pendientesUsuario("asignar")
  }, [movimientosTemp]);

  useEffect(() => {
    if (solicitudTemp.length > 0) {
      let contados = {};
      let cant = 0;
      solicitudTemp.forEach((s) => {
        if (s.detalle.estado !== "procesado") {
          if (!contados[s.consecutivo]) {
            contados[s.consecutivo] = true;
            cant++;
          }
        }
      });
      setCantSolicutudes(cant)
    } else {
      setCantSolicutudes(0)
    }

    const arrayResultado = solicitudTemp
      .filter(item => item.detalle.estado !== 'procesado')
      .filter((item, index, self) => self.findIndex(i => i.consecutivo === item.consecutivo) === index);
    setSolicitudesFiltradas(arrayResultado);
  }, [solicitudTemp]);

  //------------------------------------------
  const cambiarClave = async () => {
    if (clave1 !== "" && clave2 !== "" && claveActual !== "") {
      const listaTemporal = await consultarDocumentoWhere(
        "usuarios",
        "usuario",
        user.toUpperCase()
      );
      const hpwd = bcrypt.compareSync(claveActual, listaTemporal[0].clave);
      if (hpwd === true) {
        if (clave1 === clave2) {
          const nuevaClave = bcrypt.hashSync(clave1, 10);

          const enviarUsuario = {
            clave: nuevaClave,
            cambioClave: false,
            usuarioModificacion: usuarioModificacion,
            fechaModificacion: fechaModificacion,
          };
          await actualizarDocumentoDatabase(
            "usuarios",
            listaTemporal[0].idDocumento,
            enviarUsuario
          );
          setClave1("");
          setClave2("");
          setClaveActual("");

          toast.success(
            "Contraseña modificada de manera correcta, ya puede usarla en su proximo ingreso al aplicativo"
          );
        } else {
          toast.warning("Las contraseñas no coinciden, intente nuevamente");
        }
      } else {
        toast.warning("Contraseña incorrecta, intente nuevamente");
      }
    } else {
      toast.warning("Diligencie ambos campos");
    }
  };

  //------------------------------------------
  /* gestion de empresas */
  const cargarEmpresas = async () => {
    if (tipoUsuario === "SUPERADMIN") {
      const listaTemporal = await consultarDatabase("empresas");
      setListaEmpresas(listaTemporal)
    } else {
      const listaTemporal = await consultarDocumentoWhereIgual("empresas", "idEmpresa", empresa)
      setListaEmpresas(listaTemporal)
    }
  };

  useEffect(() => {
    if (tipoUsuario === "SUPERADMIN") {
      const arrayTemp = []
      listaEmpresas.forEach(emp => {
        const temporal = emp.idEmpresa
        arrayTemp.push(temporal)
      });

      setEmpresas(arrayTemp)
    }
    cargarLogo(empresa)

  }, [listaEmpresas]);

  useEffect(() => {
    //setEmpresa(empresa)
    const settoquen = {
      userName: user,
      tipo: tipoUsuario,
      name: nombre,
      empresa: empresa,
      idDocumento: idDocumento
    }
    localStorage.setItem("token", JSON.stringify(settoquen));
    cargarLogo(empresa)

  }, [empresa]);

  const cargarLogo = (em) => {
    const miEmp = listaEmpresas.find(e => e.idEmpresa === em)
    handleObtenerURLImagen(miEmp?.logo)
  };

  const handleObtenerURLImagen = async (ruta) => {
    if (ruta !== undefined) {
      try {
        const url = await obtenerURLImagen(ruta);
        setLogoVer(url);
      } catch (error) {
        toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
      }
    }
  };

  //-------------------*******************************-------------------------------

  useEffect(() => {
    localStorage.setItem("componente", componente);

    switch (componente) {
      case "MiEmpresa":
        setAdminShow(false)
        setMovShow(false)
        setSuperShow(false)
        setConsultShow(false)
        setSoliShow(false)
        setReportShow(false)
        setProcesShow(false)
        setActivo("Mi Empresa")
        history.replace("/mi_empresa");
        break;
      case "Home":
        setAdminShow(false)
        setMovShow(false)
        setSuperShow(false)
        setConsultShow(false)
        setSoliShow(false)
        setReportShow(false)
        setProcesShow(false)
        setActivo("Home")
        history.replace("/home");
        break;

      case "AdminEmpresa":
        setActivo("Admin Empresas")
        history.replace("/admin_empresa");
        break;
      case "AdminUsuario":
        setActivo("Admin Usuarios")
        history.replace("/admin_usuario");
        break;
      case "Migracion":
        setActivo("Migracion")
        history.replace("/migracion");
        break;
      case "CambioURL":
        setActivo("CambioURL")
        history.replace("/cambioURL");
        break;

      case "Moventrada":
        setActivo("Mov. Entrada")
        history.replace("/moventrada");
        break;
      case "Movsalida":
        setActivo("Mov. Salida")
        history.replace("/movsalida");
        break;
      case "Movimiento":
        setActivo("Mov. Internos")
        history.replace("/movimiento");
        break;

      case "ConsInventario":
        setActivo("Consulta Inv")
        history.replace("/cons_inventario");
        break;
      case "ConsMovimientos":
        setActivo("Consulta Mov")
        history.replace("/cons_movimientos");
        break;
      case "ConsArticulo":
        setActivo("Consulta Kdx")
        history.replace("/cons_articulo");
        break;
      case "ConsSolicitudes":
        setActivo("Consulta Sol")
        history.replace("/cons_solicitud");
        break;
      case "ConsMovTransito":
        setActivo("Consulta MTran")
        history.replace("/cons_mov_transito");
        break;
      case "ConsAjusteInv":
        setActivo("Consulta AjInv")
        history.replace("/cons_ajuste_inv");
        break;
      case "ReportEntradas":
        setActivo("Rep Entradas")
        history.replace("/report_entradas");
        break;
      case "ReportSalidas":
        setActivo("Rep Salidas")
        history.replace("/report_salidas");
        break;
      case "ReportMovimientos":
        setActivo("Rep Movimientos")
        history.replace("/report_movimientos");
        break;
      case "ReportSolicitudes":
        setActivo("Rep Solicitudes")
        history.replace("/report_solicitudes");
        break;
      case "SolicitudesEPP":
        setActivo("Sol EPP")
        history.replace("/solicitudes_epp");
        break;
      case "SolicitudesConsumibles":
        setActivo("Sol Consumibles")
        history.replace("/solicitudes_consumible");
        break;
      case "SolicitudesHerramienta":
        setActivo("Sol Herramientas")
        history.replace("/solicitudes_herramienta");
        break;
      case "AjusteInventario":
        setActivo("Ajuste Inv.")
        history.replace("/ajuste_inventario");
        break;
      case "Articulos":
        setActivo("Admin Art")
        history.replace("/articulos");
        break;
      case "Bodegas":
        setActivo("Admin Bod")
        history.replace("/bodegas");
        break;
      case "Proyectos":
        setActivo("Admin Proy")
        history.replace("/proyectos");
        break;
      case "Clasificaciones":
        setActivo("Admin Clasif")
        history.replace("/clasificaciones");
        break;
      case "Terceros":
        setActivo("Admin Terc")
        history.replace("/terceros");
        break;
      case "Consecutivos":
        setActivo("Admin Consec")
        history.replace("/consecutivos");
        break;
      case "Unidades":
        setActivo("Amin Und")
        history.replace("/unidades");
        break;
      case "Usuarios":
        setActivo("Admin Usuarios")
        history.replace("/usuarios");
        break;
      case "HojaVidaArt":
        setActivo("Admin HdeV")
        history.replace("/hoja_vida_art");
        break;
      default:
        break;
    }
  }, [componente])

  const handleMouseEnter = (index) => { setActivoHover(index); };
  const handleMouseLeave = () => { setActivoHover(null); };

  useEffect(() => {
    if (superShow) {
      setMovShow(false)
      setAdminShow(false)
      setConsultShow(false)
      setSoliShow(false)
      setReportShow(false)
      setProcesShow(false)
    }
    if (adminShow) {
      setMovShow(false)
      setSuperShow(false)
      setConsultShow(false)
      setSoliShow(false)
      setReportShow(false)
      setProcesShow(false)
    }
    if (movShow) {
      setAdminShow(false)
      setSuperShow(false)
      setConsultShow(false)
      setSoliShow(false)
      setReportShow(false)
      setProcesShow(false)
    }
  }, [adminShow, movShow, superShow, consultShow, soliShow, reportShow, procesShow])

  //-------------------*******************************-------------------------------

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div style={{ display: "flex", minHeight: "100vh" }}>

        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          rtl={false}
          breakPoint="md"
          rootStyles={{ color: themes[theme].sidebar.color, zIndex: 2 }}
          backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
          onBackdropClick={() => setToggled(false)}
          onBreakPoint={(broken) => {
            setBroken(broken)
            localStorage.setItem("broken", broken)
          }}
        >
          <div
            className={broken ? "mt-5" : ""}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "hiden",
              maxHeight: broken ? "calc(100vh - 48px)" : "100vh"
            }}
          >

            <div style={{ flex: 1, marginBottom: "32px" }} className={broken ? "mt-2" : ""}>

              {/* Logo Empresa Usuario */}
              <div className=" text-center continer">
                <img
                  src={logoVer}
                  className="logomenu"
                  alt="Logo"
                  width={collapsed ? "60px" : "150px"}
                />
                {/* Empresa-Usuario */}
                <div>
                  {/* Empresa */}
                  {tipoUsuario === "SUPERADMIN" && !collapsed
                    ? <select className="form-select form-select-sm mb-3 mt-3"
                      value={empresa}
                      onChange={(e) => {
                        setEmp(e.target.value)
                      }}>
                      {empresas.map(emp => (
                        <option key={emp} value={emp}>{emp}</option>
                      ))}
                    </select>
                    : null
                  }

                  {/* User */}
                  {!collapsed ?
                    <p
                      className="text-center pe-2 "
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarClave"
                      id="modalModificar"
                      title="Cambiar clave"
                      style={{ fontSize: "12px", cursor: "pointer" }}
                    >
                      {user}
                    </p>
                    : null
                  }

                </div>
              </div>

              <hr className="mb-2 mt-2 bg-light" />

              <ProMenu>

                <MenuItem
                  icon={<i className="fa fa-home" />}
                  active={componente === "home"}
                  onClick={() => setComponente("Home")}
                  className={"update-var " + (activar === "Home" ? "text-info" : "")}
                >
                  Home
                </MenuItem>

                <hr className="m-0 bg-light" />

                {/* Mi empresa */}
                {permisos.includes("consultarEmpresa") ?
                  <MenuItem icon={<i className="fa fa-city" />}
                    className={"update-var " + (activar === "Mi Empresa" ? "text-info" : "")}
                    onClick={() => setComponente("MiEmpresa")}>
                    Mi Empresa
                  </MenuItem>
                  : null
                }
                <hr className="m-0 bg-light" />


                {/* Super Admin------------------------------------------------------------------- */}
                {tipoUsuario === "SUPERADMIN"
                  ? <>
                    <SubMenu
                      collapsed={superShow}
                      label="SuperAdmin"
                      icon={<i className="fa-solid fa-screwdriver-wrench" />}
                      className={"update-var " + (superShow || activar === "admin empresa" || activar === "admin usuario" ? "text-info" : "")}
                      onClick={() => setSuperShow(!superShow)}>
                      <MenuItem
                        className={"update-var " + (componente === "AdminEmpresa" || activoHover === 1 ? " active" : "")}
                        onClick={() => { setComponente("AdminEmpresa") }}
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => handleMouseEnter(1)}
                        onMouseLeave={handleMouseLeave}>
                        <span className="fa-solid fa-industry  me-3"></span>Admin Empresas
                        <hr className="m-0 bg-light" />
                      </MenuItem>
                      <MenuItem
                        className={"update-var " + (componente === "AdminUsuario" || activoHover === 2 ? "active" : "")}
                        onClick={() => setComponente("AdminUsuario")}
                        onMouseEnter={() => handleMouseEnter(2)}
                        onMouseLeave={handleMouseLeave}>
                        <span className="fa-solid fa-user-plus me-3"></span>Admin Usuarios
                        <hr className="m-0 bg-light" />
                      </MenuItem>
                      <MenuItem
                        className={"update-var " + (componente === "Migracion" || activoHover === 3 ? "active" : "")}
                        onClick={() => setComponente("Migracion")}
                        onMouseEnter={() => handleMouseEnter(3)}
                        onMouseLeave={handleMouseLeave}>
                        <span className="fa-solid fa-database me-3"></span>Migración de Datos
                        <hr className="m-0 bg-light" />
                      </MenuItem>
                      <MenuItem
                        className={"update-var " + (componente === "CambioURL" || activoHover === 99 ? "active" : "")}
                        onClick={() => setComponente("CambioURL")}
                        onMouseEnter={() => handleMouseEnter(99)}
                        onMouseLeave={handleMouseLeave}>
                        <span className="fa-solid fa-link me-3"></span>Cambio URL
                        <hr className="m-0 bg-light" />
                      </MenuItem>

                    </SubMenu>
                  </>
                  : null
                }

                <hr className="m-0 bg-light" />

                {/* Movimientos------------------------------------------------------------------- */}
                {permisos.includes("ingresoMovEntrada") || permisos.includes("ingresoMovSalida") || permisos.includes("ingresoMovInterno")
                  ?
                  <>
                    <SubMenu
                      collapsed={movShow === true}
                      label="Movimientos"
                      icon={<i className=" fa-solid fa-people-carry-box" />}
                      className={"update-var " + (movShow || activar === "entradas" || activar === "salidas" || activar === "movimientos" ? "text-info" : "")}
                      onClick={() => setMovShow(!movShow)}>
                      {permisos.includes("ingresoMovEntrada")
                        ? <>
                          <MenuItem
                            className={"update-var " + (componente === "Moventrada" || activoHover === 4 ? "active" : "")}
                            onClick={() => setComponente("Moventrada")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(4)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-right-to-bracket me-3 "></span>{" "}
                            Entradas
                          </MenuItem>
                        </>
                        : null
                      }
                      {permisos.includes("ingresoMovSalida")
                        ? <MenuItem
                          className={"update-var " + (componente === "Movsalida" || activoHover === 5 ? "active" : "")}
                          onClick={() => setComponente("Movsalida")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(5)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-right-from-bracket me-3 "></span>{" "}
                          Salidas
                        </MenuItem>
                        : null
                      }
                      {permisos.includes("ingresoMovInterno")
                        ? <MenuItem
                          className={"update-var " + (componente === "Movimiento" || activoHover === 6 ? "active" : "")}
                          onClick={() => setComponente("Movimiento")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(6)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-shuffle me-3 "></span>{" "}
                          Movimientos
                        </MenuItem>
                        : null
                      }
                    </SubMenu>

                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }


                {/* Consulta------------------------------------------------------------------- */}
                {permisos.includes("consInventario") || permisos.includes("consMovimientos") || permisos.includes("consArticulos") ||
                  permisos.includes("consSolicitudes") || permisos.includes("consMovTransito") || permisos.includes("consAjusteInventario")
                  ?
                  <>
                    <SubMenu
                      collapsed={consultShow}
                      label="Consulta"
                      icon={<i className="fa-solid fa-magnifying-glass"></i>}
                      className={"update-var " + (consultShow || activar === "cons-inventario" || activar === "cons-movimientos"
                        || activar === "cons-articulo" || activar === "cons-solicitud"
                        || activar === "cons-mov-transito" || activar === "cons-ajuste-inv" ? "text-info" : "")}
                      onClick={() => {
                        setConsultShow(!consultShow)
                        setMovShow(false)
                        setAdminShow(false)
                        setSuperShow(false)
                        setSoliShow(false)
                        setReportShow(false)
                        setProcesShow(false)
                      }}>
                      {permisos.includes("consInventario")
                        ? <>
                          <MenuItem
                            className={"update-var " + (componente === "ConsInventario" || activoHover === 7 ? "active" : "")}
                            onClick={() => setComponente("ConsInventario")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(7)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-boxes-stacked me-3 "></span>{" "}
                            Inventario
                          </MenuItem>
                        </>
                        : null
                      }
                      {permisos.includes("consMovimientos")
                        ? <MenuItem
                          className={"update-var " + (componente === "ConsMovimientos" || activoHover === 8 ? "active" : "")}
                          onClick={() => setComponente("ConsMovimientos")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(8)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-right-from-bracket me-3 "></span>{" "}
                          Movimientos
                        </MenuItem>
                        : null
                      }
                      {permisos.includes("consArticulos")
                        ? <MenuItem
                          className={"update-var " + (componente === "ConsArticulo" || activoHover === 9 ? "active" : "")}
                          onClick={() => setComponente("ConsArticulo")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(9)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-shuffle me-3 "></span>{" "}
                          Kardex
                        </MenuItem>
                        : null
                      }
                      {permisos.includes("ingrconsSol")
                        ? <MenuItem
                          className={"update-var " + (componente === "ConsSolicitudes" || activoHover === 10 ? "active" : "")}
                          onClick={() => setComponente("ConsSolicitudes")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(10)}
                          onMouseLeave={handleMouseLeave}
                          suffix={cantSolicutudes}>
                          <span className="fa-solid fa-hand-holding me-3 "></span>{" "}
                          Solicitudes
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("consMovTransito")
                        ? <MenuItem
                          className={"update-var " + (componente === "ConsMovTransito" || activoHover === 11 ? "active" : "")}
                          onClick={() => setComponente("ConsMovTransito")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(11)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-dolly me-3 "></span>{" "}
                          Movimientos Transito
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("consAjusteInventario")
                        ? <MenuItem
                          className={"update-var " + (componente === "ConsAjusteInv" || activoHover === 12 ? "active" : "")}
                          onClick={() => setComponente("ConsAjusteInv")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(12)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-scale-balanced me-3 "></span>{" "}
                          Ajuste Inventario
                        </MenuItem>
                        : null
                      }
                    </SubMenu>

                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }


                {/* Reportes------------------------------------------------------------------- */}
                {permisos.includes("repEntradas") || permisos.includes("repSalidas") ||
                  permisos.includes("repMovimientos") || permisos.includes("repSolicitudes")
                  ?
                  <>
                    <SubMenu
                      collapsed={reportShow}
                      label="Reportes"
                      icon={<i className="fa-solid fa-print"></i>}
                      className={"update-var " +
                        (reportShow || activar === "report-entradas"
                          || activar === "report-salidas" || activar === "report-movimientos"
                          || activar === "report-solicitudes"
                          ? " text-info" : "")}
                      onClick={() => {
                        setReportShow(!reportShow)
                        setMovShow(false)
                        setAdminShow(false)
                        setSuperShow(false)
                        setConsultShow(false)
                        setSoliShow(false)
                        setProcesShow(false)
                      }}>


                      {permisos.includes("repEntradas")
                        ? <MenuItem
                          className={"update-var " + (componente === "ReportEntradas" || activoHover === 20 ? "active" : "")}
                          onClick={() => setComponente("ReportEntradas")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(20)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-boxes-stacked me-3 "></span>{" "}
                          Entradas
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("repSalidas")
                        ? <MenuItem
                          className={"update-var " + (componente === "ReportSalidas" || activoHover === 21 ? "active" : "")}
                          onClick={() => setComponente("ReportSalidas")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(21)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-right-from-bracket me-3 "></span>{" "}
                          Salidas
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("repMovimientos")
                        ? <MenuItem
                          className={"update-var " + (componente === "ReportMovimientos" || activoHover === 22 ? "active" : "")}
                          onClick={() => setComponente("ReportMovimientos")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(22)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-shuffle me-3 "></span>{" "}
                          Movimientos
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("repSolicitudes")
                        ? <MenuItem
                          className={"update-var " + (componente === "ReportSolicitudes" || activoHover === 23 ? "active" : "")}
                          onClick={() => setComponente("ReportSolicitudes")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(23)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-hand-holding me-3 "></span>{" "}
                          Solicitudes
                        </MenuItem>
                        : null
                      }

                      <hr className="m-0 bg-light" />
                    </SubMenu>
                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }

                {/* Solicitudes------------------------------------------------------------------------------------------------- */}
                {permisos.includes("solEpp") || permisos.includes("solConsumible") || permisos.includes("solHerramienta")
                  ?
                  <>
                    <SubMenu
                      collapsed={soliShow}
                      label="Solicitudes"
                      icon={<i className="fa-solid fa-code-pull-request"></i>}
                      className={"update-var "
                        + (soliShow || activar === "EPP" || activar === "Consumibles"
                          || activar === "Herramienta" ? " text-info" : "")}
                      onClick={() => {
                        setSoliShow(!soliShow)
                        setMovShow(false)
                        setAdminShow(false)
                        setSuperShow(false)
                        setConsultShow(false)
                        setReportShow(false)
                        setProcesShow(false)
                      }}>

                      {permisos.includes("solEpp")
                        ? <MenuItem
                          className={"update-var " + (componente === "SolicitudesEPP" || activoHover === 13 ? "active" : "")}
                          onClick={() => {
                            setTipoSolicitud("EPP")
                            setComponente("SolicitudesEPP")
                          }}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(13)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-helmet-safety me-3 "></span>{" "}
                          EPP
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("solConsumible")
                        ? <MenuItem
                          className={"update-var " + (componente === "SolicitudesConsumibles" || activoHover === 14 ? "active" : "")}
                          onClick={() => {
                            setTipoSolicitud("Consumibles")
                            setComponente("SolicitudesConsumibles")
                          }}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(14)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-paperclip me-3 "></span>{" "}
                          Consumible
                        </MenuItem>
                        : null
                      }

                      {permisos.includes("solHerramienta")
                        ? <MenuItem
                          className={"update-var " + (componente === "SolicitudesHerramienta" || activoHover === 15 ? "active" : "")}
                          onClick={() => {
                            setTipoSolicitud("Herramienta")
                            setComponente("SolicitudesHerramienta")
                          }}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(15)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-screwdriver-wrench me-3 "></span>{" "}
                          Herramienta o Material
                        </MenuItem>
                        : null
                      }

                    </SubMenu>
                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }


                {/* Procesos------------------------------------------------------------------- */}
                {permisos.includes("ingresoAjustInv")
                  ?
                  <>
                    <SubMenu
                      collapsed={procesShow}
                      label="Procesos"
                      icon={<i className="fa-solid fa-wrench"></i>}
                      className={"update-var " +
                        (procesShow === true || activar === "ajuste-inventario"
                          ? "text-info" : "")}
                      onClick={() => {
                        setProcesShow(!procesShow)
                        setMovShow(false)
                        setSoliShow(false)
                        setAdminShow(false)
                        setSuperShow(false)
                        setConsultShow(false)
                        setReportShow(false)
                      }}>

                      {/* Ajuste inventario */}
                      {permisos.includes("ingresoAjustInv")
                        ? <MenuItem
                          className={"update-var " + (componente === "AjusteInventario" || activoHover === 30 ? "active" : "")}
                          onClick={() => setComponente("AjusteInventario")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(30)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-solid fa-cubes me-3"></span> Ajuste Inventario
                        </MenuItem>
                        : null
                      }
                    </SubMenu>
                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }


                {/* Administracion---------------------------------------------------------------- */}
                {permisos.includes("ingresoArticulos") || permisos.includes("ingresoBodegas") || permisos.includes("ingresoProyectos") ||
                  permisos.includes("ingresoClasificaciones") || permisos.includes("ingresoTerceros") || permisos.includes("ingresoConsecutivos") ||
                  permisos.includes("ingresoUnidades") || permisos.includes("ingresoUsuarios")
                  ? <>
                    {tipoUsuario === "ADMIN" || tipoUsuario === "SUPERADMIN" ? (
                      <SubMenu
                        collapsed={adminShow}
                        label="Administración"
                        icon={<i className="fa-solid fa-user-shield"></i>}
                        className={"update-var "
                          + (adminShow || (activar === "articulos" || activar === "bodegas"
                            || activar === "proyectos" || activar === "clasificaciones"
                            || activar === "terceros" || activar === "consecutivos"
                            || activar === "unidades" || activar === "usuarios"
                            || activar === "hoja-vida-art") ? "text-info" : "")}
                        onClick={() => {
                          setAdminShow(!adminShow)
                          setMovShow(false)
                          setSuperShow(false)
                          setConsultShow(false)
                          setSoliShow(false)
                          setReportShow(false)
                          setProcesShow(false)
                        }}>



                        {permisos.includes("ingresoArticulos")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Articulos" || activoHover === 40 ? "active" : "")}
                            onClick={() => setComponente("Articulos")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(40)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-list-ul me-3 ">
                            </span>{" "}
                            Articulos
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoBodegas")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Bodegas" || activoHover === 41 ? "active" : "")}
                            onClick={() => setComponente("Bodegas")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(41)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-warehouse me-3"></span>{" "}
                            Bodegas
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoProyectos")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Proyectos" || activoHover === 42 ? "active" : "")}
                            onClick={() => setComponente("Proyectos")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(42)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-building-shield me-3"></span>{" "}
                            Proyectos
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoClasificaciones")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Clasificaciones" || activoHover === 43 ? "active" : "")}
                            onClick={() => setComponente("Clasificaciones")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(43)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa fa-tag me-3"></span> Clasificaciones
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoTerceros")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Terceros" || activoHover === 44 ? "active" : "")}
                            onClick={() => setComponente("Terceros")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(44)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa fa-handshake me-3"></span> Terceros
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoConsecutivos")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Consecutivos" || activoHover === 45 ? "active" : "")}
                            onClick={() => setComponente("Consecutivos")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(45)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa fa-folder-open me-3"></span>{" "}
                            Consecutivos
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoUnidades")
                          ?
                          <MenuItem
                            className={"update-var " + (componente === "Unidades" || activoHover === 46 ? "active" : "")}
                            onClick={() => setComponente("Unidades")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(46)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa-solid fa-ruler me-3"></span>{" "}
                            Unidades
                          </MenuItem>
                          : null
                        }

                        {permisos.includes("ingresoUsuarios")
                          ?
                          <MenuItem
                            className={"update-var " + ((componente === "Usuarios" || componente === "usuariopermiso") || activoHover === 47 ? "active" : "")}
                            onClick={() => {
                              setComponente("Usuarios")
                              history.push("/usuarios")
                            }}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => handleMouseEnter(47)}
                            onMouseLeave={handleMouseLeave}>
                            <span className="fa fa-users me-3"></span> Usuarios
                          </MenuItem>
                          : null
                        }

                        {/* {permisos.includes("")
                            ? */}
                        <MenuItem
                          className={"update-var " + (componente === "HojaVidaArt" || activoHover === 48 ? "active" : "")}
                          onClick={() => setComponente("HojaVidaArt")}
                          style={{ cursor: "pointer" }}
                          onMouseEnter={() => handleMouseEnter(48)}
                          onMouseLeave={handleMouseLeave}>
                          <span className="fa-regular fa-newspaper me-3"></span> HV Artículo
                        </MenuItem>

                        {/* : null
                          } */}
                      </SubMenu>
                    ) : null}

                    <hr className="m-0 bg-light" />
                  </>
                  : null
                }

                <hr className="m-0 bg-light" />
                {/* LogOut */}
                <MenuItem icon={<i className="fa-solid fa-arrow-right-from-bracket" />}>
                  <LogoutButton />
                </MenuItem>

              </ProMenu>

            </div>

            <i onClick={() => {
              setCollapsed(!collapsed)
              localStorage.setItem("collapsed", !collapsed);
            }}
              className={"btn border-0 fa-solid text-end " + (collapsed ? "fa-angle-right " : "fa-angle-left ") + (theme === "dark" ? "text-white" : "")}
            />
          </div>
        </Sidebar >

        <main className="w-100">

          {/* Header */}
          <nav className="navbar navbar-expand-lg fixed-top p-0 border-bottom"
            style={{
              backgroundColor: hexToRgba(themes[theme].sidebar.backgroundColor, 1),
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease"
            }}>
            <div className="container-fluid ">

              <div className="col">
                {broken ? (
                  <i
                    className={"btn fa-solid fa-bars " + (theme === "dark" ? "text-white" : "")}
                    onClick={() => setToggled(!toggled)} />
                )
                  : (<i
                    onClick={() => {
                      setCollapsed(!collapsed)
                      localStorage.setItem("collapsed", !collapsed);
                    }}
                    className={"btn border-0 fa-solid text-end " + (collapsed ? "fa-angle-right " : "fa-angle-left ") + (theme === "dark" ? "text-white" : "")}
                  />)}
              </div>

              <div className="col-auto">
                <span className={"navbar-text fs-6 me-1 " + (theme === "dark" ? "text-white" : "easystock-color")} >
                  {activo ? activo?.toUpperCase() : "Home"}
                </span>

                <i
                  onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                  className={"btn border-0 fa-solid fa-circle-half-stroke mb-1 " + (theme === "dark" ? "fa-rotate-180 text-white" : "")} />

                {datosSnapshotSolicitudes.length === 0 ? null :
                  <>
                    <button className="btn btn-sm position-relative border-0 ps-0"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">

                      <Player
                        src="https://lottie.host/b4125ae1-16ab-4c99-88e1-720380918938/mAtUMWi5uG.json"
                        className="player"
                        loop
                        autoplay
                        style={{ height: "40px", width: "40px" }}
                      />

                      <span className="position-absolute translate-middle badge rounded-pill bg-danger ms-2" style={{ top: "12px" }}>
                        {solicitudesFiltradas.length + listaPendientesM.length + listaRechazos.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </button>

                    <ul className="dropdown-menu" style={{ left: "auto", right: 50 }}>
                      {solicitudesFiltradas.length !== 0 && (
                        <>
                          <li><a className="dropdown-item" href="#"><strong>Solicitudes</strong></a></li>
                          <hr className="m-0" />
                          {solicitudesFiltradas.map(s =>
                            <li className="user-select-none" key={s.consecutivo} onClick={() => setComponente("ConsSolicitudes")}>
                              <span className="dropdown-item">{s.consecutivo}</span>
                            </li>
                          )}
                        </>
                      )}

                      {listaPendientesM.length !== 0 && (
                        <>
                          <li><a className="dropdown-item" href="#"><strong>Movimientos</strong></a></li>
                          <hr className="m-0" />
                          {listaPendientesM.map(s =>
                            <li className="user-select-none" key={s.id} onClick={() => setComponente("mov_pendientes")}>
                              <span className="dropdown-item user-selected-none">{s.consecutivo}</span>
                            </li>
                          )}
                        </>
                      )}

                      {listaRechazos.length !== 0 && (
                        <>
                          <li><a className="dropdown-item" href="#"><strong>Rechazos</strong></a></li>
                          <hr className="m-0" />
                          {listaRechazos.map(s =>
                            <li className="user-select-none" key={s.id} onClick={() => setComponente("dev_pendientes")}>
                              <span className="dropdown-item">{s.consecutivo}</span>
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </>
                }
                <span className="navbar-brand"><img src={theme === "dark" ? logoes : logoesok} alt="Logo" width="27" /></span>
              </div>
            </div>
          </nav>
          {/* Header */}

          <div className="container-fluid text-center mt-3 pt-1">

            {/* render condicional */}
            {componente === "Home" && <Home />}
            {componente === "MiEmpresa" && <MiEmpresa permisos={datosSnapshotUsuario} />}
            {componente === "AdminEmpresa" && <AdminEmpresa listaEmpresas={listaEmpresas} />}
            {componente === "AdminUsuario" && <AdminUsuario listaEmpresas={listaEmpresas} />}
            {componente === "Migracion" &&
              <Migracion
                articulos={datosSnapshotArticulos}
                kardex={datosSnapshotKardex}
                inventario={datosSnapshotListaInventario} />}
            {componente === "CambioURL" && <CambioURL articulos={datosSnapshotArticulos} />}
            {/* ---------------------------- */}
            {componente === "Moventrada" && <Moventrada inventario={datosSnapshotInventario}
              articulos={datosSnapshotArticulos}
              terceros={datosSnapshotTerceros}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Movsalida" && <Movsalida inventario={datosSnapshotInventario}
              articulos={datosSnapshotArticulos}
              terceros={datosSnapshotTerceros}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Movimiento" && <Movimiento inventario={datosSnapshotInventario}
              articulos={datosSnapshotArticulos}
              kardex={datosSnapshotKardex}
              movimientos={datosSnapshotMovimientos}
              listaFusion={datosSnapshotListaMovimientos}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "mov_pendientes" && <MovPendientes inventario={datosSnapshotInventario}
              articulos={datosSnapshotArticulos}
              terceros={datosSnapshotTerceros}
              kardex={datosSnapshotKardex}
              movimientos={datosSnapshotMovimientos}
              listaFusion={datosSnapshotListaMovimientos}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "dev_pendientes" && <DevPendientes inventario={datosSnapshotInventario}
              articulos={datosSnapshotArticulos}
              terceros={datosSnapshotTerceros}
              kardex={datosSnapshotKardex}
              movimientos={datosSnapshotMovimientos}
              listaFusion={datosSnapshotListaMovimientos}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsInventario" && <ConsInventario inventario={datosSnapshotListaInventario}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsMovimientos" &&
              <ConsMovimientos
                movimientos={datosSnapshotListaMovimientos}
                permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsArticulo" && <ConsArticulo
              articulos={datosSnapshotArticulos}
              inventario={datosSnapshotListaInventario}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsSolicitudes" && <ConsSolicitudes articulos={datosSnapshotArticulos}
              inventario={datosSnapshotListaInventario}
              solicitudes={datosSnapshotSolicitudes}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "gest_solicitud" && <GestionSolicitudes sol={sol}
              listaSol={ls}
              listaArt={la}
              listaInv={li}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsMovTransito" && <ConsMovTransito movimientos={datosSnapshotListaMovimientos}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ConsAjusteInv" && <ConsAjusteInv ajustes={datosSnapshotListaAjustesInventario}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ReportEntradas" && <ReportEntradas articulos={datosSnapshotArticulos}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ReportSalidas" && <ReportSalidas articulos={datosSnapshotArticulos}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ReportMovimientos" && <ReportMovimientos articulos={datosSnapshotArticulos}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "ReportSolicitudes" && <ReportSolicitudes articulos={datosSnapshotArticulos}
              kardex={datosSnapshotKardex}
              permisos={datosSnapshotUsuario} />
            }
            {(componente === "SolicitudesEPP" || componente === "SolicitudesConsumibles" || componente === "SolicitudesHerramienta") && (
              <Solicitudes articulos={datosSnapshotArticulos}
                inventario={datosSnapshotInventario}
                permisos={datosSnapshotUsuario}
                tipo={tipoSolicitud} />
            )}
            {componente === "AjusteInventario" && <AjusteInventario articulos={datosSnapshotArticulos}
              inventario={datosSnapshotInventario}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Articulos" && <Articulos articulos={datosSnapshotListaArticulos}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Bodegas" && <Bodegas terceros={datosSnapshotTerceros}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Proyectos" && <Proyectos terceros={datosSnapshotTerceros}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Clasificaciones" && <Clasificaciones permisos={datosSnapshotUsuario} />}
            {componente === "Terceros" && <Terceros terceros={datosSnapshotTerceros}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "Consecutivos" && <Consecutivos permisos={datosSnapshotUsuario} />}
            {componente === "Unidades" && <Unidades permisos={datosSnapshotUsuario} />}
            {componente === "Usuarios" && <Usuarios terceros={datosSnapshotTerceros}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "usuariopermiso" && <UsuariosPermisos usuario={uP}
              permisos={datosSnapshotUsuario} />
            }
            {componente === "HojaVidaArt" && <HojaVidaArt articulos={datosSnapshotArticulos}
              inventario={datosSnapshotInventario}
              permisos={datosSnapshotUsuario} />
            }

            {/* +Modal cambio de clave */}
            <div
              className="modal fade"
              aria-hidden="true"
              id="ModificarClave"
              tabIndex="-1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title">Cambio de Clave</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>

                  <div className="modal-body">
                    <div className="modal-body">
                      {/* clave actual */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label text-dark">
                          Clave Actual:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese su clave actual"
                            value={claveActual}
                            onChange={(e) => setClaveActual(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Nueva Clave 1 */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label text-dark">
                          Nueva Clave:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Ingrese su nueva clave"
                            value={clave1}
                            onChange={(e) => setClave1(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Nueva Clave 2 */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label text-dark">
                          Nueva Clave:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Repita su nueva clave"
                            value={clave2}
                            onChange={(e) => setClave2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={cambiarClave}
                    >
                      Guardar
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* -Modal cambio de clave  */}

          </div>

        </main>
      </div >

    </>
  );
};
