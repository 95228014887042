//  export const handleClickEliminar = async () => {
//     if (nit) {
//       try {
//         const respuesta = await eliminarDocumentoDatabaseCampo(`${emp}_terceros`, `${emp}_terceros`, nit);
//         if (respuesta) {
//           toast.success(respuesta);
//           await cargarTerceros();
//         }
//       } catch (error) {
//         console.error(error)
//       }
//     } else {
//       toast.warning("Seleccione un tercero para eliminar");
//     }
//   };

export const buscarData = (lineaModificar) => {
  const { proyecto: p, bodega: b, ubicacion: u, articulo: a, id } = lineaModificar;
  if (id !== undefined) {
    const idInv = p !== undefined ? `${p}\\${a}` : `${b}\\${u}\\${a}`;
    return idInv;
  }
};