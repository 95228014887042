import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";

import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  consultarDatabase,
  consultarDocumentoWhere,
  guardarDatabaseId,
  subirImagen
} from "../../config/firebase";

import sortBy from "sort-by";
import { changeTitle, isMobile, styles } from "../../config/functions/funGenerales";
import { traerEmpresa } from "../../config/functions/funReportes";
import { CarMasInventario } from "../cragaMasiva/CarMasInventario";
import { LoaderES } from "../LoaderES";
import { PlantillaExcel } from "../cragaMasiva/PlantillaExcel";

export const Moventrada = ({ inventario, articulos, terceros, permisos }) => {
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [listaInventarioActual, setListaInventarioActual] = useState([]);
  const [listaArticulosSeries, setListaArticulosSeries] = useState([]);
  const [listaConsecutivosCom, setlistaConsecutivosCom] = useState([]);
  const [listaConsecutivosInv, setlistaConsecutivosInv] = useState([]);
  const [listaSerialesTemp, setListaSerialesTemp] = useState([]);
  const [listaProveedores, setListaProveedores] = useState([]);
  const [listaInventarios, setListaInventarios] = useState([]);
  const [verListaSeriales, setVerListaSeriales] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaSeriales, setListaSeriales] = useState([]);
  const [listaCompras, setListaCompras] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [listaVerPDF, setListaVerPDF] = useState([]);
  const [listaPDF, setListaPDF] = useState([]);

  const [fecha_compra, setFecha_compra] = useState("");
  const [observacion, setObservacion] = useState("");
  const [fecha_carga, setFecha_carga] = useState("");
  const [usaSerial, setUsaSerial] = useState(false);
  const [ubicacion, setUbicacion] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [proyecto, setProyecto] = useState("");
  const [articulo, setArticulo] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [factura, setFactura] = useState("");
  const [bodega, setBodega] = useState("");
  const [idArt, setIdArt] = useState("");
  const [valor, setValor] = useState(0);
  const [um, setUm] = useState("");

  const [idDocumentoConsecutivoCom, setIdDocumentoConsecutivoCom] = useState("");
  const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
  const [valorConsecutivoCom, setValorConsecutivoCom] = useState(0);
  const [verConsecutivoCom, setVerConsecutivoCom] = useState("");
  const [valorConsecutivo, setValorConsecutivo] = useState(0);
  const [valorPrefijoCom, setValorPrefijoCom] = useState("");
  const [verConsecutivo, setVerConsecutivo] = useState("");
  const [valorPrefijo, setValorPrefijo] = useState("");

  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;
  const consecInventario = "INV";
  const consecCompra = "COM";

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("cargar-inventario");
  const [validarArtySeries, setValidarArtySeries] = useState(false);
  const [opcionSelecBoP, setOpcionSelecBoP] = useState("");
  const [verSeriales, setVerSeriales] = useState(false);

  const [contProyecto, setContProyecto] = useState(0);
  const [contBodega, setContBodega] = useState(0);
  const [guardo, setGuardo] = useState(false);

  const [selectTipoDescarga, setSelectTipoDescarga] = useState("");
  const [respuestaDescarga, setRespuestaDescarga] = useState("");
  const [respuestaCarga, setRespuestaCarga] = useState(null);
  const [selectTipoCarga, setSelectTipoCarga] = useState("");
  const [cargar, setCargar] = useState(0);

  const [dataEmpresa, setDataEmpresa] = useState([]);
  const [consecArt, setConsecArt] = useState("");
  const [consecMov, setConsecMov] = useState("");

  const urlFacturas = "gs://mn-app-60991.appspot.com/" + emp + "/facturas";
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [rutaEnFirestore, setRutaEnFirestore] = useState("");

  const [proveedores, setProveedores] = useState(listaProveedores);
  const [sinProveedores, setSinProveedores] = useState(false);
  const [proyectos, setProyectos] = useState(listaProyectos);
  const [articulosL, setArticulosL] = useState(listaArticulos);
  const [sinArticulos, setSinArticulos] = useState(false);
  const [bodegas, setBodegas] = useState(listaBodegas);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [mobile, setMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputFile = React.useRef(null);

  const [ubicacionSelect, setUbicacionSelect] = useState({
    value: "Seleccione Ubicación",
    label: "Seleccione Ubicación",
  });
  const [proveedorSelect, setProveedorSelect] = useState({
    value: "Seleccione Proveedor",
    label: "Seleccione Proveedor",
  });
  const [proyectoSelect, setProyectoSelect] = useState({
    value: "Seleccione Proyecto",
    label: "Seleccione Proyecto",
  });
  const [articuloSelect, setArticuloSelect] = useState({
    value: "Seleccione Artículo",
    label: "Seleccione Artículo",
  });
  const [bodegaSelect, setBodegaSelect] = useState({
    value: "Seleccione Bodega",
    label: "Seleccione Bodega",
  });


  //************Funciones**************
  useEffect(() => {
    changeTitle("Admin. Moventrada");
    isMobile(setMobile)
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    setListaInventarioActual(inventario);
    validarArticulos()
    cargarConsecutivos(consecInventario, setlistaConsecutivosInv);
    cargarConsecutivos(consecCompra, setlistaConsecutivosCom);
    cargarProveedores()
    cargarBodegas();
    cargarProyectos();
    traerEmpresa(emp, setDataEmpresa);
    // console.log(inventario);
    // console.log("INV: ", inventario.filter(a => a.idArticulo === '' || a.idArticulo === undefined));
    // console.log(articulos);
    // console.log("ART: ",articulos.filter(a => a.id === '' || a.id === undefined));
  };

  useEffect(() => {
    setListaInventarioActual(inventario);
  }, [inventario]);

  useEffect(() => {
    validarArticulos()
  }, [articulos]);

  const validarArticulos = () => {
    let temporal
    if (articulos.length === 0) {
      temporal = [{ id: 0 }];
      setSinArticulos(true);
    } else {
      setSinArticulos(false);
      temporal = articulos;
    }
    setListaArticulos(temporal);
  };

  const clearInputFile = () => {
    inputFile.current.value = "";
  };

  //-----------Varios-------------
  const obtenerFechaActual = () => {
    const currentDate = new Date();
    const offset = -5 * 60; // Offset en minutos (UTC-5)
    const today = new Date(currentDate.getTime() + offset * 60 * 1000)
      .toISOString()
      .split("T")[0];
    return today;
  };

  const limpiarRadioBtn = () => {
    const radioButtons = document.getElementsByName("opcionSelecBoP");
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
  };

  const cargarSelect = () => {
    setListaVerPDF([]);
    setGuardo(false);
    setImagenSeleccionada(null);
  };

  //------------Consecutivos---------------
  const cargarConsecutivos = async (prefijo, setLista) => {
    setIsLoading(true);
    try {
      const listaTemporal = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", prefijo);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setLista(activos);
      // setLista([]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const manipularConsecutivos = (
    lista,
    setConsecutivo,
    setPrefijo,
    setIdDoc,
    setVerConsec
  ) => {
    const valorCon = lista[0]?.valorActual;
    const prefCom = lista[0]?.prefijo;
    const docCom = lista[0]?.idDocumento;
    const valCom = parseInt(valorCon + 1);
    setConsecutivo(valCom);
    setPrefijo(prefCom);
    setIdDoc(docCom);
    setVerConsec(prefCom + "-" + valCom);
  };

  const handleModificarConsecutivos = async (valor, idDoc, manipular) => {
    const listaModificacion = {
      valorActual: parseInt(valor.split("-")[1]),
      fecha_modificacion,
      usuario_modificacion: user,
    };
    try {
      await actualizarDocumentoDatabase(
        `${emp}_consecutivos`,
        idDoc,
        listaModificacion
      );
    } catch (error) {
      console.error(error);
    }
    if (manipular === "compra") {
      await cargarConsecutivos(consecCompra, setlistaConsecutivosCom);
      manipularConsecutivos(
        listaConsecutivosCom,
        setValorConsecutivoCom,
        setValorPrefijoCom,
        setIdDocumentoConsecutivoCom,
        setVerConsecutivoCom
      );
    } else if (manipular === "inventario") {
      await cargarConsecutivos(consecInventario, setlistaConsecutivosInv);
      manipularConsecutivos(
        listaConsecutivosInv,
        setValorConsecutivo,
        setValorPrefijo,
        setIdDocumentoConsecutivo,
        setVerConsecutivo
      );
    }
  };

  useEffect(() => {
    manipularConsecutivos(
      listaConsecutivosCom,
      setValorConsecutivoCom,
      setValorPrefijoCom,
      setIdDocumentoConsecutivoCom,
      setVerConsecutivoCom
    );
  }, [listaConsecutivosCom]);

  useEffect(() => {
    manipularConsecutivos(
      listaConsecutivosInv,
      setValorConsecutivo,
      setValorPrefijo,
      setIdDocumentoConsecutivo,
      setVerConsecutivo
    );
  }, [listaConsecutivosInv]);

  useEffect(() => {
    if (listaArticulos.length > 0 && listaArticulos[0].id !== 0) {
      let cantidadesDisponibles = {}, total;
      for (let i = 0; i < listaInventarioActual.length; i++) {
        let consecutivo = listaInventarioActual[i].articulo;
        if (listaInventarioActual[i].hasOwnProperty("cant_disponible")) {
          if (!cantidadesDisponibles.hasOwnProperty(consecutivo)) {
            cantidadesDisponibles[consecutivo] = 0;
          }
          cantidadesDisponibles[consecutivo] += listaInventarioActual[i].cant_disponible;
        }
      }
      const options = listaArticulos.map((a) => {
        let cantDis = parseFloat(cantidadesDisponibles.hasOwnProperty(a.consecutivo) ? cantidadesDisponibles[a.consecutivo] : 0);
        let um = a.um;
        let est = a.estado;
        total = um === "UND" ? (cantDis).toFixed(0) : (cantDis).toFixed(2);
        return { value: a.consecutivo, label: a.consecutivo + ": " + a.des_Larga + ` (Cant: ${total}-${um}, Est: ${est})` };
      });
      setArticulosL(options?.sort(sortBy("label")));
    }
  }, [listaArticulos, listaInventarioActual, opcionSeleccionada]);

  //--------Bodegas-----------
  const cargarBodegas = async () => {
    try {
      const listaTemporal = await consultarDatabase(`${emp}_bodegas`);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setListaBodegas(activos);
      // setListaBodegas([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (listaBodegas.length > 0 && listaBodegas[0].id !== 0) {
      const options = listaBodegas.map((a) => ({ value: a.consecutivo, label: a.descripcion + ": (" + a.consecutivo + ")" }));
      setBodegas(options?.sort(sortBy("label")));
    }
  }, [listaBodegas]);

  useEffect(() => {
    const listaUbicacionesTemporal = listaBodegas.find((b) => b.consecutivo === bodega)?.listaUbicaciones;
    const options = listaUbicacionesTemporal?.map((a) => ({ value: a.ubicacion, label: a.ubicacion, }));
    setUbicaciones(options?.sort(sortBy("value")));
  }, [bodega]);

  //-------Proyectos--------------
  const cargarProyectos = async () => {
    try {
      const listaTemporal = await consultarDatabase(`${emp}_proyectos`);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setListaProyectos(activos);
      // setListaProyectos([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (listaProyectos.length > 0 && listaProyectos[0].id !== 0) {
      const options = listaProyectos.map((a) => ({ value: a.consecutivo, label: a.descripcion + ": (" + a.consecutivo + ")" }));
      setProyectos(options?.sort(sortBy("label")));
    }
  }, [listaProyectos]);

  //-----------Proveedores--------------
  const cargarProveedores = async () => {
    const listaTemporal = terceros
    if (listaTemporal.length === 0) {
      setSinProveedores(true);
    } else {
      const activos = listaTemporal.filter((a) => a.estado === "ACTIVO" && a.tipo === "PROVEEDOR");
      if (activos.length === 0) {
        setSinProveedores(true);
      } else {
        setSinProveedores(false);
        setListaProveedores(activos);
      }
    }
  };

  useEffect(() => {
    cargarProveedores()
  }, [terceros]);

  useEffect(() => {
    if (listaProveedores.length > 0 && listaProveedores[0].id !== 0) {
      const options = listaProveedores.map((a) => ({
        value: a.razonSocial,
        label: a.razonSocial,
      }));
      setProveedores(options?.sort(sortBy("value")));
    }
  }, [listaProveedores]);

  useEffect(() => {
    const agrupado = [];
    listaInventarioActual.forEach((item) => {
      const { articulo, listaSeriales } = item;
      if (articulo && listaSeriales && Array.isArray(listaSeriales)) {
        if (!agrupado[articulo]) {
          agrupado[articulo] = {
            series: [],
          };
        }
        agrupado[articulo].series.push(...listaSeriales);
      }
    });
    const series = Object.values(agrupado).flatMap((obj) => obj.series);
    setListaArticulosSeries(series);
  }, [listaInventarioActual]);

  //-----------Seriales--------------------
  const validarSeriales = () => {
    let k = 0;
    listaSeriales.forEach((serial) => {
      serial !== "" && k++;
    });
    if (k === cantidad) {
      toast.success("Puede continuar con el registro");
      setValidarArtySeries(true);
    } else {
      setValidarArtySeries(false);
      toast.warning(
        "La cantidad de seriales no coincide con la cantidad de articulos, por favor verifica e intenta nuevamente."
      );
    }
  };

  const handleSerialChange = (index, value) => {
    const newSeriales = [...listaSeriales];
    newSeriales[index] = value;
    setListaSeriales([...newSeriales]);
  };

  const manejarCantidad = (cantSer) => {
    if (cantSer) {
      const listaTempSeriales = [];
      for (let index = 0; index < (cantSer <= listaSeriales.length ? cantSer : listaSeriales.length); index++) {
        const element = listaSeriales[index];
        listaTempSeriales.push(element);
      }
      setListaSeriales(listaTempSeriales);
    }
  };

  const obtenerListaSeriales = (lista, articulo, fecha) => {
    let inventario = [];
    if (lista === listaCompras) {
      inventario = lista?.find(
        (item) => item.articulo === articulo && item.fecha_compra === fecha
      );
    } else {
      inventario = lista?.find(
        (item) => item.articulo === articulo && item.fecha_carga === fecha
      );
    }
    if (inventario) {
      return inventario.listaSeriales;
    } else {
      toast.warning("Articulo no encontrado");
      return [];
    }
  };

  useEffect(() => {
    const agrupado = [];
    listaInventarios.forEach((item) => {
      const { articulo, listaSeriales } = item;
      if (articulo && listaSeriales && Array.isArray(listaSeriales)) {
        if (!agrupado[articulo]) {
          agrupado[articulo] = {
            series: [],
          };
        }
        agrupado[articulo].series.push(...listaSeriales);
      }
    });
    const series = Object.values(agrupado).flatMap((obj) => obj.series);
    setListaSerialesTemp(series);
  }, [listaInventarios, listaCompras]);

  //--------------Tablas---------------------------
  const eliminarArticulo = (lista, art, fec, tipfec, setLista) => {
    toast.error(
      <div className="text-center">
        ¿Estás seguro de eliminar el artículo "{art}"?
        <div className="row mt-3 mb-2">
          <div className="col">
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                if (
                  tipfec === fecha_carga &&
                  setLista === setListaInventarios
                ) {
                  const resultado = lista.filter(
                    (item) =>
                      !(item.articulo === art && item.fecha_carga === fec)
                  );
                  setListaInventarios(resultado);
                  toast.success(
                    "El artículo fue eliminado de la tabla con exito"
                  );
                } else if (
                  tipfec === fecha_compra &&
                  setLista === setListaCompras
                ) {
                  const resultado = lista.filter(
                    (item) =>
                      !(item.articulo === art && item.fecha_compra === fec)
                  );
                  setListaCompras(resultado);
                  toast.success(
                    "El artículo fue eliminado de la tabla con exito"
                  );
                }
              }}
            >
              Aceptar
            </button>
          </div>
          <div className="col">
            <button className="btn btn-outline-info" onClick={toast.dismiss}>
              Cancelar
            </button>
          </div>
        </div>
      </div>,
      { autoClose: false }
    );
  };

  const pasarAtablas = (pasar) => {
    let elementosComunes1 = listaSeriales.filter((item) =>
      listaSerialesTemp.includes(item)
    );

    if (usaSerial && listaSeriales.length === 0) {
      toast.warning("Debe ingresar las series para añadir a la tabla");
    } else {
      if (elementosComunes1.length === 0) {
        let elementosComunes2 = listaSeriales.filter((item) =>
          listaArticulosSeries.includes(item)
        );

        if (elementosComunes2.length === 0) {
          if (opcionSelecBoP === "bodega") {
            if (pasar === "compra") {
              let itemCom;
              if (imagenSeleccionada) {
                itemCom = {
                  factura,
                  imgFactura: imagenSeleccionada.name,
                  consecutivo: consecArt,
                  idArticulo: idArt,
                  fecha_compra: fecha_compra,
                  fecha_carga: fecha_carga,
                  proveedor,
                  articulo,
                  cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                  bodega,
                  ubicacion,
                  usaserial: usaSerial,
                  valor: parseFloat(valor),
                  observacion,
                  listaSeriales,
                };
              } else {
                itemCom = {
                  factura,
                  consecutivo: consecArt,
                  idArticulo: idArt,
                  fecha_compra: fecha_compra,
                  fecha_carga: fecha_carga,
                  proveedor,
                  articulo,
                  cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                  bodega,
                  ubicacion,
                  usaserial: usaSerial,
                  valor: parseFloat(valor),
                  observacion,
                  listaSeriales,
                };
              }
              if (
                fecha_compra === "" ||
                proveedor === "" ||
                articulo === "" ||
                cantidad === 0 ||
                bodega === "" ||
                ubicacion === "" ||
                valor === "" ||
                isNaN(valor)
              ) {
                toast.warning("Todos los campos deben ser diligenciados");
              } else {
                añadirAtabla(itemCom, setListaCompras);
                toast.success("Articulo añadido a la tabla");
                limpiarPasarTablas();
              }
            } else if (pasar === "inventario") {
              const itemInv = {
                fecha_carga,
                idArticulo: idArt,
                consecutivo: consecArt,
                articulo,
                cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                bodega,
                ubicacion,
                usaserial: usaSerial,
                valor: parseFloat(valor),
                observacion,
                listaSeriales,
              };
              if (
                fecha_carga === "" ||
                articulo === "" ||
                cantidad === 0 ||
                bodega === "" ||
                ubicacion === "" ||
                isNaN(valor)
              ) {
                toast.warning("Todos los campos deben ser diligenciados");
              } else {
                añadirAtabla(itemInv, setListaInventarios);
                toast.success("Articulo añadido a la tabla");
                limpiarPasarTablas();
              }
            }
          } else if (opcionSelecBoP === "proyecto") {
            if (pasar === "compra") {
              let itemCom;
              if (imagenSeleccionada) {
                itemCom = {
                  idArticulo: idArt,
                  factura,
                  imgFactura: imagenSeleccionada.name,
                  consecutivo: consecArt,
                  fecha_compra,
                  fecha_carga,
                  proveedor,
                  articulo,
                  cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                  proyecto,
                  usaserial: usaSerial,
                  valor: parseFloat(valor),
                  observacion,
                  listaSeriales,
                };
              } else {
                itemCom = {
                  idArticulo: idArt,
                  factura,
                  consecutivo: consecArt,
                  fecha_compra,
                  fecha_carga,
                  proveedor,
                  articulo,
                  cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                  proyecto,
                  usaserial: usaSerial,
                  valor: parseFloat(valor),
                  observacion,
                  listaSeriales,
                };
              }
              if (
                fecha_compra === "" ||
                proveedor === "" ||
                articulo === "" ||
                cantidad === 0 ||
                proyecto === "" ||
                valor === "" ||
                isNaN(valor)
              ) {
                toast.warning("Todos los campos deben ser diligenciados");
              } else {
                añadirAtabla(itemCom, setListaCompras);
                toast.success("Articulo añadido a la tabla");
                limpiarPasarTablas();
              }
            } else if (pasar === "inventario") {
              const itemInv = {
                idArticulo: idArt,
                fecha_carga,
                consecutivo: consecArt,
                articulo,
                cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
                proyecto,
                usaserial: usaSerial,
                valor: parseFloat(valor),
                observacion,
                listaSeriales,
              };
              if (
                fecha_carga === "" ||
                articulo === "" ||
                cantidad === 0 ||
                proyecto === "" ||
                valor === "" ||
                isNaN(valor)
              ) {
                toast.warning("Todos los campos deben ser diligenciados");
              } else {
                añadirAtabla(itemInv, setListaInventarios);
                toast.success("Articulo añadido a la tabla");
                limpiarPasarTablas();
              }
            }
          } else {
            toast.warning(
              "Debe seleccionar una bodega o proyecto para continuar"
            );
          }
        } else {
          toast.warning(
            `Las series: ${elementosComunes2.join(
              ", "
            )} , están duplicadas, verifica por favor`
          );
          elementosComunes2 = [];
        }
      } else {
        toast.warning(
          `Las series: ${elementosComunes1.join(
            ", "
          )} , están duplicadas, verifica por favor`
        );
        elementosComunes1 = [];
      }
    }
  };

  const añadirAtabla = (item, setLista) => {
    setLista((listaProv) => [...listaProv, item]);
  };

  const manipularCabecerasTabla = (lista) => {
    let cP = 0;
    let cB = 0;
    lista.forEach((etb) => {
      if (etb.proyecto !== "" && etb.proyecto !== undefined) {
        cP = cP + 1;
      }
      if (etb.bodega !== "" && etb.bodega !== undefined) {
        cB = cB + 1;
      }
    });
    setContBodega(cB);
    setContProyecto(cP);
  };

  useEffect(() => {
    manipularCabecerasTabla(listaInventarios);
  }, [listaInventarios]);

  useEffect(() => {
    manipularCabecerasTabla(listaCompras);
  }, [listaCompras]);

  const limpiarPasarTablas = () => {
    !proyecto &&
      setProyectoSelect({
        value: "Seleccione Proyecto",
        label: "Seleccione Proyecto",
      });
    !bodega &&
      setBodegaSelect({
        value: "Seleccione Bodega",
        label: "Seleccione Bodega",
      });
    !proveedor &&
      setProveedorSelect({
        value: "Seleccione Proveedor",
        label: "Seleccione Proveedor",
      });
    setArticuloSelect({
      value: "Seleccione Artículo",
      label: "Seleccione Artículo",
    });
    setUbicacionSelect({
      value: "Seleccione Ubicacion",
      label: "Seleccione Ubicacion",
    });
    setCantidad(0);
    setValor(0);
    setArticulo("");
    setListaSeriales([]);
    setObservacion("");
    limpiarRadioBtn();
    setValidarArtySeries(false);
    setVerSeriales(false);
    clearInputFile();
  };

  //--------CRUD Inv // Comp------------
  const subirInventario = async () => {
    if (listaInventarios.length === 0) {
      toast.warning("No hay inventario para guardar, ingrese un artículo e intente de nuevo");
      return;
    }
    if (window.confirm("Esta seguro de continuar con el cargue a inventario?")) {
      const consec = valorPrefijo.toUpperCase() + "-" + valorConsecutivo;
      let k = 0;
      let resp;
      for (const li of listaInventarios) {
        let esBodega = li.bodega;
        let esProyecto = li.proyecto;
        const articuloUsaSerial = listaArticulos?.find((t) => t.consecutivo === li.consecutivo);
        const conSerial = articuloUsaSerial?.serial;
        let ultCosto = parseFloat(li?.valor);
        let artModf = articuloUsaSerial?.consecutivo;
        const documento = esBodega ? esBodega.toLowerCase() : "proyectos";

        if (esBodega !== undefined) {
          setIsLoading(true)
          try {
            const existeB = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === li.bodega && t.consecutivo.split("\\")[1] === li.ubicacion && t.consecutivo.split("\\")[2] === li.consecutivo);
            if (existeB !== undefined) {
              let listaFusion = conSerial ? [...li?.listaSeriales, ...existeB?.listaSeriales] : [];
              resp = await modificarArticulo(existeB, existeB?.consecutivo, existeB?.cant_disponible, listaFusion, li?.cantidad, li?.valor, "Bodega", conSerial);
            } else {
              const enviarInventario = crearDataInv(li, undefined, li.bodega, li.ubicacion, "b");
              const enviar = {
                [enviarInventario.bodega + "\\" + enviarInventario.ubicacion + "\\" + li.consecutivo]: enviarInventario,
              };
              resp = await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
          }
        }

        if (esProyecto !== undefined) {
          setIsLoading(true)
          try {
            const existeP = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === li.proyecto && t.consecutivo.split("\\")[1] === li.consecutivo);

            if (existeP !== undefined) {
              const listaFusion = conSerial ? [...li?.listaSeriales, ...existeP?.listaSeriales] : [];
              resp = await modificarArticulo(existeP, existeP?.consecutivo, existeP?.cant_reservada, listaFusion, li?.cantidad, li?.valor, "Proyecto", conSerial);
            } else {
              const enviarInventario = crearDataInv(li, li.proyecto, undefined, undefined, "p");
              const enviar = {
                [enviarInventario.proyecto + "\\" + li.consecutivo]: enviarInventario,
              };
              resp = await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
          }
        }

        if (resp) {
          setIsLoading(true)
          try {
            let r = await modificarUltCosto(artModf, ultCosto);
            if (r) {
              k++;
              if (k === listaInventarios.length) {
                await cargarInventarioEnKardex(listaInventarios, valorPrefijo, valorConsecutivo);
                await handleModificarConsecutivos(consec, idDocumentoConsecutivo, "inventario");
                resetStates();
                toast.success("La carga del inventario se ha realizado con éxito");
              }
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        } else {
          toast.warning("Hubo un error al cargar el inventario");
        }
      }
    } else {
      return;
    }
  };

  const subirCompra = async () => {
    if (listaCompras.length === 0) {
      toast.warning("No hay compras para guardar, ingrese un artículo e intente de nuevo");
      return;
    }
    if (window.confirm("Esta seguro de continuar con el ingreso a inventario por compra?")) {
      const consec = valorPrefijoCom.toUpperCase() + "-" + valorConsecutivoCom;
      let k = 0, resp, imgf;
      const tieneImg = listaCompras.find((objeto) => objeto.img && objeto.imgFactura);
      imgf = tieneImg?.imgFactura;
      for (const lc of listaCompras) {
        let esBodega = lc.bodega;
        let esProyecto = lc.proyecto;
        const articuloUsaSerial = listaArticulos?.find((t) => t.consecutivo === lc.consecutivo);
        const precioOld = parseFloat(articuloUsaSerial.ult_Costo);
        const conSerial = articuloUsaSerial?.serial;
        let artModf = articuloUsaSerial?.consecutivo;
        let ultCosto = parseFloat(lc?.valor);
        const documento = esBodega ? esBodega.toLowerCase() : "proyectos";

        if (esBodega !== undefined) {
          setIsLoading(true)
          try {
            const existeB = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lc.bodega && t.consecutivo.split("\\")[1] === lc.ubicacion && t.consecutivo.split("\\")[2] === lc.consecutivo);

            if (existeB !== undefined) {
              const listaFusion = conSerial ? [...lc?.listaSeriales, ...existeB?.listaSeriales] : [];
              resp = await modificarArticulo(existeB, existeB?.consecutivo, existeB?.cant_disponible, listaFusion, lc?.cantidad, lc?.valor, "Bodega", conSerial);
            } else {
              const enviarInventario = crearDataInv(lc, undefined, lc.bodega, lc.ubicacion, "b");
              const enviar = { [enviarInventario.bodega + "\\" + enviarInventario.ubicacion + "\\" + lc.consecutivo]: enviarInventario, };
              resp = await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
          }
        }

        if (esProyecto !== undefined) {
          setIsLoading(true)
          try {
            const existeP = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lc.proyecto && t.consecutivo.split("\\")[1] === lc.consecutivo);

            if (existeP !== undefined) {
              const listaFusion = conSerial ? [...lc?.listaSeriales, ...existeP?.listaSeriales] : [];
              resp = await modificarArticulo(existeP, existeP?.consecutivo, existeP?.cant_reservada, listaFusion, lc?.cantidad, lc?.valor, "Proyecto", conSerial);
            } else {

              const enviarInventario = crearDataInv(lc, lc.proyecto, undefined, undefined, "p");
              const enviar = { [enviarInventario.proyecto + "\\" + lc.consecutivo]: enviarInventario, };
              resp = await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
          }
        }

        if (precioOld !== ultCosto) {
          await modificarUltCosto(artModf, ultCosto);
        }
        if (resp) {
          setIsLoading(true)
          try {
            k++;
            if (k === listaCompras.length) {
              await cargarInventarioEnKardex(listaCompras, valorPrefijoCom, valorConsecutivoCom);
              await handleModificarConsecutivos(consec, idDocumentoConsecutivoCom, "compra");
              if (imgf) { await handleSubirImagen() }
              resetStates();
              toast.success("La carga del inventario se ha realizado con éxito");
            }

          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false);
          }
        }
      }
    } else {
      return;
    }
  };

  const crearDataInv = (l, proyecto, bodega, ubicacion, tipo) => {
    let cant = Number(Number.parseFloat(l.cantidad).toFixed(2));
    let lista = {};
    let listaP = {
      id: uniqid("inventario-"),
      idArticulo: idArt,
      articulo: l.consecutivo.toUpperCase(),
      fecha_carga: fecha_creacion,
      ...(l.usaserial && { listaSeriales: l.listaSeriales }),
      observacion: l.observacion.toUpperCase(),
      valor: parseFloat(l.valor),
      usuario_creacion: user,
      fecha_creacion: fecha_creacion,
    };

    if (tipo === "b") {
      lista = {
        ...listaP,
        bodega: bodega.toUpperCase(),
        ubicacion: ubicacion.toUpperCase(),
        cant_disponible: cant/*{parseFloat(l.cantidad),*/
      };
    } else if (tipo === "p") {
      lista = {
        ...listaP,
        proyecto: proyecto.toUpperCase(),
        cant_reservada: cant,
      };
    }
    return lista;
  };

  const modificarUltCosto = async (art, valor) => {
    let array = [...listaArticulos]
    let articuloModificar = array.find((t) => t.consecutivo === art)
    articuloModificar = {
      ...articuloModificar,
      ult_Costo: parseFloat(valor),
      usuario_modificacion: user,
      fecha_modificacion: fecha_modificacion,
      ult_Compra: fecha_modificacion,
    };
    const prefijo = articuloModificar.consecutivo.split('-')[0].toLowerCase();
    if (art !== "" && art !== undefined) {
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_articulos`, `${emp}_articulos_${prefijo}`, art, articuloModificar);
        return true;
      } catch (error) {
        console.error(error);
      }
    } else {
      return false;
    }
  };

  const resetStates = () => {
    setProyectoSelect({
      value: "Seleccione Proyecto",
      label: "Seleccione Proyecto",
    });
    setBodegaSelect({ value: "Seleccione Bodega", label: "Seleccione Bodega" });
    setArticuloSelect({
      value: "Seleccione Artículo",
      label: "Seleccione Artículo",
    });
    setProveedorSelect({
      value: "Seleccione Proveedor",
      label: "Seleccione Proveedor",
    });
    setUbicacionSelect({
      value: "Seleccione Ubicacion",
      label: "Seleccione Ubicacion",
    });
    setListaInventarios([]);
    setListaSeriales([]);
    setListaCompras([]);
    setValidarArtySeries(false);
    setVerSeriales(false);
    setUsaSerial(false);
    setFecha_compra("");
    setObservacion("");
    setOpcionSelecBoP("");
    setFecha_carga("");
    setProveedor("");
    setArticulo("");
    setProyecto("");
    setFactura("");
    setBodega("");
    setCantidad(0);
    setValor(0);
    limpiarRadioBtn();
  };

  const modificarArticulo = async (lista, consec, cantidadBD, listaFusion, cantidad, valor, tipo, conSerie) => {
    let cant = parseFloat(cantidadBD) + parseFloat(cantidad);
    cant = Number(Number.parseFloat(cant).toFixed(2));
    const bdg = lista.bodega
    const documento = tipo === "Bodega" ? bdg.toLowerCase() : "proyectos";

    let listaEnviar = {
      ...lista,
      valor: parseFloat(valor),
      usuario_modificacion: user,
      fecha_modificacion: fecha_modificacion,
    };

    if (conSerie) {
      listaEnviar.listaSeriales = listaFusion;
    }

    if (tipo === "Bodega") {
      listaEnviar.cant_disponible = cant;
    }
    if (tipo === "Proyecto") {
      listaEnviar.cant_reservada = cant;
    }

    if (consec !== "" && consec !== undefined) {
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, consec, listaEnviar);
        return true;
      } catch (error) {
        console.error(error);
      }
    } else {
      return false;
    }
  };

  const cargarInventarioEnKardex = async (lista, valPref, valConsec) => {
    const consecutivo = valPref.toUpperCase() + "-" + valConsec;
    const consec = valPref.toLowerCase();
    let tieneImg = lista.find((objeto) => objeto.imgFactura);
    let id = uniqid("kardex-");
    let cons = null, ima;

    if (lista.length !== 0) {
      for (const key in lista) {
        if (lista.hasOwnProperty(key)) {
          const objetoActual = lista[key];
          cons = objetoActual.consecutivo;
          if (tieneImg !== undefined) {
            ima = tieneImg.imgFactura;
            objetoActual.imgFactura = ima;
          }
          objetoActual.articulo = cons;
          objetoActual.imagen = rutaEnFirestore;
          objetoActual.id = id;
          objetoActual.usuario_creacion = user;
          objetoActual.fecha_creacion = fecha_creacion;
          objetoActual.fecha_carga = fecha_creacion;
          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
          delete objetoActual.imgFactura;
        }
      }
      try {
        const enviar = { [consecutivo]: lista };
        await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${consec}`, enviar);
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.warning(
        "Error, no hay informacion para guardar, por favor verifica e inetenta de nuevo"
      );
    }
  };

  const articulosNuevos = (listaFusionada) => {
    setListaArticulos(listaFusionada);
  };

  const manejarRespuestaCarga = (respuesta) => {
    if (respuesta === "Cancel") {
      setSelectTipoCarga("");
    } else {
      setRespuestaCarga(respuesta);
    }
  };

  const manejarRespuestaDescarga = () => {
    toast.info("Descarga en proceso.");
    setSelectTipoDescarga("");
    setRespuestaDescarga("");
  };

  useEffect(() => {
    setSelectTipoCarga("");
    setVerConsecutivo(respuestaCarga);
  }, [respuestaCarga]);

  useEffect(() => {
    setSelectTipoDescarga("");
    setRespuestaDescarga("");
  }, [respuestaDescarga]);

  //------- CRUD imágenes ----------
  const handleImageSelect = (event) => {
    const image = event.target.files[0];
    if (image) {
      const maxSize = 204800;
      if (image.size > maxSize) {
        toast.warning('Imagen Supera el tamaño permitido')
        event.target.value = ''
      } else {
        setRutaEnFirestore(urlFacturas + "/" + factura);
        setImagenSeleccionada(image);
      }
    }
  };

  const handleSubirImagen = async () => {
    if (imagenSeleccionada && rutaEnFirestore) {
      setIsLoading(true)
      try {
        await subirImagen(rutaEnFirestore, imagenSeleccionada);
        toast.success("La imágen fue cargada correctamente")
        setImagenSeleccionada("")
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  };

  /* ************************************ */
  return (
    <>
      {isLoading ?
        <LoaderES />
        :
        <>
          <div
            className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : !collapsed ? "250px" : "80px",
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1,
            }}
          >
            {/* botones de opcion */}
            {opcionSeleccionada !== "cargar-inventario" ? null : (
              <div className="row ms-2 me-2">
                <div className="col-sm-4 align-self-start text-start">
                  <div className="btn-group">
                    {/* {permisos.includes("cargaMasivaInv") ? */}
                    {selectTipoCarga === "" ? (
                      <button
                        className="btn fa-solid fa-upload btn-sm"
                        // disabled={!permisos.includes("cargaMasivaInv")}
                        disabled
                        data-bs-toggle="modal"
                        data-bs-target="#modalMasivos"
                        title="Seleccionar destino de carga masiva"
                        onClick={() => {
                          setCargar(1);
                        }}
                      />
                    ) : (
                      <CarMasInventario
                        listaBod={listaBodegas}
                        listaProy={listaProyectos}
                        listaArt={listaArticulos}
                        listaInvAct={listaInventarioActual}
                        articulosNuevos={articulosNuevos}
                        tipo={selectTipoCarga}
                        onRespuesta={manejarRespuestaCarga}
                        consecutivo={verConsecutivo}
                      />
                    )
                    }
                    {/* {permisos.includes("plantExcelMasInv") ? */}
                    {selectTipoDescarga === "" && respuestaDescarga === "" ? (
                      <button
                        className="btn fa-solid fa-download btn-sm "
                        // disabled={!permisos.includes("plantExcelMasInv")}
                        disabled
                        data-bs-toggle="modal"
                        data-bs-target="#modalMasivos"
                        title="Seleccionar plantilla a descargar"
                        onClick={() => {
                          setCargar(0);
                          setRespuestaDescarga("");
                        }}
                      />
                    ) : (
                      <PlantillaExcel
                        origen={"Entrada"}
                        tipo={selectTipoDescarga}
                        onRespuesta={manejarRespuestaDescarga}
                      />
                    )
                    }
                  </div>
                </div>
              </div>
            )}

          </div>

          <hr className={"text-light mt-5 mb-4" + (mobile ? " mb-4" : "")} />

          {/* Menu header */}
          <ul className="nav nav-tabs mt-3">
            <li
              className="nav-item user-select-none"
              disabled={!permisos.includes("cargarInventario")}
              onClick={() => {
                setOpcionSeleccionada("cargar-inventario");
                limpiarPasarTablas();
                resetStates();
                cargarSelect();
              }} >
              <p className={"nav-link " + (opcionSeleccionada === "cargar-inventario" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }} >Cargar Inv.</p>
            </li>

            <li
              className="nav-item user-select-none"
              onClick={() => {
                setOpcionSeleccionada("cargar-compra");
                limpiarPasarTablas();
                resetStates();
                cargarSelect();
              }}>
              <p className={"nav-link " + (opcionSeleccionada !== "cargar-inventario" ? "active" : "")}
                disabled={!permisos.includes("cargarCompra")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Cargar Compra</p>
            </li>
          </ul>

          <hr className=" text-light mt-2 mb-0" />

          {/******* Cargar Inv.********/}
          {permisos.includes("cargarInventario") ?
            opcionSeleccionada === "cargar-inventario" && (
              <>
                <div className="row">
                  <div className="col text-center">
                    <h4 className="fs-4 mt-1">Cargar Inventario</h4>
                  </div>
                </div>

                {/* Consecutivo Inventario & fechca*/}
                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="input-group input-group-sm mb-2">
                      <span className="input-group-text">Consecutivo</span>
                      <input
                        type="text"
                        className={listaConsecutivosInv.length === 0 ? "form-control text-danger" : "form-control"}
                        readOnly
                        value={listaConsecutivosInv.length === 0 ? "Sin consecutivos disponibles" : verConsecutivo}
                      />
                    </div>
                  </div>

                  {/* Fecha */}
                  <div className="col-sm-6">
                    <div className="input-group input-group-sm mb-2">
                      <span className="input-group-text">Fecha</span>
                      <input
                        disabled={listaInventarios.length > 0}
                        type="date"
                        className="form-control"
                        value={fecha_carga || obtenerFechaActual()}
                        onChange={(e) => {
                          setFecha_carga(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {listaConsecutivosInv.length > 0 &&
                  <>
                    {/* Text input articulo Inventario */}
                    <div className="row">
                      {/* Articulo */}
                      <Select
                        value={sinArticulos ? { value: "No existen artículos", label: "No existen artículos", } : articuloSelect}
                        placeholder={"Articulo"}
                        onFocus={() => {
                          setGuardo(false);
                          setListaVerPDF([]);
                        }}
                        className="mb-2"
                        onChange={(option) => {
                          const art = listaArticulos.find(
                            (art) => art.consecutivo === option.value
                          );
                          setArticuloSelect({ value: art.consecutivo, label: art.des_Larga, });
                          setArticulo(art ? art.des_Larga : "");
                          setIdArt(art ? art.id : "");
                          setConsecArt(art ? art.consecutivo : "");
                          setUsaSerial(art ? art.serial : false);
                          setUm(art?.um);
                          setValor(!isNaN(art?.ult_Costo) ? art?.ult_Costo : 0);
                          setFecha_carga(obtenerFechaActual());
                          setGuardo(false);
                        }}
                        options={articulosL}
                        styles={styles}
                        isDisabled={sinArticulos}
                      />
                    </div>

                    {/* Cantidad & cargar series*/}
                    <div className="row mb-3">
                      {/* Cantidad */}
                      <div className="input-group input-group-sm">
                        <span className="input-group-text">Cantidad</span>
                        <input
                          type="number"
                          step={um === "UND" ? 1 : 0.1}
                          min={um === "UND" ? 1 : 0.1}
                          className="form-control"
                          disabled={articulo === ""}
                          onFocus={() => setCantidad("")}
                          value={cantidad}
                          onChange={(e) => {
                            setCantidad(parseFloat(e.target.value));
                            manejarCantidad(parseFloat(e.target.value));
                          }}
                        />

                        {usaSerial && articulo !== "" ? (
                          <button
                            className="btn btn-outline-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#modalSeriales"
                            title="Agregar seriales de los articulos"
                            disabled={cantidad === 0 || cantidad === ""}
                            onClick={() => {
                              setVerSeriales(false);
                            }}
                          >
                            Cargar Series
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </>}
                {/* Seleccion entre bodegas y proeyectos Inventario */}
                {cantidad === 0 || cantidad === "" || (usaSerial && listaSeriales.length === 0) || articulo === "" ? null : (
                  <>
                    <div className="row mb-3">
                      <div className="col-sm">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            name="opcionSelecBoP"
                            type="radio"
                            id="inlineBodegasI"
                            value="bodega"
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                            onClick={() => {
                              // cargarBodegas();
                              setProyecto("");
                            }}
                            disabled={listaInventarios.length > 0}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineBodegasI"
                          >
                            Bodegas
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input me-2"
                            name="opcionSelecBoP"
                            type="radio"
                            value="proyecto"
                            id="inlineProyectosI"
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                            onClick={() => {
                              // cargarProyectos();
                              setBodega("");
                            }}
                            disabled={listaInventarios.length > 0}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineProyectosI"
                          >
                            Proyectos
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Bodega-Proyecto-ubicacion */}
                {opcionSelecBoP === "" ? null : (
                  <>
                    <div className="row">
                      {/* input bodegas y proyectos */}
                      <div className="col-sm">
                        {opcionSelecBoP === "" || opcionSelecBoP === "bodega" ? (
                          <Select
                            value={listaBodegas.length > 0 ? bodegaSelect : {
                              value: "Sin Bodegas disponibles",
                              label: "Sin Bodegas disponibles",
                            }}
                            placeholder="Bodega"
                            className="mb-2"
                            onChange={(option) => {
                              const bod = listaBodegas.find(
                                (b) => b.consecutivo === option.value
                              );
                              setBodega(bod ? bod.consecutivo : "");
                              setBodegaSelect({
                                value: bod.consecutivo,
                                label: bod.consecutivo,
                              });
                            }}
                            options={bodegas}
                            styles={styles}
                            isDisabled={listaInventarios.length > 0}
                          />
                        ) : null}

                        {/* input proyectos */}
                        {opcionSelecBoP === "" || opcionSelecBoP === "proyecto" ? (
                          <Select
                            className="mb-2"
                            value={listaProyectos.length > 0 ? proyectoSelect : {
                              value: "Sin Proyectos disponibles",
                              label: "Sin Proyectos disponibles",
                            }
                            }
                            placeholder={"Proyecto"}
                            onChange={(option) => {
                              const proy = listaProyectos.find(
                                (p) => p.consecutivo === option.value
                              );
                              setProyecto(proy ? proy.consecutivo : "");
                              setProyectoSelect({
                                value: proy.consecutivo,
                                label: proy.consecutivo,
                              });
                            }}
                            options={proyectos}
                            styles={styles}
                            disabled={listaInventarios.length > 0}
                          />
                        ) : null}
                      </div>

                      {/* input ubicacion */}
                      {opcionSelecBoP === "" || opcionSelecBoP === "bodega" ? (
                        <div className="col-sm">
                          <Select
                            value={ubicacionSelect}
                            placeholder={"Ubicación"}
                            className="mb-2"
                            onChange={(e) => {
                              setUbicacion(e.value);
                              setUbicacionSelect({
                                value: e.value,
                                label: e.value,
                              });
                            }}
                            options={ubicaciones}
                            styles={styles}
                          />
                        </div>
                      ) : null}
                    </div>

                    {/* Text inputs  valor y observacion Inventario */}
                    <div className="row">
                      {/* valor */}
                      <div className="col-sm-4">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Valor</span>
                          <input
                            type="number"
                            className="form-control"
                            value={valor}
                            min={0}
                            onChange={(e) => setValor(parseFloat(e.target.value))}
                          />
                          <span className="input-group-text">$</span>
                        </div>
                      </div>

                      {/* Observacion */}
                      <div className="col-sm-8">
                        <textarea
                          placeholder="Observaciones"
                          rows={1}
                          className="form-control form-control-sm mb-3"
                          value={observacion}
                          onChange={(e) => setObservacion(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Boton añadir a tabla Inventario */}
                    {!validarArtySeries || usaSerial ? (
                      <button
                        className="btn btn-dark  mb-4"
                        onClick={() => pasarAtablas("inventario")}
                      >
                        Añadir Artículo
                      </button>
                    ) : null}
                  </>
                )}
                {listaInventarios.length > 0 ? <hr /> : null}

                {listaInventarios.length === 0 ? null : (
                  <>
                    {/* +Tabla de artículos */}
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-sm">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Articulo</th>
                            <th scope="col">Cantidad</th>
                            {contBodega === 0 ? null : (
                              <>
                                <th scope="col">Bodega</th>
                                <th scope="col">Ubicacion</th>
                              </>
                            )}
                            {contProyecto === 0 ? null : (
                              <th scope="col">Proyecto</th>
                            )}
                            <th scope="col">Valor</th>
                            <th scope="col">Observacion</th>
                            <th scope="col">Opciones</th>
                          </tr>
                        </thead>
                        <tbody className="text-center" id="tabla_articulos">
                          {listaInventarios.map((art, index) => (
                            <tr key={index}>
                              <td>{art.fecha_carga}</td>
                              <td>{art.articulo}</td>
                              <td>{art.cantidad}</td>
                              {contBodega === 0 ? null : (
                                <>
                                  <td>{art.bodega}</td>
                                  <td>{art.ubicacion}</td>
                                </>
                              )}
                              {contProyecto === 0 ? null : (
                                <td>{art.proyecto}</td>
                              )}
                              <td>
                                ${Intl.NumberFormat("es-CO").format(art.valor)}
                              </td>
                              <td>{art.observacion}</td>

                              <td>
                                {art.usaserial ? (
                                  <button
                                    className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalSeriales"
                                    onClick={() => {
                                      setVerSeriales(true);
                                      setVerListaSeriales(
                                        obtenerListaSeriales(
                                          listaInventarios,
                                          art.articulo,
                                          art.fecha_carga
                                        )
                                      );
                                    }}
                                  />
                                ) : null}

                                <button
                                  className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                  onClick={() =>
                                    eliminarArticulo(
                                      listaInventarios,
                                      art.articulo,
                                      art.fecha_carga,
                                      fecha_carga,
                                      setListaInventarios
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Boton Cargar Inventario */}

                    <div className="row mt-4 mb-3">
                      <div className="container">
                        <button
                          className="btn btn-success"
                          title="Cargar Inventario"
                          onClick={() => {
                            subirInventario();
                            setConsecMov(verConsecutivo);
                            setGuardo(true);
                          }}
                        >
                          Cargar Inventario
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* {guardo ? (
                  <div className="text-center">
                    <NavLink
                      to={{ pathname: `/docpdf` }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="btn btn-outline-primary  mt-3 fa-solid fa-print "
                        title="Ver Reporte"
                        onClick={() => {
                          setGuardo(false);
                          localStorage.setItem('myData', JSON.stringify({
                            c: verConsecutivo, d: listaVerPDF,
                            a: "Inventario", e: dataEmpresa
                          }));
                        }}
                      />
                    </NavLink>
                  </div>
                ) : null} */}
              </>
            ) : null}

          {/******** Cragar Compra ******* */}
          {permisos.includes("cargarCompra") ?
            opcionSeleccionada === "cargar-compra" && (
              <>
                <div className="conatiner">
                  <div className="row text-center mb-3">
                    <h4 className="fs-4 mt-1">Cargar Compra</h4>
                  </div>

                  {/* Consecutivo Compra y Fecha*/}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-group input-group-sm mb-2 ">
                        <span className="input-group-text">Consecutivo</span>
                        <input
                          type="text"
                          className={listaConsecutivosCom.length === 0 ? "form-control text-danger" : "form-control"}
                          readOnly
                          value={listaConsecutivosCom.length === 0 ? "Sin consecutivos disponibles" : verConsecutivoCom}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 text-end">
                      <div className="input-group input-group-sm mb-2 ">
                        <span className="input-group-text">Fecha Carga</span>
                        <input
                          type="date"
                          className="form-control"
                          readOnly
                          value={fecha_carga || obtenerFechaActual()}
                          onChange={(e) => setFecha_carga(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {listaConsecutivosCom.length > 0 &&
                    <>
                      {/* Text inputs N° factura y  fecha  Compra */}
                      <div className="row">
                        {/* N° factura Compra */}
                        <div className="col-sm-6">
                          <div className="input-group input-group-sm mb-2 ">
                            <span className="input-group-text">Factura</span>
                            <input
                              type="text"
                              className="form-control"
                              disabled={
                                sinProveedores ||
                                sinArticulos ||
                                listaCompras.length > 0
                              }
                              value={factura}
                              onFocus={() => {
                                setGuardo(false);
                                setListaVerPDF([]);
                              }}
                              onChange={(e) => setFactura(e.target.value)}
                            />
                            {!imagenSeleccionada && (
                              <span
                                className="input-group-text px-4 fa-solid fa-paperclip"
                                title="Cargar Factura"
                                data-bs-toggle="modal"
                                data-bs-target="#modalImgFactura"
                              />
                            )}
                          </div>
                        </div>

                        {/* Fecha Compra */}
                        <div className="col-sm-6">
                          <div className="input-group input-group-sm mb-2 ">
                            <span className="input-group-text">Fecha Compra</span>
                            <input
                              type="date"
                              className="form-control"
                              disabled={
                                sinProveedores ||
                                sinArticulos ||
                                !factura ||
                                listaCompras.length > 0
                              }
                              value={fecha_compra}
                              onChange={(e) => {
                                setFecha_compra(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/*  Text inputs proveedor y  artículo  Compra */}
                      <div className="row">
                        {/*Proveedor Compra*/}
                        <div className="col-sm-6">
                          <Select
                            value={
                              sinProveedores
                                ? {
                                  value: "Sin proveedores disponibles",
                                  label: "Sin proveedores disponibles",
                                }
                                : proveedorSelect
                            }
                            placeholder={"Proveedor"}
                            className="mb-2"
                            onChange={(e) => {
                              const prov = listaProveedores.find((p) => p.razonSocial === e.value);
                              setProveedor(prov ? prov.razonSocial : "");
                              setProveedorSelect({ value: prov.razonSocial, label: prov.razonSocial });
                              // setImagen(rutaEnFirestore)
                            }}
                            options={proveedores}
                            styles={styles}
                            isDisabled={
                              !fecha_compra ||
                              sinProveedores ||
                              listaCompras.length > 0
                            }
                          />
                        </div>

                        {/* Articulo Compra*/}
                        <div className="col-sm-6">
                          <Select
                            value={
                              sinArticulos
                                ? {
                                  value: "Sin artículos disponibles",
                                  label: "Sin artículos disponibles",
                                }
                                : articuloSelect
                            }
                            placeholder={"Articulo"}
                            className="mb-2"
                            onChange={(option) => {
                              const art = listaArticulos.find(
                                (art) => art.consecutivo === option.value
                              );
                              setArticuloSelect({
                                value: art.consecutivo,
                                label: art.des_Larga,
                              });
                              setArticulo(art ? art.des_Larga : "");
                              setIdArt(art ? art.id : "");
                              setConsecArt(art ? art.consecutivo : "");
                              setUsaSerial(art ? art.serial : false);
                              setUm(art?.um);
                              setValor(!isNaN(art?.ult_Costo) ? art?.ult_Costo : 0);
                              setFecha_carga(obtenerFechaActual());
                              setGuardo(false);
                            }}
                            options={articulosL}
                            styles={styles}
                            isDisabled={
                              sinArticulos || sinProveedores || !fecha_compra
                            }
                          />
                        </div>
                      </div>
                    </>}
                  {articulo !== "" ? (
                    <>
                      {/* Seleccion entre bodegas y proeyectos compra */}
                      <div className="row mb-3">
                        <div className="col-sm">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              name="opcionSelecBoP"
                              id="inlineBodegasC"
                              type="radio"
                              value="bodega"
                              onChange={(e) => setOpcionSelecBoP(e.target.value)}
                              onClick={() => {
                                // cargarBodegas();
                                setProyecto("");
                              }}
                              disabled={listaCompras.length > 0}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineBodegasC"
                            >
                              Bodegas
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              name="opcionSelecBoP"
                              id="inlineProyectosC"
                              type="radio"
                              value="proyecto"
                              onChange={(e) => setOpcionSelecBoP(e.target.value)}
                              onClick={() => {
                                // cargarProyectos();
                                setBodega("");
                              }}
                              disabled={listaCompras.length > 0}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineProyectosC"
                            >
                              Proyectos
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {opcionSelecBoP === "" ? null : (
                    <>
                      <div className="row">
                        {/* input bodegas */}
                        <div className="col-sm">
                          {opcionSelecBoP === "" ||
                            opcionSelecBoP === "bodega" ? (
                            <Select
                              value={listaBodegas.length > 0 ? bodegaSelect : {
                                value: "Sin Bodegas disponibles",
                                label: "Sin Bodegas disponibles",
                              }}
                              placeholder={"Bodega"}
                              className="mb-2"
                              onChange={(option) => {
                                const bod = listaBodegas.find(
                                  (b) => b.consecutivo === option.value
                                );
                                setBodega(bod ? bod.consecutivo : "");
                                setBodegaSelect({
                                  value: bod.consecutivo,
                                  label: bod.consecutivo,
                                });
                              }}
                              options={bodegas}
                              styles={styles}
                              isDisabled={listaCompras.length > 0}
                            />
                          ) : null}

                          {/* input proyectos */}
                          {opcionSelecBoP === "" ||
                            opcionSelecBoP === "proyecto" ? (
                            <Select
                              className="mb-2"
                              value={listaProyectos.length > 0 ? proyectoSelect : {
                                value: "Sin Proyectos disponibles",
                                label: "Sin Proyectos disponibles",
                              }}
                              placeholder={"Proyecto"}
                              onChange={(option) => {
                                const proy = listaProyectos.find(
                                  (p) => p.consecutivo === option.value
                                );
                                setProyecto(proy ? proy.consecutivo : "");
                                setProyectoSelect({
                                  value: proy.consecutivo,
                                  label: proy.consecutivo,
                                });
                              }}
                              options={proyectos}
                              styles={styles}
                              isDisabled={listaCompras.length > 0}
                            />
                          ) : null}
                        </div>

                        {/* input ubicacion */}
                        {opcionSelecBoP === "" || opcionSelecBoP === "bodega" ? (
                          <div className="col-sm">
                            <Select
                              value={ubicacionSelect}
                              placeholder={"Ubicación"}
                              className="mb-2"
                              onChange={(e) => {
                                setUbicacion(e.value);
                                setUbicacionSelect({
                                  value: e.value,
                                  label: e.value,
                                });
                              }}
                              options={ubicaciones}
                              styles={styles}
                            />
                          </div>
                        ) : null}
                      </div>

                      {/*  Text inputs cantidad y serie  Compra */}
                      {articulo !== "" ? (
                        <div className="row">
                          {/* Cantidad Compra*/}
                          <div className="input-group input-group-sm mb-2 ">
                            <span className="input-group-text">Cantidad</span>
                            <input
                              type="number"
                              min={um === "UND" ? 1 : 0.1}
                              step={um === "UND" ? 1 : 0.1}
                              className="form-control"
                              onFocus={() => setCantidad("")}
                              value={cantidad}
                              onChange={(e) => {
                                setCantidad(parseFloat(e.target.value));
                                manejarCantidad(parseFloat(e.target.value));
                              }}
                            />

                            {usaSerial && articulo !== "" ? (
                              <button
                                className="btn btn-outline-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#modalSeriales"
                                title="Agregar seriales de los articulos"
                                disabled={cantidad === 0 || cantidad === ""}
                                onClick={() => setVerSeriales(false)}
                              >
                                Cargar Series
                              </button>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {/* Inputs  valor y observacion Compra */}
                      <div className="row">
                        {/* Valor UND Compra */}
                        <div className="col-sm-4">
                          <div className="input-group input-group-sm mb-2 ">
                            <span className="input-group-text">Valor</span>
                            <input
                              type="number"
                              className="form-control"
                              value={valor}
                              min={0}
                              onChange={(e) => setValor(parseFloat(e.target.value))}
                            />
                            <span className="input-group-text">$</span>
                          </div>
                        </div>

                        {/* Observacion Compra*/}
                        <div className="col-sm-8">
                          <textarea
                            placeholder="Observaciones"
                            className="form-control form-control-sm mb-3"
                            rows={1}
                            value={observacion}
                            onChange={(e) => setObservacion(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* Boton añadir a tabla  Compra */}
                      {!validarArtySeries || usaSerial ? (
                        <button
                          className="btn btn-dark  mb-4"
                          onClick={() => pasarAtablas("compra")}
                        >
                          Añadir Compra
                        </button>
                      ) : null}
                    </>
                  )}
                </div>

                {listaInventarios.length > 0 && <hr />}

                {listaCompras.length === 0 ? null : (
                  <>
                    {/* +Tabla de artículos */}
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-sm">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">N° Factura</th>
                            <th scope="col">Fecha Compra</th>
                            <th scope="col">Proveedor</th>
                            <th scope="col">Articulo</th>
                            <th scope="col">Cantidad</th>
                            {contBodega === 0 ? null : (
                              <>
                                <th scope="col">Bodega</th>
                                <th scope="col">Ubicacion</th>
                              </>
                            )}
                            {contProyecto === 0 ? null : (
                              <th scope="col">Proyecto</th>
                            )}
                            <th scope="col">Valor</th>
                            <th scope="col">Observaciones</th>
                            <th scope="col">Opciones</th>
                          </tr>
                        </thead>
                        <tbody className="text-center" id="tabla_articulos">
                          {listaCompras.map((art, index) => (
                            <tr key={index}>
                              <td>{art.factura}</td>
                              <td>{art.fecha_compra}</td>
                              <td>{art.proveedor}</td>
                              <td>{art.articulo}</td>
                              <td>{art.cantidad}</td>
                              {contBodega === 0 ? null : (
                                <>
                                  <td>{art.bodega}</td>
                                  <td>{art.ubicacion}</td>
                                </>
                              )}
                              {contProyecto === 0 ? null : (
                                <td>{art.proyecto}</td>
                              )}
                              <td>
                                ${Intl.NumberFormat("es-CO").format(art.valor)}
                              </td>
                              <td>{art.observacion}</td>
                              <td>
                                {!art.usaserial ? null : (
                                  <button
                                    className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalSeriales"
                                    onClick={() => {
                                      setVerSeriales(true);
                                      setVerListaSeriales(
                                        obtenerListaSeriales(
                                          listaCompras,
                                          art.articulo,
                                          art.fecha_compra
                                        )
                                      );
                                    }}
                                  />
                                )}
                                <button
                                  className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                  onClick={() =>
                                    eliminarArticulo(
                                      listaCompras,
                                      art.articulo,
                                      art.fecha_compra,
                                      fecha_compra,
                                      setListaCompras
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Boton cargar compras */}
                    <div className="row mt-4 mb-3">
                      <div className="container">
                        <button
                          className="btn btn-success"
                          title="Crear Nuevo Artículo"
                          onClick={() => {
                            subirCompra();
                            setConsecMov(verConsecutivoCom);
                            setGuardo(true);
                          }}
                        >
                          Cargar Compras
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {/* {guardo ? (
                  <div className="text-center">
                    <NavLink
                      to={{pathname: `/docpdf` }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="btn btn-outline-primary mt-3 fa-solid fa-print "
                        title="Ver Reporte"
                        onClick={() => {
                          setGuardo(false);
                          localStorage.setItem('myData', JSON.stringify({
                            c: verConsecutivoCom, d: listaVerPDF,
                            a: "Compra", e: dataEmpresa
                          }));
                        }}
                      />
                    </NavLink>
                  </div>
                ) : null} */}
              </>
            ) : null}

          {/* Modal CRUD de imagen factura*/}
          <div
            className="modal fade"
            id="modalImgFactura"
            tabIndex="-1"
            aria-labelledby="modalImgFactura"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className={
                    imagenSeleccionada
                      ? "modal-header bg-primary text-light"
                      : "modal-header bg-info text-light"
                  }
                >
                  <h1 className="modal-title fs-5" id="modalImgFacturaLabel">
                    Desea Cargar Imágen de Factura?
                  </h1>
                </div>
                <div className="modal-body">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Seleccione su imágen:
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group input-group-sm mb-2 ">
                        <input
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={handleImageSelect}
                          ref={inputFile}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  {!imagenSeleccionada && (
                    <button
                      className="btn btn-sm btn-info text-light"
                      title="Cancelar"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setImagenSeleccionada("");
                        clearInputFile();
                      }}
                    >
                      No, gracias
                    </button>
                  )}

                  {imagenSeleccionada && (
                    <>
                      <button
                        className="btn btn-sm btn-primary"
                        data-bs-dismiss="modal"
                        type="button"
                      >
                        Continuar
                      </button>

                      <button
                        className="btn btn-sm btn-secondary"
                        data-bs-dismiss="modal"
                        type="button"
                        onClick={() => {
                          clearInputFile();
                          setImagenSeleccionada("");
                        }}
                      >
                        Cancelar
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal Seriales*/}
          <div
            className="modal fade"
            id="modalSeriales"
            tabIndex="-1"
            aria-labelledby="modalSerialesLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-primary text-light">
                  <h1 className="modal-title fs-5" id="modalSerialesLabel">
                    {verSeriales ? "Seriales Añadidos" : "Agregar Seriales"}
                  </h1>
                </div>
                <div className="modal-body">
                  {/* ------ Tabla agregar seriales ------ */}
                  {!verSeriales ? (
                    <table className="table table-hover  table-sm">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Articulo</th>
                          <th scope="col">Serial</th>
                        </tr>
                      </thead>
                      <tbody className="text-center" id="tabla">
                        {usaSerial &&
                          Array.from(Array(cantidad || 0), (_, index) => (
                            <tr key={index}>
                              <td>{articulo}</td>
                              <td>
                                <input
                                  className="form-control form-control-sm"
                                  type="text"
                                  disabled={verSeriales}
                                  value={listaSeriales[index] || ""}
                                  onChange={(e) =>
                                    handleSerialChange(
                                      index,
                                      e.target.value.toUpperCase()
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : /* ------ Tabla ver seriales ------ */
                    verSeriales ? (
                      <table className="table table-hover  table-sm">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Lista seriales </th>
                          </tr>
                        </thead>
                        <tbody className="text-center" id="tabla">
                          {verListaSeriales.map((serial) => (
                            <tr key={serial}>
                              <td>
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control form-control-sm"
                                  value={serial}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {verSeriales ? "Continuar" : "Cancelar"}
                  </button>

                  {!verSeriales ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={validarSeriales}
                    >
                      Guardar
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Modal masivos */}
          <div
            className="modal fade"
            id="modalMasivos"
            tabIndex="-1"
            aria-labelledby="modalSerialesLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-primary text-light">
                  <h1 className="modal-title fs-5" id="modalSerialesLabel">
                    {cargar
                      ? "Seleccione el destino del inventario"
                      : "Seleccione la plantilla"}
                  </h1>
                </div>

                <div className="modal-body">
                  <p>
                    {cargar
                      ? "Por favor, seleccione entre 'Bodegas' o 'Proyectos'. Las cabeceras de su archivo Excel deben coincidir con su elección. Después de enviar su respuesta, proceda a cargar el archivo correspondiente."
                      : "Por favor, seleccione la plnatilla que desea descargar entre 'Bodegas' o 'Proyectos'."}{" "}
                  </p>

                  <div className="container text-center my-4">
                    <div className="form-check form-check-inline ms-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOpciones"
                        id="inlineRB"
                        value="Bodega"
                        onChange={(e) => {
                          cargar
                            ? setSelectTipoCarga(e.target.value)
                            : setSelectTipoDescarga(e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRB">
                        Bodegas
                      </label>
                    </div>

                    <div className="form-check form-check-inline ms-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOpciones"
                        id="inlineRP"
                        value="Proyecto"
                        onChange={(e) => {
                          cargar
                            ? setSelectTipoCarga(e.target.value)
                            : setSelectTipoDescarga(e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRP">
                        Proyectos
                      </label>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  {/* Boton cancelar y continuar */}
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSelectTipoCarga("");
                      setSelectTipoDescarga("");
                      const radioButtons = document.getElementsByName(
                        "inlineRadioOpciones"
                      );
                      radioButtons.forEach((radio) => {
                        radio.checked = false;
                      });
                    }}
                  >
                    Cancelar
                  </button>

                  {/* Boton guardar */}
                  {selectTipoCarga || selectTipoDescarga ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        const radioButtons = document.getElementsByName(
                          "inlineRadioOpciones"
                        );
                        radioButtons.forEach((radio) => {
                          radio.checked = false;
                        });
                      }}
                    >
                      Enviar Respuesta
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};