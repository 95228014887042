import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";

import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  guardarDatabase
} from "../../config/firebase";

import { changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import { CarMasClasificaciones } from "../cragaMasiva/CarMasClasificaciones";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { PlantillaExcel } from "../cragaMasiva/PlantillaExcel";
import { TdTablaD, ThTablaH } from "../miniComponentes";

export const Clasificaciones = ({ permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [listaClasificaciones, setListaClasificaciones] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [descripcionM, setDescripcionM] = useState([]);
  const [listaInterna, setListaInterna] = useState([]);
  const [descripcion, setDescripcion] = useState([]);
  const [estadoI, setEstadoI] = useState([]);
  const [codigoM, setCodigoM] = useState([]);
  const [estadoM, setEstadoM] = useState([]);
  const [codigo, setCodigo] = useState([]);

  const [descripcionClasificacion, setDescripcionClasificacion] = useState("");
  const cabecerasClasif = ["tipoClasificacion", "Codigo", "Descripcion"]
  const [tipoClasificacion, setTipoClasificacion] = useState("");
  const [idDocumento, setIdDocumento] = useState("");
  const [estado, setEstado] = useState("ACTIVO");
  const [idClas, setIdClas] = useState("");

  const [countOrdenar, setCountOrdenar] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [adicionar, setAdicionar] = useState(0);
  const [mobile, setMobile] = useState(true)
  const tablaRef = useRef(null);

  const [col2, setCol2] = useState(true)
  const [col3, setCol3] = useState(true)
  const [col4, setCol4] = useState(true)

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0)

  //**********Funciones*************** */
  useEffect(() => {
    changeTitle("Admin. Clasificaciones");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4], user, "clasificaciones");
    cargarClasificaciones();
    isMobile(setMobile);
  };

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);

  //----------CRUD Clasificaciones-------------
  const cargarClasificaciones = async () => {
    setIsLoading(true);
    let clasif;
    try {
      const listaTempClasificaciones = await consultarDatabase(`${emp}_clasificaciones`)
      if (listaTempClasificaciones.length === 0) {
        clasif = [{ tipoClasificacion: "Sin Datos" }]
      } else {
        clasif = listaTempClasificaciones.sort(sortBy("tipoClasificacion"));
      }
      setListaClasificaciones(clasif);
      setDocumentosFiltrados(clasif)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleClickAdicionar = async () => {
    const tamList = (listaClasificaciones.length + 1).toString();
    const respuesta = listaClasificaciones.filter((c) => c.descripcionClasificacion === descripcionClasificacion.toUpperCase())

    if (respuesta.length >= 1) {
      resetStates();
      toast.warning(`La descripcion: ${descripcionClasificacion.toUpperCase()} ya se encuentra registrada, verifique e intente de nuevo.`);
    } else {
      const enviarClasificacion = {
        id: uniqid("clasificacion-"),
        tipoClasificacion: tamList,
        descripcionClasificacion: descripcionClasificacion.toUpperCase(),
        lista: listaInterna.length > 0 ? listaInterna : [],
        estado: estado.toUpperCase(),
        usuario_creacion: user,
        fecha_creacion: fechaFormateada,
      };

      if (tamList === "" || descripcionClasificacion === "" || estado === "") {
        toast.warning("Diligencie todos los campos de manera correcta");
      } else {
        setIsLoading(true)
        try {
          await guardarDatabase(`${emp}_clasificaciones`, enviarClasificacion);
          toast.success("Clasificacion agregada de manera correcta");
          resetStates();
        } catch (error) {
          console.error(error)
        } finally {
          await cargarClasificaciones();
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = (c) => {
    setIdDocumento(c.idDocumento);
    setTipoClasificacion(c.tipoClasificacion);
    setDescripcionClasificacion(c.descripcionClasificacion);
    setEstado(c.estado);
    setIdClas(c.id);
  };

  const handleClickModificarBd = async () => {
    const enviarClasificacion = {
      descripcionClasificacion,
      estado,
      tipoClasificacion,
      usuario_modificacion: user.toUpperCase(),
      fecha_modificacion: fechaFormateada,
      id: idClas,
    };
    setIsLoading(true)
    try {
      await actualizarDocumentoDatabase(`${emp}_clasificaciones`, idDocumento, enviarClasificacion);
      toast.success("Clasificacion Modificada correctamente");
      await cargarClasificaciones();
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  };

  const resetStates = () => {
    setTipoClasificacion("");
    setDescripcionClasificacion("");
    setEstado("");
    setEstadoI("ACTIVO");
    setCodigoM("");
    setDescripcionM("");
    setCodigo([]);
    setDescripcion([]);
    setListaInterna([])
  };

  //--------CRUD Lista Interna------------
  const agreAListInterna = async (index, listas, doc) => {
    let enviarNuevaInterna = [];

    const nuevoAlista = {
      codigo: codigo[index],
      descripcion: descripcion[index],
      estadoI: estadoI[index],
    };
    if (nuevoAlista.codigo === undefined || nuevoAlista.descripcion === undefined) {
      toast.warning("Diligencie todos los campos de manera correcta");
      resetListaInterna(index);
    } else {
      const encontrada = listas.lista.find((li) => li.codigo === nuevoAlista.codigo);
      if (encontrada === undefined) {
        const nuevaListaInterna = [...listaInterna];
        nuevaListaInterna.push(nuevoAlista);
        enviarNuevaInterna = {
          lista: nuevaListaInterna,
        };
        setIsLoading(true)
        try {
          await actualizarDocumentoDatabase(`${emp}_clasificaciones`, doc, enviarNuevaInterna);
          await cargarClasificaciones();
          toast.success("Lista interna agregada de manera correcta");
          resetListaInterna(index);
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      } else {
        toast.warning("El codigo " + nuevoAlista.codigo + " ya exite, inetente de nuevo");
        resetListaInterna(index);
      }
    }
  };

  const modifDeListInterna = async () => {
    if (idDocumento !== "") {
      const elementoEncontrado = listaInterna.find((li) => li.codigo === codigoM);

      if (elementoEncontrado) {
        elementoEncontrado.descripcion = descripcionM;
        elementoEncontrado.estadoI = estadoM;
        setListaInterna(listaInterna);
        const lista = {
          lista: listaInterna,
        };
        setIsLoading(true)
        try {
          await actualizarDocumentoDatabase(`${emp}_clasificaciones`, idDocumento, lista);
          resetStates();
          await cargarClasificaciones();
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
        toast.success("Lista interna modificada correctamente");
      } else {
        toast.error("Lista interna no se pudo modificar");
      }
    } else {
      toast.warning("Seleccione una lista interna para modificarla");
    }
    resetStates();
  };

  const resetListaInterna = (index) => {
    setCodigo([...codigo.slice(0, index), "", ...codigo.slice(index + 1)]);
    setDescripcion([
      ...descripcion.slice(0, index), "", ...descripcion.slice(index + 1),
    ]);
    setEstadoI([
      ...estadoI.slice(0, index), "", ...estadoI.slice(index + 1),
    ]);
  };

  //--------------Columnas-----------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };


  //-------Paginación---------------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaClasificaciones(temporal);
    setCountOrdenar(countOrdenar + 1)
  };
  //-----------------Carga Masiva-------------------
  const manejarRespuestaCarga = () => {
    cargarClasificaciones()
  };

  const manejarRespuestaDescarga = () => {
    toast.info("Desacarga en proceso.")
  };

  //---------------------------------------
  return (
    <>
      {isLoading && <LoaderES />}
      <>
        {/* botones de opcion */}
        <div className="fixed-top mt-2"
          style={{
            backgroundColor: "white",
            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
            transition: "margin-left 0.3s ease",
            top: "46px",
            zIndex: 1
          }}>

          <div className="row ms-2 me-2">
            <div className="col-sm-4 align-self-start text-start">
              <div className="btn-group">
                {/* Adicionar */}
                {/* <button
                    className="btn fa-solid fa-plus"
                      disabled={!permisos.includes("crearClasif")}
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarAdicionar"
                    id="modaladicionar"
                    title="Crear Nueva Clasificación"
                    onClick={() => {
                      setAdicionar(1);
                      resetStates();
                    }} /> */}


                {/* Carga masiva */}
                <CarMasClasificaciones
                  permiso={permisos}
                  head={cabecerasClasif}
                  listaClasif={listaClasificaciones}
                  onRespuesta={manejarRespuestaCarga} />

                {/* plantila excel */}
                <PlantillaExcel
                  permiso={permisos}
                  origen={"Entrada"}
                  tipo={"Clasificaciones"}
                  onRespuesta={manejarRespuestaDescarga} />

                {/* Columnas */}
                <button
                  className="btn fa-solid fa-table-columns"
                  title="Seleccionar Columnas"
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarColumnas"
                  id="modalcolumnas" />

                {/* Actualizar */}
                <button
                  className="btn fa-solid fa-rotate"
                  title="Actualizar Datos"
                  onClick={cargarClasificaciones} />

              </div>
            </div>

            {/* filtrar */}
            <div className={(mobile ? " col-sm-9 mt-2 " : "col-sm-8")}>
              <Filtrar2
                tabla="myTable"
                documentos={listaClasificaciones}
                setDocumentosFiltrados={setDocumentosFiltrados}
                setRegistrosFiltrados={setRegistrosPaginaActual}
                registrosPorPagina={registrosPorPagina} />
            </div>
          </div>

        </div>

        <hr className={(mobile ? "mt-5 mb-5" : "mt-5")} />

        {/* +Tabla de clasificaciones */}
        {permisos.includes("verClasif") && (
          <div className="table-responsive">
            <table
              id="myTable"
              ref={tablaRef}
              className="table table-hover table-sm table-bordered mt-3">
              <thead>
                <tr>
                  <ThTablaH ordenar={ordenar} dOrdenar={"tipoClasificacion"} columna={col2} titulo={"Tipo Clasificacion"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"descripcionClasificacion"} columna={col3} titulo={"Descripcion Clasificacion"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"estado"} columna={col3} titulo={"Estado"} />
                  <th>Edit</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {registrosPaginaActual.map((clasificacion, index) => (

                  <React.Fragment key={clasificacion.codigo}>
                    <tr className="accordion accordion-flush" id="accordionClasificaciones"
                      key={clasificacion.codigo} >
                      <TdTablaD columna={col2} dato={clasificacion.tipoClasificacion} />
                      <TdTablaD columna={col3} dato={clasificacion.descripcionClasificacion} clase={clasificacion.estado !== "ACTIVO" && "text-danger"} />
                      <TdTablaD columna={col4} dato={clasificacion.estado} />
                      <td className="text-center" style={{ width: "30px" }}>
                        <div className="btn-group">
                          <button
                            className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                            disabled={!permisos.includes("editarClasif")}
                            data-bs-toggle="modal"
                            data-bs-target="#ModificarAdicionar"
                            id="modalModificar"
                            title="Modificar Item Seleccionado"
                            onClick={() => {
                              handleClickTraerData(clasificacion);
                              setAdicionar(0);
                            }} />
                        </div>
                      </td>

                      {/* **************  Acordion ******************** */}
                      <td style={{ width: "10px" }}>
                        <div className="accordion-item m-0">
                          <h2 className="accordion-header"
                            id={"flush-heading" + index} >
                            <button
                              className="accordion-button collapsed p-0"
                              style={{ backgroundColor: "transparent" }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#" + clasificacion.descripcionClasificacion}
                              aria-expanded="false"
                              aria-controls={clasificacion.descripcionClasificacion}
                              onClick={() => setIdDocumento(clasificacion.idDocumento)} />
                          </h2>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} className="p-0">
                        <div
                          id={clasificacion.descripcionClasificacion}
                          className="accordion-collapse collapse"
                          aria-labelledby={"flush-heading" + index}
                          data-bs-parent="#accordionClasificaciones">
                          <div className="accordion-body">
                            {clasificacion && clasificacion.lista && (
                              <table className="table table-sm table-hover table-stripped align-middle table-bordered table-info">
                                <thead>
                                  <tr>
                                    <th>Codigo</th>
                                    <th>Descripción</th>
                                    <th>Estado</th>
                                    <th className="text-center">Edit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {clasificacion.lista.map((l) => (
                                    <tr key={l.descripcion}>
                                      <td className={l.estadoI !== "ACTIVO" ? "text-danger" : ""}>{l.codigo}</td>
                                      <td className={l.estadoI !== "ACTIVO" ? "text-danger" : ""}>{l.descripcion}</td>
                                      <td className={l.estadoI !== "ACTIVO" ? "text-danger" : ""}>{l.estadoI}</td>
                                      <td className="btn-group-sm text-center">

                                        {/* Btn Modificar */}
                                        <button className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                          disabled={!permisos.includes("editarSubClasif")}
                                          data-bs-toggle="modal"
                                          data-bs-target="#EditarLista"
                                          id="modalEditarLista"
                                          title="Editar Item Seleccionado"
                                          onClick={() => {
                                            setIdDocumento(clasificacion.idDocumento);
                                            setCodigoM(l.codigo);
                                            setDescripcionM(l.descripcion);
                                            setEstadoI(l.estadoI);
                                            setListaInterna(clasificacion.lista);
                                          }} />

                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    {/* Inpt. codigo */}
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Codigo"
                                        value={codigo[index]}
                                        onFocus={() => setListaInterna(clasificacion.lista)}
                                        onChange={(e) => {
                                          const temporal = [...codigo];
                                          temporal[index] = e.target.value.toUpperCase() || "";
                                          setCodigo(temporal);
                                        }} />
                                    </td>

                                    {/* Inpt. descripcion */}
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Descripcion"
                                        value={descripcion[index]}
                                        onChange={(e) => {
                                          const tempDesc = [...descripcion];
                                          tempDesc[index] = e.target.value.toUpperCase() || "";
                                          setDescripcion(tempDesc);
                                          const tempEst = [...estadoI];
                                          tempEst[index] = "ACTIVO";
                                          setDescripcion(tempDesc);
                                          setEstadoI(tempEst);
                                        }} />
                                    </td>

                                    {/* Btn Guardar */}
                                    <td>
                                      <button
                                        className="btn btn-sm btn-primary fa-solid fa-plus"
                                        disabled={!permisos.includes("crearSubClasif")}
                                        onClick={() => {
                                          setIdDocumento(clasificacion.idDocumento);
                                          setListaInterna(clasificacion.lista);
                                          agreAListInterna(index, clasificacion, clasificacion.idDocumento);
                                        }} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* +Modal de Modificar Lista Interna*/}
        <div
          className="modal fade"
          aria-hidden="true"
          id="EditarLista"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false">

          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">

              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title">Modificar Lista Interna</h5>
              </div>

              <div className="modal-body">
                <div className="modal-body">
                  {/* Codigo */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Codigo: </span>
                    <input
                      className="form-control"
                      placeholder="Codigo"
                      disabled
                      value={codigoM}
                      onChange={(e) => setCodigoM(e.target.value)} />
                  </div>

                  {/* Descripcion  */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Descripcion: </span>
                    <input
                      className="form-control"
                      placeholder="Descripcion"
                      value={descripcionM}
                      onChange={(e) => setDescripcionM(e.target.value.toUpperCase())} />
                  </div>

                  {/* estado */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Estado:</span>
                    <select
                      className="form-select"
                      placeholder="Estado"
                      value={estadoM}
                      onChange={(e) => setEstadoM(e.target.value)} >
                      <option value="" disabled>Seleccione</option>
                      <option value="ACTIVO">Activo</option>
                      <option value="INACTIVO">Inactivo</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => modifDeListInterna()}>
                  Modificar
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => resetStates()}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* +Modal de Modificar // Adicionar*/}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarAdicionar"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false">

          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">

              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title">
                  {adicionar === 1 ? "Crear " : "Modificar "} Clasificacion
                </h5>
              </div>

              <div className="modal-body">
                <div className="modal-body">
                  {/* Descripcion Clasificacion */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text"> Descripcion Clasificacion: </span>
                    <input
                      className="form-control"
                      disabled={!adicionar}
                      value={descripcionClasificacion}
                      onChange={(e) => setDescripcionClasificacion(e.target.value)} />
                  </div>

                  {/* Estado */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Estado: </span>
                    <select
                      className="form-select"
                      placeholder="Estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}>
                      <option value="" disabled> Seleccione </option>
                      <option value="ACTIVO">Activo</option>
                      <option value="INACTIVO">Inactivo</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    adicionar === 1
                      ? handleClickAdicionar()
                      : handleClickModificarBd()
                  }>
                  {adicionar === 1 ? "Crear" : "Modificar"}
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => resetStates()}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* +Modal Columnas */}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarColumnas"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">
              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title" id="staticBackdropLabel3">
                  Selección de columnas
                </h5>
              </div>

              <div className="modal-body">

                <div className="form-check">
                  <CheckboxCol tabla="clasificaciones" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Tipo Clasificación"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="clasificaciones" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Descripcion Clasificacion"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="clasificaciones" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Estado"} />
                </div>

              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal" >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

    </>
  );
};
