
import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actualizarDocumentoDatabase, consultarDocumentoWhereIgual } from "../../config/firebase";
import { changeTitle } from "../../config/functions/funGenerales";

export const UsuariosPermisos = () => {

  const location = useLocation();
  const history = useHistory();
  let userP = location.state.userP;

  const [expanded, setExpanded] = useState([]);
  const [checked, setChecked] = useState([]);

  const [nameUser, setNameUser] = useState("");
  const [idUser, setIdUser] = useState("");
  const [origen, setOrigen] = useState("");

  const [componente, setComponente] = useState(localStorage.getItem("componente") || "usuariopermiso");

  useEffect(() => {
    const existeComponente = (location.state)?.componente
    if (existeComponente) {
      setOrigen((location.state).origen);
    }
  }, [location]);

  //-----------------------------------------------------------

  useEffect(() => {
    localStorage.setItem("componente", componente);
  }, [componente]);

  useEffect(() => {
    changeTitle("Admin. Permisos Usuarios");
    cargarUsuario(userP)
  }, []);

  const cargarUsuario = async (u) => {
    const temporal = await consultarDocumentoWhereIgual("usuarios", "id", u)
    setNameUser(temporal[0]?.empleado)
    setIdUser(temporal[0]?.idDocumento);
    setChecked(temporal[0]?.permisos ? temporal[0].permisos : []);
  };

  //------------------------------------------------------------

  const nodes = [

    {
      value: "miEmpresa", label: "Mi empresa",
      children: [
        { value: "consultarEmpresa", label: "Consultar Empresa" },
        { value: "editarEmpresa", label: "Editar Empresa" },

        {
          value: "movimientos", label: "Movimientos",
          children: [
            {
              value: "movEntrada", label: "Entrada",
              children: [
                { value: "ingresoMovEntrada", label: "Ingreso a Movimientos de Entrada" },
                { value: "plantExcelMasInv", label: "Descargar Pantilla C.M Inventario" },
                { value: "cargaMasivaInv", label: "Cargar Masiva Inventario" },
                { value: "cargarInventario", label: "Cargar Inventario" },
                { value: "cargarCompra", label: "Cargar Compras" },
              ]
            },
            {
              value: "movSalida", label: "Salida",
              children: [
                { value: "ingresoMovSalida", label: "Ingreso a Movimientos de Salida" },
                { value: "bajaInventario", label: "Baja Inventario" },
                { value: "bajaVenta", label: "Baja Venta" },
              ]
            },
            {
              value: "movInterno", label: "Internos",
              children: [
                { value: "ingresoMovInterno", label: "Ingreso a Movimientos de Internos" },
                { value: "movYAsigPend", label: "Movimientos y Asignaciones Pendientes" },
                { value: "movDevolPend", label: "Devoluciones Pendientes" },
                { value: "movProyecto", label: "Hacia Proyectos" },
                { value: "movBodega", label: "Hacia Bodegas" },
              ]
            }
          ]
        },

        {
          value: "consulta", label: "Consultas",
          children: [
            {
              value: "consSolicitudes", label: "Consulta de Solicitudes",
              children: [
                { value: "ingrconsSol", label: "Ingreso Consulta Solicitudes" },
                {
                  value: "verSol", label: "Solicitudes",
                  children: [
                    { value: "ingrVerSol", label: "Ingreso Ver Solicitudes" },
                    { value: "aprobarSol", label: "Aprobar Solicitudes" },
                    { value: "desaprobarSol", label: "Desaprobar Solicitudes" },
                    { value: "eliminarSol", label: "Eliminar Solicitudes" },
                  ]
                },
                {
                  value: "gestionSol", label: "Gestionar Solicitudes",
                  children: [
                    { value: "ingrGestSol", label: "Ingreso Gestion Solicitudes" },
                    { value: "procesarSol", label: "Procesar Solicitudes" }
                  ]
                },
              ]
            },
            { value: "consInventario", label: "Consulta de Inventario" },
            { value: "consMovimientos", label: "Consulta de Movimientos" },
            { value: "consArticulos", label: "Consulta de Artículos" },
            { value: "consMovTransito", label: "Consulta de Movimientos en Transito" },
            { value: "consAjusteInventario", label: "Consulta de Ajustes de Inventario" },
          ]
        },

        {
          value: "reportes", label: "Reportes",
          children: [
            {
              value: "repEntradas", label: "Reporte de Entradas",
              children: [
                { value: "ingreRepInv", label: "Ingreso Reportes Inventario" },
                { value: "ingreRepCom", label: "Ingreso Reporte Compras" },
                { value: "impReportInv", label: "Imprimir Reporte de Inventario" },
                { value: "impReportCom", label: "Imprimir Reporte de Compras" },
              ]
            },
            {
              value: "repSalidas", label: "Reporte de Salidas",
              children: [
                { value: "repBajas", label: "Reporte de Bajas de Inventario" },
                { value: "repVenta", label: "Reporte de Ventas" },
                { value: "impReportBaja", label: "Imprimir Reporte de Bajas" },
                { value: "impReportVen", label: "Imprimir Reporte de Ventas" },
              ]
            },
            {
              value: "repMovimientos", label: "Reporte de Movimientos",
              children: [
                { value: "repMovBodg", label: "Reporte de Movimiento a Bodega" },
                { value: "repMovProy", label: "Reporte de Asigancion a Proyecto" },
                { value: "impReportMov", label: "Imprimir Reporte de Movimiento" },
                { value: "impReportAsig", label: "Imprimir Reporte de Asignacion" },
              ]
            },
            {
              value: "repSolicitudes", label: "Reporte de Solicitudes",
              children: [
                { value: "impReportSol", label: "Imprimir Reporte de Solicitudes" },
              ]
            },
          ]
        },

        {
          value: "solicitudes", label: "Solicitudes",
          children: [
            { value: "solEpp", label: "Solicitud de EPPS" },
            { value: "solConsumible", label: "Solicitud de Consumible" },
            { value: "solHerramienta", label: "Solicitud de Herramienta o material" },
          ]
        },

        {
          value: "procesos", label: "Procesos",
          children: [
            {
              value: "ajusteInventario", label: "Ajuste de Inventario",
              children: [
                { value: "ingresoAjustInv", label: "Ingreso Ajuste Inventario" },
                { value: "manipularInv", label: "Ajustar cantidades del Inventario" },

              ]
            },
          ]
        },

        {
          value: "administración", label: "Administración",
          children: [
            {
              value: "articulos", label: "Artículos",
              children: [
                { value: "ingresoArticulos", label: "Ingreso" },
                { value: "descargaPlantArt", label: "Descargar Plantilla de C.M de Artículos" },
                { value: "editarDataArt", label: "Editar Datos de Artículos" },
                { value: "editarImgArt", label: "Editar Imagen de Artículos" },
                { value: "cargaMasArt", label: "Carga Masiva de Artículos" },
                { value: "verArticulos", label: "Ver Artículos" },
                { value: "crearArt", label: "Crear Artículos" },
              ]
            },
            {
              value: "bodegas", label: "Bodegas",
              children: [
                { value: "ingresoBodegas", label: "Ingreso" },
                { value: "verBod", label: "Ver Bodegas" },
                { value: "crearBod", label: "Crear Bodegas" },
                { value: "editarBod", label: "Editar Bodegas" },
                { value: "crearUbicBod", label: "Crear Ubicacion Bodegas" },
              ]
            },
            {
              value: "proyectos", label: "Proyectos",
              children: [
                { value: "ingresoProyectos", label: "Ingreso" },
                { value: "verProy", label: "Ver Proyectos" },
                { value: "crearProy", label: "Crear Proyectos" },
                { value: "editarProy", label: "Editar Proyectos" },
              ]
            },
            {
              value: "clasificaciones", label: "Clasificaciones",
              children: [
                { value: "ingresoClasificaciones", label: "Ingreso" },
                { value: "verClasif", label: "Ver Clasificaciones" },
                { value: "crearClasif", label: "Crear Clasificaciones" },
                { value: "editarClasif", label: "Editar Clasificaciones" },
                { value: "crearSubClasif", label: "Crear Sub-Clasificaciones" },
                { value: "editarSubClasif", label: "Editar Sub-Clasificaciones" },
                { value: "descPlantClasif", label: "Descargar Plantilla C.M de Clasificaciones" },
                { value: "cargaMasClasif", label: "Carga Masiva Clasificaciones" },
              ]
            },
            {
              value: "terceros", label: "Terceros",
              children: [
                { value: "ingresoTerceros", label: "Ingreso" },
                { value: "verTerc", label: "Ver Terceros" },
                { value: "crearTerc", label: "Crear Terceros" },
                { value: "editarTerc", label: "Editar Terceros" },
              ]
            },
            {
              value: "consecutivos", label: "Consecutivos",
              children: [
                { value: "ingresoConsecutivos", label: "Ingreso" },
                { value: "verConsec", label: "Ver Consecutivos" },
                { value: "crearConsec", label: "Crear Consecutivos" },
                { value: "editarConsec", label: "Editar Consecutivos" },
              ]
            },
            {
              value: "unidades", label: "Unidades",
              children: [
                { value: "ingresoUnidades", label: "Ingreso" },
                { value: "verUnid", label: "Ver Unidades" },
                { value: "crearUnid", label: "Crear Unidades" },
                { value: "editarUnid", label: "Editar Unidades" },
              ]
            },
            {
              value: "Usuarios", label: "Usuarios",
              children: [
                { value: "ingresoUsuarios", label: "Ingreso" },
                { value: "verUser", label: "Ver Usuarios" },
                { value: "crearUser", label: "Crear Usuarios" },
                { value: "editarUser", label: "Editar Usuarios" },
                { value: "permisosUser", label: "Permisos Usuarios" },
                { value: "cambioClaveUser", label: "Cambio Clave Usuarios" },
              ]
            },
          ]
        },
      ]
    },

  ];

  //-------------------------------------------------------------

  const guardarPermisos = async () => {
    const enviarUsuario = { permisos: checked }
    if (!idUser || !enviarUsuario) {
      console.error('idUser o enviarUsuario no están definidos');
      return;
    }
    try {
      await actualizarDocumentoDatabase("usuarios", idUser, enviarUsuario);
      toast.success("Permisos actualizados con éxito")
    } catch (error) {
      console.error(error)
    }
  }

  //-------------------------------------------------------------

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className="card mt-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-2">
              <button className="btn btn-primary fa-solid fa-angle-left btn-sm"
                onClick={() => {
                  setComponente(origen)
                  history.push({ pathname: `/${origen === "Usuarios" ? "usuarios" : "admin_usuario"}`, state: { componente: origen } })
                }} />
            </div>
            <div className="col-8">
              <h3 className="h3-easystock">
                Permisos de Usuario: <br /> {nameUser}
              </h3>
            </div>
            <div className="col-2">
              <button className="btn btn-primary fa-solid fa-floppy-disk btn-sm"
                onClick={() => guardarPermisos()} />
            </div>
          </div>
        </div>
        <div className="card-body">
          <CheckboxTree
            checked={checked}
            expanded={expanded}
            nodes={nodes}
            onCheck={(e) => setChecked(e)}
            onExpand={(e) => setExpanded(e)}
          />
        </div>
      </div>
    </>
  );
};