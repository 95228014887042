import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";
import { changeTitle, isMobile, styles } from "../../config/functions/funGenerales";

import sortBy from "sort-by";
import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  consultarDatabase,
  consultarDocumentoWhere,
  consultarDocumentoWhereIgual,
  guardarDatabaseId,
} from "../../config/firebase";
import { LoaderES } from "../LoaderES";

export const Movimiento = ({ inventario, articulos, kardex, movimientos, listaFusion, permisos }) => {
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const nombre = JSON.parse(localStorage.getItem("token")).name;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const rol = JSON.parse(localStorage.getItem("token")).tipo;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));
  const [componente, setComponente] = useState(localStorage.getItem("componente") || "Movimiento");
  const history = useHistory();
  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [articulosUnicosEnInventario, setArticulosUnicosEnInventario] = useState([]);
  const [listaConsecutivosAsignar, setlistaConsecutivosAsignar] = useState([]);
  const [listaFusionMovimientos, setListaFusionMovimientos] = useState([]);
  const [listaConsecutivosMover, setlistaConsecutivosMover] = useState([]);
  const [listaInventarioActual, setListaInventarioActual] = useState([]);
  const [valoresSeleccionados, setValoresSeleccionados] = useState([]);
  const [listaProyectoDestino, setListaProyectoDestino] = useState([]);
  const [serialesDisponibles, setSerialesDisponibles] = useState([]);
  const [listaBodegasDestino, setListaBodegasDestino] = useState([]);
  const [ubicacionesDestino, setUbicacionesDestino] = useState([]);
  const [verListaSeriales, setVerListaSeriales] = useState([]);
  const [listaUbicaciones, setListaUbicaciones] = useState([]);
  const [listaMovimientos, setListaMovimientos] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaSeriales, setListaSeriales] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [listaAsignar, setListaAsignar] = useState([]);
  const [listaVerPDF, setListaVerPDF] = useState([]);
  const [dataEmpresa, setDataEmpresa] = useState([]);
  const [listaKardex, setListaKardex] = useState([]);
  const [listaMover, setListaMover] = useState([]);

  const [ubicacionSalida, setUbicacionSalida] = useState("");
  const [proyectoSalida, setProyectoSalida] = useState("");
  const [bodegaSalida, setBodegaSalida] = useState("");
  const [observacion, setObservacion] = useState("");
  const [articulo, setArticulo] = useState("");
  const [valor, setValor] = useState(0);

  const [ubicacionDestino, setUbicacionDestino] = useState("");
  const [proyectoDestino, setProyectoDestino] = useState("");
  const [bodegaDestino, setBodegaDestino] = useState("");

  const [idDocumentoConsecutivoAsignar, setIdDocumentoConsecutivoAsignar,] = useState("");
  const [idDocumentoConsecutivoMover, setIdDocumentoConsecutivoMover] = useState("");
  const [valorConsecutivoAsignar, setValorConsecutivoAsignar] = useState(0);
  const [verConsecutivoAsignar, setVerConsecutivoAsignar] = useState("");
  const [valorConsecutivoMover, setValorConsecutivoMover] = useState(0);
  const [verConsecutivoMover, setVerConsecutivoMover] = useState("");
  const [valorPrefijoAsignar, setValorPrefijoAsignar] = useState("");
  const [valorPrefijoMover, setValorPrefijoMover] = useState("");

  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("mover");
  const [validarArtySeries, setValidarArtySeries] = useState(false);
  const [opcionSelecBoP, setOpcionSelecBoP] = useState("");
  const [verSeriales, setVerSeriales] = useState(false);
  const [cantidad, setCantidad] = useState(0);
  const [um, setUm] = useState("");

  const [cantActualProyecto, setCantActualProyecto] = useState(0);
  const [cantActualBodega, setCantActualBodega] = useState(0);
  const [contRenSeriales, setContRenSeriales] = useState(0);
  const [fechaAsignar, setFechaAsignar] = useState("");
  const [usaSerial, setUsaSerial] = useState(false);
  const [maxCantidad, setMaxCantidad] = useState(0);
  const [fechaMover, setFechaMover] = useState("");
  const [enProyecto, setEnProyecto] = useState(0);
  const [enBodega, setEnBodega] = useState(0);

  const [pendientesP, setPendientesP] = useState(0);
  const [pendientesB, setPendientesB] = useState(0);
  const [rechazosP, setRechazosP] = useState(0);
  const [rechazosB, setRechazosB] = useState(0);

  const [contProyectoDestino, setContProyectoDestino] = useState(0);
  const [contProyectoSalida, setContProyectoSalida] = useState(0);
  const [contBodegaDestino, setContBodegaDestino] = useState(0);
  const [contBodegaSalida, setContBodegaSalida] = useState(0);

  const [consecArt, setConsecArt] = useState("");
  const [consecMov, setConsecMov] = useState("");
  const [guardo, setGuardo] = useState(false);
  const [idArt, setIdArt] = useState("");
  const [mobile, setMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const consecAsignar = "ASG";
  const consecMover = "MOV";

  const [ubicacionDestinoSelect, setubicacionDestinoSelect] = useState({
    value: "Seleccione Ubicación de Destino",
    label: "Seleccione Ubicación de Destino",
  });
  const [ubicacionSalidaSelect, setubicacionSalidaSelect] = useState({
    value: "Seleccione Ubicación de Salida",
    label: "Seleccione Ubicación de Salida",
  });
  const [proyectoDestinoSelect, setProyectoDestinoSelect] = useState({
    value: "Seleccione Proyecto de Destino",
    label: "Seleccione Proyecto de Destino",
  });
  const [proyectoSalidaSelect, setProyectoSalidaSelect] = useState({
    value: "Seleccione Proyecto de Salida",
    label: "Seleccione Proyecto de Salida",
  });
  const [bodegaDestinoSelect, setBodegaDestinoSelect] = useState({
    value: "Seleccione Bodega de Destino",
    label: "Seleccione Bodega de Destino",
  });
  const [bodegaSalidaSelect, setBodegaSalidaSelect] = useState({
    value: "Seleccione Bodega de Salida",
    label: "Seleccione Bodega de Salida",
  });
  const [articuloSelect, setArticuloSelect] = useState({
    value: "Seleccione Artículo",
    label: "Seleccione Artículo",
  });
  const [bodegasDestino, setBodegasDestino] = useState(listaBodegasDestino);
  const [proyectosDestino, setProyectosDestino] = useState(listaProyectos);
  const [proyectosSalida, setProyectosSalida] = useState(listaProyectos);
  const [ubicaciones, setUbicaciones] = useState(listaUbicaciones);
  const [articulosL, setArticulosL] = useState(listaArticulos);
  const [sinArticulos, setSinArticulos] = useState(false);
  const [bodegas, setBodegas] = useState(listaBodegas);

  //************Funciones*************/
  useEffect(() => {
    changeTitle("Admin. Movimiento");
    isMobile(setMobile);
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    cargarInventarioActual();
    cargarConsecutivos(consecMover, setlistaConsecutivosMover);
    cargarConsecutivos(consecAsignar, setlistaConsecutivosAsignar);
    traerEmpresa();
    setListaMovimientos(movimientos);
    setListaFusionMovimientos(listaFusion);
    setListaKardex(kardex);
    pendientesUsuario("MOV", setRechazosB, setPendientesB);
    pendientesUsuario("ASG", setRechazosP, setPendientesP);
    cargarDestino(`${emp}_bodegas`, setListaBodegasDestino);
    cargarDestino(`${emp}_proyectos`, setListaProyectoDestino);
  };

  useEffect(() => {
    cargarInventarioActual()
  }, [inventario]);

  useEffect(() => {
    setListaMovimientos(movimientos);
  }, [movimientos]);

  useEffect(() => {
    setListaFusionMovimientos(listaFusion);
  }, [listaFusion]);

  useEffect(() => {
    setListaKardex(kardex);
  }, [kardex]);


  //---------Destino------------
  const cargarDestino = async (tabla, setLista) => {
    try {
      const listaTemporal = await consultarDatabase(tabla);
      setLista(listaTemporal);
    } catch (error) {
      console.error(error);
    }
  };

  const renderUbicacionesDestino = (bodega) => {
    const dataBodega = bodega
      ? listaBodegasDestino.filter((item) => item.consecutivo === bodega)
      : listaBodegasDestino;
    const listaUbic = dataBodega[0]?.listaUbicaciones;

    if (listaUbic.length > 0) {
      const options = listaUbic.map((a) => ({ value: a.ubicacion, label: a.ubicacion, }));
      setUbicacionesDestino(options?.sort(sortBy("value")));
    } else {
      setUbicacionesDestino([]);
    }
  };

  useEffect(() => {
    if (listaBodegasDestino.length > 0 && listaBodegasDestino[0].id !== 0) {
      const options = listaBodegasDestino.map((a) => ({ value: a.consecutivo, label: a.descripcion + ": (" + a.consecutivo + ")" }));
      setBodegasDestino(options?.sort(sortBy("label")));
    }
  }, [listaBodegasDestino]);

  useEffect(() => {
    if (bodegaDestino !== "") {
      renderUbicacionesDestino(bodegaDestino);
      setubicacionDestinoSelect({
        value: "Seleccione Ubicación de Destino",
        label: "Seleccione Ubicación de Destino",
      });
      setUbicacionDestino("");
    }
  }, [bodegaDestino]);

  useEffect(() => {
    if (listaProyectoDestino.length > 0 && listaProyectoDestino[0].id !== 0) {
      const options = listaProyectoDestino.map((a) => ({ value: a.consecutivo, label: a.descripcion + ": (" + a.consecutivo + ")" }));
      setProyectosDestino(options?.sort(sortBy("label")));
    }
  }, [listaProyectoDestino]);

  //-------------Inv. Actual // Articulo ------------------
  const cargarInventarioActual = async () => {
    const inventarioTemporal = inventario
    setListaInventarioActual(inventarioTemporal);
    const articulosSet = new Set();
    inventarioTemporal.forEach((item) => {
      if (item.articulo) {
        articulosSet.add(item.articulo);
      }
    });
    setArticulosUnicosEnInventario(Array.from(articulosSet));
  };

  const cargarArticulos = async () => {
    const listaTemporal = articulos
    if (listaTemporal.length === 0) {
      toast.warning("No se encontraron artículos creados en la base de datos");
      setListaArticulos([{ id: 0 }]);
      setSinArticulos(true);
    } else {
      const listaFiltrada = listaTemporal.filter((item) => {
        return articulosUnicosEnInventario.includes(item.consecutivo);
      });
      setListaArticulos(listaFiltrada);
      setSinArticulos(false);
    }
  };

  const eliminarArticulo = (lista, art, fec, tipfec, setLista) => {
    toast.error(
      <div className="text-center">
        ¿Estás seguro de eliminar el artículo "{art}"?
        <div className="row mt-3 mb-2">
          <div className="col">
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                if (tipfec === fechaMover && setLista === setListaMover) {
                  const resultado = lista.filter(
                    (item) =>
                      !(item.articulo === art && item.fechaMover === fec)
                  );
                  setListaMover(resultado);
                  toast.success(
                    "El artículo fue eliminado de la tabla con exito"
                  );
                } else if (
                  tipfec === fechaAsignar &&
                  setLista === setListaAsignar
                ) {
                  const resultado = lista.filter(
                    (item) =>
                      !(item.articulo === art && item.fechaAsignar === fec)
                  );
                  setListaAsignar(resultado);
                  toast.success(
                    "El artículo fue eliminado de la tabla con exito"
                  );
                }
              }}
            >
              Aceptar
            </button>
          </div>
          <div className="col">
            <button className="btn btn-outline-info" onClick={toast.dismiss}>
              Cancelar
            </button>
          </div>
        </div>
      </div>,
      { autoClose: false }
    );
  };

  useEffect(() => {
    if (articulosUnicosEnInventario.length === 0) {
      setListaArticulos([{ id: 0 }]);
      setSinArticulos(true);
    } else {
      cargarArticulos();
    }
  }, [articulosUnicosEnInventario]);

  useEffect(() => {
    if (listaArticulos.length > 0 && listaArticulos[0].id !== 0 && listaInventarioActual.length > 0) {
      let cantidadesDisponibles = {}, total;
      for (let i = 0; i < listaInventarioActual.length; i++) {
        let consecutivo = listaInventarioActual[i].articulo;
        if (listaInventarioActual[i].hasOwnProperty("cant_disponible")) {
          if (!cantidadesDisponibles.hasOwnProperty(consecutivo)) {
            cantidadesDisponibles[consecutivo] = 0;
          }
          cantidadesDisponibles[consecutivo] +=
            listaInventarioActual[i].cant_disponible;
        }
      }

      const options = listaArticulos.map((a) => {
        let cantDis = parseFloat(cantidadesDisponibles.hasOwnProperty(a.consecutivo) ? cantidadesDisponibles[a.consecutivo] : 0);
        let um = a.um;
        let est = a.estado;
        total = (cantDis);
        return { value: a.consecutivo, label: a.consecutivo + ": " + a.des_Larga + ` (Cant: ${total}-${um}, Est: ${est})` };
      });
      setArticulosL(options?.sort(sortBy("label")));
    }
  }, [listaArticulos, listaInventarioActual]);

  useEffect(() => {
    setGuardo(false);
    setListaVerPDF([]);
    setBodegaSalida("");
    setUbicacionSalida("");
    setBodegaSalidaSelect({ value: "Seleccione Bodega de Salida", label: "Seleccione Bodega de Salida", });
    setubicacionSalidaSelect({ value: "Seleccione Ubicacion de Salida", label: "Seleccione Ubicacion de Salida", });
    if (articulo !== "") {
      buscarBodega_Proyecto(articulo);
      limpiarRadioBtn();
    }
  }, [articulo]);

  useEffect(() => {
    pendientesUsuario("MOV", setRechazosB, setPendientesB);
    pendientesUsuario("ASG", setRechazosP, setPendientesP);
  }, [listaMovimientos]);

  //------------Consecutivos---------------
  const cargarConsecutivos = async (prefijo, setLista) => {
    try {
      const listaTemporal = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", prefijo);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setLista(activos);
    } catch (error) {
      console.error(error);
    }
  };

  const manipularConsecutivos = (lista, setConsecutivo, setPrefijo, setIdDoc, setVerConsec) => {
    const valorCon = lista[0]?.valorActual;
    const pref = lista[0]?.prefijo;
    const doc = lista[0]?.idDocumento;
    const val = parseInt(valorCon + 1);
    setConsecutivo(val);
    setPrefijo(pref);
    setIdDoc(doc);
    setVerConsec(pref + "-" + val);
  };

  const handleModificarConsecutivos = async (valor, idDoc, manipular) => {
    const ListaModificacion = {
      valorActual: parseInt(valor.split("-")[1]),
      fecha_modificacion: fecha_modificacion,
      usuario_modificacion: user,
    };
    await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, ListaModificacion);

    if (manipular === "mover") {
      await cargarConsecutivos(consecMover, setlistaConsecutivosMover);
      manipularConsecutivos(listaConsecutivosMover, setValorConsecutivoMover, setValorPrefijoMover, setIdDocumentoConsecutivoMover, setVerConsecutivoMover);
    } else if (manipular === "asignar") {
      await cargarConsecutivos(consecAsignar, setlistaConsecutivosAsignar);
      manipularConsecutivos(listaConsecutivosAsignar, setValorConsecutivoAsignar, setValorPrefijoAsignar, setIdDocumentoConsecutivoAsignar, setVerConsecutivoAsignar);
    }
  };

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosMover, setValorConsecutivoMover, setValorPrefijoMover, setIdDocumentoConsecutivoMover, setVerConsecutivoMover);
  }, [listaConsecutivosMover]);

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosAsignar, setValorConsecutivoAsignar, setValorPrefijoAsignar, setIdDocumentoConsecutivoAsignar, setVerConsecutivoAsignar);
  }, [listaConsecutivosAsignar]);

  //-----Buscar Bodega // Proyecto ---------------
  const buscarBodega_Proyecto = (art) => {
    let existeB = [], existeP = [];

    existeB = listaInventarioActual.filter((item) => item.articulo === consecArt && item.bodega !== undefined && item.ubicacion !== undefined && item.cant_disponible > 0);
    existeP = listaInventarioActual.filter((item) => item.articulo === consecArt && item.proyecto !== undefined && item.cant_reservada > 0);
    if (existeB.length === 0 && existeP.length === 0) {
      toast.warning("No hay stock de: " + art + ", intenta con otro artículo.");
      setArticulo("");
      setArticuloSelect({ value: "Seleccione Artículo", label: "Seleccione Artículo", });
    } else {
      let totalDisponibleB = existeB.reduce((total, item) => parseFloat(total + (item.cant_disponible || 0)), 0);
      let totalDisponibleP = existeP.reduce((total, item) => parseFloat(total + (item.cant_reservada || 0)), 0);
      setCantActualBodega(totalDisponibleB);
      setEnBodega(existeB?.length);
      setCantActualProyecto(totalDisponibleP);
      setEnProyecto(existeP?.length);
    }
  };

  const buscarSeleccion = (bodega, ubicacion, proyecto, sitio) => {
    if (sitio === "bodega") {
      const existe = listaUbicaciones?.find((i) => i.articulo === consecArt && i.bodega === bodega && i.ubicacion === ubicacion);
      setMaxCantidad(parseFloat(existe?.cant_disponible));
    } else if (sitio === "proyecto") {
      const existe = listaProyectos?.find((i) => i.articulo === consecArt && i.proyecto === proyecto);
      setMaxCantidad(parseFloat(existe?.cant_reservada));
    }
  };

  const buscarDataArticulos = (tipo) => {
    if (tipo === "bodega") {
      const articulosComunesB = listaInventarioActual.filter((i) => i.articulo === consecArt && i.bodega !== undefined && i.proyecto === undefined && i.cant_disponible > 0);
      setListaBodegas(articulosComunesB);
    }
    if (tipo === "proyecto") {
      const articulosComunesP = listaInventarioActual.filter((i) => i.articulo === consecArt && i.proyecto !== undefined && i.bodega === undefined && i.cant_reservada > 0);
      setListaProyectos(articulosComunesP);
    }
  };

  const buscarDataArticuloUbicacion = (bodega) => {
    const articulosComunes = listaBodegas.filter((item) => item.bodega === bodega && item.ubicacion !== undefined);
    setListaUbicaciones(articulosComunes);
  };


  useEffect(() => {
    if (listaBodegas.length > 0 && listaBodegas[0].id !== 0) {
      const uniqueBodegas = {};
      listaBodegas.forEach((a) => { uniqueBodegas[a.bodega] = a });
      const options = Object.values(uniqueBodegas).map((a) => {
        const matchingBodega = listaBodegasDestino.find((b) => b.consecutivo === a.bodega);
        return {
          value: a.bodega,
          label: `${matchingBodega.descripcion}: (${matchingBodega.consecutivo})`,
        };
      });

      setBodegas(options?.sort(sortBy("label")));
    }
  }, [listaBodegas]);


  useEffect(() => {
    if (bodegaSalida !== "") {
      setubicacionSalidaSelect({ value: "Seleccione Ubicación de Salida", label: "Seleccione Ubicación de Salida", });
      buscarDataArticuloUbicacion(bodegaSalida);
      setUbicacionSalida("");
      setListaSeriales([]);
      setObservacion("");
      setCantidad(0);
    }
  }, [bodegaSalida]);


  useEffect(() => {
    if (listaUbicaciones.length > 0) {
      const options = listaUbicaciones?.map((a) => ({ value: a.ubicacion, label: a.ubicacion, }));
      setUbicaciones(options?.sort(sortBy("value")));
      setubicacionSalidaSelect({
        value: "Seleccione Ubicación de Salida",
        label: "Seleccione Ubicación de Salida",
      });
    }
  }, [listaUbicaciones]);

  useEffect(() => {
    if (listaProyectos.length > 0 && listaProyectos[0].id !== 0) {
      let uniqueProyectos = {};
      listaProyectos.forEach((a) => { uniqueProyectos[a.bodega] = a });
      let proy = Object.values(uniqueProyectos).map((a) => ({ value: a.proyecto }))
      let desc = proy[0].value;
      const lb = listaProyectoDestino.find((b) => b.consecutivo === desc);
      const options = listaProyectos.map((a) => ({ value: a.proyecto, label: lb.descripcion + ": (" + lb.consecutivo + ")" }));
      setProyectosSalida(options?.sort(sortBy("label")));
    }
  }, [listaProyectos]);

  useEffect(() => {
    buscarSeleccion("", "", proyectoSalida, "proyecto");
  }, [proyectoSalida]);

  useEffect(() => {
    buscarSeleccion(bodegaSalida, ubicacionSalida, "", "bodega");
  }, [bodegaSalida, ubicacionSalida]);

  useEffect(() => {
    setContRenSeriales(0);
  }, [
    ubicacionSalida,
    proyectoSalida,
    ubicacionDestino,
    proyectoDestino,
    cantidad,
  ]);

  //-----------Tablas---------------
  const pasarAtablas = (lista, accion, setLista, id) => {
    const est = "transito";
    if (accion === "mover") {
      const existe = lista?.find((lb) => (lb.articulo === articulo && lb.bodegaSalida === bodegaSalida && lb.ubicacionSalida === ubicacionSalida && lb.bodegaDestino === bodegaDestino && lb.ubicacionDestino === ubicacionDestino) || (lb.articulo === articulo && lb.proyectoSalida === proyectoSalida && lb.bodegaDestino === bodegaDestino && lb.ubicacionDestino === ubicacionDestino));

      if (bodegaSalida !== "" && bodegaDestino !== "" && ubicacionDestino !== "" && ubicacionSalida !== "" && bodegaSalida === bodegaDestino && ubicacionSalida === ubicacionDestino) {
        toast.warning("No se puede mover el artículo, la salida y el destino son iguales");
        return;
      }
      if (existe) {
        toast.warning("El artículo " + existe.articulo + ", ya se moverá a bodega y ubicacion seleccionada, verifica porfavor.");
        return;
      }
      const item = {
        idMov: uniqid("mov-"),
        fechaMover,
        idArticulo: id,
        articulo,
        cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
        consecutivo: idArt,
        bodegaSalida,
        ubicacionSalida,
        bodegaDestino,
        ubicacionDestino,
        proyectoSalida,
        valor,
        estado: est,
        usaserial: usaSerial,
        ...(usaSerial && { listaSeriales: valoresSeleccionados }),
      };

      const camposVacios = fechaMover === "" || articulo === "" || cantidad === 0 || cantidad === "0" || cantidad === "" || (opcionSelecBoP === "bodega" && (bodegaSalida === "" || ubicacionSalida === "" || bodegaDestino === "" || ubicacionDestino === "")) || (opcionSelecBoP === "proyecto" && (proyectoSalida === "" || bodegaDestino === "" || ubicacionDestino === ""));

      if (camposVacios) {
        toast.warning("Todos los campos deben ser diligenciados");
        return;
      }
      añadirAtablas(item, setLista);
      limpiarRadioBtn();
      toast.success("Artículo añadido a la tabla, con éxito");
      limpiarPasarTablas();
      setValidarArtySeries(false);
    }
    if (accion === "asignar") {
      const existe = lista?.find((lb) => (lb.articulo === articulo && lb.bodegaSalida === bodegaSalida && lb.ubicacionSalida === ubicacionSalida && lb.proyectoDestino == proyectoDestino) || (lb.articulo === articulo && lb.proyectoSalida === proyectoSalida && lb.proyectoDestino === proyectoDestino));

      if (proyectoSalida !== "" && proyectoDestino !== "" && proyectoSalida === proyectoDestino) {
        toast.warning("No se puede mover el artículo, la salida y el destino son iguales");
        return;
      }

      if (existe) {
        toast.warning("El artículo " + existe.articulo + " ya existe en la lista.");
        return;
      }
      const item = {
        idMov: uniqid("mov-"),
        fechaAsignar,
        idArticulo: id,
        articulo,
        cantidad: Number(Number.parseFloat(cantidad).toFixed(2)),
        consecutivo: idArt,
        bodegaSalida,
        ubicacionSalida,
        proyectoSalida,
        proyectoDestino,
        valor,
        estado: est,
        usaserial: usaSerial,
        ...(usaSerial && { listaSeriales: valoresSeleccionados }),
      };

      const camposVacios = fechaAsignar === "" || articulo === "" || cantidad === 0 || cantidad === "0" || cantidad === "" || (opcionSelecBoP === "bodega" && (bodegaSalida === "" || ubicacionSalida === "" || proyectoDestino === "")) || (opcionSelecBoP === "proyecto" && (proyectoSalida === "" || proyectoDestino === ""));

      if (camposVacios) {
        toast.warning("Todos los campos deben ser diligenciados");
        return;
      }
      añadirAtablas(item, setLista);
      limpiarRadioBtn();
      toast.success("Artículo añadido a la tabla, con éxito");
      limpiarPasarTablas();
      setValidarArtySeries(false);
    }
  };

  const limpiarPasarTablas = () => {
    !ubicacionSalida && setubicacionSalidaSelect({ value: "Seleccione Ubicación de Salida", label: "Seleccione Ubicación de Salida", });
    !proyectosSalida && setProyectoSalidaSelect({ value: "Seleccione Proyecto de Salida", label: "Seleccione Proyecto de Salida", });
    !bodegaSalida && setBodegaSalidaSelect({ value: "Seleccione Bodega de Salida", label: "Seleccione Bodega de Salida", });
    setArticuloSelect({ value: "Seleccione Artículo", label: "Seleccione Artículo", });
    setCantidad(0);
    setValor(0);
    setArticulo("");
    setListaSeriales([]);
    setObservacion("");
    limpiarRadioBtn();
    setValidarArtySeries(false);
    setVerSeriales(false);
  };

  const añadirAtablas = (item, setLista) => {
    setLista((listaProv) => [...listaProv, item]);
  };

  const manipularCabecerasTabla = (lista, tipo, subTipo) => {
    let cPS = 0, cPD = 0;
    let cBS = 0, cBD = 0;
    lista.forEach((etb) => {
      if (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined) {
        cPS = cPS + 1;
      }
      if (etb.proyectoDestino !== "" && etb.proyectoDestino !== undefined) {
        cPD = cPD + 1;
      }
      if (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) {
        cBS = cBS + 1;
      }
      if (etb.bodegaDestino !== "" && etb.bodegaDestino !== undefined) {
        cBD = cBD + 1;
      }
    });
    setContBodegaSalida(cBS);
    setContProyectoSalida(cPS);
    setContBodegaDestino(cBD);
    setContProyectoDestino(cPD);

  };

  useEffect(() => {
    manipularCabecerasTabla(listaMover);
  }, [listaMover]);

  useEffect(() => {
    manipularCabecerasTabla(listaAsignar);
  }, [listaAsignar]);


  //--------------Varios-------------
  const obtenerFechaActual = () => {
    const currentDate = new Date();
    const offset = -5 * 60; // Offset en minutos (UTC-5)
    const today = new Date(currentDate.getTime() + offset * 60 * 1000).toISOString().split("T")[0];
    return today;
  };

  const handleBlurDatalist = (datalistId, value, setValue) => {
    const datalist = document.getElementById(datalistId);
    const option = Array.from(datalist.options).find(
      (opt) => opt.value === value
    );
    if (!option) {
      setValue("");
    }
  };

  const limpiarRadioBtn = () => {
    setOpcionSelecBoP("");
    setListaBodegas([]);
    setListaProyectos([]);
    setListaUbicaciones([]);
    const radioButtons = document.getElementsByName("opcionSelecBoP");
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
  };

  const traerEmpresa = async () => {
    try {
      const empresaTemp = await consultarDocumentoWhereIgual("empresas", "idEmpresa", emp);
      setDataEmpresa(empresaTemp);
    } catch (error) {
      console.error(error);
    }
  };

  const selectHeader = async () => {
    setListaMover([]);
    setListaAsignar([]);
    resetStates("asignar");
    resetStates("mover");
  };

  //---------Cantidad-------------
  const manejarCantidad = (cantSeries) => {
    if (cantSeries) {
      const listaTempSeriales = [];
      for (let index = 0; index < (cantSeries <= listaSeriales.length ? cantSeries : listaSeriales.length); index++) {
        const element = listaSeriales[index];
        listaTempSeriales.push(element);
      }
    }
  };

  //------------Seriales------------
  const validarSeriales = () => {
    let k = 0;
    if (valoresSeleccionados) {
      valoresSeleccionados?.forEach((usaserial) => {
        if (usaserial !== "") {
          k++;
        }
      });
      if (k === cantidad) {
        toast.success("Puede continuar con el registro");
        setValidarArtySeries(true);
      } else {
        setValidarArtySeries(false);
        toast.warning(
          "La cantidad de usaseriales no coincide con la cantidad de articulos, por favor verifica e intenta nuevamente."
        );
      }
    } else {
      toast.warning("La lista de usaseriales esta vacia");
    }
  };

  const handleSerialChange = (index, value) => {
    setValoresSeleccionados((prevValores) => {
      const nuevosValores = [...prevValores];
      nuevosValores[index] = value;
      return nuevosValores;
    });
  };

  const obtenerListaSeriales = (lista, bodega, ubicacion, proyecto) => {
    let articulosComunes, stock, art;
    let listaSer = [];

    if (bodega !== "" && ubicacion !== "") {
      articulosComunes = lista.filter((item) => item.bodega === bodega && item.ubicacion === ubicacion);
      stock = articulosComunes[0]?.cant_disponible;
      art = articulosComunes[0]?.articulo;
    } else if (proyecto !== "") {
      articulosComunes = lista.filter((item) => item.proyecto === proyecto);
      stock = articulosComunes[0]?.cant_reservada;
      art = articulosComunes[0]?.articulo;
    }

    articulosComunes?.forEach((list) => { listaSer = list.listaSeriales; });
    setSerialesDisponibles(listaSer);
    if (stock === 0) {
      toast.warning("No hay existencia de: " + art + " verifica e intenta de nuevo.");
      resetStates("mover");
      resetStates("asignar");
    }
  };

  const renderListaSeriales = () => {
    if (usaSerial) {
      const selectSerial = document.getElementById("seleccioneSerial");
      if (selectSerial) {
        selectSerial.innerHTML = "";
        if (Array.isArray(serialesDisponibles)) {
          serialesDisponibles.forEach((ls) => {
            let option = document.createElement("option");
            option.value = ls;
            selectSerial.appendChild(option);
          });
        }
      }
    }
  };

  const listaSerialesParaModal = (lista, articulo, fecha) => {
    let inventario = [];

    if (lista === listaMover) {
      inventario = lista?.find((item) => item.articulo === articulo && item.fechaMover === fecha);
    } else {
      inventario = lista?.find((item) => item.articulo === articulo && item.fechaAsignar === fecha);
    }
    if (inventario) {
      return inventario.listaSeriales;
    } else {
      toast.warning("Articulo no encontrado");
      return [];
    }
  };

  useEffect(() => {
    if (
      (articulo !== "" &&
        ((bodegaSalida !== "" && ubicacionSalida !== "") ||
          proyectoSalida !== "")) ||
      proyectoDestino !== ""
    ) {
      renderListaSeriales();
    }
  }, [articulo, bodegaSalida, proyectoSalida, ubicacionSalida, proyectoDestino,]);

  useEffect(() => {
    if (contRenSeriales <= 1) {
      renderListaSeriales();
    }
  }, [contRenSeriales]);

  //---------Mover // Asignar-----------
  const moverA_Bodega = async () => {
    if (listaMover.length === 0) {
      toast.warning("No hay artículos para mover, ingrese un artículo e intente de nuevo");
      return;
    }
    if (window.confirm("Esta seguro de continuar con el movimiento?")) {
      let idDocDestino = "", k = 0, res, resp, listaFusion, data;
      const asignar = [];
      const consec = valorPrefijoMover.toUpperCase() + "-" + valorConsecutivoMover;

      for (const lb of listaMover) {
        let esBodega = lb.bodegaSalida;
        let esProyecto = lb.proyectoSalida;
        const conSerial = lb.usaserial;

        if (conSerial && (lb.listaSeriales.length === 0 || lb.listaSeriales === "" || lb.listaSeriales === undefined)) {
          toast.warning("Debe validar los seriales del artículo");
          return;
        }

        const existeBD = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.bodegaDestino && t.consecutivo.split("\\")[1] === lb.ubicacionDestino &&
          t.consecutivo.split("\\")[2] === lb.consecutivo);
        const existeBS = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.bodegaSalida && t.consecutivo.split("\\")[1] === lb.ubicacionSalida && t.consecutivo.split("\\")[2] === lb.consecutivo);
        const existePS = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.proyectoSalida && t.consecutivo.split("\\")[1] === lb.consecutivo);

        if (esBodega !== "") {
          setIsLoading(true)
          try {
            listaFusion = conSerial ? existeBS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
            res = await modificarArtMovAsig(existeBS, existeBS?.consecutivo, existeBS?.cant_disponible, listaFusion, lb?.cantidad, lb?.valor, "bodega", conSerial);
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
        if (esProyecto !== "") {
          setIsLoading(true)
          try {
            listaFusion = conSerial ? existePS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
            res = await modificarArtMovAsig(existePS, existePS?.consecutivo, existePS?.cant_reservada, listaFusion, lb?.cantidad, lb?.valor, "proyecto", conSerial);
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
        if (res) {
          if (existeBD !== undefined) {
            idDocDestino = existeBD.consecutivo;
            const documento = existeBD.bodega.toLowerCase();
            const list = crearLista(existeBD, "", lb.estado);
            setIsLoading(true)
            try {
              await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, idDocDestino, list);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false)
            }
          }
          const item = {
            id: uniqid("movimiento-"),
            idMov: lb.idMov,
            consecutivo: consec,
            fechaMover: lb.fechaMover,
            cantidad: parseFloat(lb.cantidad),
            valor: parseFloat(lb.valor),
            ...(conSerial && { listaSeriales: lb.listaSeriales }),
            usuario_creacion: user,
            fecha_creacion,
            estado: lb.estado,
          };

          idDocDestino = `${lb.bodegaDestino}\\${lb.ubicacionDestino}\\${lb.consecutivo}`;
          if (existeBS !== undefined && lb && esBodega !== "") {
            data = {
              ...item,
              bodegaSalida: lb.bodegaSalida,
              ubicacionSalida: lb.ubicacionSalida,
              bodegaDestino: lb.bodegaDestino,
              ubicacionDestino: lb.ubicacionDestino,
              idDocSalida: existeBS?.consecutivo,
              idDocDestino: idDocDestino,
              articulo: existeBS?.articulo,
            };
          }

          if (existePS !== undefined && lb && esProyecto !== "") {
            data = {
              ...item,
              proyectoSalida: lb.proyectoSalida,
              bodegaDestino: lb.bodegaDestino,
              ubicacionDestino: lb.ubicacionDestino,
              idDocSalida: existePS?.consecutivo,
              idDocDestino: idDocDestino,
              articulo: existePS?.articulo,
            };
          }


          asignar.push(data);
          k++;
          if (k === listaMover.length) {
            const enviar = {
              [verConsecutivoMover]: asignar,
            };
            setIsLoading(true)
            try {
              resp = await guardarDatabaseId(`${emp}_movimientos`, `${emp}_movimientos_${valorPrefijoMover.toLowerCase()}`, enviar);
              if (resp !== "") {
                await cargarSalidaEnKardex(listaMover, valorPrefijoMover, valorConsecutivoMover);
                await handleModificarConsecutivos(consec, idDocumentoConsecutivoMover, "mover");
                await cargarConsecutivos(consecMover, setlistaConsecutivosMover);
                setListaMover([]);
                resetStates();
                toast.success("El movimiento se han realizado con éxito");
              }
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false)
            }
          }
        } else {
          toast.warning(
            "Error con el movimiento del artículo en el inventario"
          );
        }
      }
    }
  };

  const asignarA_Proyecto = async () => {
    if (listaAsignar.length === 0) {
      toast.warning("No hay artículos para mover, ingrese un artículo e intente de nuevo");
      return;
    }

    if (window.confirm("Esta seguro de continuar con la asignación?")) {
      let idDocDestino = "", k = 0, res, resp, listaFusion, data;
      const asignar = [];
      const consec = valorPrefijoAsignar.toUpperCase() + "-" + valorConsecutivoAsignar;

      for (const lb of listaAsignar) {
        let esBodega = lb.bodegaSalida;
        let esProyecto = lb.proyectoSalida;
        const conSerial = lb.usaserial;

        if (conSerial && (lb.listaSeriales.length === 0 || lb.listaSeriales === "" || lb.listaSeriales === undefined)) {
          toast.warning("Debe validar los seriales del artículo");
          return;
        }

        const existePD = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.proyectoDestino && t.consecutivo.split("\\")[1] === lb.consecutivo);
        const existePS = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.proyectoSalida && t.consecutivo.split("\\")[1] === lb.consecutivo);
        const existeBS = listaInventarioActual.find((t) => t.consecutivo.split("\\")[0] === lb.bodegaSalida && t.consecutivo.split("\\")[1] === lb.ubicacionSalida && t.consecutivo.split("\\")[2] === lb.consecutivo);

        if (esBodega !== "") {
          setIsLoading(true)
          try {
            listaFusion = conSerial ? existeBS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
            res = await modificarArtMovAsig(existeBS, existeBS?.consecutivo, existeBS?.cant_disponible, listaFusion, lb?.cantidad, lb?.valor, "bodega", conSerial);
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }

        }
        if (esProyecto !== "") {
          setIsLoading(true)
          try {
            const listaFusion = conSerial ? existePS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
            res = await modificarArtMovAsig(existePS, existePS?.consecutivo, existePS?.cant_reservada, listaFusion, lb?.cantidad, lb?.valor, "proyecto", conSerial);
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
        if (res) {
          if (existePD !== undefined) {
            idDocDestino = existePD.consecutivo;
            const list = crearLista(existePD, "", lb.estado);
            const documento = "proyectos";
            setIsLoading(true)
            try {
              await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, idDocDestino, list);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false)
            }
          }
          const item = {
            id: uniqid("movimiento-"),
            idMov: lb.idMov,
            consecutivo: consec,
            fechaAsignar: lb.fechaAsignar,
            cantidad: parseFloat(lb.cantidad),
            valor: parseFloat(lb.valor),
            usuario_creacion: user,
            ...(conSerial && { listaSeriales: lb.listaSeriales }),
            fecha_creacion: fecha_creacion,
            estado: lb.estado,
          };

          idDocDestino = `${lb.proyectoDestino}\\${lb.consecutivo}`;
          if (existeBS !== undefined && lb && esBodega !== "") {
            data = {
              ...item,
              bodegaSalida: lb.bodegaSalida,
              ubicacionSalida: lb.ubicacionSalida,
              proyectoDestino: lb.proyectoDestino,
              idDocSalida: existeBS?.consecutivo,
              idDocDestino: idDocDestino,
              articulo: existeBS?.articulo,
            };
          }

          if (existePS !== undefined && lb && esProyecto !== "") {
            data = {
              ...item,
              proyectoSalida: lb.proyectoSalida,
              proyectoDestino: lb.proyectoDestino,
              idDocSalida: existePS?.consecutivo,
              idDocDestino: idDocDestino,
              articulo: existePS?.articulo,
            };
          }


          asignar.push(data);
          k++;
          if (k === listaAsignar.length) {
            const enviar = {
              [verConsecutivoAsignar]: asignar,
            };
            setIsLoading(true)
            try {
              resp = await guardarDatabaseId(`${emp}_movimientos`, `${emp}_movimientos_${valorPrefijoAsignar.toLowerCase()}`, enviar);
              if (resp !== "") {
                await cargarSalidaEnKardex(listaAsignar, valorPrefijoAsignar, valorConsecutivoAsignar);
                await handleModificarConsecutivos(consec, idDocumentoConsecutivoAsignar, "asignar");
                await cargarConsecutivos(consecAsignar, setlistaConsecutivosAsignar);
                setListaAsignar([]);
                resetStates();
                toast.success("La asignación se han realizado con éxito");
              }
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false)
            }
          }
        } else {
          toast.warning(
            "Error con la asignación del artículo en el inventario"
          );
        }
      }
    }
  };

  const resetStates = (accion) => {
    if (accion === "mover") {
      setUbicacionDestino("");
      setBodegaDestino("");
      setFechaMover("");
    } else if (accion === "asignar") {
      setProyectoDestino("");
      setFechaAsignar("");
    }
    setArticuloSelect({
      value: "Seleccione Artículo",
      label: "Seleccione Artículo",
    });
    setBodegaSalidaSelect({
      value: "Seleccione Bodega de Salida",
      label: "Seleccione Bodega de Salida",
    });
    setBodegaDestinoSelect({
      value: "Seleccione Bodega Destino",
      label: "Seleccione Bodega Destino",
    });
    setubicacionSalidaSelect({
      value: "Seleccione Ubicación de Salida",
      label: "Seleccione Ubicación de Salida",
    });
    setubicacionDestinoSelect({
      value: "Seleccione Ubicacion Destino",
      label: "Seleccione Ubicacion Destino",
    });
    setProyectoSalidaSelect({
      value: "Seleccione Proyecto de Salida",
      label: "Seleccione Proyecto de Salida",
    });
    setProyectoDestinoSelect({
      value: "Seleccione Proyecto de Destino",
      label: "Seleccione Proyecto de Destino",
    });
    setUbicacionSalida("");
    setProyectoSalida("");
    setBodegaSalida("");
    setObservacion("");
    setArticulo("");
    setCantidad(0);
    // setValor(0);
    setValoresSeleccionados([]);
    setListaSeriales([]);
    setValidarArtySeries(false);
    setVerSeriales(false);
    setEnProyecto(0);
    setEnBodega(0);
  };

  const traerInfoAVerPDF = async (consec) => {
    let filtro = [];
    const dato = consec.split("-")[0];
    const listaTemporal = kardex
    if (dato === "MOV") {
      filtro = listaTemporal?.filter((a) => a.consecutivo.startsWith("MOV"));
    } else {
      filtro = listaTemporal?.filter((a) => a.consecutivo.startsWith("ASG"));
    }
    procesarDatos(filtro, setListaVerPDF);
  };

  const procesarDatos = (lista, setLista1) => {
    const arreglo = [];
    const arreglo2 = [];
    lista.forEach((element) => {
      Object.entries(element).forEach((e) => {
        if (e[0] !== "consecutivo") {
          const datoTemporal = {
            consecutivo: Object.entries(element).pop()[1],
            ...e[1],
          };
          arreglo.push(datoTemporal);
          const datoTemporalSinDetalle = {
            consecutivo: datoTemporal.consecutivo,
            fecha_creacion: datoTemporal.fecha_creacion,
            articulo: datoTemporal.articulo,
            detalle: datoTemporal,
          };
          arreglo2.push(datoTemporalSinDetalle);
        }
      });
    });
    setLista1(arreglo2);
  };

  //------------Kardex--------------
  const cargarSalidaEnKardex = async (lista, valPref, valConsec) => {
    const consecutivo = valPref.toUpperCase() + "-" + valConsec;
    let id = uniqid("kardex-");
    let cons = null;
    if (lista.length !== 0) {
      for (const key in lista) {
        if (lista.hasOwnProperty(key)) {
          const objetoActual = lista[key];
          cons = objetoActual.idArticulo;
          objetoActual.articulo = cons;
          objetoActual.id = id;
          objetoActual.usuario_creacion = user;
          objetoActual.fecha_creacion = fecha_creacion;

          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
          delete objetoActual.idArticulo;
          objetoActual.proyectoSalida === "" &&
            delete objetoActual.proyectoSalida;
          objetoActual.bodegaSalida === "" && delete objetoActual.bodegaSalida;
          objetoActual.ubicacionSalida === "" &&
            delete objetoActual.ubicacionSalida;
        }
      }
      const enviar = {
        [consecutivo]: lista,
      };
      try {
        let res = await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${valPref.toLowerCase()}`, enviar);
        // if (res !== "") {
        //   traerInfoAVerPDF(consecutivo);
        //   return true;
        // } else {
        //   return false;
        // }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.warning(
        "Error lista vacia, por favor verifica e inetenta de nuevo"
      );
    }
  };

  //--------------Pedidos-------------------
  const crearLista = (existe, observacionPendientes, estado) => {
    return {
      ...existe,
      estado: estado,
      observacion: observacionPendientes ? observacionPendientes : observacion,
      usuario_modificacion: user,
      fecha_modificacion,
    };
  };

  const modificarArtMovAsig = async (lista, consec, cantidadBD, listaFusion, cantidad, valor, sitio, usaSerie) => {
    let cant = parseFloat(cantidadBD) - parseFloat(cantidad);
    cant = Number(Number.parseFloat(cant).toFixed(2));
    const bdg = lista?.bodega?.toLowerCase();
    const documento = sitio === "bodega" ? bdg : "proyectos";
    let ls = {
      ...lista,
      valor: parseFloat(valor),
      fecha_modificacion,
      usuario_modificacion: user,
      estado: "despachado",
    };
    if (usaSerie) {
      ls.listaSeriales = listaFusion;
    }
    if (sitio === "bodega") {
      ls.cant_disponible = cant;
    }
    if (sitio === "proyecto") {
      ls.cant_reservada = cant;
    }
    try {
      const res = await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, consec, ls);
      return res !== "" && res !== undefined ? true : false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const pendientesUsuario = async (tipo, setRechazos, setTransito) => {
    let resultado = listaMovimientos.reduce((acumulador, item) => {
      for (let key in item) {
        if (typeof item[key] === 'object' && item[key] !== null && item.consecutivo.startsWith(tipo)) {
          if (item[key].estado === 'rechazado') {
            acumulador.rechazados++;
          } else if (item[key].estado === 'transito') {
            acumulador.transito++;
          }
        }
      }
      return acumulador;
    }, { rechazados: 0, transito: 0 });
    setRechazos(resultado.rechazados)
    setTransito(resultado.transito)
  };

  useEffect(() => {
    localStorage.setItem("componente", componente);
  }, [componente]);

  //************ Inicio componente ******************/
  return (
    <>
      {isLoading ?
        <LoaderES />
        :
        <>
          <div
            className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : !collapsed ? "250px" : "80px",
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1,
            }}
          ></div>
          <hr className="my-3" />

          {/* Menu header */}
          <ul className="nav nav-tabs mt-4">
            {/* Mover a bodega */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movBodega")}
              onClick={() => {
                setOpcionSeleccionada("mover");
                selectHeader();
                limpiarPasarTablas();
              }}>
              <p className={"nav-link " + (opcionSeleccionada === "mover" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Mover a Bodega</p>
            </li>

            {/* Asignar a Proyecto */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movProyecto")}
              onClick={() => {
                setOpcionSeleccionada("asignar");
                limpiarPasarTablas();
                selectHeader();
              }}>
              <p className={"nav-link " + (opcionSeleccionada === "asignar" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}> Asignar a Proyecto</p>
            </li>

            {/* Mov. y Asig. pendietes */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movYAsigPend")}
              onClick={() => {
                setComponente("mov_pendientes")
                history.push({
                  pathname: "/mov_pendientes",
                  state: {
                    componente: "mov_pendientes",
                    origen: "Movimiento",
                  }
                })
              }}
            > <p className="nav-link "
              style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }} >Movimientos Pendientes <span className="text-danger" > ({pendientesB + pendientesP})</span></p>
            </li>

            {/* Devoluciones pendietes */}
            <li
              className="nav-item user-select-none "
              disabled={!permisos.includes("movDevolPend")}
              onClick={() => {
                setComponente("dev_pendientes")
                history.push({
                  pathname: "/dev_pendientes",
                  state: {
                    componente: "dev_pendientes",
                    origen: "Movimiento",
                  }
                })
              }}
            >
              <p className="nav-link "
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Devoluciones Pendientes <span className="text-danger" > ({rechazosB + rechazosP})</span></p>
            </li>
          </ul>
          <>
            <hr className="text-light mt-2 mb-0" />

            {/******** Mover a bodega *********/}
            {permisos.includes("movBodega") ?
              opcionSeleccionada === "mover" ? (
                <>
                  <div className="conatainer">
                    <div className="row">
                      <h4 className="fs-4">Mover a Bodega</h4>
                    </div>

                    {/* Consecutivo  y fecha mover*/}
                    <div className="row mt-2">
                      {/* consecutivo mover */}
                      <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Consecutivo</span>
                          <input
                            type="text"
                            className={listaConsecutivosMover.length === 0 ? "form-control text-danger" : "form-control"}
                            readOnly
                            value={listaConsecutivosMover.length > 0 ? verConsecutivoMover : "Sin consecutivos disponibles"}
                          />
                        </div>
                      </div>

                      {/* Fecha mover */}
                      <div className="col">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Fecha</span>
                          <input
                            type="date"
                            className="form-control"
                            readOnly
                            value={fechaMover || obtenerFechaActual()}
                            onChange={(e) => setFechaMover(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {listaConsecutivosMover.length > 0 &&
                      <>
                        {/* Select bodega y ubicacion destino mover*/}
                        <h6 className="fs-5 my-3">Datos Destino</h6>
                        <div className="row">
                          {/* Select bodega destino mover */}
                          <div className="col">
                            <Select
                              value={listaBodegasDestino.length > 0 ? bodegaDestinoSelect : {
                                value: "Sin Bodegas disponibles",
                                label: "Sin Bodegas disponibles",
                              }}
                              placeholder={"Bodega destino"}
                              className="mb-2"
                              onChange={(e) => {
                                setBodegaDestino(e.value);
                                setBodegaDestinoSelect({
                                  value: e.value,
                                  label: e.label,
                                });
                              }}
                              options={bodegasDestino}
                              styles={styles}
                              isDisabled={listaMover.length > 0}
                            />
                          </div>
                          {/* Select Ubicacion destino mover */}
                          <div className="col">
                            <Select
                              value={ubicacionDestinoSelect}
                              placeholder={"Ubicación Destino"}
                              className="mb-2"
                              onChange={(e) => {
                                setUbicacionDestino(e.value);
                                setubicacionDestinoSelect({
                                  value: e.value,
                                  label: e.value,
                                });
                              }}
                              options={ubicacionesDestino}
                              styles={styles}
                            // isDisabled={listaMover.length > 0}
                            />
                          </div>
                        </div>
                        {listaBodegasDestino.length > 0 && <>
                          {/* <!-- Text inputs  articulo mover --> */}
                          <div className="row">
                            {/* Articulo mover */}
                            <div className="col">
                              <Select
                                value={
                                  sinArticulos === true
                                    ? {
                                      value: "Sin artículos disponibles",
                                      label: "Sin artículos disponibles",
                                    }
                                    : articuloSelect
                                }
                                placeholder={"Articulo"}
                                className="mb-2"
                                onChange={(option) => {
                                  const art = listaArticulos.find((art) => art.consecutivo === option.value);
                                  setArticuloSelect({ value: art.consecutivo, label: art.des_Larga, });
                                  setConsecArt(art ? art.consecutivo : "");
                                  setValor(art.ult_Costo)
                                  setArticulo(art ? art.des_Larga : "");
                                  setIdArt(art ? art.consecutivo : "");
                                  setUsaSerial(art ? art.serial : false);
                                  setUm(art?.um);
                                  setFechaMover(obtenerFechaActual());
                                  setConsecMov(false);
                                }}
                                options={articulosL}
                                styles={styles}
                                isDisabled={sinArticulos}
                              />
                            </div>
                          </div>
                        </>}
                      </>}
                    {/* Radio Btn bodegas y proeyectos mover */}
                    {articulo !== "" ? (
                      <>
                        <hr className="mt-2 mb-0" />
                        <div className="row mb-3">
                          <h6 className="fs-5 my-2">Datos Origen</h6>
                          <div className="col-sm">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                name="opcionSelecBoP"
                                type="radio"
                                id="inlineBodegasB"
                                value="bodega"
                                disabled={enBodega === 0 || cantActualBodega == 0}
                                onChange={(e) => {
                                  setOpcionSelecBoP(e.target.value);
                                  buscarDataArticulos("bodega");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineBodegasB"
                              >
                                Bodegas
                              </label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input me-2"
                                name="opcionSelecBoP"
                                type="radio"
                                id="inlineProyectosB"
                                value="proyecto"
                                disabled={
                                  enProyecto === 0 || cantActualProyecto === 0
                                }
                                onChange={(e) => {
                                  setOpcionSelecBoP(e.target.value);
                                  buscarDataArticulos("proyecto");
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineProyectosB"
                              >
                                Proyectos
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/*Select bodega y ubicación // proyecto salida mover */}
                    {opcionSelecBoP === "" || articulo == "" ? null : (
                      <>
                        <div className="row">
                          {/* Select bodega y proyectos salida mover*/}
                          <div className="col-sm">
                            {opcionSelecBoP === "" ||
                              opcionSelecBoP === "proyecto" ? null : (
                              <Select
                                value={bodegaSalidaSelect}
                                placeholder={"Bodega de Salida"}
                                className="mb-2"
                                onChange={(e) => {
                                  setBodegaSalida(e.value);
                                  setBodegaSalidaSelect({
                                    value: e.value,
                                    label: e.value,
                                  });
                                }}
                                options={bodegas}
                                styles={styles}
                              />
                            )}

                            {/* Select proyectos salida mover*/}
                            {opcionSelecBoP === "" ||
                              opcionSelecBoP === "bodega" ? null : (
                              <Select
                                className="mb-2"
                                value={proyectoSalidaSelect}
                                placeholder={"Proyecto de Salida"}
                                onChange={(e) => {
                                  setProyectoSalida(e.value);
                                  setProyectoSalidaSelect({
                                    value: e.value,
                                    label: e.value,
                                  });
                                  obtenerListaSeriales(
                                    listaProyectos,
                                    "",
                                    "",
                                    e.value
                                  );
                                }}
                                options={proyectosSalida}
                                styles={styles}
                              />
                            )}
                          </div>

                          {/* Select Ubicacion salida mover */}
                          {opcionSelecBoP === "" ||
                            opcionSelecBoP === "proyecto" ? null : (
                            <div className="col-sm">
                              <Select
                                value={ubicacionSalidaSelect}
                                placeholder={"Ubicación"}
                                className="mb-2"
                                onChange={(e) => {
                                  setUbicacionSalida(e.value);
                                  setubicacionSalidaSelect({
                                    value: e.value,
                                    label: e.value,
                                  });
                                  obtenerListaSeriales(
                                    listaUbicaciones,
                                    bodegaSalida,
                                    e.value,
                                    ""
                                  );
                                }}
                                options={ubicaciones}
                                styles={styles}
                              />
                            </div>
                          )}
                        </div>

                        {/* <!-- Text inputs cantidad y serie  mover--> */}
                        {(bodegaSalida !== "" && ubicacionSalida !== "") ||
                          proyectoSalida !== "" ? (
                          <>
                            <div className="row">
                              {/* Cantidad mover */}
                              <div className="input-group input-group-sm mb-2 ">
                                <span className="input-group-text">
                                  Cantidad ({maxCantidad})
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  min={um === "UND" ? 1 : 0.1}
                                  step={um === "UND" ? 1 : 0.1}
                                  onFocus={() => setCantidad("")}
                                  value={cantidad}
                                  onChange={(e) => {
                                    if (e.target.value <= maxCantidad) {
                                      setCantidad(parseFloat(e.target.value));
                                      manejarCantidad(cantidad);
                                    } else {
                                      setCantidad("");
                                      toast.warning(
                                        "Cantidad no permitida, intenta de nuevo"
                                      );
                                    }
                                  }}
                                />

                                {/* boton cargar series mover*/}
                                {usaSerial ? (
                                  <>
                                    <button
                                      className="btn btn-outline-primary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalSeriales"
                                      title="Agregar usaseriales de los articulos"
                                      disabled={cantidad === 0 || cantidad === ""}
                                      onClick={() => setVerSeriales(false)}
                                    >
                                      Cargar Series
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}

                        {/* Inputs  valor y observacion mover */}
                        <div className="row">
                          {/* valor mover*/}
                          <div className="col-4">
                            <div className="input-group input-group-sm mb-2 ">
                              <span className="input-group-text">Valor</span>
                              <input
                                type="number"
                                className="form-control"
                                value={valor}
                                readOnly
                              />
                              <span className="input-group-text">$</span>
                            </div>
                          </div>
                          {/* Observacion mover*/}
                          <div className="col-sm-8">
                            <textarea
                              placeholder="Observaciones"
                              className="form-control form-control-sm mb-3"
                              rows={1}
                              value={observacion}
                              onChange={(e) => setObservacion(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* Boton pasar a tabla mover*/}
                        {(bodegaSalida !== "" && ubicacionSalida !== "") ||
                          (proyectoSalida !== "" &&
                            cantidad !== "" &&
                            cantidad !== 0) ? (
                          <button
                            className="btn btn-dark  mb-4"
                            onClick={() =>
                              pasarAtablas(
                                listaMover,
                                "mover",
                                setListaMover,
                                idArt
                              )
                            }
                          >
                            Añadir Artículo
                          </button>
                        ) : null}
                      </>
                    )}
                  </div>

                  {listaMover.length > 0 && <hr className="mt-2 mb-0" />}
                  {/* +Tabla de artículos a mover */}
                  {listaMover.length === 0 ? null : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-hover table-striped table-sm">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Fecha</th>
                              <th scope="col">Articulo</th>
                              <th scope="col">Cantidad</th>
                              {contBodegaSalida === 0 ? null : (
                                <>
                                  <th scope="col">Desde Bodega </th>
                                  <th scope="col">Desde Ubicacion</th>
                                </>
                              )}
                              {contProyectoSalida === 0 ? null : (
                                <th scope="col">Desde Proyecto</th>
                              )}
                              {contBodegaDestino === 0 ? null : (
                                <>
                                  <th scope="col">A Bodega</th>
                                  <th scope="col">A Ubicacion</th>
                                </>
                              )}
                              <th scope="col">Valor</th>
                              <th scope="col">Observacion</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="text-center" id="tabla_articulos">
                            {listaMover.map((art, index) => (
                              <tr key={index}>
                                <td>{art.fechaMover}</td>
                                <td>{art.articulo}</td>
                                <td>{art.cantidad}</td>
                                {contBodegaSalida === 0 ? null : (
                                  <>
                                    <td>{art.bodegaSalida}</td>
                                    <td>{art.ubicacionSalida}</td>
                                  </>
                                )}
                                {contProyectoSalida === 0 ? null : (
                                  <td>{art.proyectoSalida}</td>
                                )}
                                {contBodegaDestino === 0 ? null : (
                                  <>
                                    <td>{art.bodegaDestino}</td>
                                    <td>{art.ubicacionDestino}</td>
                                  </>
                                )}
                                <td>
                                  ${Intl.NumberFormat("es-CO").format(art.valor)}
                                </td>
                                <td>{art.observacion}</td>
                                <td>
                                  {!art.usaserial ? null : (
                                    <>
                                      <button
                                        className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalSeriales"
                                        onClick={() => {
                                          setVerSeriales(true);
                                          setVerListaSeriales(
                                            listaSerialesParaModal(
                                              listaMover,
                                              art.articulo,
                                              art.fechaMover
                                            )
                                          );
                                        }}
                                      />
                                    </>
                                  )}

                                  <button
                                    className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                    onClick={() => {
                                      eliminarArticulo(
                                        listaMover,
                                        art.articulo,
                                        art.fechaMover,
                                        fechaMover,
                                        setListaMover
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* -Btn Mover articulos a bodega */}
                      <div className="row mt-4">
                        <div className="container">
                          <button
                            className="btn btn-success"
                            title="Crear Nuevo Artículo"
                            onClick={() => {
                              moverA_Bodega();
                              setConsecMov(verConsecutivoMover);
                              setGuardo(true);
                            }}
                          >
                            Mover A Bodega
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {guardo && listaVerPDF ? (
                    <div className="text-center">
                      <NavLink
                        to={{
                          pathname: `/docpdf`,
                          state: {
                            c: consecMov,
                            d: listaVerPDF,
                            a: "Movimiento",
                            e: dataEmpresa,
                            l: listaArticulos,
                          },
                        }}
                      >
                        <button
                          className="btn btn-outline-primary mt-3 fa-solid fa-print "
                          title="Ver Reporte"
                          onClick={() => {
                            setGuardo(false);
                            setListaVerPDF([]);
                          }}
                        />
                      </NavLink>
                    </div>
                  ) : null} */}
                </>
              ) : null
              : null}

            {/*---------- Asignar A Proyecto ----------------*/}
            {permisos.includes("movProyecto") ?
              opcionSeleccionada === "asignar" ? (
                <>
                  <div className="conatainer">
                    <div className="row">
                      <h4 className="fs-4">Asignar a Proyecto</h4>
                    </div>

                    {/* Consecutivo  y fecha asignar*/}
                    <div className="row mt-2">
                      {/* consecutivo mover */}
                      <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Consecutivo</span>
                          <input
                            type="text"
                            className={listaConsecutivosAsignar.length === 0 ? "form-control text-danger" : "form-control"}
                            readOnly
                            value={listaConsecutivosAsignar.length === 0 ? "Sin consecutivos disponibles" : verConsecutivoAsignar}
                          />
                        </div>
                      </div>

                      {/* Fecha asignar */}
                      <div className="col">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Fecha</span>
                          <input
                            type="date"
                            className="form-control"
                            readOnly
                            value={fechaAsignar || obtenerFechaActual()}
                            onChange={(e) => setFechaAsignar(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {listaConsecutivosAsignar.length > 0 &&
                      <>
                        {/* input proyecto destino asignar */}
                        <h6 className="fs-5 my-3">Datos Destino</h6>
                        <div className="row">
                          <div className="col">
                            <Select
                              value={listaProyectoDestino.length > 0 ? proyectoDestinoSelect :
                                {
                                  value: "Sin proyectos disponibles",
                                  label: "Sin proyectos disponibles",
                                }
                              }
                              className="mb-2"
                              onChange={(e) => {
                                setProyectoDestino(e.value);
                                setProyectoDestinoSelect({
                                  value: e.value,
                                  label: e.label,
                                });
                              }}
                              options={proyectosDestino}
                              styles={styles}
                              isDisabled={listaAsignar.length > 0}
                            />
                          </div>
                        </div>

                        {/* Select articulo asignar */}
                        <div className="row">
                          {/* Articulo asignar */}
                          <div className="col">
                            <Select
                              value={sinArticulos === true ? { value: "Sin artículos disponibles", label: "Sin artículos disponibles", } : articuloSelect}
                              placeholder={"Articulo"}
                              className="mb-2"
                              onChange={(option) => {
                                const art = listaArticulos.find((art) => art.consecutivo === option.value);
                                setIdArt(art ? art.consecutivo : "");
                                setConsecArt(art ? art.consecutivo : "");
                                setUsaSerial(art ? art.serial : false);
                                setArticulo(art ? art.des_Larga : "");
                                setUm(art?.um);
                                setValor(art.ult_Costo)
                                setArticuloSelect({ value: art.consecutivo, label: art.des_Larga, });
                                setFechaAsignar(obtenerFechaActual());
                                setConsecMov(false);
                              }}
                              options={articulosL}
                              styles={styles}
                              isDisabled={sinArticulos}
                            />
                          </div>
                        </div>
                      </>}
                    {articulo === "" ? null : (
                      <>
                        {/* Radio btn bodegas y proeyectos asignar */}
                        <div className="row mb-3">
                          <div className="col-sm">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                name="opcionSelecBoP"
                                type="radio"
                                id="inlineBodegasB"
                                value="bodega"
                                disabled={
                                  enBodega === 0 || cantActualBodega === 0
                                }
                                onChange={(e) => {
                                  setOpcionSelecBoP(e.target.value);
                                  buscarDataArticulos("bodega");
                                }}
                                onClick={() => {
                                  setProyectoSalida("");
                                  setListaSeriales([]);
                                  setCantidad(0);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineBodegasB"
                              >
                                Bodegas
                              </label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input me-2"
                                name="opcionSelecBoP"
                                type="radio"
                                id="inlineProyectosB"
                                value="proyecto"
                                disabled={
                                  enProyecto === 0 || cantActualProyecto === 0
                                }
                                onChange={(e) => {
                                  setOpcionSelecBoP(e.target.value);
                                  buscarDataArticulos("proyecto");
                                }}
                                onClick={() => {
                                  setBodegaSalida("");
                                  setUbicacionSalida("");
                                  setListaSeriales([]);
                                  setCantidad(0);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineProyectosB"
                              >
                                Proyectos
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* Select bodega y ubicación // proyecto salida asignar */}
                    {opcionSelecBoP === "" || articulo === "" ? null : (
                      <>
                        <h6 className="fs-5 my-2">Datos Origen</h6>
                        <div className="row">
                          <div className="col-sm">
                            {opcionSelecBoP === "" ||
                              opcionSelecBoP === "proyecto" ? null : (
                              <Select
                                value={bodegaSalidaSelect}
                                placeholder={"Bodega de Salida"}
                                className="mb-2"
                                onChange={(e) => {
                                  setBodegaSalida(e.value);
                                  setBodegaSalidaSelect({
                                    value: e.value,
                                    label: e.value,
                                  });
                                }}
                                options={bodegas}
                                styles={styles}
                              />
                            )}

                            {opcionSelecBoP === "" ||
                              opcionSelecBoP === "bodega" ? null : (
                              <Select
                                className="mb-2"
                                value={proyectoSalidaSelect}
                                placeholder={"Proyecto de Salida"}
                                onChange={(e) => {
                                  setProyectoSalida(e.value);
                                  setProyectoSalidaSelect({
                                    value: e.value,
                                    label: e.value,
                                  });
                                  obtenerListaSeriales(
                                    listaProyectos,
                                    "",
                                    "",
                                    e.value
                                  );
                                }}
                                options={proyectosSalida}
                                styles={styles}
                              />
                            )}
                          </div>

                          {/* Ubicacion salida asignar */}
                          {opcionSelecBoP === "" ||
                            opcionSelecBoP === "proyecto" ? null : (
                            <>
                              <div className="col-sm">
                                <Select
                                  value={ubicacionSalidaSelect}
                                  placeholder={"Ubicación"}
                                  className="mb-2"
                                  onChange={(e) => {
                                    setUbicacionSalida(e.value);
                                    setubicacionSalidaSelect({
                                      value: e.value,
                                      label: e.value,
                                    });
                                    obtenerListaSeriales(
                                      listaUbicaciones,
                                      bodegaSalida,
                                      e.value,
                                      ""
                                    );
                                  }}
                                  options={ubicaciones}
                                  styles={styles}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        {/* Text inputs cantidad y serie  asignar */}
                        {(bodegaSalida !== "" && ubicacionSalida !== "") ||
                          proyectoSalida !== "" ? (
                          <>
                            <div className="row">
                              {/* Cantidad asignar */}
                              <div className="input-group input-group-sm mb-2 ">
                                <span className="input-group-text">
                                  Cantidad ({maxCantidad})
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  min={um === "UND" ? 1 : 0.1}
                                  step={um === "UND" ? 1 : 0.1}
                                  onFocus={() => setCantidad("")}
                                  value={cantidad}
                                  onChange={(e) => {
                                    if (e.target.value <= maxCantidad) {
                                      setCantidad(parseFloat(e.target.value));
                                      manejarCantidad(cantidad);
                                    } else {
                                      setCantidad("");
                                      toast.warning(
                                        "Cantidad no permitida, intenta de nuevo"
                                      );
                                    }
                                  }}
                                />

                                {/* boton cargar series asignar*/}
                                {usaSerial ? (
                                  <button
                                    className="btn btn-outline-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalSeriales"
                                    title="Agregar usaseriales de los articulos"
                                    disabled={cantidad === 0 || cantidad === ""}
                                    onClick={() => setVerSeriales(false)}
                                  >
                                    Cargar Series
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : null}

                        <hr className="mt-2 mb-0" />

                        {/* <!-- Text inputs  valor y observacion asignar --> */}
                        <div className="row">
                          {/* valor asignar*/}
                          <div className="col-lg-4 col-sm-12">
                            <div className="input-group input-group-sm mb-2 ">
                              <span className="input-group-text">Valor</span>
                              <input
                                type="number"
                                className="form-control"
                                value={valor ? parseFloat(valor) : 0}
                                readOnly
                              />
                              <span className="input-group-text">$</span>
                            </div>
                          </div>

                          {/* Observacion asignar*/}
                          <div className="col-lg-8 col-sm-12 ">
                            <textarea
                              placeholder="Observaciones"
                              className="form-control form-control-sm mb-3"
                              rows={1}
                              value={observacion}
                              onChange={(e) => setObservacion(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* Boton pasar a tabla asignar*/}
                        {proyectoDestino !== "" ? (
                          <button
                            className="btn btn-dark  mb-4"
                            hidden={
                              validarArtySeries || !usaSerial ? false : true
                            }
                            onClick={() =>
                              pasarAtablas(
                                listaAsignar,
                                "asignar",
                                setListaAsignar,
                                idArt
                              )
                            }
                          >
                            Añadir Artículo
                          </button>
                        ) : null}
                      </>
                    )}
                  </div>

                  <hr className="my-3" />

                  {listaAsignar.length === 0 ? null : (
                    <>
                      {/* +Tabla de artículos a asignar */}
                      <div className="table-responsive">
                        <table className="table table-hover table-striped table-sm">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Fecha</th>
                              <th scope="col">Articulo</th>
                              <th scope="col">Cantidad</th>
                              {contProyectoSalida === 0 ? null : (
                                <th scope="col">Desde Proyecto</th>
                              )}
                              {contBodegaSalida === 0 ? null : (
                                <>
                                  <th scope="col">Desde Bodega </th>
                                  <th scope="col">Desde Ubicacion</th>
                                </>
                              )}
                              {contProyectoDestino === 0 ? null : (
                                <th scope="col">A Proyecto</th>
                              )}
                              <th scope="col">Valor</th>
                              <th scope="col">Observacion</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="text-center" id="tabla_articulos">
                            {listaAsignar.map((art, index) => (
                              <tr key={index}>
                                <td>{art.fechaAsignar}</td>
                                <td>{art.articulo}</td>
                                <td>{art.cantidad}</td>
                                {contProyectoSalida === 0 ? null : (
                                  <td>{art.proyectoSalida}</td>
                                )}
                                {contBodegaSalida === 0 ? null : (
                                  <>
                                    <td>{art.bodegaSalida}</td>
                                    <td>{art.ubicacionSalida}</td>
                                  </>
                                )}
                                {contProyectoDestino === 0 ? null : (
                                  <td>{art.proyectoDestino}</td>
                                )}
                                <td>
                                  ${Intl.NumberFormat("es-CO").format(art.valor)}
                                </td>
                                <td>{art.observacion}</td>
                                <td>
                                  {!art.usaserial ? null : (
                                    <button
                                      className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalSeriales"
                                      onClick={() => {
                                        setVerSeriales(true);
                                        setVerListaSeriales(
                                          listaSerialesParaModal(
                                            listaAsignar,
                                            art.articulo,
                                            art.fechaAsignar
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                  <button
                                    className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                    onClick={() => {
                                      eliminarArticulo(
                                        listaAsignar,
                                        art.articulo,
                                        art.fechaAsignar,
                                        fechaAsignar,
                                        setListaAsignar
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* -Btn asignar articulos*/}
                      <div className="row mt-4">
                        <div className="container">
                          <button
                            className="btn btn-success"
                            title="Crear Nuevo Artículo"
                            onClick={() => {
                              asignarA_Proyecto();
                              setConsecMov(verConsecutivoAsignar);
                              setGuardo(true);
                            }}
                          >
                            Asignar A Proyecto
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {guardo && listaVerPDF ? (
                    <div className="text-center">
                      <NavLink
                        to={{
                          pathname: `/docpdf`,
                          state: {
                            c: consecMov,
                            d: listaVerPDF,
                            a: "Asignacion",
                            e: dataEmpresa,
                            l: listaArticulos,
                          },
                        }}
                      >
                        <button
                          className="btn btn-outline-primary  mt-3 fa-solid fa-print "
                          title="Ver Reporte"
                          onClick={() => {
                            setGuardo(false);
                            setListaVerPDF([]);
                          }}
                        />
                      </NavLink>
                    </div>
                  ) : null} */}
                </>
              ) : null
              : null}
          </>

          {/* ------ Modal seriales --------- */}
          <div
            className="modal fade"
            id="modalSeriales"
            tabIndex="-1"
            aria-labelledby="modalSerialesLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-primary text-light">
                  <h1 className="modal-title fs-5" id="modalSerialesLabel">
                    {verSeriales ? "Seriales Añadidos" : "Agregar Seriales"}
                  </h1>
                </div>
                {/* ------ Tabla Seriales ------ */}
                <div className="modal-body">
                  {!verSeriales ? (
                    <>
                      <table className="table table-hover  table-sm">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Articulo</th>
                            <th scope="col">Serial</th>
                          </tr>
                        </thead>
                        <tbody className="text-center" id="tabla">
                          {usaSerial &&
                            Array.from(Array(cantidad || 0), (_, index) => (
                              <tr key={index}>
                                <td>{articulo}</td>
                                <td>
                                  <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    list="seleccioneSerial"
                                    disabled={verSeriales}
                                    onClick={(e) => {
                                      setContRenSeriales(
                                        (prueba) => contRenSeriales + 1
                                      );
                                    }}
                                    onBlur={() =>
                                      handleBlurDatalist(
                                        "seleccioneSerial",
                                        listaSeriales,
                                        setListaSeriales
                                      )
                                    }
                                    // value={listaSeriales[index] || ""}
                                    onChange={(e) =>
                                      handleSerialChange(index, e.target.value)
                                    }
                                  />
                                  <datalist id="seleccioneSerial" />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : verSeriales ? (
                    <>
                      <table className="table table-hover  table-sm">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Lista usaseriales </th>
                          </tr>
                        </thead>
                        <tbody className="text-center" id="tabla">
                          {verListaSeriales?.map((usaserial) => (
                            <tr key={usaserial}>
                              <td>
                                <input
                                  type="text"
                                  readOnly
                                  className="form-control form-control-sm"
                                  value={usaserial}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {verSeriales ? "Continuar" : "Cancelar"}
                  </button>
                  {verSeriales ? null : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => validarSeriales()}
                    >
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>


        </>
      }
    </>
  );
};
