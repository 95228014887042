import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  actualizarImagen,
  eliminarImagen,
  obtenerURLImagen,
  subirImagen,
} from "./../config/firebase"; // Importa las funciones desde el archivo de configuración de Firebase
import { Menu } from "./Menu";


export const Imagen = () => {

  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [rutaEnFirestore, setRutaEnFirestore] = useState("");
  const [imagenURL, setImagenURL] = useState("");

  // Función para manejar la selección de la imagen
  const handleImageSelect = (event) => {
    const image = event.target.files[0];
    if (image) {
      const maxSize = 204800;
      if (image.size > maxSize) {
        toast.warning('Imagen Supera el tamaño permitido')
        event.target.value = ''
      } else {
        setImagenSeleccionada(image);
      }
    }
  };

  // Función para subir la imagen
  const handleSubirImagen = async () => {
    if (imagenSeleccionada && rutaEnFirestore) {
      await subirImagen(rutaEnFirestore, imagenSeleccionada);
      setImagenURL("");
    }
  };

  // Función para eliminar la imagen
  const handleEliminarImagen = async () => {
    if (rutaEnFirestore) {
      await eliminarImagen(rutaEnFirestore);
      setImagenURL("");
    }
  };

  // Función para actualizar la imagen
  const handleActualizarImagen = async () => {
    if (imagenSeleccionada && rutaEnFirestore) {
      await actualizarImagen(rutaEnFirestore, imagenSeleccionada);
      setImagenURL("");
    }
  };

  // Función para obtener la URL de la imagen
  const handleObtenerURLImagen = async () => {
    if (rutaEnFirestore) {
      try {
        const url = await obtenerURLImagen(rutaEnFirestore);
        setImagenURL(url);
      } catch (error) {
        console.error("Error al obtener la URL de la imagen:", error);
      }
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" />

      <div className="wrapper d-flex align-items-stretch mt-3">
        <Menu activo={"crud imagen"} />

        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">URL o dirección en firestore de la imagen</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ruta en Firestore"
                  value={rutaEnFirestore}
                  onChange={(e) => setRutaEnFirestore(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <input type="file" accept="image/*" onChange={handleImageSelect} className="form-control" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <input type="text"
                  disabled
                  className="form-control"
                  placeholder="UPLOAD IMAGE => Se requiere una url y un archivo"
                />
                <button className="btn btn-outline-secondary fa-solid fa-cloud-arrow-up"
                  type="button"
                  onClick={handleSubirImagen} />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <input type="text"
                  disabled
                  className="form-control"
                  placeholder="DELETE IMAGE => Se requiere una url o ruta de la imagen a eliminar"
                />
                <button className="btn btn-outline-secondary fa-solid fa-trash"
                  type="button"
                  onClick={handleEliminarImagen} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <input type="text"
                  disabled
                  className="form-control"
                  placeholder="UPDATE IMAGE => Se requiere una url y un archivo"
                />
                <button className="btn btn-outline-secondary fa-solid fa-pen-nib"
                  type="button"
                  onClick={handleActualizarImagen} />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-group mb-3">
                <input type="text"
                  disabled
                  className="form-control"
                  placeholder="GET IMAGE => Se requiere una url o ruta de la imagen para visualizar"
                />
                <button className="btn btn-outline-secondary fa-solid fa-image"
                  type="button"
                  onClick={handleObtenerURLImagen} />
              </div>
            </div>
          </div>

          {imagenURL && (
            <div className="row text-center" >
              <div className="col">
                <img src={imagenURL} alt="Imagen" className="logo" />
              </div>
            </div>
          )}
        </div>


      </div>
    </>
  );
}
