import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { actualizarDocumentoDatabaseIdCampo, consultarDocumentoWhereIgual } from "../config/firebase";

const LogoutButton = () => {

    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("token")).userName;

    const handleLogout = () => {

        confirmAlert({
            title: "Confirmar",
            message: "¿Estás seguro que deseas cerrar sesión?",
            buttons: [
                {
                    label: "Sí",
                    onClick: () => { deleteActiveSesion() }
                },
                { label: "No" }
            ]
        });
    };

    const deleteActiveSesion = async () => {
        const listaTemporal = await consultarDocumentoWhereIgual(
            "usuarios",
            "usuario",
            user
        );
        if (listaTemporal.length > 0) {
            await actualizarDocumentoDatabaseIdCampo(
                "usuarios",
                listaTemporal[0].idDocumento,
                "activeSesion",
                ""
            );
        }
        localStorage.removeItem("token");
        localStorage.removeItem("componente");
        history.push("/");
    }

    return <div className={"update-var"} onClick={handleLogout}>LogOut</div>
};

export default LogoutButton;