import bcrypt from "bcryptjs";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";

import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  consultarDocumentoWhereIgualY,
  createUser,
  guardarDatabase
} from "../../config/firebase";

import { changeTitle, convertirDate, handleColum, isMobile, styles } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";

export const Usuarios = ({ terceros, permisos }) => {

  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaEmpleadosAct, setListaEmpleadosAct] = useState([]);
  const [listaEmpleados, setListaEmpleados] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);

  const [idDocumento, setIdDocumento] = useState("");
  const [estado, setEstado] = useState("ACTIVO");
  const [telefono, setTelefono] = useState("");
  const [empleado, setEmpleado] = useState("");
  const [usuario, setUsuario] = useState("");
  const [correo, setCorreo] = useState("");
  const [rol, setRol] = useState("");
  const [id, setId] = useState("");

  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;
  const usuario_modificacion = user;
  const usuario_creacion = user;

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [componente, setComponente] = useState(localStorage.getItem("componente") || "Usuarios");
  const [empleados, setEmpleados] = useState(listaEmpleados);
  const [conteoInicial, setConteoInicial] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [adicionar, setAdicionar] = useState(0);
  const [mobile, setMobile] = useState(true);
  const tablaRef = useRef(null);


  //************Funciones*****************
  useEffect(() => {
    changeTitle("Admin. Usuarios");
    cargaInicial()
  }, []);


  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8], user, "user");
    if (conteoInicial === 0) {
      cargarUsuarios()
      cargarYValidarEmpleados();
      setConteoInicial(conteoInicial + 1)
    };
    isMobile(setMobile);
  };

  useEffect(() => {
    cargarYValidarEmpleados()
  }, [terceros]);

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);

  useEffect(() => {
    localStorage.setItem("componente", componente);
  }, [componente]);


  //-----------CRUD--------------
  const cargarUsuarios = async () => {
    setIsLoading(true);
    let usuarios;
    try {
      const listaTempUsuarios = await consultarDocumentoWhereIgualY("usuarios", "empresa", emp, "rol", "SUPERADMIN");
      if (listaTempUsuarios.length === 0) {
        usuarios = [{ empleado: "Sin Datos" }]
      } else {
        usuarios = listaTempUsuarios.sort(sortBy("empleado"));
      }
      setListaUsuarios(usuarios);
      setDocumentosFiltrados(usuarios);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  };

  const handleClickAdicionar = async () => {
    const passEmpleado = bcrypt.hashSync("123456", 10);
    const passAdmin = bcrypt.hashSync("123456", 10);//P@55w0rd!

    const respuesta = listaUsuarios.find((u) => u.correo === correo && u.empleado === empleado)
    const ter = listaEmpleados.find((e) => e.email === correo && e.razonSocial === empleado)
    if (respuesta !== undefined) {
      resetStates();
      toast.info(`El usuario con correo: ${correo.toUpperCase()} ya se encuentra registrado, intente de nuevo.`);
    } else {
      const llave = rol === "ADMIN" ? passAdmin : passEmpleado;
      const enviarUsuario = {
        id: uniqid("usuario-"), empleado, usuario, correo, estado, rol,
        telefono, clave: llave, cambioClave: true, usuario_creacion, fecha_creacion, empresa: emp,
      };

      if (empleado === "" || correo === "" || estado === "" ||
        rol === "" || telefono === "" || usuario === "") {
        toast.warning("Diligencie todos los campos de manera correcta para completar el registro");
      } else {
        let resp = await createUser(correo);
        if (resp.code !== "auth/email-already-in-use") {
          setIsLoading(true)
          try {
            await guardarDatabase("usuarios", enviarUsuario);
            await ModificarEsUsuario(ter);
            resetStates();
            toast.success("Usuario registrado de manera correcta");
            await cargarUsuarios();
          } catch (error) {
            console.error("Error al adicionar usuario:", error);
          } finally {
            setIsLoading(false)
          }
        } else if (resp.code === "auth/email-already-in-use") {
          toast.warning(`FireBase: El correo ${correo} ya se encuentra registrado, verifique e intente nuevamente`)
        } else {
          toast.warning(`FireBase: A ocurrido un error con el registro, intente nuevamente o pongase en contacto con el desarrollador`);
        }
      }
    }
  };

  const ModificarEsUsuario = async (res) => {
    let id = res.consecutivo
    const obj = {
      ...res,
      esUsuario: true
    }
    setIsLoading(true)
    try {
      await actualizarDocumentoDatabaseIdCampo(`${emp}_terceros`, `${emp}_terceros`, id, obj);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  };

  const handleClickTraerData = (u) => {
    setIdDocumento(u.idDocumento);
    setEmpleado(u.empleado);
    setCorreo(u.correo);
    setRol(u.rol);
    setTelefono(u.telefono);
    setEstado(u.estado);
    setUsuario(u.usuario);
    setId(u.id);
  };

  const handleClickModificarBd = async () => {
    if (idDocumento && idDocumento !== "") {
      const enviarUsuario = {
        usuario, telefono, estado, usuario_modificacion, fecha_modificacion, id,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabase("usuarios", idDocumento, enviarUsuario);
        toast.success("Usuario modificado correctamente");
        await cargarUsuarios();
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("Seleccione un usuario valido para modificarlo");
    }
  };

  const resetStates = () => {
    setEmpleado("");
    setCorreo("");
    setTelefono("");
    setRol("");
    setEstado("");
    setUsuario("");
  };

  //-----------Varios-----------
  const cargarYValidarEmpleados = async () => {
    const tipo = "EMPLEADO";
    const estado = "ACTIVO";
    const usuario = false;
    try {
      const listaTemporal = terceros;
      const activos = listaTemporal.filter((act) => act.estado === estado && act.tipo === tipo && act.esUsuario === usuario);
      setListaEmpleados(listaTemporal);
      setListaEmpleadosAct(activos);
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    const options = listaEmpleadosAct.map(cliente => ({ value: cliente.razonSocial, label: cliente.razonSocial }));
    setEmpleados(options?.sort(sortBy("value")))
  }, [listaEmpleadosAct])

  const permisoCambioClave = async (id) => {
    const valor = {
      cambioClave: true,
    };
    setIsLoading(true)
    try {
      await actualizarDocumentoDatabase("usuarios", id, valor);
      toast.success("Permiso otorgado exitosamente. Comuníqueselo al usuario.");
      await cargarUsuarios();
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  };

  //---------Columnas-------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaUsuarios(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //-------------- Paginación --------------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas]);




  //-------------- Inicio componente --------------
  return (
    <>
      <ToastContainer position="bottom-right" />

      {isLoading ?
        <LoaderES />
        : <>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            <div className="row ms-2 me-2">
              {/* botones de opcion */}
              <div className="col-sm-4 align-self-start text-start">
                <div className="btn-group">

                  {/* Adicionar */}
                  <button
                    className="btn fa-solid fa-plus"
                    disabled={!permisos.includes("crearUser")}
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarAdicionar"
                    id="modaladicionar"
                    title="Crear Nuevo Usuario"
                    onClick={() => setAdicionar(1)} />

                  {/* Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                  {/* Actualizar */}
                  <button
                    className="btn fa-solid fa-rotate"
                    title="Actualizar Datos"
                    onClick={() => cargarUsuarios()} />
                </div>
              </div>

              {/* filtrar */}
              <div className="col-sm-8">
                <Filtrar2
                  tabla="myTable"
                  documentos={listaUsuarios}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={" mt-5 " + (mobile === true ? " mb-5" : "")} />

          {permisos.includes("verUser") ?
            mobile ?
              /* Mobile */
              <>
                {registrosPaginaActual.map((usuario) => (
                  usuario.empleado === "Sin Datos" ?
                    <button
                      className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                      disabled={!permisos.includes("crearUser")}
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarAdicionar"
                      id="modaladicionar"
                      title="Crear Nuevo Usuario"
                      onClick={() => setAdicionar(1)} />
                    :
                    <div key={usuario.id}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr key={usuario.id}>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + usuario.id}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none" }}>{usuario.empresa}:</th>
                            <td style={{ minWidth: "200px" }} className={usuario.cambioClave ? "text-warning" : ""}>{usuario.empleado}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + usuario.id}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody>
                              {!col2 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}> Ult. Ingreso</th>
                                  <td>{convertirDate(usuario.lastActiveSesion)}</td>
                                </tr>
                              }
                              {!col3 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Empleado</th>
                                  <td >{usuario.empleado}</td>
                                </tr>
                              }
                              {!col4 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Usuario</th>
                                  <td title={!usuario.cambioClave ? "" : "Tiene pendiente el cambio de clave"}
                                    className={usuario.cambioClave ? "text-warning" : ""}>
                                    {!usuario.cambioClave ? usuario.usuario : usuario.usuario + " * "}
                                  </td>
                                </tr>
                              }

                              {!col5 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Correo</th>
                                  <td>{usuario.correo}</td>
                                </tr>
                              }

                              {!col6 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Telefono</th>
                                  <td>{usuario.telefono}</td>
                                </tr>
                              }

                              {!col7 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Rol</th>
                                  <td>{usuario.rol}</td>
                                </tr>
                              }

                              {!col8 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td className={(usuario.estado !== "ACTIVO" && col6) ? "text-danger" : ""}>{usuario.estado}</td>
                                </tr>
                              }

                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Actions </th>
                                <td>
                                  <div className="btn-group">

                                    {/* Btn Password */}
                                    {!usuario.cambioClave ?
                                      <button
                                        className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole border-0"
                                        disabled={!permisos.includes("cambioClaveUser")}
                                        data-bs-toggle="modal"
                                        data-bs-target="#pass"
                                        id="modalPass"
                                        title="Cambiar Contarseña"
                                        onClick={() => handleClickTraerData(usuario)} />
                                      : null}

                                    {/* Btn Modificar */}
                                    <button
                                      className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                      disabled={!permisos.includes("editarUser")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#ModificarAdicionar"
                                      id="modalModificar"
                                      title="Modificar Item Seleccionado"
                                      onClick={() => {
                                        handleClickTraerData(usuario);
                                        setAdicionar(0);
                                      }} />

                                    {/* Btn security */}
                                    <button className="btn btn-outline-warning btn-sm fa-solid fa-shield-halved border-0"
                                      disabled={!permisos.includes("permisosUser")}
                                      title="Persmisos de Usuario"
                                      onClick={() => {
                                        setComponente("usuariopermiso")
                                        history.push({ pathname: "/usuariopermiso", state: { componente: "usuariopermiso", userP: usuario.id, origen: "Usuarios" } })
                                      }} />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                ))}
              </>
              /* Mobile */
              :
              /* Desktop */
              <div className="table-responsive abs-center-table ms-3">
                {/* +Tabla de Usuarios */}
                <table
                  id="myTable"
                  ref={tablaRef}
                  className="table table-hover table-striped table-sm table-bordered mt-3">
                  <thead className="text-center">
                    <tr>
                      <th onClick={() => ordenar("empleado")} className={col2 ? "" : "d-none"}>Ult. Ingreso</th>
                      <th onClick={() => ordenar("empleado")} className={col3 ? "" : "d-none"}>Empleado</th>
                      <th onClick={() => ordenar("usuario")} className={col4 ? "" : "d-none"}>Usuario</th>
                      <th onClick={() => ordenar("correo")} className={col5 ? "" : "d-none"}>Correo</th>
                      <th onClick={() => ordenar("telefono")} className={col6 ? "" : "d-none"}>Telefono</th>
                      <th onClick={() => ordenar("rol")} className={col7 ? "" : "d-none"}>Rol</th>
                      <th onClick={() => ordenar("estado")} className={col8 ? "" : "d-none"}>Estado</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_usuarios">
                    {registrosPaginaActual.map((usuario) => (
                      usuario.empleado === "Sin Datos" ?
                        <tr>
                          <td colSpan={"100%"} className="text-center">
                            <button
                              className="btn fa-solid fa-plus"
                              disabled={!permisos.includes("crearUser")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modaladicionar"
                              title="Crear Nuevo Usuario"
                              onClick={() => setAdicionar(1)} />
                          </td>
                        </tr>
                        :
                        <tr key={usuario.id}>
                          <td className={col2 ? "" : "d-none"}>{convertirDate(usuario.lastActiveSesion)}</td>
                          <td className={col3 ? "" : "d-none"}>{usuario.empleado}</td>

                          <td title={!usuario.cambioClave ? "" : "Tiene pendiente el cambio de clave"}
                            className={(usuario.cambioClave && col4) ? "text-warning" : (!usuario.cambioClave && !col4) ? "d-none" : (col4) ? "" : "d-none"}> {!usuario.cambioClave ? usuario.usuario : usuario.usuario + " * "}
                          </td>
                          <td className={col5 ? "" : "d-none"}>{usuario.correo.toUpperCase()}</td>
                          <td className={col6 ? "" : "d-none"}>{usuario.telefono}</td>
                          <td className={col7 ? "" : "d-none"}>{usuario.rol}</td>
                          <td className={(usuario.estado !== "ACTIVO" && col8) ? "text-danger" : (!usuario.estado && !col8) ? "d-none" : (col8) ? "" : "d-none"}>{usuario.estado}</td>

                          <td className="btn-group text-center d-block" style={{ borderRadius: "0" }}>
                            {/* Btn security */}
                            <button className="btn btn-outline-warning btn-sm fa-solid fa-shield-halved"
                              disabled={!permisos.includes("permisosUser")}
                              title="Persmisos de Usuario"
                              onClick={() => {
                                setComponente("usuariopermiso")
                                history.push({ pathname: "/usuariopermiso", state: { componente: "usuariopermiso", userP: usuario.id, origen: "Usuarios" } })
                              }} />

                            {/* +Btn modificar */}
                            <button className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                              disabled={!permisos.includes("editarUser")}

                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modalModificar"
                              title="Modificar Item Seleccionado"
                              onClick={() => {
                                handleClickTraerData(usuario);
                                setAdicionar(0);
                              }} />

                            {/* +Btn passw */}
                            {!usuario.cambioClave ?
                              <button className="btn btn-outline-info btn-sm fa-solid fa-unlock-keyhole"
                                disabled={!permisos.includes("cambioClaveUser")}
                                data-bs-toggle="modal"
                                data-bs-target="#pass"
                                id="modalPass"
                                title="Cambiar Contarseña"
                                onClick={() => handleClickTraerData(usuario)} />
                              : null}

                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            /* Desktop */
            : null}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados}
          />

          {/* +Modal de Modificar // Adicionar*/}
          <div className="modal fade"
            aria-hidden="true"
            id="ModificarAdicionar"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title">
                    {adicionar === 1 ? "Crear " : "Modificar "} Usuario
                  </h5>
                </div>

                <div className="modal-body">
                  {/* Empleado */}
                  {adicionar === 0 ?
                    (<div className="input-group input-group-sm mb-2">
                      <span className="input-group-text">Empleado:</span>
                      <input type="text"
                        disabled
                        className="form-control"
                        value={empleado}
                      />
                    </div>)
                    : listaEmpleadosAct.length > 0 ?
                      (<Select
                        className="mb-2"
                        placeholder="Seleccione Empleado"
                        value={empleado.value}
                        onChange={(op) => {
                          const emp = listaEmpleados.find((emp) => emp.razonSocial === op.label);
                          setEmpleado(op.value);
                          setCorreo(emp ? emp.email : "");
                          setTelefono(emp ? emp.telefono : "");
                        }}
                        options={empleados}
                        styles={styles} />
                      )
                      : (<div className="input-group input-group-sm mb-2">
                        <span className="input-group-text">Empleado:</span>
                        <input type="text"
                          readOnly
                          className="form-control text-danger"
                          value={"No hay empleados disponibles"} />
                      </div>)}

                  {/* rol */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Rol:</span>
                    <select
                      className="form-select"
                      placeholder="Rol"
                      disabled={!adicionar}
                      value={rol}
                      onChange={(e) => setRol(e.target.value)}>
                      <option value="" disabled>Seleccione </option>
                      <option value="ADMIN">Admin</option>
                      <option value="EMPLEADO">Empleado</option>
                    </select>
                  </div>

                  {/* usuario */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Usuario:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Usuario"}
                      value={usuario}
                      onChange={(e) => setUsuario(e.target.value.toUpperCase())} />
                  </div>

                  {/* correo */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Correo:</span>
                    <input
                      disabled={!adicionar}
                      type="email"
                      className="form-control"
                      placeholder="Correo"
                      value={correo} />
                  </div>

                  {/* telefono */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Teléfono:</span>
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Teléfono"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)} />
                  </div>

                  {/* estado */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Estado:</span>
                    <select
                      className="form-select"
                      placeholder="Estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)} >
                      <option value="" disabled>Seleccione</option>
                      <option value="ACTIVO">Activo</option>
                      <option value="INACTIVO">Inactivo</option>
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  {listaEmpleadosAct.length > 0 || !adicionar ?
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() =>
                        adicionar === 1
                          ? handleClickAdicionar()
                          : handleClickModificarBd()
                      }>
                      {adicionar === 1 ? "Crear" : "Modificar"}
                    </button>
                    : null}

                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => resetStates()}>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal de Modificar // Adicionar */}


          {/* +Modal permiso cambio clave */}
          <div className="modal fade"
            aria-hidden="true"
            id="pass"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false">

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-info text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Permiso Para Cambio De Contraseña
                  </h5>
                </div>

                <div className="modal-body">
                  <p> Desea permitir que el usuario: <strong>{usuario}</strong> <br /> pueda cambiar su clave?</p>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-info text-light"
                    data-bs-dismiss="modal"
                    id="btnEliminarArticulo"
                    onClick={() => permisoCambioClave(idDocumento)}>
                    Sí, Permitir
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal" >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal permiso cambio clave */}

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Ult. Ingreso"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Empleado"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Usuario"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Correo "} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Teléfono"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Rol"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="user" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal" >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal Columnas */}
        </>}

    </>
  );
};
