import React from 'react'

export const ThTablaH = ({ ordenar, dOrdenar, columna, titulo }) => {
    return (
        <>
            <th onClick={() => ordenar(dOrdenar)} className={columna ? "user-select-none" : "d-none"}>{titulo}</th>

        </>
    )
}
