import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import sortBy from 'sort-by';
import { guardarDatabaseId } from '../../config/firebase';
import { changeTitle, traerTabla } from '../../config/functions/funGenerales';
import { LoaderES } from '../LoaderES';


export const CambioURL = ({ articulos }) => {

    const emp = JSON.parse(localStorage.getItem("token")).empresa;
    const rutaArticulos = "gs://mn-app-60991.appspot.com/" + emp + "/articulos";

    const [listaArticulos, setListaArticulos] = useState([]);
    const [listaPrefijos, setListaPrefijos] = useState([]);
    const [lisPref, setLisPref] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [prefijo, setPrefijo] = useState("");

    //******************************** */
    useEffect(() => {
        changeTitle("Admin. CambioURL");
        getTablas();
        setListaArticulos(articulos)
    }, []);

    useEffect(() => {
        setListaArticulos(articulos)
    }, [articulos])

    const getTablas = async () => {
        const tempConsec = await traerTabla(emp, "consecutivos");
        setListaPrefijos(tempConsec);
    };

    useEffect(() => {
        let filtro = [];
        const clase = "ARTICULO";
        filtro = listaPrefijos.filter((a) => a.clase === clase);
        const prefijos = filtro.map((p) => p.prefijo);
        const options = prefijos.map(p => ({ value: p, label: p }));
        setLisPref(options?.sort(sortBy("value")));
    }, [listaPrefijos]);

    const getDocumentos = async (prefijo) => {
        if (listaArticulos.length > 0) {
            const temp = listaArticulos.filter((a) => a.consecutivo.startsWith(prefijo))
            return temp;
        }
    };

    const modificarURL = async () => {
        const prf = prefijo.toLowerCase()
        const data = await getDocumentos(prefijo)
        const nuevoArreglo = data?.map(objeto => {
            let consec = objeto.consecutivo
            return { ...objeto, imagen: `${rutaArticulos}/${consec}` };
        });
        setIsLoading(true)
        try {
            if (nuevoArreglo.length > 0) {
                const objeto = nuevoArreglo.reduce((obj, item) => {
                    obj[item.consecutivo] = item;
                    return obj;
                }, {});
                await guardarDatabaseId(`${emp}_articulos`, `${emp}_articulos_${prf}`, objeto);
                toast.success("Cambio de URL's completada con exito")

            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
        resetStates()
    };

    const resetStates = () => {
        setPrefijo("");
    };

    const selectOptions = (list) => {
        return (
            list.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))
        )
    };

    //******************************** */

    return (
        <>
            {isLoading
                ? <LoaderES />
                :
                <>
                    <div className="container mt-5">
                        <h3 className="my-4 ">Cambio masivo URL</h3>


                        <div className="input-group mb-3 ">
                            <span className="input-group-text" >Prefijo</span>
                            <select
                                className="form-control"
                                value={prefijo}
                                onChange={(e) => setPrefijo(e.target.value)}>

                                <option value="" disabled>Seleccione</option>
                                {selectOptions(lisPref)}
                            </select>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text">Url</span>
                            <input type="text"
                                className="form-control"
                                readOnly
                                value={rutaArticulos}
                            />
                        </div>

                        <div className="row justify-content-center mt-5">
                            <button className=" w-25 btn btn-md btn-primary"
                                onClick={modificarURL}
                            >
                                Cambiar URL
                            </button>
                        </div>

                    </div>
                </>
            }
        </>
    )
};
