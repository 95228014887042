import { consultarDocumentoWhereIgual } from "../firebase";



export const procesarDatos = (listaAux, listaArticulos, setLista1, setLista2) => {
    let lista = [];
    if (listaAux[0]?.consecutivo.split('-')[0] === 'SOL') {
        let arrayModificado = listaAux?.map(item => {
            let { consecutivo, ...resto } = item;
            let nuevoItem = {};
            for (let key in resto) {
                if (!isNaN(key)) {
                    nuevoItem[key] = resto[key];
                }
            }
            nuevoItem.consecutivo = consecutivo;
            return nuevoItem;
        });
        lista = arrayModificado
    } else {
        lista = listaAux
    }
    const arreglo = [], arreglo2 = [];
    let datosArt, desLargaArt;
    lista?.forEach(element => {
        Object.entries(element).forEach(e => {
            if (e[0] !== "consecutivo") {
                const datoTemporal = { consecutivo: Object.entries(element).pop()[1], ...e[1] }
                arreglo.push(datoTemporal)
                if (datoTemporal.articulo !== undefined) {
                    let articulos = datoTemporal.articulo.split(',');
                    datosArt = articulos;
                    const articuloEncontrado = listaArticulos?.find(art => art.consecutivo === articulos[0]);
                    desLargaArt = articuloEncontrado ? articuloEncontrado.des_Larga : '';
                }
                const datoTemporalSinDetalle = {
                    consecutivo: datoTemporal.consecutivo,
                    fecha_creacion: datoTemporal.fecha_creacion,
                    articulo: datosArt,
                    descArt: desLargaArt,
                    detalle: datoTemporal
                }
                arreglo2.push(datoTemporalSinDetalle)
            }
        })
    });
    const nuevoArreglo = arreglo2.reduce((resultado, elemento) => {
        const encontrado = resultado.find(item => item.consecutivo === elemento.consecutivo);
        if (encontrado) {
            encontrado.articulo = [...encontrado.articulo, ...elemento.articulo];
            encontrado.descArt = elemento.descArt;
        } else {
            resultado.push({ consecutivo: elemento.consecutivo, articulo: elemento.articulo, descArt: elemento.descArt, fecha_creacion: elemento.fecha_creacion, detalle: elemento.detalle });
        }
        return resultado;
    }, []);
    setLista1(nuevoArreglo);
    setLista2(arreglo2);
};

export const crearGruposConsec = (lista, setGrupo) => {
    const groupedItems = lista?.reduce((groups, item) => {
        const prefix = item.split('-')[0];
        if (!groups[prefix]) {
            groups[prefix] = [];
        }
        groups[prefix].push(item);
        return groups;
    }, {});
    setGrupo(groupedItems)
};

export const traerEmpresa = async (empresa, setDataEmpresa) => {
    const empresaTemp = await consultarDocumentoWhereIgual("empresas", "idEmpresa", empresa)
    setDataEmpresa(empresaTemp)
};



