import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import {
  actualizarDocumentoDatabaseIdCampo,
  guardarDatabaseId
} from "../../config/firebase";
import { cargarPermisos, changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";

export const Terceros = ({ terceros,permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaTerceros, setListaTerceros] = useState([]);

  const [razonSocial, setRazonSocial] = useState("");
  const [esUsuario, setEsUsuario] = useState(false);
  const [asignado, setAsignado] = useState(false);
  const [estado, setEstado] = useState("ACTIVO");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [email, setEmail] = useState("");
  const [tipo, setTipo] = useState("");
  const [nit, setNit] = useState("");
  const [id, setId] = useState("");

  const [fecha_creacion, setFecha_creacion] = useState(fechaFormateada);
  const [usuario_creacion, setUsuario_creacion] = useState(user);
  const usuario_modificacion = user;
  const fecha_modificacion = fechaFormateada;

  const [adicionar, setAdicionar] = useState(0);
  const tablaRef = useRef(null);

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0)

  const [countOrdenar, setCountOrdenar] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true)

  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Terceros");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9], user, "terceros");
    cargarTerceros();
    isMobile(setMobile);
  };

  useEffect(() => {
    cargarTerceros()
  }, [terceros]);

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);


  //---------CRUD---------------------
  const cargarTerceros = async () => {
    setIsLoading(true);
    let temporal;
    try {
      const listaTempTerceros = terceros;
      if (listaTempTerceros.length === 0) {
        temporal = [{ nit: "Sin Datos" }];
      } else {
        temporal = listaTempTerceros.sort(sortBy("razonSocial"));
      }
      setListaTerceros(temporal);
      setDocumentosFiltrados(temporal);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdicionar = async () => {
    const existe = listaTerceros.find((item) => item.razonSocial === razonSocial || item.nit === nit || item.email === email);

    if (existe) {
      if (existe.razonSocial === razonSocial && existe.nit === nit) {
        toast.info(`La razón social: ${razonSocial} y el NIT: ${nit} ya se encuentran registrados, intente de nuevo.`);
      }
      if (existe.razonSocial === razonSocial) {
        toast.info(`La razón social: ${razonSocial} ya se encuentra registrada, intente de nuevo.`);
      }
      if (existe.nit === nit) {
        toast.info(`El NIT: ${nit} ya se encuentra registrado, intente de nuevo.`);
      }
      if (existe.email === email) {
        toast.info(`El correo: ${email} ya se encuentra registrado, intente de nuevo.`);
      }
      resetStates();
    } else {
      if ([razonSocial, nit, direccion, telefono, email, tipo, ciudad, estado].includes("")) {
        toast.warning("Diligencie todos los campos de manera correcta");
      } else {
        const enviarTercero = {
          id: uniqid("tercero-"), nit, razonSocial, direccion, ciudad, telefono, email, tipo,
          estado, asignado: false, esUsuario: false, usuario_creacion, fecha_creacion,
        };
        const enviar = { [nit]: enviarTercero };
        setIsLoading(true)
        try {
          await guardarDatabaseId(`${emp}_terceros`, `${emp}_terceros`, enviar);
          toast.success("Tercero agregado de manera correcta");
          resetStates();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = (n) => {
    setId(n.id);
    setRazonSocial(n.razonSocial);
    setNit(n.nit);
    setTelefono(n.telefono);
    setDireccion(n.direccion);
    setCiudad(n.ciudad);
    setEmail(n.email);
    setTipo(n.tipo);
    setEstado(n.estado);
    setAsignado(n.asignado);
    setEsUsuario(n.esUsuario);
    setUsuario_creacion(n.usuario_creacion);
    setFecha_creacion(n.fecha_creacion);
  };

  const handleClickModificarBd = async () => {
    if (nit && nit !== "") {
      const enviarTercero = {
        id, razonSocial, nit, telefono, direccion, ciudad, email, tipo, estado, asignado,
        esUsuario, usuario_modificacion, fecha_modificacion, usuario_creacion, fecha_creacion,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_terceros`, `${emp}_terceros`, nit, enviarTercero);
        toast.success("Tercero modificado correctamente");
        resetStates();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("Seleccione un tercero valido para modificarlo");
    }
  };

  const resetStates = () => {
    setId("");
    setRazonSocial("");
    setNit("");
    setTelefono("");
    setDireccion("");
    setCiudad("");
    setEmail("");
    setTipo("");
    setEstado("");
    setAsignado(false);
    setEsUsuario(false);
  };


  //------------ Paginación ------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    if (col9 && excluido !== 'col9') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaTerceros(temporal);
    setCountOrdenar(countOrdenar + 1)
  };


  //-------- Paginacion ------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas]);


  useEffect(() => {
    setCol2(localStorage.getItem(user + "/terceros/2") === "false" ? false : true);
    setCol3(localStorage.getItem(user + "/terceros/3") === "false" ? false : true);
    setCol4(localStorage.getItem(user + "/terceros/4") === "false" ? false : true);
    setCol5(localStorage.getItem(user + "/terceros/5") === "false" ? false : true);
    setCol6(localStorage.getItem(user + "/terceros/6") === "false" ? false : true);
    setCol7(localStorage.getItem(user + "/terceros/7") === "false" ? false : true);
    setCol8(localStorage.getItem(user + "/terceros/8") === "false" ? false : true);
  }, []);


  //----------------------------------
  return (
    <>
      <ToastContainer position="bottom-right" />

      {isLoading ?
        <LoaderES />
        :
        <>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            <div className="row ms-2 me-2">
              {/* botones de opcion */}
              <div className="col-sm-4 align-self-start text-start">
                <div className="btn-group">

                  {/* Adicionar */}
                  <button
                    className="btn fa-solid fa-plus"
                    disabled={!permisos.includes("crearTerc")}
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarAdicionar"
                    id="modaladicionar"
                    title="Crear Tercero"
                    onClick={() => setAdicionar(1)} />

                  {/* Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                </div>
              </div>

              {/* filtrar */}
              <div className="col-sm-8">
                <Filtrar2
                  tabla="myTable"
                  documentos={listaTerceros}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={" mt-5 " + (mobile === true ? " mb-5" : "")} />

          {permisos.includes("verTerc") ?
            mobile ?
              /* Mobile */
              <>
                {registrosPaginaActual.map((tercero) => (
                  tercero.nit === "Sin Datos" ?
                    <button
                      className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                      disabled={!permisos.includes("crearTerc")}
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarAdicionar"
                      id="modaladicionar"
                      title="Crear Nuevo Usuario"
                      onClick={() => setAdicionar(1)} />
                    :
                    <div key={tercero.consecutivo}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + tercero.consecutivo}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none", width: "90px" }}>Razon Social:</th>
                            <td className={tercero.estado !== "ACTIVO" ? "text-danger text-start" : "text-start"} style={{ minWidth: "90px" }}
                            >{tercero.razonSocial}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + tercero.consecutivo}>
                        <div className="card card-body">
                          <table className="table table-sm table-hover table-striped mb-0">
                            <tbody>
                              {!col2 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}> Razon Social </th>
                                  <td>{tercero.razonSocial}</td>
                                </tr>
                              }
                              {!col3 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>NIT </th>
                                  <td>{tercero.nit}</td>
                                </tr>
                              }

                              {!col4 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Telefono </th>
                                  <td>{tercero.telefono}</td>
                                </tr>
                              }

                              {!col5 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Direccion </th>
                                  <td>{tercero.direccion}</td>
                                </tr>
                              }

                              {!col6 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Ciudad </th>
                                  <td>{tercero.ciudad}</td>
                                </tr>
                              }

                              {!col7 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Email </th>
                                  <td>{tercero.email}</td>
                                </tr>
                              }

                              {!col8 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Tipo </th>
                                  <td>{tercero.tipo}</td>
                                </tr>
                              }

                              {!col9 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Estado </th>
                                  <td className={tercero.estado !== "ACTIVO" ? "text-danger" : ""} >{tercero.estado}</td>
                                </tr>
                              }

                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Edit </th>
                                <td>
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                      disabled={!permisos.includes("editarTerc")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#ModificarAdicionar"
                                      id="modalModificar"
                                      title="Modificar Item Seleccionado"
                                      onClick={() => {
                                        handleClickTraerData(tercero);
                                        setAdicionar(0);
                                      }} />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table >
                        </div>
                      </div>
                    </div>
                ))}
              </>
              /* Mobile */
              :
              /* Desktop */
              <div className="table-responsive abs-center-table ms-3">
                <table id="myTable"
                  ref={tablaRef}
                  className="table table-hover table-striped table-sm table-bordered mt-3" >

                  <thead className="text-center">
                    <tr>
                      <th onClick={() => ordenar("razonSocial")} className={col2 ? "" : "d-none"} >Razon Social</th>
                      <th onClick={() => ordenar("nit")} className={col3 ? "" : "d-none"}>NIT</th>
                      <th onClick={() => ordenar("telefono")} className={col4 ? "" : "d-none"}>Telefono</th>
                      <th onClick={() => ordenar("direccion")} className={col5 ? "" : "d-none"}>Direccion</th>
                      <th onClick={() => ordenar("ciudad")} className={col6 ? "" : "d-none"}>Ciudad</th>
                      <th onClick={() => ordenar("email")} className={col7 ? "" : "d-none"}>Email</th>
                      <th onClick={() => ordenar("tipo")} className={col8 ? "" : "d-none"}>Tipo</th>
                      <th onClick={() => ordenar("estado")} className={col9 ? "" : "d-none"}>Estado</th>
                      <th className="text-center">Edit</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual.map((tercero) => (
                      tercero.nit === "Sin Datos"
                        ? <tr key={tercero.id + tercero.nit}>
                          <td colSpan={10} className="text-center">
                            <button
                              className="btn fa-solid fa-plus"
                              disabled={!permisos.includes("crearTerc")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modaladicionar"
                              title="Crear Nuevo Tercero"
                              onClick={() => setAdicionar(1)} />
                          </td>
                        </tr>
                        :
                        <tr key={tercero.id + tercero.nit}>
                          <td className={(tercero.estado !== "ACTIVO" && col2) ? "text-danger" : (tercero.estado !== "ACTIVO" && !col2) ? "d-none" : (col2) ? "" : "d-none"}>{tercero.razonSocial}</td>
                          <td className={col3 ? "" : "d-none"}>{tercero.nit}</td>
                          <td className={col4 ? "" : "d-none"}>{tercero.telefono}</td>
                          <td className={col5 ? "" : "d-none"}>{tercero.direccion}</td>
                          <td className={col6 ? "" : "d-none"}>{tercero.ciudad}</td>
                          <td className={col7 ? "" : "d-none"}>{tercero.email}</td>
                          <td className={col8 ? "" : "d-none"}>{tercero.tipo}</td>
                          <td className={(tercero.estado !== "ACTIVO" && col9) ? "text-danger" : (tercero.estado !== "ACTIVO" && !col9) ? "d-none" : (col9) ? "" : "d-none"}>{tercero.estado}</td>

                          {/* +Btn modificar */}
                          <td className="text-center">
                            <div className="btn-group">
                              <button
                                className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                disabled={!permisos.includes("editarTerc")}
                                data-bs-toggle="modal"
                                data-bs-target="#ModificarAdicionar"
                                id="modalModificar"
                                title="Modificar Item Seleccionado"
                                onClick={() => {
                                  handleClickTraerData(tercero);
                                  setAdicionar(0);
                                }} />
                            </div>
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            /* Desktop */
            : null}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados} />

          {/* +Modal de Modificar // Adicionar*/}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarAdicionar"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title">
                    {adicionar === 1 ? "Crear " : "Modificar "} Tercero
                  </h5>
                </div>

                <div className="modal-body">
                  {/* NIT */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">NIT:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="NIT"
                      disabled={!adicionar}
                      value={nit}
                      onChange={(e) => setNit(e.target.value)} />
                  </div>

                  {/* razón social */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Razón Social:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Razón social"
                      value={razonSocial}
                      onChange={(e) => setRazonSocial(e.target.value.toUpperCase())} />
                  </div>

                  {/* telefono */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Teléfono:</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Teléfono"
                      min={0}
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)} />
                  </div>

                  {/* Direccion */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Direccion:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Direccion"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value.toUpperCase())} />
                  </div>

                  {/* Ciudad */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Ciudad:</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ciudad"
                      value={ciudad}
                      onChange={(e) => setCiudad(e.target.value.toUpperCase())} />
                  </div>

                  {/* Email */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Email:</span>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>

                  <div className="row">
                    {/* Tipo */}
                    <div className="input-group input-group-sm mb-2 col">
                      <span className="input-group-text">Tipo:</span>
                      <select
                        className="form-select"
                        placeholder="Tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)} >
                        <option value="" disabled>Seleccione</option>
                        <option value="CLIENTE">Cliente</option>
                        <option value="PROVEEDOR">Proveedor</option>
                        <option value="EMPLEADO">Empleado</option>
                      </select>
                    </div>

                    {/* Estado */}
                    <div className="input-group input-group-sm mb-2 col">
                      <span className="input-group-text">Estado:</span>
                      <select
                        className="form-select"
                        placeholder="Estado"
                        value={estado}
                        onChange={(e) => setEstado(e.target.value)} >
                        <option value="" disabled> Seleccione </option>
                        <option value="ACTIVO">Activo</option>
                        <option value="INACTIVO">Inactivo</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      adicionar === 1
                        ? handleClickAdicionar()
                        : handleClickModificarBd()
                    } >
                    {adicionar === 1 ? "Crear" : "Modificar"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => resetStates()}>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal de Modificar // Adicionar */}

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Razon Social"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"NIT"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={" Teléfono"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Dirección"} />
                  </div>

                  <div className="form-check">

                    <CheckboxCol tabla="terceros" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Ciudad"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"E-mail"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Tipo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="terceros" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9')} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal Columnas */}

        </>}
    </>
  );
};
