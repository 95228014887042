import React from "react"
import { Blocks } from "react-loader-spinner";
import '../css/loader.css'

export const LoaderES = () => {
  return (
    <div className="abs-center-loader">
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
  )
}
