import React from 'react';

export const Series = ({ articulo }) => {

    const codigo = articulo.articulo
    const descripcion = articulo.des_Larga
    const bodega = articulo.bodega || articulo.bodegaSalida || '';
    const ubicacion = articulo.ubicacion || articulo.ubicacionSalida || '';
    const proyecto = articulo.proyecto || '';
    const listaSeries = articulo.listaSeriales
    //const disponible = articulo.cant_disponible

    return (
        <>
            <button
                className="btn btn-sm btn-outline-primary fa-solid fa-info"
                // style={{ fontSize: '7px' }}
                data-bs-toggle="modal"
                data-bs-target="#modalSeries"
                title='Series' />

            <div className="modal fade" id="modalSeries" tabIndex="-1" aria-labelledby="modalSeriesLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-light">
                            <h1 className="modal-title fs-5" id="modalSeriesLabel">{`${codigo}: ${descripcion}`}</h1>
                        </div>
                        <div className="modal-body">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>{proyecto ? 'Proyecto' : 'Bodega'}</th>
                                        {!proyecto && <th>ubicacion</th>}
                                        <th>Serie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaSeries?.map(serie => (
                                        <tr key={serie}>
                                            <td>{proyecto ? proyecto : bodega}</td>
                                            {!proyecto ? <td>{ubicacion}</td> : null}
                                            <td>{serie}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal" >
                                Cerrar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}
