import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";
import { changeTitle, handleModificarConsecutivo, isMobile, styles } from "../config/functions/funGenerales";
import { LoaderES } from "./LoaderES";

import sortBy from "sort-by";
import {
  consultarDatabase,
  consultarDocumentoWhere,
  guardarDatabaseId
} from "../config/firebase";

export const Solicitudes = ({ articulos, inventario, permisos, tipo }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const nombre = JSON.parse(localStorage.getItem("token")).name;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [listaConsecutivos, setListaConsecutivos] = useState([]);
  const [listaUbicaciones, setListaUbicaciones] = useState([]);
  const [listaInventario, setListaInventario] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [listaDestinos, setListaDestinos] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);

  const [listaFilas, setListaFilas] = useState([{ articulo: "", cantidadSolicitada: 0, destino: "", ubicacionDestino: "*", cantidadDisponible: 0, ArtExiste: null, idArticulo: "", },]);

  const [fechaSolicitud, setFechaSolicitud] = useState("");
  const fecha_creacion = fechaFormateada;

  const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
  const [valorConsecutivo, setValorConsecutivo] = useState(0);
  const [verConsecutivo, setVerConsecutivo] = useState("");
  const [valorPrefijo, setValorPrefijo] = useState("");

  const [destinos, setDestinos] = useState(listaBodegas);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [art, setArt] = useState(listaArticulos);

  const [modalConsecutivo, setModalConsecutivo] = useState("");
  const [modalUbicDestino, setModalUbicDestino] = useState("");
  const [modalIdArticulo, setModalIdArticulo] = useState("");
  const [modalDisponible, setModalDisponible] = useState(0);
  const [modalArticulo, setModalArticulo] = useState("");
  const [modalCantidad, setModalCantidad] = useState(0);
  const [modalExiste, setModalExiste] = useState(false);
  const [modalDestino, setModalDestino] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [um, setUm] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [guardar, setGuardar] = useState(false);
  const [articulo, setArticulo] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [lstNew, setLstNew] = useState([]);
  const [ok, setOk] = useState(null);

  //*********Funciones*********/
  useEffect(() => {
    changeTitle(`Admin. Solicitudes ${tipo}`);
    cargaInicial();
  }, []);

  useEffect(() => {
    changeTitle(`Admin. Solicitudes ${tipo}`);
  }, [tipo])

  const cargaInicial = () => {
    cargarConsecutivos();
    cargarArticulos();
    setListaInventario(inventario);
    cargarTabla(`${emp}_bodegas`, setListaBodegas);
    cargarTabla(`${emp}_proyectos`, setListaProyectos);
    isMobile(setMobile);
  };


  //--------Varios
  const obtenerFechaActual = () => {
    const currentDate = new Date();
    const offset = -5 * 60; // Offset en minutos (UTC-5)
    const today = new Date(currentDate.getTime() + offset * 60 * 1000)
      .toISOString()
      .split("T")[0];
    return today;
  };

  useEffect(() => {
    cargarArticulos()
  }, [articulos]);

  useEffect(() => {
    setListaInventario(inventario);
  }, [inventario]);

  const cargarTabla = async (tabla, setLista) => {
    try {
      const datos = await consultarDatabase(tabla);
      const activos = datos.filter((a) => a.estado === "ACTIVO")
      setLista(activos);
    } catch (error) {
      console.error(error);
    }
  };

  //-----------Consec
  const cargarConsecutivos = async () => {
    try {
      const listaTemporalC = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", "SOL");
      const activos = listaTemporalC.filter((a) => a.estado === "ACTIVO")
      setListaConsecutivos(activos);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    manipularConsecutivos();
  }, [listaConsecutivos]);

  const manipularConsecutivos = () => {
    const valorCon = listaConsecutivos[0]?.valorActual;
    const prefCom = listaConsecutivos[0]?.prefijo;
    const docCom = listaConsecutivos[0]?.idDocumento;
    const valCom = parseInt(valorCon + 1);
    setValorConsecutivo(valCom);
    setValorPrefijo(prefCom);
    setIdDocumentoConsecutivo(docCom);
    setVerConsecutivo(prefCom + "-" + valCom);
  };

  //----------Destinos
  const crearListaDestinos = async () => {
    let bdg = listaBodegas?.map((b) => ({ cons: b.consecutivo, desc: b.descripcion }));
    let pry = listaProyectos?.map((b) => ({ cons: b.consecutivo, desc: b.descripcion }));
    const listaTemp = [...bdg, ...pry];
    if (
      (bdg !== "" && bdg !== undefined) ||
      (pry !== "" && pry !== undefined)
    ) {
      if (listaTemp.length > 0) {
        setListaDestinos(listaTemp);
      }
    }
  };

  const crearListaUbicaciones = async (b) => {
    if (b.split("-")[0] === "BDG") {
      let bdg = listaBodegas?.find((a) => a.consecutivo === b);
      const listaTemp = bdg.listaUbicaciones;
      if (bdg !== "" && bdg !== undefined) {
        if (listaTemp.length > 0) {
          setListaUbicaciones(listaTemp);
        }
      }
    }
  };

  useEffect(() => {
    crearListaDestinos();
  }, [listaBodegas, listaProyectos]);

  useEffect(() => {
    const options = listaDestinos.map((d) => ({ value: d.cons, label: d.desc + ": (" + d.cons + ")" }));
    setDestinos(options?.sort(sortBy("value")));
  }, [listaDestinos]);

  useEffect(() => {
    const options = listaUbicaciones.map((d) => ({
      value: d.ubicacion,
      label: d.ubicacion,
    }));
    setUbicaciones(options?.sort(sortBy("value")));
  }, [listaUbicaciones]);

  //---------------Artículos
  const cargarArticulos = async (tipo1, tipo2) => {

    const listaTemporal = articulos
    if (tipo1 === "EPP" && tipo2 === "") {
      const resultado = listaTemporal.filter((element) => element.tipo === tipo1 && element.estado === "ACTIVO");
      setListaArticulos(resultado);
    }
    if (tipo1 === "CONSUMIBLE" && tipo2 === "") {
      const resultado = listaTemporal.filter((element) => element.tipo === tipo1 && element.estado === "ACTIVO");
      setListaArticulos(resultado);
    }
    if (tipo1 === "HERRAMIENTA" && tipo2 === "MATERIAL") {
      const resultadoH = listaTemporal.filter((element) => element.tipo === tipo1 && element.estado === "ACTIVO");
      const resultadoM = listaTemporal.filter((element) => element.tipo === tipo2 && element.estado === "ACTIVO");
      const resultadoFinal = resultadoH.concat(resultadoM);
      setListaArticulos(resultadoFinal);
    }

  };

  useEffect(() => {
    if (listaArticulos.length > 0 && listaArticulos[0].id !== 0) {
      let cantidadesDisponibles = {}, total;
      for (let i = 0; i < listaInventario.length; i++) {
        let consecutivo = listaInventario[i].articulo;
        if (listaInventario[i].hasOwnProperty("cant_disponible")) {
          if (!cantidadesDisponibles.hasOwnProperty(consecutivo)) {
            cantidadesDisponibles[consecutivo] = 0;
          }
          cantidadesDisponibles[consecutivo] += listaInventario[i].cant_disponible;
        }
      }
      const options = listaArticulos.map((a) => {
        let cantDis = parseFloat(cantidadesDisponibles.hasOwnProperty(a.consecutivo) ? cantidadesDisponibles[a.consecutivo] : 0);
        let um = a.um;
        let est = a.estado;
        total = Number(Number.parseFloat(cantDis).toFixed(2));
        return { value: a.consecutivo, label: a.consecutivo + ": " + a.des_Larga + ` (Cant: ${total} - ${um}, Est: ${est})` };
      });
      setArt(options?.sort(sortBy("label")));
    }
  }, [listaArticulos, listaInventario, tipo]);

  useEffect(() => {
    if (tipo === "EPP") {
      cargarArticulos("EPP", "");
    }
    if (tipo === "Consumibles") {
      cargarArticulos("CONSUMIBLE", "");
    }
    if (tipo === "Herramienta") {
      cargarArticulos("HERRAMIENTA", "MATERIAL");
    }
    setListaFilas([
      {
        articulo: "",
        cantidadSolicitada: 0,
        destino: "",
        ubicacionDestino: "*",
        cantidadDisponible: 0,
        ArtExiste: null,
        idArticulo: "",
      },
    ]);
  }, [tipo]);


  //--------Filas
  const agregarFila = (tipo) => {
    if (tipo === "M") {
      const datos = {
        articulo: modalConsecutivo,
        cantidadDisponible: modalDisponible,
        ArtExiste: modalExiste,
        idArticulo: modalIdArticulo,
        consArt: modalConsecutivo,
        cantidadSolicitada: Number(Number.parseFloat(modalCantidad).toFixed(2)),
        destino: modalDestino.value && modalDestino.value,
      };
      if (datos.destino.split("-")[0] === "BDG") {
        datos.ubicacionDestino = modalUbicDestino.value;
      } else {
        datos.ubicacionDestino = "*";
      }
      let tieneCamposVacios = Object.values(datos).some(
        (valor) => valor === ""
      );
      if (!tieneCamposVacios) {
        const nuevasFilas = [...listaFilas, datos];
        setListaFilas(nuevasFilas);
        limpiaData();
      } else {
        toast.warning(
          "No se pudo crear la solicitud del artículo, intente nuevamente."
        );
      }
    } else {
      setListaFilas([
        ...listaFilas,
        {
          articulo: "",
          cantidadSolicitada: 0,
          destino: "",
          ubicacionDestino: "*",
          cantidadDisponible: 0,
          ArtExiste: null,
          idArticulo: "",
        },
      ]);
      limpiaData();
    }
  };

  const validarExiste = (tipo, dest) => {
    let nuevoArticulo, nuevaCantSol, nuevoDestino;
    let data = {};

    if (tipo === "M") {
      nuevoArticulo = modalConsecutivo;
      nuevaCantSol = Number(Number.parseFloat(modalCantidad).toFixed(2));
      nuevoDestino = dest.value;
      const filaExistente = lstNew.some(
        (fila) =>
          fila.art === nuevoArticulo &&
          fila.cant === nuevaCantSol &&
          fila.dest === nuevoDestino
      );
      if (filaExistente) {
        toast.warning(
          "La fila que intentas agregar ya existe en la lista, intenta nuevamente."
        );
        limpiaData();
        return false;
      }
    } else {
      nuevoArticulo = listaFilas[listaFilas.length - 1].articulo?.trim();
      nuevaCantSol = parseFloat(
        listaFilas[listaFilas.length - 1].cantidadSolicitada
      );
      nuevoDestino = dest.value;
      const filaExistente = lstNew.some(
        (fila) =>
          fila.art === nuevoArticulo &&
          fila.cant === nuevaCantSol &&
          fila.dest === nuevoDestino
      );
      if (filaExistente) {
        toast.warning(
          "La fila que intentas agregar ya existe en la lista, intenta nuevamente."
        );
        limpiaData();
        return false;
      }
    }
    data = {
      art: articulo,
      cant: cantidad,
      dest: dest.value,
    };
    let listaData = [...lstNew, data];
    setLstNew(listaData);
    return true;
  };

  const limpiaData = () => {
    setModalConsecutivo("");
    setModalDisponible("");
    setModalExiste("");
    setModalIdArticulo("");
    setModalConsecutivo("");
    setModalCantidad("");
    setModalDestino("");
    setModalUbicDestino("");
    setSelectedOption("");
    setArticulo("");
    setCantidad("");
  };

  const obtenerCantidadDisponible = (listaPrincipal, idA) => {
    let exist, totalCantidad, resp = [];
    const existeEnArticulos = listaArticulos.filter((element) => element.consecutivo === idA);
    const coincidenciasListaPrincipal = listaPrincipal.filter((element) => element.articulo === idA);
    if (existeEnArticulos.length > 0) {
      if (coincidenciasListaPrincipal.length > 1) {
        totalCantidad = coincidenciasListaPrincipal.reduce((sum, elem) => {
          return parseFloat(sum + (elem.cant_disponible || 0));
        }, 0);

        exist = true;
        resp = [totalCantidad, exist];
        return resp;
      } else if (coincidenciasListaPrincipal.length === 1) {
        const unicaCoincidencia = coincidenciasListaPrincipal[0];
        totalCantidad = parseFloat(unicaCoincidencia.cant_disponible) || 0;
        exist = true;
        resp = [totalCantidad, exist];
        return resp;
      } else {
        exist = true;
        totalCantidad = 0;
        resp = [totalCantidad, exist];
        return resp;
      }
    } else {
      exist = false;
      totalCantidad = 0;
      resp = [totalCantidad, exist];
      return resp;
    }
  };

  //--------Solicitud
  const hacerSolicitud = async () => {
    const consecutivo = valorPrefijo.toUpperCase() + "-" + valorConsecutivo;
    const listaArtSolicitud = Object.values(listaFilas).filter(
      (item) => typeof item === "object"
    );
    const listaArtSolicitudFiltrada = listaArtSolicitud.filter(
      (f) => f.articulo !== "" && f.cantidadSolicitada !== 0 && f.destino !== "" && f.cantidadDisponible !== "" && f.ArtExiste !== null && f.idArticulo !== "");

    if (listaArtSolicitudFiltrada) {
      const listaModificada = listaArtSolicitudFiltrada.map((item) => {
        const { consArt, ...rest } = item;
        return {
          ...rest,
          articulo: consArt,
          solicitante: nombre,
          fecha_creacion: fecha_creacion,
          fechaSolicitud: fechaFormateada,
          tipo: tipo,
          estado: "solicitado",
          consecutivo,
        };
      }
      );
      const item = listaModificada
      const enviar = {
        [consecutivo]: item,
      };
      try {
        setIsLoading(true)

        let resGuardar = await guardarDatabaseId(`${emp}_solicitudes`, `${emp}_solicitudes`, enviar);
        if (resGuardar !== undefined || resGuardar !== "") {
          setListaFilas([]);
          let respKd = await cargarInventarioEnKardex(enviar);
          if (respKd) {
            toast.success("La solicitud se ha enviado con exito");
            await handleModificarConsecutivo(consecutivo, user, fechaFormateada, idDocumentoConsecutivo, emp);
            await cargarConsecutivos();
            setListaFilas([{ articulo: "", cantidadSolicitada: 0, destino: "", ubicacionDestino: "*", cantidadDisponible: 0, ArtExiste: null, idArticulo: "", },]);
            limpiaData();
            setOk(false);
            setGuardar(false);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false)

      }
    } else {
      toast.warning("No hay articulos para solicitar, intente nuevamente");
    }
  };

  const cargarInventarioEnKardex = async (lista) => {
    let id = uniqid("kardex-");
    let valor;
    if (lista.length !== 0) {
      for (const key in lista) {
        if (lista.hasOwnProperty(key)) {
          const objetoActual = lista[key];
          valor = objetoActual[0].consecutivo.split("-")[0];
          if (typeof objetoActual === "object" && objetoActual !== null) {
            objetoActual.id = id;
            objetoActual.usuario_creacion = user;
            objetoActual.fecha_creacion = fecha_creacion;
          }
        }
      }
      if (lista.length !== 0) {
        try {
          let res = await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, lista);
          if (res !== "") {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.warning(
          "Error, la lista está vacia, por favor verifica e inetenta de nuevo"
        );
      }
    }
  };

  /****************** Inicio Componente ******************/
  return (
    <div >
      {isLoading ?
        <LoaderES />
        :
        <>
          {/* Titulo */}
          {permisos.includes("solEpp") || permisos.includes("solConsumible") || permisos.includes("solHerramienta") ?
            <div className="row mt-5 fs-4">
              <h4>Solicitud de {tipo} </h4>
            </div>
            : null}

          {/* Consecutivo  y fecha solicitud*/}
          {permisos.includes("solEpp") || permisos.includes("solConsumible") || permisos.includes("solHerramienta") ?
            <div className="row mt-2 mb-3">
              {/* consecutivo solicitud*/}
              <div className="col-sm-6">
                <div className="input-group mb-2">
                  <span className="input-group-text">Consecutivo</span>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={listaConsecutivos.length > 0 ? verConsecutivo : "Sin consecutivos didponibles"}
                  />
                </div>
              </div>

              {/* Fecha solicitud */}
              <div className="col-sm-6">
                <div className="input-group">
                  <span className="input-group-text">Fecha</span>
                  <input
                    type="date"
                    readOnly
                    className="form-control"
                    value={fechaSolicitud || obtenerFechaActual()}
                    onChange={(e) => setFechaSolicitud(e.target.value)}
                  />
                </div>
              </div>
            </div>
            : null}

          {/* Tabla de solicitud */}
          {(permisos.includes("solEpp") || permisos.includes("solConsumible") || permisos.includes("solHerramienta")) && tipo !== "" && (
            <div className="table-responsive" style={{ minHeight: "50vh" }}>
              {listaConsecutivos.length > 0 && (
                <>
                  {mobile ?
                    (
                      <>
                        <table className="table table-sm table-hover table-bordered">
                          <thead>
                            <tr>
                              <th style={{ resize: "none" }}>Artículo</th>
                              <th style={{ resize: "none" }} className="text-center">Cant</th>
                              <th style={{ resize: "none" }}>Destino</th>
                              <th style={{ resize: "none" }}>Ubic. Destino</th>
                              <th style={{ resize: "none" }} className="text-center">Disp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listaFilas.map((fila, index) =>
                              fila.articulo !== "" ? (
                                <tr key={index}>
                                  <th>{fila.articulo}</th>
                                  <th className="text-center">{parseFloat(fila.cantidadSolicitada)}</th>
                                  <th>{fila.destino}</th>
                                  <th>{fila.ubicacionDestino}</th>
                                  <th className="text-center">{parseFloat(fila.cantidadDisponible)}</th>
                                </tr>
                              ) : null
                            )}
                            {listaFilas.length >= 2 ? null : (
                              <tr>
                                <th className="text-center" colSpan={"100%"}>
                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#Solicitud"
                                    className="btn bnt-sm btn-primary fa-solid fa-plus w-100 mt-2"
                                  />
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* Btn add // save */}
                        {mobile && listaFilas.length > 1 && !guardar ? (
                          <>
                            <p> <strong> Desea agregar otro artículo o guardar ? </strong></p>
                            <button
                              title="Deseo Guardar"
                              className="btn btn-md btn-outline-primary fa-solid fa-floppy-disk me-2"
                              onClick={() => {
                                setGuardar(true);
                              }}
                            />
                            <button
                              title="Sí, agregar artículo"
                              className="btn btn-md btn-outline-success fa-solid fa-plus ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#Solicitud"
                            />
                          </>
                        ) : null}
                      </>
                    )
                    : (
                      <>
                        <table
                          id="myTable"
                          className="table table-hover table-sm">
                          <thead>
                            <tr>
                              <th>Artículo</th>
                              <th>Cantidad</th>
                              <th>Destino</th>
                              <th>Ubic. Destino</th>
                              <th>Disponible</th>
                            </tr>
                          </thead>
                          <tbody id="tabla-body">
                            {listaFilas.map((fila, index) => (
                              <tr key={index}>
                                {/* Arículo */}
                                <td className="py-0">
                                  <Select
                                    placeholder="Artículo"
                                    value={listaArticulos.length > 0 ? fila.articulo.label : "Sin artículos disponibles"}
                                    onChange={(selectedOption) => {
                                      const art = listaArticulos.find((a) => a.consecutivo === selectedOption.value);
                                      let resp = obtenerCantidadDisponible(listaInventario, art ? art.consecutivo : "");
                                      const nuevasFilas = [...listaFilas];
                                      nuevasFilas[index].articulo = selectedOption.value;
                                      nuevasFilas[index].cantidadDisponible = parseFloat(resp[0]);
                                      nuevasFilas[index].ArtExiste = resp[1];
                                      nuevasFilas[index].idArticulo = art ? art.id : "";
                                      setUm(art?.um);
                                      nuevasFilas[index].consArt = art ? art.consecutivo : "";
                                      setListaFilas(nuevasFilas);
                                      setArticulo(selectedOption.value);
                                      setSelectedOption(selectedOption);
                                    }}
                                    options={art}
                                    styles={styles}
                                  />
                                </td>
                                {/* Cantidad */}
                                <td className="py-0">
                                  <input
                                    type="number"
                                    className="form-control"
                                    step={um === "UND" ? 1 : 0.1}
                                    min={um === "UND" ? 1 : 0.1}
                                    disabled={!fila.articulo}
                                    onFocus={() => fila.cantidadSolicitada = ""}
                                    value={fila.cantidadSolicitada ? fila.cantidadSolicitada : ""}
                                    onChange={(e) => {
                                      const nuevasFilas = [...listaFilas];
                                      nuevasFilas[index].cantidadSolicitada = parseFloat(e.target.value);
                                      setCantidad(parseFloat(e.target.value));
                                      setListaFilas(nuevasFilas);
                                    }}
                                  />
                                </td>

                                {/* Destino */}
                                <td className="py-0">
                                  <Select
                                    value={listaDestinos.length > 0 ? fila.destino.label : "Sin destinos disponibles"}
                                    onChange={(option) => {
                                      const nuevasFilas = [...listaFilas];
                                      nuevasFilas[index].destino = option.value && option.value;
                                      setListaFilas(nuevasFilas);
                                      validarExiste("A", option) ? setOk(true) : setOk(false);
                                      crearListaUbicaciones(option.value);
                                    }}
                                    options={destinos}
                                    isDisabled={!fila.cantidadSolicitada}
                                    styles={styles}
                                  />
                                </td>

                                {/* Ubic. Destino */}
                                <td className="py-0">
                                  <Select
                                    value={fila.ubicacionDestino.label}
                                    onChange={(option) => {
                                      const nuevasFilas = [...listaFilas];
                                      nuevasFilas[index].ubicacionDestino = option.value && option.value;
                                      setListaFilas(nuevasFilas);
                                    }}
                                    options={ubicaciones}
                                    isDisabled={!fila.destino || fila.destino.split("-")[0] !== "BDG"}
                                    styles={styles}
                                  />
                                </td>

                                <td className="py-0">
                                  {fila.articulo !== "" && (fila.cantidadSolicitada !== 0 || (fila.cantidadSolicitada !== "" && fila.cantidadSolicitada !== "0")) ? (
                                    <input className={
                                      fila.cantidadDisponible === 0 ?
                                        "form-control form-control-sm bg-danger text-light"
                                        : parseFloat(fila.cantidadSolicitada) > parseFloat(fila.cantidadDisponible) ?
                                          "form-control bg-warning"
                                          : parseFloat(fila.cantidadSolicitada) < parseFloat(fila.cantidadDisponible) ?
                                            "form-control bg-success text-light"
                                            : "form-control bg-info "}
                                      type="number"
                                      readOnly
                                      value={parseFloat(fila.cantidadDisponible)}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}

                  {ok && !mobile &&
                    (
                      <>
                        <p><strong>Desea agregar otro artículo o guardar ?</strong> </p>
                        <button
                          title="Deseo Guardar"
                          className="btn btn-md btn-outline-primary fa-solid fa-floppy-disk me-2"
                          onClick={() => {
                            setOk(false);
                            setGuardar(true);
                          }}
                        />
                        <button
                          title="Sí, agregar artículo"
                          className="btn btn-md btn-outline-success fa-solid fa-plus ms-2"
                          onClick={() => {
                            setGuardar(false);
                            setOk(false);
                            agregarFila("A");
                          }}
                        />
                      </>
                    )}

                  {guardar &&
                    (
                      <button
                        title="Enviar solicitud"
                        className="btn btn-outline-primary ms-3"
                        onClick={() => hacerSolicitud()} >
                        Enviar Solicitud
                      </button>
                    )}
                </>
              )}
            </div>
          )}
        </>
      }

      {/* +Modal de Modificar // Adicionar*/}
      <div
        className="modal fade"
        aria-hidden="true"
        id="Solicitud"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title fs-5">Solicitud Artículo</h5>
            </div>

            {/* Formulario */}
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                {/* Art */}
                <Select
                  className="w-100"
                  placeholder="Artículo"
                  value={selectedOption}
                  onChange={(selectedOption) => {
                    const art = listaArticulos.find((art) => art.consecutivo === selectedOption.value);
                    let resp = obtenerCantidadDisponible(listaInventario, art ? art.consecutivo : "");
                    setModalConsecutivo(art ? art.consecutivo : "");
                    setModalIdArticulo(art ? art.id : "");
                    setModalDisponible(parseFloat(resp[0]));
                    setModalExiste(resp[1]);
                    setModalArticulo(selectedOption.value);
                    setUm(art?.um);
                    setArticulo(selectedOption.value);
                    setSelectedOption(selectedOption);
                  }}
                  options={art}
                  styles={styles}
                />
              </div>

              {/* Cant */}
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text">Cantidad</span>
                <input
                  type="number"
                  step={um === "UND" ? 1 : 0.1}
                  min={um === "UND" ? 1 : 0.1}
                  className="form-control form-control-sm"
                  value={modalCantidad}
                  onFocus={() => setModalCantidad("")}
                  onChange={(e) => {
                    setModalCantidad(parseFloat(e.target.value));
                    setCantidad(parseFloat(e.target.value));
                  }}
                />
              </div>

              {/* Dest */}
              <div className="input-group input-group-sm mb-3">
                <Select
                  className="w-100"
                  placeholder="Destino"
                  value={modalDestino}
                  options={destinos}
                  styles={styles}
                  onChange={(option) => {
                    setModalDestino(option);
                    validarExiste("M", option) ? setOk(true) : setOk(false);
                    crearListaUbicaciones(option.value);
                  }}
                />
              </div>

              {/* Ubic. Destino */}
              {modalDestino.value?.split("-")[0] === "BDG" ? (
                <div className="input-group input-group-sm mb-3">
                  <Select
                    className="w-100"
                    placeholder="Ubicacion Destino"
                    value={modalUbicDestino}
                    options={ubicaciones}
                    styles={styles}
                    onChange={(option) => {
                      setModalUbicDestino(option);
                    }}
                  />
                </div>
              ) : null}

              {modalArticulo !== "" &&
                (modalCantidad !== 0 || modalCantidad !== "") ? (
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text">Disponible</span>
                  <input
                    value={modalDisponible}
                    className={
                      modalDisponible === 0
                        ? "form-control form-control-sm bg-danger text-light"
                        : parseFloat(modalCantidad) > parseFloat(modalDisponible)
                          ? "form-control bg-warning"
                          : parseFloat(modalCantidad) < parseFloat(modalDisponible)
                            ? "form-control bg-success text-light"
                            : "form-control bg-info "
                    }
                    type="number"
                    readOnly
                  />
                </div>
              ) : null}
            </div>

            <div className="modal-footer">
              {ok ? (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => agregarFila("M")}
                >
                  Crear
                </button>
              ) : null}

              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => limpiaData()}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
