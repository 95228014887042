import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  guardarDatabase
} from "../../config/firebase";
import { changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";

export const Unidades = ({ permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [idDocumento, setIdDocumento] = useState("");
  const [estado, setEstado] = useState("ACTIVO");
  const [codigo, setCodigo] = useState("");

  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;
  const usuario_modificacion = user;
  const usuario_creacion = user;

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [adicionar, setAdicionar] = useState(0);
  const [mobile, setMobile] = useState(true);
  const tablaRef = useRef(null);


  //************Funciones*****************
  useEffect(() => {
    changeTitle("Admin. Unidades");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4], user, "unidad");
    cargarUnidades();
    isMobile(setMobile);
  };

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);

  //------------CRUD----------------
  const cargarUnidades = async () => {
    setIsLoading(true);
    let unidades;
    try {
      const listaTempUnidades = await consultarDatabase(`${emp}_unidades`);
      if (listaTempUnidades.length === 0) {
        unidades = [{ codigo: "Sin Datos" }]
      } else {
        unidades = listaTempUnidades.sort(sortBy("codigo"));
      }
      setListaUnidades(unidades)
      setDocumentosFiltrados(unidades)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleClickAdicionar = async () => {
    const respuesta = listaUnidades.find((u) => u.codigo === codigo);
    if (respuesta !== undefined) {
      resetStates();
      toast.info("El Codigo: " + codigo.toUpperCase() + " ya se encuentra registrado, intente de nuevo");
    } else {
      const enviarUnidad = {
        id: uniqid("unidad-"), codigo, descripcion, estado, usuario_creacion, fecha_creacion,
      };
      if (descripcion === "" || codigo === "" || estado === "") {
        toast.warning("Diligencie Todos los campos de manera correcta");
      } else {
        setIsLoading(true)
        try {
          await guardarDatabase(`${emp}_unidades`, enviarUnidad);
          resetStates();
          toast.success("Unidad agregada de manera correcta");
          await cargarUnidades();
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = (u) => {
    setIdDocumento(u.idDocumento);
    setCodigo(u.codigo);
    setDescripcion(u.descripcion);
    setEstado(u.estado);
  };

  const handleClickModificarBd = async () => {
    if (idDocumento) {
      const enviarUnidad = {
        descripcion, estado, usuario_modificacion, fecha_modificacion,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabase(`${emp}_unidades`, idDocumento, enviarUnidad);
        resetStates();
        toast.success("Unidad modificada correctamente");
        await cargarUnidades();
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("Seleccione una unidad valida para modificarla");
    }
  };

  const resetStates = () => {
    setDescripcion("");
    setCodigo("");
    setEstado("");
  };

  //----------Columnas------------------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaUnidades(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //-----------Paginación-----------------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas]);



  //----------------------------
  return (
    <>
      <ToastContainer position="bottom-right" />
      {isLoading ?
        <LoaderES />
        : <>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: "white",
              marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
              transition: "margin-left 0.3s ease",
              top: "46px",
              zIndex: 1
            }}>

            <div className="row ms-2 me-2">
              {/* botones de opcion */}
              <div className="col-sm-4 align-self-start text-start">
                <div className="btn-group">

                  {/* Adicionar */}
                  <button
                    className="btn fa-solid fa-plus"
                    disabled={!permisos.includes("crearUnid")}
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarAdicionar"
                    id="modaladicionar"
                    title="Crear Nueva Unidad"
                    onClick={() => setAdicionar(1)} />

                  {/* Columnas */}
                  <button
                    className="btn fa-solid fa-table-columns"
                    title="Seleccionar Columnas"
                    data-bs-toggle="modal"
                    data-bs-target="#ModificarColumnas"
                    id="modalcolumnas" />

                  {/* Actualizar */}
                  <button
                    className="btn fa-solid fa-rotate"
                    title="Actualizar Datos"
                    onClick={cargarUnidades} />
                </div>
              </div>

              {/* filtrar */}
              <div className="col-sm-8">
                <Filtrar2
                  tabla="myTable"
                  documentos={listaUnidades}
                  setDocumentosFiltrados={setDocumentosFiltrados}
                  setRegistrosFiltrados={setRegistrosPaginaActual}
                  registrosPorPagina={registrosPorPagina} />
              </div>
            </div>
          </div>

          <hr className={" mt-5 " + (mobile === true ? " mb-5" : "")} />

          {permisos.includes("verUnid") ?
            mobile ?
              /* Mobile */
              <>
                {registrosPaginaActual.map((unidad) => (
                  unidad.codigo === "Sin Datos" ?
                    <button
                      className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                      disabled={!permisos.includes("crearUnid")}
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarAdicionar"
                      id="modaladicionar"
                      title="Crear Nueva Unidad"
                      onClick={() => setAdicionar(1)} />
                    :
                    <div key={unidad.id}>
                      <table className="table table-sm table-hover table-striped mb-0" >
                        <thead>
                          <tr key={unidad.id}>
                            <th style={{ width: "50px", resize: "none" }}>
                              <button className="btn  fa-solid fa-chevron-down btn-sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseItem" + unidad.id}
                                aria-expanded="false"
                                aria-controls="collapseItem" />
                            </th>
                            <th style={{ resize: "none" }}>{unidad.codigo}:</th>
                            <td style={{ minWidth: "200px" }}
                              className={unidad.estado !== "ACTIVO" ? "text-danger" : ""}>{unidad.descripcion}</td>
                          </tr>
                        </thead>
                      </table>

                      <div className="collapse" id={"collapseItem" + unidad.id}>
                        <div className="card card-body">
                          <table className="table table-sm">
                            <tbody>
                              {!col2 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Código</th>
                                  <td>{unidad.codigo}</td>
                                </tr>
                              }

                              {!col3 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Descripción</th>
                                  <td>{unidad.descripcion}</td>
                                </tr>
                              }

                              {!col4 ? null :
                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td className={unidad.estado !== "ACTIVO" ? "text-danger" : ""}>{unidad.estado}</td>
                                </tr>
                              }

                              <tr>
                                <td></td>
                                <th style={{ resize: "none" }}>Actions </th>
                                <td>
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                      disabled={!permisos.includes("editarUnid")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#ModificarAdicionar"
                                      id="modalModificar"
                                      title="Modificar Item Seleccionado"
                                      onClick={() => {
                                        handleClickTraerData(unidad);
                                        setAdicionar(0);
                                      }} />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                ))}
              </>
              /* Mobile */
              :
              /* Desktop */
              <div className="table-responsive abs-center-table">
                {/* Tabla Unidades*/}
                <table id="myTable" ref={tablaRef}
                  className="table table-hover table-striped table-sm table-bordered mt-3" >
                  <thead className="text-center">
                    <tr>
                      <th scope="col" onClick={() => ordenar("codigo")} className={col2 ? "" : "d-none"}>Código</th>
                      <th scope="col" onClick={() => ordenar("descripcion")} className={col3 ? "" : "d-none"}>Descripción</th>
                      <th scope="col" onClick={() => ordenar("estado")} className={col4 ? "" : "d-none"}>Estado</th>
                      <th scope="col" >Edit</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual.map((unidad) => (
                      unidad.codigo === "Sin Datos" ?
                        <tr>
                          <td colSpan={"100%"} className="text-center">
                            <button
                              className="btn fa-solid fa-plus"
                              disabled={!permisos.includes("crearUnid")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modaladicionar"
                              title="Crear Nueva Unidad"
                              onClick={() => setAdicionar(1)} />
                          </td>
                        </tr>
                        :
                        <tr key={unidad.id}>
                          <td className={(unidad.estado !== "ACTIVO" && col2) ? "text-danger" : (unidad.estado !== "ACTIVO" && !col2) ? "text-danger" : (col2) ? "" : "d-none"}>{unidad.codigo}</td>
                          <td className={(unidad.estado !== "ACTIVO" && col3) ? "text-danger" : (unidad.estado !== "ACTIVO" && !col3) ? "text-danger" : (col3) ? "" : "d-none"}>{unidad.descripcion}</td>
                          <td className={(unidad.estado !== "ACTIVO" && col4) ? "text-danger" : (unidad.estado !== "ACTIVO" && !col4) ? "text-danger" : (col4) ? "" : "d-none"}>{unidad.estado}</td>

                          <td className="text-center">
                            {/* +Btn modificar */}
                            <button
                              className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                              disabled={!permisos.includes("editarUnid")}
                              data-bs-toggle="modal"
                              data-bs-target="#ModificarAdicionar"
                              id="modalModificar"
                              title="Modificar Item Seleccionado"
                              onClick={() => {
                                handleClickTraerData(unidad);
                                setAdicionar(0);
                              }} />
                          </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            /* Desktop */
            : null}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados}
          />

          {/* +Modal de Modificar // Adicionar*/}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarAdicionar"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">

                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title">
                    {adicionar === 1 ? "Crear " : "Modificar "} Unidad
                  </h5>
                </div>

                <div className="modal-body">
                  {/* Codigo */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Codigo:</span>
                    <input type="text"
                      className="form-control"
                      disabled={!adicionar}
                      placeholder="Codigo"
                      value={codigo}
                      onChange={(e) => setCodigo(e.target.value.toUpperCase())} />
                  </div>

                  {/* Descripcion  */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Descripcion:</span>
                    <input type="text"
                      className="form-control"
                      placeholder="Descripcion "
                      value={descripcion}
                      onChange={(e) => setDescripcion(e.target.value.toUpperCase())} />
                  </div>

                  {/* estado */}
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Estado:</span>
                    <select
                      className="form-select"
                      placeholder="Estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)} >
                      <option value="" disabled>Seleccione</option>
                      <option value="ACTIVO">Activo</option>
                      <option value="INACTIVO">Inactivo</option>
                    </select>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      adicionar === 1
                        ? handleClickAdicionar()
                        : handleClickModificarBd()
                    } >
                    {adicionar === 1 ? "Crear" : "Modificar"}
                  </button>

                  <button type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal de Modificar // Adicionar */}

          {/* +Modal Columnas */}
          <div
            className="modal fade"
            aria-hidden="true"
            id="ModificarColumnas"
            tabIndex="-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
              <div className="modal-content ">
                <div className="modal-header bg-primary text-light">
                  <h5 className="modal-title" id="staticBackdropLabel3">
                    Selección de columnas
                  </h5>
                </div>

                <div className="modal-body">

                  <div className="form-check">
                    <CheckboxCol tabla="unidad" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Codigo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="unidad" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Descripcion"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="unidad" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal" >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -Modal Columnas */}
        </>}
    </>
  );
};
