import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import { actualizarDocumentoDatabaseIdCampo, eliminarDocumentoDatabaseCampo } from "../../config/firebase";
import { changeTitle, exportToExcel, fixConsecutivo, handleColum, isMobile } from "../../config/functions/funGenerales";
import { CheckboxCol } from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";

export const ConsSolicitudes = ({ articulos, inventario, solicitudes, permisos }) => {

  const [componente, setComponente] = useState(localStorage.getItem("componente") || "ConsSolicitudes");

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));
  const history = useHistory();

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActualAll, setRegistrosPaginaActualAll] = useState([])
  const [documentosFiltradosAll, setDocumentosFiltradosAll] = useState([]);
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [todasSolicitudes, setTodasSolicitudes] = useState([]);
  const [listaSolicitudes, setListaSolicitudes] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaSolPend, setListaSolPend] = useState([]);
  const [listaInv, setListaInv] = useState([]);
  const [datos, setDatos] = useState([]);

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);
  const [col14, setCol14] = useState(true);
  const [col15, setCol15] = useState(true);
  const [col16, setCol16] = useState(true);
  const [col17, setCol17] = useState(true);
  const [col18, setCol18] = useState(true);
  const [col19, setCol19] = useState(true);
  const [col20, setCol20] = useState(true);
  const [col21, setCol21] = useState(true);
  const [col22, setCol22] = useState(true);
  const [col23, setCol23] = useState(true);
  const [col24, setCol24] = useState(true);
  const [col25, setCol25] = useState(true);
  const [col26, setCol26] = useState(true);
  const [col27, setCol27] = useState(true);
  const [col28, setCol28] = useState(true);
  const [col29, setCol29] = useState(true);
  const [col30, setCol30] = useState(true);
  const [col31, setCol31] = useState(true);
  const [col32, setCol32] = useState(true);
  const [col33, setCol33] = useState(true);
  const [col34, setCol34] = useState(true);
  const [col35, setCol35] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [carga, setCarga] = useState(0);

  //*************** Funciones ******************* */
  useEffect(() => {
    cargaInicial();
    changeTitle("Admin. Consulta_solicitudes");
    isMobile(setMobile)
    setOpcionSeleccionada("consulta_sol");
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, setCol18, setCol19, setCol20, setCol21, setCol22, setCol23, setCol24, setCol25, setCol26, setCol27, setCol28, setCol29, setCol30, setCol31, setCol32, setCol33, setCol34, setCol35], user, "consSolicitudes");
    setListaArticulos(articulos)
    setTodasSolicitudes(solicitudes);
    procesarDatos(solicitudes);
    setListaInv(inventario)
  };

  useEffect(() => {
    localStorage.setItem("componente", componente);
  }, [componente]);

  useEffect(() => {
    setListaArticulos(articulos)
  }, [articulos]);

  useEffect(() => {
    setListaInv(inventario)
  }, [inventario]);

  useEffect(() => {
    procesarDatos(solicitudes);
  }, [solicitudes]);

  //--------Varios-------
  const procesarDatos = (listaSolicitudes) => {
    const arreglo = [];
    listaSolicitudes.forEach((element) => {
      Object.entries(element).forEach(([key, value]) => {
        if (key !== "consecutivo" && typeof value === "object") {
          const temporalArticulo = articulos.find(at => at.consecutivo === value.articulo)
          let datoTemporal = { consecutivo: element.consecutivo, ...value };
          let datoTemporalSinDetalle = {
            consecutivo: datoTemporal.consecutivo,
            fecha_creacion: element.fecha_creacion,
            articulo: datoTemporal.articulo,
            estadoArt: temporalArticulo?.estado,
            estadoMov: value.estado,
            des_Larga: temporalArticulo?.des_Larga,
            clasificacion1: temporalArticulo?.clasificacion1,
            clasificacion2: temporalArticulo?.clasificacion2,
            clasificacion3: temporalArticulo?.clasificacion3,
            consecutivoFixSol: fixConsecutivo(datoTemporal.consecutivo),
            consecutivoFix: fixConsecutivo(datoTemporal.articulo),
            detalle: datoTemporal,
          };
          arreglo.push(datoTemporalSinDetalle);
        }
      });
    });
    setDatos(arreglo);
  };

  useEffect(() => {
    traerCantidades(datos)
  }, [datos]);

  const traerCantidades = (lista) => {
    let nuevaLista = []
    nuevaLista = lista.map((li) => {
      let totalDisponible = 0, totalReservada = 0, totDispDest = 0, totResDest = 0;
      listaInv.forEach(item => {
        if (item.articulo === li.detalle.articulo && !(item.bodega === li.detalle.destino && item.ubicacion === li.detalle.ubicacionDestino)
          && !(item.proyecto === li.detalle.destino)) { totalDisponible += item.cant_disponible || 0; totalReservada += item.cant_reservada || 0; }

        if (item.articulo === li.detalle.articulo && (item.bodega === li.detalle.destino && item.ubicacion === li.detalle.ubicacionDestino)
          || item.articulo === li.detalle.articulo && (item.proyecto === li.detalle.destino)) { totDispDest += item.cant_disponible || 0; totResDest += item.cant_reservada || 0; }
      });

      totalReservada = parseFloat(totalReservada);
      totResDest = parseFloat(totResDest);
      totDispDest = parseFloat(totDispDest);
      totalDisponible = parseFloat(totalDisponible);
      let cantDis = parseFloat(totalDisponible), cantSol = parseFloat(li.detalle.cantidadSolicitada);
      let falta = (parseFloat(cantDis) < parseFloat(cantSol) ? parseFloat(cantDis) - parseFloat(cantSol) : 0);

      let nuevoObjeto = { ...li };
      nuevoObjeto.totalDisponible = totalDisponible;
      nuevoObjeto.totalReservada = totalReservada;
      nuevoObjeto.totDispDest = totDispDest;
      nuevoObjeto.totResDest = totResDest;
      nuevoObjeto.cantDis = cantDis;
      nuevoObjeto.falta = falta;
      return nuevoObjeto;
    });
    setListaSolicitudes(nuevaLista.sort(sortBy("consecutivoFixSol")));

  };

  useEffect(() => {
    let arrayOriginal = listaSolicitudes;
    let arrayAgrupado = arrayOriginal.reduce((acumulador, itemActual) => {
      if (!acumulador[itemActual.consecutivo]) {
        acumulador[itemActual.consecutivo] = [];
      }
      if (itemActual.detalle) {
        itemActual = { ...itemActual, ...itemActual.detalle };
        delete itemActual.detalle;
      }
      acumulador[itemActual.consecutivo].push(itemActual);
      return acumulador;
    }, {});

    let arrayFinal = Object.keys(arrayAgrupado).map(consecutivo => {
      let items = arrayAgrupado[consecutivo];
      let estado = items[items.length - 1].estado;
      return {
        estado: estado,
        consecutivo: consecutivo,
        items: items,
        datosComunes: obtenerDatosComunes(items)
      };
    });
    setDocumentosFiltradosAll(arrayFinal.sort(sortBy("consecutivo")));
    setCarga(c => c + 1)

  }, [listaSolicitudes]);

  const obtenerDatosComunes = (array) => {
    let datosComunes = {};
    let primerObjeto = array[0];
    for (let propiedad in primerObjeto) {
      let valor = primerObjeto[propiedad];
      if (array.every(objeto => objeto[propiedad] === valor)) {
        datosComunes[propiedad] = valor;
      }
    }
    return datosComunes;
  };

  const datosGestSolicitudes = (lista) => {
    if (lista?.length > 0) {
      const pendientes = lista.filter((art) => art.estado === "aprobado");
      if (pendientes.length > 0) {
        setDocumentosFiltrados(pendientes.sort(sortBy("consecutivo")));
        setListaSolPend(pendientes.sort(sortBy("consecutivo")));
      }
    }
  };

  const cambioEstado = async (id, est) => {
    const valor = id.split("-")[0];
    const objEncon = solicitudes.find((obj) => obj.consecutivo === id);
    let newObj = { ...objEncon };
    delete newObj.consecutivo
    Object.keys(newObj).forEach((key) => {
      if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        let objetoModificado = { ...newObj[key] };
        objetoModificado.estado = est;
        objetoModificado.fecha_modificacion = fechaFormateada;
        objetoModificado.usuario_modificacion = user;
        newObj[key] = objetoModificado;
      }
    });
    setIsLoading(true)
    try {
      await actualizarDocumentoDatabaseIdCampo(`${emp}_solicitudes`, `${emp}_solicitudes`, id, newObj);
      await actualizarDocumentoDatabaseIdCampo(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, id, newObj);
      toast.success(`El estado ha sido modificado a '${est.toUpperCase()}' correctamente.`);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  };


  //-----Tablas--------
  const validarCheckboxes = (excluido, tipo) => {
    let contador = 0;
    if (tipo === "ver") {
      if (col2 && excluido !== 'col2') contador++;
      if (col3 && excluido !== 'col3') contador++;
      if (col4 && excluido !== 'col4') contador++;
      if (col5 && excluido !== 'col5') contador++;
      if (col6 && excluido !== 'col6') contador++;
      if (col7 && excluido !== 'col7') contador++;
      if (col8 && excluido !== 'col8') contador++;
      if (col9 && excluido !== 'col9') contador++;
      if (col10 && excluido !== 'col10') contador++;
      if (col11 && excluido !== 'col11') contador++;
      if (col12 && excluido !== 'col12') contador++;
      if (col13 && excluido !== 'col13') contador++;
      if (col14 && excluido !== 'col14') contador++;
      if (col15 && excluido !== 'col15') contador++;
      if (col16 && excluido !== 'col16') contador++;
      if (col17 && excluido !== 'col17') contador++;
      if (col18 && excluido !== 'col18') contador++;
      if (col19 && excluido !== 'col19') contador++;
      if (col20 && excluido !== 'col20') contador++;
    }
    if (tipo === "gest") {
      if (col21 && excluido !== 'col21') contador++;
      if (col22 && excluido !== 'col22') contador++;
      if (col23 && excluido !== 'col23') contador++;
      if (col24 && excluido !== 'col24') contador++;
      if (col25 && excluido !== 'col25') contador++;
      if (col26 && excluido !== 'col26') contador++;
      if (col27 && excluido !== 'col27') contador++;
      if (col28 && excluido !== 'col28') contador++;
      if (col29 && excluido !== 'col29') contador++;
      if (col30 && excluido !== 'col30') contador++;
      if (col31 && excluido !== 'col31') contador++;
      if (col32 && excluido !== 'col32') contador++;
      if (col33 && excluido !== 'col33') contador++;
      if (col34 && excluido !== 'col34') contador++;
      if (col35 && excluido !== 'col35') contador++;
    }
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //-------Paginación-----------
  useEffect(() => {
    if (opcionSeleccionada === "consulta_sol") {
      setTotalPaginas(Math.ceil(documentosFiltradosAll.length / registrosPorPagina))
    };
  }, [registrosPorPagina, documentosFiltradosAll, opcionSeleccionada]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina);
    const fin = inicio + parseInt(registrosPorPagina);
    setRegistrosPaginaActualAll(documentosFiltradosAll.slice(inicio, fin));
  }, [paginaActual, totalPaginas, carga]);

  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina));
  }, [registrosPorPagina, opcionSeleccionada, listaSolPend, documentosFiltrados]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina);
    const fin = inicio + parseInt(registrosPorPagina);
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin));
  }, [paginaActual, totalPaginas, carga]);

  const ordenar = (columna) => {

    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    // const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    const temporal = listaSolicitudes.sort(sortBy(direccion + columna))
    setListaSolicitudes(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  const eliminarSolicitud = (consec) => {
    const valor = consec.split("-")[0];
    toast.error(
      <div className="text-center">
        Tenga en cuenta que se eliminará el registro y esta accion no se puede revertir,<br />¿Estás seguro de eliminar la solicitud <strong>{consec}</strong>?,
        <div className="row mt-3 mb-2">
          <div className="col">
            <button
              className="btn btn-md btn-outline-danger"
              onClick={() => {
                eliminarDocumentoDatabaseCampo(`${emp}_solicitudes`, `${emp}_solicitudes`, consec);
                eliminarDocumentoDatabaseCampo(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, consec);
                toast.success(`Solicitud ${consec} eliminada correctamente`)
              }}>
              Aceptar
            </button>
          </div>
          <div className="col">
            <button className="btn btn-md btn-outline-info" onClick={toast.dismiss}>
              Cancelar
            </button>
          </div>
        </div>
      </div>,
      { autoClose: false }
    );
  };

const formatExcel = (lista) => {
  const data = lista?.map((item) => {
    return {
      "Consecutivo Artículo": item?.articulo,
      "Consecutivo Solicitud": item?.consecutivo,
      "Tipo": item?.detalle?.tipo,
      "Estado": item?.estadoMov,
      "Artículo": item?.des_Larga,
      "Clasificación 1": item?.clasificacion1,
      "Clasificación 2": item?.clasificacion2,
      "Clasificación 3": item?.clasificacion3,
      "Existe": item?.detalle?.ArtExiste ? "SI" : "NO",
      "En Destino": item?.detalle?.destino.split("-")[0] === "BDG" ? item?.totDispDest : item?.totResDest,
      "En Proyecto": item?.totalReservada,
      "En Bodega": item?.totalDisponible,
      "Disponible": item?.totalDisponible,
      "Solicitado": item?.detalle?.cantidadSolicitada,
      "Faltante": item?.falta,
      "Destino": item?.detalle?.destino,
      "Ubicación Destino": item?.detalle?.ubicacionDestino,
      "Fecha": item?.detalle?.fecha_creacion,
      "Solicitante": item?.detalle?.solicitante,
    }
  });
  if (data.length > 0) {
    exportToExcel(data, 'datos.xlsx')
  }
};

  //********************************************* */
  return (
    <>
      {isLoading && <LoaderES />}

      <div
        className="fixed-top mt-2"
        style={{
          backgroundColor: "white",
          marginLeft: broken || mobile ? "" : !collapsed ? "250px" : "80px",
          transition: "margin-left 0.3s ease",
          top: "46px",
          zIndex: 1,
        }}
      >
        {/* botones de opcion */}
        <div className="row ms-2 me-2">
          <div className="col-4 align-self-start text-start">
            <div className="btn-group">
              {/* Columnas */}
              <button
                className="btn fa-solid fa-table-columns"
                title="Seleccionar Columnas"
                data-bs-toggle="modal"
                data-bs-target="#ModificarColumnas"
                id="modalcolumnas"
              />

              <button className="btn fa-solid fa-file-excel"
                onClick={() => {
                  formatExcel(registrosPaginaActual)
                }}
                style={{ cursor: 'pointer', color: 'green' }}
                title='Exportar a Excel'
              />
            </div>
          </div>

          {/* filtrar */}
          <div className="col-8">
            <Filtrar2
              tabla="myTable"
              documentos={opcionSeleccionada === "consulta_sol" ? listaSolicitudes : listaSolPend}
              setDocumentosFiltrados={setDocumentosFiltrados}
              setRegistrosFiltrados={setRegistrosPaginaActual}
              registrosPorPagina={registrosPorPagina}
            />
          </div>
        </div>
      </div>

      <hr className={"text-light mt-5 mb-4" + (mobile ? " mb-4" : "")} />

      {/* Menu header */}
      <ul className="nav nav-tabs mt-3 mb-0">
        <li
          className="nav-item user-select-none"
          onClick={() => {
            permisos.includes("ingrVerSol") && setOpcionSeleccionada("consulta_sol")
          }}>
          <p className={"nav-link " + (opcionSeleccionada === "consulta_sol" ? "active" : "")}
            style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Solicitudes</p>
        </li>

        <li
          className="nav-item user-select-none"
          onClick={() => {
            if (permisos.includes("ingrGestSol")) {
              setOpcionSeleccionada("gestion_sol")
              datosGestSolicitudes(registrosPaginaActualAll)
            }
          }}>
          <p className={"nav-link " + (opcionSeleccionada === "gestion_sol" ? "active" : "")}
            style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Gestion Solicitudes </p>
        </li>
      </ul>

      {mobile
        ? <>
          {permisos.includes("ingrVerSol") &&
            opcionSeleccionada === "consulta_sol" &&
            (
              (registrosPaginaActualAll && registrosPaginaActualAll.length > 0) && (
                registrosPaginaActualAll.map((ls, index) => (
                  <div key={ls.consecutivo + index}>
                    <table className="table table-sm table-hover table-striped mb-0 mt-3" >
                      <thead>
                        <tr key={ls.consecutivo}>
                          <th style={{ width: "50px", resize: "none" }}>
                            <button className="btn  fa-solid fa-chevron-down btn-sm"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseItem" + ls.consecutivo + ls.estado}
                              aria-expanded="false"
                              aria-controls="collapseItem" />
                          </th>
                          <th style={{ resize: "none" }}>{ls.consecutivo}:</th>
                          <td style={{ minWidth: "200px" }}>
                            <div className="btn-group">

                              {/* BTN Aprobar */}
                              {permisos.includes("aprobarSol") ?
                                ls.estado === "desaprobado" || (ls.estado === "solicitado" && ls.estado !== "gestionado") ? (
                                  <button
                                    className="btn btn-sm btn-outline-primary  fa-regular fa-thumbs-up"
                                    title="Aprobar Solicitud"
                                    onClick={() => {
                                      cambioEstado(ls.consecutivo, "aprobado");
                                    }}
                                  />
                                ) : null
                                : null}

                              {/* BTN Desaprobar */}
                              {permisos.includes("desaprobarSol") ?
                                ls.estado === "aprobado" && ls.estado !== "gestionado" ? (
                                  <button
                                    className="btn btn-sm btn-outline-warning fa-regular fa-thumbs-down"
                                    title="Desaprobar Solicitud"
                                    onClick={() => {
                                      cambioEstado(ls.consecutivo, "desaprobado");
                                    }}
                                  />
                                ) : null
                                : null}

                              {/* BTN Cancelar solicitud */}
                              {permisos.includes("eliminarSol") ?
                                ls.estado === "solicitado" || ls.estado === "desaprobado" && ls.estado !== "gestionado" ? (
                                  <button
                                    className="btn btn-sm btn-outline-danger fa-solid fa-ban"
                                    title="Cancelar Solicitud"
                                    onClick={() => {
                                      eliminarSolicitud(ls.consecutivo)
                                    }}
                                  />
                                ) : null
                                : null}
                            </div>
                          </td>
                        </tr>
                      </thead>
                    </table>

                    <div className="collapse" id={"collapseItem" + ls.consecutivo + ls.estado}>
                      <div className="card card-body">
                        {ls.items.map((li, index) =>
                          <table className="table table-sm "/*table-bordered*/>
                            {index > 0 &&
                              <thead>
                                <tr>
                                  <th colSpan={19}>
                                    <hr className="border border-primary border-2 opacity-75 mt-1 rounded-4" />
                                  </th>
                                </tr>
                              </thead>
                            }
                            <tbody>
                              {!col2 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Consec. Art</th>
                                  <td>{li.consecutivoFix}</td>
                                </tr>}
                              {!col3 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Consec. Sol</th>
                                  <td>{li.consecutivoFixSol}</td>
                                </tr>}
                              {!col4 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Tipo</th>
                                  <td>{li.tipo}</td>
                                </tr>
                              }
                              {!col5 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td>{li.estado}</td>
                                </tr>
                              }
                              {!col6 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Artículo</th>
                                  <td className={(li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : li.estadoArt === "INACTIVO" ? " text-danger d-none" : ""}>{li.des_Larga}</td>
                                </tr>
                              }
                              {!col7 ? null :
                                <tr>

                                  <th>Clasificacion 1 </th>
                                  <td >{li.clasificacion1}</td>
                                </tr>
                              }
                              {!col8 ? null :
                                <tr>

                                  <th>Clasificacion 2 </th>
                                  <td >{li.clasificacion2}</td>
                                </tr>
                              }
                              {!col9 ? null :
                                <tr>

                                  <th>Clasificacion 3 </th>
                                  <td >{li.clasificacion3}</td>
                                </tr>
                              }
                              {!col10 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Existe</th>
                                  <td>{li.ArtExiste ? "SI" : "NO"}</td>
                                </tr>
                              }
                              {!col11 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>En Destino</th>
                                  <td>{li.destino.split("-")[0] === "BDG" ? li.totDispDest : li.totResDest}</td>
                                </tr>
                              }
                              {!col12 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>En Proy.</th>
                                  <td>{li.totalReservada}</td>
                                </tr>
                              }
                              {!col13 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>En Bodg.</th>
                                  <td>{li.totalDisponible}</td>
                                </tr>
                              }
                              {!col14 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Disponible</th>
                                  <td>{li.totalDisponible}</td>
                                </tr>
                              }
                              {!col15 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Solicitado</th>
                                  <td>{li.cantidadSolicitada}</td>
                                </tr>
                              }
                              {!col16 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Faltante</th>
                                  <td className={li.falta < 0 && "bg-danger text-light text-center"}>{li.falta}</td>
                                </tr>
                              }
                              {!col17 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Destino</th>
                                  <td>{li.destino}</td>
                                </tr>
                              }
                              {!col18 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Ubic. Destino</th>
                                  <td>{li.ubicacionDestino}</td>
                                </tr>
                              }
                              {!col19 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Fecha</th>
                                  <td>{li.fecha_creacion}</td>
                                </tr>
                              }
                              {!col20 ? null :
                                <tr>

                                  <th style={{ resize: "none" }}>Solicitante</th>
                                  <td>{li.solicitante}</td>
                                </tr>
                              }

                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>

                ))
              )
            )}

          {permisos.includes("ingrGestSol") &&
            opcionSeleccionada === "gestion_sol" &&
            (
              listaSolPend && listaSolPend.length > 0 && (
                registrosPaginaActual?.map((ls, index) => (
                  <div key={ls.consecutivo + index}>
                    <table className="table table-sm table-hover table-striped mb-0 mt-3" >
                      <thead>
                        <tr key={ls.consecutivo}>
                          <th style={{ width: "50px", resize: "none" }}>
                            <button className="btn  fa-solid fa-chevron-down btn-sm"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseItem" + ls.consecutivo + index}
                              aria-expanded="false"
                              aria-controls="collapseItem" />
                          </th>
                          <th style={{ resize: "none" }}>{ls.consecutivo}:</th>
                          <td style={{ minWidth: "200px" }}>
                            <div className="btn-group">
                              {permisos.includes("procesarSol") ?
                                <button
                                  className="btn btn-sm btn-outline-primary fa-solid fa-keyboard"
                                  onClick={() => {
                                    setComponente("gest_solicitud")
                                    history.push({
                                      pathname: "/gest_solicitud", state: {
                                        componente: "gest_solicitud",
                                        sol: ls?.consecutivo,
                                        origen: "ConsSolicitudes",
                                        listaSol: solicitudes,
                                        listaArt: listaArticulos,
                                        listaInv: listaInv,
                                        todasSol: todasSolicitudes,
                                      }
                                    })
                                  }}
                                />
                                : null}</div>
                          </td>
                        </tr>
                      </thead>
                    </table>

                    <div className="collapse" id={"collapseItem" + ls.consecutivo + index}>
                      <div className="card card-body">
                        {ls.items?.map((li, index) =>
                          <table className="table table-sm">
                            {index > 0 &&
                              <thead>
                                <tr key={ls.consecutivo + index}>
                                  <th colSpan={19}>
                                    <hr className="border border-primary border-2 opacity-75 mt-1 rounded-4" />
                                  </th>
                                </tr>
                              </thead>
                            }
                            <tbody>
                              {!col21 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Consec. Art</th>
                                  <td>{li.consecutivoFix}</td>
                                </tr>
                              }
                              {!col22 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Consec. Sol</th>
                                  <td>{li.consecutivoFixSol}</td>
                                </tr>}
                              {!col23 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Estado</th>
                                  <td>{li.estado}</td>
                                </tr>
                              }
                              {!col24 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Fecha Sol.</th>
                                  <td>{li.fecha_creacion}</td>
                                </tr>
                              }
                              {!col25 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Solicitante</th>
                                  <td>{li.solicitante}</td>
                                </tr>
                              }
                              {!col26 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Tipo</th>
                                  <td>{li.tipo}</td>
                                </tr>
                              }
                              {!col27 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Artículo</th>
                                  <td className={(li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : li.estadoArt === "INACTIVO" ? " text-danger d-none" : ""}>{li.des_Larga}</td>
                                </tr>
                              }
                              {!col28 ? null :
                                <tr>
                                  <th>Clasificacion 1 </th>
                                  <td >{li.clasificacion1}</td>
                                </tr>
                              }
                              {!col29 ? null :
                                <tr>
                                  <th>Clasificacion 2 </th>
                                  <td >{li.clasificacion2}</td>
                                </tr>
                              }
                              {!col30 ? null :
                                <tr>
                                  <th>Clasificacion 3 </th>
                                  <td >{li.clasificacion3}</td>
                                </tr>
                              }
                              {!col31 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Cant. Disponible</th>
                                  <td>{li.totalDisponible}</td>
                                </tr>
                              }
                              {!col32 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Cant. Solicitada</th>
                                  <td>{li.cantidadSolicitada}</td>
                                </tr>
                              }
                              {!col33 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Cant. Faltante</th>
                                  <td className={li.falta < 0 && "bg-danger text-light text-center"}>{li.falta}</td>
                                </tr>
                              }
                              {!col34 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Destino</th>
                                  <td>{li.destino}</td>
                                </tr>
                              }
                              {!col35 ? null :
                                <tr>
                                  <th style={{ resize: "none" }}>Ubic. Destino</th>
                                  <td>{li.ubicacionDestino}</td>
                                </tr>
                              }

                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )
            )}
        </>
        :
        <>
          {opcionSeleccionada === "consulta_sol" && <h5 className="my-3">Todas las Solicitudes</h5>}
          {permisos.includes("ingrVerSol") && opcionSeleccionada === "consulta_sol" && registrosPaginaActualAll && registrosPaginaActualAll.length > 0 &&
            registrosPaginaActualAll.map((ls) => (
              <div className="table-responsive abs-center-table mt-3" key={ls.consecutivo + ls.datosComunes.articulo}>
                <table
                  id="myTable"
                  className="table table-sm table-hover table-striped  table-bordered">

                  <thead className="text-center">
                    <tr key={ls.consecutivo}>
                      <th colSpan={3}>{`Consec. Sol: ${ls.consecutivo}`}</th>
                      <th colSpan={3}>{`Estado: ${ls.estado}`}</th>
                      <th colSpan={3}>{`Fecha Sol: ${ls.datosComunes.fecha_creacion}`}</th>
                      <th colSpan={3}>{`Solicitante: ${ls.datosComunes.solicitante}`}</th>
                      <th colSpan={3}>
                        <div className="btn-group">

                          {/* BTN Aprobar */}
                          {permisos.includes("aprobarSol") && (ls.estado === "desaprobado" || ls.estado === "solicitado") && (
                            <button
                              className="btn btn-sm btn-outline-primary fa-regular fa-thumbs-up"
                              title="Aprobar Solicitud"
                              onClick={() => cambioEstado(ls.consecutivo, "aprobado")}
                            />
                          )}

                          {/* BTN Desaprobar */}
                          {permisos.includes("desaprobarSol") && ls.estado === "aprobado" && ls.estado !== "gestionado" && (
                            <button
                              className="btn btn-sm btn-outline-warning fa-regular fa-thumbs-down"
                              title="Desaprobar Solicitud"
                              onClick={() => {
                                cambioEstado(ls.consecutivo, "desaprobado")
                                setListaSolPend([])
                              }}
                            />
                          )}

                          {/* BTN Cancelar solicitud */}
                          {permisos.includes("eliminarSol") &&
                            (ls.estado === "solicitado" || ls.estado === "desaprobado" && ls.estado !== "gestionado") && (
                              <button
                                className="btn btn-sm btn-outline-danger fa-solid fa-ban"
                                title="Cancelar Solicitud"
                                onClick={() => eliminarSolicitud(ls.consecutivo)}
                              />
                            )}

                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th onClick={() => ordenar("consecutivoFix")} className={col2 ? "text-center" : "d-none"}>Consec. Art</th>
                      <th onClick={() => ordenar("detalle.tipo")} className={col4 ? "text-center" : "d-none"}>Tipo</th>
                      <th onClick={() => ordenar("des_Larga")} className={col6 ? "text-center" : "d-none"}>Artículo</th>
                      <th onClick={() => ordenar("clasificacion1")} className={col7 ? "" : "d-none"}>Clasificacion 1</th>
                      <th onClick={() => ordenar("clasificacion2")} className={col8 ? "" : "d-none"}>Clasificacion 2</th>
                      <th onClick={() => ordenar("clasificacion3")} className={col9 ? "" : "d-none"}>Clasificacion 3</th>
                      <th onClick={() => ordenar("detalle.ArtExiste")} className={col10 ? "text-center" : "d-none"}>Existe</th>
                      <th className={col11 ? "text-center" : "d-none"}>En Destino</th>
                      <th className={col12 ? "text-center" : "d-none"}>En Proy.</th>
                      <th className={col13 ? "text-center" : "d-none"}>En Bodg.</th>
                      <th className={col14 ? "text-center" : "d-none"}>Disponible</th>
                      <th onClick={() => ordenar("detalle.cantidadSolicitada")} className={col15 ? "text-center" : "d-none"}>Solicitado</th>
                      <th className={col16 ? "text-center" : "d-none"}>Faltante</th>
                      <th onClick={() => ordenar("detalle.destino")} className={col17 ? "text-center" : "d-none"}>Destino</th>
                      <th onClick={() => ordenar("detalle.ubicacionDestino")} className={col18 ? "text-center" : "d-none"}>Ubic. Destino</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActualAll && registrosPaginaActualAll.length > 0 ?
                      ls.items.map((li) => (
                        <tr key={li.idArticulo + "-" + li.destino + li.consecutivo}>
                          <td className={col2 ? "text-center" : "d-none"}>{li.consecutivoFix}</td>
                          <td className={col4 ? "text-center" : "d-none"}>{li.tipo}</td>
                          <td className={(col6 && li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : !col6 ? " text-danger d-none" : col6 ? "" : "d-none"} title={li.estadoArt === "INACTIVO" && "Artículo inactivo"}>{li.des_Larga}</td>
                          <td className={col7 ? "text-center" : "d-none"}>{li.clasificacion1}</td>
                          <td className={col8 ? "text-center" : "d-none"}>{li.clasificacion2}</td>
                          <td className={col9 ? "text-center" : "d-none"}>{li.clasificacion3}</td>
                          <td className={col10 ? "text-center" : "d-none"}>{li.ArtExiste ? "SI" : "NO"}</td>
                          <td className={col11 ? "text-center" : "d-none"}>{li.destino.split("-")[0] === "BDG" ? li.totDispDest : li.totResDest}</td>
                          <td className={col12 ? "text-center" : "d-none"}>{li.totalReservada}</td>
                          <td className={col13 ? "text-center" : "d-none"}>{li.totalDisponible}</td>
                          <td className={col14 ? "text-center" : "d-none"}>{li.totalDisponible}</td>
                          <td className={col15 ? "text-center" : "d-none"}>{li.cantidadSolicitada}</td>
                          <td className={col16 ? (li.falta < 0 && ls.estado !== "procesado") && "bg-danger text-light text-center" : "d-none"}
                            title={li.falta < 0 && "No ha y la cantidad suficiente para gestionar la solicitud, debe cargar en inventario."}>{li.falta}</td>
                          <td className={col17 ? "text-center" : "d-none"}>{li.destino}</td>
                          <td className={col18 ? "text-center" : "d-none"}>{li.ubicacionDestino}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            ))
          }

          {/* ************************//**********************/}

          {opcionSeleccionada === "gestion_sol" && <h5 h5 className="my-3">Solicitudes Aprobadas</h5>}
          {permisos.includes("ingrGestSol") && opcionSeleccionada === "gestion_sol" &&
            listaSolPend && listaSolPend.length > 0 &&
            registrosPaginaActual?.map((ls) => (
              <div className="table-responsive abs-center-table mt-3">
                <table className="table table-sm table-hover table-striped table-bordered"
                  id="myTable">
                  {/* Tabla procesar */}
                  <thead className="text-center">

                    <tr key={ls.consecutivo}>
                      <th colSpan={2}>{`Consec. Sol: ${ls?.consecutivo}`}</th>
                      <th colSpan={2}>{`Estado: ${ls?.estado}`}</th>
                      <th colSpan={2}>{`Fecha Sol: ${ls.datosComunes?.fecha_creacion}`}</th>
                      <th colSpan={3}>{`Solicitante: ${ls.datosComunes?.solicitante}`}</th>
                      <th colSpan={2}>
                        <div className="btn-group">
                          {permisos.includes("procesarSol") ?
                            <button
                              className="btn btn-sm btn-outline-primary fa-solid fa-keyboard"
                              onClick={() => {
                                setComponente("gest_solicitud")
                                history.push({
                                  pathname: "/gest_solicitud", state: {
                                    componente: "gest_solicitud",
                                    sol: ls.consecutivo,
                                    origen: "ConsSolicitudes",
                                    listaSol: solicitudes,
                                    listaArt: listaArticulos,
                                    listaInv: listaInv,
                                    todasSol: todasSolicitudes,
                                  }
                                })
                              }}
                            />
                            : null}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th onClick={() => ordenar("consecutivoFix")} className={col21 ? "text-center" : "d-none"}>Consec. Art</th>
                      <th onClick={() => ordenar("detalle.tipo")} className={col26 ? "text-center" : "d-none"}>Tipo</th>
                      <th onClick={() => ordenar("des_Larga")} className={col27 ? "text-center" : "d-none"}>Artículo</th>
                      <th onClick={() => ordenar("clasificacion1")} className={col28 ? "" : "d-none"}>Clasificacion 1</th>
                      <th onClick={() => ordenar("clasificacion2")} className={col29 ? "" : "d-none"}>Clasificacion 2</th>
                      <th onClick={() => ordenar("clasificacion3")} className={col30 ? "" : "d-none"}>Clasificacion 3</th>
                      <th className={col31 ? "text-center" : "d-none"}>Cant. <br /> Disponible</th>
                      <th onClick={() => ordenar("detalle.cantidadSolicitada")} className={col32 ? "text-center" : "d-none"}>Cant. <br /> Solicitada</th>
                      <th className={col33 ? "text-center" : "d-none"}>Cant. <br />Faltante </th>
                      <th onClick={() => ordenar("detalle.destino")} className={col34 ? "text-center" : "d-none"}>Destino</th>
                      <th onClick={() => ordenar("detalle.ubicacionDestino")} className={col35 ? "text-center" : "d-none"}>Ubic. Destino</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ls.items?.map((li) => (
                      <tr key={li.idArticulo + li.destino + li.consecutivo} >
                        <td className={col21 ? "text-center" : "d-none"}>{li.consecutivoFix}</td>
                        <td className={col26 ? "text-center" : "d-none"}>{li.tipo}</td>
                        <td className={(col27 && li.estadoArt === "INACTIVO") ? "text-danger user-select-none" : !col27 ? " text-danger d-none" : col27 ? "" : "d-none"} title={li.estadoArt === "INACTIVO" && "Artículo inactivo"}>{li.des_Larga}</td>
                        <td className={col28 ? "text-center" : "d-none"}>{li.clasificacion1}</td>
                        <td className={col29 ? "text-center" : "d-none"}>{li.clasificacion2}</td>
                        <td className={col30 ? "text-center" : "d-none"}>{li.clasificacion3}</td>
                        <td className={col31 ? "text-center" : "d-none"}>{li.totalDisponible}</td>
                        <td className={col32 ? "text-center" : "d-none"}>{li.cantidadSolicitada}</td>
                        <td className={col33 ? "text-center" : "d-none"}>{li.falta}</td>
                        <td className={col34 ? "text-center" : "d-none"}>{li.destino}</td>
                        <td className={col35 ? "text-center" : "d-none"}>{li.ubicacionDestino}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))
          }
        </>}

      <Pagination
        paginaActual={paginaActual}
        totalPaginas={totalPaginas}
        setPaginaActual={setPaginaActual}
        registrosPorPagina={registrosPorPagina}
        setRegistrosPorPagina={setRegistrosPorPagina}
        documentosFiltrados={opcionSeleccionada === "consulta_sol" ? documentosFiltradosAll : documentosFiltrados}
      />

      {/* Modal Columnas */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModificarColumnas"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title" id="staticBackdropLabel3">
                Selección de columnas {opcionSeleccionada === "consulta_sol" ? "Solicitudes" : "Gestion Sol."}
              </h5>
            </div>

            <div className="modal-body">

              {opcionSeleccionada === "consulta_sol" ?
                <>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2', "ver")} agregarColumna={agregarColumna} texto={"Consecutivo Art"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3', "ver")} agregarColumna={agregarColumna} texto={"Consecutivo Sol"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4', "ver")} agregarColumna={agregarColumna} texto={"Tipo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5', "ver")} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6', "ver")} agregarColumna={agregarColumna} texto={"Artículo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7', "ver")} agregarColumna={agregarColumna} texto={"Clasificacion 1"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8', "ver")} agregarColumna={agregarColumna} texto={"Clasificacion 2"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9', "ver")} agregarColumna={agregarColumna} texto={"Clasificacion 3"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10', "ver")} agregarColumna={agregarColumna} texto={"Existe"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11', "ver")} agregarColumna={agregarColumna} texto={"En Destino"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12', "ver")} agregarColumna={agregarColumna} texto={"En Proyecto"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13', "ver")} agregarColumna={agregarColumna} texto={"En Bodega"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14', "ver")} agregarColumna={agregarColumna} texto={"Disponible"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={15} checked={col15} setChecked={setCol15} validar={() => validarCheckboxes('col15', "ver")} agregarColumna={agregarColumna} texto={"Solicitado"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={16} checked={col16} setChecked={setCol16} validar={() => validarCheckboxes('col16', "ver")} agregarColumna={agregarColumna} texto={"Faltante"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={17} checked={col17} setChecked={setCol17} validar={() => validarCheckboxes('col17', "ver")} agregarColumna={agregarColumna} texto={"Destino"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={18} checked={col18} setChecked={setCol18} validar={() => validarCheckboxes('col18', "ver")} agregarColumna={agregarColumna} texto={"Ubicacion Destino"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={19} checked={col19} setChecked={setCol19} validar={() => validarCheckboxes('col19', "ver")} agregarColumna={agregarColumna} texto={"Fecha"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={20} checked={col20} setChecked={setCol20} validar={() => validarCheckboxes('col20', "ver")} agregarColumna={agregarColumna} texto={"Solicitante"} />
                  </div>
                </>
                :
                <>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={21} checked={col21} setChecked={setCol21} validar={() => validarCheckboxes('col21', "gest")} agregarColumna={agregarColumna} texto={" Consec. Art"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={22} checked={col22} setChecked={setCol22} validar={() => validarCheckboxes('col22', "gest")} agregarColumna={agregarColumna} texto={"Consec. Sol"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={23} checked={col23} setChecked={setCol23} validar={() => validarCheckboxes('col23', "gest")} agregarColumna={agregarColumna} texto={"Estado"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={24} checked={col24} setChecked={setCol24} validar={() => validarCheckboxes('col24', "gest")} agregarColumna={agregarColumna} texto={"Fecha Sol."} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={25} checked={col25} setChecked={setCol25} validar={() => validarCheckboxes('col25', "gest")} agregarColumna={agregarColumna} texto={"Solicitante"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={26} checked={col26} setChecked={setCol26} validar={() => validarCheckboxes('col26', "gest")} agregarColumna={agregarColumna} texto={"Tipo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={27} checked={col27} setChecked={setCol27} validar={() => validarCheckboxes('col27', "gest")} agregarColumna={agregarColumna} texto={"Artículo"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={28} checked={col28} setChecked={setCol28} validar={() => validarCheckboxes('col28', "gest")} agregarColumna={agregarColumna} texto={"Clasificacion 1"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={29} checked={col29} setChecked={setCol29} validar={() => validarCheckboxes('col29', "gest")} agregarColumna={agregarColumna} texto={"Clasificacion 2"} />
                  </div>

                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={30} checked={col30} setChecked={setCol30} validar={() => validarCheckboxes('col30', "gest")} agregarColumna={agregarColumna} texto={"Clasificacion 3"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={31} checked={col31} setChecked={setCol31} validar={() => validarCheckboxes('col31', "gest")} agregarColumna={agregarColumna} texto={"Cant. Disponible"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={32} checked={col32} setChecked={setCol32} validar={() => validarCheckboxes('col32', "gest")} agregarColumna={agregarColumna} texto={"Cant. Solicitada"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={33} checked={col33} setChecked={setCol33} validar={() => validarCheckboxes('col33', "gest")} agregarColumna={agregarColumna} texto={"Cant. Faltante"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={34} checked={col34} setChecked={setCol34} validar={() => validarCheckboxes('col34', "gest")} agregarColumna={agregarColumna} texto={"Destino"} />
                  </div>
                  <div className="form-check">
                    <CheckboxCol tabla="consSolicitudes" columna={35} checked={col35} setChecked={setCol35} validar={() => validarCheckboxes('col35', "gest")} agregarColumna={agregarColumna} texto={"Ubic. destino"} />
                  </div>


                </>}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
