import React from 'react';

export const VerImagen = ({ url, show, handleClose, nombre }) => {

    return (
        <>
            <div className="modal" tabIndex="-1" id="myModal" style={show ? { display: 'block' } : { display: 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header bg-primary text-light ">
                            <h5 className="modal-title ">Imágen Artículo</h5>
                            <button className="btn fa-solid fa-xmark text-light fs-4" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                        </div>

                        <div className="modal-body">
                            <img src={url} alt="Mi imagen" style={{
                                "display": "block", "margin-left": "auto",
                                "margin-right": "auto", "width": "40%",
                                "height": "auto"
                            }} />
                            <p className="mt-3"><strong>{nombre}</strong></p>
                        </div>

                    </div>
                </div>
            </div>
            <div className={show ? "modal-backdrop show" : ""}></div>
        </>
    )
}
