import sortBy from "sort-by";
import { actualizarDocumentoDatabase, consultarDatabase, consultarDocumentoWhere, consultarDocumentoWhereIgual } from "../firebase";
import * as XLSX from 'xlsx';


export const styles = {
    control: (base) => ({
        ...base,
        height: 30,
        minHeight: 32,
        fontSize: '13px',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '13px',
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    menu: (base) => ({
        ...base,
        fontSize: '13px',
    }),
};

export const convertirDate = (fec) => {
    let fechaLeg
    let fecha = new Date(fec)
    fechaLeg = fecha.toLocaleString();
    if (fechaLeg !== "Invalid Date") {
        return fechaLeg
    } else {
        return ("Sin Ingreso Registrado")
    }
};

export const fixConsecutivo = (consecutivo) => {
    const prefijo = consecutivo.split('-')[0]
    const numero = consecutivo.split('-')[1]
    const largoNumero = numero.length
    let consecutivoNuevo
    switch (largoNumero) {
        case 1:
            consecutivoNuevo = `${prefijo}-000${numero}`
            break;
        case 2:
            consecutivoNuevo = `${prefijo}-00${numero}`
            break;
        case 3:
            consecutivoNuevo = `${prefijo}-0${numero}`
            break;
        default:
            consecutivoNuevo = `${prefijo}-${numero}`
            break;
    }
    return consecutivoNuevo
};

export const isMobile = (setMobile) => {
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
        window.removeEventListener("resize", handleResize);
    };
};

//-------- Columnas ---------
export const handleColum = (setters, user, tabla) => {
    setters.forEach((setter, index) => {
        setEstadoColum(setter, user, index + 2, tabla);
    });
};

const setEstadoColum = (setCol, user, unit, tabla) => {
    const value = localStorage.getItem(`${user}/${tabla}/${unit}`);
    setCol(value === "false" ? false : true);
};

//---------- Permisos -----------
export const cargarPermisos = async (user, setPermisos) => {
    try {
        const temporal = await consultarDocumentoWhereIgual("usuarios", "usuario", user)
        setPermisos(temporal[0]?.permisos ? temporal[0].permisos : [])
    } catch (error) {
        console.error(error)
    }
};

export const aplanarObjetos = (obj) => {
    let listaTempMovimientos = obj.reduce((acc, item) => {
        if (item && typeof item === 'object' && !Array.isArray(item)) {
            for (let key in item) {
                if (item[key] && typeof item[key] === 'object' && !Array.isArray(item[key])) {
                    acc.push(item[key]);
                }
            }
        }
        return acc;
    }, []);
    return listaTempMovimientos
};

export const traerTabla = async (emp, tabla) => {
    const listaTemp = await consultarDatabase(`${emp}_${tabla}`);
    return listaTemp
};

export const cargarConsecutivos = async (clase, emp, setLista) => {
    try {
        const listaTemporal = await consultarDocumentoWhere(`${emp}_consecutivos`, "clase", clase);
        const activos = listaTemporal.filter((a) => a.estado === "ACTIVO")
        if (activos.length > 0) {
            setLista(activos.sort(sortBy("prefijo")));
        } else {
            return "No hay consecutivos disponibles"
        }
    } catch (error) {
        console.error(error)
    }
};

export const handleModificarConsecutivo = async (valor, user, fecha, idDoc, emp) => {
    const val = parseInt(valor.split("-")[1]);
    const valorActual = {
        valorActual: val,
        usuario_modificacion: user.toUpperCase(),
        fecha_modificacion: fecha,
    };
    try {
        await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, valorActual);
    } catch (error) {
        console.error(error)
    }
};

export const changeTitle = async (titulo) => {
    document.title = titulo;
};

//------------------------------------------------------------------------------------------
// esta función se encarga de exportar a excel un array de objetos
export const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
}