import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";

import {
  actualizarDocumentoDatabase,
  consultarDatabase,
  guardarDatabase
} from "../../config/firebase";

import { cargarConsecutivos, changeTitle, handleColum, handleModificarConsecutivo, isMobile, styles } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "../miniComponentes";

export const Proyectos = ({ terceros, permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaResponsables, setListaResponsables] = useState([]);
  const [listaConsecutivos, setListaConsecutivos] = useState([]);
  const [listaConsecTem, setListaConsecTem] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);

  const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
  const [valorConsecutivo, setValorConsecutivo] = useState(0);
  const [responsableM, setResponsableM] = useState("");
  const [idDocumento, setIdDocumento] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [responsable, setResponsable] = useState("");
  const [consecutivo, setConsecutivo] = useState("");
  const [direccion, setDireccion] = useState("");
  const [estado, setEstado] = useState("ACTIVO");
  const [cliente, setCliente] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [idProy, setIdProy] = useState("");

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const [responsables, setResponsables] = useState(listaResponsables);
  const [clientes, setClientes] = useState(listaClientes);
  const [idResponsable, setIdResponsable] = useState("");
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [adicionar, setAdicionar] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [mobile, setMobile] = useState(true);
  const tablaRef = useRef(null);


  //***********Funciones***********
  useEffect(() => {
    changeTitle("Admin. Proyectos");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7], user, "proyectos");
    cargarProyectos();
    cargarClientes();
    cargarConsecutivos("PROYECTO", emp, setListaConsecTem);
    isMobile(setMobile);
  };

  useEffect(() => {
    cargarClientes()
  }, [terceros]);

  useEffect(() => {
    if (adicionar === 1) {
      resetStates();
    }
  }, [adicionar]);

  useEffect(() => {
    const activos = listaConsecTem.filter((a) => a.estado == "ACTIVO");
    setListaConsecutivos(activos)
  }, [listaConsecTem])

  //-------------CRUD--------------------
  const cargarProyectos = async () => {
    setIsLoading(true);
    let temporal;
    try {
      const listaTempProyectos = await consultarDatabase(`${emp}_proyectos`);
      if (listaTempProyectos.length === 0) {
        temporal = [{ idResponsable: "Sin Datos" }];
      } else {
        temporal = listaTempProyectos.sort(sortBy("consecutivo"));
      }
      setListaProyectos(temporal);
      setDocumentosFiltrados(temporal)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }

  };

  const handleClickAdicionar = async () => {
    const respuesta = listaProyectos.find(p => p.descripcion === descripcion.toUpperCase() && p.ciudad === ciudad && p.cliente === cliente.label)

    if (respuesta !== undefined) {
      resetStates();
      toast.info("El proyecto: " + descripcion.toUpperCase() + " ya se encuentra registrado, intente de nuevo");
    } else {
      const rep = responsable.label;
      const cli = cliente.label;
      const enviarProyecto = {
        id: uniqid("proyecto-"),
        idResponsable,
        consecutivo: consecutivo.toUpperCase() + "-" + valorConsecutivo,
        descripcion,
        cliente: cli,
        ciudad,
        direccion,
        responsable: rep,
        estado,
        usuario_creacion: user,
        fecha_creacion: fechaFormateada,
      };
      if (estado === "" || direccion === "" || responsable === "" ||
        ciudad === "" || descripcion === "" || cliente === "") {
        toast.warning("Diligencie todos los campos de manera correcta");
      } else {
        setIsLoading(true)
        try {
          await guardarDatabase(`${emp}_proyectos`, enviarProyecto);
          await handleModificarConsecutivo(enviarProyecto.consecutivo, user, fechaFormateada, idDocumentoConsecutivo, emp);
          resetStates();
          toast.success("Proyecto registrado de manera correcta");
          await cargarProyectos();
          await cargarConsecutivos("PROYECTO", emp, setListaConsecutivos);

        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    }
  };

  const handleClickTraerData = (p) => {
    setIdDocumento(p.idDocumento);
    setConsecutivo(p.consecutivo);
    setDescripcion(p.descripcion);
    setCliente(p.cliente);
    setDireccion(p.direccion);
    setCiudad(p.ciudad);
    setResponsable(p.responsable);
    setEstado(p.estado);
    setIdResponsable(p.idResponsable);
    setIdProy(p.id);
  };

  const handleClickModificarBd = async () => {
    let rep = responsableM.label !== undefined ? responsableM.label : responsable;
    if (idDocumento && idDocumento !== undefined) {
      const enviarProyecto = {
        direccion,
        ciudad,
        idResponsable,
        responsable: rep,
        estado,
        id: idProy,
        usuario_modificacion: user,
        fecha_modificacion: fechaFormateada,
      };
      setIsLoading(true)
      try {
        await actualizarDocumentoDatabase(`${emp}_proyectos`, idDocumento, enviarProyecto);
        resetStates();
        toast.success("Proyecto Modificado correctamente");
        await cargarProyectos();

      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning("El proyecto no existe, porfavor verifique e intente de nuevo.")
    }
  };

  const resetStates = () => {
    setConsecutivo("");
    setDescripcion("");
    setValorConsecutivo("");
    setCliente("");
    setDireccion("");
    setCiudad("");
    setResponsable("");
    setResponsableM("");
    setIdResponsable("");
    setEstado("");
  };

  //----------Clientes//Responsables-------------
  const cargarClientes = async () => {
    const listaTemporal = terceros;
    const estadoResp = "ACTIVO";
    const tipoCliente = "CLIENTE";
    const tipoEmp = "EMPLEADO";
    try {
      const activosC = listaTemporal.filter((act) => act.estado === estadoResp && act.tipo === tipoCliente);
      const activosE = listaTemporal.filter((act) => act.estado === estadoResp && act.tipo === tipoEmp);
      setListaClientes(activosC);
      setListaResponsables(activosE);

    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    const options = listaClientes.map(cliente => ({ value: cliente.razonSocial, label: cliente.razonSocial }));
    setClientes(options?.sort(sortBy("value")))
  }, [listaClientes]);

  useEffect(() => {
    const options = listaResponsables.map(cliente => ({ value: cliente.razonSocial, label: cliente.razonSocial }));
    setResponsables(options?.sort(sortBy("value")))
  }, [listaResponsables]);

  //----------Consecutivos-------------
  useEffect(() => {
    const selectConsecutivo = document.getElementById("seleccioneConsecutivo");
    if (selectConsecutivo) {
      selectConsecutivo.innerHTML = "";
      listaConsecutivos.forEach((lc) => {
        let option = document.createElement("option");
        option.value = lc.prefijo;
        option.text = lc.descripcion;
        selectConsecutivo.appendChild(option);
      });
    }
    setRefresh(false);
  }, [listaConsecutivos, adicionar, refresh]);

  //---------- BLUR ----------
  const handleBlurDatalist = (datalistId, value, setValue) => {
    const datalist = document.getElementById(datalistId);
    const option = Array.from(datalist.options).find((opt) => opt.value === value);
    !option && setValue("");
  };

  //----------Columnas----------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaProyectos(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //---------- Paginación ----------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas]);



  //----------Componente----------
  return (
    <>
      {isLoading && <LoaderES />}

      <>
        {/* botones de opcion */}
        <div className="fixed-top mt-2"
          style={{
            backgroundColor: "white",
            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
            transition: "margin-left 0.3s ease",
            top: "46px",
            zIndex: 1
          }}>

          <div className="row ms-2 me-2">
            {/* botones de opcion */}
            <div className="col-sm-4 align-self-start text-start">
              <div className="btn-group">

                {/* Adicionar */}
                <button
                  className="btn fa-solid fa-plus"
                  disabled={!permisos.includes("crearProy")}
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarAdicionar"
                  id="modaladicionar"
                  title="Crear Nuevo Proyecto"
                  onClick={() => {
                    setAdicionar(1)
                    setRefresh(true)
                  }} />

                {/* Columnas */}
                <button
                  className="btn fa-solid fa-table-columns"
                  title="Seleccionar Columnas"
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarColumnas"
                  id="modalcolumnas" />

                {/* Actualizar */}
                <button
                  className="btn fa-solid fa-rotate"
                  title="Actualizar Datos"
                  onClick={() => cargarProyectos()} />
              </div>
            </div>

            {/* filtrar */}
            <div className="col-sm-8">
              <Filtrar2
                tabla="myTable"
                documentos={listaProyectos}
                setDocumentosFiltrados={setDocumentosFiltrados}
                setRegistrosFiltrados={setRegistrosPaginaActual}
                registrosPorPagina={registrosPorPagina} />
            </div>
          </div>

        </div>

        <hr className={(mobile ? "mt-5 mb-5" : "mt-5")} />
        {permisos.includes("verProy") &&
          mobile ?
          (<>
            {registrosPaginaActual.map((proyecto) => (
              proyecto.idResponsable === "Sin Datos" ? (
                <button
                  className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                  disabled={!permisos.includes("crearProy")}
                  data-bs-toggle="modal"
                  data-bs-target="#ModificarAdicionar"
                  id="modaladicionar"
                  title="Crear Nuevo Proyecto"
                  onClick={() => setAdicionar(1)} />)
                :
                <>
                  <table className="table table-sm table-hover table-striped mb-0"
                    key={proyecto.consecutivo}>
                    <thead>
                      <tr>
                        <th style={{ width: "50px", resize: "none" }}>
                          <button className="btn  fa-solid fa-chevron-down btn-sm"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseItem" + proyecto.consecutivo}
                            aria-expanded="false"
                            aria-controls="collapseItem" />
                        </th>
                        <th style={{ width: "65px", resize: "none" }}>{proyecto.consecutivo}:</th>
                        <td style={{ minWidth: "60px", resize: "none", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                          className={proyecto.estado !== "ACTIVO" ? "text-danger" : ""}>
                          <span className="truncate-text">
                            {proyecto.descripcion.length > 50 ? proyecto.descripcion.slice(0, 50) + "..." : proyecto.descripcion}
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>

                  <div className="collapse" id={"collapseItem" + proyecto.consecutivo}>
                    <div className="card card-body">
                      <table className="table table-sm" >
                        <tbody >
                          <DatoTablaMobile titulo={"Consecutivo"} dato={proyecto.consecutivo} columna={col2} />
                          <DatoTablaMobile titulo={"Descripcion"} dato={proyecto.descripcion} columna={col3} />
                          <DatoTablaMobile titulo={"Dirección"} dato={proyecto.direccion} columna={col4} />
                          <DatoTablaMobile titulo={"Ciudad"} dato={proyecto.ciudad} columna={col5} />
                          <DatoTablaMobile titulo={"Cliente"} dato={proyecto.cliente} columna={col6} />
                          <DatoTablaMobile titulo={"Responsable"} dato={proyecto.responsable} columna={col7} />
                          <DatoTablaMobile titulo={"Estado"} dato={proyecto.estado} columna={col8} clase={proyecto.estado !== "ACTIVO" ? "text-danger" : ""} />
                          <tr>
                            <td></td>
                            <th style={{ resize: "none" }}>Actions </th>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                  disabled={!permisos.includes("editarProy")}
                                  data-bs-toggle="modal"
                                  data-bs-target="#ModificarAdicionar"
                                  id="modalModificar"
                                  title="Modificar Item Seleccionado"
                                  onClick={() => {
                                    handleClickTraerData(proyecto);
                                    setAdicionar(0);
                                  }} />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
            ))}
          </>)
          :
          (<div className="table-responsive abs-center-table">
            {/* Tabla proyectos */}
            <table id="myTable" ref={tablaRef} className="table table-hover table-striped table-sm table-bordered mt-3" >
              <thead className="text-center">
                <tr>
                  <ThTablaH ordenar={ordenar} dOrdenar={"consecutivo"} columna={col2} titulo={"Consecutivo"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"descripcion"} columna={col3} titulo={"Descripcion"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"direccion"} columna={col4} titulo={"Dirección"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"ciudad"} columna={col5} titulo={"Ciudad"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"cliente"} columna={col6} titulo={"Cliente"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"responsable"} columna={col7} titulo={"Responsable"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"estado"} columna={col8} titulo={"Estado"} />
                  <th className="text-center">Edit</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {registrosPaginaActual.map((proyecto) => (
                  proyecto.idResponsable === "Sin Datos"
                    ? <tr>
                      <td colSpan={"100%"} className="text-center">
                        <button
                          className="btn fa-solid fa-plus"
                          disabled={!permisos.includes("crearProy")}
                          data-bs-toggle="modal"
                          data-bs-target="#ModificarAdicionar"
                          id="modaladicionar"
                          title="Crear Nuevo Proyecto"
                          onClick={() => setAdicionar(1)} />
                      </td>
                    </tr>
                    :
                    <tr key={proyecto.consecutivo}>
                      <TdTablaD columna={col2} dato={proyecto.consecutivo} clase={(proyecto.estado !== "ACTIVO" && "text-danger")} />
                      <TdTablaD columna={col3} dato={proyecto.descripcion} />
                      <TdTablaD columna={col4} dato={proyecto.direccion} />
                      <TdTablaD columna={col5} dato={proyecto.ciudad} />
                      <TdTablaD columna={col6} dato={proyecto.cliente} />
                      <TdTablaD columna={col7} dato={proyecto.responsable} />
                      <TdTablaD columna={col8} dato={proyecto.estado} clase={proyecto.estado !== "ACTIVO" && "text-danger"} />
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                            disabled={!permisos.includes("editarProy")}
                            data-bs-toggle="modal"
                            data-bs-target="#ModificarAdicionar"
                            id="modalModificar"
                            title="Modificar Item Seleccionado"
                            onClick={() => {
                              handleClickTraerData(proyecto);
                              setAdicionar(0);
                            }} />
                        </div>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
          </div>)}

        <Pagination
          paginaActual={paginaActual}
          totalPaginas={totalPaginas}
          setPaginaActual={setPaginaActual}
          registrosPorPagina={registrosPorPagina}
          setRegistrosPorPagina={setRegistrosPorPagina}
          documentosFiltrados={documentosFiltrados}
        />

        {/*Modal de Modificar // Adicionar*/}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarAdicionar"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">

              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title ">
                  {adicionar ? "Crear " : "Modificar "} Proyecto
                </h5>
              </div>

              <div className="modal-body">
                {/* Consecutivo */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Consecutivo:</span>
                  <input
                    list="seleccioneConsecutivo"
                    className={listaConsecutivos.length > 0 ? "form-control" : "form-control text-danger"}
                    disabled={!adicionar}
                    type="text"
                    placeholder="Type to search..."
                    value={listaConsecutivos.length > 0 ? consecutivo : "Sin consecutivos disponibles"}
                    onBlur={() =>
                      handleBlurDatalist("seleccioneConsecutivo", consecutivo, setConsecutivo)
                    }
                    onChange={(e) => {
                      const c = listaConsecutivos.find((c) => c.prefijo === e.target.value.toUpperCase());
                      setConsecutivo(e.target.value);
                      setIdDocumentoConsecutivo(c ? c.idDocumento : "");
                      setValorConsecutivo(c ? parseInt(c.valorActual) + 1 : 0);
                    }} />
                  <datalist id="seleccioneConsecutivo" />

                  {adicionar && listaConsecutivos.length > 0 ?
                    <input
                      type="number"
                      className="form-control text-center"
                      value={valorConsecutivo}
                      disabled
                    /> : null}
                </div>

                {/* Descripcion */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Descripcion:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Descripcion"
                    disabled={!adicionar}
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value.toUpperCase())}
                  />
                </div>

                {/* Cliente */}
                {adicionar ?
                  listaClientes.length === 0 ?
                    <input
                      className=" form-control text-center text-danger mb-2"
                      value={"Sin clientes disponibles"}
                    />
                    :
                    <Select
                      className="mb-2"
                      placeholder="Cliente"
                      value={cliente}
                      onChange={(option) => setCliente(option)}
                      options={clientes}
                      styles={styles}
                    />
                  :
                  /* Cliente actual */
                  < div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Cliente :</span>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={typeof cliente === "object" && cliente !== undefined ? cliente.value : cliente}
                    />
                  </div>
                }

                {/* Direccion */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Direccion:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Direccion"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value.toUpperCase())}
                  />
                </div>

                {/* Ciudad */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Ciudad:</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ciudad"
                    value={ciudad}
                    onChange={(e) => setCiudad(e.target.value.toUpperCase())}
                  />
                </div>

                {/* Responsable */}
                {listaResponsables.length === 0 ?
                  <input
                    className=" form-control text-center text-danger mb-2"
                    value={"Sin responsables disponibles"} />
                  :
                  <Select
                    className="mb-2"
                    value={adicionar ? responsable : responsableM}
                    placeholder="Responsable"
                    onChange={(option) => {
                      const res = listaResponsables.find((r) => r.razonSocial === option.label);
                      setIdResponsable(res ? res.consecutivo : "");
                      adicionar ? setResponsable(option) : setResponsableM(option);
                    }}
                    options={responsables}
                    styles={styles} />
                }

                {/* Responsable actual */}
                {!adicionar && (
                  <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text">Responsable Actual:</span>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={typeof responsable === "object" && responsable !== undefined ? responsable.value : responsable}
                    />
                  </div>)}

                {/* Estado */}
                <div className="input-group input-group-sm mb-2">
                  <span className="input-group-text">Estado:</span>
                  <select
                    className="form-select"
                    placeholder="Estado"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)} >
                    <option value="" disabled> Seleccione </option>
                    <option value="ACTIVO">Activo</option>
                    <option value="INACTIVO">Inactivo</option>
                  </select>
                </div>
              </div>

              <div className="modal-footer">
                {(listaResponsables.length > 0 && listaClientes.length > 0 && adicionar) &&
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => handleClickAdicionar()}>
                    Crear
                  </button>}

                {!adicionar &&
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => handleClickModificarBd()}>
                    Modificar
                  </button>}

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setRefresh(true)
                    resetStates()
                  }}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* +Modal Columnas */}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarColumnas"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">
              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title" id="staticBackdropLabel3">
                  Selección de columnas
                </h5>
              </div>

              <div className="modal-body">

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Descripción"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Dirección"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Ciudad"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Cliente"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Responsable"} />
                </div>

                <div className="form-check">
                  <CheckboxCol tabla="proyectos" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Estado"} />
                </div>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal" >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
