export const validarConsecArt = (tipo, lista1, lista2) => {
    let prefixMap = {};

    if (tipo === "art") {
        let lstConc = lista1.map((a) => a.consecutivo)
        lstConc.forEach(item => {
            let [prefix, value] = item.split('-');
            value = Number(value);
            if (!prefixMap[prefix] || value > prefixMap[prefix]) {
                prefixMap[prefix] = value;
            }
        });
    }

    if (tipo === "con") {
        lista2.forEach(item => {
            let [prefix, value] = item.split('-');
            value = Number(value);
            if (!prefixMap[prefix] || value > prefixMap[prefix]) {
                prefixMap[prefix] = value;
            }
        });
    }

    return prefixMap;
};