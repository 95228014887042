import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import { changeTitle, isMobile } from "../../config/functions/funGenerales";
import {
    actualizarDocumentoDatabase,
    actualizarImagen,
    consultarDocumentoDatabaseObject,
    consultarDocumentoWhere,
    eliminarImagen,
    guardarDatabaseId,
    obtenerURLImagen,
    subirImagen
} from "../../config/firebase";
import { Filtrar2 } from "../Filtrar2";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";


export const HojaVidaArt = ({ articulos, inventario, permisos }) => {

    const user = JSON.parse(localStorage.getItem("token")).userName;
    const emp = JSON.parse(localStorage.getItem("token")).empresa;

    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
    const fecha_modificacion = fechaFormateada;
    const fecha_creacion = fechaFormateada;

    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
    const [listaInventarioActual, setListaInventarioActual] = useState([]);
    const [listaMantArtSel, setListaMantArtSel] = useState([]);
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [listaMantenimientos, setListaMantenimientos] = useState([]);
    const [listaConsecutivos, setListaConsecutivos] = useState([]);
    const [listaArtSerie, setListaArtSerie] = useState([]);
    const [listaArt, setListaArt] = useState([]);
    const [lineaVer, setLineaVer] = useState([]);

    const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
    const [valorConsecutivo, setValorConsecutivo] = useState(0);

    const [fechaProxMant, setFechaProxMant] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [serialArt, setSerialArt] = useState("");
    const [consecutivo, setConsecutivo] = useState("");
    const [consecArt, setConsecArt] = useState("");
    const [nomArt, setNomArt] = useState("");
    const [tecnico, setTecnico] = useState("");
    const [estado, setEstadoA] = useState("");
    const [lugar, setLugar] = useState("");
    const [idArt, setIdArt] = useState("");
    const [fecha, setFecha] = useState("");
    const [costo, setCosto] = useState(0);
    const [tipo, setTipo] = useState("");
    const [bodega, setBodega] = useState("");
    const [ubicacion, setUbicacion] = useState("");

    const [col2, setCol2] = useState(true);
    const [col3, setCol3] = useState(true);
    const [col4, setCol4] = useState(true);
    const [col5, setCol5] = useState(true);
    const [col6, setCol6] = useState(true);
    const [col7, setCol7] = useState(true);
    const [col8, setCol8] = useState(true);
    const [col9, setCol9] = useState(true);
    const [col10, setCol10] = useState(true);
    const [col11, setCol11] = useState(true);
    const [col12, setCol12] = useState(true);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [countOrdenar, setCountOrdenar] = useState(0);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);

    const rutaMant = "gs://mn-app-60991.appspot.com/" + emp + "/mantenimientos";
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [rutaEnFirestore, setRutaEnFirestore] = useState("");
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [deleteImg, setDeleteImg] = useState(false);
    const [imagenURL, setImagenURL] = useState("");
    const [adicionar, setAdicionar] = useState(0);
    const [existe, setExiste] = useState(null);
    const inputFile = React.useRef(null);
    const tablaRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [mobile, setMobile] = useState(true);
    const [verHv, setVerHv] = useState(false);

    //------ Principales--------
    useEffect(() => {
        changeTitle("Admin. Mantenimientos");
        cargaInicial();
        isMobile(setMobile);
        buscarColumnas();
    }, []);

    const cargaInicial = () => {
        cargarArticulos();
        setListaInventarioActual(inventario);
        cargarMantenimientos();
        cargarConsecutivos();
    }

    useEffect(() => {
        cargarArticulos()
    }, [articulos])

    useEffect(() => {
        setListaInventarioActual(inventario);
    }, [inventario])

    useEffect(() => {
        if (adicionar === 1) {
            resetStates();
        }
    }, [adicionar]);

    const obtenerFechaActual = () => {
        const currentDate = new Date()
        const offset = -5 * 60; // Offset en minutos (UTC-5)
        const today = new Date(currentDate.getTime() + offset * 60 * 1000).toISOString().split("T")[0];
        return today;
    };

    const buscarColumnas = () => {
        setCol2(localStorage.getItem(user + "/articulo/2") === "false" ? false : true);
        setCol3(localStorage.getItem(user + "/articulo/3") === "false" ? false : true);
        setCol4(localStorage.getItem(user + "/articulo/4") === "false" ? false : true);
        setCol5(localStorage.getItem(user + "/articulo/5") === "false" ? false : true);
        setCol6(localStorage.getItem(user + "/articulo/6") === "false" ? false : true);
        setCol7(localStorage.getItem(user + "/articulo/7") === "false" ? false : true);
        setCol8(localStorage.getItem(user + "/articulo/8") === "false" ? false : true);
        setCol9(localStorage.getItem(user + "/articulo/9") === "false" ? false : true);
        setCol10(localStorage.getItem(user + "/articulo/10") === "false" ? false : true);
        setCol11(localStorage.getItem(user + "/articulo/11") === "false" ? false : true);
        setCol12(localStorage.getItem(user + "/articulo/12") === "false" ? false : true);
    };

    const agregarColumna = (tabla, columna, valor) => {
        localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
    };

    //------ Mantenimientos--------
    const cargarMantenimientos = async () => {
        setIsLoading(true);
        try {
            const listaTemporal = await consultarDocumentoDatabaseObject(`${emp}_mantenimientos`, `${emp}_mantenimientos`);
            if (listaTemporal.length > 0) {
                setListaMantenimientos(listaTemporal.sort(sortBy("consecutivo")));
                // setDocumentosFiltrados(listaTemporal.sort(sortBy("consecutivo")));
            } else {
                const temporal = [{
                    consecutivo: "",
                }]
                setListaMantenimientos(temporal);
                // setDocumentosFiltrados(temporal);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (listaMantenimientos.length >= 1) {
            const listaTempMant = listaMantenimientos.filter((m) => m.consec_art === lineaVer.consecutivo && m.serial === lineaVer.serial);
            setListaMantArtSel(listaTempMant)
        }
    }, [lineaVer, verHv])

    //------ Consecutivos---------
    const cargarConsecutivos = async () => {
        try {
            const listaTemporal = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", "MNT");
            if (listaTemporal.length > 0) {
                setListaConsecutivos(listaTemporal.sort(sortBy("prefijo")));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderConsecutivos = () => {
        const selectConsecutivo = document.getElementById("seleccioneConsecutivo");
        if (selectConsecutivo) {
            selectConsecutivo.innerHTML = "";
            listaConsecutivos?.forEach((lc) => {
                let option = document.createElement("option");
                option.value = lc.prefijo;
                option.text = lc.descripcion;
                selectConsecutivo.appendChild(option);
            });
        }
    };

    const handleModificarConsecutivo = (valor) => {
        const valorActual = {
            valorActual: parseInt(valor.split("-")[1]),
            usuario_modificacion: user.toUpperCase(),
            fecha_modificacion: fecha_modificacion,
        };
        try {
            actualizarDocumentoDatabase(`${emp}_consecutivos`, idDocumentoConsecutivo, valorActual);
            cargarConsecutivos()
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        renderConsecutivos()
    }, [listaConsecutivos]);


    //------ Artículos ----------
    const cargarArticulos = async () => {
        const listaTemporal = articulos
        if (listaTemporal.length === 0) {
            toast.warning("No se encontraron artículos creados en la base de datos")
            setListaArt([{ id: 0 }])
        } else {
            const herramientas = listaTemporal.filter(item => item.tipo === "HERRAMIENTA" && item.serial === true);
            setListaArt(herramientas);
        }
    };

    const crearLista = (lista) => {
        let nuevaLista = [], nuevaListaP = [], indiceSerial = 0;

        let listaArtObj = {};
        listaArt.forEach(art => {
            listaArtObj[art.consecutivo] = art;
        });

        const procesarItem = (itemInventario, cantidad, extraProps) => {
            let articulo = listaArtObj[itemInventario.articulo];
            if (articulo) {
                for (let i = 0; i < cantidad; i++) {
                    if (indiceSerial < itemInventario.listaSeriales.length) {
                        let nuevoObjeto = {
                            ...articulo,
                            ...extraProps,
                            serial: itemInventario.listaSeriales[indiceSerial],
                        };
                        nuevaLista.push(nuevoObjeto);
                        indiceSerial++;
                    }
                }
                indiceSerial = 0;
            }
        };

        lista.forEach(itemInventario => {
            procesarItem(itemInventario, itemInventario.cant_disponible, {
                ubicacion: itemInventario.ubicacion,
                bodega: itemInventario.bodega,
            });
            procesarItem(itemInventario, itemInventario.cant_reservada, {
                proyecto: itemInventario.proyecto,
            });
        });

        const l = [...nuevaLista, ...nuevaListaP]
        setListaArtSerie(l.sort(sortBy("consecutivo")));
        setDocumentosFiltrados(l.sort(sortBy("consecutivo")));
    };

    useEffect(() => {
        if (listaArt.length > 0 && listaInventarioActual.length > 0) {
            crearLista(listaInventarioActual)
        }
    }, [listaInventarioActual, listaArt])


    //-------- CRUD  Mantenimiento --------
    const handleClickAdicionar = async () => {
        const consecutivoBuscado = consecutivo.toUpperCase() + "-" + valorConsecutivo
        const respuesta = listaMantenimientos.find(mant => mant.consecutivo === consecutivoBuscado);

        if (respuesta) {
            toast.info("El artículo con consecutivo : " + consecutivoBuscado + " ya se encuentra registrado, intente de nuevo");
            resetStates();
        } else {

            const enviarMnt = {
                id: uniqid("mantenimiento-"),
                consecutivo: consecutivoBuscado.toUpperCase(),
                nom_art: nomArt.toUpperCase(),
                consec_art: consecArt.toUpperCase(),
                id_art: idArt,
                serial: serialArt,
                tipo: tipo.toUpperCase(),
                fecha: fecha,
                imagen: imagenSeleccionada.name,
                imagenURL: rutaEnFirestore,
                lugar: lugar.toUpperCase(),
                tecnico: tecnico.toUpperCase(),
                costo: parseFloat(costo),
                estado_art: estado.toUpperCase(),
                proximo: fechaProxMant,
                usuario_creacion: user.toUpperCase(),
                fecha_creacion: fecha_creacion,
            };

            const enviar = {
                [enviarMnt.consecutivo]: enviarMnt
            };
            if (consecutivo === "" || nomArt === "" || consecArt === "" || serialArt === "" || tipo === "" || fecha === "" || costo === "" || tecnico === "" || lugar === "" || estado === "" || fechaProxMant === "" || imagenSeleccionada.name === undefined || imagenSeleccionada.name === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
                setTipo("")
            } else {
                setIsLoading(true)
                try {
                    await guardarDatabaseId(`${emp}_mantenimientos`, `${emp}_mantenimientos`, enviar);
                    handleModificarConsecutivo(consecutivoBuscado);
                    toast.success("Mantenimiento agregado de manera correcta");
                    handleSubirImagen();
                    resetStates();
                    setVerHv(false);
                    await cargarMantenimientos();
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false)
                }
            }
        }
    };

    const resetStates = () => {
        setImagenSeleccionada("");
        setValorConsecutivo(0);
        setFechaProxMant("");
        setDescripcion("");
        setConsecutivo("");
        setSerialArt("");
        setConsecArt("");
        // setBodega("");
        // setUbicacion("");
        setNomArt("");
        setTecnico("");
        setEstadoA("");
        setLugar("");
        setFecha("");
        setCosto("");
        setTipo("");
        clearInputFile();
    };

    //------- BLUR ----------
    const handleBlurDatalist = (datalistId, value, setValue) => {
        const datalist = document.getElementById(datalistId);
        const option = Array.from(datalist.options).find(
            (opt) => opt.value === value
        );
        if (!option) {
            setValue("");
        }
    };


    //------- Paginacion ---------
    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas, countOrdenar]);

    const ordenar = (columna) => {
        const direccion = (countOrdenar % 2 === 0 ? "" : "-")
        const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
        setListaMantenimientos(temporal);
        setCountOrdenar(countOrdenar + 1)
    };


    //-------- CRUD imágenes ----------
    const handleImageSelect = (event) => {
        const image = event.target.files[0];
        if (image) {
            const maxSize = 204800;
            if (image.size > maxSize) {
                toast.warning('Imagen Supera el tamaño permitido')
                event.target.value = ''
            } else {
                setImagenSeleccionada(image);
            }
        }
    };

    const handleSubirImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await subirImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue cargada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleEliminarImagen = async () => {
        if (rutaEnFirestore) {
            try {
                await eliminarImagen(rutaEnFirestore);
                setImagenURL("");
                toast.success("La imagen fue eliminada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleActualizarImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await actualizarImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue actualiada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleObtenerURLImagen = async (ruta) => {
        setImagenURL("")
        if (ruta !== undefined) {
            try {
                const url = await obtenerURLImagen(ruta);
                setImagenURL(url);
                setRutaEnFirestore(url)
                setExiste(true)
            } catch (error) {
                console.error(error);
                toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
                setExiste(false)
                setRutaEnFirestore(ruta)
            }
        }
    };

    const clearInputFile = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
        }
    };

    const limpiar = () => {
        setOcultarBtn(false)
        setUpdateImg(false)
        setDeleteImg(false)
    };


    //**************************************************
    return (
        <>
            <ToastContainer position="bottom-right" />

            {isLoading ?
                <LoaderES />
                : <>
                    {verHv ? null :
                        <>
                            <div className="fixed-top mt-2"
                                style={{
                                    backgroundColor: "white",
                                    marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
                                    transition: "margin-left 0.3s ease",
                                    top: "46px",
                                    zIndex: 1
                                }}>
                                <div className="row ms-2 me-2">

                                    {/* botones de opcion */}
                                    <div className="col-sm-4 align-self-start text-start">
                                        <div className="btn-group">

                                            {/* Columnas */}
                                            <button
                                                className="btn fa-solid fa-table-columns"
                                                title="Seleccionar Columnas"
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModificarColumnas"
                                                id="modalcolumnas" />

                                            {/* Actualizar */}
                                            <button
                                                className="btn fa-solid fa-rotate"
                                                title="Actualizar Datos"
                                                onClick={() => cargarMantenimientos()} />
                                        </div>
                                    </div>

                                    {/* filtrar */}
                                    <div className="col-sm-8">
                                        <Filtrar2
                                            tabla="myTable"
                                            documentos={listaArtSerie}
                                            setDocumentosFiltrados={setDocumentosFiltrados}
                                            setRegistrosFiltrados={setRegistrosPaginaActual}
                                            registrosPorPagina={registrosPorPagina} />
                                    </div>
                                </div>
                            </div>

                            <hr className={" mt-5 " + (mobile === true ? " mb-5" : "")} />
                        </>
                    }

                    {mobile ?
                        !verHv ?
                            <>
                                {documentosFiltrados?.map((articulo) => (
                                    <div key={articulo.consecutivo + articulo.id + articulo.serial}>
                                        <table className="table table-sm table-hover table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px", resize: "none" }}>
                                                        <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#collapseItem" + articulo.consecutivo + articulo.serial}
                                                            aria-expanded="false"
                                                            aria-controls="collapseItem" />
                                                    </th>
                                                    <th style={{ width: "65px", resize: "none" }}>{articulo.consecutivo + " :"}</th>
                                                    <td style={{ minWidth: "60px", maxWidth: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none", textAlign: "start" }}>
                                                        <span className="truncate-text ms-1">
                                                            {articulo.serial > 50 ? articulo.serial.slice(0, 50) + "..." : articulo.serial}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>

                                        <div className="collapse" id={"collapseItem" + articulo.consecutivo + articulo.serial}>
                                            <div className="card card-body">
                                                <table className="table table-sm">
                                                    <tbody >
                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}>Consecutivo </th>
                                                            <td>{articulo.consecutivo}</td>
                                                        </tr>

                                                        {!col2 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}>Artículo </th>
                                                                <td>{articulo.des_Larga}</td>
                                                            </tr>
                                                        }

                                                        {!col3 ? null :
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}>Serial Art </th>
                                                                <td>{articulo.serial}</td>
                                                            </tr>
                                                        }

                                                        <tr>
                                                            <td></td>
                                                            <th style={{ resize: "none" }}>Actions </th>
                                                            <td>
                                                                <div className="btn-group">
                                                                    {/* Btn Ver Mantenimiento  */}
                                                                    <button className="btn btn-sm btn-outline-warning fa-solid fa-clipboard-list"
                                                                        title="Ver HV del artículo"
                                                                        onClick={() => {
                                                                            setVerHv(true);
                                                                            setLineaVer(articulo)
                                                                        }} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            : null
                        /* Mobile */
                        :
                        /* Desktop */
                        !verHv ?

                            <div className="table-responsive">
                                <table
                                    id="myTable"
                                    ref={tablaRef}
                                    className="table table-hover table-striped table-sm table-bordered mt-3">
                                    <thead className="text-center">
                                        <tr>
                                            <th onClick={() => ordenar("consecutivo")}>Consecutivo</th>
                                            <th onClick={() => ordenar("articulo")} className={col2 ? "" : "d-none"}>Artículo</th>
                                            <th onClick={() => ordenar("serial")} className={col3 ? "" : "d-none"}>Serial Art.</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center" id="tabla_articulos">
                                        {documentosFiltrados?.map((articulo) => (
                                            <tr key={articulo.consecutivo + articulo.id + articulo.serial}>
                                                <td>{articulo.consecutivo}</td>
                                                <td className={col2 ? "" : "d-none"}>{articulo.des_Larga}</td>
                                                <td className={col3 ? "" : "d-none"}>{articulo.serial}</td>

                                                {/* Botones acciones */}
                                                <td className="text-center">
                                                    <div className="btn-group">

                                                        {/* Btn Ver Mantenimiento  */}
                                                        <button className="btn btn-sm btn-outline-warning fa-solid fa-clipboard-list"
                                                            title="Ver HV del artículo"
                                                            onClick={() => {
                                                                setVerHv(true);
                                                                setLineaVer(articulo)
                                                            }} />

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            : null
                        /* Desktop */
                    }
                    {/* Form mantenimiento */}
                    {verHv ?
                        <>
                            <div className="row mt-5 mb-1 ">
                                <div className="col-6 text-start ">
                                    <button className="btn btn-primary fa-solid fa-angle-left btn-md ms-4"
                                        title="Volver"
                                        onClick={() => {
                                            setVerHv(false)
                                            setLineaVer([])
                                            setListaMantArtSel([])
                                        }} />
                                </div>

                                {/* Btn Ver Mantenimiento  */}
                                <div className="col-6 text-end">
                                    <button className="btn btn-md btn-outline-primary fa-solid fa-screwdriver-wrench me-4"
                                        title="Registrar Mantenimiento"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                    />
                                </div>
                            </div>

                            <div className="row mt-1 mb-3 justify-content-center">

                                <div className="col-sm-auto col-md-6">
                                    <span className="fs-5 mt-2">HV del articulo:{mobile ? <br /> : null} <strong className="fs-6 mt-2">{lineaVer.des_Larga}</strong></span>
                                </div>
                            </div>
                        </> : null}
                    {mobile ?
                        <>
                            {/* mobile */}
                            {verHv ?
                                <>
                                    <div className="table-responsive ">
                                        {listaMantArtSel?.map((articulo) => (
                                            <div key={articulo.consecutivo + articulo.id}>
                                                <table className="table table-sm table-hover table-striped mb-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "50px", resize: "none" }}>
                                                                <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={"#collapseItem" + articulo.consecutivo}
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseItem" />
                                                            </th>
                                                            <th style={{ width: "65px", resize: "none" }}>{articulo.consecutivo}</th>
                                                            <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }}>
                                                                <span className="truncate-text">
                                                                    {articulo.articulo > 50 ? articulo.articulo.slice(0, 50) + "..." : articulo.articulo}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="collapse" id={"collapseItem" + articulo.consecutivo}>
                                                    <div className="card card-body">
                                                        <table className="table table-sm">
                                                            <tbody >
                                                                <tr>
                                                                    <td></td>
                                                                    <th style={{ resize: "none" }}>Consecutivo </th>
                                                                    <td>{articulo.consecutivo}</td>
                                                                </tr>

                                                                {!col2 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Artículo </th>
                                                                        <td>{articulo.nom_art}</td>
                                                                    </tr>
                                                                }

                                                                {!col3 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Serial Art. </th>
                                                                        <td>{articulo.serial}</td>
                                                                    </tr>
                                                                }

                                                                {!col4 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Fecha Mnt.</th>
                                                                        <td> {isNaN(articulo.fecha) ? articulo.fecha
                                                                            : moment.unix(articulo.fecha).format("DD MMM, YYYY")}
                                                                        </td>
                                                                    </tr>
                                                                }

                                                                {!col5 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Tipo Mnt.</th>
                                                                        <td>{articulo.tipo}</td>
                                                                    </tr>
                                                                }

                                                                {!col6 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Lugar Mnt. </th>
                                                                        <td>{articulo.lugar}</td>
                                                                    </tr>
                                                                }

                                                                {!col7 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Técnico </th>
                                                                        <td>{articulo.tecnico}</td>
                                                                    </tr>
                                                                }

                                                                {!col9 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Costo </th>
                                                                        <td>$
                                                                            {Intl.NumberFormat("es-CO").format(articulo.costo)}
                                                                        </td>
                                                                    </tr>
                                                                }

                                                                {!col10 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Estado Art.</th>
                                                                        <td>{articulo.estado_art}</td>
                                                                    </tr>
                                                                }

                                                                {!col11 ? null :
                                                                    <tr>
                                                                        <td></td>
                                                                        <th style={{ resize: "none" }}>Prox. Mant. </th>
                                                                        <td>{isNaN(articulo.proximo) ? articulo.proximo
                                                                            : moment.unix(articulo.proximo).format("DD MMM, YYYY")}
                                                                        </td>
                                                                    </tr>
                                                                }

                                                                <tr>
                                                                    <td></td>
                                                                    <th style={{ resize: "none" }}>Actions </th>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            {/* Btn Mas img. mantenimeinto */}
                                                                            <button
                                                                                className="btn btn-outline-info btn-sm fa-regular fa-regular fa-images"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#ModificarAdicionar"
                                                                                id="modalModificar"
                                                                                title="Cargar mas Imgs. del Artículo "
                                                                                onClick={() => {
                                                                                    // handleClickModificar(articulo.consecutivo);
                                                                                    // setAdicionar(0);
                                                                                }} />

                                                                            {/* Ver img. mantenimiento */}
                                                                            <button className="fa-solid btn btn-sm btn-outline-success fa-eye"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#imagen"
                                                                                onClick={() => {
                                                                                    handleObtenerURLImagen(articulo.imagenURL)
                                                                                }} />

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </> : null}
                            {/* mobile */}
                        </>
                        :
                        <>
                            {/* Desktop */}
                            {listaMantArtSel.length > 0 ?
                                <div className="table-responsive">
                                    <table
                                        id="myTable"
                                        ref={tablaRef}
                                        className="table table-hover table-striped table-sm table-bordered mt-3">
                                        <thead className="text-center">
                                            <tr>
                                                <th onClick={() => ordenar("consecutivo")}>Consecutivo</th>
                                                <th onClick={() => ordenar("articulo")} className={col2 ? "" : "d-none"}>Artículo</th>
                                                <th onClick={() => ordenar("serial")} className={col3 ? "" : "d-none"}>Serial Art.</th>
                                                <th onClick={() => ordenar("fecha")} className={col4 ? "" : "d-none"}>Fecha Mnt.</th>
                                                <th onClick={() => ordenar("tipo")} className={col5 ? "" : "d-none"}>Tipo Mnt.</th>
                                                <th onClick={() => ordenar("lugar")} className={col6 ? "" : "d-none"}>Lugar Mnt.</th>
                                                <th onClick={() => ordenar("tecnico")} className={col7 ? "" : "d-none"}>Técnico</th>
                                                <th className={col8 ? "text-center" : "d-none"}>Fotos</th>
                                                <th onClick={() => ordenar("costo")} className={col9 ? "" : "d-none"}>Costo Mnt. </th>
                                                <th onClick={() => ordenar("estadoA")} className={col11 ? "" : "d-none"}>Estado Art.</th>
                                                <th onClick={() => ordenar("proximo")} className={col12 ? "" : "d-none"}>Próximo Mnt.</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center" id="tabla_articulos">
                                            {listaMantArtSel?.map((articulo) => (
                                                <tr key={articulo.consecutivo + articulo.id + articulo.serial}>
                                                    <td>{articulo.consecutivo}</td>
                                                    <td className={col2 ? "" : "d-none"}>{articulo.nom_art}</td>
                                                    <td className={col3 ? "" : "d-none"}>{articulo.serial}</td>
                                                    <td className={col4 ? "text-center" : "d-none"}>
                                                        {isNaN(articulo.fecha) ? articulo.fecha
                                                            : moment.unix(articulo.fecha).format("DD MMM, YYYY")
                                                        }
                                                    </td>
                                                    <td className={col5 ? "" : "d-none"}>{articulo.tipo}</td>
                                                    <td className={col6 ? "" : "d-none"}>{articulo.lugar}</td>
                                                    <td className={col7 ? "" : "d-none"}>{articulo.tecnico}</td>
                                                    <td className={col8 ? "" : "d-none"}> <button className="fa-solid btn btn-sm btn-outline-success fa-eye"
                                                        title="Ver Imagenes"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#imagen"
                                                        onClick={() => {
                                                            handleObtenerURLImagen(articulo.imagenURL)
                                                        }} /></td>
                                                    <td className={col9 ? "" : "d-none"}>
                                                        ${Intl.NumberFormat("es-CO").format(articulo.costo)}
                                                    </td>
                                                    <td className={col11 ? "" : "d-none"}>{articulo.estado_art}</td>
                                                    <td className={col12 ? "" : "d-none"}>{articulo.proximo}</td>

                                                    {/* Botones acciones */}
                                                    <td>
                                                        <div className="btn-group">

                                                            {/* Btn Modificar mantenimiento */}
                                                            <button
                                                                className="btn btn-outline-info btn-sm fa-regular fa-regular fa-images"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModificarAdicionar"
                                                                id="modalModificar"
                                                                title="Cargar mas Imgs. del Artículo "
                                                                onClick={() => {
                                                                    // handleClickModificar(articulo.consecutivo);
                                                                    // setAdicionar(0);
                                                                }} />

                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                                : <div className="row mt-3 mb-2 justify-content-center" >
                                    <h5 className="text-danger">El articulo no tiene mantenimientos registrados aún</h5>
                                </div>}
                            {/* Desktop */}

                        </>
                    }

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />


                    {/* +Modal de Modificar // Adicionar*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarAdicionar"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title fs-5">Crear Mantenimiento </h5>
                                </div>

                                {/* Formulario */}
                                <div className="modal-body">
                                    {/* consecutivo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Consecutivo:</span>
                                        <input
                                            list="seleccioneConsecutivo"
                                            className="form-control"
                                            type="text"
                                            placeholder="Select"
                                            onBlur={() =>
                                                handleBlurDatalist("seleccioneConsecutivo", consecutivo, setConsecutivo)
                                            }
                                            value={consecutivo}
                                            onChange={(e) => {
                                                const c = listaConsecutivos.find((c) => c.prefijo === e.target.value);
                                                setSerialArt(lineaVer.serial);
                                                setConsecArt(lineaVer.consecutivo);
                                                setNomArt(lineaVer.des_Larga);
                                                setBodega(lineaVer.bodega);
                                                setUbicacion(lineaVer.ubicacion);
                                                setIdArt(lineaVer.id);
                                                setFecha(obtenerFechaActual())
                                                setConsecutivo(e.target.value);
                                                setIdDocumentoConsecutivo(c ? c.idDocumento : "");
                                                setValorConsecutivo(c ? parseInt(c.valorActual) + 1 : 0);
                                            }} />
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={valorConsecutivo}
                                            disabled />
                                        <datalist id="seleccioneConsecutivo" />
                                    </div>

                                    {/* Articulo  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Artículo:</span>
                                        <input type="text"
                                            disabled={!consecutivo}
                                            className="form-control"
                                            readOnly
                                            value={nomArt} />
                                    </div>


                                    {/* Bodegas */}
                                    {/* <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Bodega:</span>
                                        <input type="text"
                                            disabled={!consecutivo}
                                            className="form-control"
                                            readOnly
                                            value={bodega} />
                                    </div> */}

                                    {/* Ubicaciones */}
                                    {/* <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Ubicacion:</span>
                                        <input type="text"
                                            disabled={!consecutivo}
                                            className="form-control"
                                            readOnly
                                            value={ubicacion} />
                                    </div> */}


                                    {/* Serial */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Serial:</span>
                                        <input type="text"
                                            disabled={!consecutivo}
                                            className="form-control"
                                            readOnly
                                            value={serialArt} />
                                    </div>

                                    {/* Fecha mantenimiento  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Fecha: </span>
                                        <input
                                            className="form-control"
                                            disabled={!consecutivo}
                                            type="date"
                                            value={fecha}
                                            onChange={(e) => { setFecha(e.target.value) }} />
                                    </div>

                                    {/* Imagen */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Imágen: </span>
                                        <input type="file" accept="image/*"
                                            disabled={!fecha}
                                            onChange={handleImageSelect} className="form-control" ref={inputFile} />
                                    </div>

                                    {/* tipo mantenimiento */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Tipo Mnt: </span>
                                        <select
                                            className="form-select"
                                            placeholder="Tipo"
                                            disabled={!fecha}
                                            value={tipo}
                                            onChange={(e) => {
                                                setTipo(e.target.value)
                                                setRutaEnFirestore(rutaMant + "/" + consecutivo + "-" + valorConsecutivo + "+" + lineaVer.consecutivo + "/" + imagenSeleccionada.name)
                                            }}>
                                            <option value="" disabled>Seleccione</option>
                                            <option value="PREVENTIVO">Preventivo</option>
                                            <option value="CORRECTIVO">Correctivo</option>
                                        </select>
                                    </div>


                                    {/* Lugar Mant */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Lugar: </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={!tipo}
                                            placeholder="Lugar"
                                            value={lugar}
                                            onChange={(e) => setLugar(e.target.value)} />
                                    </div>

                                    {/* Tecnico*/}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Técnico: </span>
                                        <input
                                            list="seleccioneClasificacion"
                                            className="form-control"
                                            disabled={!lugar}
                                            type="textarea"
                                            placeholder="Técnico"
                                            value={tecnico}
                                            onChange={(e) => {
                                                setTecnico(e.target.value)
                                            }} />
                                    </div>

                                    {/* Costo */}
                                    {adicionar === 1 ? null :
                                        <div className="input-group input-group-sm mb-2">
                                            <span className="input-group-text">Costo: </span>
                                            <input
                                                className="form-control"
                                                disabled={!tecnico}
                                                type="number"
                                                min={1}
                                                placeholder="Costo"
                                                value={costo}
                                                onChange={(e) => setCosto(e.target.value)} />
                                        </div>
                                    }

                                    {/*  Estado Art*/}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado Art. </span>
                                        <select
                                            className="form-select"
                                            disabled={!costo}
                                            value={estado}
                                            onChange={(e) => {
                                                setEstadoA(e.target.value)
                                            }} >
                                            <option value="" disabled> Seleccione </option>
                                            <option value="BUENO">Bueno</option>
                                            <option value="MALO">Malo</option>
                                        </select>
                                    </div>

                                    {/* Fecha proximo mant  */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Fecha Proximo Mant.: </span>
                                        <input
                                            className="form-control"
                                            disabled={!estado}
                                            type="date"
                                            value={fechaProxMant}
                                            onChange={(e) => { setFechaProxMant(e.target.value) }} />
                                    </div>

                                    {/* Descripcion */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Descripcion:</span>
                                        <textarea
                                            type="text"
                                            disabled={!fechaProxMant}
                                            className="form-control"
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)} />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {consecutivo && fecha && fechaProxMant && tipo && lugar && tecnico && costo ?
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            data-bs-dismiss="modal"
                                            onClick={() => handleClickAdicionar()} >
                                            Crear
                                        </button>
                                        : null}
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetStates()} >
                                        Cancelar
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}


                    {/* +Modal Columnas */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarColumnas"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Selección de columnas
                                    </h5>
                                </div>

                                <div className="modal-body">
                                    <div className="modal-body">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col2}
                                                onChange={(e) => {
                                                    setCol2(e.target.checked)
                                                    agregarColumna("mant", 2, e.target.checked)
                                                }}
                                                id="clasif1" />
                                            <label className="form-check-label"
                                                htmlFor="clasif1">
                                                Consecutivo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col3}
                                                onChange={(e) => {
                                                    setCol3(e.target.checked)
                                                    agregarColumna("mant", 3, e.target.checked)
                                                }}
                                                id="clasif2" />
                                            <label className="form-check-label"
                                                htmlFor="clasif2">
                                                Artículo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col4}
                                                onChange={(e) => {
                                                    setCol4(e.target.checked)
                                                    agregarColumna("mant", 4, e.target.checked)
                                                }}
                                                id="clasif3" />
                                            <label className="form-check-label"
                                                htmlFor="clasif3">
                                                Serial
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col5}
                                                onChange={(e) => {
                                                    setCol5(e.target.checked)
                                                    agregarColumna("mant", 5, e.target.checked)
                                                }}
                                                id="clasif4" />
                                            <label className="form-check-label"
                                                htmlFor="clasif4">
                                                Fecha
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col6}
                                                onChange={(e) => {
                                                    setCol6(e.target.checked)
                                                    agregarColumna("mant", 6, e.target.checked)
                                                }}
                                                id="clasif5" />
                                            <label className="form-check-label"
                                                htmlFor="clasif5">
                                                Tipo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col7}
                                                onChange={(e) => {
                                                    setCol7(e.target.checked)
                                                    agregarColumna("mant", 7, e.target.checked)
                                                }}
                                                id="clasif6" />
                                            <label className="form-check-label"
                                                htmlFor="clasif6">
                                                Lugar
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col8}
                                                onChange={(e) => {
                                                    setCol8(e.target.checked)
                                                    agregarColumna("mant", 8, e.target.checked)
                                                }}
                                                id="clasif7" />
                                            <label className="form-check-label"
                                                htmlFor="clasif7">
                                                Técnico
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col9}
                                                onChange={(e) => {
                                                    setCol9(e.target.checked)
                                                    agregarColumna("mant", 9, e.target.checked)
                                                }}
                                                id="clasif8" />
                                            <label className="form-check-label"
                                                htmlFor="clasif8">
                                                Costo
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col10}
                                                onChange={(e) => {
                                                    setCol10(e.target.checked)
                                                    agregarColumna("mant", 10, e.target.checked)
                                                }}
                                                id="clasif9" />
                                            <label className="form-check-label"
                                                htmlFor="clasif9">
                                                Estado Mant.
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col11}
                                                onChange={(e) => {
                                                    setCol11(e.target.checked)
                                                    agregarColumna("mant", 11, e.target.checked)
                                                }}
                                                id="clasif10" />
                                            <label className="form-check-label"
                                                htmlFor="clasif10">
                                                Estado Art.
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                checked={col12}
                                                onChange={(e) => {
                                                    setCol12(e.target.checked)
                                                    agregarColumna("mant", 12, e.target.checked)
                                                }}
                                                id="clasif11" />
                                            <label className="form-check-label"
                                                htmlFor="clasif11">
                                                Proximo Mant.
                                            </label>
                                        </div>



                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Columnas */}


                    {/* +Modal Imagen */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="imagen"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content ">
                                <div className="modal-header bg-info text-light">
                                </div>
                                <div className="modal-body">

                                    {!deleteImg && !updateImg && existe ? <>
                                        <img src={imagenURL} alt="Mi imagen" style={{
                                            "display": "block", "marginLeft": "auto",
                                            "marginRight": "auto", "width": "40%",
                                            "height": "auto"
                                        }} />
                                    </>
                                        :
                                        updateImg || !existe ? <>
                                            <div className="container mt-3">
                                                <div className="row text-center">
                                                    <h2 className={existe ? "mb-3 fs-5 text-primary" : "mb-3 fs-5 text-success"}>{existe ? "Modificar Imágen" : "Cargara imágen"}</h2>
                                                    <div className="mb-3 row">
                                                        <label className="col-sm-3 col-form-label">
                                                            Seleccione su nueva imágen:
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group mb-3">
                                                                <input type="file" accept="image/*"
                                                                    onChange={handleImageSelect} className="form-control" ref={inputFile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {imagenSeleccionada ?
                                                        <div className="col text-center">
                                                            <button className="btn btn-sm btn-primary fa-solid fa-floppy-disk"
                                                                type="button"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => {
                                                                    if (imagenSeleccionada && existe) {
                                                                        handleActualizarImagen()
                                                                        limpiar()
                                                                    } else
                                                                        if (imagenSeleccionada && !existe) {
                                                                            handleSubirImagen()
                                                                            limpiar()
                                                                        } else {
                                                                            toast.warning("Debe seleccionar un archivo para guardar, intente nuevamente")
                                                                        }
                                                                }} />
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </>
                                            : <>
                                                <div className="container mt-3">
                                                    <div className="row text-center">
                                                        <p className="mb-3"> Esta seguro de eliminar esta imágen? <br /><span className="text-danger">Recuerde que esta accion no se puede revertir</span></p>
                                                        <img src={imagenURL} alt="Mi imagen" style={{
                                                            "display": "block", "marginLeft": "auto",
                                                            "marginRight": "auto", "width": "20%",
                                                            "height": "auto"
                                                        }} />
                                                    </div>
                                                    <div className="row text-center mt-4">
                                                        <div className="col">
                                                            <div className="col">
                                                                <button className="btn btn-sm btn-danger"
                                                                    data-bs-dismiss="modal" title="Confirmar Eliminacion Imágen" onClick={() => {
                                                                        handleEliminarImagen()
                                                                        limpiar()
                                                                    }} >Si, Eliminar</button>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col">
                                                                <button
                                                                    className="btn btn-sm btn-secondary fa-solid fa-ban"
                                                                    data-bs-dismiss="modal"
                                                                    title="Cancelar" onClick={() => {
                                                                        limpiar()
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                    {/* Eliminar o modificar imagen */}
                                    <div className="container mt-3">
                                        <div className="row text-center">
                                            {existe ? <>
                                                {ocultarBtn ? null : <>
                                                    <div className="col">
                                                        <button className="btn btn-sm btn-danger fa-solid fa-trash-can" title="Eliminar Imagen" onClick={() => {
                                                            setDeleteImg(true)
                                                            setOcultarBtn(true)
                                                        }} />
                                                    </div>
                                                </>}
                                                {ocultarBtn ? null : <>
                                                    <div className="col">
                                                        <button className="btn bnt-sm btn-primary fa-solid fa-pen-to-square" title="Cambiar Imagen" onClick={() => {
                                                            setUpdateImg(true)
                                                            setOcultarBtn(true)
                                                        }} />
                                                    </div>
                                                </>}
                                            </> : null}
                                        </div>
                                    </div>
                                    {/* </>} */}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setImagenSeleccionada("")
                                            limpiar()
                                        }} >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

