import { toast } from "react-toastify";


export  const CheckboxCol = ({ tabla, columna, checked, setChecked, validar, agregarColumna, texto }) => {
   
    const manejarCambio = (valor, col) => {
        if (!valor && !validar(col)) {
            toast.warning("Deben haber seleccionadas al menos dos opciones.");
        } else {
            agregarColumna(tabla, columna, valor);
            setChecked(valor);
        }
    };

    
    return (
        <div className="form-check">
            <input className="form-check-input"
                type="checkbox"
                checked={checked}
                onChange={(e) => manejarCambio(e.target.checked)}
                id={`clasif${columna}`} />
            <label className="form-check-label"
                htmlFor={`clasif${columna}`}>
                {texto}
            </label>
        </div>
    );
};


