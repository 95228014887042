import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from "uniqid";
import {
    actualizarDocumentoDatabaseIdCampo,
    actualizarImagen,
    consultarDatabase,
    consultarDocumentoWhere,
    eliminarImagen,
    guardarDatabaseId,
    obtenerURLImagen,
    subirImagen
} from "../../config/firebase";
import { cargarConsecutivos, handleColum, handleModificarConsecutivo, isMobile, styles } from "../../config/functions/funGenerales";
import { changeTitle } from '../../config/functions/funGenerales';
import { CargaMasiva } from "../cragaMasiva/CargaMasiva";
import { CheckboxCol } from "../CheckboxCol";
import { Filtrar2 } from '../Filtrar2';
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { PlantillaExcel } from "../cragaMasiva/PlantillaExcel";
import { DatoTablaMobile, TdTablaD, ThTablaH } from '../miniComponentes';

export const Articulos = ({ articulos, permisos }) => {

    const user = JSON.parse(localStorage.getItem("token")).userName;
    const emp = JSON.parse(localStorage.getItem("token")).empresa;
    const broken = JSON.parse(localStorage.getItem("broken"));
    const collapsed = JSON.parse(localStorage.getItem("collapsed"));

    const fechaActual = new Date();
    const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
    const [listaClasificacion1, setListaClasificacion1] = useState([]);
    const [listaClasificacion2, setListaClasificacion2] = useState([]);
    const [listaClasificacion3, setListaClasificacion3] = useState([]);
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
    const [listaConsecutivos, setListaConsecutivos] = useState([]);
    const [listaCabeceras, setListaCabeceras] = useState([]);
    const [listaArticulos, setListaArticulos] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);

    const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
    const [valorConsecutivo, setValorConsecutivo] = useState(0);

    const [clasificacion1, setClasificacion1] = useState("");
    const [clasificacion2, setClasificacion2] = useState("");
    const [clasificacion3, setClasificacion3] = useState("");
    const [consecutivo, setConsecutivo] = useState("");
    const [ult_Compra, setUlt_Compra] = useState("");
    const [referencia, setReferencia] = useState("");
    const [des_Corta, setDes_Corta] = useState("");
    const [des_Larga, setDes_Larga] = useState("");
    const [ult_Costo, setUlt_Costo] = useState(0);
    const [serial, setSerial] = useState(false);
    const [imagen, setImagen] = useState("");
    const [idArt, setIdArt] = useState("");
    const [tipo, setTipo] = useState("");
    const [um, setUm] = useState("");
    const [uc, setUc] = useState("");

    const [col2, setCol2] = useState(true);
    const [col3, setCol3] = useState(true);
    const [col4, setCol4] = useState(true);
    const [col5, setCol5] = useState(true);
    const [col6, setCol6] = useState(true);
    const [col7, setCol7] = useState(true);
    const [col8, setCol8] = useState(true);
    const [col9, setCol9] = useState(true);
    const [col10, setCol10] = useState(true);
    const [col11, setCol11] = useState(true);
    const [col12, setCol12] = useState(true);
    const [col13, setCol13] = useState(true);
    const [col14, setCol14] = useState(true);
    const [col15, setCol15] = useState(true);
    const [col16, setCol16] = useState(true);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
    const [countOrdenar, setCountOrdenar] = useState(0);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);

    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [rutaEnFirestore, setRutaEnFirestore] = useState("");
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [deleteImg, setDeleteImg] = useState(false);
    const [imagenURL, setImagenURL] = useState("");
    const [existe, setExiste] = useState(null);

    const rutaArticulos = "gs://mn-app-60991.appspot.com/" + emp + "/articulos";
    const [isLoading, setIsLoading] = useState(false);
    const [adicionar, setAdicionar] = useState(0);
    const [mobile, setMobile] = useState(true);
    const inputFile = useRef(null);
    const tablaRef = useRef(null);

    const [clf1, setClf1] = useState(listaClasificacion1);
    const [clf2, setClf2] = useState(listaClasificacion2);
    const [clf3, setClf3] = useState(listaClasificacion3);
    const [unds, setUnds] = useState(listaUnidades);
    const [estado, setEstado] = useState("");
    const [estadoV, setEstadoV] = useState("");
    /************ Funciones ************/

    useEffect(() => {
        changeTitle("Admin. Artículos");
        cargaInicial();
    }, []);

    const cargaInicial = () => {
        handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16], user, "articulo");
        cargarArticulos()
        cargarUnidadMedida()
        cargarConsecutivos("ARTICULO", emp, setListaConsecutivos);
        isMobile(setMobile)
        cargarClasificaciones("1", setListaClasificacion1);
        cargarClasificaciones("2", setListaClasificacion2);
        cargarClasificaciones("3", setListaClasificacion3);
    };

    useEffect(() => {
        cargarArticulos()
    }, [articulos])

    useEffect(() => {
        if (adicionar === 1) {
            resetStates();
        }
    }, [adicionar]);

    //----------Articulos----------
    const cargarArticulos = async () => {
        const listaTemporal = articulos
        if (listaTemporal.length > 0) {
            setListaArticulos(listaTemporal.sort(sortBy("consecutivo")));
            setDocumentosFiltrados(listaTemporal.sort(sortBy("consecutivo")))
        } else {
            const temporal = [{ consecutivo: "Sin Datos" }]
            setListaArticulos(temporal);
            setDocumentosFiltrados(temporal)
        }
    };

    //----------Clasificaciones----------
    const cargarClasificaciones = async (desc, setListaClasificacion) => {
        try {
            const listaTemporal = await consultarDocumentoWhere(`${emp}_clasificaciones`, "tipoClasificacion", desc);
            const listaC = listaTemporal[0]?.lista;

            const activos = listaC.filter((a) => a.estadoI == "ACTIVO");
            const listClasific = activos?.map((lm) => lm.descripcion);
            setListaClasificacion(listClasific.sort(sortBy("codigo")));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const options = listaClasificacion1?.map(lc => ({ value: lc, label: lc }));
        setClf1(options?.sort(sortBy("value")));
    }, [listaClasificacion1]);

    useEffect(() => {
        const options = listaClasificacion2?.map(lc => ({ value: lc, label: lc }));
        setClf2(options?.sort(sortBy("value")))
    }, [listaClasificacion2]);

    useEffect(() => {
        const options = listaClasificacion3?.map(lc => ({ value: lc, label: lc }));
        setClf3(options?.sort(sortBy("value")))
    }, [listaClasificacion3]);

    //----------Unidades----------
    const cargarUnidadMedida = async () => {
        try {
            const listaTemporal = await consultarDatabase(`${emp}_unidades`);
            const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
            setListaUnidades(activos.sort(sortBy("codigo")));
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        const options = listaUnidades?.map(lc => ({ value: lc.codigo, label: lc.descripcion }));
        setUnds(options?.sort(sortBy("value")))
    }, [listaUnidades]);

    //----------Consecutivos----------
    const renderConsecutivos = () => {
        const selectConsecutivo = document.getElementById("seleccioneConsecutivo");
        if (selectConsecutivo) {
            selectConsecutivo.innerHTML = "";
            listaConsecutivos?.forEach((lc) => {
                let option = document.createElement("option");
                option.value = lc.prefijo;
                option.text = lc.descripcion;
                selectConsecutivo.appendChild(option);
            });
        }
    };

    useEffect(() => {
        renderConsecutivos()
    }, [listaConsecutivos]);

    //----------CRUD Articulos---------- 
    const handleClickAdicionar = async () => {
        const consecutivoBuscado = consecutivo.toUpperCase() + "-" + valorConsecutivo
        const respuesta = listaArticulos.find(articulo => articulo.consecutivo === consecutivoBuscado);
        if (respuesta) {
            toast.info("El artículo con consecutivo : " + consecutivoBuscado + " ya se encuentra registrado, intente de nuevo");
            resetStates();
        } else {
            const enviarArticulo = {
                id: uniqid("articulo-"),
                serial: serial,
                consecutivo: consecutivoBuscado,
                clasificacion1: clasificacion1.value,
                clasificacion2: clasificacion2.value,
                clasificacion3: clasificacion3.value,
                des_Corta: des_Corta.toUpperCase(),
                des_Larga: des_Larga.toUpperCase(),
                imagen: imagen,
                um: um.value,
                uc: parseInt(uc),
                tipo: tipo.toUpperCase(),
                ult_Compra: ult_Compra,
                ult_Costo: parseFloat(ult_Costo),
                estado: estado.toUpperCase(),
                referencia: referencia.toUpperCase(),
                usuario_creacion: user.toUpperCase(),
                fecha_creacion: fechaFormateada,
            };
            const enviar = {
                [enviarArticulo.consecutivo]: enviarArticulo
            };
            if (consecutivo === "" || clasificacion1 === "" || clasificacion2 === "" || clasificacion3 === "" || des_Corta === "" || des_Larga === "" || um === "" || uc === "" || tipo === "" || ult_Compra === "" || ult_Costo === "" || ult_Costo === NaN || estado === "" || referencia === "" || imagen === undefined || imagen === "") {
                toast.warning("Diligencie todos los campos de manera correcta");
            } else {
                setIsLoading(true)
                try {
                    await guardarDatabaseId(`${emp}_articulos`, `${emp}_articulos_${consecutivo.toLowerCase()}`, enviar);
                    await handleModificarConsecutivo(consecutivoBuscado, user, fechaFormateada, idDocumentoConsecutivo, emp);
                    await handleSubirImagen();
                    toast.success("Artículo agregado de manera correcta");

                } catch (error) {
                    console.error(error)
                } finally {
                    resetStates();
                    setIsLoading(false);
                    await cargarConsecutivos("ARTICULO", emp, setListaConsecutivos);

                }
            }
        }
    };

    const handleClickTraerData = (a) => {
        setConsecutivo(a.consecutivo.split("-")[0])
        setValorConsecutivo(a.consecutivo.split("-")[1])
        setClasificacion1(a.clasificacion1);
        setClasificacion2(a.clasificacion2);
        setClasificacion3(a.clasificacion3);
        setDes_Corta(a.des_Corta);
        setDes_Larga(a.des_Larga);
        setImagen(a.imagen);
        setReferencia(a.referencia);
        setUm(a.um);
        setUc(a.uc);
        setTipo(a.tipo);
        setUlt_Compra(a.ult_Compra);
        setUlt_Costo(a.ult_Costo);
        setSerial(a.serial);
        setEstado(a.estado);
        setEstadoV(a.estado);
        setIdArt(a.id);
    };

    const handleClickModificarBd = async () => {
        const campo = consecutivo.toUpperCase() + "-" + valorConsecutivo
        const enviarArticulo = {
            consecutivo: campo,
            clasificacion1: typeof (clasificacion1) === "object" ? clasificacion1?.value : clasificacion1,
            clasificacion2: typeof (clasificacion2) === "object" ? clasificacion2?.value : clasificacion2,
            clasificacion3: typeof (clasificacion3) === "object" ? clasificacion3?.value : clasificacion3,
            des_Corta: des_Corta?.toUpperCase(),
            des_Larga: des_Larga?.toUpperCase(),
            referencia: referencia?.toUpperCase(),
            id: idArt,
            imagen: imagen,
            um: typeof (um) === "object" ? um?.value : um,
            uc: parseInt(uc),
            tipo: tipo?.toUpperCase(),
            ult_Compra: ult_Compra,
            ult_Costo: parseFloat(ult_Costo),
            serial: serial,
            estado: estado?.toUpperCase(),
            usuario_modificacion: user?.toUpperCase(),
            fecha_modificacion: fechaFormateada,
        };

        if (campo !== "" && campo !== undefined) {
            setIsLoading(true);
            try {
                await actualizarDocumentoDatabaseIdCampo(`${emp}_articulos`, `${emp}_articulos_${consecutivo.toLowerCase()}`, campo, enviarArticulo);
                toast.success("Artículo Modificado correctamente");
                handleSubirImagen()
                resetStates();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }

        } else {
            toast.warning("Seleccione un artículo valido para modificarlo");
        }
    };

    const resetStates = () => {
        setSerial(false);
        setConsecutivo("");
        setClasificacion1("");
        setClasificacion2("");
        setClasificacion3("");
        setDes_Corta("");
        setDes_Larga("");
        setImagenSeleccionada("");
        setImagen("");
        setUm("");
        setUc(0);
        setTipo("");
        setReferencia("");
        setUlt_Compra("");
        setUlt_Costo("");
        setEstado("");
        setValorConsecutivo(0);
        clearInputFile();
    };

    //----------Columnas----------
    const validarCheckboxes = (excluido) => {
        let contador = 0;
        if (col2 && excluido !== 'col2') contador++;
        if (col3 && excluido !== 'col3') contador++;
        if (col4 && excluido !== 'col4') contador++;
        if (col5 && excluido !== 'col5') contador++;
        if (col6 && excluido !== 'col6') contador++;
        if (col7 && excluido !== 'col7') contador++;
        if (col8 && excluido !== 'col8') contador++;
        if (col9 && excluido !== 'col9') contador++;
        if (col10 && excluido !== 'col10') contador++;
        if (col11 && excluido !== 'col11') contador++;
        if (col12 && excluido !== 'col12') contador++;
        if (col13 && excluido !== 'col13') contador++;
        if (col14 && excluido !== 'col14') contador++;
        if (col15 && excluido !== 'col15') contador++;
        if (col16 && excluido !== 'col16') contador++;
        return contador >= 3;
    };

    const agregarColumna = (tabla, columna, valor) => {
        localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
    };

    const handleBlurDatalist = (datalistId, value, setValue) => {
        const datalist = document.getElementById(datalistId);
        const option = Array.from(datalist.options).find(
            (opt) => opt.value === value
        );
        if (!option) {
            setValue("");
        }
    };

    //--------Carga masiva--------
    const articulosNuevos = (listaFusionada) => {
        setListaArticulos(listaFusionada)
    };

    const manejarRespuestaDescarga = () => {
        toast.info("Descarga en proceso.")
    };

    useEffect(() => {
        const obtenerCabeceras = () => {
            if (tablaRef.current) {
                const tabla = tablaRef.current;
                const cabecera = tabla.querySelector("thead");
                const celdasCabecera = Array.from(cabecera.querySelectorAll("th"));
                return celdasCabecera?.map((celda) => celda.textContent).filter((cont) => cont.trim() !== "");
            }
        };
        const cbs = obtenerCabeceras();
        setListaCabeceras(cbs);
    }, [listaArticulos]);

    //-------Paginacion----------
    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina]);

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas, countOrdenar]);

    const ordenar = (columna) => {
        const direccion = (countOrdenar % 2 === 0 ? "" : "-")
        const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
        setListaArticulos(temporal);
        setCountOrdenar(countOrdenar + 1)
    };

    //-------CRUD imágenes--------
    const handleImageSelect = (event) => {
        const image = event.target.files[0];
        if (image) {
            const maxSize = 204800;
            if (image.size > maxSize) {
                toast.warning('Imagen Supera el tamaño permitido')
                event.target.value = ''
            } else {
                setImagenSeleccionada(image);
            }
        }
    };

    const handleSubirImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            setIsLoading(true)
            try {
                await subirImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue cargada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    };

    const handleEliminarImagen = async () => {
        if (rutaEnFirestore) {
            try {
                await eliminarImagen(rutaEnFirestore);
                setImagenURL("");
                toast.success("La imagen fue eliminada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        }
    };

    const handleActualizarImagen = async () => {
        if (imagenSeleccionada && rutaEnFirestore) {
            try {
                await actualizarImagen(rutaEnFirestore, imagenSeleccionada);
                setImagenURL("");
                toast.success("La imágen fue actualizada correctamente")
                setImagenSeleccionada("")
            } catch (error) {
                console.error(error)
            }
        }
    };

    const handleObtenerURLImagen = async (ruta) => {
        setImagenURL("")
        if (ruta !== undefined) {
            try {
                const url = await obtenerURLImagen(ruta);
                setImagenURL(url);
                setRutaEnFirestore(url)
                setExiste(true)
            } catch (error) {
                toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
                setExiste(false)
                setRutaEnFirestore(ruta)
            }
        }
    };

    const clearInputFile = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
        }
    };

    const limpiar = () => {
        setOcultarBtn(false)
        setUpdateImg(false)
        setDeleteImg(false)
    };

    //*********** Mini Componentes ***********
    const selector = (listaDatos, dato, texto, setDatos, listOpt, titulo) => {
        return (
            <Select
                className="mb-2"
                placeholder={titulo}
                value={listaDatos.length > 0 ? dato : { value: texto, label: texto }}
                onChange={(option) => setDatos(option)}
                options={listOpt}
                styles={styles}
                isDisabled={listaDatos.length === 0} />
        )
    };

    const inputCalsf = (dato, tipo) => {
        return (
            <div className="input-group input-group-sm ">
                <span className="input-group-text" style={{ height: "31px" }}>{tipo}</span>
                < input className="form-control form-control-sm text-center mb-2" disabled value={dato} />
            </div>
        )
    };

    //-------------------------------------------
    return (
        <>
            {isLoading
                ? <LoaderES />
                : <>
                    <div className="fixed-top mt-2"
                        style={{
                            backgroundColor: "white",
                            marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
                            transition: "margin-left 0.3s ease",
                            top: "46px",
                            zIndex: 1
                        }}>

                        {/* botones de opcion */}
                        <div className="row ms-2 me-2">
                            <div className="col-sm-4 align-self-start text-start">
                                <div className="btn-group">

                                    {/* Adicionar */}
                                    <button
                                        className="btn fa-solid fa-plus"
                                        disabled={!permisos.includes("crearArt")}
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarAdicionar"
                                        id="modaladicionar"
                                        title="Crear Nuevo Artículo"
                                        onClick={() => {
                                            renderConsecutivos()
                                            setAdicionar(1);
                                        }} />

                                    {/* Carga masiva */}
                                    <CargaMasiva
                                        permiso={permisos}
                                        head={listaCabeceras}
                                        campoFecha={"ult_Compra"}
                                        listaClasf1={listaClasificacion1}
                                        listaClasf2={listaClasificacion2}
                                        listaClasf3={listaClasificacion3}
                                        listaConstvo={listaConsecutivos}
                                        listaArt={listaArticulos}
                                        articulosNuevos={articulosNuevos} />

                                    {/* Btn plantila excel */}
                                    <PlantillaExcel
                                        permiso={permisos}
                                        origen={"Entrada"}
                                        tipo={"Articulos"}
                                        onRespuesta={manejarRespuestaDescarga} />

                                    {/* Columnas */}
                                    <button
                                        className="btn fa-solid fa-table-columns"
                                        title="Seleccionar Columnas"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModificarColumnas"
                                        id="modalcolumnas" />

                                </div>
                            </div>

                            {/* filtrar */}
                            <div className={`col-sm-8 ${mobile && "mt-2"}`}>
                                <Filtrar2
                                    tabla="myTable"
                                    documentos={listaArticulos}
                                    setDocumentosFiltrados={setDocumentosFiltrados}
                                    setRegistrosFiltrados={setRegistrosPaginaActual}
                                    registrosPorPagina={registrosPorPagina} />
                            </div>
                        </div>

                    </div>

                    <hr className={" mt-5 " + (mobile ? " my-5 pt-3" : "")} />

                    {/* Componente */}
                    {permisos.includes("verArticulos") && (
                        mobile ?
                            (<>
                                {registrosPaginaActual?.map((articulo) => (
                                    articulo.consecutivo === "Sin Datos" ?
                                        <button
                                            className="btn btn-sm btn-primary mt-2 fa-solid fa-plus"
                                            disabled={!permisos.includes("crearArt")}
                                            data-bs-toggle="modal"
                                            data-bs-target="#ModificarAdicionar"
                                            id="modaladicionar"
                                            title="Crear Nuevo Artículo"
                                            onClick={() => setAdicionar(1)}
                                        />
                                        :
                                        <div key={articulo.consecutivo + articulo.id}>
                                            <table className="table table-sm table-hover table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "50px", resize: "none" }}>
                                                            <button className="btn  fa-solid fa-chevron-down btn-sm me-0"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#collapseItem" + articulo.consecutivo}
                                                                aria-expanded="false"
                                                                aria-controls="collapseItem" />
                                                        </th>
                                                        <th style={{ width: "75px", resize: "none" }}>{`${articulo.consecutivoFix} : `}</th>
                                                        <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }} className={(articulo.estado !== "ACTIVO" && col15) ? "text-danger text-start" : "text-start"}>
                                                            <span className="truncate-text">
                                                                {articulo.des_Larga.length > 50 ? articulo.des_Larga.slice(0, 50) + "..." : articulo.des_Larga}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>

                                            <div className="collapse" id={"collapseItem" + articulo.consecutivo}>
                                                <div className="card card-body">
                                                    <table className="table table-sm">
                                                        <tbody >
                                                            <DatoTablaMobile titulo={'Consecutivo'} dato={articulo.consecutivoFix} columna={col2} />
                                                            <DatoTablaMobile titulo={'Clasificacion1'} dato={articulo.clasificacion1} columna={col3} />
                                                            <DatoTablaMobile titulo={'Clasificacion2'} dato={articulo.clasificacion2} columna={col4} />
                                                            <DatoTablaMobile titulo={'Clasificacion3'} dato={articulo.clasificacion3} columna={col5} />
                                                            <DatoTablaMobile titulo={'Des_Corta'} dato={articulo.des_Corta} columna={col6} />
                                                            <DatoTablaMobile titulo={'Des_Larga'} dato={articulo.des_Larga} columna={col7} />
                                                            <DatoTablaMobile titulo={'Referencia'} dato={articulo.referencia} columna={col8} />
                                                            <DatoTablaMobile titulo={'UM'} dato={articulo.um} columna={col9} />
                                                            <DatoTablaMobile titulo={'UC'} dato={articulo.uc} columna={col10} />
                                                            <DatoTablaMobile titulo={'Tipo'} dato={articulo.tipo} columna={col11} />
                                                            <DatoTablaMobile titulo={'Ult_Compra'} dato={isNaN(articulo.ult_Compra) ? articulo.ult_Compra : moment.unix(articulo.ult_Compra).format("DD MMM, YYYY")} columna={col12} />
                                                            <DatoTablaMobile titulo={'Ult_Costo'} dato={`$${Intl.NumberFormat("es-CO").format(articulo.ult_Costo)}`} columna={col13} />
                                                            <DatoTablaMobile titulo={'Serial'} dato={articulo.serial ? "SI" : "NO"} columna={col14} />
                                                            <DatoTablaMobile titulo={'Estado'} dato={articulo.estado ? "SI" : "NO"} columna={col15} clase={articulo.estado !== "ACTIVO" ? "text-danger" : ""} />

                                                            {/* BTNS */}
                                                            <tr>
                                                                <td></td>
                                                                <th style={{ resize: "none" }}>Actions </th>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <button
                                                                            className="btn btn-outline-success fa-solid fa-eye border-0 btn-sm"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#imagen"
                                                                            onClick={() => handleObtenerURLImagen(articulo.imagen)} />
                                                                        <button
                                                                            className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square border-0"
                                                                            disabled={!permisos.includes("editarDataArt")}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModificarAdicionar"
                                                                            id="modalModificar"
                                                                            title="Modificar Item Seleccionado"
                                                                            onClick={() => {
                                                                                handleClickTraerData(articulo);
                                                                                setAdicionar(0);
                                                                            }} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                ))}
                            </>)
                            :
                            (<>
                                <div className="table-responsive">
                                    <table
                                        id="myTable"
                                        ref={tablaRef}
                                        className="table table-hover table-striped table-sm table-bordered mt-3">
                                        <thead className="text-center">
                                            <tr>
                                                <ThTablaH ordenar={ordenar} dOrdenar={'consecutivo'} columna={col2} titulo={'Consecutivo'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion1'} columna={col3} titulo={'Clasificacion1'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion2'} columna={col4} titulo={'Clasificacion2'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'clasificacion3'} columna={col5} titulo={'Clasificacion3'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'des_Corta'} columna={col6} titulo={'Des_Corta'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'des_Larga'} columna={col7} titulo={'Des_Larga'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'referencia'} columna={col8} titulo={'Referencia'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'um'} columna={col9} titulo={'UM'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'uc'} columna={col10} titulo={'UC'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'tipo'} columna={col11} titulo={'Tipo'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'ult_Compra'} columna={col12} titulo={'Ult_Compra'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'ult_Costo'} columna={col13} titulo={'Ult_Costo'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'serial'} columna={col14} titulo={'Serial'} />
                                                <ThTablaH ordenar={ordenar} dOrdenar={'estado'} columna={col15} titulo={'Estado'} />
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center" id="tabla_articulos">
                                            {registrosPaginaActual?.map((articulo) => (
                                                articulo.consecutivo === "Sin Datos" ?
                                                    <tr>
                                                        <td colSpan={"100%"} className="text-center">
                                                            <button
                                                                className="btn fa-solid fa-plus"
                                                                disabled={!permisos.includes("crearArt")}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModificarAdicionar"
                                                                id="modaladicionar"
                                                                title="Crear Nuevo Artículo"
                                                                onClick={() => {
                                                                    renderConsecutivos()
                                                                    setAdicionar(1);
                                                                }} />
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr key={articulo.consecutivo + articulo.id}>
                                                        <TdTablaD columna={col2} dato={articulo.consecutivoFix} />
                                                        <TdTablaD columna={col3} dato={articulo.clasificacion1} />
                                                        <TdTablaD columna={col4} dato={articulo.clasificacion2} />
                                                        <TdTablaD columna={col5} dato={articulo.clasificacion3} />
                                                        <TdTablaD columna={col6} dato={articulo.des_Corta} />
                                                        <TdTablaD columna={col7} dato={articulo.des_Larga} />
                                                        <TdTablaD columna={col8} dato={articulo.referencia} />
                                                        <TdTablaD columna={col9} dato={articulo.um} />
                                                        <TdTablaD columna={col10} dato={articulo.uc} />
                                                        <TdTablaD columna={col11} dato={articulo.tipo} />
                                                        <TdTablaD columna={col12} dato={isNaN(articulo.ult_Compra) ? articulo.ult_Compra : moment.unix(ult_Compra).format("DD MMM, YYYY")} />
                                                        <TdTablaD columna={col13} dato={`$${Intl.NumberFormat("es-CO").format(articulo.ult_Costo)}`} />
                                                        <TdTablaD columna={col14} dato={articulo.serial ? "SI" : "NO"} />
                                                        <TdTablaD columna={col15} dato={articulo.estado} clase={(articulo.estado !== "ACTIVO" && col15) ? "text-danger" : (articulo.estado !== "ACTIVO" && !col15) ? "d-none" : (col15) ? "" : "d-none"} />

                                                        {/* +Btn modificar */}
                                                        <td className="text-center">
                                                            <div className="btn-group">
                                                                <button className="fa-solid btn btn-sm btn-outline-success fa-eye"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#imagen"
                                                                    onClick={() => handleObtenerURLImagen(articulo.imagen)} />

                                                                <button
                                                                    className="btn btn-outline-info btn-sm fa-regular fa-pen-to-square"
                                                                    disabled={!permisos.includes("editarDataArt")}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#ModificarAdicionar"
                                                                    id="modalModificar"
                                                                    title="Modificar Item Seleccionado"
                                                                    onClick={() => {
                                                                        handleClickTraerData(articulo);
                                                                        setAdicionar(0);
                                                                    }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                            ))}
                                        </tbody >
                                    </table>
                                </div>
                            </>)
                    )}

                    <Pagination
                        paginaActual={paginaActual}
                        totalPaginas={totalPaginas}
                        setPaginaActual={setPaginaActual}
                        registrosPorPagina={registrosPorPagina}
                        setRegistrosPorPagina={setRegistrosPorPagina}
                        documentosFiltrados={documentosFiltrados}
                    />

                    {/* +Modal de Modificar // Adicionar*/}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarAdicionar"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">
                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title fs-5">
                                        {adicionar === 1 ? "Crear " : "Modificar "} Artículo
                                    </h5>
                                </div>

                                {/* Formulario */}
                                <div className="modal-body">

                                    {/* consecutivo */}
                                    <div className="input-group input-group-sm mb-2">
                                        {listaConsecutivos.length >= 1
                                            ? <>
                                                <span className="input-group-text">Consecutivo:</span>
                                                <input
                                                    list="seleccioneConsecutivo"
                                                    className="form-control"
                                                    disabled={!adicionar}
                                                    type="text"
                                                    placeholder="Select"
                                                    onBlur={() => handleBlurDatalist("seleccioneConsecutivo", consecutivo, setConsecutivo)}
                                                    value={consecutivo}
                                                    onChange={(e) => {
                                                        const c = listaConsecutivos.find(
                                                            (c) => c.prefijo === e.target.value
                                                        );
                                                        setConsecutivo(e.target.value);
                                                        setIdDocumentoConsecutivo(c ? c.idDocumento : "");
                                                        setValorConsecutivo(
                                                            c ? parseInt(c.valorActual) + 1 : 0
                                                        );
                                                    }} />
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={valorConsecutivo}
                                                    disabled />
                                                <datalist id="seleccioneConsecutivo" />
                                            </>
                                            :
                                            <input className="form-control text-danger text-center " readOnly
                                                value="Por favor crear el consecutivo de artículos" />}
                                    </div>

                                    {/* Clasificacion1  */}
                                    {adicionar ?
                                        (selector(listaClasificacion1, clasificacion1, "Sin clasificaciones disponibles", setClasificacion1, clf1, "Clasificacion1"))
                                        :
                                        (inputCalsf(clasificacion1, "Clasificacion 1:"))
                                    }

                                    {/* Clasificacion2  */}
                                    {adicionar ?
                                        (selector(listaClasificacion2, clasificacion2, "Sin clasificaciones disponibles", setClasificacion2, clf2, "Clasificacion2"))
                                        :
                                        (inputCalsf(clasificacion2, "Clasificacion 2:"))
                                    }

                                    {/* Clasificacion3  */}
                                    {adicionar ?
                                        (selector(listaClasificacion3, clasificacion3, "Sin clasificaciones disponibles", setClasificacion3, clf3, "Clasificacion3"))
                                        :
                                        (inputCalsf(clasificacion3, "Clasificacion 3:"))
                                    }

                                    {/* desc. corta */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Des.Corta: </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Des.Corta"
                                            value={des_Corta}
                                            onChange={(e) => setDes_Corta(e.target.value)} />
                                    </div>

                                    {/* desc. larga */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Des.Larga: </span>
                                        <input
                                            list="seleccioneClasificacion"
                                            className="form-control"
                                            type="textarea"
                                            placeholder="Des.Larga"
                                            value={des_Larga}
                                            onChange={(e) => setDes_Larga(e.target.value)} />
                                    </div>

                                    {/* referencia */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Referencia: </span>
                                        <input
                                            list="seleccioneClasificacion"
                                            className="form-control"
                                            type="text"
                                            placeholder="Referencia"
                                            value={referencia}
                                            onChange={(e) => setReferencia(e.target.value)} />
                                    </div>

                                    {/* url imagen*/}
                                    {adicionar === 0 ?
                                        null
                                        : <>
                                            <div className="input-group input-group-sm mb-2">
                                                <span className="input-group-text">Imágen: </span>
                                                <input type="file" accept="image/*"
                                                    onChange={handleImageSelect} className="form-control" ref={inputFile} />
                                            </div>
                                        </>}

                                    {/* UM // UC */}
                                    <div className="row">
                                        {/* UM */}
                                        <div className="col-sm-6">
                                            {adicionar ?
                                                (selector(listaUnidades, um, "Sin unidades disponibles", setUm, unds, "Und. Medida"))
                                                :
                                                (inputCalsf(um, "Und. Medida:"))}
                                        </div>

                                        <div className="col-sm-6">
                                            {/*  UC */}
                                            <div className="input-group input-group-sm mb-2">
                                                <span className="input-group-text">Und. Conversion: </span>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min={0}
                                                    placeholder="UC"
                                                    value={uc ? uc : ''}
                                                    onChange={(e) => setUc(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>


                                    {/* tipo */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Tipo: </span>
                                        <select
                                            className="form-select"
                                            placeholder="Tipo"
                                            value={tipo}
                                            onChange={(e) => {
                                                setTipo(e.target.value)
                                                setRutaEnFirestore(rutaArticulos + "/" + consecutivo + "-" + valorConsecutivo)
                                            }} >
                                            <option value="" disabled>Seleccione </option>
                                            <option value="EPP">EPP</option>
                                            <option value="CONSUMIBLE">Consumible</option>
                                            <option value="MATERIAL">Material</option>
                                            <option value="HERRAMIENTA">Herramienta</option>
                                        </select>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/* ultima compra */}
                                            <div className="input-group input-group-sm mb-2">
                                                <span className="input-group-text">Últ.Compra: </span>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="Fecha ultima compra"
                                                    value={ult_Compra}
                                                    onChange={(e) => {
                                                        setUlt_Compra(e.target.value)
                                                        setImagen(rutaEnFirestore)
                                                    }} />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            {/* ultimo costo */}
                                            <div className="input-group input-group-sm mb-2">
                                                <span className="input-group-text">Últ.Costo: </span>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    className="form-control"
                                                    placeholder="Costo $"
                                                    value={ult_Costo}
                                                    onChange={(e) => setUlt_Costo(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Estado */}
                                    {!adicionar &&
                                        (<div className="row">
                                            {inputCalsf(estadoV, "Estado Actual:")}
                                        </div>)}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Estado: </span>
                                        <select
                                            className="form-select"
                                            placeholder="Estado"
                                            defaultValue={estado}
                                            onChange={(e) => setEstado(e.target.value)}
                                        >
                                            <option value="" disabled> Seleccione</option>
                                            <option value="Activo">Activo</option>
                                            <option value="Inactivo">Inactivo</option>
                                        </select>
                                    </div>

                                    {/* usa serial */}
                                    <div className="input-group input-group-sm mb-2">
                                        <span className="input-group-text">Usa Serial: </span>
                                        <span className="input-group-text">
                                            <div className="form-check form-switch">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input "
                                                    checked={serial}
                                                    onChange={(e) => setSerial(e.target.checked)} />
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {clasificacion1 && clasificacion2 && clasificacion3 && um ?
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            data-bs-dismiss="modal"
                                            onClick={() =>
                                                adicionar === 1
                                                    ? handleClickAdicionar()
                                                    : handleClickModificarBd()
                                            }>
                                            {adicionar === 1 ? "Crear" : "Modificar"}
                                        </button>
                                        : null}

                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetStates()}>
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal de Modificar // Adicionar */}

                    {/* +Modal Imagen */}
                    <div className="modal fade"
                        aria-hidden="true"
                        id="imagen"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >

                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content ">

                                <div className={`modal-header ${deleteImg ? "bg-danger" : "bg-primary"} text-light`}>
                                    <h4 className="text-center mb-3 ">Imágen del artículo</h4>
                                </div>

                                <div className="modal-body">
                                    {!deleteImg && !updateImg && existe ? <>
                                        <img src={imagenURL} alt="Mi imagen" style={{
                                            "display": "block", "marginLeft": "auto",
                                            "marginRight": "auto", "width": "40%",
                                            "height": "auto"
                                        }} />
                                    </>
                                        :
                                        updateImg || !existe ? <>
                                            <div className="container mt-3">
                                                <div className="row text-center">
                                                    <h2 className={existe ? "mb-3 fs-5 text-primary" : "mb-3 fs-5 text-success"}>{existe ? "Modificar Imágen" : "Cargara imágen"}</h2>
                                                    <div className="mb-3 row">
                                                        <label className="col-sm-3 col-form-label">
                                                            Seleccione su nueva imágen:
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group mb-3">
                                                                <input type="file" accept="image/*"
                                                                    onChange={handleImageSelect} className="form-control" ref={inputFile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {imagenSeleccionada ?
                                                        <div className="col text-center">
                                                            <button className="btn btn-sm btn-primary fa-solid fa-floppy-disk"
                                                                type="button"
                                                                disabled={!permisos.includes("editarImgArt")}
                                                                data-bs-dismiss="modal"
                                                                onClick={() => {
                                                                    if (imagenSeleccionada && existe) {
                                                                        handleActualizarImagen()
                                                                        limpiar()
                                                                    } else
                                                                        if (imagenSeleccionada && !existe) {
                                                                            handleSubirImagen()
                                                                            limpiar()
                                                                        } else {
                                                                            toast.warning("Debe seleccionar un archivo para guardar, intente nuevamente")
                                                                        }
                                                                }} />
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </>
                                            : <>
                                                <div className="container mt-3">
                                                    <div className="row text-center">
                                                        <p className="mb-3"> Esta seguro de eliminar esta imágen? <br /><span className="text-danger">Recuerde que esta accion no se puede revertir</span></p>
                                                        <img src={imagenURL} alt="Mi imagen" style={{
                                                            "display": "block", "marginLeft": "auto",
                                                            "marginRight": "auto", "width": "20%",
                                                            "height": "auto"
                                                        }} />
                                                    </div>
                                                    <div className="row text-center mt-4">
                                                        <div className="col">
                                                            <div className="col">

                                                                <button className="btn btn-sm btn-danger"
                                                                    disabled={!permisos.includes("editarImgArt")}
                                                                    data-bs-dismiss="modal" title="Confirmar Eliminacion Imágen" onClick={() => {
                                                                        handleEliminarImagen()
                                                                        limpiar()
                                                                    }} >Si, Eliminar</button>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="col">
                                                                <button
                                                                    className="btn btn-sm btn-secondary fa-solid fa-ban"
                                                                    data-bs-dismiss="modal"
                                                                    title="Cancelar" onClick={() => {
                                                                        limpiar()
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                    {/* Eliminar o modificar imagen */}
                                    <div className="container mt-3">
                                        <div className="row text-center">
                                            {existe ? <>
                                                {ocultarBtn ? null : <>
                                                    <div className="col">
                                                        <button className="btn btn-sm btn-danger fa-solid fa-trash-can"
                                                            disabled={!permisos.includes("editarImgArt")}
                                                            title="Eliminar Imagen" onClick={() => {
                                                                setDeleteImg(true)
                                                                setOcultarBtn(true)
                                                            }} />
                                                    </div>
                                                </>}
                                                {ocultarBtn ? null : <>
                                                    <div className="col">
                                                        <button className="btn bnt-sm btn-primary fa-solid fa-pen-to-square"
                                                            disabled={!permisos.includes("editarImgArt")} title="Cambiar Imagen" onClick={() => {
                                                                setUpdateImg(true)
                                                                setOcultarBtn(true)
                                                            }} />
                                                    </div>
                                                </>}
                                            </> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setImagenSeleccionada("")
                                            limpiar()
                                        }}>
                                        Cancelar
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* -Modal Imagen */}

                    {/* +Modal Columnas */}
                    <div
                        className="modal fade"
                        aria-hidden="true"
                        id="ModificarColumnas"
                        tabIndex="-1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                            <div className="modal-content ">

                                <div className="modal-header bg-primary text-light">
                                    <h5 className="modal-title" id="staticBackdropLabel3">
                                        Selección de columnas
                                    </h5>
                                </div>

                                <div className="modal-body">

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={" Clasificación 1"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={" Clasificación 2"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={" Clasificación 3"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Descripción corta"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Descripción Larga"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Referencia"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9')} agregarColumna={agregarColumna} texto={"Unidad de Medida"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10')} agregarColumna={agregarColumna} texto={"Unidad de Conversión"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11')} agregarColumna={agregarColumna} texto={"Tipo"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12')} agregarColumna={agregarColumna} texto={"Última Compra"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13')} agregarColumna={agregarColumna} texto={"Último Costo"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14')} agregarColumna={agregarColumna} texto={"Usa Serial"} />
                                    </div>

                                    <div className="form-check">
                                        <CheckboxCol tabla="articulo" columna={15} checked={col15} setChecked={setCol15} validar={() => validarCheckboxes('col15')} agregarColumna={agregarColumna} texto={"Estado"} />
                                    </div>



                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal" >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -Modal Columnas */}

                </>}
        </>
    );
};

