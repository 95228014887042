import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import {
  actualizarDocumentoDatabaseIdCampo,
  consultarDocumentoWhere
} from "../../config/firebase";
import { changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import {CheckboxCol} from "../CheckboxCol";
import { LoaderES } from "../LoaderES";
import { Pagination } from "../Pagination";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "../miniComponentes";

export const DevPendientes = ({ inventario, kardex, movimientos, listaFusion, permisos }) => {
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const nombre = JSON.parse(localStorage.getItem("token")).name;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const rol = JSON.parse(localStorage.getItem("token")).tipo;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));
  const location = useLocation();
  const history = useHistory();
  const [origen, setOrigen] = useState("");

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
  const fecha_modificacion = fechaFormateada;

  const [listaFusionMovimientos, setListaFusionMovimientos] = useState([]);
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [listaInventarioActual, setListaInventarioActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaMovimientos, setListaMovimientos] = useState([]);
  const [listaRechazosP, setListaRechazosP] = useState([]);
  const [listaRechazosB, setListaRechazosB] = useState([]);
  const [listaKardex, setListaKardex] = useState([]);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("pendientesB");
  const [observacionPendientes, setObservacionPendientes] = useState("");
  const [rechazosP, setRechazosP] = useState(0);
  const [rechazosB, setRechazosB] = useState(0);

  const [contProyectoRA, setContProyectoRA] = useState(0);
  const [contProyectoRM, setContProyectoRM] = useState(0);
  const [contBodegaRA, setContBodegaRA] = useState(0);
  const [contBodegaRM, setContBodegaRM] = useState(0);
  const [lineaRecibir, setLineaRecibir] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [esRespPry, setEsRespPry] = useState(null);
  const [esRespBdg, setEsRespBdg] = useState(null);
  const [mobile, setMobile] = useState(false);

  const [col1, setCol1] = useState(true);
  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);
  const [col14, setCol14] = useState(true);
  const [col15, setCol15] = useState(true);
  const [col16, setCol16] = useState(true);
  const [col17, setCol17] = useState(true);
  const [col18, setCol18] = useState(true);
  const [col19, setCol19] = useState(true);
  const [col20, setCol20] = useState(true);
  const [col21, setCol21] = useState(true);
  const [col22, setCol22] = useState(true);
  const [col23, setCol23] = useState(true);
  const [col24, setCol24] = useState(true);
  const [col25, setCol25] = useState(true);
  const [col26, setCol26] = useState(true);
  const [col27, setCol27] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);

  //************Funciones*************/
  useEffect(() => {
    changeTitle("Admin. Devoluciones Pend.");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    isMobile(setMobile);
    handleColum([setCol1, setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14, setCol15, setCol16, setCol17, setCol18, setCol19, setCol20, setCol21, setCol22, setCol23, setCol24, setCol25, setCol26, setCol27], user, "devPendietes");
    setListaMovimientos(movimientos);
    setListaFusionMovimientos(listaFusion);
    setListaKardex(kardex);
    pendientesUsuario("mover");
    pendientesUsuario("asignar");
    validarUser();
  };

  useEffect(() => {
    const existeComponente = (location.state)?.componente
    if (existeComponente) {
      setOrigen((location.state).origen);
    }
  }, [location]);

  useEffect(() => {
    setListaMovimientos(movimientos);
  }, [movimientos]);

  useEffect(() => {
    setListaFusionMovimientos(listaFusion);
  }, [listaFusion]);

  useEffect(() => {
    setListaKardex(kardex);
  }, [kardex]);

  useEffect(() => {
    setListaInventarioActual(inventario);
  }, [inventario]);

  useEffect(() => {
    pendientesUsuario("mover");
    pendientesUsuario("asignar");
  }, [listaFusionMovimientos]);


  //-----------Tablas---------------
  const manipularCabecerasTabla = (lista, tipo, subTipo) => {
    if (tipo === "rechazos") {
      if (subTipo === "mover") {
        let cPR = 0;
        let cBR = 0;
        lista.forEach((etb) => {
          if (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined) {
            cPR = cPR + 1;
          }
          if (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) {
            cBR = cBR + 1;
          }
        });
        setContBodegaRM(cBR);
        setContProyectoRM(cPR);
      } else if (subTipo === "asignar") {
        let cPR = 0;
        let cBR = 0;
        lista.forEach((etb) => {
          if (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined) {
            cPR = cPR + 1;
          }
          if (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) {
            cBR = cBR + 1;
          }
        });
        setContBodegaRA(cBR);
        setContProyectoRA(cPR);
      }
    }

  };

  useEffect(() => {
    manipularCabecerasTabla(listaRechazosB, "rechazos", "mover");
  }, [listaRechazosB]);

  useEffect(() => {
    manipularCabecerasTabla(listaRechazosP, "rechazos", "asignar");
  }, [listaRechazosP]);

  //------------Kardex--------------
  const modificarEstadoKrdx = async (id, estadoNue, art, estadoAct, idMov) => {
    let hj;
    const valor = id.split("-")[0];
    const nuevosDatos = listaKardex.find((lk) => lk.consecutivo === id);

    for (const key in nuevosDatos) {
      const obj = nuevosDatos[key];
      const enTransito = Object(obj);

      if (enTransito.articulo === art && enTransito.estado === estadoAct && enTransito.idMov === idMov) {
        hj = enTransito;
        hj.estado = estadoNue;
        hj.observacion = observacionPendientes;
        hj.usuario_modificacion = user;
        hj.fecha_modificacion = fecha_modificacion;
      }
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, id, nuevosDatos);
      } catch (error) {
        console.error(error);
      }
    }
  };

  //--------------Pedidos-------------------
  const aceptDevol = async () => {
    const datoAModificar = lineaRecibir;
    const bod = datoAModificar.bodegaSalida;
    const pro = datoAModificar.proyectoSalida;
    const idDocumento = datoAModificar.consecutivo;
    const idDocSal = datoAModificar.idDocSalida;
    const valor = datoAModificar.consecutivo.split("-")[0];
    const ubc = idDocSal.split("\\")[0];

    const datosMov = listaMovimientos.find((lk) => lk.consecutivo === idDocumento);
    const existeBS = listaInventarioActual.find((t) => t.consecutivo === idDocSal);
    const existePS = listaInventarioActual.find((t) => t.consecutivo === idDocSal);

    const documento = ubc.startsWith("PRY") ? "proyectos" : ubc.toLowerCase();

    let datos = modifObjeto(datosMov, datoAModificar.articulo, "devuelto", "rechazado", datoAModificar.idDocSalida);

    if (bod !== undefined && existeBS !== undefined && idDocumento !== "") {
      const listaEnviarB = crearLista(existeBS, "", "devuelto");

      setIsLoading(true)
      try {
        const res = await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, existeBS?.consecutivo, listaEnviarB);
        if (res) {
          try {
            await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
          } catch (error) {
            console.error(error);
          }
        } else {
          toast.warning("Error al modificar el artículo devuelto");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false)
      }
    }

    if (pro !== undefined && existePS !== undefined && idDocumento !== "") {
      const listaEnviarP = crearLista(existePS, "", "devuelto");
      setIsLoading(true)
      try {
        const res = await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, existePS?.consecutivo, listaEnviarP);
        if (res) {
          try {
            await actualizarDocumentoDatabaseIdCampo(`${emp}_movimientos`, `${emp}_movimientos_${valor.toLowerCase()}`, datoAModificar.consecutivo, datos);
          } catch (error) {
            console.error(error);
          }
        } else {
          toast.warning("Error al modificar el artículo devuelto");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false)
      }
    }
    setIsLoading(true)
    try {
      await modificarEstadoKrdx(idDocumento, "devuelto", datoAModificar.articulo, "rechazado", datoAModificar.idMov);
      await pendientesUsuario("mover");
      await pendientesUsuario("asignar");
      toast.success("Artículo devuelto con exito al lugar de origen");
      history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  };

  const crearLista = (existe, observacionPendientes, estado) => {
    return {
      ...existe,
      estado: estado,
      observacion: observacionPendientes ? observacionPendientes : "",
      usuario_modificacion: user,
      fecha_modificacion,
    };
  };

  const modifObjeto = (datos, articulo, estadoNue, estadoAct, docSal) => {
    let hj;
    for (const key in datos) {
      const obj = datos[key];
      const enTransito = Object(obj);

      if (
        enTransito.articulo === articulo &&
        enTransito.estado === estadoAct &&
        enTransito.idDocSalida === docSal
      ) {
        hj = enTransito;
        hj.estado = estadoNue;
        hj.observacion = observacionPendientes;
        hj.usuario_modificacion = user;
        hj.fecha_modificacion = fecha_modificacion;
      }
    }
    return datos;
  };

  const validarUser = async () => {
    const datosBodega = await consultarDocumentoWhere(`${emp}_bodegas`, "responsable", nombre);
    let bdg = datosBodega?.map((b) => b.consecutivo);
    const datosProyecto = await consultarDocumentoWhere(`${emp}_proyectos`, "responsable", nombre);
    let pry = datosProyecto?.map((b) => b.consecutivo);
    if (bdg.length > 0) {
      setEsRespBdg(true);
    } else {
      setEsRespBdg(false);
    }
    if (pry.length > 0) {
      setEsRespPry(true);
    } else {
      setEsRespPry(false);
    }
  };

  const pendientesUsuario = async (tipo) => {
    let rechazadosB = [], rechazadosP = [];

    if (tipo === "mover") {
      rechazadosB = listaFusionMovimientos.filter((m) => m.consecutivo.split("-")[0] === "MOV" && m.estado === "rechazado");
      setListaRechazosB(rechazadosB);
      setDocumentosFiltrados(rechazadosB);
      setRechazosB(rechazadosB.length);
    }
    if (tipo === "asignar") {
      rechazadosP = listaFusionMovimientos.filter((m) => m.consecutivo.split("-")[0] === "ASG" && m.estado === "rechazado");
      setListaRechazosP(rechazadosP);
      setDocumentosFiltrados(rechazadosP);
      setRechazosP(rechazadosP.length);
    }
  };

  //-----Tablas--------
  const validarCheckboxes = (excluido, tipo) => {
    let contador = 0;
    if (tipo === "b") {
      if (col1 && excluido !== 'col1') contador++;
      if (col2 && excluido !== 'col2') contador++;
      if (col3 && excluido !== 'col3') contador++;
      if (col4 && excluido !== 'col4') contador++;
      if (col5 && excluido !== 'col5') contador++;
      if (col6 && excluido !== 'col6') contador++;
      if (col7 && excluido !== 'col7') contador++;
      if (col8 && excluido !== 'col8') contador++;
      if (col9 && excluido !== 'col9') contador++;
      if (col10 && excluido !== 'col10') contador++;
      if (col11 && excluido !== 'col11') contador++;
      if (col12 && excluido !== 'col12') contador++;
      if (col13 && excluido !== 'col13') contador++;
      if (col14 && excluido !== 'col14') contador++;
    }
    if (tipo === "p") {
      if (col15 && excluido !== 'col15') contador++;
      if (col16 && excluido !== 'col16') contador++;
      if (col17 && excluido !== 'col17') contador++;
      if (col18 && excluido !== 'col18') contador++;
      if (col19 && excluido !== 'col19') contador++;
      if (col20 && excluido !== 'col20') contador++;
      if (col21 && excluido !== 'col21') contador++;
      if (col22 && excluido !== 'col22') contador++;
      if (col23 && excluido !== 'col23') contador++;
      if (col24 && excluido !== 'col24') contador++;
      if (col25 && excluido !== 'col25') contador++;
      if (col26 && excluido !== 'col26') contador++;
      if (col27 && excluido !== 'col27') contador++;
    }
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //-------Paginación-----------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina));
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina);
    const fin = inicio + parseInt(registrosPorPagina);
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin));
  }, [paginaActual, totalPaginas, countOrdenar]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    if (opcionSeleccionada === "pendientesB") {
      setListaRechazosB(temporal);
      setCountOrdenar(countOrdenar + 1)
    }
    if (opcionSeleccionada === "pendientesP") {
      setListaRechazosP(temporal);
      setCountOrdenar(countOrdenar + 1)
    }
  };

  //************ Inicio componente ******************/
  return (
    <>
      {isLoading && <LoaderES />}

      <>
        <div
          className="fixed-top mt-2"
          style={{
            backgroundColor: "white",
            marginLeft: broken || mobile ? "" : !collapsed ? "250px" : "80px",
            transition: "margin-left 0.3s ease",
            top: "46px",
            zIndex: 1,
          }}
        />

        <hr className="my-3" />
        <div className="row justify-content-start ms-3 mt-4">
          <div className="col-4 text-start">
            <button className="btn btn-primary fa-solid fa-angle-left btn-sm  me-4 "
              title="Volver"
              onClick={() => {
                history.push({ pathname: `/${origen === "Movimiento" ? "Movimiento" : "movimiento"}`, state: { componente: origen } })
              }} />

            {/* Columnas */}
            <button
              className="btn fa-solid fa-table-columns"
              title="Seleccionar Columnas"
              data-bs-toggle="modal"
              data-bs-target="#ModificarColumnas"
              id="modalcolumnas"
            />
          </div>

        </div>

        {/* Menu header */}
        <ul className="nav nav-tabs mt-2">
          {/* Mov Rechazados */}
          <li
            className="nav-item user-select-none "
            disabled={!permisos.includes("movBodega")}
            onClick={() => {
              setOpcionSeleccionada("pendientesB");
            }}>
            <p className={"nav-link " + (opcionSeleccionada === "pendientesB" ? "active" : "")}
              style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}> Rechazos de Movimientos <span className="text-danger" > ({rechazosB})</span></p>
          </li>

          {/* Asig Rechazados */}
          <li
            className="nav-item user-select-none "
            disabled={!permisos.includes("movProyecto")}
            onClick={() => {
              setOpcionSeleccionada("pendientesP");
            }}>
            <p className={"nav-link " + (opcionSeleccionada === "pendientesP" ? "active" : "")}
              style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }}>Rechazos de Asignaciones <span className="text-danger" > ({rechazosP})</span></p>
          </li>
        </ul>

        <hr className="text-light mt-2 mb-0" />

        {mobile ?
          (<>
            {opcionSeleccionada === "pendientesB" &&
              (<>
                {registrosPaginaActual?.map((art) => (
                  <div key={art.consecutivo + art.id}>
                    <table className="table table-sm table-hover table-striped mb-0 mt-2">
                      <thead>
                        <tr>
                          <th style={{ width: "50px", resize: "none" }}>
                            <button className="btn  fa-solid fa-chevron-down btn-sm"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseItem" + art.consecutivo}
                              aria-expanded="false"
                              aria-controls="collapseItem" />
                          </th>
                          <th style={{ width: "75px", resize: "none" }}>{art.consecutivoFix}</th>
                          <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }} className={(art.estado !== "ACTIVO") ? "text-danger" : ""}>
                            <span className="truncate-text">
                              {art.des_Larga.length > 50 ? art.des_Larga.slice(0, 50) + "..." : art.des_Larga}
                            </span>
                          </td>
                        </tr>
                      </thead>
                    </table>

                    <div className="collapse" id={"collapseItem" + art.consecutivo}>
                      <div className="card card-body">
                        <table className="table table-sm">
                          <tbody >
                            <DatoTablaMobile titulo={"Fecha"} dato={art.fecha_modificacion} columna={col1} />
                            <DatoTablaMobile titulo={"Consec. Art"} dato={art.consecutivoFix} columna={col2} />
                            <DatoTablaMobile titulo={"Descripcion"} dato={art.des_Larga} columna={col3} />
                            <DatoTablaMobile titulo={"Tipo"} dato={art.tipo} columna={col4} />
                            <DatoTablaMobile titulo={"Estado"} dato={art.estado} columna={col5} />
                            <DatoTablaMobile titulo={"Valor"} dato={`$ ${Intl.NumberFormat("es-CO").format(art.valor)}`} columna={col6} />
                            <DatoTablaMobile titulo={"Cantidad"} dato={art.cantidad} columna={col7} />
                            <DatoTablaMobile titulo={"Consec. Mov"} dato={art.consecutivoFixMov} columna={col8} />
                            {contProyectoRM > 0 && (
                              <DatoTablaMobile titulo={"Origen"} dato={art.proyectoSalida} columna={col9} />)}
                            {contBodegaRM > 0 && (<>
                              <DatoTablaMobile titulo={"Origen"} dato={art.bodegaSalida} columna={col10} />
                              <DatoTablaMobile titulo={"Ubicacion Origen"} dato={art.ubicacionSalida} columna={col11} />
                            </>)}
                            <DatoTablaMobile titulo={"Destino"} dato={art.bodegaDestino} columna={col12} />
                            <DatoTablaMobile titulo={"Ubic. Destino"} dato={art.ubicacionDestino} columna={col13} />
                            <DatoTablaMobile titulo={"Enviado por"} dato={art.usuario_modificacion} columna={col14} />
                            <tr>
                              <td></td>
                              <th style={{ resize: "none" }}>Actions </th>
                              <td>
                                <div className="btn-group">
                                  {(esRespBdg || rol === "ADMIN" || rol === "SUPERADMIN") && (
                                    <button
                                      className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                      data-bs-toggle="modal"
                                      title="Aceptar"
                                      data-bs-target="#modalPendientes"
                                      onClick={() => setLineaRecibir(art)}
                                    />)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
            </>)
            }

            {opcionSeleccionada === "pendientesP" &&
              (<>
              {registrosPaginaActual?.map((art) => (
                <div key={art.consecutivo + art.id}>
                  <table className="table table-sm table-hover table-striped mb-0 mt-2">
                    <thead>
                      <tr>
                        <th style={{ width: "50px", resize: "none" }}>
                          <button className="btn  fa-solid fa-chevron-down btn-sm"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseItem" + art.consecutivo}
                            aria-expanded="false"
                            aria-controls="collapseItem" />
                        </th>
                        <th style={{ width: "75px", resize: "none" }}>{art.consecutivoFix}</th>
                        <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }} className={(art.estado !== "ACTIVO") ? "text-danger" : ""}>
                          <span className="truncate-text">
                            {art.des_Larga.length > 50 ? art.des_Larga.slice(0, 50) + "..." : art.des_Larga}
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>

                  <div className="collapse" id={"collapseItem" + art.consecutivo}>
                    <div className="card card-body">
                      <table className="table table-sm">
                        <tbody >
                          <DatoTablaMobile titulo={"Fecha"} dato={art.fecha_modificacion} columna={col15} />
                          <DatoTablaMobile titulo={"Consec. Art"} dato={art.consecutivoFix} columna={col16} />
                          <DatoTablaMobile titulo={"Descripcion"} dato={art.des_Larga} columna={col17} />
                          <DatoTablaMobile titulo={"Tipo"} dato={art.tipo} columna={col18} />
                          <DatoTablaMobile titulo={"Estado"} dato={art.estado} columna={col19} />
                          <DatoTablaMobile titulo={"Valor"} dato={`$ ${Intl.NumberFormat("es-CO").format(art.valor)}`} columna={col20} />
                          <DatoTablaMobile titulo={"Cantidad"} dato={art.cantidad} columna={col21} />
                          <DatoTablaMobile titulo={"Consec. Asig"} dato={art.consecutivoFixMov} columna={col22} />
                          {contProyectoRA > 0 && (
                            <DatoTablaMobile titulo={"Origen"} dato={art.proyectoSalida} columna={col23} />)}
                          {contBodegaRA > 0 && (<>
                            <DatoTablaMobile titulo={"Origen"} dato={art.bodegaSalida} columna={col24} />
                            <DatoTablaMobile titulo={"Ubic. Origen"} dato={art.ubicacionSalida} columna={col25} />
                          </>)}
                          <DatoTablaMobile titulo={"Destino"} dato={art.proyectoDestino} columna={col26} />
                          <DatoTablaMobile titulo={"Enviado por"} dato={art.usuario_modificacion} columna={col27} />
                          <tr>
                            <td></td>
                            <th style={{ resize: "none" }}>Actions </th>
                            <td>
                              <div className="btn-group">
                                {esRespPry || rol === "ADMIN" || rol === "SUPERADMIN" && (
                                  <button
                                    className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                                    data-bs-toggle="modal"
                                    title="Aceptar"
                                    data-bs-target="#modalPendientes"
                                    onClick={() => setLineaRecibir(art)}
                                  />)}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </>)
            }
          </>)
          :
          (<>
            {opcionSeleccionada === "pendientesB" &&
              (<>
                {/* +Tabla de Rechazados  mover  */}
                <div className="table-responsive mt-2">
                <h5 className="text-center mb-4">Artículos devueltos de bodega</h5>
                <table className="table table-hover table-striped table-sm">
                  <thead className="text-center">
                    <tr>
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col1} titulo={"Fecha"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col2} titulo={"Consec. Art"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col3} titulo={"Descripcion"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col4} titulo={"Tipo"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col5} titulo={"Estado"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col6} titulo={"Valor"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col7} titulo={"Cantidad"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col8} titulo={"Consec. Asig"} />
                      {contProyectoRM > 0 && (<ThTablaH ordenar={ordenar} dOrdenar={""} columna={col9} titulo={" Origen P"} />)}
                      {contBodegaRM > 0 && (<>
                        <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col10} titulo={"Origen B"} />
                        <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col11} titulo={"Ubic. Origen"} />
                      </>)}
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col12} titulo={"Destino"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col13} titulo={"Ubic. Destino"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col14} titulo={"Enviado por"} />
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual.map((art) => (
                      <tr key={art.articulo + art.tipo + art.des_Larga}>
                        <TdTablaD columna={col1} dato={art.fecha_modificacion} />
                        <TdTablaD columna={col2} dato={art.consecutivoFix} />
                        <TdTablaD columna={col3} dato={art.des_Larga} />
                        <TdTablaD columna={col4} dato={art.tipo} />
                        <TdTablaD columna={col5} dato={art.estado} />
                        <TdTablaD columna={col6} dato={`$ ${Intl.NumberFormat("es-CO").format(art.valor)}`} />
                        <TdTablaD columna={col7} dato={art.cantidad} />
                        <TdTablaD columna={col8} dato={art.consecutivoFixMov} />
                        {contProyectoRM > 0 && (
                          <TdTablaD columna={col9} dato={art.proyectoSalida} />)}
                        {contBodegaRM > 0 && (<>
                          <TdTablaD columna={col10} dato={art.bodegaSalida} />
                          <TdTablaD columna={col11} dato={art.ubicacionSalida} />
                        </>)}
                        <TdTablaD columna={col12} dato={art.bodegaDestino} />
                        <TdTablaD columna={col13} dato={art.ubicacionDestino} />
                        <TdTablaD columna={col14} dato={art.usuario_modificacion} />
                        <td className="btn-group">
                          {(esRespBdg || rol === "ADMIN" || rol === "SUPERADMIN") && (
                            <button
                              className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                              data-bs-toggle="modal"
                              title="Aceptar"
                              data-bs-target="#modalPendientes"
                              onClick={() => setLineaRecibir(art)}
                            />)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
              )}

            {opcionSeleccionada === "pendientesP" &&
              (<>
                {/* +Tabla de Rechazados  Asignaciones  */}
                <div className="table-responsive mt-2">
                  <h5 className="text-center mb-4">Artículos devueltos de proyecto</h5>
                  <table className="table table-hover table-striped table-sm">
                    <thead className="text-center">
                      <tr>
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col15} titulo={"Fecha"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col16} titulo={"Consec. Art"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col17} titulo={"Descripcion"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col18} titulo={"Tipo"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col19} titulo={"Estado"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col20} titulo={"Valor"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col21} titulo={"Cantidad"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col22} titulo={"Consec. Asig"} />
                      {contProyectoRA > 0 && (
                        <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col23} titulo={"Origen P"} />)}
                      {contBodegaRA > 0 && (<>
                        <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col24} titulo={"Origen B"} />
                        <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col25} titulo={"Ubic. Origen"} />
                      </>)}
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col26} titulo={"Destino"} />
                      <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col27} titulo={"Enviado por"} />
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>

                  <tbody className="text-center" id="tabla_articulos">
                    {registrosPaginaActual.map((art) => (
                      <tr key={art.articulo + art.tipo + art.des_Larga}>
                        <TdTablaD columna={col15} dato={art.fecha_modificacion} />
                        <TdTablaD columna={col16} dato={art.consecutivoFix} />
                        <TdTablaD columna={col17} dato={art.des_Larga} />
                        <TdTablaD columna={col18} dato={art.tipo} />
                        <TdTablaD columna={col19} dato={art.estado} />
                        <TdTablaD columna={col20} dato={`$ ${Intl.NumberFormat("es-CO").format(art.valor)}`} />
                        <TdTablaD columna={col21} dato={art.cantidad} />
                        <TdTablaD columna={col22} dato={art.consecutivoFixMov} />
                        {contProyectoRA > 0 && (
                          <TdTablaD columna={col23} dato={art.proyectoSalida} />)}
                        {contBodegaRA > 0 && (<>
                          <TdTablaD columna={col24} dato={art.bodegaSalida} />
                          <TdTablaD columna={col25} dato={art.ubicacionSalida} />
                        </>)}
                        <TdTablaD columna={col26} dato={art.proyectoDestino} />
                        <TdTablaD columna={col27} dato={art.usuario_modificacion} />
                        <td className="btn-group">
                          {esRespPry || rol === "ADMIN" || rol === "SUPERADMIN" && (
                            <button
                              className="btn btn-outline-primary btn-sm fa-solid fa-thumbs-up"
                              data-bs-toggle="modal"
                              title="Aceptar"
                              data-bs-target="#modalPendientes"
                              onClick={() => setLineaRecibir(art)}
                            />)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
              </>)}
          </>)}

        <Pagination
          paginaActual={paginaActual}
          totalPaginas={totalPaginas}
          setPaginaActual={setPaginaActual}
          registrosPorPagina={registrosPorPagina}
          setRegistrosPorPagina={setRegistrosPorPagina}
          documentosFiltrados={documentosFiltrados} />

        {/* Modal pendientes */}
        <div
          className="modal fade"
          id="modalPendientes"
          tabIndex="-1"
          aria-labelledby="modalSerialesLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="modal-header bg-primary text-light">
                <h1 className="modal-title fs-5" id="modalSerialesLabel">
                  Aceptar Devolucion artículo
                </h1>
              </div>

              {/* ------ Input Observaciones ------ */}
              <div className="modal-body">
                <div className="row mt-3">
                  {/* informacion del articulo */}
                  <div className="col">
                    <div className="input-group input-group-sm mb-2 ">
                      <p>
                        El Artículo <span>{`${lineaRecibir.articulo}:${lineaRecibir.des_Larga}`}</span>, <br />
                        ha sido devuelto por el destinatario, debe
                        aceptarlo para ingresarlo de nuevo a su bodega o
                        proyecto.
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="modal-footer">

                {/* Devolucion */}

                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => aceptDevol()}>
                  Aceptar Devolución
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setObservacionPendientes("")}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Columnas */}
        <div
          className="modal fade"
          aria-hidden="true"
          id="ModificarColumnas"
          tabIndex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div className="modal-content ">

              <div className="modal-header bg-primary text-light">
                <h5 className="modal-title" id="staticBackdropLabel3">
                  Selección de columnas {opcionSeleccionada === "pendientesB" ? "Movimientos" : "Asiganaciones"}
                </h5>
              </div>

              <div className="modal-body">

                {opcionSeleccionada === "pendientesB" ?
                  <>
                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={1} checked={col1} setChecked={setCol1} validar={() => validarCheckboxes('col1', "b")} agregarColumna={agregarColumna} texto={"Fecha Movimiento"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2', "b")} agregarColumna={agregarColumna} texto={"Consecutivo Art."} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3', "b")} agregarColumna={agregarColumna} texto={"Descripcion"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4', "b")} agregarColumna={agregarColumna} texto={"Tipo"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5', "b")} agregarColumna={agregarColumna} texto={"Estado"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6', "b")} agregarColumna={agregarColumna} texto={"Valor"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7', "b")} agregarColumna={agregarColumna} texto={"Cantidad"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8', "b")} agregarColumna={agregarColumna} texto={"Consecutivo Mov."} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9', "b")} agregarColumna={agregarColumna} texto={"Origen Proyecto"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10', "b")} agregarColumna={agregarColumna} texto={"Origen Bodega"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11', "b")} agregarColumna={agregarColumna} texto={"Ubicacion Origen"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12', "b")} agregarColumna={agregarColumna} texto={"Destino"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13', "b")} agregarColumna={agregarColumna} texto={"Ubicacion Destino"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14', "b")} agregarColumna={agregarColumna} texto={"Enviado por"} />
                    </div>

                  </>
                  :
                  <>
                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={15} checked={col15} setChecked={setCol15} validar={() => validarCheckboxes('col15', "p")} agregarColumna={agregarColumna} texto={"Fecha Asignacion"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={16} checked={col16} setChecked={setCol16} validar={() => validarCheckboxes('col16', "p")} agregarColumna={agregarColumna} texto={"Consecutivo Art"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={17} checked={col17} setChecked={setCol17} validar={() => validarCheckboxes('col17', "p")} agregarColumna={agregarColumna} texto={"Descripcion"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={18} checked={col18} setChecked={setCol18} validar={() => validarCheckboxes('col18', "p")} agregarColumna={agregarColumna} texto={"Tipo"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={19} checked={col19} setChecked={setCol19} validar={() => validarCheckboxes('col19', "p")} agregarColumna={agregarColumna} texto={"Estado"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={20} checked={col20} setChecked={setCol20} validar={() => validarCheckboxes('col20', "p")} agregarColumna={agregarColumna} texto={"Valor"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={21} checked={col21} setChecked={setCol21} validar={() => validarCheckboxes('col21', "p")} agregarColumna={agregarColumna} texto={"Cantidad"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={22} checked={col22} setChecked={setCol22} validar={() => validarCheckboxes('col22', "p")} agregarColumna={agregarColumna} texto={"Consecutivo Asig."} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={23} checked={col23} setChecked={setCol23} validar={() => validarCheckboxes('col23', "p")} agregarColumna={agregarColumna} texto={"Origen Proyecto"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={24} checked={col24} setChecked={setCol24} validar={() => validarCheckboxes('col24', "p")} agregarColumna={agregarColumna} texto={"Origen Bodega"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={25} checked={col25} setChecked={setCol25} validar={() => validarCheckboxes('col25', "p")} agregarColumna={agregarColumna} texto={"Ubicacion Origen"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={26} checked={col26} setChecked={setCol26} validar={() => validarCheckboxes('col26', "p")} agregarColumna={agregarColumna} texto={"Destino"} />
                    </div>

                    <div className="form-check">
                      <CheckboxCol tabla="devPendietes" columna={27} checked={col27} setChecked={setCol27} validar={() => validarCheckboxes('col27', "p")} agregarColumna={agregarColumna} texto={"Enviado por"} />
                    </div>
                  </>}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>

            </div>
          </div>
        </div>
      </>

    </>
  );
};