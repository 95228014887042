import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import { obtenerURLImagen } from "../../config/firebase";
import { aplanarObjetos, changeTitle, exportToExcel, fixConsecutivo, handleColum, isMobile } from "../../config/functions/funGenerales";
import { CheckboxCol } from "../CheckboxCol";
import { Filtrar2 } from '../Filtrar2';
import { LoaderES } from "../LoaderES";
import { Pagination } from '../Pagination';
import { Series } from '../Series';
import { VerImagen } from "../VerImagen";
import { DatoTablaMobile } from '../miniComponentes/DatoTablaMobile';
import { TdTablaD } from '../miniComponentes/TdTablaD';
import { ThTablaH } from '../miniComponentes/ThTablaH';
//***********Se esta trabajando en implemetar el kardex****************** */
export const ConsArticulo = ({ articulos, kardex }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;

  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

  const [listaKardexCompletaSeriales, setListaKardexCompletaSeriales] = useState([]);
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [listaKardexCompleta, setListaKardexCompleta] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaArticulos, setListaArticulos] = useState(articulos);
  const [listaKardex, setListaKardex] = useState(kardex);

  const [acumulados, setAcumulados] = useState({ superTotal: 0, cantidadArticulo: 0 });
  const [contProyecto, setContProyecto] = useState(0);
  const [contBodega, setContBodega] = useState(0);

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);
  const [col10, setCol10] = useState(true);
  const [col11, setCol11] = useState(true);
  const [col12, setCol12] = useState(true);
  const [col13, setCol13] = useState(true);
  const [col14, setCol14] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [imagenURL, setImagenURL] = useState("");
  const [show, setShow] = useState(false);


  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Consulta_kardex");
    cargaInicial()
  }, []);

  const cargaInicial = () => {
    isMobile(setMobile)
    setListaArticulos(articulos);
    asignarConsecutivo(kardex)
    handleColum(
      [setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9, setCol10, setCol11, setCol12, setCol13, setCol14],
      user,
      "consArticulo"
    );
  };

  const borrarFiltros = () => {
    const inputBuscar = document.getElementById('buscar');
    inputBuscar.value = '';
    inputBuscar.focus();
    setDocumentosFiltrados(listaKardexCompletaSeriales)
  };

  useEffect(() => { setListaArticulos(articulos) }, [articulos]);
  useEffect(() => { asignarConsecutivo(kardex) }, [kardex]);

  const asignarConsecutivo = (lista) => {
    let listaTemp = lista.map(obj => {
      let newObj = { ...obj };
      for (let key in newObj) {
        if (newObj.hasOwnProperty(key) && typeof newObj[key] === 'object') {
          newObj[key] = { ...newObj[key], consecutivo: newObj.consecutivo };
        }
      }
      return newObj;
    });
    setListaKardex(listaTemp);
  };

  useEffect(() => {
    const procesados = aplanarObjetos(listaKardex)
    if (procesados.length > 0) {
      const data = ajustarListas(procesados)
      setListaKardexCompleta(data)
      setDocumentosFiltrados(data);
    }
  }, [listaKardex]);

  const ajustarListas = (lista) => {
    const articulos = listaArticulos === undefined || (listaArticulos && listaArticulos.length === 0)
    const listaAux = lista === undefined || (lista && lista.length === 0)
    if (articulos || listaAux) return

    const arrayTemporal = []
    lista.forEach(e => {
      const temporalArticulo = listaArticulos.find(at => at.consecutivo === e.articulo)
      let consecutivoFix = '', consecutivoFixMov = '';
      consecutivoFix = temporalArticulo?.consecutivo === undefined ? consecutivoFix : fixConsecutivo(temporalArticulo?.consecutivo)
      consecutivoFixMov = e?.consec_ajin === undefined ? consecutivoFixMov : fixConsecutivo(e?.consec_ajin)
      const datocompleto = {
        ...temporalArticulo,
        ...e,
        consecutivoFix,
        consecutivoFixMov
      }
      arrayTemporal.push(datocompleto)
    });
    return (arrayTemporal)
  };

  //---------- Varios ----------
  const manipularCabecerasTabla = async () => {
    let cP = 0
    let cB = 0
    registrosPaginaActual?.forEach(etb => {
      if ((etb.proyecto !== "" && etb.proyecto !== undefined) || (etb.proyectoDestino !== "" && etb.proyectoDestino !== undefined) || (etb.proyectoSalida !== "" && etb.proyectoSalida !== undefined)) {
        cP = cP + 1
      }
      if ((etb.bodega !== "" && etb.bodega !== undefined) || (etb.bodegaSalida !== "" && etb.bodegaSalida !== undefined) || (etb.bodegaDestino !== "" && etb.bodegaDestino !== undefined)) {
        cB = cB + 1
      }
    })
    setContBodega(cB)
    setContProyecto(cP)
  };

  useEffect(() => {
    manipularCabecerasTabla()
  }, [registrosPaginaActual]);

  const calcularAcumulado = (listaMostrada) => {
    const resultado = listaMostrada?.reduce(
      (acumulados, li) => {
        const valorCelda = (li.ult_Costo !== "" && li.ult_Costo > 0 ? li.ult_Costo : 0) * ((li.cantidad > 0 && li.cantidad !== "" ? li.cantidad : 0) || (li.cantidadSolicitada > 0 && li.cantidadSolicitada !== "" ? li.cantidadSolicitada : 0));
        return {
          superTotal: acumulados.superTotal + valorCelda,
          cantidadArticulo: parseFloat(acumulados.cantidadArticulo + ((li.cantidad > 0 && li.cantidad !== "" ? li.cantidad : 0) || (li.cantidadSolicitada > 0 && li.cantidadSolicitada !== "" ? li.cantidadSolicitada : 0))),
        };
      },
      { superTotal: 0, cantidadArticulo: 0 }
    );
    return resultado;
  };

  useEffect(() => {
    const { superTotal, cantidadArticulo } = calcularAcumulado(registrosPaginaActual);
    setAcumulados({ superTotal, cantidadArticulo });

  }, [registrosPaginaActual, paginaActual]);

  //---------- Paginación ----------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados?.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina])

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados?.slice(inicio, fin))
  }, [paginaActual, totalPaginas, countOrdenar])


  //-----Columnas-----
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    if (col9 && excluido !== 'col9') contador++;
    if (col10 && excluido !== 'col10') contador++;
    if (col11 && excluido !== 'col11') contador++;
    if (col12 && excluido !== 'col12') contador++;
    if (col13 && excluido !== 'col13') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  }

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna))
    setListaKardexCompletaSeriales(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  useEffect(() => {
    const datosTemporales = listaKardexCompleta.reduce((acumulador, lk) => {
      if (lk.listaSeriales?.length > 0) {
        lk.listaSeriales.forEach(ns => {
          acumulador.push({
            ...lk,
            serialNumber: ns,
            cantidad: 1,
          });
        });
      } else {
        acumulador.push({
          ...lk,
          serialNumber: '',
        });
      }
      return acumulador;
    }, []);
    setDocumentosFiltrados(datosTemporales.sort(sortBy("consecutivoFix")));
    setListaKardexCompletaSeriales(datosTemporales.sort(sortBy("consecutivoFix")));
  }, [listaKardexCompleta]);


  const handleObtenerURLImagen = async (ruta) => {
    if (ruta !== undefined) {
      try {
        const url = await obtenerURLImagen(ruta);
        if (url) {
          setShow(true);
          setImagenURL(url);
        } else {
          toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
          setShow(false)
        }
      } catch (error) {
        toast.warning("El artículo no tiene imágen disponible, debe cargar una en este modal")
        setShow(false)
        console.error(error)
      }
    }
  };

  const cerrarModal = () => {
    setShow(false);
  };

  const formatExcel = (lista) => {
    const data = lista?.map((item) => {
      return {
        "Consecutivo Art": item?.consecutivoFix,
        "Artículo": item?.des_Larga,
        "Consecutivo Gest": item?.consecutivo,
        "Clasificacion 1": item?.clasificacion1,
        "Clasificacion 2": item?.clasificacion2,
        "Clasificacion 3": item?.clasificacion3,
        "Cantidad": item?.cantidad > 0 ? item?.cantidad : 0 || item?.cantidadSolicitada > 0 ? item?.cantidadSolicitada : 0,
        "Bodega": item?.bodega ? item?.bodega : item?.bodegaSalida,
        "Ubicacion": item?.ubicacion ? item?.ubicacion : item?.ubicacionSalida,
        "Proyecto": item?.proyecto ? item?.proyecto : item?.proyectoSalida ? item?.proyectoSalida : item?.proyectoDestino,
        "Costo en Gestion": Intl.NumberFormat("es-CO").format(item?.ult_Costo),
        "Costo Actual": Intl.NumberFormat("es-CO").format(item?.valor),
        "Valor Total": `$ ${Intl.NumberFormat("es-CO").format((item?.ult_Costo > 0 && item?.ult_Costo !== "" ? item?.ult_Costo : 0) * (item?.cantidad > 0 && item?.cantidad !== "" ? item?.cantidad : 0 || item?.cantidadSolicitada > 0 && item?.cantidadSolicitada !== "" ? item?.cantidadSolicitada : 0))}`
      }
    });
    if (data.length > 0) {
      exportToExcel(data, 'datos.xlsx')
    }
  }

  //********************************** */
  return (
    <>
      {(isLoading || listaKardexCompletaSeriales.length === 0) && <LoaderES />}

      <div className="fixed-top mt-2"
        style={{
          backgroundColor: "white",
          marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
          transition: "margin-left 0.3s ease",
          top: "46px",
          zIndex: 1
        }}>

        {/* botones de opcion */}
        <div className="row ms-2 me-2">
          <div className="col-4 align-self-start text-start">
            <div className="btn-group">

              {/* Columnas */}
              <button
                className="btn fa-solid fa-table-columns"
                title="Seleccionar Columnas"
                data-bs-toggle="modal"
                data-bs-target="#ModificarColumnas"
                id="modalcolumnas" />

              <button className="btn fa-solid fa-file-excel"
                onClick={() => {
                  formatExcel(registrosPaginaActual)
                }}
                style={{ cursor: 'pointer', color: 'green' }}
                title='Exportar a Excel'
              />

              <button
                className="btn fa-solid fa-eraser"
                title="Borrar Filtros"
                onClick={() => borrarFiltros()}
              />
            </div>
          </div>

          {/* filtrar */}
          <div className="col-8">
            <Filtrar2
              tabla="myTable"
              documentos={listaKardexCompletaSeriales}
              setDocumentosFiltrados={setDocumentosFiltrados}
              setRegistrosFiltrados={setRegistrosPaginaActual}
              registrosPorPagina={registrosPorPagina} />
          </div>
        </div>
      </div>

      <hr className={(mobile ? " mb-5" : "mt-5 mb-4")} />

      {mobile
        ?
        <>
          {registrosPaginaActual?.map((li, index) => (
            <div key={li.consecutivo}>
              <table className="table table-sm table-hover table-striped mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "50px", resize: "none" }}>
                      <button className="btn  fa-solid fa-chevron-down btn-sm"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapseItem" + (li.consecutivoFix) + (index)}
                        aria-expanded="false"
                        aria-controls="collapseItem" />
                    </th>
                    <th style={{ resize: "none" }}>{li.consecutivoFix}</th>
                    <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }}>
                      <span className={li.estado === "INACTIVO" ? "truncate-text text-danger" : "truncate-text "}>
                        {li.des_Larga?.length > 50 ? li.des_Larga?.slice(0, 50) + "..." : li.des_Larga}
                      </span>
                    </td>
                  </tr>
                </thead>
              </table>

              <div className="collapse" id={"collapseItem" + (li.consecutivoFix) + (index)}>
                <div className="card card-body">
                  <table className="table table-sm">
                    <tbody>
                      <DatoTablaMobile titulo={"Consecutivo Art."} dato={li.consecutivoFix} columna={col2} />
                      <DatoTablaMobile titulo={"Artículo"} dato={li.des_Larga} columna={col3} clase={(col3 && li.estado === "INACTIVO") ? "text-danger user-select-none" : !col3 ? " text-danger d-none" : col3 ? "" : "d-none"} />
                      <DatoTablaMobile titulo={"Consecutivo Gest."} dato={li.consecutivoFix} columna={col4} />
                      <DatoTablaMobile titulo={"Clasificacion 1"} dato={li.clasificacion1} columna={col5} />
                      <DatoTablaMobile titulo={"Clasificacion 2"} dato={li.clasificacion2} columna={col6} />
                      <DatoTablaMobile titulo={"Clasificacion 3"} dato={li.clasificacion3} columna={col7} />
                      <DatoTablaMobile titulo={"Cantidad"} dato={li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada} columna={col8} />
                      {li.bodega && (<>
                        <DatoTablaMobile titulo={"Bodega"} dato={li.bodega ? li.bodega : li.bodegaSalida} columna={col9} />
                        <DatoTablaMobile titulo={"Ubicacion"} dato={li.ubicacion ? li.ubicacion : li.ubicacionSalida} columna={col10} />
                      </>)}
                      {li.proyecto && (
                        <DatoTablaMobile titulo={"Proyecto"} dato={li.proyecto ? li.proyecto : li.proyectoDestino} columna={col11} />
                      )}
                      <DatoTablaMobile titulo={"Costo Actual"} dato={Intl.NumberFormat("es-CO").format(li.ult_Costo)} columna={col12} />
                      <DatoTablaMobile titulo={"Costo en Gestion"} dato={Intl.NumberFormat("es-CO").format(li.valor)} columna={col13} />
                      <DatoTablaMobile titulo={"Valor Total"} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada))}`} columna={col14} />
                      {li.serial &&
                        <tr>
                          <td></td>
                          <th style={{ resize: "none" }}>Ver Series </th>
                          <td>
                            <Series articulo={li} />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </>
        :
        <div className="table-responsive">
          <table id="myTable" className="table table-hover table-striped table-sm table-bordered mt-2">
            <thead className="text-center">
              <tr>
                <ThTablaH ordenar={ordenar} dOrdenar={"consecutivoFix"} columna={col2} titulo={"Consec. Art"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"des_Larga"} columna={col3} titulo={"Artículo"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"consecutivo"} columna={col4} titulo={"Consec. Gest."} />
                <ThTablaH ordenar={ordenar} dOrdenar={"clasificacion1"} columna={col5} titulo={"Clasificacion 1"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"clasificacion2"} columna={col6} titulo={"Clasificacion 2"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"clasificacion3"} columna={col7} titulo={"Clasificacion 3"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"cantidad"} columna={col8} titulo={"Cantidad"} />
                {contBodega > 0 && (<>
                  <ThTablaH ordenar={ordenar} dOrdenar={"bodega" || "bodegaSalida" || "bodegaDestino"} columna={col9} titulo={"Bodega"} />
                  <ThTablaH ordenar={ordenar} dOrdenar={"ubicacion" || "ubicacionSalida" || "ubicacionDestino"} columna={col10} titulo={"Ubicacion"} /></>)}
                {contProyecto > 0 && (
                  <ThTablaH ordenar={ordenar} dOrdenar={"proyecto" || "proyectoSalida" || "proyectoDestino"} columna={col11} titulo={"Proyecto"} />)}
                <ThTablaH ordenar={ordenar} dOrdenar={"ult_Costo"} columna={col12} titulo={"Costo en Gestion"} />
                <ThTablaH ordenar={ordenar} dOrdenar={"valor"} columna={col13} titulo={"Costo Actual"} />
                <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col14} titulo={"Valor Total"} />
                <th scope="col">Opciones</th>
              </tr>
            </thead>

            <tbody className="text-center" id="tabla_articulos">
              {registrosPaginaActual?.map((li, index) => (
                <tr key={li.articulo + li.consecutivo + index}>
                  <TdTablaD columna={col2} dato={li.consecutivoFix} />
                  <TdTablaD columna={col3} dato={li.des_Larga} clase={li.estado === "INACTIVO" && "text-danger user-select-none"} />
                  <TdTablaD columna={col4} dato={li.consecutivo} />
                  <TdTablaD columna={col5} dato={li.clasificacion1} />
                  <TdTablaD columna={col6} dato={li.clasificacion2} />
                  <TdTablaD columna={col7} dato={li.clasificacion3} />
                  <TdTablaD columna={col8} dato={li.cantidad > 0 ? li.cantidad : 0 || li.cantidadSolicitada > 0 ? li.cantidadSolicitada : 0} />
                  {contBodega > 0 && (<>
                    <TdTablaD columna={col9} dato={li.bodega ? li.bodega : li.bodegaSalida} />
                    <TdTablaD columna={col10} dato={li.ubicacion ? li.ubicacion : li.ubicacionSalida} />
                  </>)}
                  {contProyecto > 0 && (
                    <TdTablaD columna={col11} dato={li.proyecto ? li.proyecto : li.proyectoSalida ? li.proyectoSalida : li.proyectoDestino} />
                  )}
                  <TdTablaD columna={col12} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor > 0 ? li.valor : 0)}`} />
                  <TdTablaD columna={col13} dato={`$ ${Intl.NumberFormat("es-CO").format(li.ult_Costo > 0 ? li.ult_Costo : 0)}`} />
                  <TdTablaD columna={col14} dato={`$ ${Intl.NumberFormat("es-CO").format((li.ult_Costo > 0 && li.ult_Costo !== "" ? li.ult_Costo : 0) * (li.cantidad > 0 && li.cantidad !== "" ? li.cantidad : 0 || li.cantidadSolicitada > 0 && li.cantidadSolicitada !== "" ? li.cantidadSolicitada : 0))}`} />
                  <td>
                    {li.serial ?
                      <Series articulo={li} />
                      : null}
                    {li.consecutivo?.split("-")[0] === "COM" &&
                      <button className="btn btn-sm btn-outline-primary fa-solid fa-file-invoice-dollar"
                        title="Ver Factura" onClick={() => handleObtenerURLImagen(li.imagen)} />}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="text-center text-danger">
              <tr key={acumulados.cantidadArticulo}>
                <td className={col2 ? "" : "d-none"}></td>
                <td className={col3 ? "" : "d-none"}></td>
                <td className={col4 ? "" : "d-none"}></td>
                <td className={col5 ? "" : "d-none"}></td>
                <td className={col6 ? "" : "d-none"}></td>
                <td className={col7 ? "" : "d-none"}></td>
                <td className={col8 ? "text-center" : "d-none"}>{Intl.NumberFormat(undefined, { mininumFractionsDigits: 2 }).format(acumulados.cantidadArticulo)}</td>
                {contBodega > 0 && (<> <td className={col9 ? "" : "d-none"}></td>
                  <td className={col10 ? "" : "d-none"} ></td></>)}
                {contProyecto > 0 && <td className={col11 ? "" : "d-none"}></td>}
                <td className={col12 ? "" : "d-none"} ></td>
                <td className={col13 ? "" : "d-none"} ></td>
                <td className={col14 ? "text-center" : "d-none"}  >$ {Intl.NumberFormat("es-CO").format(acumulados.superTotal)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      }

      <Pagination
        paginaActual={paginaActual}
        totalPaginas={totalPaginas}
        setPaginaActual={setPaginaActual}
        registrosPorPagina={registrosPorPagina}
        setRegistrosPorPagina={setRegistrosPorPagina}
        documentosFiltrados={documentosFiltrados}
      />

      {/* Modal Imagen */}
      <VerImagen url={imagenURL} show={show} handleClose={cerrarModal} nombre={""} />

      {/* +Modal Columnas */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModificarColumnas"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title" id="staticBackdropLabel3">
                Selección de columnas
              </h5>
            </div>

            <div className="modal-body">

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo Art"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Artículo"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Consec. Gestion"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5')} agregarColumna={agregarColumna} texto={"Clasificacion 1"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6')} agregarColumna={agregarColumna} texto={"Clasificacion 2"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7')} agregarColumna={agregarColumna} texto={"Clasificacion 3"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8')} agregarColumna={agregarColumna} texto={"Cantidad"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9')} agregarColumna={agregarColumna} texto={"Bodega"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={10} checked={col10} setChecked={setCol10} validar={() => validarCheckboxes('col10')} agregarColumna={agregarColumna} texto={"Ubicacion"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={11} checked={col11} setChecked={setCol11} validar={() => validarCheckboxes('col11')} agregarColumna={agregarColumna} texto={"Proyecto"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={12} checked={col12} setChecked={setCol12} validar={() => validarCheckboxes('col12')} agregarColumna={agregarColumna} texto={"Costo en Gestion"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={13} checked={col13} setChecked={setCol13} validar={() => validarCheckboxes('col13')} agregarColumna={agregarColumna} texto={" Costo Actual"} />
              </div>
              <div className="form-check">
                <CheckboxCol tabla="consArticulo" columna={14} checked={col14} setChecked={setCol14} validar={() => validarCheckboxes('col14')} agregarColumna={agregarColumna} texto={" Valor Total"} />
              </div>

            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
