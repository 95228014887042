import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import uniqid from "uniqid";
import { read, utils } from "xlsx";
import { validarConsecArt } from "../../config/functions/funAdmin";
import "../../css/modal.css";
import { actualizarDocumentoDatabase, guardarDatabaseId } from '../../config/firebase';
import { LoaderES } from "../LoaderES";

export const CargaMasiva = ({
  permiso,
  head,
  campoFecha,
  listaClasf1,
  listaClasf2,
  listaClasf3,
  listaConstvo,
  listaArt,
  articulosNuevos,
}) => {
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const fechaActual = new Date();
  const fechaActualFormat = fechaActual.toLocaleDateString("es-CO");

  const [listConModif, setListConModif] = useState([]);
  const [sonIguales, setSonIguales] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [minuscula, setMinuscula] = useState([]);
  const [errores, setErrores] = useState([]);
  const [datosX, setDatosX] = useState([]);
  const [headX, setHeadX] = useState([]);
  const [infoX, setInfoX] = useState([]);
  const [ok, setOk] = useState(false);
  const [per, setPer] = useState(false);
  /******** FUNCIONES ***********/

  useEffect(() => {
    const temporal = permiso;
    if (temporal.length > 0) {
      setPer(temporal.includes("cargaMasArt"));
      // setPer(temporal.includes("descargaPlantArt"));
    }
  }, [permiso])

  useEffect(() => {
    // setPer(permiso)
    const seleccionarArchivo = () => {
      const fileInput = document.getElementById("file-input");
      fileInput.click();
    };
    const fileInputButton = document.getElementById("file-input-button");
    if (fileInputButton) {
      fileInputButton.addEventListener("click", seleccionarArchivo);
    }
    return () => {
      if (fileInputButton) {
        fileInputButton.removeEventListener("click", seleccionarArchivo);
      }
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      setDatosX(jsonData);
      toast.success("Datos obtenidos correctamente");
      const cx = jsonData.length >= 1 ? Array.from(jsonData[0]) : [];
      const dxArray = jsonData.length >= 1 ? Array.from(jsonData) : [];
      dxArray.splice(0, 1);
      setHeadX(cx);
      setInfoX(dxArray);
    };
    reader.readAsArrayBuffer(file);
  };

  const validarCabecerasIguales = () => {
    const ct = head?.map((valT) => valT?.toLowerCase());
    const cx = headX?.map((valX) => valX?.toLowerCase());

    const res =
      ct.length === cx.length &&
      ct.every((valor, index) => valor === cx[index].toLowerCase());
    if (res) {
      toast.success("Las cabeceras son iguales");
      convertirA_Objeto(datosX, campoFecha);
    } else {
      toast.warning(
        "Las cabeceras no coinciden, verifique el archivo e intente nuevamente."
      );
    }
    setSonIguales(res);
  };

  const convertirA_Objeto = async (val, campo) => {
    const resultado = {};
    const url = `gs://mn-app-60991.appspot.com/${emp}/articulos/`;
    let conSerial = null, fec;
    let consec = "";
    let img = "";

    for (let i = 1; i < val.length; i++) {
      const item = val[i];
      let objeto = {};

      for (let j = 0; j < item.length; j++) {
        const clave = val[0][j];
        let valor = item[j];

        if (typeof valor === "string") {
          valor = valor.trim().toUpperCase();
        }

        if (clave === campo) {
          const valorClave = valor;
          if (typeof valorClave !== "string") {
            fec = convertirFechas(valorClave);
          }
          else {
            fec = valorClave;
          }
        }

        if (clave === "serial") {
          if (valor === "1" || valor === 1) {
            conSerial = true;
          } else if (valor === "0" || valor === 0) {
            conSerial = false;
          } else {
            conSerial = "error"
          }
        }

        if (clave === "consecutivo") {
          consec = valor;
          img = `${url}${consec}`;
        }

        if (valor !== undefined && valor !== null && valor !== "") {
          objeto[clave] = valor;
        }
      }
      if (Object.keys(objeto).length > 0) {
        objeto.id = uniqid("articulo-");
        objeto.fecha_creacion = fechaActualFormat;
        objeto.usuario_creacion = user;
        objeto.serial = conSerial;
        objeto.imagen = img;
        objeto.ult_Compra = fec;
      }
      resultado[objeto.consecutivo] = objeto
    }
    setMinuscula(resultado);
  };

  const convertirFechas = (fecExel) => {
    const excelEpoch = new Date("1899-12-31");
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const offset = (fecExel) * millisecondsPerDay;
    const date = new Date(excelEpoch.getTime() + offset);

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear().toString();

    return `${dia}/${mes}/${anio}`;
  };

  const validarData = () => {
    let contador = 0;
    const listaNueva = [], listConsec = [];
    let res = validarConsecArt("art", listaArt, "");

    if (minuscula && Object.keys(minuscula).length > 0) {
      for (const key1 in minuscula) {
        if (minuscula.hasOwnProperty(key1)) {
          contador++;
          const objeto1 = minuscula[key1];
          const consecutivo = objeto1.consecutivo;
          const clasif1 = objeto1.clasificacion1;
          const clasif2 = objeto1.clasificacion2;
          const clasif3 = objeto1.clasificacion3;
          const prefijo = consecutivo && consecutivo.split("-")[0];
          const valConc = consecutivo && consecutivo.split("-")[1];
          const serial = objeto1.serial;
          const deLar = objeto1.des_Larga;

          listConsec.push(consecutivo);

          if (res[prefijo] !== undefined && (valConc < res[prefijo] || valConc === res[prefijo]))
            listaNueva.push(`El consecutivo de ${prefijo} va en ${res[prefijo]}, debe validarlo`)

          const existeConsec = listaArt.some((c) => c.consecutivo === consecutivo);
          if (existeConsec) listaNueva.push("El consecutivo: " + consecutivo + " ya existe")

          const desLarga = listaArt.some((c) => c.des_Larga === deLar);
          if (desLarga) listaNueva.push("El articulo: " + deLar + " ya existe")

          const existePrefijoConsctvo = listaConstvo.some((pf) => pf.prefijo === prefijo);
          if (!existePrefijoConsctvo) listaNueva.push("El consecutivo: " + prefijo + " no existe")

          const existeMarca = listaClasf1.includes(clasif1);
          if (!existeMarca) listaNueva.push("La clasificacion:" + clasif1 + " no existe")

          const existeFamilia = listaClasf2.includes(clasif2);
          if (!existeFamilia) listaNueva.push("La clasificacion:" + clasif2 + " no existe")

          const existeProveedor = listaClasf3.includes(clasif3);
          if (!existeProveedor) listaNueva.push("La clasificacion:" + clasif3 + " no existe")

          if (serial === "error") listaNueva.push("Serial de tipo 'si' o 'no' es invalido, es '1' o '0'")

          if (contador === Object.keys(minuscula).length) {
            let res = validarConsecArt("con", "", listConsec);
            setListConModif(res);
            const listaSinDuplicados = [...new Set(listaNueva)];
            setErrores(listaSinDuplicados)
            if (listaNueva.length === 0) {
              toast.success("Puede subir la informacion sin problemas")
              setOk(true)
            }
          }
        }
      }
    }
  };

  const tableHeaders = head?.map((header, index) => {
    return <th key={index}>{header}</th>;
  });

  const guardarMasivos = async () => {
    setIsLoading(true);
    if (minuscula && Object.keys(minuscula).length > 0) {
      try {
        await guardarDatabaseId(`${emp}_articulos`, `${emp}_articulos`, minuscula);
        actualizarConsecutivos();
        toast.success("Datos cargardos correctamente")
        const arrayDeObjetos = Object.values(minuscula);
        const listaFusionada = listaArt.concat(arrayDeObjetos);

        if (articulosNuevos) {
          articulosNuevos(listaFusionada);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error)
      }
    }
  };

  const actualizarConsecutivos = () => {
    let contador = 0, idDoc;
    for (let key in listConModif) {
      let doc = listaConstvo.find((c) => c.prefijo === key);
      idDoc = doc.idDocumento;

      const actualizarConsecutivo = async () => {
        const valCon = listConModif[key];
        const valorActual = {
          valorActual: (parseInt(valCon)),
          usuario_modificacion: user,
          fecha_modificacion: fechaActualFormat,
        };
        try {
          await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, valorActual);
        } catch (error) {
          console.error(error)
        }
      };
      contador++;
      actualizarConsecutivo();
    }
  }


  //---------------------------------------------------
  return (
    <>
      <ToastContainer position="bottom-right" />

      {/* Boton carga masiva */}
      {isLoading ?
        <LoaderES /> : null}
      {datosX.length === 0 ?
        (<>
          <button
            id="file-input-button"
            // disabled={!per}
            disabled
            className="btn fa-solid fa-file-csv"
            title="Cargar Datos desde Excel" />
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange} />
        </>)
        : null}

      {datosX.length >= 1 ?
        (
          <button
            className="btn btn-sm  fa-regular fa-eye"
            data-bs-toggle="modal"
            data-bs-target="#ModalVistaDatos"
            id="modalEliminar"
            title="Visualizar Datos Cargados"
            onClick={() => {
              validarCabecerasIguales();
            }} />
        )
        : null}

      {/* +Modal de Visualizacion*/}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModalVistaDatos"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false">

        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header text-center">
              <h3 className={sonIguales ? "modal-title " : "modal-title text-danger"}>
                {sonIguales
                  ? "Las cabeceras coinciden"
                  : "Las cabeceras no coinciden"}
              </h3>
            </div>

            <div className="modal-body">
              <table className="table table-hover table-striped table-sm">
                <thead className="text-center">
                  <tr>{tableHeaders}</tr>
                </thead>

                <tbody className="text-center" id="tabla_articulos">
                  {infoX?.map((x) => (
                    <tr key={x + "errores"}>
                      {x?.map((y, colIndex) => (
                        <td key={colIndex}>
                          {y}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="modal-footer">
              {sonIguales && !ok ?
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalError"
                  id="modalEliminar"
                  title="Visualizar Datos Cargados"
                  onClick={() => {
                    validarData();
                  }}>Validar data
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                  setSonIguales(false);
                  setOk(false);
                }}>Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* -Modal de fallas */}
      <div
        className="modal fade "
        aria-hidden="true"
        id="ModalError"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false">

        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable  ">
          <div className="modal-content ">

            <div className="modal-header text-center">
              <h3 className="modal-title ">
                Resultado validacion
              </h3>
            </div>

            <div className="modal-body text-center">
              {ok ?
                <h5 className="modal-title ">
                  Puede constinuar con la carga, la validacion ha sido existosa
                </h5>
                : <>
                  <h6 className={ok ? "modal-title " : "modal-title text-danger"}>
                    Se han detectado problemas de coincidencia en los siguientes campos. Por favor, corríjalos e inténtelo de nuevo.
                  </h6>
                  <table className="table table-hover table-striped table-sm">
                    <thead className="text-center">
                      <tr><th>Problemas</th></tr>
                    </thead>

                    <tbody className="text-center" id="tabla_articulos">
                      {errores?.map((x) => (
                        <tr key={x + "error"}>
                          <td >
                            {x}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>}
            </div>

            <div className="modal-footer">
              {ok ?
                <button
                  type="button"
                  className="btn btn-primary text-light"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setDatosX([]);
                    setInfoX([]);
                    setHeadX([]);
                    guardarMasivos();
                  }} >
                  Cargar Datos
                </button>
                : null}

              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDatosX([]);
                  setInfoX([]);
                  setHeadX([]);
                }}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
