import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";

import sortBy from "sort-by";
import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  consultarDatabase,
  consultarDocumentoWhere,
  consultarDocumentoWhereIgual,
  guardarDatabaseId
} from "../../config/firebase";
import { isMobile, styles } from "../../config/functions/funGenerales";
import { changeTitle } from '../../config/functions/funGenerales';
import { LoaderES } from "../LoaderES";

export const Movsalida = ({ inventario, articulos, terceros, kardex, permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [listaConsecutivosBajas, setlistaConsecutivosBajas] = useState([]);
  const [listaConsecutivosVentas, setlistaConsecutivosVentas] = useState([]);
  const [listaUbicaciones, setListaUbicaciones] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaKardex, setListaKardex] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [listaProyectos, setListaProyecto] = useState([]);

  const [listaInventarioActual, setListaInventarioActual] = useState([]);
  const [serialesDisponibles, setSerialesDisponibles] = useState([]);
  const [verListaSeriales, setVerListaSeriales] = useState([]);
  const [listaSeriales, setListaSeriales] = useState([]);
  const [listaVentas, setListaVentas] = useState([]);
  const [listaBajas, setListaBajas] = useState([]);

  const [valorConsecutivoBajas, setValorConsecutivoBajas] = useState(0);
  const [valorPrefijoBajas, setValorPrefijoBajas] = useState("");
  const [valorConsecutivo, setValorConsecutivo] = useState(0);
  const [valorPrefijo, setValorPrefijo] = useState("");
  const [observacion, setObservacion] = useState("");
  const [maxCantidad, setMaxCantidad] = useState(0);
  const [fechaVenta, setFechaVenta] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [fechaBaja, setFechaBaja] = useState("");
  const [proyecto, setProyecto] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [cliente, setCliente] = useState("");
  const [factura, setFactura] = useState("");
  const [bodega, setBodega] = useState("");
  const [valor, setValor] = useState(1);

  const [idDocumentoConsecutivoBajas, setIdDocumentoConsecutivoBajas] = useState("");
  const [idDocumentoConsecutivo, setIdDocumentoConsecutivo] = useState("");
  const [verConsecutivoVentas, setVerConsecutivoVentas] = useState("");
  const [verConsecutivoBajas, setVerConsecutivoBajas] = useState("");
  const [verSeriales, setVerSeriales] = useState(false);

  const [consecMov, setConsecMov] = useState("");
  const fecha_modificacion = fechaFormateada;
  const fecha_creacion = fechaFormateada;
  const consecVenta = "VEN";
  const consecBaja = "BJA";

  const [opcionSeleccionada, setOpcionSeleccionada] = useState("darBaja");
  const [valoresSeleccionados, setValoresSeleccionados] = useState([]);
  const [contRenSeriales, setContRenSeriales] = useState(0);
  const [opcionSelecBoP, setOpcionSelecBoP] = useState("");
  const [enProyecto, setEnProyecto] = useState(0);
  const [enBodega, setEnBodega] = useState(0);

  const [contProyecto, setContProyecto] = useState(0);
  const [dataEmpresa, setDataEmpresa] = useState([]);
  const [listaVerPDF, setListaVerPDF] = useState([]);
  const [contBodega, setContBodega] = useState(0);
  const [guardo, setGuardo] = useState(false);

  const [ubicacionSelect, setUbicacionSelect] = useState({ value: "Seleccione Ubicación", label: "Seleccione Ubicación" });
  const [proyectoSelect, setProyectoSelect] = useState({ value: "Seleccione Proyecto", label: "Seleccione Proyecto" });
  const [articuloSelect, setArticuloSelect] = useState({ value: "Seleccione Artículo", label: "Seleccione Artículo" });
  const [clienteSelect, setClienteSelect] = useState({ value: "Seleccione Cliente", label: "Seleccione Cliente" });
  const [bodegaSelect, setBodegaSelect] = useState({ value: "Seleccione Bodega", label: "Seleccione Bodega" });
  const [ubicaciones, setUbicaciones] = useState(listaUbicaciones);
  const [proyectos, setProyectos] = useState();
  const [articulosL, setArticulosL] = useState(listaArticulos);
  const [clientes, setClientes] = useState(listaArticulos);
  const [bodegas, setBodegas] = useState();

  const [sinArticulos, setSinArticulos] = useState(false);
  const [sinClientes, setSinClientes] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [articuloSeleccionado, setArticuloSeleccionado] = useState({})
  const [inventarioArticuloBodega, setInventarioArticuloBodega] = useState([])
  const [inventarioArticuloProyecto, setInventarioArticuloProyecto] = useState([])

  //*************Funciones*******************
  useEffect(() => {
    changeTitle("Admin. Movsalida");
    isMobile(setMobile);
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    setListaInventarioActual(inventario);
    setListaArticulos(articulos)
    setListaKardex(kardex)
    validarArticulos()
    cargarConsecutivos(consecBaja, setlistaConsecutivosBajas);
    cargarConsecutivos(consecVenta, setlistaConsecutivosVentas);
    traerEmpresa();
    cargarDestino(`${emp}_bodegas`, setListaBodegas);
    cargarDestino(`${emp}_proyectos`, setListaProyecto);
  };

  useEffect(() => {
    setListaInventarioActual(inventario);
  }, [inventario])

  useEffect(() => {
    validarArticulos()
  }, [articulos])

  useEffect(() => {
    setListaKardex(kardex)
  }, [kardex])

  const validarArticulos = () => {
    let temporal
    if (articulos.length === 0) {
      temporal = [{ id: 0 }];
      setSinArticulos(true);
    } else {
      setSinArticulos(false);
      temporal = articulos;
    }
    setListaArticulos(temporal);
  }

  //-----------Varios-----------
  const cargarDestino = async (tabla, setLista) => {
    try {
      const listaTemporal = await consultarDatabase(tabla);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setLista(activos);
    } catch (error) {
      console.error(error);
    }
  };

  const traerEmpresa = async () => {
    try {
      const empresaTemp = await consultarDocumentoWhereIgual("empresas", "idEmpresa", emp)
      setDataEmpresa(empresaTemp)
    } catch (error) {
      console.error(error)
    }
  };

  const obtenerFechaActual = () => {
    const currentDate = new Date()
    const offset = -5 * 60; // Offset en minutos (UTC-5)
    const today = new Date(currentDate.getTime() + offset * 60 * 1000).toISOString().split("T")[0];
    return today;
  };

  const limpiarRadioBtn = () => {
    setOpcionSelecBoP("");
    const radioButtons = document.getElementsByName("opcionSelecBoP");
    radioButtons.forEach((radio) => { radio.checked = false; });
  };

  const handleBlurDatalist = (datalistId, value, setValue) => {
    const datalist = document.getElementById(datalistId);
    const option = Array.from(datalist.options).find(opt => opt.value === value);
    if (!option) { setValue(""); }
  };

  const cargarSelect = () => {
    if (opcionSeleccionada === "cargar-venta") {
      cargarClientes();
    }
    setGuardo(false);
    setListaVerPDF([]);
  };

  //------------Consecutivos---------------
  const cargarConsecutivos = async (prefijo, setLista) => {
    try {
      const listaTemporal = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", prefijo);
      const activos = listaTemporal.filter((a) => a.estado == "ACTIVO");
      setLista(activos);
    } catch (error) {
      console.error(error)
    }
  };

  const manipularConsecutivos = (lista, setConsecutivo, setPrefijo, setIdDoc, setVerConsec) => {
    const valorCon = lista[0]?.valorActual;
    const pref = lista[0]?.prefijo;
    const doc = lista[0]?.idDocumento;
    const val = parseInt(valorCon + 1);
    setConsecutivo(val);
    setPrefijo(pref);
    setIdDoc(doc);
    setVerConsec(pref + "-" + val);
  };

  const handleModificarConsecutivos = async (valor, idDoc, manipular) => {
    const ListaModificacion = {
      valorActual: parseInt(valor.split("-")[1]),
      fecha_modificacion: fecha_modificacion,
      usuario_modificacion: user,
    };
    try {
      await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, ListaModificacion);
    } catch (error) {
      console.error(error)
    }
    if (manipular === "venta") {
      await cargarConsecutivos(consecVenta, setlistaConsecutivosVentas)
      manipularConsecutivos(listaConsecutivosVentas, setValorConsecutivo, setValorPrefijo, setIdDocumentoConsecutivo, setVerConsecutivoVentas);

    } else if (manipular === "bajas") {
      await cargarConsecutivos(consecBaja, setlistaConsecutivosBajas);
      manipularConsecutivos(listaConsecutivosBajas, setValorConsecutivoBajas, setValorPrefijoBajas, setIdDocumentoConsecutivoBajas, setVerConsecutivoBajas);
    }
  };

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosBajas, setValorConsecutivoBajas, setValorPrefijoBajas, setIdDocumentoConsecutivoBajas, setVerConsecutivoBajas);
  }, [listaConsecutivosBajas]);

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosVentas, setValorConsecutivo, setValorPrefijo, setIdDocumentoConsecutivo, setVerConsecutivoVentas);
  }, [listaConsecutivosVentas]);

  useEffect(() => {
    if (listaArticulos.length > 0 && listaArticulos[0].id !== 0 && listaInventarioActual.length > 0) {
      let cantidadesDisponibles = {}, total
      for (let i = 0; i < listaInventarioActual.length; i++) {
        let consecutivo = listaInventarioActual[i].articulo;
        if (listaInventarioActual[i].hasOwnProperty('cant_disponible')) {
          if (!cantidadesDisponibles.hasOwnProperty(consecutivo)) {
            cantidadesDisponibles[consecutivo] = 0;
          }
          cantidadesDisponibles[consecutivo] += listaInventarioActual[i].cant_disponible;
        }
      }
      const options = listaArticulos.map(a => {
        let cantDis = parseFloat(cantidadesDisponibles.hasOwnProperty(a.consecutivo) ? cantidadesDisponibles[a.consecutivo] : 0);
        let um = a.um;
        let est = a.estado;
        // total = cantDis;
        total = Math.floor(cantDis * 10) / 10;

        return { value: a.consecutivo, label: a.consecutivo + ": " + a.des_Larga + ` (Cant: ${total}-${um}, Est: ${est})` };
      });
      setArticulosL(options?.sort(sortBy("label")));
    }
  }, [listaArticulos, listaInventarioActual]);

  //-----------Clientes--------------
  const cargarClientes = async () => {
    const listaTemporal = terceros
    if (listaTemporal.length === 0) {
      setSinClientes(true)
      toast.warning("No se encontraron clientes creados en la base de datos")
    } else {
      const activos = listaTemporal.filter((act) => act.estado === "ACTIVO" && act.tipo === "CLIENTE");
      if (activos.length > 0) {
        setSinClientes(false)
      } else {
        setSinClientes(true)
      }
      setListaClientes(activos);
    }
  };

  useEffect(() => {
    if (listaClientes.length > 0 && listaClientes[0].id !== 0) {
      const options = listaClientes.map(a => ({ value: a.razonSocial, label: a.razonSocial }));
      setClientes(options?.sort(sortBy("value")))
    }
  }, [listaClientes]);

  //---------Seriales-----------
  const validarSeriales = () => {
    let k = 0;
    if (valoresSeleccionados) {
      valoresSeleccionados?.forEach(serial => {
        if (serial !== "") {
          k++;
        }
      });
      if (k === cantidad) {
        toast.success("Puede continuar con el registro");
      } else {
        toast.warning(
          "La cantidad de seriales no coincide con la cantidad de articulos, por favor verifica e intenta nuevamente."
        );
      }
    } else {
      toast.warning("La lista de seriales esta vacia");
    }
  };

  const handleSerialChange = (index, value) => {
    setValoresSeleccionados((prevValores) => {
      const nuevosValores = [...prevValores];
      nuevosValores[index] = value;
      return nuevosValores;
    });
  };

  const obtenerListaSeriales = (lista) => {

    let artEncontrado, stock, art;
    let listaSer = [];

    if (bodega && ubicacion) {
      artEncontrado = lista.find((item) => item.bodega === bodega && item.ubicacion === ubicacion);
      stock = artEncontrado?.cant_disponible;
      art = artEncontrado?.articulo;
    }
    if (proyecto) {
      artEncontrado = lista.find((item) => item.proyecto === proyecto);
      stock = artEncontrado?.cant_reservada;
      art = artEncontrado?.articulo;
    }
    if (stock === 0) {
      toast.warning("No hay existencia de: " + art + " verifica e intenta de nuevo.");
      resetStates();
    }
    listaSer = artEncontrado?.listaSeriales

    setSerialesDisponibles(listaSer)
  };

  useEffect(() => {
    obtenerListaSeriales(inventarioArticuloProyecto)
  }, [proyectoSelect])

  useEffect(() => {
    obtenerListaSeriales(inventarioArticuloBodega)
  }, [ubicacionSelect])

  const listaSerialesParaModal = (lista, articulo, fecha) => {
    let inventario = [];
    if (lista === listaBajas) {
      inventario = lista?.find(
        (item) => item.articulo === articulo && item.fechaBaja === fecha
      );
    } else {
      inventario = lista?.find(
        (item) => item.articulo === articulo && item.fechaVenta === fecha
      );
    }
    if (inventario) {
      return inventario.listaSeriales;
    } else {
      toast.warning("Articulo no encontrado");
      return [];
    }
  };

  const manejarCantidad = (cantSer) => {
    if (cantSer) {
      const listaTempSeriales = [];
      for (let index = 0; index < (cantSer <= listaSeriales.length ? cantSer : listaSeriales.length); index++) {
        const element = listaSeriales[index];
        listaTempSeriales.push(element);

      }
      setListaSeriales(listaTempSeriales);
    }
  };

  const renderListaSeriales = () => {
    if (articuloSeleccionado.serial) {
      if (serialesDisponibles?.length > 0 && serialesDisponibles !== undefined) {
        const selectSerial = document.getElementById("seleccioneSerial");
        if (selectSerial && serialesDisponibles.length > 0) {
          selectSerial.innerHTML = "";
          serialesDisponibles?.forEach((ls) => {
            let option = document.createElement("option");
            option.value = ls;
            selectSerial.appendChild(option);
          });
        }
      }
    }
  };

  useEffect(() => {
    if (articuloSeleccionado.consecutivo !== "" && ((!bodegaSelect && !ubicacionSelect) || !proyectoSelect) && articuloSeleccionado.serial) {
      renderListaSeriales()
    }
  }, [articuloSeleccionado, bodegaSelect, proyectoSelect, ubicacionSelect]);

  useEffect(() => {
    if (contRenSeriales <= 1) {
      renderListaSeriales();
    }
  }, [contRenSeriales]);

  useEffect(() => {
    setContRenSeriales(0);
  }, [ubicacionSelect, proyectoSelect, cantidad]);

  //----------Tablas---------------
  const eliminarArticulo = (lista, art, fec, tipfec, setLista) => {
    toast.error(
      <div className="text-center">
        ¿Estás seguro de eliminar el artículo "{articuloSeleccionado.des_Larga}"?

        <div className="row mt-3 mb-2">
          <div className="col">
            <button className="btn btn-outline-danger" onClick={() => {

              if (tipfec === fechaBaja && setLista === setListaBajas) {
                const resultado = lista.filter(item => !(item.articulo === articuloSeleccionado.des_Larga && item.fechaBaja === fec));
                setListaBajas(resultado)
                toast.success("El artículo fue eliminado de la tabla con exito");

              } else if (tipfec === fechaVenta && setLista === setListaVentas) {
                const resultado = lista.filter(item => !(item.articulo === articuloSeleccionado.des_Larga && item.fechaVenta === fec));
                setListaVentas(resultado)
                toast.success("El artículo fue eliminado de la tabla con exito");
              }
            }}>Aceptar</button>
          </div>

          <div className="col">
            <button className="btn btn-outline-info" onClick={toast.dismiss}>Cancelar</button>
          </div>

        </div>
      </div>,
      { autoClose: false }
    );
  };

  const crearItem = (opcion, serial, setLista, tipo) => {
    let item
    if (tipo === "bajas") {
      item = {
        idArticulo: articuloSeleccionado.id,
        consecutivo: articuloSeleccionado.consecutivo,
        articulo: articuloSeleccionado.des_Larga,
        fecha_baja: fechaBaja,
        usaserial: serial,
        observacion,
        cantidad,
        valor,
      };
    }
    if (tipo === "ventas") {
      item = {
        idArticulo: articuloSeleccionado.id,
        consecutivo: articuloSeleccionado.consecutivo,
        articulo: articuloSeleccionado.des_Larga,
        fecha_venta: fechaVenta,
        usaserial: serial,
        observacion,
        cantidad,
        factura,
        cliente,
        valor,
      };

    }
    if (opcion === "bodega") {
      item.bodega = bodegaSelect.value;
      item.ubicacion = ubicacionSelect.value;
    }
    if (opcion === "proyecto") {
      item.proyecto = proyectoSelect.value;
    }
    if (serial) {
      item.listaSeriales = valoresSeleccionados;
    }
    const itemSinObservacion = { ...item };
    delete itemSinObservacion.observacion;
    delete itemSinObservacion.valor;
    const camposVacios = Object.values(itemSinObservacion).some((valor) => valor === "" || valor === 0);


    if (camposVacios) {
      toast.warning("Todos los campos deben ser diligenciados");
    } else {
      añadirAtablas(item, setLista);
      toast.success("Articulo añadido a la tabla con exito");
    }
  };

  const pasarAtablas = (setLista, pasar) => {
    if (pasar === "bajas") {

      const existe = listaBajas?.find(
        (lb) =>
          (lb.articulo === articuloSeleccionado.des_Larga &&
            lb.bodega === bodegaSelect.value &&
            lb.ubicacion === ubicacionSelect.value) ||
          (lb.articulo === articuloSeleccionado.des_Larga && lb.proyecto === proyectoSelect.value)
      );

      if (articuloSeleccionado.serial && valoresSeleccionados.length !== cantidad) {
        toast.warning("Los seriales no coinciden con la cantidad, por favor verifique e intente de nuevo");
        return;
      }
      if (!existe) {
        crearItem(opcionSelecBoP, articuloSeleccionado.serial, setLista, "bajas");
      }
    }
    if (pasar === "ventas") {

      const existe = listaVentas?.find(
        (lb) =>
          (lb.articulo === articuloSeleccionado.des_Larga &&
            lb.bodega === bodegaSelect.value &&
            lb.ubicacion === ubicacionSelect.value) ||
          (lb.articulo === articuloSeleccionado.des_Larga && lb.proyecto === proyectoSelect.value)
      );

      if (articuloSeleccionado.serial && valoresSeleccionados.length !== cantidad) {
        toast.warning("Los seriales no coinciden con la cantidad, por favor verifique e intente de nuevo");
        return;
      }
      if (!existe) {
        crearItem(opcionSelecBoP, articuloSeleccionado.serial, setLista, "ventas");
      }
    }
  };

  const añadirAtablas = (item, setLista) => {
    setLista((listaProv) => [...listaProv, item]);
    limpiarPasarTablas();
  };

  const manipularCabecerasTabla = (lista) => {
    let cP = 0
    let cB = 0
    lista.forEach(etb => {
      if (etb.proyecto !== "" && etb.proyecto !== undefined) {
        cP = cP + 1
      }
      if (etb.bodega !== "" && etb.bodega !== undefined) {
        cB = cB + 1
      }
    })
    setContBodega(cB)
    setContProyecto(cP)
  };

  useEffect(() => {
    manipularCabecerasTabla(listaBajas)
  }, [listaBajas]);

  useEffect(() => {
    manipularCabecerasTabla(listaVentas)
  }, [listaVentas]);

  const limpiarPasarTablas = () => {
    !cliente && setClienteSelect({ value: "Seleccione Cliente", label: "Seleccione Cliente" });
    setUbicacionSelect({ value: "Seleccione Ubicacion", label: "Seleccione Ubicacion" });
    setProyectoSelect({ value: "Seleccione Proyecto", label: "Seleccione Proyecto" });
    setArticuloSelect({ value: "Seleccione Artículo", label: "Seleccione Artículo" });
    setBodegaSelect({ value: "Seleccione Bodega", label: "Seleccione Bodega" });
    setSerialesDisponibles([]);
    setArticuloSeleccionado({})
    setValoresSeleccionados([]);
    setVerSeriales(false);
    setObservacion("");
    setFechaBaja("");
    setProyecto("");
    setBodega("");
    setCantidad(0);
    setValor(0);
    limpiarRadioBtn();
  };

  //--------CRUD Baja // Venta ------------  
  const guardarBaja = async () => {
    const cnsB = verConsecutivoBajas;
    if (listaBajas.length === 0) {
      toast.warning("No hay bajas para registrar, ingrese un artículo e intente de nuevo");
      return;
    }

    if (window.confirm("Esta seguro de continuar con la baja de inventario?")) {
      const consec = valorPrefijoBajas.toUpperCase() + "-" + valorConsecutivoBajas
      let k = 0;
      let res;
      for (const li of listaBajas) {
        let esBodega = li.bodega;
        let esProyecto = li.proyecto;
        const conSerial = li.usaserial;

        if (esProyecto !== undefined) {
          setIsLoading(true)
          try {
            const existeP = listaInventarioActual.find(t => (t.consecutivo.split("\\")[0] === li.proyecto && t.consecutivo.split("\\")[1] === li.consecutivo))
            if (existeP !== undefined) {
              const listaFusion = (conSerial ? existeP?.listaSeriales.filter(serial => !li?.listaSeriales.includes(serial)) : [])
              res = await modificarArticulo(existeP, existeP?.consecutivo, existeP?.cant_reservada, listaFusion, li?.cantidad, li?.valor, "pro", conSerial)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }

        if (esBodega !== undefined) {
          setIsLoading(true)
          try {
            const existeB = listaInventarioActual.find(t => (t.consecutivo.split("\\")[0] === li.bodega &&
              t.consecutivo.split("\\")[1] === li.ubicacion && t.consecutivo.split("\\")[2] === li.consecutivo));

            if (existeB !== undefined) {
              const listaFusion = (conSerial ? existeB?.listaSeriales.filter(serial => !li?.listaSeriales.includes(serial)) : [])
              res = await modificarArticulo(existeB, existeB?.consecutivo, existeB?.cant_disponible, listaFusion, li?.cantidad, li?.valor, "bod", conSerial);
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }

        if (res) {
          setIsLoading(true)
          try {
            k++;
            if (k === listaBajas.length) {
              await cargarInventarioEnKardex(listaBajas, cnsB);
              await handleModificarConsecutivos(consec, idDocumentoConsecutivoBajas, "bajas");
              await cargarConsecutivos(consecBaja, setlistaConsecutivosBajas);
              setListaBajas([]);
              resetStates();
              toast.success("Las bajas en el inventario se han realizado con éxito");
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        } else {
          toast.warning("Hubo un error al dar baja en el inventario");
        }
      }
    }
  };

  const guardarVenta = async () => {
    const cnsV = verConsecutivoVentas;
    if (listaVentas.length === 0) {
      toast.warning("No hay compras para guardar, ingrese un artículo e intente de nuevo");
      return;
    }

    if (window.confirm("Esta seguro de Continuar con la baja de inventario por venta?")) {
      const consec = valorPrefijo.toUpperCase() + "-" + valorConsecutivo;
      let k = 0, res = null;
      for (const lc of listaVentas) {
        let esBodega = lc.bodega;
        let esProyecto = lc.proyecto;
        const conSerial = lc.usaserial;

        if (esBodega !== undefined) {
          setIsLoading(true)
          try {
            const existeB = listaInventarioActual.find((t) => (t.consecutivo.split("\\")[0] === lc.bodega &&
              t.consecutivo.split("\\")[1] === lc.ubicacion && t.consecutivo.split("\\")[2] === lc.consecutivo))

            if (existeB !== undefined) {
              const listaFusion = (conSerial ? existeB?.listaSeriales.filter(serial => !lc?.listaSeriales.includes(serial)) : [])
              res = await modificarArticulo(existeB, existeB?.consecutivo, existeB?.cant_disponible, listaFusion, lc?.cantidad, lc?.valor, "bod", conSerial);
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
        if (esProyecto !== undefined) {
          setIsLoading(true)
          try {
            const existeP = listaInventarioActual.find((t) => (t.consecutivo.split("\\")[0] === lc.proyecto && t.consecutivo.split("\\")[1] === lc.consecutivo))
            if (existeP !== undefined) {
              const listaFusion = (conSerial ? existeP?.listaSeriales.filter(serial => !lc?.listaSeriales.includes(serial)) : [])
              res = await modificarArticulo(existeP, existeP?.consecutivo, existeP?.cant_reservada, listaFusion, lc?.cantidad, lc?.valor, "pro", conSerial);
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        }
        if (res) {
          setIsLoading(true)
          try {
            k++;
            if (k === listaVentas.length) {
              await cargarInventarioEnKardex(listaVentas, cnsV);
              await handleModificarConsecutivos(consec, idDocumentoConsecutivo, "venta");
              await cargarConsecutivos(consecVenta, setlistaConsecutivosVentas);
              setListaVentas([]);
              resetStates();
              toast.success("Las ventas de inventario se han realizado con éxito");
            }
          } catch (error) {
            console.error(error)
          } finally {
            setIsLoading(false)
          }
        } else {
          toast.warning("Error al cargar la venta del artículo en el sistema");
        }
      }
    }
  };

  const modificarArticulo = async (lista, consec, cantidadBD, listaFusion, cantidad, valor, tipo, conSerie) => {
    let cant = (parseFloat(cantidadBD)) - (parseFloat(cantidad));
    cant = Number(Number.parseFloat(cant).toFixed(2));
    const doc = lista.bodega !== undefined ? lista.bodega : lista.proyecto;
    const documento = doc.split("-")[0] === "BDG" ? doc.toLowerCase() : "proyectos";
    let listaEnviar = {
      ...lista,
      valor: valor,
      usuario_modificacion: user,
      fecha_modificacion: fecha_modificacion,
    };

    if (conSerie) {
      listaEnviar.listaSeriales = listaFusion;
    }

    if (tipo === 'bod') {
      listaEnviar.cant_disponible = cant;
    } else if (tipo === 'pro') {
      listaEnviar.cant_reservada = cant;
    }

    if (consec !== "" && consec !== undefined) {
      try {
        await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, consec, listaEnviar);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  };

  const cargarInventarioEnKardex = async (lista, consecutivo) => {
    const valor = consecutivo.split("-")[0]
    let id = uniqid("kardex-")
    let cons = null
    if (lista.length !== 0) {

      for (const key in lista) {
        if (lista.hasOwnProperty(key)) {
          const objetoActual = lista[key];
          cons = objetoActual.consecutivo;
          objetoActual.articulo = cons;
          objetoActual.id = id;
          objetoActual.usuario_creacion = user;
          objetoActual.fecha_creacion = fecha_creacion
          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
        }
      }
      const enviar = { [consecutivo]: lista };
      try {

        // const res =
        await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, enviar);
        // if (res !== "") {
        //   traerInfoAVerPDF(consecutivo);
        //   return true;
        // } else {
        //   return false;
        // }
      } catch (error) {
        console.error(error)
      }
    } else {
      toast.warning("Error, no hay informacion para guardar, por favor verifica e inetenta de nuevo");
    }
  };

  const resetStates = () => {
    setArticuloSeleccionado({});
    setValoresSeleccionados([]);
    setListaSeriales([]);
    setListaVentas([]);
    setListaBajas([]);
    setVerSeriales(false);
    setObservacion("");
    setFechaVenta("");
    setFechaBaja("");
    setUbicacion("");
    setProyecto("");
    setCliente("");
    setFactura("");
    setBodega("");
    setEnProyecto(0);
    setEnBodega(0);
    setCantidad(0);
    setValor(0);
    limpiarRadioBtn();
  };

  //----------Nuevo----------
  useEffect(() => {
    limpiarRadioBtn()
    setBodegaSelect('')
    setProyectoSelect('')
    setUbicacionSelect('')
    setBodegas([])
    setUbicaciones([])
    setProyectos([])
    setFechaBaja(obtenerFechaActual())
    const temp = inventario.filter(i => i.articulo === articuloSeleccionado.consecutivo)
    buscarBodega_Proyecto(articuloSeleccionado.consecutivo, temp)
  }, [articuloSeleccionado])

  const buscarBodega_Proyecto = (cons, inv) => {
    if (cons) {
      const existeB = inv.filter((i) => i.articulo === cons && i.bodega !== undefined && i.ubicacion !== undefined && i.cant_disponible > 0);
      const existeP = inv.filter((i) => i.articulo === cons && i.proyecto !== undefined && i.cant_reservada > 0);

      setInventarioArticuloBodega(existeB)
      setInventarioArticuloProyecto(existeP)

      setEnBodega(existeB.length);
      setEnProyecto(existeP.length);

      if (existeB.length === 0 && existeP.length === 0) {
        toast.warning("No hay stock, intenta con otro artículo.")
      }
    }
  };

  useEffect(() => {

    let nuevoInventario = []
    inventarioArticuloBodega.forEach(inv => {
      const bodegaDesc = listaBodegas?.find(lb => lb.consecutivo === inv.bodega)?.descripcion
      const tempo = { ...inv, bodegaDesc }
      nuevoInventario.push(tempo)
    });

    const options = nuevoInventario.map(a => ({ value: a.bodega, label: a.bodegaDesc + ": (" + a.bodega + ")" }));
    setBodegas(options?.sort(sortBy("value")))

  }, [inventarioArticuloBodega]);

  useEffect(() => {

    let nuevoInventario = []
    inventarioArticuloProyecto.forEach(inv => {
      const proyectoDesc = listaProyectos?.find(lb => lb.consecutivo === inv.proyecto)?.descripcion
      const tempo = { ...inv, proyectoDesc }
      nuevoInventario.push(tempo)
    });

    const options = nuevoInventario.map(a => ({ value: a.proyecto, label: a.proyectoDesc + ": (" + a.proyecto + ")" }));
    setProyectos(options?.sort(sortBy("value")))

  }, [inventarioArticuloProyecto]);

  useEffect(() => {
    if (bodegaSelect.value !== "") {
      const ubic = inventarioArticuloBodega.filter((i) => i.bodega === bodegaSelect.value && i.ubicacion !== undefined);
      setListaUbicaciones(ubic);
      const options = ubic?.map(a => ({ value: a.ubicacion, label: a.ubicacion }));
      setUbicaciones(options?.sort(sortBy("value")))
    }
  }, [bodegaSelect]);

  useEffect(() => {
    const existe = inventarioArticuloBodega?.find((item) => item.articulo === articuloSeleccionado.consecutivo && item.bodega === bodegaSelect.value && item.ubicacion === ubicacionSelect.value);
    setMaxCantidad(existe?.cant_disponible);
  }, [ubicacionSelect]);

  useEffect(() => {
    const existe = inventarioArticuloProyecto?.find((item) => item.articulo === articuloSeleccionado.consecutivo && item.proyecto === proyectoSelect.value);
    setMaxCantidad(existe?.cant_reservada);
  }, [proyectoSelect]);


  //************ Inicio componente ******************/
  return (
    <>
      {isLoading
        ? <LoaderES />
        : <>
          <div className="fixed-top mt-2"
            style={{
              backgroundColor: 'white',
              marginLeft: broken || mobile ? '' : (!collapsed ? '250px' : '80px'),
              transition: 'margin-left 0.3s ease',
              top: '46px',
              zIndex: 1
            }}>
          </div>

          <hr className="my-3" />

          {/* Menu header */}
          <ul className="nav nav-tabs mt-4">

            <li className="nav-item user-select-none"
              disabled={!permisos.includes("bajaInventario")}
              onClick={() => {
                setOpcionSeleccionada("darBaja")
                limpiarPasarTablas();
                resetStates();
                cargarSelect();
              }}>
              <p className={"nav-link " + (opcionSeleccionada === "darBaja" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }} >Dar de baja un artículo</p>
            </li>

            <li className="nav-item user-select-none"
              disabled={!permisos.includes("bajaVenta")}
              onClick={() => {
                setOpcionSeleccionada("vender");
                limpiarPasarTablas();
                resetStates();
                cargarSelect();
                cargarClientes();
              }}>
              <p className={"nav-link " + (opcionSeleccionada !== "darBaja" ? "active" : "")}
                style={{ fontSize: "0.8em", margin: "8px 0 -1px 0" }} > Venta de Artículos</p>
            </li>
          </ul>

          <hr className="text-light mt-2 mb-0" />

          {/******* Dar de baja un artículo *******/}
          {permisos.includes("bajaInventario") ?
            opcionSeleccionada === "darBaja" &&
            (<>
              <div className="conatiner">
                <div className="row text-center">
                  <h4 className="fs-4">Dar de baja un artículo</h4>
                </div>

                {/* Consecutivo  y fecha bajas */}
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <div className="input-group input-group-sm mb-2 ">
                      <span className="input-group-text">Consecutivo</span>
                      <input
                        type="text"
                        className={listaConsecutivosBajas.length === 0 ? "form-control text-danger" : "form-control"}
                        readOnly
                        value={listaConsecutivosBajas.length === 0 ? "Sin consecutivos disponibles" : verConsecutivoBajas} />
                    </div>
                  </div>

                  {/* Fecha bajas*/}
                  <div className="col">
                    <div className="input-group input-group-sm mb-2 ">
                      <span className="input-group-text">Fecha</span>
                      <input
                        type="date"
                        className="form-control"
                        value={fechaBaja || obtenerFechaActual()}
                        onChange={(e) => setFechaBaja(e.target.value)} />
                    </div>
                  </div>
                </div>

                {listaConsecutivosBajas.length > 0 &&
                  <>
                    {/* Text input articulo baja */}
                    <div className="row">
                      {/* Articulo bajas*/}
                      <Select
                        value={articuloSelect}
                        placeholder={"Articulo"}
                        onFocus={() => {
                          setGuardo(false);
                          setListaVerPDF([]);
                        }}
                        className="mb-2"
                        onChange={(option) => {
                          const art = listaArticulos.find((art) => art.consecutivo === option.value);
                          setValor(art.ult_Costo)
                          setArticuloSelect({ value: art.consecutivo, label: art.des_Larga });
                          setArticuloSeleccionado(art)
                        }}
                        options={articulosL}
                        styles={styles}
                        isDisabled={sinArticulos} />
                    </div>
                  </>}
                {/* RadioBtn bodegas y proyectos bajas */}
                {articuloSeleccionado.consecutivo ?
                  <>
                    <div className="row mb-3" >
                      <div className="col-sm" >
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            name="opcionSelecBoP"
                            type="radio"
                            id="inlineBodegasB"
                            value="bodega"
                            disabled={enBodega === 0}
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                          />
                          <label className="form-check-label"
                            htmlFor="inlineBodegasB">Bodegas</label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input me-2"
                            name="opcionSelecBoP"
                            type="radio"
                            id="inlineProyectosB"
                            value="proyecto"
                            disabled={enProyecto === 0}
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                          />
                          <label className="form-check-label"
                            htmlFor="inlineProyectosB">Proyectos</label>
                        </div>
                      </div>
                    </div>
                  </>
                  : null}

                {/* Select Bodega - Proyecto - Ubicacion bajas */}
                {opcionSelecBoP !== "" ?
                  <>
                    <div className="row">
                      {/* select bodegas bajas*/}
                      <div className="col-sm" >
                        {opcionSelecBoP === "" || opcionSelecBoP === "proyecto" ? null :
                          <Select
                            value={listaBodegas.length > 0 ? bodegaSelect : {
                              value: "Sin Bodegas disponibles",
                              label: "Sin Bodegas disponibles",
                            }}
                            placeholder={"Bodega"}
                            className="mb-2"
                            onChange={(e) => {
                              setBodega(e.value)
                              setBodegaSelect({ value: e.value, label: e.value });
                            }}
                            options={bodegas}
                            styles={styles}
                          />}

                        {/* select proyectos bajas*/}
                        {opcionSelecBoP === "" || opcionSelecBoP === "bodega" ? null
                          :
                          <Select
                            className="mb-2"
                            value={listaProyectos.length > 0 ? proyectoSelect : {
                              value: "Sin Proyectos disponibles",
                              label: "Sin Proyectos disponibles",
                            }
                            }
                            placeholder={"Proyecto"}
                            onChange={(e) => {
                              setProyecto(e.value)
                              setProyectoSelect({ value: e.value, label: e.value });
                              // obtenerListaSeriales(inventarioArticuloProyecto);
                            }}
                            options={proyectos}
                            styles={styles}
                          />}
                      </div>

                      {/* select Ubicaciones bajas*/}
                      {opcionSelecBoP === "" || opcionSelecBoP === "proyecto" || opcionSelecBoP === "bodega" && !bodega ? null
                        :
                        <div className="col-sm">
                          <Select
                            value={ubicacionSelect}
                            placeholder={"Ubicación"}
                            className="mb-2"
                            onChange={(e) => {
                              setUbicacion(e.value)
                              setUbicacionSelect({ value: e.value, label: e.value });
                              // obtenerListaSeriales(inventarioArticuloBodega)
                            }}
                            options={ubicaciones}
                            styles={styles} />
                        </div>
                      }
                    </div>

                    {/* <!-- Text inputs cantidad y serie baja--> */}
                    {(opcionSelecBoP === "bodega" && (!bodega || !ubicacion)) || (opcionSelecBoP === "proyecto" && !proyecto) ? null
                      :
                      <>
                        <div className="row">
                          {/* Cantidad bajas*/}
                          <div className="input-group input-group-sm mb-2 ">
                            <span className="input-group-text">Cantidad ({maxCantidad})</span>
                            <input
                              type="number"
                              className="form-control"
                              min={articuloSeleccionado.um === "UND" ? 1 : 0.1}
                              step={articuloSeleccionado.um === "UND" ? 1 : 0.1}
                              disabled={(!bodegaSelect && !ubicacionSelect) && (!proyectoSelect)}
                              placeholder={articuloSeleccionado.um !== "UND" && "Usa coma para decimales"}
                              onFocus={() => setCantidad("")}
                              value={cantidad}
                              onChange={(e) => {
                                if (e.target.value <= maxCantidad) {
                                  setCantidad(parseFloat(e.target.value));
                                  manejarCantidad(cantidad);
                                } else {
                                  setCantidad("");
                                  toast.warning("Cantidad no permitida, intenta de nuevo");
                                }
                              }}
                            />

                            {articuloSeleccionado.serial ?
                              <button
                                className="btn btn-outline-primary"
                                disabled={cantidad === 0 || cantidad === ""}
                                data-bs-toggle="modal"
                                data-bs-target="#modalSeriales"
                                title="Seleccionar seriales de los articulos"
                                onClick={() => setVerSeriales(false)}>
                                Cargar Series
                              </button>
                              : null}
                          </div>
                        </div>
                        <div className="row" >
                          {/* valor bajas */}
                          <div className="col-sm-4">
                            <div className="input-group input-group-sm mb-2 ">
                              <span className="input-group-text">Valor</span>
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                value={valor ? parseFloat(valor) : 0}
                                readOnly
                                disabled />
                              <span className="input-group-text">$</span>
                            </div>
                          </div>

                          {/* Observacion bajas*/}
                          <div className="col-sm-8">
                            <textarea
                              placeholder="Observaciones"
                              rows={1}
                              className="form-control form-control-sm mb-3"
                              value={observacion}
                              onChange={(e) => setObservacion(e.target.value)} />
                          </div>
                        </div>
                        {/* Boton añadir a tabla bajas */}
                        <button
                          className="btn btn-dark  mb-4"
                          onClick={() => pasarAtablas(setListaBajas, "bajas")}>
                          Añadir Artículo</button>
                      </>}
                  </>
                  : null}
              </div>

              {listaBajas.length !== 0 && <hr />}

              {listaBajas.length !== 0 ?
                <>
                  {/* +Tabla de artículos a dar baja */}
                  <div className="table-responsive">
                    <table className="table table-hover table-striped table-sm">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Articulo</th>
                          <th scope="col">Cantidad</th>
                          {contBodega === 0 ? null : <>
                            <th scope="col">Bodega </th>
                            <th scope="col">Ubicacion</th>
                          </>}
                          {contProyecto === 0 ? null :
                            <th scope="col">Proyecto</th>
                          }
                          <th scope="col">Valor</th>
                          <th scope="col">Observacion</th>
                          <th scope="col">Opciones</th>
                        </tr>
                      </thead>
                      <tbody className="text-center" id="tabla_articulos">
                        {listaBajas?.map((art, index) => (
                          <tr key={index}>
                            <td>{art.fecha_baja}</td>
                            <td>{art.articulo}</td>
                            <td>{art.cantidad}</td>
                            {contBodega === 0 ? null : <>
                              <td>{art.bodega}</td>
                              <td>{art.ubicacion}</td>
                            </>}
                            {contProyecto === 0 ? null :
                              <td>{art.proyecto}</td>
                            }
                            <td>${Intl.NumberFormat("es-CO").format(art.valor)}</td>
                            <td>{art.observacion}</td>
                            <td>
                              {!art.usaserial ? null :
                                <button
                                  className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalSeriales"
                                  onClick={() => {
                                    setVerSeriales(true);
                                    setVerListaSeriales(listaSerialesParaModal(listaBajas, art.articulo, art.fechaBaja));
                                  }} />
                              }

                              <button
                                className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                onClick={() => {
                                  eliminarArticulo(listaBajas, art.articulo, art.fechaBaja, fechaBaja, setListaBajas)
                                }} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Boton Dar de baja */}
                  <div className="row mt-4">
                    <div className="container">
                      <button
                        className="btn btn-success"
                        title="Dar de baja"
                        onClick={() => {
                          guardarBaja()
                          setConsecMov(verConsecutivoBajas)
                          setGuardo(true)
                        }}>
                        Dar de Baja
                      </button>
                    </div>
                  </div>
                </>
                : null}

              {/* {guardo && listaVerPDF ?
                <div className="text-center">
                  <NavLink
                    to={{
                      pathname: `/docpdf`,
                      state: { c: consecMov, d: listaVerPDF, a: "Baja", e: dataEmpresa, l: listaArticulos }
                    }}>
                    <button className="btn btn-outline-primary  mt-3 fa-solid fa-print "
                      title="Ver Reporte"
                      onClick={() => {
                        setGuardo(false);
                        setListaVerPDF([])
                      }} />
                  </NavLink>
                </div>
                : null} */}
            </>)
            : null
          }

          {/* //******* Vender Artículo *******/}
          {permisos.includes("bajaVenta") ?
            opcionSeleccionada === "vender" &&
            (<>
              <div className="conatiner">

                <div className="row text-center">
                  <h4 className="fs-4">Cargar Venta</h4>
                </div>

                {/* Consecutivo y fecha carga venta*/}
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <div className="input-group input-group-sm mb-2 ">
                      <span className="input-group-text">Consecutivo</span>
                      <input
                        type="text"
                        className={listaConsecutivosVentas.length === 0 ? "form-control text-danger" : "form-control"}
                        readOnly
                        value={listaConsecutivosVentas.length === 0 ? "Sin consecutivos disponibles" : verConsecutivoVentas} />
                    </div>
                  </div>

                  <div className="col-sm-6 text-end">
                    <div className="input-group input-group-sm mb-2 ">
                      <span className="input-group-text">Fecha Carga</span>
                      <input
                        type="date"
                        className="form-control"
                        readOnly
                        value={fechaBaja || obtenerFechaActual()}
                        onChange={(e) => setFechaBaja(e.target.value)} />
                    </div>
                  </div>
                </div>

                {listaConsecutivosVentas.length > 0 &&
                  <>
                    {/* Text inputs N° factura y  fecha  ventas */}
                    <div className="row">
                      {/* N° factura venta */}
                      <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Factura</span>
                          <input
                            type="text"
                            disabled={sinClientes || sinArticulos || listaVentas.length > 0}
                            className="form-control"
                            onFocus={() => {
                              setGuardo(false);
                              setListaVerPDF([]);
                            }}
                            value={factura}
                            onChange={(e) => setFactura(e.target.value)} />
                        </div>
                      </div>

                      {/* Fecha venta */}
                      <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-2 ">
                          <span className="input-group-text">Fecha Venta</span>
                          <input
                            type="date"
                            className="form-control"
                            disabled={sinClientes || sinArticulos || !factura || listaVentas.length > 0}
                            value={fechaVenta}
                            onChange={(e) => setFechaVenta(e.target.value)} />
                        </div>
                      </div>
                    </div>

                    {/* <!-- Text inputs cliente y  artículo venta--> */}
                    <div className="row">

                      {/* Cliente */}
                      <div className="col-sm-6">
                        <Select
                          value={sinClientes ? { value: "No existen clientes", label: "No existen clientes" } : clienteSelect}
                          placeholder={"Cliente"}
                          className="mb-2"
                          onChange={(e) => {
                            const cl = listaClientes.find(c => c.razonSocial === e.value);
                            setCliente(cl ? cl.razonSocial : "")
                            setClienteSelect({ value: cl.razonSocial, label: cl.razonSocial });
                          }}
                          options={clientes}
                          styles={styles}
                          isDisabled={!fechaVenta || sinClientes || listaVentas.length > 0}
                        />
                      </div>

                      {/* Articulo ventas */}
                      <div className="col-sm-6">
                        <Select
                          value={articuloSelect}
                          placeholder={"Articulo"}
                          onFocus={() => {
                            setGuardo(false);
                            setListaVerPDF([]);
                          }}
                          className="mb-2"
                          onChange={(option) => {
                            const art = listaArticulos.find((art) => art.consecutivo === option.value);
                            setValor(art.ult_Costo)
                            setArticuloSelect({ value: art.consecutivo, label: art.des_Larga });
                            setArticuloSeleccionado(art)
                          }}
                          options={articulosL}
                          styles={styles}
                          isDisabled={!fechaVenta || sinArticulos} />
                      </div>
                    </div>
                  </>}

                {articuloSeleccionado.consecutivo ?
                  <>
                    {/* Radio btn bodegas y proeyectos  venta*/}
                    <div className="row mb-3">
                      <div className="col-sm">
                        <div className="form-check form-check-inline">
                          <input
                            disabled={enBodega === 0}
                            className="form-check-input"
                            name="opcionSelecBoP"
                            id="inlineBodegasV"
                            type="radio"
                            value="bodega"
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                          />
                          <label className="form-check-label"
                            htmlFor="inlineBodegasV">
                            Bodegas
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            disabled={enProyecto === 0}
                            className="form-check-input"
                            name="opcionSelecBoP"
                            type="radio"
                            id="inlineProyectosV"
                            value="proyecto"
                            onChange={(e) => setOpcionSelecBoP(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor="inlineProyectosV">
                            Proyectos
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                  : null}

                {opcionSelecBoP === "" ? null
                  :
                  <>
                    <div className="row">
                      {/* input bodegas Venta */}
                      <div className="col-sm">
                        {opcionSelecBoP === "" || opcionSelecBoP === "proyecto" ? null
                          :
                          <Select
                            value={listaBodegas.length > 0 ? bodegaSelect : {
                              value: "Sin Bodegas disponibles",
                              label: "Sin Bodegas disponibles",
                            }}
                            placeholder={"Bodega"}
                            className="mb-2"
                            onChange={(e) => {
                              setBodega(e.value)
                              setBodegaSelect({ value: e.value, label: e.value });
                            }}
                            options={bodegas}
                            styles={styles}
                          />
                        }

                        {/* input proyectos ventas */}
                        {opcionSelecBoP === "" || opcionSelecBoP === "bodega" ? null
                          :

                          <Select
                            className="mb-2"
                            value={listaProyectos.length > 0 ? proyectoSelect : {
                              value: "Sin Proyectos disponibles",
                              label: "Sin Proyectos disponibles",
                            }
                            }
                            placeholder={"Proyecto"}
                            onChange={(e) => {
                              setProyecto(e.value)
                              setProyectoSelect({ value: e.value, label: e.value });
                              // obtenerListaSeriales(inventarioArticuloProyecto);
                            }}
                            options={proyectos}
                            styles={styles}
                          />}
                      </div>

                      {/* input Ubicaciones */}
                      {opcionSelecBoP == "" || opcionSelecBoP === "proyecto" ? null
                        :
                        <div className="col-sm">

                          <Select
                            value={ubicacionSelect}
                            placeholder={"Ubicación"}
                            className="mb-2"
                            onChange={(e) => {
                              setUbicacion(e.value)
                              setUbicacionSelect({ value: e.value, label: e.value });
                              // obtenerListaSeriales(inventarioArticuloBodega)
                            }}
                            options={ubicaciones}
                            styles={styles} />
                        </div>}
                    </div>

                    {/* <!-- Text inputs cantidad y serie ventas --> */}
                    <div className="row">
                      {/* Cantidad */}
                      <div className="input-group input-group-sm mb-2 ">
                        <span className="input-group-text">Cantidad ({maxCantidad})</span>

                        <input
                          type="number"
                          className="form-control"
                          min={articuloSeleccionado.um === "UND" ? 1 : 0.1}
                          step={articuloSeleccionado.um === "UND" ? 1 : 0.1}
                          disabled={(!bodegaSelect && !ubicacionSelect) && (!proyectoSelect)}
                          placeholder={articuloSeleccionado.um !== "UND" && "Usa coma para decimales"}
                          onFocus={() => setCantidad("")}
                          value={cantidad}
                          onChange={(e) => {
                            if (e.target.value <= maxCantidad) {
                              setCantidad(parseFloat(e.target.value));
                              manejarCantidad(cantidad);
                            } else {
                              setCantidad("");
                              toast.warning("Cantidad no permitida, intenta de nuevo");
                            }
                          }}
                        />

                        {articuloSeleccionado.serial ?
                          <button
                            className="btn btn-outline-primary"
                            disabled={cantidad === 0 || cantidad === ""}
                            data-bs-toggle="modal"
                            data-bs-target="#modalSeriales"
                            title="Seleccionar seriales de los articulos"
                            onClick={() => setVerSeriales(false)}>
                            Cargar Series
                          </button>
                          : null}
                      </div>
                    </div>


                    {/* <!-- Text inputs  valor y observacion ventas --> */}
                    {cantidad !== "" && cantidad !== 0 ?
                      <>
                        <div className="row" >
                          {/* valor */}
                          <div className="col-sm-4">
                            <div className="input-group input-group-sm mb-2 ">
                              <span className="input-group-text">Valor</span>
                              <input
                                type="number"
                                className="form-control"
                                value={valor ? parseFloat(valor) : 0}
                                readOnly />
                              <span className="input-group-text">$</span>
                            </div>
                          </div>

                          {/* Observacion */}
                          <div className="col-sm-8">
                            <textarea
                              placeholder="Observaciones"
                              rows={1}
                              className="form-control form-control-sm mb-3"
                              value={observacion}
                              onChange={(e) => setObservacion(e.target.value)} />
                          </div>
                        </div>

                        {/* boton añadir a tabla ventas */}
                        <button
                          className="btn btn-dark  mb-4"
                          onClick={() => pasarAtablas(setListaVentas, "ventas")}>
                          Añadir Venta
                        </button>
                      </>
                      : null}
                  </>}
              </div>

              {listaVentas.length !== 0 && < hr />}
              {listaVentas.length === 0 ? null
                :
                <>
                  {/* +Tabla de artículos ventas */}
                  <div className="table-responsive">
                    <table className="table table-hover table-striped table-sm">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Factura</th>
                          <th scope="col">Articulo</th>
                          <th scope="col">Cliente</th>
                          <th scope="col">Cantidad</th>
                          {contBodega === 0 ? null : <>
                            <th scope="col">Bodega </th>
                            <th scope="col">Ubicacion</th>
                          </>}
                          {contProyecto === 0 ? null :
                            <th scope="col">Proyecto</th>
                          }
                          <th scope="col">Valor <br />Und.</th>
                          <th scope="col">Valor <br />Total</th>
                          <th scope="col">Observacion</th>
                          <th scope="col">Opciones</th>
                        </tr>
                      </thead>
                      <tbody className="text-center" id="tabla_articulos">
                        {listaVentas?.map((art, index) => (
                          <tr key={index}>
                            <td>{art.fecha_venta}</td>
                            <td>{art.factura}</td>
                            <td>{art.articulo}</td>
                            <td>{art.cliente}</td>
                            <td>{art.cantidad}</td>
                            {contBodega === 0 ? null : <>
                              <td>{art.bodega}</td>
                              <td>{art.ubicacion}</td>
                            </>}
                            {contProyecto === 0 ? null :
                              <td>{art.proyecto}</td>
                            }
                            <td>${Intl.NumberFormat("es-CO").format(art.valor)}</td>
                            <td>${Intl.NumberFormat("es-CO").format(art.valor * art.cantidad)}</td>
                            <td>{art.observacion}</td>
                            <td>
                              {!art.usaserial ? null :
                                <button
                                  className="btn btn-outline-primary btn-sm fa-solid fa-rectangle-list"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalSeriales"
                                  onClick={() => {
                                    setVerSeriales(true);
                                    setVerListaSeriales(listaSerialesParaModal(listaVentas, art.articulo, art.fechaVenta));
                                  }}
                                />}
                              <button
                                className="btn btn-outline-danger btn-sm fa-solid fa-trash-can"
                                onClick={() =>
                                  eliminarArticulo(listaVentas, art.articulo, art.fechaVenta, fechaVenta, setListaVentas)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Boton Cargar Ventas*/}
                  <div className="row mt-4">
                    <div className="container">
                      <button
                        className="btn btn-success"
                        title="Cargar Ventas"
                        onClick={() => {
                          guardarVenta()
                          setConsecMov(verConsecutivoVentas)
                          setGuardo(true)
                        }}>
                        Cargar Venta
                      </button>
                    </div>
                  </div>
                </>}

              {/* {guardo && listaVerPDF ?
                <div className="text-center">
                  <NavLink
                    to={{
                      pathname: `/docpdf`,
                      state: { c: consecMov, d: listaVerPDF, a: "Venta", e: dataEmpresa, l: listaArticulos }
                    }}>
                    <button
                      className="btn btn-outline-primary  mt-3 fa-solid fa-print "
                      title="Ver Reporte"
                      onClick={() => {

                        setGuardo(false);
                        setListaVerPDF([])
                      }} />
                  </NavLink>

                </div>
                : null} */}
            </>)
            : null}
        </>}

      {/* ------ Modal Seriales --------- */}
      < div
        className="modal fade"
        id="modalSeriales"
        tabIndex="-1"
        aria-labelledby="modalSerialesLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header bg-primary text-light">
              <h1 className="modal-title fs-5" id="modalSerialesLabel">
                {verSeriales ? "Seriales Añadidos" : "Agregar Seriales"}
              </h1>
            </div>
            {/* ------ Tabla seriales ------ */}
            <div className="modal-body">
              {!verSeriales ?
                (<>
                  <table className="table table-hover  table-sm">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">Articulo</th>
                        <th scope="col">Serial</th>
                      </tr>
                    </thead>
                    <tbody className="text-center" id="tabla">
                      {articuloSeleccionado.serial &&
                        Array.from(Array(cantidad || 0), (_, index) => (
                          <tr key={index}>
                            <td>{articuloSeleccionado.des_Larga}</td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                list="seleccioneSerial"
                                disabled={verSeriales}
                                onClick={() => setContRenSeriales(prueba => contRenSeriales + 1)}
                                onBlur={() =>
                                  handleBlurDatalist("seleccioneSerial", listaSeriales, setListaSeriales)}
                                onChange={(e) => handleSerialChange(index, e.target.value)} />
                              <datalist id="seleccioneSerial" />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>)
                :
                verSeriales ?
                  (<>
                    <table className="table table-hover  table-sm">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Lista seriales </th>
                        </tr>
                      </thead>
                      <tbody className="text-center" id="tabla">
                        {verListaSeriales?.map((serial) => (
                          <tr key={serial}>
                            <td>
                              <input
                                type="text"
                                readOnly
                                className="form-control form-control-sm"
                                value={serial} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>)
                  : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal" >
                {verSeriales ? "Continuar" : "Cancelar"}
              </button>
              {verSeriales ? null
                :
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => validarSeriales()}>
                  Guardar
                </button>}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
