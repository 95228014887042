import React from 'react';

export const DatoTablaMobile = ({ titulo, dato, columna, clase }) => {
    return (
        columna && (
            <tr>
                <td></td>
                <th style={{ resize: "none", textAlign: "start" }}>{titulo} </th>
                <td className={clase ? clase : ''}>{dato}</td>
            </tr>
        )
    )
};
