
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from 'uniqid';
import {
  actualizarDocumentoDatabase,
  actualizarDocumentoDatabaseIdCampo,
  consultarDatabase,
  consultarDocumentoWhere,
  guardarDatabaseId
} from "../config/firebase";
import { changeTitle, fixConsecutivo, handleColum, isMobile, styles } from "../config/functions/funGenerales";
import { buscarData } from "../config/functions/funProcesos";
import {CheckboxCol} from "./CheckboxCol";
import { Filtrar2 } from "./Filtrar2";
import { LoaderES } from "./LoaderES";
import { Pagination } from "./Pagination";
import { DatoTablaMobile, TdTablaD, ThTablaH } from "./miniComponentes";

export const AjusteInventario = ({ articulos, inventario, permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed"));

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");

  const [acumulados, setAcumulados] = useState({ superTotal: 0, cantidadArticulo: 0 });
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [valoresSeleccionados, setValoresSeleccionados] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaInventarioOrig, setListaInventarioOrig] = useState([]);
  const [listaPasarKardex, setListaPasarKardex] = useState([]);
  const [contRenSeriales, setContRenSeriales] = useState([]);
  const [listaInventario, setListaInventario] = useState([]);
  const [nuevosSeriales, setNuevosSeriales] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [lineaModificar, setLineaModificar] = useState([]);
  const [serialesBorra, setSerialesBorrar] = useState([]);
  const [listaSeriales, setListaSeriales] = useState([]);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [listaConse, setListaConse] = useState([]);

  const [contProyecto, setContProyecto] = useState(0);
  const [contBodega, setContBodega] = useState(0);
  const [ubicacion, setUbicacion] = useState("");
  const [proyecto, setProyecto] = useState("");
  const [articulo, setArticulo] = useState("");
  const [bodega, setBodega] = useState("");

  const [col2, setCol2] = useState(true);
  const [col3, setCol3] = useState(true);
  const [col4, setCol4] = useState(true);
  const [col5, setCol5] = useState(true);
  const [col6, setCol6] = useState(true);
  const [col7, setCol7] = useState(true);
  const [col8, setCol8] = useState(true);
  const [col9, setCol9] = useState(true);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [idDocumentoConse, setIdDocumentoConse] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [cantidadModif, setCantidadModif] = useState(0);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [idInv, setIdInv] = useState("");

  const [validarArrtySeries, setValidarArtySeries] = useState(false);
  const [valorAnterior, setValorAnterior] = useState(0);
  const [countOrdenar, setCountOrdenar] = useState(0);
  const [serialOk, setSerialOk] = useState(false);
  const [valorPref, setValorPref] = useState("");
  const [usaSerial, setUsaSerial] = useState(0);
  const [iguales, setIguales] = useState(false);
  const [valorCons, setValorCons] = useState(0);
  const [ajustar, setAjustar] = useState(false);
  const [verConse, setVerConse] = useState("");
  const [sum, setSum] = useState(false);

  const [proyectos, setProyectos] = useState(listaProyectos);
  const [bodegas, setBodegas] = useState(listaBodegas);
  const [isLoading, setIsLoading] = useState(false);
  const [ubicaciones, setUbicaciones] = useState();
  const [cantSeries, setCantSeries] = useState(0);
  const [mobile, setMobile] = useState(true);
  const [um, setUm] = useState("");


  /************ Funciones ************/
  useEffect(() => {
    changeTitle("Admin. Ajuste_Inventario");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum([setCol2, setCol3, setCol4, setCol5, setCol6, setCol7, setCol8, setCol9], user, "AjusteInv");
    isMobile(setMobile);
    setListaArticulos(articulos);
    setListaInventarioOrig(inventario);
    setListaInventario(inventario.sort(sortBy("articulo", "bodega")));
    cargarTabla(`${emp}_bodegas`, setListaBodegas);
    cargarTabla(`${emp}_proyectos`, setListaProyectos);
    cargarConsecutivos();
  };

  useEffect(() => {
    setListaArticulos(articulos)
  }, [articulos])

  useEffect(() => {
    setListaInventarioOrig(inventario);
    setListaInventario(inventario.sort(sortBy("articulo", "bodega")));
  }, [inventario])

  const cargarTabla = async (tabla, setLista) => {
    try {
      const listaTemporal = await consultarDatabase(tabla);
      setLista(listaTemporal);
    } catch (error) {
      console.error(error)
    }
  };

  const ajustarListas = (listaArticulos, listaAux, setListaAux) => {
    if (listaArticulos.length > 0 && listaAux.length > 0) {
      const temporalListaAjustes = [...listaAux]
      const arrayTemporal = []
      temporalListaAjustes.forEach(e => {
        const temporalArticulo = listaArticulos?.find(at => at.consecutivo === e.articulo)
        if (e.cant_disponible) { e.cant_disponible = Number(Number.parseFloat(e.cant_disponible).toFixed(2)) }
        if (e.cant_reservada) { e.cant_reservada = Number(Number.parseFloat(e.cant_reservada).toFixed(2)) }
        const datocompleto = {
          ...temporalArticulo,
          ...e,
          consecutivoFix: fixConsecutivo(temporalArticulo.consecutivo),
        }
        arrayTemporal.push(datocompleto)
      });
      const sortedArray = arrayTemporal.sort(sortBy('consecutivoFix'));
      if (JSON.stringify(listaAux) !== JSON.stringify(sortedArray)) {
        setListaAux(sortedArray)
        setDocumentosFiltrados(sortedArray)
      }
    }
  };

  useEffect(() => {
    ajustarListas(listaArticulos, listaInventario, setListaInventario);
  }, [listaArticulos, listaInventario]);

  //-----Options Bdg// Ubc // Proy-------------
  useEffect(() => {
    const options = listaBodegas.map(bd => ({ value: bd.consecutivo, label: bd.descripcion + ": (" + bd.consecutivo + ")" }));
    setBodegas(options?.sort(sortBy("value")))
  }, [listaBodegas]);

  useEffect(() => {
    if (bodega.value && bodega.value !== undefined) {
      const temporal = listaBodegas.find(b => b.consecutivo === bodega.value)?.listaUbicaciones
      const options = temporal.map(t => ({ value: t.ubicacion, label: t.ubicacion }));
      setUbicaciones(options?.sort(sortBy("value")))
    } else {
      setUbicaciones()
      setUbicacion("")
    }
  }, [bodega]);

  useEffect(() => {
    const options = listaProyectos.map(py => ({ value: py.consecutivo, label: py.descripcion + ": (" + py.consecutivo + ")" }));
    setProyectos(options?.sort(sortBy("value")))
  }, [listaProyectos]);


  //-----------Filtrar------------
  const filtrarLista = () => {
    let listaFiltradaTemporal = [];
    if (bodega.value !== undefined && ubicacion.value === undefined) {
      listaFiltradaTemporal = listaInventario.filter(item => item.bodega === bodega.value);
      setDocumentosFiltrados(listaFiltradaTemporal);
    }
    if (bodega.value !== undefined && ubicacion.value !== undefined) {
      listaFiltradaTemporal = listaInventario.filter(item => item.bodega === bodega.value && item.ubicacion === ubicacion.value)
      setDocumentosFiltrados(listaFiltradaTemporal);
    }
    if (proyecto.value !== undefined) {
      listaFiltradaTemporal = listaInventario.filter(item => item.proyecto === proyecto.value)
      setDocumentosFiltrados(listaFiltradaTemporal);
    }
    if (listaFiltradaTemporal.length === 0) {
      toast.warning(`No se encontro registro de inventario en la ubicacion seleccionada, por favor intente de nuevo`)
    }
    setDocumentosFiltrados(listaFiltradaTemporal);
  };

  //-----------Varios------------
  const handleBlurDatalist = (datalistId, value, setValue) => {
    const datalist = document.getElementById(datalistId);
    const option = Array.from(datalist.options).find(
      (opt) => opt.value === value
    );
    if (!option) {
      setValue("");
    }
  };

  const manipularCabecerasTabla = async (lista) => {
    let cP = 0
    let cB = 0

    lista.forEach(etb => {
      if (etb.proyecto !== "" && etb.proyecto !== undefined) {
        cP = cP + 1
      }
      if (etb.bodega !== "" && etb.bodega !== undefined) {
        cB = cB + 1
      }
    })
    setContBodega(cB)
    setContProyecto(cP)
  };

  useEffect(() => {
    manipularCabecerasTabla(registrosPaginaActual)
  }, [registrosPaginaActual]);


  const calcularAcumulado = (listaMostrada) => {
    const resultado = listaMostrada.reduce(
      (acumulados, li) => {
        const valorCelda = li.valor * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada);
        return {
          superTotal: acumulados.superTotal + valorCelda,
          cantidadArticulo: acumulados.cantidadArticulo + parseInt(li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada),
        };
      },
      { superTotal: 0, cantidadArticulo: 0 }
    );

    return resultado;
  };

  useEffect(() => {
    const { superTotal, cantidadArticulo } = calcularAcumulado(registrosPaginaActual);
    setAcumulados({ superTotal, cantidadArticulo });
  }, [registrosPaginaActual, paginaActual]);

  //--------Columnas----------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    if (col5 && excluido !== 'col5') contador++;
    if (col6 && excluido !== 'col6') contador++;
    if (col7 && excluido !== 'col7') contador++;
    if (col8 && excluido !== 'col8') contador++;
    if (col9 && excluido !== 'col9') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //------Ajuste Inv--------
  const modificarInventario = async () => {
    let arrayLimpio = [], elementosNoIncluidos = [], listaFusion = [], fusion, objModificado;
    let data = listaInventarioOrig.find(obj => obj.consecutivo === idInv);
    const valor = idInv.split("\\")[0];
    if (data.id !== undefined) {
      const objetoModificar = data;
      let serialesBD = (usaSerial ? objetoModificar.listaSeriales : [])

      if (sum) {
        arrayLimpio = nuevosSeriales.filter(Boolean);
        fusion = [...serialesBD, ...arrayLimpio]
      } else {
        listaFusion = serialesBD.filter(usa => valoresSeleccionados.includes(usa));
        elementosNoIncluidos = serialesBD.filter(usase => !listaFusion.includes(usase));
        fusion = listaFusion
      }
      if (usaSerial) {
        if (objetoModificar.bodega !== undefined) {
          objetoModificar.cant_disponible = Number(Number.parseFloat(cantidadModif).toFixed(2));
          objetoModificar.observacion = observaciones
          objetoModificar.fecha_modificacion = fechaFormateada
          objetoModificar.usuario_modificacion = user
          objetoModificar.listaSeriales = fusion
        } else {
          objetoModificar.cant_reservada = Number(Number.parseFloat(cantidadModif).toFixed(2));
          objetoModificar.observacion = observaciones
          objetoModificar.fecha_modificacion = fechaFormateada
          objetoModificar.usuario_modificacion = user
          objetoModificar.listaSeriales = fusion
        }
      } else {
        if (objetoModificar.bodega !== undefined) {
          objetoModificar.cant_disponible = Number(Number.parseFloat(cantidadModif).toFixed(2));
          objetoModificar.observacion = observaciones
          objetoModificar.fecha_modificacion = fechaFormateada
          objetoModificar.usuario_modificacion = user
        } else {
          objetoModificar.cant_reservada = Number(Number.parseFloat(cantidadModif).toFixed(2));
          objetoModificar.observacion = observaciones
          objetoModificar.fecha_modificacion = fechaFormateada
          objetoModificar.usuario_modificacion = user
        }
      }

      objModificado = objetoModificar
      setListaPasarKardex(objetoModificar)
    }
    const documento = valor.startsWith("PRY") ? "proyectos" : valor.toLowerCase();
    try {
      setIsLoading(true)

      await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, idInv, objModificado);
      await cargarEnKardex(objModificado, elementosNoIncluidos, arrayLimpio)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  };

  const cargarEnKardex = async (lista, del, add) => {
    const con = valorPref.toUpperCase() + "-" + valorCons
    let id = uniqid("kardex-")
    if (lista.length !== 0) {
      const dato = lista.consecutivo;
      delete lista.consecutivo
      lista.id = id
      lista.cant_anterior = Number(Number.parseFloat(valorAnterior).toFixed(2))
      lista.seriales_excluidos = del
      lista.seriales_añadidos = add
      lista.consecutivo = con
      lista.rutaInv = dato
      const enviar = {
        [con]: lista
      };
      try {
        await guardarDatabaseId(`${emp}_ajuste_inv`, `${emp}_ajuste_inv`, enviar);
        let res = await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${valorPref.toLowerCase()}`, enviar);
        if (res !== "") {
          handleModificarConsecutivos(con, idDocumentoConse)
          toast.success("Ajuste inventario  ejecutado con exito")
          resetStates();
          setAjustar(false);
        } else {
          toast.warning("Ajuste inventario  no ejecutado correctamente, intente de nuevo")
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      toast.warning("Error lista vacia, por favor verifica e inetenta de nuevo");
    }
  };

  const resetStates = () => {
    setCantidadModif(0)
    setValorAnterior("")
    setObservaciones("")
    setNuevosSeriales([])
    setSerialesBorrar([])
    setSum(false)
    setLineaModificar([])
    setUsaSerial(false)
    setSerialOk(false)
    setIdInv("")
    setValidarArtySeries(false)
  };

  useEffect(() => {
    setArticulo(lineaModificar.des_Larga)
    setValorAnterior(lineaModificar.bodega ? parseFloat(lineaModificar.cant_disponible) : parseFloat(lineaModificar.cant_reservada))
    setUsaSerial(lineaModificar.serial)
    setUm(lineaModificar.um);
    if (lineaModificar.serial) setSerialesBorrar(lineaModificar.listaSeriales)
  }, [lineaModificar]);

  //------Seriales----------
  const renderListaSeriales = () => {
    const selectSerial = document.getElementById("seleccioneSerial");
    if (selectSerial) {
      selectSerial.innerHTML = "";
      if (Array.isArray(serialesBorra)) {
        serialesBorra.forEach((ls) => {
          let option = document.createElement("option");
          option.value = ls;
          selectSerial.appendChild(option);
        });
      }
    }
  };

  const handleSerialChange = (index, value) => {
    setValoresSeleccionados((prevValores) => {
      const nuevosValores = [...prevValores];
      nuevosValores[index] = value;
      return nuevosValores;
    });
  };

  const validarSeriales = () => {
    let k = 0;
    if (valoresSeleccionados) {
      valoresSeleccionados?.forEach(usaserial => {
        if (usaserial.length > 0) {
          k++;
        }
      });
      if (k === parseInt(cantSeries)) {
        toast.success("Puede continuar con el registro ");
        setValidarArtySeries(true);
        setIguales(true)
      } else {
        setValidarArtySeries(false);
        setIguales(false)
        toast.warning(
          "La cantidad de usaseriales no coincide con la cantidad de articulos, por favor verifica e intenta nuevamente."
        );
      }
    } else {
      toast.warning("La lista de usaseriales esta vacia");
    }
  };

  const convertirArray = (info) => {
    const arr = info.split(",").map(item => item.trim());
    setNuevosSeriales(arr)
  };

  useEffect(() => {
    if (parseFloat(nuevosSeriales?.length - 1) === (parseFloat(cantidadModif) - parseFloat(valorAnterior))) {
      toast.success("Puede continuar con el ajuste de inventario")
      setSerialOk(true)
    }
  }, [nuevosSeriales]);

  //------Consecutivos---------
  const cargarConsecutivos = async () => {
    const listaTemporalC = await consultarDocumentoWhere(`${emp}_consecutivos`, "prefijo", "AJIN");
    setListaConse(listaTemporalC);
    manipularConsecutivos(listaConse, setValorCons, setValorPref, setIdDocumentoConse, setVerConse);
  };

  const handleModificarConsecutivos = async (valor, idDoc) => {
    const ListaModificacion = {
      valorActual: parseInt(valor.split("-")[1]),
      fecha_modificacion: fechaFormateada,
      usuario_modificacion: user,
    };
    await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, ListaModificacion);
    await cargarConsecutivos()
    manipularConsecutivos(listaConse, setValorCons, setValorPref, setIdDocumentoConse, setVerConse);
  };

  const manipularConsecutivos = (lista, setConsecutivo, setPrefijo, setIdDoc, setVerConsec) => {
    const valorCon = lista[0]?.valorActual;
    const pref = lista[0]?.prefijo;
    const doc = lista[0]?.idDocumento;
    const val = parseInt(valorCon + 1);
    setConsecutivo(val);
    setPrefijo(pref);
    setIdDoc(doc);
    setVerConsec(pref + "-" + val);
  };

  useEffect(() => {
    manipularConsecutivos(listaConse, setValorCons, setValorPref, setIdDocumentoConse, setVerConse);
  }, [listaConse]);

  useEffect(() => {
    renderListaSeriales()
  }, [serialesBorra, observaciones]);


  //------- Paginación --------
  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [totalPaginas, paginaActual, countOrdenar, documentosFiltrados]);

  const ordenar = (columna) => {
    const direccion = (countOrdenar % 2 === 0 ? "" : "-")
    const temporal = documentosFiltrados.sort(sortBy(direccion + columna));
    setDocumentosFiltrados(temporal);
    setCountOrdenar(countOrdenar + 1)
  };

  //----Nuevo-----
  useEffect(() => {
    if (bodega !== "" || proyecto !== "")
      filtrarLista()
  }, [bodega, ubicacion, proyecto])

  useEffect(() => {
    setUbicacion("")
    if (bodega === "") return
    setProyecto("")
  }, [bodega])

  useEffect(() => {
    if (proyecto === "") return
    setBodega("")
  }, [proyecto])

  const borrarFiltros = () => {
    setBodega("")
    setUbicacion("")
    setProyecto("")
    setDocumentosFiltrados(listaInventario);
  };

  //*************************************** */
  return (
    <>
      {isLoading && <LoaderES />}

      {permisos.includes("ingresoAjustInv") && (
        !ajustar && (
          <>
            <div className="fixed-top mt-1"
              style={{
                backgroundColor: "white",
                marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
                transition: "margin-left 0.3s ease",
                top: "46px",
                zIndex: 1
              }}>

              <div className="row ms-2 me-2 mt-1">
                {/* botones de opcion */}
                <div className="col-sm-4 align-self-start text-start">
                  <div className="btn-group">

                    {/* Columnas */}
                    <button
                      className="btn fa-solid fa-table-columns"
                      title="Seleccionar Columnas"
                      data-bs-toggle="modal"
                      data-bs-target="#ModificarColumnas"
                      id="modalcolumnas" />

                    {/* Boton borrar filtros */}
                    <button
                      className="btn fa-solid fa-eraser me-2"
                      title="Borrar Filtros"
                      onClick={() => borrarFiltros()}
                    />
                  </div>
                </div>

                {/* filtrar */}
                <div className="col-sm-8">
                  <Filtrar2
                    tabla="myTable"
                    documentos={listaInventario}
                    setDocumentosFiltrados={setDocumentosFiltrados}
                    setRegistrosFiltrados={setRegistrosPaginaActual}
                    registrosPorPagina={registrosPorPagina} />
                </div>
              </div>

              {/* Filtros */}
              <div className="container">
                <div className="row mb-3">

                  {/* Bodega */}
                  <div className={"col-12 col-md-4" + (mobile ? " mt-4 mb-1" : " mt-3 mb-2")}>
                    <Select
                      placeholder="Bodega"
                      value={bodega}
                      onChange={(option) => setBodega(option)}
                      options={bodegas}
                      styles={styles}
                    />
                  </div>

                  {/* Ubicacion */}
                  <div className={"col-12 col-md-4" + (mobile ? " mt-1 mb-1" : " mt-3 mb-2")}>
                    <Select
                      placeholder="Ubicación"
                      isDisabled={proyecto || !bodega}
                      value={ubicacion}
                      onChange={(option) => setUbicacion(option)}
                      options={ubicaciones}
                      styles={styles}
                    />
                  </div>

                  {/* Proyecto */}
                  <div className={"col-12 col-md-4" + (mobile ? " mt-1 mb-1" : " mt-3 mb-2")}>
                    <Select
                      placeholder="Proyecto"
                      value={proyecto}
                      onChange={(option) => setProyecto(option)}
                      options={proyectos}
                      styles={styles}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr className={"mt-5 pt-4" + (mobile ? " mb-5" : "")} />
          </>)
      )}

      {/* Tabla de artículos */}
      {permisos.includes("ingresoAjustInv") && (
        <div className=" table-responsive abs-center-table">
          {listaInventario.length > 0 && (
            <>
              {ajustar ? (
                <>
                  {/* Formulario ajuste inv */}
                  <div className="container mt-5">
                    <div className="row text-center mb-3">

                      <div className="col-auto">
                        <button className="btn btn-primary fa-solid fa-angle-left btn-sm"
                          onClick={() => {
                            resetStates()
                            setAjustar(false)
                          }} />
                      </div>

                      <div className="col-auto">
                        <h4 className="fs-4">Ajuste de Inventario</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-2">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">Consecutivo:</span>
                          <input type="text" className="form-control"
                            readOnly
                            value={verConse} />
                        </div>
                      </div>

                      {/* desc. larga*/}
                      <div className="col-md mb-2">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">Artículo:</span>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={articulo} />
                        </div>
                      </div>

                      {/* Cantidad actual */}
                      <div className="col-md mb-2">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">Cant. Actual:</span>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={valorAnterior} />
                        </div>
                      </div>

                      <div className="container mt-0"><hr className="mb-2 bg-dark" /></div>
                      <h4 className="mt-2 fs-4 mb-2">Datos modificacion</h4>
                    </div>

                    <div className="row">
                      {/* Cantidad modificada */}
                      <div className=" col-sm mb-2">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">Cantidad a Asignar:</span>
                          <input
                            type="text"
                            className="form-control"
                            disabled={articulo === "" || serialOk}
                            onFocus={() => setCantidadModif("")}
                            value={cantidadModif}
                            step={um === "UND" ? 1 : 0.1}
                            min={um === "UND" ? 1 : 0.1}
                            maxLength={5}
                            onChange={(e) => {
                              setCantidadModif(parseFloat(e.target.value))
                              setCantSeries(parseInt(e.target.value))
                            }}
                            onBlur={() => {
                              setIdInv(buscarData(lineaModificar))
                              if (parseFloat(valorAnterior) === parseFloat(cantidadModif)) {
                                toast.warning("La cantidad a asignar es igual a la actual, por favor verifique e intente nuevamente");
                                setCantidadModif("")
                                setCantSeries("")
                              }
                            }}
                          />

                        </div>
                      </div>

                      {/* Observaciones */}
                      <div className="mb-2 col-sm ">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">Observaciones:</span>
                          <textarea
                            type="text"
                            rows={1}
                            disabled={!cantidadModif || serialOk}
                            className="form-control form-control-sm"
                            value={observaciones}
                            onChange={(e) => {
                              setObservaciones(e.target.value)
                            }} />
                        </div>
                      </div>
                    </div>

                    {/* seirales */}
                    {cantidadModif !== 0 && cantidadModif !== "" && cantidadModif !== "0" && usaSerial &&
                      <>
                        {parseFloat(cantidadModif) < parseFloat(valorAnterior) ?
                          <>
                          {!validarArrtySeries &&
                            <>
                              <div div className="mb-3 col-sm">
                                <button className="btn btn-outline-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalSeriales"
                                  onClick={() => renderListaSeriales()}>
                                  Cargar Series
                                </button>
                              </div>
                            </>
                          }
                        </>
                        :
                        <>
                          {/* seriales nuevos */}
                          {observaciones &&
                            <div className="mb-3 col-sm">
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text" id="basic-addon1">Seriales Nuevos:</span>
                                <input
                                  type="text"
                                  disabled={serialOk}
                                  className="form-control"
                                  value={nuevosSeriales}
                                  onChange={(e) => {
                                    convertirArray(e.target.value)
                                    setSum(true)
                                  }} />
                              </div>

                              <div className="col-sm">
                                {!serialOk &&
                                  <p className="text-danger mt-2"> {`Debe ingresar ${(parseFloat(cantidadModif) - parseFloat(valorAnterior))} serial(es) separados por coma, al terminar hagalo con coma`}</p>}
                              </div>
                            </div>
                          }
                        </>}
                    </>
                    }

                    {/* Boton Modificar */}
                    <div className="container mt-4 mb-2">
                      <div className="row ">
                        {usaSerial && cantidadModif !== 0 && observaciones !== "" && (serialOk || iguales) ?
                          <>
                            <div className="col">
                              <button className="btn btn-primary"
                                onClick={() => {
                                  // toast.info("Componente en mantenimiento")
                                  modificarInventario();
                                  resetStates();
                                }}>
                                Modificar
                              </button>
                            </div>
                          </>
                          : null}
                        {!usaSerial && cantidadModif !== 0 && observaciones !== "" ?
                          <>
                            <div className="col">
                              <button className="btn btn-primary"
                                onClick={() => {
                                  // toast.info("Componente en mantenimiento")
                                  modificarInventario();
                                  resetStates();
                                }}>
                                Cargar Ajuste
                              </button>
                            </div>
                          </>
                          : null}

                        <div className="col">
                          <button className="btn btn-secondary ms-2"
                            onClick={() => {
                              resetStates()
                              setAjustar(false)
                            }}>
                            Cerrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
                :
                (<>
                  {mobile ?
                    registrosPaginaActual?.map((li, index) => (
                      <div key={li.articulo + li.bodega + li.ubicacion + li.proyecto}>
                        <table className="table table-sm table-hover table-striped mt-2 mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px", resize: "none" }}>
                                <button className="btn  fa-solid fa-chevron-down btn-sm"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#collapseItem" + index}
                                  aria-expanded="false"
                                  aria-controls="collapseItem" />
                              </th>
                              <th style={{ width: "55px", resize: "none" }}>{li.bodega ? li.bodega : li.proyecto}</th>
                              <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }}>
                                <span className="truncate-text">
                                  {li.des_Larga.length > 50 ? li.des_Larga.slice(0, 50) + "..." : li.des_Larga}
                                </span>
                              </td>
                              <th style={{ width: "65px", resize: "none" }}>{li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada}</th>
                            </tr>
                          </thead>
                        </table>

                        <div className="collapse" id={"collapseItem" + index}>
                          <div className="card card-body">
                            <table className="table table-sm">
                              <tbody >
                                <DatoTablaMobile titulo={"Consecutivo"} dato={li.consecutivoFix} columna={col2} />
                                <DatoTablaMobile titulo={"Artículo"} dato={li.des_Larga} columna={col3} />
                                <DatoTablaMobile titulo={"Cantidad"} dato={li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada} columna={col4} />

                                {contBodega > 0 && (<>
                                  <DatoTablaMobile titulo={"Bodega"} dato={li.bodega} columna={col5} />
                                  <DatoTablaMobile titulo={"Ubicación"} dato={li.ubicacion} columna={col6} />
                                </>)
                                }
                                {contProyecto > 0 && (
                                  <DatoTablaMobile titulo={"Proyecto"} dato={li.proyecto} columna={col7} />
                                )}
                                <DatoTablaMobile titulo={"Valor"} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} columna={col8} />
                                <DatoTablaMobile titulo={"Total"} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada))}`} columna={col9} />

                                <tr>
                                  <td></td>
                                  <th style={{ resize: "none" }}>Actions </th>
                                  <td>
                                    <div className="btn-group">
                                      {permisos.includes("manipularInv") && (
                                        <button className="btn btn-sm btn-outline-primary fa-solid fa-box-open"
                                          title="Ajustar Inventario"
                                          onClick={() => {
                                            setLineaModificar(li)
                                            setAjustar(true)
                                          }} />)}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    <>
                      <table id="myTable" className="table table-hover table-striped table-sm table-bordered mt-5 mb-5">
                        <thead className="text-center">
                          <tr>
                            <ThTablaH ordenar={ordenar} dOrdenar={"articulo"} columna={col2} titulo={"Consecutivo"} />
                            <ThTablaH ordenar={ordenar} dOrdenar={"des_Larga"} columna={col3} titulo={"Artículo"} />
                            <ThTablaH ordenar={ordenar} dOrdenar={contBodega > 0 ? "cant_disponible" : "cant_reservada"} columna={col4} titulo={"Cantidad"} />
                            {contBodega > 0 && (
                              <>
                                <ThTablaH ordenar={ordenar} dOrdenar={"bodega"} columna={col5} titulo={"Bodega"} />
                                <ThTablaH ordenar={ordenar} dOrdenar={"ubicacion"} columna={col6} titulo={"Ubicacion"} />
                              </>)}
                            {contProyecto > 0 && (
                              <ThTablaH ordenar={ordenar} dOrdenar={"proyecto"} columna={col7} titulo={"Proyecto"} />
                            )}
                            <ThTablaH ordenar={ordenar} dOrdenar={"valor"} columna={col8} titulo={"Valor Und"} />
                            <ThTablaH ordenar={ordenar} dOrdenar={""} columna={col9} titulo={"Valor Total"} />
                            <th className="text-center" >Actions</th>
                          </tr>
                        </thead>

                        <tbody className="text-center" id="tabla_articulos">
                          {registrosPaginaActual?.map(li => (
                            <tr key={li.articulo + li.bodega + li.proyecto + li.ubicacion}>
                              <TdTablaD columna={col2} dato={li.consecutivoFix} />
                              <TdTablaD columna={col3} dato={li.des_Larga} />
                              <TdTablaD columna={col4} dato={li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada} />
                              {contBodega > 0 && (<>
                                <TdTablaD columna={col5} dato={li.bodega} />
                                <TdTablaD columna={col6} dato={li.ubicacion} />
                              </>)}
                              {contProyecto > 0 && (
                                <TdTablaD columna={col7} dato={li.proyecto} />)}
                              <TdTablaD columna={col8} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor)}`} />
                              <TdTablaD columna={col9} dato={`$ ${Intl.NumberFormat("es-CO").format(li.valor * (li.cant_disponible !== undefined ? li.cant_disponible : li.cant_reservada))}`} />
                              {/* Btn ajustar */}
                              <td className="text-center">
                                {permisos.includes("manipularInv") && (
                                  <button className="btn btn-sm btn-outline-primary fa-solid fa-box-open"
                                    title="Ajustar Inventario"
                                    onClick={() => {
                                      setLineaModificar(li)
                                      setAjustar(true)
                                    }} />)}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        <tfoot className="text-center text-danger">
                          <tr>
                            <td className={col2 ? "" : "d-none"}></td>
                            <td className={col3 ? "" : "d-none"}></td>
                            <td className={col4 ? "text-center" : "d-none"} scope="col" >{Intl.NumberFormat(undefined, { mininumFractionsDigits: 2 }).format(acumulados.cantidadArticulo)}</td>
                            {contBodega > 0 && (<>
                              <td className={col5 ? "" : "d-none"}></td>
                              <td className={col6 ? "" : "d-none"}></td></>)}
                            {contProyecto > 0 && (<td className={col7 ? "" : "d-none"}></td>)}
                            <td className={col8 ? "" : "d-none"}></td>
                            <td className={col9 ? "text-center" : "d-none"} scope="col" >${Intl.NumberFormat("es-CO").format(acumulados.superTotal)}</td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </>}
                </>)}
            </>)}
        </div>
      )}

      {!ajustar && (
        <Pagination
          paginaActual={paginaActual}
          totalPaginas={totalPaginas}
          setPaginaActual={setPaginaActual}
          registrosPorPagina={registrosPorPagina}
          setRegistrosPorPagina={setRegistrosPorPagina}
          documentosFiltrados={documentosFiltrados}
        />)}

      {/* Modal Columnas */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModificarColumnas"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">

            <div className="modal-header bg-primary text-light ">
              <h5 className="modal-title" id="staticBackdropLabel3">
                Selección de columnas
              </h5>
            </div>

            <div className="modal-body">

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2', "inv")} agregarColumna={agregarColumna} texto={"Consecutivo"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3', "inv")} agregarColumna={agregarColumna} texto={"Artículo"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4', "inv")} agregarColumna={agregarColumna} texto={"Cantidad"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={5} checked={col5} setChecked={setCol5} validar={() => validarCheckboxes('col5', "com")} agregarColumna={agregarColumna} texto={"Bodega"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={6} checked={col6} setChecked={setCol6} validar={() => validarCheckboxes('col6', "com")} agregarColumna={agregarColumna} texto={"Ubicacion"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={7} checked={col7} setChecked={setCol7} validar={() => validarCheckboxes('col7', "com")} agregarColumna={agregarColumna} texto={"Proyecto"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={8} checked={col8} setChecked={setCol8} validar={() => validarCheckboxes('col8', "com")} agregarColumna={agregarColumna} texto={"Valor Und"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repEntradas" columna={9} checked={col9} setChecked={setCol9} validar={() => validarCheckboxes('col9', "com")} agregarColumna={agregarColumna} texto={"Valor Total"} />
              </div>

            </div>


            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal" >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal seriales */}
      < div
        className="modal fade"
        id="modalSeriales" tabIndex="-1" aria-labelledby="modalSerialesLabel" aria-hidden="true" >
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header bg-primary text-light">
              <h1 className="modal-title fs-5 text-center" id="modalSerialesLabel">
                Seleccione los seriales que va a conservar el articulo
              </h1>
            </div>

            {/* Tabla Seriales */}
            <div className="modal-body">
              <table className="table table-hover  table-sm">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Articulo</th>
                    <th scope="col">Serial</th>
                  </tr>
                </thead>
                <tbody className="text-center" id="tabla">
                  {Array.from(Array(Math.max(0, cantSeries) || 0), (_, index) => (
                    <tr key={index}>
                      <td>{articulo}</td>
                      <td>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          list="seleccioneSerial"
                          onClick={() => setContRenSeriales(prueba => contRenSeriales + 1)}
                          onBlur={() =>
                            handleBlurDatalist("seleccioneSerial", listaSeriales, setListaSeriales)}
                          onChange={(e) => handleSerialChange(index, e.target.value)}
                        />
                        <datalist id="seleccioneSerial" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                Cancelar
              </button>

              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => validarSeriales()}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div >

    </>
  );
};
