
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortBy from "sort-by";
import uniqid from 'uniqid';
import {
  actualizarDocumentoDatabaseIdCampo,
  guardarDatabaseId
} from "../config/firebase";
import { changeTitle, fixConsecutivo, isMobile, styles } from "../config/functions/funGenerales";
import { cargarConsecutivos, handleModificarConsecutivo } from './../config/functions/funGenerales';
import { LoaderES } from "./LoaderES";

export const GestionSolicitudes = ({ sol, listaSol, listaArt, listaInv }) => {

  const [componente, setComponente] = useState(localStorage.getItem("componente") || "gest_solicitud");
  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;

  const location = useLocation();
  const history = useHistory();
  const [origen, setOrigen] = useState("");

  const fechaActual = new Date();
  const fechaFormateada = fechaActual.toLocaleDateString("es-CO");
  const fecha_creacion = fechaFormateada;
  const fecha_modificacion = fechaFormateada;

  const [listaConsecutivosAsignar, setlistaConsecutivosAsignar] = useState([]);
  const [listaConsecutivosMover, setlistaConsecutivosMover] = useState([]);
  const [inventarioArticulo, setInventarioArticulo] = useState([]);
  const [listaConsecutivos, setlistaConsecutivos] = useState([]);
  const [solicitudProcesar, setSolicitudProcesar] = useState([]);
  const [serialesProcesar, setSerialesProcesar] = useState([]);
  const [serialesTomados, setSerialesTomados] = useState([]);
  const [lineaProcesar, setLineaProcesar] = useState([]);
  const [listaSeries, setListaSeries] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [bodegas, setBodegas] = useState([]);

  const [contRenSeriales, setContRenSeriales] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [usaSerial, setUsaSerial] = useState(false);
  const consecAsignar = "ASG";
  const consecMover = "MOV";

  const [idDocumentoConsecutivoAsignar, setIdDocumentoConsecutivoAsignar,] = useState("");
  const [idDocumentoConsecutivoMover, setIdDocumentoConsecutivoMover,] = useState("");
  const [valorConsecutivoAsignar, setValorConsecutivoAsignar] = useState(0);
  const [valorConsecutivoMover, setValorConsecutivoMover] = useState(0);
  const [valorPrefijoAsignar, setValorPrefijoAsignar] = useState("");
  const [valorPrefijoMover, setValorPrefijoMover] = useState("");

  const [cantidadArreglo, setCantidadArreglo] = useState(0);
  const [validacionOk, setValidacionOk] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [indexFila, setIndexFila] = useState(1000);
  const [disponible, setDisponible] = useState({});
  const [ubicacion, setUbicacion] = useState("");
  const [mobile, setMobile] = useState(false);
  const [bodega, setBodega] = useState("");
  const [mayor, setMayor] = useState(null);
  const [menor, setMenor] = useState(null);
  const [valOk, setValOk] = useState(true);
  const [clicks, setClicks] = useState(0);
  const [art, setArt] = useState("");
  const [key, setKey] = useState(0);
  //******************************************* */

  useEffect(() => {
    changeTitle("Gestion_solicitudes");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    obtenerSolicitud(sol)
    cargarConsecutivos("DOCUMENTO", emp, setlistaConsecutivos);
    isMobile(setMobile)
  };

  useEffect(() => {
    localStorage.setItem("componente", componente);
  }, [componente]);

  useEffect(() => {
    const existeComponente = (location.state)?.componente
    if (existeComponente) {
      setOrigen((location.state).origen);
    }
  }, [location]);

  const obtenerSolicitud = async (id) => {
    const solCompleta = listaSol.filter((s) => s.consecutivo === id)
    procesarDatos(listaArt, solCompleta)
  };

  const procesarDatos = async (listaArticulos, listaSolicitudes) => {
    if (listaArticulos.length > 0 && listaSolicitudes.length > 0 && listaInv.length > 0) {
      const arreglo = [];
      listaSolicitudes.forEach((element) => {
        Object.entries(element).forEach(([key, value]) => {
          if (key !== "consecutivo" && typeof value === "object") {
            const temporalArticulo = listaArticulos?.find(at => at.consecutivo === value.articulo)
            let datoTemporal = { consecutivo: element.consecutivo, ...value };
            let datoTemporalSinDetalle = {
              consecutivo: datoTemporal.consecutivo,
              fecha_creacion: element.fecha_creacion,
              articulo: datoTemporal.articulo,
              estadoArt: temporalArticulo?.estado,
              estadoMov: value.estado,
              des_Larga: temporalArticulo?.des_Larga,
              serial: temporalArticulo?.serial,
              um: temporalArticulo?.um,
              valor: temporalArticulo?.ult_Costo,
              consecutivoFixSol: fixConsecutivo(datoTemporal.consecutivo),
              detalle: datoTemporal,
            };
            arreglo.push(datoTemporalSinDetalle);
          }
        });
      });
      const solicitud = arreglo.sort(sortBy("consecutivoFixSol"))
      const temp = []

      solicitud.forEach(s => {
        const invArticulo = listaInv.filter(lia => lia.articulo === s.articulo)
        const solicitudInventario = {
          ...s,
          inventario: invArticulo
        }
        temp.push(solicitudInventario)
      });
      setSolicitudProcesar(temp);
    }
  };

  //-------Consecutivos-------- *
  useEffect(() => {
    const listaTemporalC = listaConsecutivos.filter((f) => f.prefijo === consecMover);
    setlistaConsecutivosMover(listaTemporalC)

    const listaTemporal = listaConsecutivos.filter((f) => f.prefijo === consecAsignar);
    setlistaConsecutivosAsignar(listaTemporal)
  }, [listaConsecutivos]);

  const manipularConsecutivos = (lista, setConsecutivo, setPrefijo, setIdDoc) => {
    const valorCon = lista[0]?.valorActual;
    const pref = lista[0]?.prefijo;
    const doc = lista[0]?.idDocumento;
    const val = parseInt(valorCon + 1);
    setConsecutivo(val);
    setPrefijo(pref);
    setIdDoc(doc);
  };

  // const handleModificarConsecutivos = async (valor, idDoc, manipular) => {
  //   if (manipular === "mover") {
  //     const ListaModificacion = {
  //       valorActual: parseInt(valor.split("-")[1]),
  //       fecha_modificacion: fechaFormateada,
  //       usuario_modificacion: user,
  //     };
  //     try {
  //       await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, ListaModificacion);
  //       await cargarConsecutivos(consecMover, setlistaConsecutivosMover);
  //       manipularConsecutivos(listaConsecutivosMover, setValorConsecutivoMover, setValorPrefijoMover, setIdDocumentoConsecutivoMover);
  //       // manipularConsecutivos(listaConsecutivosMover, setValorConsecutivoMover, setValorPrefijoMover, setIdDocumentoConsecutivoMover)

  //     } catch (error) {
  //       console.error(error)
  //     }
  //   } else if (manipular === "asignar") {
  //     const ListaModificacion = {
  //       valorActual: parseInt(valor.split("-")[1]),
  //       fecha_modificacion: fechaFormateada,
  //       usuario_modificacion: user,
  //     };
  //     try {
  //       await actualizarDocumentoDatabase(`${emp}_consecutivos`, idDoc, ListaModificacion);
  //       await cargarConsecutivos(consecAsignar, setlistaConsecutivosAsignar);
  //       manipularConsecutivos(listaConsecutivosAsignar, setValorConsecutivoAsignar, setValorPrefijoAsignar, setIdDocumentoConsecutivoAsignar);

  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  // };

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosMover, setValorConsecutivoMover, setValorPrefijoMover, setIdDocumentoConsecutivoMover);
  }, [listaConsecutivosMover]);

  useEffect(() => {
    manipularConsecutivos(listaConsecutivosAsignar, setValorConsecutivoAsignar, setValorPrefijoAsignar, setIdDocumentoConsecutivoAsignar);
  }, [listaConsecutivosAsignar]);

  //------ Mover--------
  const validarDisponibilidad = () => {

    for (let item of solicitudProcesar) {
      let { cantidad: cantEnv, bodega: bdg, ubicacion: ubc, detalle: { cantidadSolicitada: cantSol }, des_Larga: nom, articulo: cons, observacion: obs } = item;
      if (cantEnv === "" || bdg === "" || ubc === "") {
        toast.warning("Todos los registros deben estar diligenciados correctamente, porfavor verifique e intente de nuevo");
        setValidacionOk(false)
        return
      }
      if ((cantEnv > cantSol || cantEnv < cantSol) && !obs) {
        setMayor(cantEnv > cantSol);
        setMenor(cantEnv < cantSol);
        setValidacionOk(false)
        toast.warning(`La cantidad a enviar del artículo ${cons} : ${nom} es ${cantEnv > cantSol ? 'mayor' : 'menor'} a la solicitada, debe especificar la razón en observación`);
        return;
      }
      setMayor(false);
      setMenor(false);
      setValidacionOk(true);
    }
  };

  const enviarSol = () => {
    if (validacionOk) {
      const bod = solicitudProcesar.filter((bp) => bp.detalle.destino.split("-")[0] === "BDG")
      const pro = solicitudProcesar.filter((bp) => bp.detalle.destino.split("-")[0] === "PROY")
      if (bod.length > 0) {
        moverA_Bodega(bod)
      }
      if (pro.length > 0) {
        moverA_Proyecto(pro)
      }
    }

  };

  const moverA_Bodega = async (listaMover) => {
    if (listaMover.length === 0) {
      toast.warning("No hay artículos para mover, ingrese un artículo e intente de nuevo");
      return;
    }
    if (window.confirm("Esta seguro de continuar con el movimiento?")) {
      let idDocDestino = "", k = 0, res, resp, listaFusion, data;
      const asignar = [], est = "transito";
      const consec = valorPrefijoMover.toUpperCase() + "-" + valorConsecutivoMover;

      for (const lb of listaMover) {
        let esBodega = lb.bodega.split("-")[0] === "BDG" ? lb.bodega : "";
        const conSerial = lb.serial;

        if (conSerial && (lb.listaSeriales?.length === 0 || lb.listaSeriales === "" || lb.listaSeriales === undefined)) {
          toast.warning("Debe validar los seriales del artículo");
          return;
        }

        const existeBD = listaInv.find((t) => t.consecutivo.split("\\")[0] === lb.detalle.destino && t.consecutivo.split("\\")[1] === lb.detalle.ubicacionDestino && t.consecutivo.split("\\")[2] === lb.articulo);
        const existeBS = listaInv.find((t) => t.consecutivo.split("\\")[0] === lb.bodega && t.consecutivo.split("\\")[1] === lb.ubicacion && t.consecutivo.split("\\")[2] === lb.articulo);

        const bdgDes = existeBD?.bodega;

        if (esBodega !== "") {
          listaFusion = conSerial ? existeBS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
          res = await modificarArtMovAsig(existeBS, existeBS?.consecutivo, existeBS?.cant_disponible, listaFusion, lb?.cantidad, "bodega", conSerial);
          if (res) {
            const item = {
              id: uniqid("movimiento-"),
              idMov: uniqid("mov-"),
              consecutivo: consec,
              fechaMover: fecha_creacion,
              cantidad: Number(Number.parseFloat(lb.cantidad).toFixed(2)),
              valor: parseFloat(lb.valor),
              ...(conSerial && { listaSeriales: lb.listaSeriales }),
              usuario_creacion: user,
              fecha_creacion: fechaFormateada,
              estado: est,
              observaciones: observaciones,
            };
            if (existeBD !== undefined) {
              idDocDestino = existeBD.consecutivo;
              const list = crearLista(existeBD);
              delete list.clasificacion1;
              delete list.clasificacion2;
              delete list.clasificacion3;
              delete list.consecutivoFix;
              delete list.consecutivoFixMov;
              delete list.des_Larga;
              delete list.des_Corta;
              delete list.imagen;
              delete list.referencia;
              delete list.serial;
              delete list.tipo;
              delete list.uc;
              delete list.um;
              delete list.um;
              delete list.ult_Costo;
              delete list.ult_Compra;
              try {
                await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${bdgDes.toLowerCase()}`, idDocDestino, list);
              } catch (error) {
                console.error(error);
              }
            } else {
              const doc = lb.detalle.destino;
              idDocDestino = `${lb.detalle.destino}\\${lb.detalle.ubicacionDestino}\\${lb.articulo}`;
              const idInv = uniqid("inventario-")
              const lista1 = {
                articulo: lb.articulo,
                bodega: lb.detalle.destino,
                cant_disponible: lb.cantidad,
                consecutivo: idDocDestino,
                fecha_carga: fechaFormateada,
                fecha_creacion: fechaFormateada,
                id: idInv,
                idArticulo: lb.detalle.idArticulo,
                observacion: observaciones,
                ubicacion: lb.detalle.ubicacionDestino,
                usuario_creacion: user,
                valor: parseFloat(lb.valor),
                estado: "transito",
              };
              const enviar = {
                [idDocDestino]: lista1,
              };
              try {
                await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${doc.toLowerCase()}`, enviar);
              } catch (error) {
                console.error(error);
              }
            }
            idDocDestino = `${lb.detalle.destino}\\${lb.detalle.ubicacionDestino}\\${lb.articulo}`;
            if (existeBS !== undefined && lb && esBodega !== "") {
              data = {
                ...item,
                bodegaSalida: lb.bodega,
                ubicacionSalida: lb.ubicacion,
                bodegaDestino: lb.detalle.destino,
                ubicacionDestino: lb.detalle.ubicacionDestino,
                idDocSalida: existeBS?.consecutivo,
                idDocDestino: idDocDestino,
                articulo: existeBS?.articulo,
                observaciones: observaciones,
              };
              delete data.fecha_creacion;
            }
            asignar.push(data);
          } else {
            toast.warning("Error con el movimiento del artículo en el inventario");
          }
        }

        k++;
        if (k === listaMover.length) {
          const enviar = {
            [consec]: asignar,
          };
          setIsLoading(true)
          try {
            resp = await guardarDatabaseId(`${emp}_movimientos`, `${emp}_movimientos_${valorPrefijoMover.toLowerCase()}`, enviar);
            if (resp !== "") {
              await cargarSalidaEnKardex(asignar, consec);
              await cambioEstadoTodos(solicitudProcesar, "procesado");
              await handleModificarConsecutivo(consec, user, fechaFormateada, idDocumentoConsecutivoMover, emp);
              await cargarConsecutivos("DOCUMENTO", emp, setlistaConsecutivos);
              limpiaGestion();
              toast.success("El movimiento se han realizado con éxito");
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
            setComponente(origen)
            history.push({ pathname: `/${origen === "ConsSolicitudes" ? "cons_solicitud" : "cons_solicitud"}`, state: { componente: origen } })
          }
        }
      }
    } else {
      return
    }
  };

  const moverA_Proyecto = async (listaMover) => {
    if (listaMover.length === 0) {
      toast.warning("No hay artículos para mover, ingrese un artículo e intente de nuevo");
      return;
    }
    if (window.confirm("Esta seguro de continuar con el movimiento?")) {
      let idDocDestino = "", k = 0, res, resp, listaFusion, data;
      const asignar = [], est = "transito";
      const consec = valorPrefijoAsignar.toUpperCase() + "-" + valorConsecutivoAsignar;

      for (const lb of listaMover) {
        let esBodega = lb.bodega.split("-")[0] === "BDG" ? lb.bodega : "";
        const conSerial = lb.serial;

        if (conSerial && (lb.listaSeriales.length === 0 || lb.listaSeriales === "" || lb.listaSeriales === undefined)) {
          toast.warning("Debe validar los seriales del artículo");
          return;
        }
        const documento = "proyectos";
        const existeBS = listaInv.find((t) => t.consecutivo.split("\\")[0] === lb.bodega && t.consecutivo.split("\\")[1] === lb.ubicacion && t.consecutivo.split("\\")[2] === lb.articulo);
        const existePD = listaInv.find((t) => t.consecutivo.split("\\")[0] === lb.detalle.destino && t.consecutivo.split("\\")[1] === lb.articulo);

        if (esBodega !== "") {
          listaFusion = conSerial ? existeBS?.listaSeriales.filter((usaserial) => !lb?.listaSeriales.includes(usaserial)) : [];
          res = await modificarArtMovAsig(existeBS, existeBS?.consecutivo, existeBS?.cant_disponible, listaFusion, lb?.cantidad, "bodega", conSerial);

          if (res) {
            const item = {
              id: uniqid("movimiento-"),
              idMov: uniqid("mov-"),
              consecutivo: consec,
              fechaMover: fecha_creacion,
              cantidad: Number(Number.parseFloat(lb.cantidad).toFixed(2)),
              valor: parseFloat(lb.valor),
              ...(conSerial && { listaSeriales: lb.listaSeriales }),
              usuario_creacion: user,
              fecha_creacion: fechaFormateada,
              estado: est,
              observaciones: observaciones,
            };

            if (existePD !== undefined) {
              idDocDestino = existePD.consecutivo;
              const list = crearLista(existePD);
              delete list.clasificacion1;
              delete list.clasificacion2;
              delete list.clasificacion3;
              delete list.consecutivoFix;
              delete list.consecutivoFixMov;
              delete list.des_Larga;
              delete list.des_Corta;
              delete list.imagen;
              delete list.referencia;
              delete list.serial;
              delete list.tipo;
              delete list.uc;
              delete list.um;
              delete list.um;
              delete list.ult_Costo;
              delete list.ult_Compra;
              try {
                await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, idDocDestino, list);
              } catch (error) {
                console.error(error);
              }
            } else {
              idDocDestino = `${lb.detalle.destino}\\${lb.articulo}`;
              const idInv = uniqid("inventario-")
              const lista1 = {
                articulo: lb.articulo,
                bodega: lb.detalle.destino,
                cant_reservada: lb.cantidad,
                consecutivo: idDocDestino,
                fecha_carga: fechaFormateada,
                fecha_creacion: fechaFormateada,
                id: idInv,
                idArticulo: lb.detalle.idArticulo,
                observacion: observaciones,
                usuario_creacion: user,
                valor: parseFloat(lb.valor),
                estado: "transito",
              };
              const enviar = {
                [idDocDestino]: lista1,
              };
              try {
                await guardarDatabaseId(`${emp}_inventario`, `${emp}_inventario_${documento}`, enviar);
              } catch (error) {
                console.error(error);
              }
            }

            idDocDestino = `${lb.detalle.destino}\\${lb.articulo}`;
            if (existeBS !== undefined && lb && esBodega !== "") {
              data = {
                ...item,
                bodegaSalida: lb.bodega,
                ubicacionSalida: lb.ubicacion,
                bodegaDestino: lb.detalle.destino,
                ubicacionDestino: lb.detalle.ubicacionDestino,
                idDocSalida: existeBS?.consecutivo,
                idDocDestino: idDocDestino,
                articulo: existeBS?.articulo,
                observaciones: observaciones,
              };
              delete data.fecha_creacion;
            }
            asignar.push(data);
          } else {
            toast.warning(
              "Error con el movimiento del artículo en el inventario"
            );
          }
        }

        k++;
        if (k === listaMover.length) {
          const enviar = {
            [consec]: asignar,
          };
          setIsLoading(true);
          try {
            resp = await guardarDatabaseId(`${emp}_movimientos`, `${emp}_movimientos_${valorPrefijoAsignar.toLowerCase()}`, enviar);
            if (resp !== "") {
              await cargarSalidaEnKardex(asignar, consec);
              await cambioEstadoTodos(solicitudProcesar, "procesado");
              await handleModificarConsecutivo(consec, user, fechaFormateada, idDocumentoConsecutivoAsignar, emp);
              await cargarConsecutivos("DOCUMENTO", emp, setlistaConsecutivos);
              limpiaGestion();
              toast.success("El movimiento se han realizado con éxito");
              setComponente(origen)
              history.push({ pathname: `/${origen === "ConsSolicitudes" ? "cons_solicitud" : "cons_solicitud"}`, state: { componente: origen } })
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false)
            setComponente(origen)
            history.push({ pathname: `/${origen === "ConsSolicitudes" ? "cons_solicitud" : "cons_solicitud"}`, state: { componente: origen } })
          }
        }
      }
    } else {
      return
    }
  };

  const cambioEstadoTodos = async (array, est) => {
    let id, valor, mdf = {}, nuevoArray = [];
    for (let item of array) {
      delete item.fecha_creacion;
      id = item.consecutivo;
      valor = item.consecutivo.split("-")[0];
      nuevoArray = array.map(obj => {
        let { inventario, ...resto } = obj;
        let { detalle, observacion, listaSeriales, cantidad, bodega, ubicacion } = resto;
        let { ...detalleSinAnidado } = detalle;

        return {
          ...detalleSinAnidado,
          listaSeriales: listaSeriales || [],
          observacion: observacion ? observacion : "",
          origen: bodega,
          ubicOrigen: ubicacion,
          cantidadEnviada: cantidad,
          estado: est,
        };
      });
    }
    mdf = nuevoArray
    try {
      await actualizarDocumentoDatabaseIdCampo(`${emp}_solicitudes`, `${emp}_solicitudes`, id, mdf);
      await actualizarDocumentoDatabaseIdCampo(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, id, mdf);
    } catch (error) {
      console.error(error)
    }

  };

  const limpiaGestion = () => {
    setUsaSerial(false);
    setSerialesTomados([]);
  };

  const modificarArtMovAsig = async (lista, consec, cantidadBD, listaFusion, cantidad, sitio, usaSerie) => {
    let cant = parseFloat(cantidadBD) - parseFloat(cantidad);
    cant = Number(Number.parseFloat(cant).toFixed(2));
    const valor = lista.bodega.toLowerCase();
    const documento = sitio === "bodega" ? valor : "proyectos";

    const ls = {
      ...lista,
      fecha_modificacion,
      usuario_modificacion: user,
      estado: "despachado",
    };
    delete ls.clasificacion1;
    delete ls.clasificacion2;
    delete ls.clasificacion3;
    delete ls.consecutivoFix;
    delete ls.consecutivoFixMov;
    delete ls.des_Larga;
    delete ls.des_Corta;
    delete ls.imagen;
    delete ls.referencia;
    delete ls.serial;
    delete ls.tipo;
    delete ls.uc;
    delete ls.um;
    delete ls.um;
    delete ls.ult_Costo;
    delete ls.ult_Compra;
    if (usaSerie) {
      ls.listaSeriales = listaFusion;
    }
    if (sitio === "bodega") {
      ls.cant_disponible = cant;
    }
    if (sitio === "proyecto") {
      ls.cant_reservada = cant;
    }
    try {
      const res = await actualizarDocumentoDatabaseIdCampo(`${emp}_inventario`, `${emp}_inventario_${documento}`, consec, ls);
      return res !== "" && res !== undefined ? true : false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const crearLista = (existe) => {
    return {
      ...existe,
      estado: "transito",
      usuario_modificacion: user,
      fecha_modificacion,
    };
  };

  const cargarSalidaEnKardex = async (lista, consec) => {
    const consecutivo = consec;
    const valor = consec.split("-")[0];
    let id = uniqid("kardex-");
    let cons = null;
    if (lista.length !== 0) {
      for (const key in lista) {
        if (lista.hasOwnProperty(key)) {
          const objetoActual = lista[key];
          cons = objetoActual.articulo;
          objetoActual.articulo = cons;
          objetoActual.id = id;
          objetoActual.usuario_creacion = user;
          objetoActual.fecha_creacion = fecha_creacion;
          delete objetoActual.usaserial;
          delete objetoActual.consecutivo;
          delete objetoActual.idArticulo;
        }
      }
      const enviar = {
        [consecutivo]: lista,
      };
      try {
        await guardarDatabaseId(`${emp}_kardex`, `${emp}_kardex_${valor.toLowerCase()}`, enviar);
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.warning("Error lista vacia, por favor verifica e inetenta de nuevo");
    }
  };

  //--------Nuevo---------
  const handleClick = async (index, lista) => {
    const dest = lista.detalle.destino;
    const filtrados = lista.inventario.filter(f => f.bodega !== dest && f.cant_disponible > 0)
    setListaSeries([])
    setSerialesTomados([])
    setBodega(lista.bodega || '')
    setUbicacion(lista.ubicacion || '')
    setCantidadArreglo(lista.cantidad || 0)
    setIndexFila(index)
    setInventarioArticulo(lista.inventario)
    const bodegasInv = Array.from(new Set(filtrados.map(i => i.bodega)));
    const temporal = bodegasInv.filter(bod => bod !== undefined && bod !== dest);
    if (temporal.length > 0) {
      setBodegas(temporal);
    } else {
      setBodegas([]);
      setBodega("sin inventario")
      setUbicacion("sin inventario")
      setCantidadArreglo(0)
    }
  };

  useEffect(() => {
    const invUbicacion = inventarioArticulo.filter(ia => ia.bodega === bodega);
    const ubicacionesT = Array.from(new Set(invUbicacion.map(i => i.ubicacion)));
    setUbicaciones(ubicacionesT);
    if (indexFila === 1000) return
    const solicitudTempo = [...solicitudProcesar]
    solicitudTempo[indexFila].bodega = bodega
    setSolicitudProcesar(solicitudTempo)
  }, [bodega]);

  useEffect(() => {
    if (indexFila === 1000) return
    const disponible = inventarioArticulo.find(ia => ia.bodega === bodega && ia.ubicacion === ubicacion)
    setDisponible(disponible);
    const solicitudTempo = [...solicitudProcesar]
    solicitudTempo[indexFila].ubicacion = ubicacion
    setSolicitudProcesar(solicitudTempo)
  }, [ubicacion]);

  useEffect(() => {
    usaSerial && buscarSeriales(art);
    if (indexFila === 1000) return
    const solicitudTempo = [...solicitudProcesar]
    solicitudTempo[indexFila].cantidad = parseFloat(cantidadArreglo)
    setSolicitudProcesar(solicitudTempo)
    setKey(cantidadArreglo)
  }, [cantidadArreglo]);

  useEffect(() => {
    if (indexFila === 1000) return
    const solicitudTempo = [...solicitudProcesar]
    solicitudTempo[indexFila].observacion = observaciones
    setSolicitudProcesar(solicitudTempo)
  }, [observaciones]);

  useEffect(() => {
    if (indexFila === 1000) return
    const solicitudTempo = [...solicitudProcesar]
    solicitudTempo[indexFila].listaSeriales = serialesTomados || []
    setSolicitudProcesar(solicitudTempo)
  }, [serialesTomados]);

  //------ Seriales -----------
  const buscarSeriales = (art) => {
    const b = lineaProcesar?.bodega;
    const u = lineaProcesar?.ubicacion;
    const inv = lineaProcesar?.inventario;
    let series = [];
    const linea = inv.find((s) => s.articulo === art && s.bodega === b && s.ubicacion === u)
    series = linea?.listaSeriales;
    setSerialesProcesar(series);
  };

  useEffect(() => {
    const options = serialesProcesar?.map(ls => ({ value: ls, label: ls }));
    setListaSeries(options?.sort(sortBy("value")));
  }, [serialesProcesar, cantidadArreglo]);

  const handleSerialChange = (index, value) => {
    setSerialesTomados((prevValores) => {
      const nuevosValores = [...prevValores];
      nuevosValores[index] = value.value;
      validarSeriesRepetidas(nuevosValores)
      return nuevosValores;
    });
  };

  const validarSeriesRepetidas = (lista) => {
    let repetidos = {};
    for (let i = 0; i < lista.length; i++) {
      if (repetidos[lista[i]]) {
        toast.warning(`El serial ${lista[i]} esta repetido, debe cambiarlo`);
        break;
      }
      repetidos[lista[i]] = true;
    }
  };

  useEffect(() => {
    if (modalIsOpen) {
      setSerialesTomados([]);
      setKey(prevKey => prevKey + 1);
    }
  }, [modalIsOpen]);

  const texto = () => {
    return (
      <strong className="text-danger">Campo Obligatorio</strong>
    )
  }

  //---------------
  return (
    <>
      {isLoading && <LoaderES />}

      {mobile ?
        (<>
          <div className="card-header mt-5">
            <div className="row align-items-center">
              <div className="col-2">
                <button className="btn btn-primary fa-solid fa-angle-left btn-sm"
                  onClick={() => {
                    setComponente(origen)
                    history.push({ pathname: `/${origen === "ConsSolicitudes" ? "cons_solicitud" : "cons_solicitud"}`, state: { componente: origen } })
                  }} />
              </div>
              <div className="col-8">
                <h3 className="h3-easystock">
                  Gestion Solicitud: {sol}
                </h3>
              </div>
            </div>
          </div>
          {solicitudProcesar?.map((li, index) => (
            <>
              <div key={li.consecutivoFixSol + li.id + index} className={index === 0 && "mt-3"}>
                <table className="table table-sm table-hover table-striped mb-0 mt-2">
                  <thead >
                    <tr key={li?.consecutivo + index} >
                      <th style={{ width: "30px", resize: "none" }}>
                        <button className="btn  fa-solid fa-chevron-down btn-sm"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapseItem" + li?.consecutivo + index}
                          aria-expanded="false"
                          aria-controls="collapseItem" />
                      </th>
                      <th style={{ width: "75px", resize: "none" }}>{li.articulo}</th>
                      <td style={{ minWidth: "60px", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", resize: "none" }}>
                        <span className="truncate-text">
                          {li.des_Larga.length > 50 ? li.des_Larga.slice(0, 50) + "..." : li.des_Larga}
                        </span>
                      </td>
                    </tr>
                  </thead>
                </table>

                <div className="collapse" id={"collapseItem" + li?.consecutivo + index}>
                  <div className="card card-body">
                    <table className="table table-sm mb-0">
                      <thead >
                        <tr key={li?.consecutivo + index}>
                          <th >Consec. <br /> Sol</th>
                          <th >Consec. <br /> Art</th>
                          <th >Artículo</th>
                          <th >Solicitado</th>
                          <th ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={li.detalle?.destino + li.detalle.idArticulo}>
                          <td>{li?.consecutivoFixSol}</td>
                          <td>{li?.articulo}</td>
                          <td>{li?.des_Larga}</td>
                          <td>{li.detalle?.cantidadSolicitada}</td>
                          <td className="bnt-group">
                            <button
                              className="btn btn-sm btn-outline-dark fa-solid fa-pencil me-1"
                              title="Llenar datos"
                              disabled={validacionOk}
                              onClick={() => {
                                handleClick(index, li)
                                setLineaProcesar(li)
                                setClicks(clicks + 1)
                              }}
                            />
                            {usaSerial && serialesTomados.length === 0 &&
                              <button
                                className="btn btn-sm btn-outline-primary  fa-solid fa-list-check"
                                disabled={validacionOk}
                                data-bs-toggle="modal"
                                data-bs-target="#modalSeriales"
                                title="Agregar seriales de los articulos"
                                onClick={() => setModalIsOpen(true)}
                              />
                            }
                          </td>
                        </tr>

                        <tr>
                          <th>Origen</th>
                          <td colSpan={5} >
                            <div className="input-group">
                              <select
                                className="form-select form-select-sm"
                                disabled={index !== indexFila || bodegas.length === 0 || validacionOk}
                                onChange={(e) => {
                                  setArt("")
                                  setBodega(e.target.value)
                                  setUsaSerial(li.serial)
                                  setArt(li.articulo)
                                }}
                              >
                                {(li.bodega && index !== indexFila) && <option value={li.bodega} selected> {li.bodega} </option>}

                                {index === indexFila && (bodegas.length === 0)
                                  ? <option value=''> Sin Inventario disponible </option>
                                  : <option value=''> Seleccione Bodega </option>
                                }

                                {index === indexFila && bodegas.map(b => (
                                  <option key={b} value={b} selected={b === li.bodega}>
                                    {b}
                                  </option>
                                ))}

                              </select>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th>Ubic. Origen</th>
                          <td colSpan={5} >
                            <div className="input-group">
                              <select
                                className="form-select form-select-sm"
                                disabled={index !== indexFila || bodegas.length === 0 || validacionOk}
                                onChange={(e) => setUbicacion(e.target.value)}
                              >

                                {li.ubicacion && index !== indexFila && <option value={li.ubicacion} selected> {li.ubicacion} </option>}

                                {index === indexFila && (ubicaciones.length === 0)
                                  ? <option value=''> Sin Inventario disponible </option>
                                  : <option value=''> Seleccione Ubicacion </option>
                                }

                                {index === indexFila && ubicaciones.map((b) => (
                                  <option key={b} value={b} selected={b === li.ubicacion}>
                                    {b}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th>Cant. Enviar</th>
                          <td colSpan={5} >
                            <div className="input-group input-group-sm">
                              <span className="input-group-text" id="basic-addon1">
                                ({index === indexFila && disponible !== undefined ? disponible?.cant_disponible : 0})
                              </span>
                              <input
                                className={!valOk ? "text-danger form-control form-control-sm " : "form-control form-control-sm "}
                                disabled={(index !== indexFila) || (disponible?.cant_disponible === 0) || (solicitudProcesar[index]?.bodega === undefined) || (solicitudProcesar[index]?.ubicacion === undefined) || (bodegas.length === 0) || (validacionOk)}
                                type="number"
                                step={li.um === "UND" ? 1 : 0.1}
                                min={li.um === "UND" ? 0 : 0.1}
                                max={disponible?.cant_disponible}
                                onChange={(e) => {
                                  if (disponible.cant_disponible > 0 && e.target.value <= disponible.cant_disponible) {
                                    setCantidadArreglo(parseFloat(e.target.value))
                                    setUsaSerial(li.serial)
                                    setValOk(true)
                                  } else {
                                    toast.warning("Cantidad no permitida, intenta de nuevo")
                                    setValOk(false)
                                    setValidacionOk(false)
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th>Observaciones</th>
                          <td colSpan={5} >
                            <div className="input-group ">
                              <textarea
                                type="text"
                                className={`form-control form-control-sm ${bodegas.length === 0 && index === indexFila && !observaciones ? "mb-1 mt-3" : ""}`}
                                disabled={index !== indexFila || validacionOk}
                                placeholder={mayor ? "(*) Es mayor porque..." : menor ? "(*) Es menor porque..." : ""}
                                rows={1}
                                onChange={(e) => setObservaciones(e.target.value)}
                              />
                            </div>
                            {(bodegas.length === 0 && index === indexFila && !observaciones) &&
                              texto()
                            }
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>))}

          <div className=" btn-group my-3">
            {validacionOk &&
              <button
                className="btn btn-sm btn-primary"
                onClick={() => enviarSol()}>
                Enviar
              </button>
            }

            {clicks >= solicitudProcesar.length && !validacionOk &&
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={() => {
                  validarDisponibilidad()
                }}>
                Validar
              </button>
            }
          </div>

        </>)
        :
        <div className="card mt-5">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-2">
                <button className="btn btn-primary fa-solid fa-angle-left btn-sm"
                  onClick={() => {
                    setComponente(origen)
                    history.push({ pathname: `/${origen === "ConsSolicitudes" ? "cons_solicitud" : "cons_solicitud"}`, state: { componente: origen } })
                  }} />
              </div>
              <div className="col-8">
                <h3 className="h3-easystock">
                  Gestion Solicitud: {sol}
                </h3>
              </div>
            </div>
          </div>

          <div className="card-body table-responsive">

            <table className="table table-sm table-hover table-striped mt-3">
              {/* Tabla procesar */}
              <thead className="text-center">
                <tr>
                  <th >Consec. Sol</th>
                  <th >Consec. Art</th>
                  <th >Artículo</th>
                  <th >Solicitado</th>
                  <th >Origen</th>
                  <th >Ubic. Origen</th>
                  <th >Cant. Enviar</th>
                  <th >Observacion
                    {mayor || menor || bodegas.length === 0 ?
                      <span className="text-danger"> <strong>(*)</strong> Obligatorio</span>
                      : null}
                  </th>
                  <th >Actions</th>
                </tr>
              </thead>

              <tbody>
                {solicitudProcesar?.map((li, index) => (
                  <tr key={li.detalle.destino + li.detalle.idArticulo}>
                    <td>{li.consecutivoFixSol}</td>
                    <td>{li.articulo}</td>
                    <td>{li.des_Larga}</td>
                    <td>{li.detalle.cantidadSolicitada}</td>

                    {/* Origen */}
                    <td>
                      <div className="input-group">
                        <select
                          className="form-select form-select-sm"
                          disabled={index !== indexFila || bodegas.length === 0 || validacionOk}
                          onChange={(e) => {
                            setArt("")
                            setBodega(e.target.value)
                            setUsaSerial(li.serial)
                            setArt(li.articulo)
                          }}
                        >
                          {li.bodega && index !== indexFila
                            ? <option value={li.bodega} selected> {li.bodega} </option>
                            : null
                          }

                          {index === indexFila && (bodegas.length === 0)
                            ? <option value=''> Sin Inventario disponible </option>
                            : <option value=''> Seleccione Bodega </option>
                          }

                          {index === indexFila && bodegas.map(b => (
                            <option key={b} value={b} selected={b === li.bodega}>
                              {b}
                            </option>
                          ))}

                        </select>
                      </div>
                    </td>

                    {/* Ubicacion Origen */}
                    <td>
                      <div className="input-group">
                        <select
                          className="form-select form-select-sm"
                          disabled={index !== indexFila || bodegas.length === 0 || validacionOk}
                          onChange={(e) => setUbicacion(e.target.value)}
                        >

                          {li.ubicacion && index !== indexFila
                            ? <option value={li.ubicacion} selected> {li.ubicacion} </option>
                            : null
                          }

                          {index === indexFila && (ubicaciones.length === 0)
                            ? <option value=''> Sin Inventario disponible </option>
                            : <option value=''> Seleccione Ubicacion </option>
                          }

                          {index === indexFila && ubicaciones.map((b) => (
                            <option key={b} value={b} selected={b === li.ubicacion}>
                              {b}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>

                    {/* Cantidad Enviar */}
                    <td>
                      <div className="input-group input-group-sm">
                        <span className="input-group-text" id="basic-addon1">
                          ({index === indexFila && disponible !== undefined ? disponible?.cant_disponible : 0})
                        </span>
                        <input
                          className={!valOk ? "text-danger form-control form-control-sm " : "form-control form-control-sm "}
                          disabled={(index !== indexFila) || (disponible?.cant_disponible === 0) || (solicitudProcesar[index]?.bodega === undefined) || (solicitudProcesar[index]?.ubicacion === undefined) || (bodegas.length === 0) || (validacionOk)}
                          type="number"
                          step={li.um === "UND" ? 1 : 0.1}
                          min={li.um === "UND" ? 0 : 0.1}
                          max={disponible?.cant_disponible}
                          onChange={(e) => {
                            if (disponible.cant_disponible > 0 && e.target.value <= disponible.cant_disponible) {
                              setCantidadArreglo(parseFloat(e.target.value))
                              setUsaSerial(li.serial)
                              setValOk(true)
                            } else {
                              toast.warning("Cantidad no permitida, intenta de nuevo")
                              setValOk(false)
                              setValidacionOk(false)
                            }
                          }}
                        />
                      </div>
                    </td>

                    {/* Observaciones */}
                    <td>
                      <div className="input-group ">
                        <textarea
                          type="text"
                          className={`form-control form-control-sm ${bodegas.length === 0 && index === indexFila && !observaciones ? "mb-1 mt-3" : ""}`}
                          disabled={index !== indexFila || validacionOk}
                          placeholder={mayor ? "(*) Es mayor porque..." : menor ? "(*) Es menor porque..." : ""}
                          rows={1}
                          onChange={(e) => setObservaciones(e.target.value)}
                        />
                      </div>
                      {(bodegas.length === 0 && index === indexFila && !observaciones) &&
                        texto()
                      }
                    </td>

                    <td className="bnt-group">
                      <button
                        className="btn btn-sm btn-outline-dark fa-solid fa-pencil me-1"
                        title="Llenar datos"
                        disabled={validacionOk}
                        onClick={() => {
                          handleClick(index, li)
                          setLineaProcesar(li)
                          setClicks(clicks + 1)
                        }}
                      />
                      {usaSerial && serialesTomados.length === 0 &&
                        <button
                          className="btn btn-sm btn-outline-primary  fa-solid fa-list-check"
                        disabled={validacionOk}
                          data-bs-toggle="modal"
                          data-bs-target="#modalSeriales"
                          title="Agregar seriales de los articulos"
                          onClick={() => setModalIsOpen(true)}
                        />
                      }
                    </td>
                  </tr>

                ))}
              </tbody>
            </table>

            {/* botones*/}
            <div className=" btn-group">
              {validacionOk &&
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => enviarSol()}>
                  Enviar
                </button>
              }

              {clicks >= solicitudProcesar.length && !validacionOk &&
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    validarDisponibilidad()
                  }}>
                  Validar
                </button>
              }

            </div>

          </div>
        </div>
      }
      {/* Modal seriales */}
      <div
        className="modal fade"
        id="modalSeriales"
        aria-hidden="true"
        aria-labelledby="modalSerialesLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-info text-light">
              <h1 className="modal-title fs-5" id="modalSerialesLabel2">
                Seriales
              </h1>
            </div>
            <div className="modal-body">
              <table className="table table-hover  table-sm">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Serial</th>
                  </tr>
                </thead>
                <tbody className="text-center" id="tabla">
                  {usaSerial &&
                    Array.from(Array(cantidadArreglo || 0), (_, index) => (
                      <tr key={index}>
                        <td>
                          <Select
                            key={key}
                            className="mb-2"
                            placeholder="Serie"
                            onClick={(e) => setContRenSeriales((prueba) => contRenSeriales + 1)}
                            onChange={(option) => { handleSerialChange(index, option) }}
                            options={listaSeries}
                            styles={styles} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={() => setModalIsOpen(true)}
              >
                Guardar
              </button>
              <button
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setSerialesTomados([])
                  setModalIsOpen(true)
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};