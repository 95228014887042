import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { cargarPermisos, changeTitle, handleColum, isMobile } from "../../config/functions/funGenerales";
import { crearGruposConsec, procesarDatos, traerEmpresa } from "../../config/functions/funReportes";
import {CheckboxCol} from "../CheckboxCol";
import { Filtrar2 } from "../Filtrar2";
import { ModalArtReport } from './ModalArtReport';
import { Pagination } from "../Pagination";

export const ReportSolicitudes = ({ articulos, kardex,permisos }) => {

  const user = JSON.parse(localStorage.getItem("token")).userName;
  const emp = JSON.parse(localStorage.getItem("token")).empresa;
  const broken = JSON.parse(localStorage.getItem("broken"));
  const collapsed = JSON.parse(localStorage.getItem("collapsed") || 'false');

  const [listaFiltroSolicitudes, setListaFiltroSolicitudes] = useState([]);
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([]);
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [listaSolicitudes, setListaSolicitudes] = useState([]);
  const [listaArticulos, setListaArticulos] = useState([]);
  const [dataEmpresa, setDataEmpresa] = useState([]);
  const [listaPDF, setListaPDF] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [mobile, setMobile] = useState(true);
  const [consec, setConsec] = useState("");
  const tablaRef = useRef(null);

  const [col2, setCol2] = useState(true)
  const [col3, setCol3] = useState(true)
  const [col4, setCol4] = useState(true);


  /***************** Funciones ****************/
  useEffect(() => {
    changeTitle("Admin. Reporte_Solicitudes");
    cargaInicial();
  }, []);

  const cargaInicial = () => {
    handleColum(
      [setCol2, setCol3, setCol4],
      user,
      "repSolicitud"
    );
    cargarSolicitudes();
    setListaArticulos(articulos)
    isMobile(setMobile);
    traerEmpresa(emp, setDataEmpresa);
  };

  useEffect(() => {
    setListaArticulos(articulos)
  }, [articulos]);

  useEffect(() => {
    cargarSolicitudes()
  }, [kardex]);

  useEffect(() => {
    if (listaSolicitudes.length > 0) {
      procesarDatos(listaSolicitudes, listaArticulos, setListaFiltroSolicitudes, setListaPDF)
    }
  }, [listaSolicitudes]);

  const cargarSolicitudes = async () => {
    const listaTemporal = kardex
    const resultado = listaTemporal.filter(elemento => {
      return elemento.consecutivo.startsWith("SOL");
    });
    setListaSolicitudes(resultado)
  };

  //------- Tablas ---------
  const validarCheckboxes = (excluido) => {
    let contador = 0;
    if (col2 && excluido !== 'col2') contador++;
    if (col3 && excluido !== 'col3') contador++;
    if (col4 && excluido !== 'col4') contador++;
    return contador >= 2;
  };

  const agregarColumna = (tabla, columna, valor) => {
    localStorage.setItem(user + "/" + tabla + "/" + columna, JSON.stringify(valor));
  };

  //-------- Paginación ---------
  useEffect(() => {
    setTotalPaginas(Math.ceil(listaFiltroSolicitudes.length / registrosPorPagina))
  }, [listaFiltroSolicitudes, registrosPorPagina]);

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(listaFiltroSolicitudes.slice(inicio, fin))
  }, [paginaActual, totalPaginas]);


  /************************************** */
  return (
    <>
      <div className="fixed-top mt-2"
        style={{
          backgroundColor: "white",
          marginLeft: broken || mobile ? "" : (!collapsed ? "250px" : "80px"),
          transition: "margin-left 0.3s ease",
          top: "46px",
          zIndex: 1
        }}>

        <div className="row ms-2 me-2">

          {/* botones de opcion */}
          <div className="col-4 align-self-start text-start">
            <div className="btn-group">

              {/* Columnas */}
              <button
                className="btn fa-solid fa-table-columns"
                title="Seleccionar Columnas"
                data-bs-toggle="modal"
                data-bs-target="#ModificarColumnas"
                id="modalcolumnas" />
            </div>
          </div>

          {/* filtrar */}
          <div className="col-8">
            <Filtrar2
              tabla="myTable"
              documentos={listaFiltroSolicitudes}
              setDocumentosFiltrados={setDocumentosFiltrados}
              setRegistrosFiltrados={setRegistrosPaginaActual}
              registrosPorPagina={registrosPorPagina} />
          </div>
        </div>
      </div>

      <hr className={"mt-5 " + (mobile === true ? " mb-5" : "")} />

      {permisos.includes("repSolicitudes") ?
        <div className=" table-responsive abs-center-table">
          <table id="myTable"
            ref={tablaRef}
            className="table table-hover table-striped table-sm table-bordered mt-3">
            <thead className="text-center">
              <tr>
                <th className="text-center" style={{ resize: "none" }} colSpan={"100%"}> REPORTE DE SOLICITUDES</th>
              </tr>
              <tr>
                <th className={col2 ? "" : "d-none"}>Consecutivo</th>
                <th className={col3 ? "" : "d-none"}>Fecha Solicitud</th>
                <th className={col4 ? "" : "d-none"}>Artículos</th>
                <th className="text-center">Ver Rep.</th>
              </tr>
            </thead>
            <tbody className="text-center" id="tabla_articulos">
              {registrosPaginaActual && registrosPaginaActual.map(art => (
                <tr key={art.consecutivo}>
                  <td className={col2 ? "" : "d-none"}>{art.consecutivo}</td>
                  <td className={col3 ? "" : "d-none"}>{art.fecha_creacion}</td>
                  <td className={col4 ? "" : "d-none"}>
                    <button className="btn btn-sm btn-outline-primary fa-solid fa-list"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      onClick={() => {
                        setConsec(art.consecutivo)
                        crearGruposConsec(art.articulo, setGrupo)
                      }} />
                  </td>
                  <td className="text-center">
                    {permisos.includes("impReportSol") ?
                      <NavLink
                        to={{ pathname: `/docpdf` }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="btn btn-outline-primary btn-sm me-2 fa-solid fa-print"
                          onClick={() => {
                            localStorage.setItem('myData', JSON.stringify({
                              c: art.consecutivo, d: listaPDF,
                              a: "Solicitudes", e: dataEmpresa
                            }));
                          }}
                        />
                      </NavLink>
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        : null}

      <Pagination
        paginaActual={paginaActual}
        totalPaginas={totalPaginas}
        setPaginaActual={setPaginaActual}
        registrosPorPagina={registrosPorPagina}
        setRegistrosPorPagina={setRegistrosPorPagina}
        documentosFiltrados={documentosFiltrados}
      />

      {/* Modal Columnas */}
      <div
        className="modal fade"
        aria-hidden="true"
        id="ModificarColumnas"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content ">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title" id="staticBackdropLabel3">
                Selección de columnas
              </h5>
            </div>

            <div className="modal-body">

              <div className="form-check">
                <CheckboxCol tabla="repSolicitud" columna={2} checked={col2} setChecked={setCol2} validar={() => validarCheckboxes('col2')} agregarColumna={agregarColumna} texto={"Consecutivo"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repSolicitud" columna={3} checked={col3} setChecked={setCol3} validar={() => validarCheckboxes('col3')} agregarColumna={agregarColumna} texto={"Fecha Solicitud"} />
              </div>

              <div className="form-check">
                <CheckboxCol tabla="repSolicitud" columna={4} checked={col4} setChecked={setCol4} validar={() => validarCheckboxes('col4')} agregarColumna={agregarColumna} texto={"Artículos"} />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal articulos */}
      <ModalArtReport consecutivo={consec} lista={grupo} />

    </>
  );
};
